import * as yup from 'yup'
import { AnyObject } from 'yup/lib/types'

/**
 * Enhances yup.number() with the possibility to pass the context as a generic parameter
 *
 * @returns a yup.number constructor
 */
export const yupNumber = <TContext extends AnyObject = AnyObject>() =>
  new yup.NumberSchema<number, TContext, number>()
