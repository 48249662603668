import React, { PropsWithChildren } from 'react'
import { ProductSection } from 'services/api/entities/catalog/types'
import { useKRecordContext } from 'hooks/useKRecordContext'
import { hasSections } from 'services/utils'
import {
  CatalogItemBase,
  MainProduct,
  TailoredProduct,
  WithId
} from '@kampaay/common'
import CustomReferenceInput from 'components/Inputs/CustomReferenceInput'
import LocalizedSelectArrayInput from 'components/Inputs/Localized/LocalizedSelectArrayInput'
import CatalogItemBaseFormContent from 'components/Forms/CatalogItemBaseFormContent'
import { useKResourceContext } from 'hooks/useKResourceContext'
import { SectionEntity } from 'services/api/entities'
import { SECTION_PRODUCT_ENTITY_MAP } from 'services/cfg/name-maps'

type TailoredProductWithId = TailoredProduct & WithId

export type ProductSectionFormContentProps = PropsWithChildren & {
  canAssignProductToMultipleSections?: boolean
}

export const filterNoTailoredProducts = (e: TailoredProductWithId) =>
  !e.customers.length && !e.organizations.length

const filterBySectionAssigned = (d: MainProduct) => !hasSections(d)

const ProductSectionFormContent: React.FC<ProductSectionFormContentProps> = ({
  children,
  canAssignProductToMultipleSections = true
}) => {
  const record = useKRecordContext<ProductSection<CatalogItemBase>>()
  const sectionEntity = useKResourceContext<SectionEntity>()
  const productEntity = SECTION_PRODUCT_ENTITY_MAP[sectionEntity]

  const filterFns = [
    // if the flag is true (by default) then don't filter
    // else filter only the products that have not had a section
    canAssignProductToMultipleSections ? () => true : filterBySectionAssigned,
    filterNoTailoredProducts
  ]

  return (
    <CatalogItemBaseFormContent>
      {children}
      <CustomReferenceInput
        filterFns={filterFns}
        entity={productEntity}
        additionalChoices={record?.productsFull}
      >
        <LocalizedSelectArrayInput
          source="products"
          label="Products"
          fullWidth
        />
      </CustomReferenceInput>
    </CatalogItemBaseFormContent>
  )
}

export default ProductSectionFormContent
