import { APIFood } from '@kampaay/common'
import { APICfg } from 'services/api/api'
import { ProductSectionWithDescription } from 'services/api/entities/catalog/types'
import { productSectionParse } from 'services/api/entities/catalog/utils'

type FoodSection = ProductSectionWithDescription<APIFood>

type FoodSectionToAPI = FoodSection

const apiCfg: APICfg = {
  endpoint: 'foodsections',
  parse: productSectionParse,
  write: async (req: FoodSection): Promise<FoodSectionToAPI> => req
}
export default apiCfg
