import React from 'react'
import { RAFormFC } from 'types/common'
import { useForm } from 'hooks/useForm'
import { PricingReadOnly } from 'services/api/entities/events/types/internal/event-models'
import LabeledNumberField from 'components/Fields/LabeledFields/LabeledNumberField'
import { useTranslate } from 'react-admin'

type Props = {
  title?: string
  labelPrice?: string
  labelCostNoVat?: string
}

const PricingSummary: RAFormFC<Props> = ({
  source,
  title,
  labelPrice,
  labelCostNoVat
}) => {
  const { getSource } = useForm<PricingReadOnly>(source)
  const translate = useTranslate()

  // ===================================
  // JSX
  // ===================================
  return (
    <div>
      {title ? (
        <h4
          style={{
            marginTop: '.5em'
          }}
          data-testid="pricing-summary--title"
        >
          {translate(title)}
        </h4>
      ) : null}

      <div
        style={{
          display: 'flex',
          gap: '1em'
        }}
      >
        <LabeledNumberField
          label={labelPrice ?? 'form.fields.price'}
          source={getSource('price')}
          roundTwoDecimals
          data-testid={'pricing-summary--price'}
        />

        <LabeledNumberField
          label={labelCostNoVat ?? 'form.fields.costNoVat'}
          source={getSource('costNoVat')}
          data-testid={'pricing-summary--cost-no-vat'}
          roundTwoDecimals
        />
      </div>
    </div>
  )
}

export default PricingSummary
