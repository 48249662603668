import { OutlinedFlag } from '@mui/icons-material'
import EventEdit from 'resources/operations/events/EventEdit'
import EventCreate from 'resources/operations/events/EventCreate'
import EventList from 'resources/operations/events/EventList'

const eventsIndex = {
  icon: OutlinedFlag,
  list: EventList,
  edit: EventEdit,
  create: EventCreate
}

export default eventsIndex
