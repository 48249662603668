import { getId } from '../../../utils'
import type { APIGeoAreaPreset, GeoAreaPreset } from './types'

export const geoareaPresetParse = ({
  organizations,
  geoArea,
  ...res
}: APIGeoAreaPreset): GeoAreaPreset => {
  return {
    ...res,
    geoArea: {
      ...geoArea,
      radius: geoArea.radius / 1000 // turning meters into kilometers
    },
    organizations: organizations.map(getId),
    organizationsFull: organizations
  }
}
