import * as React from 'react'
import { EditButton } from 'react-admin'
import { RAFormFC } from 'types/common'
import { useKRecordContext } from 'hooks/useKRecordContext'
import { EventItem } from 'services/api/entities/events/types/internal/event-models'

const EventIdEditButton: RAFormFC = () => {
  const record = useKRecordContext<EventItem>()

  if (!record) {
    return null
  }
  return <EditButton label={record.id.toString()} icon={<></>} />
}

export default EventIdEditButton
