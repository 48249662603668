import React from 'react'
import SectionWrapper from 'components/UI/SectionWrapper'
import ProfessionalFiguresFormContent from 'resources/catalog/professionals/p-figures/ProfessionalFiguresFormContent'
import { KSimpleForm } from 'components/Forms/KForms/KSimpleForm'
import KCreate from 'components/Forms/KCreate/KCreate'

const ProfessionalFiguresCreate: React.FC = () => {
  return (
    <SectionWrapper>
      <KCreate>
        <KSimpleForm>
          <ProfessionalFiguresFormContent />
        </KSimpleForm>
      </KCreate>
    </SectionWrapper>
  )
}

export default ProfessionalFiguresCreate
