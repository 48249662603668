import type { ProposalItem } from '../../../api'
import { roundTwoDecimals, getMargin, getMarginPerc } from '../../prices'

type GetPricingParam = Pick<
  ProposalItem,
  | 'unitaryCost'
  | 'unitaryPrice'
  | 'quantity'
  | 'quantity2'
  | 'commissionPercentage'
  | 'deltaCost'
  | 'totalCost'
  | 'totalPrice'
>

export type ProposalItemPricingProps = {
  totalPrice: number | undefined
  totalCost: number | undefined
  marginality: number | undefined
  percentageMarginality: number | undefined
  percentageMarginalityLabel: string
  commissionAmount: number | undefined
}

export const getProposalItemPricing = (
  item: GetPricingParam
): ProposalItemPricingProps => {
  const {
    commissionPercentage = 0,
    deltaCost = 0,
    totalPrice = 0,
    totalCost = 0
  } = item

  const finalCost = totalCost + deltaCost

  const commissionAmount = commissionPercentage
    ? finalCost * (commissionPercentage / 100)
    : 0
  const marginality =
    !!totalPrice && !!finalCost ? getMargin(totalPrice, finalCost) : 0
  const percentageMarginality =
    marginality && !!totalPrice ? getMarginPerc(marginality, totalPrice) : 0
  const percentageMarginalityLabel = percentageMarginality
    ? `${percentageMarginality}%`
    : ''

  return {
    totalPrice,
    totalCost: finalCost,
    marginality,
    percentageMarginality,
    percentageMarginalityLabel,
    commissionAmount
  }
}

export const getUnitaryProposalItemPriceOrCost = (
  totalPriceOrCost: number | undefined,
  quantity: number = 1,
  quantity2: number = 1
): number | undefined => {
  const totalQuantity = quantity * quantity2
  return totalPriceOrCost
    ? roundTwoDecimals(totalPriceOrCost / totalQuantity)
    : undefined
}

export const getTotalProposalItemPriceOrCost = (
  unitaryPriceOrCost: number | undefined,
  quantity: number = 1,
  quantity2: number = 1
): number | undefined => {
  const totalQuantity = quantity * quantity2
  return unitaryPriceOrCost
    ? roundTwoDecimals(unitaryPriceOrCost * totalQuantity)
    : undefined
}
