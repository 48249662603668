import { LocalPizza } from '@mui/icons-material'
import FoodUpsellingList from 'resources/catalog/food/upselling/FoodUpsellingList'
import FoodUpsellingEdit from 'resources/catalog/food/upselling/FoodUpsellingEdit'
import FoodUpsellingCreate from 'resources/catalog/food/upselling/FoodUpsellingCreate'

const foodUpsellingIndex = {
  icon: LocalPizza,
  list: FoodUpsellingList,
  edit: FoodUpsellingEdit,
  create: FoodUpsellingCreate
}

export default foodUpsellingIndex
