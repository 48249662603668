import WhyThis from 'components/WhyThis'
import React, { useMemo } from 'react'
import {
  BulkExportButton,
  BulkExportButtonProps,
  Exporter,
  useListContext
} from 'react-admin'
import { exportFormatter } from 'services/csv/exporter'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import { Transaction } from 'services/api/entities/accounting/transaction/types/internal/transaction-models'

type ExportForPurchaseOrderButtonProps = Pick<
  BulkExportButtonProps,
  'selectedIds'
>

const ExportForPurchaseOrderButton: React.FC<
  ExportForPurchaseOrderButtonProps
> = () => {
  const { selectedIds, data } = useListContext<Transaction>()

  const notFromConciergeExists = useMemo(
    () =>
      data
        .filter((datum) => selectedIds.includes(datum.id))
        .some((d) => !d.proposalId),
    [data, selectedIds]
  )

  return (
    <div className="flex items-center">
      {/* eslint-disable-next-line react-admin/noNativeInput */}
      <BulkExportButton
        selectedIds={selectedIds}
        exporter={exportFormatter('purchaseOrder') as Exporter}
        label="ra.action.exportForPurchaseOrder"
        icon={<ShoppingCartIcon />}
        disabled={notFromConciergeExists}
      />
      <WhyThis
        placement="bottom-start"
        text="info.exportForPurchaseOrderDisabled"
        iconOnly
        show={notFromConciergeExists}
      />
    </div>
  )
}

export default ExportForPurchaseOrderButton
