/**
 * Open internal link in new window
 * (because RA uses # for internal links)
 * @param pathname
 */
export const openInternalLinkInNewWindow = (pathname: string) => {
  window.open(`#${pathname}`, '_blank')
}

/**
 * Open external link in new window
 * @param url
 */
export const openExternalLinkInNewWindow = (url: string) => {
  window.open(url, '_blank')
}

type App = 'react-app' | 'vue-app'
type NavigateOptions = {
  internal?: boolean
  target?: string
}

export const navigate = (
  path: string,
  app: App = 'react-app',
  opts: NavigateOptions = {}
) => {
  const { internal = true, target = '_self' } = opts

  // compute the base url based on the app we want to navigate to
  const baseUrl =
    app === 'react-app'
      ? `${window.location.origin}/#/`
      : `${window.location.origin}/new/`

  // if internal is true, we want to navigate to a path inside the app, else we will navigate to the full external url
  const url = internal ? `${baseUrl}${path}` : path

  if (target === '_blank') {
    window.open(url, '_blank')
    return
  }

  window.location.assign(url)
}
