import { styled } from '@mui/material/styles'
import React, { PropsWithChildren } from 'react'
import { useTranslate } from 'react-admin'
import { ReactQueryDevtools } from 'react-query/devtools'

const PREFIX = 'SectionWrapper'

const classes = {
  title: `${PREFIX}-title`
}

const Root = styled('div')({
  [`& .${classes.title}`]: {
    fontWeight: 'bold',
    fontSize: '32px'
  }
})

type SectionWrapperProps = PropsWithChildren & {
  title?: string
}

/**
 * This is a styled wrapper with a title used in each section of the app.
 * @param title The title of the section.
 * @param children
 */
const SectionWrapper: React.FC<SectionWrapperProps> = ({ title, children }) => {
  const translate = useTranslate()
  return (
    <Root>
      {title && <p className={classes.title}>{translate(title)}</p>}
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </Root>
  )
}

export default SectionWrapper
