import React, { useCallback } from 'react'
import { Button, useCreatePath, useGetOne, useTranslate } from 'react-admin'
import { Grid } from '@mui/material'
import CustomReferenceInput from 'components/Inputs/CustomReferenceInput'
import { useFormContext } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { OpenInNew } from '@mui/icons-material'
import { openInternalLinkInNewWindow } from 'services/utils/navigationUtils'
import { FILTER_SOURCES, OrganizationSubGroup } from '@kampaay/common'
import LocalizedAutocompleteInput from 'components/Inputs/Localized/LocalizedAutocompleteInput'
import OrganizationSubGroupCreateModal from 'components/FormComponents/Organization/SubGroups/OrganizationSubGroupCreateModal'

const OrganizationMembershipSubGroup: React.FC = () => {
  const { id: organizationId } = useParams()

  const translate = useTranslate()
  const createPath = useCreatePath()

  const { watch } = useFormContext()
  const subGroupId = watch('subGroupId')

  const { data } = useGetOne<OrganizationSubGroup>(
    'organizationsubgroup',
    {
      id: subGroupId
    },
    { enabled: !!subGroupId }
  )

  const openSubGroupEditPage = useCallback(() => {
    const path = createPath({
      resource: `organization/${organizationId}/sub-groups`,
      id: data?.id,
      type: 'edit'
    })

    openInternalLinkInNewWindow(path)
  }, [])

  return (
    <div className="w-full">
      <h2>{translate('form.fields.subGroup')}</h2>
      <Grid
        container
        spacing={2}
        alignItems="center"
        wrap="nowrap"
        width="100%"
      >
        <Grid item xs={6}>
          <CustomReferenceInput
            entity="organizationsubgroup"
            queryFilter={{
              [FILTER_SOURCES.organizationId.eq]: organizationId!
            }}
          >
            <LocalizedAutocompleteInput
              source="subGroupId"
              label="form.fields.subGroupLower"
              helperText={false}
              create={<OrganizationSubGroupCreateModal />}
              createLabel="form.modals.createNewSubGroup.title"
              createItemLabel="form.modals.createNewSubGroup.title"
              className="w-full"
            />
          </CustomReferenceInput>
        </Grid>
        <Grid item xs={3}>
          <Button onClick={openSubGroupEditPage}>
            <OpenInNew />
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

export default OrganizationMembershipSubGroup
