import React from 'react'
import { APIEntity } from 'services/api/entities'
import { useKGetList } from 'hooks/useKGetList'
import { distinctById } from 'services/utils'
import { VoidFunction } from 'types/common'
import { SelectInput } from 'react-admin'
import LocalizedWithChoicesInput from 'components/Inputs/Localized/LocalizedWithChoicesInput'
import { WithId } from '@kampaay/common'

export type FilterSelectInputProps<T extends WithId> = {
  entity: APIEntity
  searchFn: (v: any) => T[]
  source: string
  optionText: keyof T
  label: string
  onChange: VoidFunction
}

/**
 * This is an input that fetches a list and takes unique elements given from a search function to put into a select input.
 * @param listResource the current resource of the list to fetch
 * @param searchFn the function that takes a list element and returns the element with witch to filter
 * @param source the source of the filter
 * @param optionText the name to display in the select input taken from the object of the filter
 */
export const FilterSelectInput = <T extends WithId>({
  entity,
  searchFn,
  source,
  optionText,
  onChange,
  label
}: FilterSelectInputProps<T>) => {
  const resource = useKGetList(entity).list

  // the list to which the filter applies
  const items = resource.flatMap(searchFn)
  const choices = distinctById(items)

  return (
    // <LocalizedSelectInput
    //   source={source}
    //   label={label}
    //   choices={choices.map((e: T) => ({
    //     id: e.id,
    //     name: e[optionText],
    //   }))}
    //   onChange={onChange}
    // />
    <LocalizedWithChoicesInput
      label={label}
      source={source}
      choices={choices.map((e: T) => ({
        id: e.id,
        name: e[optionText]
      }))}
      onChange={onChange}
    >
      {/* FIXME: this is needed because of how "TailoredVisibleToAllFilter" handles the form data imperatively, find a way to make it work with our wrapper */}
      {/* eslint-disable-next-line react-admin/noNativeInput */}
      <SelectInput />
    </LocalizedWithChoicesInput>
  )
}
