import { Button } from '@mui/material'
import React from 'react'
import { useNotify, useTranslate } from 'react-admin'
import { EventItem } from 'services/api/entities/events/types/internal/event-models'
import { useKRecordContext } from 'hooks/useKRecordContext'
import { WithStyle } from 'types/ui'
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined'
import { BASE_SF_EVENT_ROUTE } from 'services/utils/urlUtils'

type Props = WithStyle

const ShareEventButton: React.FC<Props> = ({ style }) => {
  const record = useKRecordContext<EventItem>()
  const translate = useTranslate()
  const notify = useNotify()

  const copyEventLinkAndNotify = async () => {
    await navigator.clipboard.writeText(`${BASE_SF_EVENT_ROUTE}/${record?.id}`)
    notify('notification.info.linkCopiedToClipboard', { type: 'info' })
  }

  //=================================
  // JSX
  //=================================
  return (
    <Button
      variant="text"
      startIcon={<ShareOutlinedIcon />}
      onClick={copyEventLinkAndNotify}
      style={style}
      sx={{
        fontWeight: 'bold'
      }}
    >
      {translate('common.label.share')}
    </Button>
  )
}
export default ShareEventButton
