import { getId } from '../../../../../utils'
import { parseFileInfo } from '../../../fileinfo'
import type { APIFoodUpselling } from '../../food-upselling'
import { mainProductParse } from '../products'
import { type APIUpselling, upsellingParse } from '../upselling'
import type {
  APIOptionedProduct,
  APIOptionGroup,
  APIProductWithUpselling,
  OptionedProduct,
  OptionGroup,
  ProductWithUpselling
} from './types'

/**
 * @deprecated Kept for backwards compatibility to use for the obsolete location model. This level of abstraction is not needed anymore for the remaining products: food, physicals and professionals.
 */
export const productWithUpsellingParse = ({
  upselling,
  ...rest
}: APIProductWithUpselling): ProductWithUpselling => ({
  ...mainProductParse(rest),
  upsellingItemsFull: upselling.map(upsellingParse),
  upsellingItems: upselling.map(getId)
})

export const optionsParse = (apiOptions: APIOptionGroup[]): OptionGroup[] =>
  apiOptions.map((opt) => {
    return {
      ...opt,
      maxSelection: opt.maxSelection ?? null,
      items: opt.items.map((optItem) => ({
        ...optItem,
        isPriced: !!optItem.pricing.length,
        image: parseFileInfo(optItem.image)
      }))
    }
  })

export const optionedProductParse = (
  res: APIOptionedProduct<APIUpselling | APIFoodUpselling>
): OptionedProduct => {
  return {
    ...res,
    ...productWithUpsellingParse(res),
    options: optionsParse(res.options)
  }
}
