import { formatOrdinals } from '../'
import type { Translate, UTranslate } from '../types'

const translateStep = (message: string, t: Translate) => {
  const fieldRegex = /^(\w+)(?:\[(\d+)\])?$/
  const [, name, index] = fieldRegex.exec(message)!

  if (index != null) {
    return `${formatOrdinals(parseInt(index) + 1)} ${t(name!)}`
  }

  return t(name!)
}

export const createReadableErrorPath = (errorPath: string, t: UTranslate) =>
  errorPath
    .split('.')
    .map((f) => translateStep(f, t('form.fields')))
    .join(' > ')

export const makeErrorReadable = (message: string, t: UTranslate) => {
  // If the error message starts with a lowercase letter, it means it's not translated
  // and it needs to be transformed into a readable one. Otherwise, as it's already
  // translated, we return it as is.
  if (/^\s*[a-z]/.test(message)) {
    return message.replace(
      /^\S+/,
      (value) => `Error at ${createReadableErrorPath(value, t)}:`
    )
  }

  return message
}
