import {
  KTestConfig,
  KTestFunction,
  UseValidationSchema,
  yupArray,
  yupBoolean,
  yupIgnore,
  yupObject,
  YupSchema
} from 'services/yup'
import {
  hasAtLeastOneToggleEnabledCustomPrice,
  hasAtLeastOneToggleEnabledIsHidden
} from 'services/validations/crm/oraganizationTailorings/sharedTestConfig'
import { standardPricingArrayVS } from 'services/validations/catalog/standard-pricing/standardPricingArray'
import * as yup from 'yup'
import { APIPriceRange, ProductTailoring, Tailorings } from '@kampaay/common'

// ================================================
// tests
// ================================================
const atLeastOnePricingWhenCustomPriceEnabled: KTestFunction<
  boolean,
  ProductTailoring<APIPriceRange>
> = (hasCustomPrice, { parent: { pricing } }) => {
  return !!hasCustomPrice ? !!pricing?.length : true
}

export const hasAtLeastOnePricingWhenCustomPriceEnabled: KTestConfig<
  boolean,
  ProductTailoring<APIPriceRange>
> = {
  name: 'hasAtLeastOnePricingWhenCustomPriceEnabled',
  test: atLeastOnePricingWhenCustomPriceEnabled,
  message: 'form.validations.atLeastOnePricing'
}

// ================================================
// VS
// ================================================
const organizationTailoringsProductArrayVS: YupSchema<
  ProductTailoring<APIPriceRange>
> = yupObject().shape({
  id: yupIgnore(),
  isHidden: yupBoolean<ProductTailoring<APIPriceRange>>()
    .required()
    .test(hasAtLeastOneToggleEnabledIsHidden),
  hasCustomPrice: yupBoolean<ProductTailoring<APIPriceRange>>()
    .required()
    .test(hasAtLeastOneToggleEnabledCustomPrice)
    .test(hasAtLeastOnePricingWhenCustomPriceEnabled),
  // TODO: fix the any cast
  pricing: yup.lazy((_, { parent }) =>
    parent.hasCustomPrice ? standardPricingArrayVS() : yupArray()
  ) as any
})

export const useOrganizationTailoringsProductVS: UseValidationSchema<
  Tailorings<ProductTailoring<APIPriceRange>>
> = () =>
  yupObject().shape({
    id: yupIgnore(),
    tailorings: yupArray(organizationTailoringsProductArrayVS)
  })
