import React from 'react'
import { useTranslate } from 'react-admin'
import { MappedEnrichedEvent } from 'services/api/entities/events/types/internal/event-models'
import { pdfCss } from 'services/pdf-print/pdf-css'
import { getDateAsString, getTimeLabelFromDate } from '@kampaay/common'
import { RAFormFC } from 'types/common'
import { useForm } from 'hooks/useForm'
import { parseMultilangOrFallbackEng } from '@kampaay/common'
import KLogo from 'components/UI/KLogo'
import { entries } from 'services/utils'
import BriefSection from 'resources/operations/events/EventsComponents/PdfRenderers/commonRenderers/BriefSection'
import CustomizationsSection from 'resources/operations/events/EventsComponents/PdfRenderers/commonRenderers/CustomizationsSection'
import Footer from 'resources/operations/events/EventsComponents/PdfRenderers/commonRenderers/Footer'
import GenericSection from 'resources/operations/events/EventsComponents/PdfRenderers/commonRenderers/GenericSection'
import OptionsSection from 'resources/operations/events/EventsComponents/PdfRenderers/commonRenderers/OptionsSection'
import TranslatedParagraph from 'resources/operations/events/EventsComponents/PdfRenderers/commonRenderers/TranslatedParagraph'
import UpsellingSection from 'resources/operations/events/EventsComponents/PdfRenderers/commonRenderers/UpsellingSection'

const EventSummaryRenderer: RAFormFC = ({ source }) => {
  const { useGetValue } = useForm<MappedEnrichedEvent>(source)
  const translate = useTranslate()
  const id = useGetValue('id')
  const customerInfo = useGetValue('customerInfo')
  const startDate = useGetValue('startDate')
  const endDate = useGetValue('endDate')
  const address = useGetValue('fullAddress')
  const guestsNumber = useGetValue('guestsNumber')
  const productServices = useGetValue('productServices')
  const locations = useGetValue('locations')
  const drinks = useGetValue('drinks')
  const foods = useGetValue('foods')
  const physicals = useGetValue('physicals')
  const musics = useGetValue('musics')
  const professionals = useGetValue('professionalService.services')
  const professionalCustomizations = useGetValue(
    'professionalService.customizations'
  )
  const professionalOutfit = useGetValue('professionalOutfit')
  const generalBriefQuestions = useGetValue('generalService.briefQuestions')
  const purchasedServices = useGetValue('purchasedServices')
    ?.split(', ')
    ?.map((service) => service.toLowerCase())

  return (
    <html>
      <head>
        <title>Event Summary</title>
        <style>{pdfCss}</style>
      </head>
      <body>
        <GenericSection>
          <div className="page-header"> </div>

          <div className="page-footer">
            <Footer />
          </div>

          <table className="quotation-body">
            <thead>
              <tr>
                <td>
                  <div className="header-space">
                    <KLogo width="207" height="51" />
                  </div>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="page-content">
                    {/* GENERAL */}
                    <GenericSection>
                      <h4>
                        {`${translate('common.label.eventSummary')} #${id}`}
                      </h4>
                      <TranslatedParagraph
                        i18nKey="form.fields.customer"
                        value={customerInfo?.name}
                      />
                      <TranslatedParagraph
                        i18nKey="form.fields.startDate"
                        value={getDateAsString(startDate)}
                      />
                      <TranslatedParagraph
                        i18nKey="form.fields.endDate"
                        value={getDateAsString(endDate)}
                      />
                      <TranslatedParagraph
                        i18nKey="form.fields.eventTime"
                        value={`${getTimeLabelFromDate(
                          startDate
                        )} - ${getTimeLabelFromDate(endDate)}`}
                      />
                      <TranslatedParagraph
                        i18nKey="form.fields.eventAddress"
                        value={address}
                      />
                      <TranslatedParagraph
                        i18nKey="form.fields.participants"
                        value={guestsNumber}
                      />
                    </GenericSection>

                    {/* LOCATION */}
                    {purchasedServices.includes('location') && (
                      <GenericSection
                        footerTextI18nKey="form.fields.total"
                        footerValue={
                          productServices?.locationService?.pricing?.costNoVat
                        }
                      >
                        <h4 style={{ textDecoration: 'underline' }}>
                          {translate('form.tabs.location')}
                        </h4>
                        <TranslatedParagraph
                          i18nKey="form.fields.supplier"
                          value={productServices?.locationService?.supplierName}
                        />
                        <CustomizationsSection
                          customizations={
                            productServices?.locationService?.customizations
                          }
                        />
                        {locations?.map((location) => (
                          <div
                            key={`${location.id}-${location.name}`}
                            style={{ marginTop: '16px', marginBottom: '16px' }}
                          >
                            <p style={{ fontWeight: 'bold' }}>
                              {parseMultilangOrFallbackEng(location.name)}
                            </p>
                            <TranslatedParagraph
                              i18nKey="form.fields.reservationTime"
                              value={`${location.bookedFrom} - ${location.bookedTo}`}
                            />
                            <UpsellingSection
                              upsellings={location.upsellingSelection}
                            />
                          </div>
                        ))}
                        <BriefSection
                          briefQuestions={
                            productServices?.locationService?.briefQuestions
                          }
                        />
                        <TranslatedParagraph
                          i18nKey="form.fields.supplierMessage"
                          value={productServices?.locationService?.lastMessage}
                        />
                      </GenericSection>
                    )}

                    {/* BEVERAGES */}
                    {purchasedServices.includes('drink') && (
                      <GenericSection
                        footerTextI18nKey="form.fields.total"
                        footerValue={
                          productServices?.drinkService?.pricing?.costNoVat
                        }
                      >
                        <h4 style={{ textDecoration: 'underline' }}>
                          {translate('form.tabs.drink')}
                        </h4>
                        <TranslatedParagraph
                          i18nKey="form.fields.supplier"
                          value={productServices?.drinkService?.supplierName}
                        />

                        {drinks?.map((drink) => (
                          <div
                            key={`${drink.id}-${drink.name}`}
                            style={{ marginTop: '16px', marginBottom: '16px' }}
                          >
                            <p>
                              <span style={{ fontWeight: 'bold' }}>
                                {`${parseMultilangOrFallbackEng(drink.name)}: `}
                              </span>
                              <span>{drink.quantity}</span>
                            </p>
                          </div>
                        ))}
                        <CustomizationsSection
                          customizations={
                            productServices?.drinkService?.customizations
                          }
                        />
                        <BriefSection
                          briefQuestions={
                            productServices?.drinkService?.briefQuestions
                          }
                        />
                        <TranslatedParagraph
                          i18nKey="form.fields.supplierMessage"
                          value={productServices?.drinkService?.lastMessage}
                        />
                      </GenericSection>
                    )}

                    {/* CATERINGS */}
                    {purchasedServices.includes('food') && (
                      <GenericSection
                        footerTextI18nKey="form.fields.total"
                        footerValue={
                          productServices?.foodService?.pricing?.costNoVat
                        }
                      >
                        <h4 style={{ textDecoration: 'underline' }}>
                          {translate('form.tabs.food')}
                        </h4>
                        <TranslatedParagraph
                          i18nKey="form.fields.supplier"
                          value={productServices?.foodService?.supplierName}
                        />

                        {foods
                          ?.filter((f) => !!f.id)
                          .map((food) => (
                            <div
                              key={`${food.id}-${food.name}`}
                              style={{
                                marginTop: '16px',
                                marginBottom: '16px'
                              }}
                            >
                              <p style={{ fontWeight: 'bold' }}>
                                {parseMultilangOrFallbackEng(food.name)}
                              </p>
                              <TranslatedParagraph
                                i18nKey="form.fields.description"
                                value={parseMultilangOrFallbackEng(
                                  food.description
                                )}
                              />
                              <TranslatedParagraph
                                i18nKey="form.fields.quantity"
                                value={food.quantity}
                              />
                              <UpsellingSection
                                upsellings={food.upsellingSelection}
                              />
                              <OptionsSection options={food.optionSelections} />

                              <p>
                                {translate('form.fields.intoleranceTypes')}:{' '}
                              </p>

                              <ul style={{ paddingLeft: '60px' }}>
                                {entries(food.intolerances).map(
                                  (intoleranceEntry) => {
                                    if (typeof intoleranceEntry === 'undefined')
                                      return
                                    const [name, value] = intoleranceEntry
                                    return value ? (
                                      <li>{`${translate(
                                        `form.fields.intolerances.${name}`
                                      )}: ${value}`}</li>
                                    ) : undefined
                                  }
                                )}
                              </ul>

                              <span>{`${translate(
                                'form.fields.cateringSetup'
                              )}: ${
                                parseMultilangOrFallbackEng(
                                  food.logisticSelection?.name
                                ) ?? ''
                              } - ${food?.deliveryTime}`}</span>
                            </div>
                          ))}
                        <CustomizationsSection
                          customizations={
                            productServices?.foodService?.customizations
                          }
                        />
                        <BriefSection
                          briefQuestions={
                            productServices?.foodService?.briefQuestions
                          }
                        />
                        <TranslatedParagraph
                          i18nKey="form.fields.supplierMessage"
                          value={productServices?.foodService?.lastMessage}
                        />
                      </GenericSection>
                    )}

                    {/* PHYSICAL */}
                    {purchasedServices.includes('physical') && (
                      <GenericSection
                        footerTextI18nKey="form.fields.total"
                        footerValue={
                          productServices?.physicalService?.pricing?.costNoVat
                        }
                      >
                        <h4 style={{ textDecoration: 'underline' }}>
                          {translate('form.tabs.physicalService')}
                        </h4>
                        <TranslatedParagraph
                          i18nKey="form.fields.supplier"
                          value={productServices?.physicalService?.supplierName}
                        />

                        {physicals?.map((physical) => (
                          <div
                            key={`${physical.id}-${physical.name}}`}
                            style={{ marginTop: '16px', marginBottom: '16px' }}
                          >
                            <p style={{ fontWeight: 'bold' }}>
                              {parseMultilangOrFallbackEng(physical.name)}
                            </p>
                            <TranslatedParagraph
                              i18nKey="form.fields.description"
                              value={parseMultilangOrFallbackEng(
                                physical.description
                              )}
                            />
                            <TranslatedParagraph
                              i18nKey="form.fields.quantity"
                              value={physical.quantity}
                            />
                            <UpsellingSection
                              upsellings={physical.upsellingSelection}
                            />
                            <OptionsSection
                              options={physical.optionSelections}
                            />
                          </div>
                        ))}
                        <CustomizationsSection
                          customizations={
                            productServices?.physicalService?.customizations
                          }
                        />
                        <BriefSection
                          briefQuestions={
                            productServices?.physicalService?.briefQuestions
                          }
                        />
                        <TranslatedParagraph
                          i18nKey="form.fields.supplierMessage"
                          value={productServices?.physicalService?.lastMessage}
                        />
                      </GenericSection>
                    )}

                    {/* MUSIC */}
                    {purchasedServices.includes('music') && (
                      <GenericSection
                        footerTextI18nKey="form.fields.total"
                        footerValue={
                          productServices?.musicService?.pricing?.costNoVat
                        }
                      >
                        <h4 style={{ textDecoration: 'underline' }}>
                          {translate('form.tabs.musicService')}
                        </h4>
                        <TranslatedParagraph
                          i18nKey="form.fields.supplier"
                          value={productServices?.musicService?.supplierName}
                        />

                        {musics?.map((music) => (
                          <div
                            key={`${music.id}-${music.name}}`}
                            style={{ marginTop: '16px', marginBottom: '16px' }}
                          >
                            <p style={{ fontWeight: 'bold' }}>
                              {parseMultilangOrFallbackEng(music.name)}
                            </p>
                            <TranslatedParagraph
                              i18nKey="form.fields.description"
                              value={parseMultilangOrFallbackEng(
                                music.description
                              )}
                            />
                            <TranslatedParagraph
                              i18nKey="form.fields.quantity"
                              value={music.quantity}
                            />
                          </div>
                        ))}
                        <CustomizationsSection
                          customizations={
                            productServices?.musicService?.customizations
                          }
                        />
                        <BriefSection
                          briefQuestions={
                            productServices?.musicService?.briefQuestions
                          }
                        />
                        <TranslatedParagraph
                          i18nKey="form.fields.supplierMessage"
                          value={productServices?.musicService?.lastMessage}
                        />
                      </GenericSection>
                    )}

                    {/* PROFESSIONALS */}
                    {purchasedServices.includes('location') && (
                      <GenericSection>
                        <h4 style={{ textDecoration: 'underline' }}>
                          {translate('form.tabs.professionals')}
                        </h4>
                        {professionals?.map((professional) => (
                          <div
                            key={`${professional.id}-${professional.name}`}
                            style={{ margin: '28px 0' }}
                          >
                            <TranslatedParagraph
                              i18nKey="form.fields.supplier"
                              value={professional.supplierName}
                            />
                            <TranslatedParagraph
                              i18nKey="form.fields.name"
                              value={parseMultilangOrFallbackEng(
                                professional.name
                              )}
                            />
                            <ul style={{ marginBottom: '20px' }}>
                              {professional?.selections?.map((selection) => (
                                <li key={`${selection.id}-${selection.name}`}>
                                  <p style={{ fontWeight: 'bold' }}>
                                    {parseMultilangOrFallbackEng(
                                      selection.name
                                    )}
                                  </p>
                                  <TranslatedParagraph
                                    i18nKey="form.fields.quantity"
                                    value={selection.quantity}
                                  />
                                  <TranslatedParagraph
                                    i18nKey="form.fields.serviceTime"
                                    value={`${selection.startTime} - ${selection.endTime}`}
                                  />
                                </li>
                              ))}
                            </ul>
                            <BriefSection
                              briefQuestions={professional.briefQuestions}
                            />
                            <TranslatedParagraph
                              i18nKey="form.fields.supplierMessage"
                              value={professional.lastMessage}
                            />
                            <div
                              style={{ display: 'flex', justifyContent: 'end' }}
                            >
                              <TranslatedParagraph
                                i18nKey="form.fields.total"
                                keyStyle={{ fontWeight: 'bold' }}
                                value={professional.pricing?.costNoVat}
                              />
                            </div>
                          </div>
                        ))}

                        <CustomizationsSection
                          customizations={professionalCustomizations}
                        />
                        <TranslatedParagraph
                          i18nKey="form.fields.outfit"
                          value={professionalOutfit}
                        />
                      </GenericSection>
                    )}

                    {/* GENERAL BRIEF */}
                    <GenericSection>
                      <h4 style={{ textDecoration: 'underline' }}>
                        {translate('form.fields.generalBrief')}
                      </h4>
                      <BriefSection briefQuestions={generalBriefQuestions} />
                    </GenericSection>
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>
                  <div className="footer-space">&nbsp;</div>
                </td>
              </tr>
            </tfoot>
          </table>
        </GenericSection>
      </body>
    </html>
  )
}

export default EventSummaryRenderer
