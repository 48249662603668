type SortOrder = 'DESC' | 'ASC'
type SortingParam = string | number

/**
 * @param order sorting order
 * @param extractorFn function that extracts the property to be compared in the ordering
 * @returns a sorter function
 * according to Array.prototype.sort() specs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
 */
export const alphaNumericSorter =
  <TElement>(order: SortOrder, extractorFn: (el: TElement) => SortingParam) =>
  (a: TElement, b: TElement) => {
    const first = extractorFn(a)
    const second = extractorFn(b)

    if (first > second) {
      return order === 'ASC' ? 1 : -1
    }
    if (first < second) {
      return order === 'ASC' ? -1 : 1
    }

    return 0
  }
