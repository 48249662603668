import { getCode, getId, isTruthy } from '../../../../../utils'
import { parseFileInfo } from '../../../fileinfo'
import { parseImage } from '../../../images'
import type { APIFacet } from '../../facets/types/response-models'
import type {
  APIMainProduct,
  APIProductCommon,
  APITailoredProduct,
  MainProduct,
  ProductCommon,
  TailoredProduct
} from './types'

export const getFacetsWithCodes = (facets: APIFacet[]) => ({
  facets: facets.map(getId),
  facetCodes: facets.map(getCode)
})

// ============
// PARSE
// ============
export const productCommonParse = (res: APIProductCommon): ProductCommon => ({
  ...res,
  ...getFacetsWithCodes(res.facets),
  images: res.images.map(parseImage).filter(isTruthy),
  geoAreaPresets: res.geoAreaPresets.map(getId)
})

export const tailoredProductParse = ({
  customers,
  organizations
}: APITailoredProduct): TailoredProduct => ({
  organizations: organizations.map(getId),
  organizationsFull: organizations,
  customers: customers.map(getId),
  customersFull: customers
})

export const mainProductParse = ({
  highlights,
  suppliers,
  thenClauseLinkedRules,
  ifClauseLinkedRules,
  customers,
  organizations,
  advanceReservationMin: _advanceReservationMin,
  ...res
}: APIMainProduct): MainProduct => ({
  ...res,
  ...productCommonParse(res),
  ...tailoredProductParse({ customers, organizations }),
  sections: res.sections.map(getId),
  highlights,
  suppliers,
  ifClauseLinkedRules,
  thenClauseLinkedRules
})
