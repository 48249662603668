import { CreateBase, EditBase, useExpanded, useListContext } from 'react-admin'
import { KSimpleForm } from 'components/Forms/KForms/KSimpleForm'
import EditableDatagridToolbar from 'components/Lists/EditableList/EditableDatagridToolbar'
import React from 'react'
import { WithId } from '@kampaay/common'
import { useKResourceContext } from 'hooks/useKResourceContext'

type EditableListDynamicFormProps = {
  record?: WithId
  formContent: JSX.Element
  setCreateVisibility: (value: boolean) => void
  allowDelete: boolean
}

/**
 * This component shows a Create or Edit component with the form inside
 * depending on the fact if the record contains the id
 * @param record - the record taken from the list
 * @param formContent - the content that will be placed in the form
 * @param setCreateVisibility - function that places first row of the list as empty element creation
 * @param allowDelete - if the delete action should be allowed
 */
export const EditableListDynamicForm: React.FC<
  EditableListDynamicFormProps
> = ({ record, formContent, setCreateVisibility, allowDelete }) => {
  const form = (
    <KSimpleForm
      record={record}
      toolbar={
        <EditableDatagridToolbar
          id={record?.id}
          setCreateVisibility={setCreateVisibility}
          allowDelete={allowDelete}
        />
      }
    >
      {formContent}
    </KSimpleForm>
  )

  const resource = useKResourceContext()

  // This handles the expansion panel collapse when the form is submitted or closed
  const [_, setExpanded] = useExpanded(resource, record?.id ?? 'row0')

  const { refetch } = useListContext()

  const mutationOptions = {
    onSuccess: () => {
      // This is essential when you save a new record in order
      // to let to the list know that it needs to refetch the data
      refetch()

      // Close expansion panel when form is submitted
      setExpanded()

      if (!record?.id) {
        // Hides the empty row at the beginning of the list used to create a new record
        setCreateVisibility(false)
      }
    }
  }

  return record?.id ? (
    <EditBase
      mutationMode="pessimistic"
      id={record?.id}
      mutationOptions={mutationOptions}
    >
      {form}
    </EditBase>
  ) : (
    <CreateBase redirect={false} mutationOptions={mutationOptions}>
      {form}
    </CreateBase>
  )
}
