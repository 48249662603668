import React, { PropsWithChildren, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { ExpandMore } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Badge
} from '@mui/material'
import KHeader, { HeadingLevel } from 'components/UI/KHeader'
import ErrorIcon from '@mui/icons-material/Error'
import { WithStyle } from 'types/ui'
import { WithDataTestId } from 'test/utils'
import get from 'lodash/get'
import {
  KAccordionContextProvider,
  KAccordionContextType
} from 'components/UI/Accordions/KAccordion/KAccordionContext'

export type KAccordionProps = PropsWithChildren &
  WithStyle &
  WithDataTestId &
  Pick<
    AccordionProps,
    | 'defaultExpanded'
    | 'elevation'
    | 'className'
    | 'sx'
    | 'disableGutters'
    | 'square'
  > & {
    accordionTitle: string | React.ReactElement
    shouldDisplayAlertIcon?: boolean
    asideContent?: React.ReactElement
    accordionHeadingLevel?: HeadingLevel
    errorsDependencies?: string[] | undefined
    source?: string
    expandedIcon?: React.ReactNode
    collapsedIcon?: React.ReactNode
    titleIcon?: React.ReactNode
  }

/**
 * This component is a wrapper of MUI Accordion
 * @see https://mui.com/material-ui/react-accordion/
 *
 * @param accordionTitle The title of the accordion
 * @param shouldDisplayAlertIcon If true, an alert icon will be displayed in the header
 * @param defaultExpanded If true, the accordion will be expanded by default
 * @param asideContent Content to be displayed on the right side of the header
 * @param accordionHeadingLevel The dimension of the header (h1, h2, h3, h4, h5, h6)
 * @param children The content of the accordion
 * @param errorsDependencies Array of sources contained in the accordion, if one of these contains an error, the accordion will be expanded
 * @param style
 * @param dataTestId
 * @param source
 * @param expandedIcon
 * @param collapsedIcon
 * @param titleIcon
 */
const KAccordion: React.FC<KAccordionProps> = ({
  accordionTitle,
  shouldDisplayAlertIcon,
  defaultExpanded = false,
  asideContent,
  accordionHeadingLevel = 'h2',
  children,
  errorsDependencies,
  style,
  source,
  'data-testid': dataTestId = 'k-accordion',
  expandedIcon = <ExpandMore />,
  collapsedIcon,
  titleIcon,
  ...restProps
}) => {
  const [expanded, setExpanded] = useState(defaultExpanded)

  const context: KAccordionContextType = {
    toggleExpanded: () => setExpanded((prev) => !prev)
  }

  const formContext = useFormContext()
  const errors = formContext?.formState?.errors ?? {}

  errorsDependencies?.forEach((errorsDependency) => {
    const error = errorsDependency ? !!get(errors, errorsDependency) : undefined
    if (error && !expanded) setExpanded(true)
  })

  const hideAlert = !get(errors, source!) && !shouldDisplayAlertIcon

  const isReactElement = React.isValidElement(accordionTitle)
  const computedExpandIcon = useMemo(() => {
    if (!!collapsedIcon) {
      return expanded ? collapsedIcon : expandedIcon
    }
    return null
  }, [collapsedIcon, expandedIcon, expanded])

  return (
    <Accordion
      {...restProps}
      // Since when going from list to detail view, RA performs optimistic rendering with the list response record,
      // the default condition will not always be initialized as this component first gets mounted.
      // Thus, we need to force its re-render via the key attribute, whenever the default condition changes.
      key={`${defaultExpanded}`}
      style={{ width: '100%', ...style }}
      defaultExpanded={defaultExpanded}
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
      data-testid={dataTestId}
    >
      <AccordionSummary expandIcon={computedExpandIcon}>
        {' '}
        <Badge
          invisible={hideAlert}
          badgeContent={<ErrorIcon color="error" fontSize="small" />}
        >
          {isReactElement ? (
            accordionTitle
          ) : (
            <div className="flex items-center gap-1">
              {typeof accordionTitle === 'string' ? (
                <KHeader
                  title={accordionTitle}
                  headingLevel={accordionHeadingLevel}
                />
              ) : (
                accordionTitle
              )}
              {titleIcon}
            </div>
          )}
        </Badge>
        {!!asideContent && asideContent}
      </AccordionSummary>
      <AccordionDetails style={{ flexDirection: 'column' }}>
        <KAccordionContextProvider value={context}>
          {children}
        </KAccordionContextProvider>
      </AccordionDetails>
    </Accordion>
  )
}

export default KAccordion
