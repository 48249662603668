import { useForm } from 'hooks/useForm'
import React from 'react'
import { SimpleFormIterator } from 'react-admin'
import { IWithCustomizations } from 'services/api/entities/events/types/internal/event-models'
import { RAFormFC } from 'types/common'
import TransactionCustomizationInput from 'components/FormComponents/Transaction/TransactionCustomizationsInput'
import KArrayInput from 'components/Inputs/KInputs/KArrayInput'

const TransactionCustomizationArrayInput: RAFormFC = ({ source }) => {
  const { getSource } = useForm<IWithCustomizations>(source)

  return (
    <KArrayInput source={getSource('customizations')}>
      <SimpleFormIterator disableAdd disableRemove disableReordering inline>
        <TransactionCustomizationInput />
      </SimpleFormIterator>
    </KArrayInput>
  )
}

export default TransactionCustomizationArrayInput
