import React from 'react'
import SectionWrapper from 'components/UI/SectionWrapper'
import RuleFormContent from 'components/FormComponents/Rules/RuleFormContent'
import { KSimpleForm } from 'components/Forms/KForms/KSimpleForm'
import KEdit from 'components/Forms/KEdit/KEdit'

const RuleEdit: React.FC = () => {
  return (
    <SectionWrapper>
      <KEdit>
        <KSimpleForm>
          <RuleFormContent />
        </KSimpleForm>
      </KEdit>
    </SectionWrapper>
  )
}

export default RuleEdit
