import {
  organizationBudgetParse,
  organizationBudgetWrite
} from '@kampaay/common'
import { APICfg } from 'services/api/api'

const apiCfg = {
  endpoint: 'organizationbudget',
  parse: organizationBudgetParse,
  write: organizationBudgetWrite
} as APICfg

export default apiCfg
