import React from 'react'
import { RAFormFC } from 'types/common'
import StandardPricingInput from 'components/FormComponents/pricing/standard/StandardPricingInput'
import { useForm } from 'hooks/useForm'
import KDivider from 'components/UI/KDivider'
import { APIEntity } from 'services/api/entities'
import { OptionedTailoring, WithIdAndName } from '@kampaay/common'

type ProductCommonTailoringInputProps = {
  relatedTailoredProducts?: WithIdAndName[]
  productEntity: APIEntity
}

const ProductCommonTailoringInput: RAFormFC<
  ProductCommonTailoringInputProps
> = ({ source, productEntity }) => {
  const { getSource, useGetValue } = useForm<OptionedTailoring>(source)
  const hasCustomPrice = useGetValue('hasCustomPrice')

  const hideVariablePrice = productEntity === 'logistics'
  const hideFixedPrice = productEntity === 'musics' || productEntity === 'rents'

  ///////////////////////////
  // JSX
  ///////////////////////////
  return (
    <>
      {hasCustomPrice ? (
        <>
          <KDivider />
          <StandardPricingInput
            source={getSource('pricing')}
            hideVariablePrice={hideVariablePrice}
            hideFixedPrice={hideFixedPrice}
            data-testid={'product-common-tailoring--standard-pricing-input'}
          />
        </>
      ) : null}
    </>
  )
}

export default ProductCommonTailoringInput
