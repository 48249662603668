import React from 'react'
import StandardPricingInput from 'components/FormComponents/pricing/standard/StandardPricingInput'
import LinkedRulesField from 'components/Fields/LinkedRulesField'
import MainProductFormContent, {
  MainProductFormContentProps
} from 'components/Forms/MainProductFormContent'
import SuppliersAutocompleteArrayInput from 'components/Inputs/SuppliersAutocompleteArrayInput'

export type BaseProductFormContentProps = MainProductFormContentProps

const BaseProductFormContent: React.FC<BaseProductFormContentProps> = (
  props
) => {
  return (
    <MainProductFormContent {...props}>
      <SuppliersAutocompleteArrayInput />
      <StandardPricingInput hideFixedPrice />
      <LinkedRulesField />
    </MainProductFormContent>
  )
}

export default BaseProductFormContent
