import React from 'react'
import KRichTextInput, {
  KRichTextInputProps
} from 'components/Inputs/KInputs/KRichTextInput'
import LocalizedInput from 'components/Inputs/Localized/LocalizedInput'

const LocalizedRichTextInput: React.FC<KRichTextInputProps> = ({
  source,
  label,
  ...rest
}) => {
  return (
    <LocalizedInput source={source}>
      <KRichTextInput {...rest} source={source} label={label} />
    </LocalizedInput>
  )
}

export default LocalizedRichTextInput
