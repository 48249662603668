import React, { useCallback } from 'react'
import {
  Button,
  Datagrid,
  FunctionField,
  Identifier,
  TextField,
  useCreatePath,
  useTranslate
} from 'react-admin'
import { RaRecord } from 'ra-core'
import { useParams } from 'react-router-dom'
import { openInternalLinkInNewWindow } from 'services/utils/navigationUtils'
import { getOrganizationMembershipRoleName } from 'services/cfg/form'
import { OrganizationMembership } from '@kampaay/common'

type Props = {
  bulkActions?: boolean
  data?: OrganizationMembership[]
  removeMembers?: () => void
}

const OrganizationSubGroupDatagrid: React.FC<Props> = ({
  bulkActions,
  ...props
}) => {
  const createPath = useCreatePath()
  const { id: organizationId } = useParams()
  const translate = useTranslate()

  const openMemberEditPage = useCallback(
    (_id: Identifier, _resource: string, record: RaRecord) => {
      const membership = record as OrganizationMembership
      const path = createPath({
        resource: `organization/${organizationId}/members`,
        id: membership.id,
        type: 'edit'
      })

      openInternalLinkInNewWindow(path)

      return ''
    },
    []
  )

  return (
    <Datagrid
      {...props}
      bulkActionButtons={
        bulkActions ? (
          <Button label="ra.action.remove" onClick={props.removeMembers} />
        ) : (
          false
        )
      }
      rowClick={openMemberEditPage}
      sort={{ field: 'id', order: 'ASC' }}
      empty={
        <em className="opacity-50">
          {'(' + translate('form.fields.budget.noResults') + ')'}
        </em>
      }
    >
      <TextField source="userName" label="form.fields.name" sortable={false} />
      <TextField
        source="userEmail"
        label="form.fields.email"
        sortable={false}
      />
      <FunctionField
        label="form.fields.role"
        sortable={false}
        render={(record?: OrganizationMembership) =>
          record ? getOrganizationMembershipRoleName(record.role) : undefined
        }
      />
    </Datagrid>
  )
}

export default OrganizationSubGroupDatagrid
