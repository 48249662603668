import { styled } from '@mui/material/styles'
import React from 'react'
import { AppBar, UserMenu, useTranslate } from 'react-admin'
import { ReactComponent as Logo } from 'assets/logo.svg'
import LanguageToggler from 'layout/config/LanguageToggler'
import { Button } from '@mui/material'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { ConnectGmailButton } from 'components/Buttons/ConnectGmailButton'
import EngineeringPanel from 'components/EngineeringPanel/EngineeringPanel'

const PREFIX = 'CustomAppBar'

const classes = {
  title: `${PREFIX}-title`
}

const StyledAppBar = styled(AppBar)({
  [`& .${classes.title}`]: { flex: 1 }
})

// const ConfigurationMenu = forwardRef((props:MenuItemLinkProps, ref:any) => {
//   const translate = useTranslate()
//   return (
//     <MenuItemLink
//       ref={ref}
//       primaryText={translate('pos.settings')}
//       leftIcon={<SettingsIcon />}
//       {...props}
//       to="/settings"
//     />
//   )
// })

const CustomUserMenu = () => <UserMenu>{/* <ConfigurationMenu /> */}</UserMenu>

const KAppBar = (props: any) => {
  const translate = useTranslate()

  return (
    <StyledAppBar {...props} color="primary" userMenu={<CustomUserMenu />}>
      <Logo className={classes.title} />
      <ConnectGmailButton className="mr-2" />
      <Button
        startIcon={<HelpOutlineIcon />}
        color="inherit"
        variant="text"
        sx={{
          textTransform: 'none'
        }}
        href={translate('appbar.docsLink')}
        target="_blank"
      >
        {translate('appbar.help')}
      </Button>
      <LanguageToggler />
      <EngineeringPanel />
    </StyledAppBar>
  )
}

export default KAppBar
// link example to react-admin/layout/AppBar.tsx https://github.com/marmelab/react-admin/blob/master/examples/demo/ layout/AppBar.tsx
