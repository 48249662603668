import React from 'react'
import { translate } from 'services/i18n'
import KToolTip from 'components/UI/KTooltip'
import FlexBox from 'components/UI/layout/FlexBox'
import { InfoOutlined } from '@mui/icons-material'
import { TooltipProps } from '@mui/material'
import { WithDataTestId } from 'test/utils'

type WhyThisProps = WithDataTestId & {
  text: string
  placement: TooltipProps['placement']
  show?: boolean
  alwaysShow?: boolean
  iconOnly?: boolean
}

/**
 * This is a component to display an info icon with a tooltip under certain conditions.
 * The icon will always be an info icon with the i18n label "whyThis".
 * @param text The text of the tooltip
 * @param placement the placement of the tooltip
 * @param show the condition under which the tooltip activator will be displayed
 * @param showAlways if true, the tooltip activator will always be displayed
 *
 */
const WhyThis: React.FC<WhyThisProps> = ({
  show,
  alwaysShow,
  text,
  placement,
  iconOnly,
  'data-testid': dataTestid
}) => {
  return (
    <>
      {(alwaysShow || show) && (
        <KToolTip data-testid={dataTestid} title={text} placement={placement}>
          <FlexBox
            className="cursor-pointer text-secondaryAccent"
            alignContent={'center'}
            alignItems={'center'}
          >
            <InfoOutlined className="mr-2" />
            {iconOnly || <span>{translate('common.label.whyThis')}</span>}
          </FlexBox>
        </KToolTip>
      )}
    </>
  )
}

export default WhyThis
