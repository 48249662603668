import React from 'react'
import {
  BulkExportButton,
  BulkExportButtonProps,
  Exporter,
  useListContext
} from 'react-admin'
import { exportFormatter } from 'services/csv/exporter'

type KBulkExportButtonProps = Pick<BulkExportButtonProps, 'selectedIds'>

/**
 * Wrapper of react-admins BulkExportButton.
 * @param selectedIds the selected ids of the list to export
 */
const KBulkExportButton: React.FC<KBulkExportButtonProps> = ({}) => {
  const { selectedIds } = useListContext()
  return (
    <BulkExportButton
      selectedIds={selectedIds}
      exporter={exportFormatter() as Exporter}
    />
  )
}
export default KBulkExportButton
