import React from 'react'
import { UpsellingEntity } from 'services/api/entities'
import CustomReferenceInput from 'components/Inputs/CustomReferenceInput'
import LocalizedSelectArrayInput from 'components/Inputs/Localized/LocalizedSelectArrayInput'
import { useForm } from 'hooks/useForm/useForm'
import { RAFormFC } from 'types/common'
import { ProductWithUpselling } from '@kampaay/common'

export type UpsellingSelectorProps = {
  upsellingEntity: UpsellingEntity
}

const UpsellingSelector: RAFormFC<UpsellingSelectorProps> = ({
  upsellingEntity,
  source
}) => {
  const { getSource } = useForm<ProductWithUpselling>(source)
  return (
    <CustomReferenceInput entity={upsellingEntity}>
      <LocalizedSelectArrayInput
        source={getSource('upsellingItems')}
        label="form.fields.upsellingItems"
        fullWidth
      />
    </CustomReferenceInput>
  )
}

export default UpsellingSelector
