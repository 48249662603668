import { buildFilterConfig } from 'services/utils/filters'
import { FILTER_SOURCES } from '@kampaay/common'
import { GEO_AREA_FILTER_CFG } from 'services/filters/entities/geoareaFilter'

export const TAILORED_FILTERS = [
  buildFilterConfig('clientFilter', {
    source: FILTER_SOURCES.customerId.eq,
    label: 'form.fields.onlyForClient',
    alwaysOn: false
  }),
  buildFilterConfig('organizationFilter', {
    source: FILTER_SOURCES.organizationId.eq,
    label: 'form.fields.onlyForOrganization',
    alwaysOn: false
  }),
  buildFilterConfig('visibleToAll', {
    source: FILTER_SOURCES.isPublic.eq,
    label: 'form.fields.visibleToAll',
    alwaysOn: true
  }),
  buildFilterConfig('text', {
    source: FILTER_SOURCES.name.contains,
    label: 'form.fields.name',
    alwaysOn: true
  }),
  GEO_AREA_FILTER_CFG
]
