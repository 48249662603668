import React from 'react'
import UpsellingHintInput from 'resources/catalog/food/upselling/UpsellingHintInput'
import { APIEntity, ProductWithUpsellingEntity } from 'services/api/entities'
import { useKGetList } from 'hooks/useKGetList'
import StandardPricingInput from 'components/FormComponents/pricing/standard/StandardPricingInput'
import LocalizedAutoCompleteArrayInput from 'components/Inputs/Localized/LocalizedAutoCompleteArrayInput'
import KNumberInput from 'components/Inputs/KInputs/KNumberInput/KNumberInput'
import { CatalogItemBaseFormContentProps } from 'components/Forms/CatalogItemBaseFormContent'
import ProductCommonFormContent from 'components/Forms/ProductCommonFormContent'

type Props = CatalogItemBaseFormContentProps & {
  relatedProduct: Extract<APIEntity, ProductWithUpsellingEntity>
}

const UpsellingFormContent: React.FC<Props> = ({
  children,
  relatedProduct,
  ...props
}) => {
  return (
    <ProductCommonFormContent {...props}>
      <KNumberInput
        source="minQuantity"
        label="Minimum quantity"
        defaultValue={1}
      />

      <LocalizedAutoCompleteArrayInput
        source="products"
        choices={useKGetList(relatedProduct).list}
        fullWidth
        label="form.fields.products"
      />
      {children}
      <UpsellingHintInput />
      <StandardPricingInput />
    </ProductCommonFormContent>
  )
}

export default UpsellingFormContent
