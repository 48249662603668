export const formatOrdinals = (n: number) => {
  const pluralRules = new Intl.PluralRules('en-US', { type: 'ordinal' })

  const suffixes = new Map([
    ['one', 'st'],
    ['two', 'nd'],
    ['few', 'rd'],
    ['other', 'th']
  ])

  const rule = pluralRules.select(n)
  const suffix = suffixes.get(rule)
  return `${n}${suffix}`
}

const removeHtmlTags = (str: string) => {
  return str.replace(/<[^>]+>/gi, '')
}

export const stringIsEmpty = (str: string) => {
  return !str.trim()
}

export const isRichTextEmpty = (str: string) => {
  return stringIsEmpty(removeHtmlTags(str))
}

export const camelToSentence = (str: string) =>
  str.replace(/(^\w|(?<=[a-z])[A-Z])/g, (m) => ` ${m.toUpperCase()}`).trim()

export const isMultilangString = (str: string | undefined | null) => {
  const multilangStringRegex = /^\w+(\.\w+)+$/g
  return str ? multilangStringRegex.test(str) : false
}

export const removeQueryString = (inputString: string) => {
  return inputString.replace(/\?.*$/, '')
}
