import { UseValidationSchema } from 'services/yup/types'
import { yupArray, yupIgnore } from 'services/yup'
import { useOptionedProductVS } from 'services/validations/catalog/optionedProduct'
import { useKGetList } from 'hooks/useKGetList'
import { hasConsistentUpsellings } from 'services/validations/catalog/food/food.validations'
import { buildGeoareaPresetValidation } from 'services/validations/common'
import { APIPriceRange, Food, FoodUpselling, Logistic } from '@kampaay/common'

export const useFoodVS: UseValidationSchema<
  Food,
  APIPriceRange[] | undefined
> = () => {
  const { list: upsellingItemsFull } =
    useKGetList<FoodUpselling>('foodsupselling')
  const { list: logisticsFull } = useKGetList<Logistic>('logistics')

  const geoAreaPresetsTest = buildGeoareaPresetValidation<Food>(
    {
      key: 'upsellingItems',
      list: upsellingItemsFull
    },
    {
      key: 'logistics',
      list: logisticsFull
    }
  )

  return useOptionedProductVS().shape({
    logistics: yupArray().min(1),
    upsellingItems: yupArray().test(
      hasConsistentUpsellings(upsellingItemsFull)
    ),
    upsellingItemsFull: yupArray(),
    logisticsFull: yupArray(),
    tagFacets: yupIgnore(),
    filterTypeFacet: yupIgnore(),
    geoAreaPresets: yupArray().min(1).test(geoAreaPresetsTest)
  })
}
