import { KTestConfig, KTestFunction } from 'services/yup'
import { include } from 'resources/operations/events/utils'
import { isIntersecting } from 'services/utils/array'
import { Food, FoodUpselling } from '@kampaay/common'

/**
 * this function test
 * the compatibility of the food logistics with the upselling logistics
 *
 * Rule:
 * We have the following product with the associated upselling:
 *
 * Product: Logistics [4,3]
 * Upselling1: Logistics[1,3,4,5]
 * Upselling2: Logistics[1,4]
 * Upselling3: Logistics[4,5]
 * Upsellgin4: Logistics[3,4]
 *
 * Then
 *
 * Upselling: Logistics[1,3,5] --> Not Valid since there is no 1 common logistic with upselling
 * Upselling: Logistics[4] --> Valid --> 4 is a logistic common to all 4 upselling and the product
 *
 */
export const validateConsistentFoodLogistics =
  (
    foods: Pick<Food, 'id' | 'logistics'>[]
  ): KTestFunction<number[], Pick<FoodUpselling, 'logistics'>> =>
  (
    selectedProductsIds = [],
    { parent: { logistics: selectedLogisticsIds } }
  ) => {
    // for the current upselling
    //
    //   if (there are no logistics)
    //   (although an upselling must have at least one logistic),
    //   let the test pass, and let the user carry on anyway
    if (selectedLogisticsIds.length <= 0) return true

    //   if (there are no products selected)
    //   let the test pass, and let the user carry on anyway
    if (selectedProductsIds.length <= 0) return true

    // current upselling logistics

    // get selected food logistics
    const selectedFoodsFull = include(selectedProductsIds, foods)
    const foodLogistics: number[][] = selectedFoodsFull.map(
      (food) => food.logistics
    )

    // find the intersection among
    // - current upselling logistics,
    // - food logistics
    return isIntersecting(selectedLogisticsIds, ...foodLogistics)
  }

export const hasConsistentFoods = (
  foods: Pick<Food, 'id' | 'logistics'>[]
): KTestConfig<number[]> => ({
  test: validateConsistentFoodLogistics(foods),
  message: 'form.validations.cannotAssociateIncompatibleUpsellings'
})
