import React from 'react'
import KChip from 'components/UI/KChip'
import { useKRecordContext } from 'hooks/useKRecordContext'
import {
  EventPlaceholder,
  EventPlaceholderStatus
} from 'services/api/entities/event-placeholder/types/internal/event-placeholder-models'
import { SupportedColor } from 'layout/config/themes'
import { useTranslate } from 'react-admin'

const CHIP_CONFIG_MAP: Record<
  EventPlaceholderStatus,
  { label: string; color: SupportedColor }
> = {
  confirmed: {
    label: 'org.eventPlaceholders.filterLabels.confirmed',
    color: 'success'
  },
  pending: {
    label: 'org.eventPlaceholders.filterLabels.pending',
    color: 'warning'
  },
  noEvents: {
    label: 'org.eventPlaceholders.filterLabels.noEvents',
    color: 'neutral-lighten-3'
  }
}

const EventPlaceholderStatusChip = () => {
  const record = useKRecordContext<EventPlaceholder>()
  const translate = useTranslate()

  if (!record) {
    return null
  }

  return (
    <KChip
      label={translate(CHIP_CONFIG_MAP[record.status].label)}
      customBackgroundColor={CHIP_CONFIG_MAP[record.status].color}
    />
  )
}

export default EventPlaceholderStatusChip
