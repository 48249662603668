import { APICfg } from 'services/api/api'
import { parseArea, writeArea } from '@kampaay/common'

const apiCfg: APICfg = {
  endpoint: 'area',
  parse: parseArea,
  write: writeArea
}

export default apiCfg
