import React from 'react'
import { getStandardPricingStartup } from 'services/cfg/form'
import StandardPricingInput from 'components/FormComponents/pricing/standard/StandardPricingInput'
import { KBooleanInput } from 'components/Inputs/KInputs/KBooleanInput'
import { useForm } from 'hooks/useForm'
import LocalizedTextInput from 'components/Inputs/Localized/LocalizedTextInput'
import LocalizedRichTextInput from 'components/Inputs/Localized/LocalizedRichTextInput'
import ExclusiveBoolean from 'components/Inputs/ExclusiveBoolean'
import KDivider from 'components/UI/KDivider'
import KImageInput from 'components/Inputs/KImageInput'
import { RAFormFC } from 'types/common'
import { OptionItem } from '@kampaay/common'

type CatalogOptionsInputProps = {
  isDefaultDependentSources: string[]
}

const CatalogOptionsInput: RAFormFC<CatalogOptionsInputProps> = ({
  source,
  isDefaultDependentSources
}) => {
  const { useGetValue, getSource, useSetValue } = useForm<OptionItem>(source!)

  const isPriced = useGetValue('isPriced')
  const pricing = useGetValue('pricing')
  const setPricing = useSetValue('pricing')

  if (isPriced && !pricing?.length) {
    setPricing(getStandardPricingStartup())
  }

  return (
    <>
      <LocalizedTextInput
        source={getSource('name')}
        label="form.fields.name"
        fullWidth
      />

      <LocalizedRichTextInput
        source={getSource('description')}
        label="form.fields.description"
        fullWidth
      />

      <ExclusiveBoolean
        source={getSource('isDefault')}
        label="form.fields.isDefault"
        dependentFieldsSources={isDefaultDependentSources}
        defaultValue={false}
      />

      <KBooleanInput
        source={getSource('isPriced')}
        label="form.fields.pricing"
      />
      {isPriced && <StandardPricingInput source={getSource('pricing')} />}

      <KDivider />

      <KImageInput source={getSource('image')} />
    </>
  )
}

export default CatalogOptionsInput
