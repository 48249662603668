import { CountryCode, CustomerBase } from '@kampaay/common'
import { yupIgnore, yupObject } from 'services/yup'
import { yupString } from 'services/yup/custom-schemas/string'
import { YupSchema } from 'services/yup/types'

export const customerBaseVS: YupSchema<CustomerBase> = yupObject().shape({
  id: yupIgnore(),
  country: yupString<CountryCode>().required(),
  name: yupString().required(),
  responsibleFullName: yupString().required(),
  headQuarters: yupString(),
  organizationNumber: yupString(),
  responsibleEmail: yupString().email(),
  vat: yupString()
})
