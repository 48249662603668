import { APICfg } from 'services/api/api'
import { convertEmptyStringToUndefined } from 'services/utils'
import {
  APICustomer,
  Customer,
  CustomerBase,
  CustomerITA,
  CustomerITAToAPI,
  countrySwitch,
  customerUserParse
} from '@kampaay/common'

////////////////////////////////
// CFG
////////////////////////////////

export const baseCustomerWrite = <T extends CustomerBase>({
  responsibleEmail,
  headQuarters,
  ...rest
}: T) => ({
  ...rest,
  responsibleEmail: convertEmptyStringToUndefined(responsibleEmail),
  headQuarters: convertEmptyStringToUndefined(headQuarters)
})

export const customerUserWrite = async ({
  address,
  createdAt,
  lastLogin,
  organizationNumber,
  ...rest
}: Customer): Promise<APICustomer> => ({
  ...baseCustomerWrite(rest),
  address: convertEmptyStringToUndefined(address),
  organizationNumber: convertEmptyStringToUndefined(organizationNumber),
  createdAt,
  lastLogin
})

export const customerITAWrite = async ({
  pec,
  sdi,
  vat,
  ...customer
}: CustomerITA): Promise<CustomerITAToAPI> => ({
  ...(await customerUserWrite(customer)),
  pec: convertEmptyStringToUndefined(pec),
  sdi: convertEmptyStringToUndefined(sdi),
  vat: convertEmptyStringToUndefined(vat)
})

const write = countrySwitch(customerUserWrite, {
  IT: customerITAWrite
})

const apiCfg: APICfg = {
  endpoint: 'usercustomer',
  parse: customerUserParse,
  write
}

export default apiCfg
