import React, { useState } from 'react'
import {
  DashboardMenuItem,
  Menu,
  MenuProps,
  useSidebarState
} from 'react-admin'
import SubMenu from 'layout/SubMenu'
import BackupTableIcon from '@mui/icons-material/BackupTable'
import KampaaySharedMenu from 'layout/sharedMenuItems'

// operations services
import operations from 'resources/operations'
import eventsDraft from 'resources/operations/eventsDraft'

// accounting services
import accounting from 'resources/accounting'
import transaction from 'resources/accounting/transaction'

// crm services
import crm from 'resources/crm'
import clients from 'resources/crm/clients'
import customers from 'resources/crm/clients/customers'
import kampaayers from 'resources/crm/kampaayers'
import organization from 'resources/crm/clients/organization'

// catalog services
import catalog from 'resources/catalog'
import location from 'resources/catalog/location/product'
import locationUpselling from 'resources/catalog/location/upselling'
import locationfeatures from 'resources/catalog/location/features'
import locationSections from 'resources/catalog/location/sections'
import drink from 'resources/catalog/drink'
import drinkSections from 'resources/catalog/drink/sections'
import foodSections from 'resources/catalog/food/sections'
import food from 'resources/catalog/food/product'
import foodupselling from 'resources/catalog/food/upselling'
import music from 'resources/catalog/music/product'
import musicSections from 'resources/catalog/music/sections'
import rent from 'resources/catalog/rent/product'
import rentSections from 'resources/catalog/rent/sections'
import virtualexperiences from 'resources/catalog/virtualexperiences/product'
import virtualexperienceSections from 'resources/catalog/virtualexperiences/sections'
import virtualexperiencesupselling from 'resources/catalog/virtualexperiences/upselling'
import physicalexperience from 'resources/catalog/physicalexperiences/product'
import physicalexperienceSections from 'resources/catalog/physicalexperiences/sections'
import physicalexperienceUpselling from 'resources/catalog/physicalexperiences/upselling'
import professionalSections from 'resources/catalog/professionals/sections'
import professionalFigures from 'resources/catalog/professionals/p-figures'
import area from 'resources/area'
import mailingList from 'resources/mailing-list'
import {
  Place as PlaceIcon,
  TipsAndUpdates,
  TypeSpecimen as TypeSpecimenIcon
} from '@mui/icons-material'

// facets
import facets from 'resources/facets'
import rule from 'resources/catalog/rule'
import servicesconfig from 'resources/services-config'
import logistics from 'resources/catalog/logistics'

// i18n
import i18nManager from 'resources/i18n-manager'
import { PocMenu } from 'poc'
import { ManageAccounts } from '@mui/icons-material'
import KToolTip from 'components/UI/KTooltip'
import { useLoggedUser } from 'contexts/loggedUserContext/useLoggedUser'
import { navigate } from 'services/utils/navigationUtils'
import { isFeatureFlagEnabled } from '@kampaay/common'

const RAMenu = ({ onMenuClick, dense }: MenuProps) => {
  const [state, setState] = useState<Record<string, boolean>>({
    menuOperations: false,
    menuAccounting: false,
    menuCrm: false,
    menuClients: false,
    menuCatalog: false,
    menuDrink: false,
    menuFood: false,
    menuVirtual: false,
    menuProfessional: false,
    menuMusic: false,
    menuRent: false,
    menuLocation: false,
    menuPhysical: false,
    menuSettings: false,
    menuI18n: false
  })

  const [open] = useSidebarState()
  const { loggedUserRole } = useLoggedUser()
  const loggedUserIsAdminOrEventExcellence =
    loggedUserRole === 'admin' || loggedUserRole === 'eventExcellence'

  const handleToggle = (menu: string) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }))
  }

  return (
    <Menu
      sx={{
        '& .RaMenuItemLink-active': {
          fontWeight: 'bold',
          color: 'primary.main',

          '.RaMenuItemLink-icon': {
            color: 'primary.main'
          }
        }
      }}
    >
      <DashboardMenuItem
        sidebarIsOpen={open}
        onClick={(e) => {
          e.preventDefault()
          navigate('', 'vue-app')
        }}
      />
      {(isFeatureFlagEnabled('timelog') ||
        loggedUserIsAdminOrEventExcellence) && (
        <div
          className="mb-8"
          // using style because tw border bottom classes are not working
          style={{
            borderBottom: '1px solid #e0e0e0'
          }}
        >
          <KampaaySharedMenu.EventsMenuItem
            onClick={onMenuClick}
            dense={dense}
          />
          <KampaaySharedMenu.ConciergeMenuItem
            onClick={onMenuClick}
            dense={dense}
            color="primary"
          />
          <KampaaySharedMenu.ProposalMenuItem
            onClick={onMenuClick}
            dense={dense}
          />
          <KampaaySharedMenu.SuppliersMenuItem
            onClick={onMenuClick}
            dense={dense}
          />

          {(isFeatureFlagEnabled('timelog') ||
            loggedUserIsAdminOrEventExcellence) && (
            <Menu.Item
              to="/timelogs"
              primaryText="resources.timeLog.name"
              leftIcon={<BackupTableIcon />}
              onClick={(e) => {
                e.preventDefault()
                navigate('timelogs')
              }}
              dense={dense}
            />
          )}
        </div>
      )}

      <SubMenu
        handleToggle={() => handleToggle('menuOperations')}
        isOpen={state.menuOperations}
        sidebarIsOpen={open}
        name="pos.menu.operations"
        icon={<operations.icon />}
        dense={dense}
      >
        <KampaaySharedMenu.EventsMenuItem onClick={onMenuClick} dense={dense} />
        <KampaaySharedMenu.ConciergeMenuItem
          onClick={onMenuClick}
          dense={dense}
        />
        <Menu.Item
          to={`/eventsdraft`}
          primaryText="resources.events.draft"
          leftIcon={<eventsDraft.icon />}
          onClick={onMenuClick}
          dense={dense}
        />
        <KampaaySharedMenu.ProposalMenuItem
          onClick={onMenuClick}
          dense={dense}
        />
        <Menu.Item
          to={`/inspirations`}
          primaryText="resources.events.inspirations"
          leftIcon={<TipsAndUpdates />}
          onClick={(e) => {
            e.preventDefault()
            navigate('inspirations', 'vue-app')
          }}
          dense={dense}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle('menuAccounting')}
        isOpen={state.menuAccounting}
        sidebarIsOpen={open}
        name="pos.menu.accounting"
        icon={<accounting.icon />}
        dense={dense}
      >
        <Menu.Item
          to={`/transaction`}
          primaryText="resources.transaction.name"
          leftIcon={<transaction.icon />}
          onClick={onMenuClick}
          dense={dense}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle('menuCrm')}
        isOpen={state.menuCrm}
        sidebarIsOpen={open}
        name="pos.menu.crm"
        icon={<crm.icon />}
        dense={dense}
      >
        <SubMenu
          handleToggle={() => handleToggle('menuClients')}
          isOpen={state.menuClients}
          sidebarIsOpen={open}
          name="pos.menu.clients"
          icon={<clients.icon />}
          dense={dense}
        >
          <Menu.Item
            to={`/customers`}
            primaryText="resources.customers.pmi"
            leftIcon={<customers.icon />}
            onClick={onMenuClick}
            dense={dense}
          />

          <Menu.Item
            to={`/organization`}
            primaryText="resources.customers.organization"
            leftIcon={<organization.icon />}
            onClick={onMenuClick}
            dense={dense}
          />
        </SubMenu>

        <Menu.Item
          to={`/kampaayers`}
          primaryText="resources.kampaayers.name"
          leftIcon={<kampaayers.icon />}
          onClick={onMenuClick}
          dense={dense}
        />
        <KampaaySharedMenu.SuppliersMenuItem
          onClick={onMenuClick}
          dense={dense}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle('menuCatalog')}
        isOpen={state.menuCatalog}
        sidebarIsOpen={open}
        name="pos.menu.catalog"
        icon={<catalog.icon />}
        dense={dense}
      >
        <SubMenu
          handleToggle={() => handleToggle('menuDrink')}
          isOpen={state.menuDrink}
          sidebarIsOpen={open}
          name="pos.menu.drinks"
          icon={<drink.icon />}
          dense={dense}
        >
          <Menu.Item
            to={`/drinksections`}
            primaryText="resources.drinksections.name"
            leftIcon={<drinkSections.icon />}
            onClick={onMenuClick}
            dense={dense}
          />
          <Menu.Item
            to={`/drinks`}
            primaryText="pos.menu.drinks"
            leftIcon={<drink.icon />}
            onClick={onMenuClick}
            dense={dense}
          />
        </SubMenu>

        <SubMenu
          handleToggle={() => handleToggle('menuFood')}
          isOpen={state.menuFood}
          sidebarIsOpen={open}
          name="pos.menu.foods"
          icon={<food.icon />}
          dense={dense}
        >
          <Menu.Item
            to={`/foodsections`}
            primaryText="resources.foodsections.name"
            leftIcon={<foodSections.icon />}
            onClick={onMenuClick}
            dense={dense}
          />
          <Menu.Item
            to={`/foods`}
            primaryText="resources.foods.name"
            leftIcon={<food.icon />}
            onClick={onMenuClick}
            dense={dense}
          />
          <Menu.Item
            to={`/foodsupselling`}
            primaryText="resources.foodsupselling.name"
            leftIcon={<foodupselling.icon />}
            onClick={onMenuClick}
            dense={dense}
          />
          <Menu.Item
            to={`/logistics`}
            primaryText="resources.logistics.name"
            leftIcon={<logistics.icon />}
            onClick={onMenuClick}
            dense={dense}
          />
        </SubMenu>

        <SubMenu
          handleToggle={() => handleToggle('menuMusic')}
          isOpen={state.menuMusic}
          sidebarIsOpen={open}
          name="resources.musics.name"
          icon={<music.icon />}
          dense={dense}
        >
          <Menu.Item
            to={`/musicsections`}
            primaryText="resources.musicsections.name"
            leftIcon={<musicSections.icon />}
            onClick={onMenuClick}
            dense={dense}
          />
          <Menu.Item
            to={`/musics`}
            primaryText="resources.musics.name"
            leftIcon={<music.icon />}
            onClick={onMenuClick}
            dense={dense}
          />
        </SubMenu>

        {/* ------ */}

        <SubMenu
          handleToggle={() => handleToggle('menuRent')}
          isOpen={state.menuRent}
          sidebarIsOpen={open}
          name="pos.menu.rents"
          icon={<rent.icon />}
          dense={dense}
        >
          <Menu.Item
            to={`/rentsections`}
            primaryText="resources.rentsections.name"
            leftIcon={<rentSections.icon />}
            onClick={onMenuClick}
            dense={dense}
          />
          <Menu.Item
            to={`/rents`}
            primaryText="resources.rents.name"
            leftIcon={<rent.icon />}
            onClick={onMenuClick}
            dense={dense}
          />
        </SubMenu>
        {/* ------ */}

        {/* virtuals */}
        <SubMenu
          handleToggle={() => handleToggle('menuVirtual')}
          isOpen={state.menuVirtual}
          sidebarIsOpen={open}
          name="pos.menu.virtuals"
          icon={<virtualexperiences.icon />}
          dense={dense}
        >
          <Menu.Item
            to={`/virtualexperiencessections`}
            primaryText="resources.foodsections.name"
            leftIcon={<virtualexperienceSections.icon />}
            onClick={onMenuClick}
            dense={dense}
          />
          <Menu.Item
            to={`/virtuals`}
            primaryText="pos.menu.virtuals"
            leftIcon={<virtualexperiences.icon />}
            onClick={onMenuClick}
            dense={dense}
          />
          <Menu.Item
            to={`/virtualupselling`}
            primaryText="pos.menu.virtualsUpselling"
            leftIcon={<virtualexperiencesupselling.icon />}
            onClick={onMenuClick}
            dense={dense}
          />
        </SubMenu>
        {/* --------- */}

        {/* physicals */}
        <SubMenu
          handleToggle={() => handleToggle('menuPhysical')}
          isOpen={state.menuPhysical}
          sidebarIsOpen={open}
          name="pos.menu.physicals"
          icon={<physicalexperience.icon />}
          dense={dense}
        >
          <Menu.Item
            to={`/physicalsections`}
            primaryText="resources.physicalsections.name"
            leftIcon={<physicalexperienceSections.icon />}
            onClick={onMenuClick}
            dense={dense}
          />
          <Menu.Item
            to={`/physicals`}
            primaryText="pos.menu.physicals"
            leftIcon={<physicalexperience.icon />}
            onClick={onMenuClick}
            dense={dense}
          />
          <Menu.Item
            to={`/physicalupselling`}
            primaryText="pos.menu.physicalsUpselling"
            leftIcon={<physicalexperienceUpselling.icon />}
            onClick={onMenuClick}
            dense={dense}
          />
        </SubMenu>
        {/* ---------- */}
        <SubMenu
          handleToggle={() => handleToggle('menuProfessional')}
          isOpen={state.menuProfessional}
          sidebarIsOpen={open}
          name="pos.menu.professionals"
          icon={<professionalFigures.icon />}
          dense={dense}
        >
          <Menu.Item
            to={`/professionalsections`}
            primaryText="resources.professionalsections.name"
            leftIcon={<professionalSections.icon />}
            onClick={onMenuClick}
            dense={dense}
          />
          <Menu.Item
            to={`/professionals`}
            primaryText="resources.professionals.name"
            leftIcon={<professionalFigures.icon />}
            onClick={onMenuClick}
            dense={dense}
          />
        </SubMenu>
        {/* Location */}

        <SubMenu
          handleToggle={() => handleToggle('menuLocation')}
          isOpen={state.menuLocation}
          sidebarIsOpen={open}
          name="pos.menu.locations"
          icon={<location.icon />}
          dense={dense}
        >
          <Menu.Item
            to={`/locationsections`}
            primaryText="resources.locationsections.name"
            leftIcon={<locationSections.icon />}
            onClick={onMenuClick}
            dense={dense}
          />
          <Menu.Item
            to={`/locations`}
            primaryText="resources.locations.name"
            leftIcon={<location.icon />}
            onClick={onMenuClick}
            dense={dense}
          />
          <Menu.Item
            to={`/locationupselling`}
            primaryText="resources.locationupselling.name"
            leftIcon={<locationUpselling.icon />}
            onClick={onMenuClick}
            dense={dense}
          />
          <Menu.Item
            to={`/locationfeatures`}
            primaryText="resources.locations.features"
            leftIcon={<locationfeatures.icon />}
            onClick={onMenuClick}
            dense={dense}
          />
        </SubMenu>
        <Menu.Item
          to={`/rules`}
          primaryText="resources.rules.name"
          leftIcon={<rule.icon />}
          onClick={onMenuClick}
          dense={dense}
        />
      </SubMenu>

      <SubMenu
        handleToggle={() => handleToggle('menuSettings')}
        isOpen={state.menuSettings}
        sidebarIsOpen={open}
        name="pos.settings"
        icon={<ManageAccounts />}
        dense={dense}
      >
        <Menu.Item
          to={`/facets`}
          primaryText="resources.facet.name"
          leftIcon={<facets.icon />}
          onClick={onMenuClick}
          dense={dense}
        />

        <Menu.Item
          to="/suppliertype"
          primaryText="resources.supplierTypes.name"
          leftIcon={<TypeSpecimenIcon />}
          onClick={(e) => {
            e.preventDefault()
            navigate('suppliertype', 'vue-app')
          }}
          dense={dense}
        />
        <Menu.Item
          to="/servicesconfig"
          primaryText="resources.serviceConfig.name"
          leftIcon={<servicesconfig.icon />}
          onClick={onMenuClick}
          dense={dense}
        />
        <Menu.Item
          to="/geoareapresets"
          primaryText="resources.geoAreaPresets.name"
          leftIcon={<PlaceIcon />}
          onClick={(e) => {
            e.preventDefault()
            navigate('geoareapresets', 'vue-app')
          }}
          dense={dense}
        />
        <Menu.Item
          to="/area"
          primaryText="resources.area.name"
          leftIcon={<area.icon />}
          onClick={onMenuClick}
          dense={dense}
        />
        <Menu.Item
          to="/mailingList"
          primaryText="resources.mailingList.title"
          leftIcon={<mailingList.icon />}
          onClick={onMenuClick}
          dense={dense}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle('menuI18n')}
        isOpen={state.menuI18n}
        name="resources.i18nManager.name"
        icon={<i18nManager.icon />}
        sidebarIsOpen={open}
        dense={dense}
      >
        <Menu.Item
          to={`/i18n-panel-be`}
          primaryText="resources.i18nManager.be"
          leftIcon={<servicesconfig.icon />}
          onClick={onMenuClick}
          dense={dense}
        />
        <Menu.Item
          to={`/i18n-panel-fe`}
          primaryText="resources.i18nManager.fe"
          leftIcon={<servicesconfig.icon />}
          onClick={onMenuClick}
          dense={dense}
        />
        <KToolTip title="Updates are immediately visible in your current BO session (until you refresh!), but are propagated according to cache policies (currently 5m long)">
          <Menu.Item
            to={`/i18n-panel-bo`}
            primaryText="resources.i18nManager.bo"
            leftIcon={<servicesconfig.icon />}
            onClick={onMenuClick}
            dense={dense}
          />
        </KToolTip>
      </SubMenu>

      <PocMenu sidebarIsOpen={open} onClick={onMenuClick} dense={dense} />
    </Menu>
  )
}

export default RAMenu
