import React from 'react'
import HalfStepNumberInput from 'components/Inputs/HalfStepNumberInput/HalfStepNumberInput'
import { RAFormFC } from 'types/common'
import { useForm } from 'hooks/useForm'
import { Location } from 'services/api/entities/catalog/location/types/internal/location-models'

const LocationTimeSuggestionsInput: RAFormFC = ({ source }) => {
  const { getSource } = useForm<Location>(source)
  return (
    <div>
      <HalfStepNumberInput
        source={getSource('defaultHoursBefore')}
        label="form.fields.defaultHoursBefore"
      />
      <br />
      <HalfStepNumberInput
        source={getSource('defaultHoursAfter')}
        label="form.fields.defaultHoursAfter"
      />
    </div>
  )
}

export default LocationTimeSuggestionsInput
