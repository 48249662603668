import { useKGetList } from 'hooks/useKGetList'
import { MainProductAPIEntity } from 'services/api/entities'
import { Supplier, SUPPLIER_SERVICE_NAMES_MAP } from '@kampaay/common'
import { arrayIncludesEvery } from 'services/utils'

type Payload = {
  selectionIds: (number | undefined)[]
  serviceName: MainProductAPIEntity
  ifSupplierNotAssigned: boolean
}

//when having two or more products that do not have a at least 1 common supplier
//If the supplier was not assigned, I see this banner
const useCheckIfSelectionsHasCommonSupplier = ({
  selectionIds,
  serviceName,
  ifSupplierNotAssigned
}: Payload) => {
  //get suppliers
  const suppliers = useKGetList<Supplier>('suppliers').list

  let haveSupplierWithAllProduct = false
  for (const supplier of suppliers) {
    const SCN: string = SUPPLIER_SERVICE_NAMES_MAP[serviceName]
    const selectedServiceArray: number[] = supplier[
      SCN as keyof Supplier
    ] as unknown as number[]
    if (arrayIncludesEvery(selectedServiceArray, selectionIds)) {
      haveSupplierWithAllProduct = true
      break
    }
  }
  return haveSupplierWithAllProduct === false &&
    selectionIds.length > 1 &&
    ifSupplierNotAssigned
    ? true
    : false
}

export default useCheckIfSelectionsHasCommonSupplier
