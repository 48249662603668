import { createMockBuilder } from '../../../../utils'
import type { ProposalHistory } from '../types'

const buildBaseMock = (): ProposalHistory => ({
  id: 0,
  concierge: 0,
  proposals: []
})

export const buildProposalHistoryMock = createMockBuilder(buildBaseMock)
