import React, { useCallback } from 'react'
import {
  BooleanField,
  Button,
  ChipField,
  Datagrid,
  FunctionField,
  Identifier,
  NumberField,
  useCreatePath
} from 'react-admin'
import { RaRecord } from 'ra-core'
import { useParams } from 'react-router-dom'
import { openInternalLinkInNewWindow } from 'services/utils/navigationUtils'
import { OrganizationBudget } from '@kampaay/common'
import LocalizedTextField from 'components/Fields/Localized/LocalizedTextField'
import KDateField from 'components/Fields/KDateField'

type Props = {
  bulkActions?: boolean
  data?: OrganizationBudget[]
  removeMembers?: () => void
}

const OrganizationMembershipBudgetsDatagrid: React.FC<Props> = ({
  bulkActions,
  ...props
}) => {
  const createPath = useCreatePath()
  const { id: organizationId } = useParams()

  const openBudgetEditPage = useCallback(
    (_id: Identifier, _resource: string, record: RaRecord) => {
      const budget = record as OrganizationBudget
      const path = createPath({
        resource: `organization/${organizationId}/budgets`,
        id: budget.id,
        type: 'edit'
      })

      openInternalLinkInNewWindow(path)

      return ''
    },
    []
  )

  return (
    <Datagrid
      {...props}
      bulkActionButtons={
        bulkActions ? (
          <Button label="ra.action.remove" onClick={props.removeMembers} />
        ) : (
          false
        )
      }
      rowClick={openBudgetEditPage}
      sort={{ field: 'id', order: 'ASC' }}
    >
      <LocalizedTextField
        source="name"
        label="form.fields.name"
        sortable={false}
      />
      <NumberField
        source="totalAmount"
        label="form.fields.total"
        sortable={false}
      />
      <FunctionField
        label="form.fields.available"
        sortable={false}
        render={(record: OrganizationBudget) => (
          <NumberField
            record={{
              available: record.totalAmount - record.amountSpent
            }}
            source="available"
          />
        )}
      />
      <KDateField
        source="startDate"
        label="form.fields.startDate"
        sortable={false}
      />
      <KDateField
        source="endDate"
        label="form.fields.endDate"
        sortable={false}
      />
      <FunctionField
        label="form.fields.members"
        sortable={false}
        render={(record: OrganizationBudget) => (
          <ChipField
            record={{ members: record.membershipsIds.length }}
            source="members"
          />
        )}
      />
      <BooleanField
        source="isActive"
        label="form.fields.isActive"
        sortable={false}
      />
      <BooleanField
        source="automaticRenewal"
        label="form.fields.automaticRenewal"
        sortable={false}
      />
    </Datagrid>
  )
}

export default OrganizationMembershipBudgetsDatagrid
