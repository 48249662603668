import React from 'react'
import CatalogItemBaseList, {
  CatalogItemBaseListProps
} from 'components/Lists/CatalogItemBaseList'
import { exportFormatter } from 'services/csv/exporter'
import { ReferenceArrayField, SingleFieldList } from 'react-admin'
import LocalizedChipField from 'components/Fields/Localized/LocalizedChipField'

export type ProductCommonListProps = CatalogItemBaseListProps

const ProductCommonList: React.FC<ProductCommonListProps> = ({
  children,
  exporter = exportFormatter(),
  ...rest
}) => {
  return (
    <CatalogItemBaseList exporter={exporter} {...rest}>
      <ReferenceArrayField
        source="geoAreaPresets"
        reference="geoareapresets"
        sortable={false}
      >
        <SingleFieldList>
          <LocalizedChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      {children}
    </CatalogItemBaseList>
  )
}

export default ProductCommonList
