import React from 'react'
import ProfessionalTailoringsInput from 'resources/crm/clients/organization/tailoring/components/byRule/ProfessionalTailoringsInput'
import ServiceTailoringsEdit from 'resources/crm/clients/organization/tailoring/ServiceTailoringsEdit'

const ProfessionalTailoringsEdit: React.FC = () => {
  return (
    <ServiceTailoringsEdit
      service="professionals"
      productEntity="professionals"
      tailoringsInput={ProfessionalTailoringsInput}
    />
  )
}

export default ProfessionalTailoringsEdit
