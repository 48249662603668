import type {
  Hint,
  WithQuantityHint,
  WithTimeBeforeAfterHint,
  WithTimeBeforeHint
} from './types'

const writeHint = <T extends Hint>(
  isEnabled: boolean,
  defaultHint: T,
  hints: T[]
) => {
  if (isEnabled) {
    hints.push(defaultHint)
    return hints
  } else return []
}

/**
 * @returns the quantity hint array if show quantity is true else empty array
 */
export const writeQuantityHint = ({
  defaultQuantityHint,
  quantityHint,
  showQuantityHint
}: WithQuantityHint) =>
  writeHint(showQuantityHint, defaultQuantityHint, quantityHint)

/**
 * @returns the before hint array if show time before hint is true else empty array
 */
export const writeTimeBeforeHint = ({
  timeBeforeHint,
  defaultTimeBeforeHint,
  showTimeBeforeHint
}: WithTimeBeforeHint) =>
  writeHint(showTimeBeforeHint, defaultTimeBeforeHint, timeBeforeHint)

/**
 * @returns the time before after hint array if show time before after hint is true else empty array
 */
export const writeTimeBeforeAfterHint = ({
  timeBeforeAfterHint,
  defaultTimeBeforeAfterHint,
  showTimeBeforeAfterHint
}: WithTimeBeforeAfterHint) =>
  writeHint(
    showTimeBeforeAfterHint,
    defaultTimeBeforeAfterHint,
    timeBeforeAfterHint
  )
