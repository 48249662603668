import { KTestConfig, KTestFunction } from 'services/yup'
import { hasConsistentLogistics } from 'services/validations/common'
import { Food, FoodUpselling } from '@kampaay/common'

/**
 * this function test
 * the compatibility of the upsellings logistics with the product logistics
 *
 * Rule:
 * ALL upsellings must have a common logistic with the product logistics
 * hence, at least one product logistic must be in common with ALL upsellings logistics
 *
 * e.g.
 * Upselling1: Logistics[1,3,4,5]
 * Upselling2: Logistics[1,4]
 * Upselling3: Logistics[4,5]
 * Upselling4: Logistics[3,4]
 *
 * Then
 *
 * Product: Logistics [1,3,5] --> Not Valid since there is no 1 common logistic with upselling
 * Product: Logistics [1,3,4,5] --> Valid --> 4 is a logistic common to all 4 upselling
 * Product: Logistics [4,3] --> Valid --> 4 is a logistic common to all 4 upselling
 *
 */
const validateConsistentUpsellingLogistics =
  (upsellingItemsFull: FoodUpselling[]): KTestFunction<number[], Food> =>
  (
    _,
    {
      parent: {
        logistics: foodLogisticsIds,
        upsellingItems: selectedUpsellingsIds
      }
    }
  ) => {
    // for the current food
    //
    //   if (there are no logistics)
    //   (although a food must have at least one logistic),
    //   let the test pass, and let the user carry on anyway
    if (foodLogisticsIds.length <= 0) return true

    //   if (there are no upsellings selected)
    //   let the test pass, and let the user carry on anyway
    if (selectedUpsellingsIds?.length <= 0) return true

    //
    return hasConsistentLogistics(
      selectedUpsellingsIds,
      foodLogisticsIds,
      upsellingItemsFull
    )
  }

export const hasConsistentUpsellings = (
  upsellingItemsFull: FoodUpselling[]
): KTestConfig<number[]> => ({
  test: validateConsistentUpsellingLogistics(upsellingItemsFull),
  message: 'form.validations.cannotAssociateIncompatibleProducts'
})
