import type { APIProposalHistory, ProposalHistory } from './types'

export const proposalHistoryParse = ({
  id,
  concierge,
  proposals,
  status
}: APIProposalHistory): ProposalHistory => {
  const lastProposalId = proposals?.at(-1)

  return { id, concierge, proposals, lastProposalId, status }
}
