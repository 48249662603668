/* eslint-disable unused-imports/no-unused-vars */
import { useLoading, useNotify, useSaveContext } from 'ra-core'
import React, { useCallback, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { CollectionNameWithProducts } from 'services/utils/validation/rule'
import { getIncompatibilityRules } from 'hooks/getIncompatibilityRules'
import { isProduction } from 'services/env'
import { Toolbar, ToolbarProps } from 'ra-ui-materialui'
import { CRUDMode } from 'services/utils/concierge'
import { useKampaayDataProvider } from 'hooks/useKampaayDataProvider'
import {
  EventItem,
  MappedEnrichedEvent
} from 'services/api/entities/events/types/internal/event-models'
import { useRedirect, useTranslate } from 'react-admin'
import { useKResourceContext } from 'hooks/useKResourceContext'
import { useQueryClient } from 'react-query'
import KSaveButton from 'components/Buttons/KSaveButton'
import { useKRecordContext } from 'hooks/useKRecordContext'
import { Rule, WithId, isTruthy } from '@kampaay/common'

// const EVENT_FORM_ERROR_MESSAGE = {
//   incompatibleProducts: translate('form.fields.incompatiblesProductByRule'),
//   productionMode: translate('form.fields.warningTriggeringEmail'),
//   incompatibleProductsInProductionMode: `${translate(
//     'form.fields.warningTriggeringEmail'
//   )} \n${translate('form.fields.incompatiblesProductByRule')}`,
// } as const

const extractSelectionIds = (selections: Partial<WithId>[]) =>
  selections.map((s) => s.id).filter(isTruthy)

const EventFormToolbar: React.FC<ToolbarProps & { crudMode: CRUDMode }> = ({
  crudMode,
  ...props
}) => {
  const dp = useKampaayDataProvider()
  const { save, saving } = useSaveContext()
  const loading = useLoading()
  const notify = useNotify()
  const redirect = useRedirect()
  const resource = useKResourceContext()
  const record = useKRecordContext<EventItem>()
  const queryClient = useQueryClient()
  const translate = useTranslate()
  const {
    getValues,
    handleSubmit,
    formState: { isDirty, isValidating, isSubmitting }
  } = useFormContext<MappedEnrichedEvent>()

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  /**
   * the pending promise of the rules getList
   */
  const [rulesPromise, setRulesPromise] = useState<
    Promise<Rule[]> | undefined
  >()

  // Get incompatibility rules on start
  useEffect(() => {
    setRulesPromise(getIncompatibilityRules(dp))
  }, [])

  const getRecord = (): CollectionNameWithProducts => {
    const eventItem = getValues()
    const recordToReturn: CollectionNameWithProducts = {
      ...(eventItem.foods && { foods: extractSelectionIds(eventItem.foods) }),
      ...(eventItem.virtuals && {
        virtuals: extractSelectionIds(eventItem.virtuals)
      }),
      ...(eventItem.locations && {
        locations: extractSelectionIds(eventItem.locations)
      }),
      ...(eventItem.physicals && {
        physicals: extractSelectionIds(eventItem.physicals)
      }),
      ...(eventItem.musics && {
        musics: extractSelectionIds(eventItem.musics)
      }),
      ...(eventItem.drinks && {
        drinks: extractSelectionIds(eventItem.drinks)
      }),
      ...(eventItem.rents && { rents: extractSelectionIds(eventItem.rents) }),
      ...(eventItem.professionalService && {
        professionals: extractSelectionIds(
          eventItem.professionalService.services.flatMap((s) => s.selections)
        )
      })
    }
    return recordToReturn
  }

  const shouldShowEmailWarningModal = isProduction || crudMode === 'createMode'

  // Save form if the dialog does not open
  const saveForm = useCallback(
    () =>
      handleSubmit(() =>
        save?.(getValues(), {
          onSuccess: (data) => {
            queryClient.removeQueries([resource])

            notify('ra.notification.created', {
              type: 'info',
              messageArgs: { smart_count: 1 }
            })
            redirect('edit', resource, data.id, data)
          }
        })
      )(),
    [handleSubmit, save, getValues]
  )

  // TODO: Incompatibility rules have been disabled at the moment: since
  // react admin does not call the "onMutate" mutateOption when the save button
  // is clicked, we cannot check the incompatibilities before the save.
  // The tradeoff was between using a normal save button with onclick handler
  // to show the incompatibility modal, or using the KSaveButton with all the
  // logic inside, but without the possibility to show the modal.
  // We decided to use the KSaveButton, since incompatibility rules are not
  // used at the moment.

  // Check incompatibilities
  // const handleButtonClick = useCallback(async () => {
  //   if (!rulesPromise) return

  //   const rules = await rulesPromise

  //   const hasIncompatibleProducts = areProductsIncompatible(getRecord(), rules)

  //   if (shouldShowEmailWarningModal || hasIncompatibleProducts) {
  //     if (shouldShowEmailWarningModal && hasIncompatibleProducts) {
  //       setErrorMessage(
  //         EVENT_FORM_ERROR_MESSAGE.incompatibleProductsInProductionMode
  //       )
  //     } else if (shouldShowEmailWarningModal) {
  //       setErrorMessage(EVENT_FORM_ERROR_MESSAGE.productionMode)
  //     } else {
  //       setErrorMessage(EVENT_FORM_ERROR_MESSAGE.incompatibleProducts)
  //     }

  //     setIsModalOpen(true)
  //     throw new Error('Incompatible products')
  //   }
  // }, [rulesPromise])

  const isPendingApproval = record?.eventStatus === 'pendingApproval'
  const disabled =
    !isDirty ||
    isValidating ||
    !!saving ||
    isSubmitting ||
    loading ||
    isPendingApproval

  return (
    <Toolbar {...props}>
      <KSaveButton disabled={disabled} variant="contained" />

      {/* TODO: Uncomment this when incompatibility rules have been reimplemented */}
      {/* <SaveModal
        title={'form.fields.attentionTitle'}
        description={errorMessage}
        isOpen={isModalOpen}
        saveButtonProps={{
          onSuccessCallback: () => setIsModalOpen(false),
        }}
        onCancelCTAClick={() => setIsModalOpen(false)}
      /> */}
    </Toolbar>
  )
}

export default EventFormToolbar
