import type { Concierge } from '../types/internal/concierge-models'
import { buildCustomerMock } from '../../customers/mocks/customer-mocks'
import { createMockBuilder } from '../../../../utils'

const buildBaseMock = (): Concierge => ({
  id: 0,
  acceptedProposalId: 0,
  createdByKampaayerId: 0,
  customer: 0,
  customerDocs: [],
  customerFull: buildCustomerMock(),
  guid: '123abc',
  kampaayerDocs: [],
  proposalHistories: [],
  status: 'received',
  updatedByKampaayerId: 0,
  allowHubSpotIntegration: false,
  expirationStatus: 'valid',
  hasSupplierAssigned: false,
  activities: [],
  eventCategory: 'teamBuilding',
  color: '#ffffff',
  eventStatus: 'draft',
  checkoutQuestionResponses: []
})

export const buildConciergeMock = createMockBuilder(buildBaseMock)
