import React from 'react'
import BaseTailoringsEdit from 'resources/crm/clients/organization/tailoring/BaseTailoringsEdit'
import HiddenServiceTailoringBanner from 'resources/crm/clients/organization/tailoring/HiddenServiceTailoringBanner'
import { RAFormFC, ServiceCollectionName } from 'types/common'
import TailoringsArrayInput from 'resources/crm/clients/organization/tailoring/components/TailoringsArrayInput'
import { APIEntity } from 'services/api/entities'

type ServiceTailoringsEditProps = {
  service: ServiceCollectionName
  productEntity: APIEntity
  tailoringsInput: RAFormFC<{ productEntity: APIEntity }>
}

const ServiceTailoringsEdit: React.FC<ServiceTailoringsEditProps> = ({
  service,
  productEntity,
  tailoringsInput: TailoringsInput
}) => {
  return (
    <BaseTailoringsEdit>
      <HiddenServiceTailoringBanner service={service} />
      <TailoringsArrayInput productEntity={productEntity}>
        <TailoringsInput productEntity={productEntity} />
      </TailoringsArrayInput>
    </BaseTailoringsEdit>
  )
}

export default ServiceTailoringsEdit
