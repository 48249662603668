import React from 'react'
import { styled } from '@mui/material/styles'
import { getCurrentCatalogLanguages } from '@kampaay/common'
import { useLangContext } from 'contexts/langContext'
const PREFIX = 'LanguageToggler'

const classes = {
  buttonsSectionOpen: `${PREFIX}-buttonsSectionOpen`,
  customButton: `${PREFIX}-customButton`,
  colorMain: `${PREFIX}-colorMain`,
  colorGrey: `${PREFIX}-colorGrey`
}

const Root = styled('div')(({ theme }: any) => ({
  [`&.${classes.buttonsSectionOpen}`]: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-around',
    padding: '8px',
    gap: '6px',
    alignItems: 'center'
  },

  [`& .${classes.customButton}`]: {
    color: 'white',
    borderRadius: '8px',
    padding: '2px 4px',
    textAlign: 'center',
    textTransform: 'uppercase',
    border: '1px solid #ccc'
  },

  [`& .${classes.colorMain}`]: {
    backgroundColor: theme.palette.primary.main,
    pointerEvents: 'none'
  },

  [`& .${classes.colorGrey}`]: {
    backgroundColor: 'grey',
    cursor: 'pointer'
  }
}))

const LanguageToggler: React.FC = () => {
  const { currentLang, updateCurrentLang } = useLangContext()
  const catalogLanguages = getCurrentCatalogLanguages()

  return (
    <Root className={classes.buttonsSectionOpen}>
      {catalogLanguages.map((l, idx) => (
        <div
          className={`${classes.customButton} ${
            l.localeCode === currentLang ? classes.colorMain : classes.colorGrey
          }`}
          key={idx}
          onClick={() => updateCurrentLang(l.localeCode)}
        >
          {l.languageCode}
        </div>
      ))}
    </Root>
  )
}

export default LanguageToggler
