import { Button } from 'react-admin'
import NotifySupplierSwitch from 'components/Toggles/NotifySupplierSwitch/NotifySupplierSwitch'
import React, { FC } from 'react'
import { NotifySupplierErrors } from 'hooks/events/useEventService'

type Props = {
  isInGeneralTab: boolean
  serviceSource: string
  ableToNotifyAllSuppliers: boolean
  notifyServiceSupplier?: () => void
  notifyAllSuppliers: () => void
  isNotifySupplierDisabled?: NotifySupplierErrors
}

const NotifySupplierSection: FC<Props> = ({
  isInGeneralTab,
  notifyAllSuppliers,
  serviceSource,
  isNotifySupplierDisabled,
  notifyServiceSupplier,
  ableToNotifyAllSuppliers
}) =>
  isInGeneralTab ? (
    <Button
      label={
        ableToNotifyAllSuppliers
          ? 'button.label.questions.notifyAllSuppliers'
          : 'button.label.questions.noSuppliersToNotify'
      }
      variant="outlined"
      size="large"
      disabled={!ableToNotifyAllSuppliers}
      onClick={() => notifyAllSuppliers()}
    />
  ) : (
    <NotifySupplierSwitch
      serviceSource={serviceSource}
      notifyServiceSupplier={notifyServiceSupplier}
      isNotifySupplierDisabled={isNotifySupplierDisabled}
    />
  )

export default NotifySupplierSection
