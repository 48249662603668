import {
  Drink,
  DrinkVariant
} from 'services/api/entities/catalog/drink/types/internal/drink-models'
import { parseCsvFields } from '@kampaay/common'
import { CSVEntityDescriptor } from 'services/csv/types'
import { formatMainProduct } from 'services/csv/entities/catalog/common/mainProduct'
import { formatStandardPricing } from 'services/csv/entities/catalog/common/standardPricing'

const formatDrinkVariant = ({ name, pricing }: DrinkVariant) => ({
  ...parseCsvFields({ Name: name }),
  Pricing: pricing.map(formatStandardPricing)
})

const formatDrink = ({ hasMultipleVariants, variants, ...rest }: Drink) => ({
  ...formatMainProduct(rest),
  'With Multiple Variants': hasMultipleVariants,
  Variants: variants.map(formatDrinkVariant)
})

export const drinks: CSVEntityDescriptor<Drink> = {
  format: formatDrink
}
