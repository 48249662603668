import React from 'react'
import { TransactionRecapBillingInfo } from 'resources/accounting/transaction/computedGetters'
import LabeledNumberField from 'components/Fields/LabeledFields/LabeledNumberField'

type Props = {
  formId: number
  transactionRecapInfo: TransactionRecapBillingInfo
}

/**
 * This component displays the billing info of a transaction.
 * This information was made of the sum of all the services billing infos
 * @param formId - the id of the transaction
 * @param transactionRecapInfo - the billing info of the transaction
 */
const TransactionRecapBillingInfoFields: React.FC<Props> = ({
  formId,
  transactionRecapInfo
}) => {
  return (
    <>
      {Object.keys(transactionRecapInfo).map((item, idx) => (
        <LabeledNumberField
          key={idx}
          label={`form.fields.${item}`}
          record={{ ...transactionRecapInfo, id: formId }}
          source={`${item}`}
          style={{ marginBottom: '16px' }}
          roundTwoDecimals
        />
      ))}
    </>
  )
}

export default TransactionRecapBillingInfoFields
