import React from 'react'
import { exportFormatter } from 'services/csv/exporter'
import CatalogItemBaseList, {
  CatalogItemBaseListProps
} from 'components/Lists/CatalogItemBaseList'

const DrinkSectionList: React.FC<CatalogItemBaseListProps> = (props) => (
  <CatalogItemBaseList {...props} exporter={exportFormatter()} />
)

export default DrinkSectionList
