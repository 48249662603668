import React, { ReactElement } from 'react'
import { Create, CreateProps } from 'react-admin'
import { UseMutationOptions } from 'react-query'
import { UseCreateMutateParams } from 'ra-core'

type NativeMutationOptions = UseMutationOptions<
  any,
  unknown,
  UseCreateMutateParams
> & { meta?: any }

// onSuccess and onError from the KSaveButton wrapper overwrite the ones from the Create component,
// therefore we remove them from the interface in order to avoid missing this
// redirect after create is managed by the KSaveButton, therefore we remove it from the interface.
// If you want to add a redirect path use the `redirectAfterCreate` prop from the KSimpleForm or KTabbedForm (if you're not using the default toolbar),
// or from the KToolbarProps or KSaveButtonProps (if you're using a custom toolbar)
type Props = Omit<CreateProps, 'mutationOptions' | 'redirect'> & {
  mutationOptions?: Omit<NativeMutationOptions, 'onSuccess' | 'onError'>
  children: ReactElement
}

const KCreate: React.FC<Props> = ({ children, ...rest }) => {
  return <Create {...rest}>{children}</Create>
}

export default KCreate
