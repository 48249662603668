import type { APICfg } from 'services/api/api'
import {
  APIOrganizationSubGroup,
  OrganizationSubGroup,
  OrganizationSubGroupToAPI
} from '@kampaay/common'

const parseOrganizationSubGroup = (
  apiModel: APIOrganizationSubGroup
): OrganizationSubGroup => apiModel

const writeOrganizationSubGroup = async (
  subGroup: APIOrganizationSubGroup
): Promise<OrganizationSubGroupToAPI> => subGroup

const apiCfg = {
  endpoint: 'organizationsubgroup',
  parse: parseOrganizationSubGroup,
  write: writeOrganizationSubGroup
} as APICfg

export default apiCfg
