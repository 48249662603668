import React from 'react'
import { NumberField } from 'react-admin'
import { CatalogItemBaseListProps } from 'components/Lists/CatalogItemBaseList'
import KCloneButton from 'components/Buttons/KCloneButton'
import ProductCommonList from 'components/Lists/ProductCommonList'
import { Upselling } from '@kampaay/common'

const VirtualExperienceUpsellingList: React.FC<CatalogItemBaseListProps> = (
  props
) => (
  <ProductCommonList {...props}>
    <NumberField
      source="component.perGuestDefaultCost"
      label="Guest Default Cost"
      sortable={false}
    />
    <KCloneButton<Upselling> omittedProperties={['products']} />
  </ProductCommonList>
)

export default VirtualExperienceUpsellingList
