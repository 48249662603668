import {
  formatStandardPricing,
  StandardPricingCSV
} from 'services/csv/entities/catalog/common/standardPricing'
import {
  formatProductCommon,
  ProductCommonCSV
} from 'services/csv/entities/catalog/common/productCommon'
import { Upselling } from '@kampaay/common'

export type UpsellingCSV = ProductCommonCSV & {
  Price: StandardPricingCSV[]
  'Min quantity': number
}

export const formatUpselling = ({
  minQuantity,
  pricing,
  ...rest
}: Upselling): UpsellingCSV => ({
  ...formatProductCommon(rest),
  Price: pricing.map(formatStandardPricing),
  'Min quantity': minQuantity
})
