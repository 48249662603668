import React, { useEffect } from 'react'
import { RAFormFC } from 'types/common'
import { useForm } from 'hooks/useForm'
import StandardPricingInput from 'components/FormComponents/pricing/standard/StandardPricingInput'
import LocalizedAutocompleteInput from 'components/Inputs/Localized/LocalizedAutocompleteInput'
import KDivider from 'components/UI/KDivider'
import {
  APIPriceRange,
  getById,
  OptionItem,
  parseMultilangOrFallbackToAnyAvailable,
  ProductTailoring
} from '@kampaay/common'
import { KBooleanInput } from 'components/Inputs/KInputs/KBooleanInput'

type OptionTailoringProps = {
  allOptions: OptionItem[]
  filteredOptions: OptionItem[]
}

const OptionTailoringInput: RAFormFC<OptionTailoringProps> = ({
  source,
  allOptions = [],
  filteredOptions = []
}) => {
  const { getSource, useGetValue, useSetValue } =
    useForm<ProductTailoring<APIPriceRange>>(source)
  const optionId = useGetValue('id')
  const currentFullOption = getById(allOptions, optionId)
  const optionName = currentFullOption?.name ?? ''

  // ===================================
  // EFFECTS
  // ===================================
  // resetting pricing to make VS validation pass
  const hasCustomPrice = useGetValue('hasCustomPrice')
  const setPricing = useSetValue('pricing')
  useEffect(() => {
    if (!hasCustomPrice) {
      setPricing([])
    }
  }, [hasCustomPrice])

  // ===================================
  // JSX
  // ===================================
  return (
    <>
      <div style={{ visibility: 'hidden', height: '0px' }}>
        <KBooleanInput
          source={getSource('isHidden')}
          label={'form.fields.isHidden'}
          defaultValue={false}
        />
        <KBooleanInput
          source={getSource('hasCustomPrice')}
          label={'form.fields.addCustomPrice'}
          defaultValue={true}
        />
      </div>
      {optionId ? (
        <>
          <KDivider />
          <h3>{parseMultilangOrFallbackToAnyAvailable(optionName)}</h3>
          <StandardPricingInput source={getSource('pricing')} />
        </>
      ) : (
        <LocalizedAutocompleteInput
          choices={filteredOptions}
          source={getSource('id')}
        />
      )}
    </>
  )
}

export default OptionTailoringInput
