import { UseValidationSchema, yupArray, yupString } from 'services/yup'
import { VirtualExperienceSection } from 'services/api/entities/catalog/sections/experienceSection'
import { useProductSectionWithDescriptionVS } from 'services/validations/catalog/sections/productSectionWithDescriptionVS'
import { VirtualSpecialType } from 'types/form'

export const useVirtualSectionVS: UseValidationSchema<
  VirtualExperienceSection
> = () =>
  useProductSectionWithDescriptionVS().shape({
    specialType: yupString<VirtualSpecialType>(),
    productsFull: yupArray()
  })
