import React, { useCallback } from 'react'
import {
  Datagrid,
  Identifier,
  NumberField,
  TextField,
  useCreatePath,
  useList,
  useTranslate
} from 'react-admin'
import { FILTER_SOURCES } from '@kampaay/common'
import { useKRecordContext } from 'hooks/useKRecordContext'
import type { OrganizationBudget } from '@kampaay/common'
import type { RAFormFC } from 'types/common'
import { useForm } from 'hooks/useForm'
import type { EventItem } from 'services/api/entities/events/types/internal/event-models'
import { useKGetList } from 'hooks/useKGetList'
import { ListContextProvider, RaRecord } from 'ra-core'
import KDateField from 'components/Fields/KDateField'
import { openInternalLinkInNewWindow } from 'services/utils/navigationUtils'

const OrganizationBudgetEventsDatagrid: RAFormFC = ({ source }) => {
  const translate = useTranslate()
  const record = useKRecordContext<OrganizationBudget>()
  const { getSource } = useForm<EventItem>(source)
  const createPath = useCreatePath()
  const { list } = useKGetList<EventItem>('events', {
    filter: {
      [FILTER_SOURCES.organizationBudgetId.eq]: record?.id
    }
  })

  const listContext = useList({ data: list })

  const openEventEditPage = useCallback(
    (_id: Identifier, _resource: string, record: RaRecord) => {
      const event = record as EventItem
      const path = createPath({
        resource: `events`,
        id: event.id,
        type: 'edit'
      })

      openInternalLinkInNewWindow(path)

      return ''
    },
    [createPath]
  )

  if (!list.length) {
    return <p>{translate('resources.organization.noEvents')}</p>
  }

  return (
    <ListContextProvider value={{ ...listContext, resource: 'events' }}>
      <Datagrid bulkActionButtons={false} rowClick={openEventEditPage}>
        <TextField
          label="resources.organization.eventOrganizer"
          source={getSource('customerInfo.name')}
          sortable={false}
        />
        <NumberField
          label="resources.organization.eventAmount"
          source={getSource('pricing.price')}
          sortable={false}
        />
        <KDateField
          label="form.fields.startDate"
          source={getSource('startDate')}
          sortable={false}
        />
        <KDateField
          label="form.fields.confirmedAt"
          source={getSource('confirmedAt')}
          sortable={false}
        />
      </Datagrid>
    </ListContextProvider>
  )
}

export default OrganizationBudgetEventsDatagrid
