export { useForm } from './useForm'

/**
 * Takes the source of a field and returns the same source removing any index notation for array fields
 *
 * @example
 * ```ts
 * const source = 'items.0.name'
 *
 * getSourceWithoutIndexNotation(source) // 'items.name'
 * ```
 *
 * @param source
 */
export const getSourceWithoutIndexNotation = (source: string) =>
  source.replace(/\.\d+/g, '')
