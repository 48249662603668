import { Tooltip } from '@mui/material'
import React from 'react'
import { useTranslate } from 'react-admin'
import HalfStepNumberInput from 'components/Inputs/HalfStepNumberInput/HalfStepNumberInput'
import AddressAutocompleteInput from 'components/Inputs/AddressAutocompleteInput'

const PhysicalKeyInfoSection: React.FC = () => {
  const translate = useTranslate()

  return (
    <>
      <AddressAutocompleteInput
        label={'form.fields.address'}
        source={'geoCodedAddress'}
        fullWidth
        checkAddressType
      />

      <Tooltip
        title={translate('tooltip.hint.experienceDuration')}
        style={{ marginRight: '24px' }}
      >
        <div>
          <HalfStepNumberInput
            source="minDuration"
            label="form.fields.minDuration"
          />
        </div>
      </Tooltip>

      <Tooltip title={translate('tooltip.hint.experienceDuration')}>
        <div>
          <HalfStepNumberInput
            source="maxDuration"
            label="form.fields.maxDuration"
          />
        </div>
      </Tooltip>
    </>
  )
}

export default PhysicalKeyInfoSection
