import {
  DrinkSelection,
  FoodSelection,
  LocationSelection,
  LogisticSelection,
  MainProductSelection,
  OptionedProductSelection,
  OptionGroupSelection,
  ProfessionalSelection
} from 'services/api/entities/events/types/internal/selections'
import {
  EventProfessionalService,
  EventCustomizedService,
  EventServiceBase,
  EventProfessionalServiceGroup,
  EventProductServicesBundle
} from 'services/api/entities/events/types/internal/services'
import {
  BaseProductSelectionToAPI,
  EventProfessionalServiceToAPI,
  EventToAPI,
  FoodSelectionToAPI,
  LogisticSelectionToAPI,
  OptionedProductSelectionToAPI,
  OptionsSelectionToAPI,
  DrinkSelectionToAPI,
  CustomizationToAPI,
  CustomizedServiceToAPI,
  EventProfessionalServiceGroupToAPI,
  EventServiceBaseToAPI,
  ProfessionalSelectionToAPI,
  LocationSelectionToApi
} from 'services/api/entities/events/types/api/request-models'
import {
  convertEmptyStringToUndefined,
  entries,
  filterObject,
  fromEntries,
  initializeListParams,
  keys
} from 'services/utils'
import {
  Customization,
  EventItem
} from 'services/api/entities/events/types/internal/event-models'
import { DataProvider } from 'react-admin'
import { uploadOrKeepMultipleFiles } from 'services/utils/files'
import {
  ProductServiceName,
  ServiceCollectionNameWithVirtualCatering
} from 'types/common'
import { groupBy } from 'resources/operations/events/utils'
import { uploadOrKeepMultipleImgs } from 'services/utils/images'
import { CheckoutQuestionReply } from '@kampaay/common'
import {
  Food,
  getById,
  IntolerancesMap,
  isTruthy,
  WithId
} from '@kampaay/common'

const baseProductSelectionWrite = ({
  id,
  quantity
}: MainProductSelection): BaseProductSelectionToAPI => ({
  id: id!,
  quantity: quantity!
})

export const customizationWrite = async (
  c: Customization
): Promise<CustomizationToAPI> => {
  const uploadedDocs = await uploadOrKeepMultipleFiles(c.documents ?? [])
  const uploadedImages = await uploadOrKeepMultipleImgs(c.images ?? [])

  return {
    ...c,
    documents: uploadedDocs,
    images: uploadedImages
  }
}

const locationSelectionWrite = ({
  id,
  bookedFrom,
  bookedTo,
  quantity,
  arrangementName,
  upsellingSelection
}: LocationSelection): LocationSelectionToApi => ({
  id: id!,
  bookedFrom: bookedFrom!,
  bookedTo: bookedTo!,
  quantity: quantity ?? 1,
  arrangementName: convertEmptyStringToUndefined(arrangementName),
  upselling: upsellingSelection.map(({ id, quantity }) => ({
    id: id!,
    quantity: quantity!
  }))
})

const eventServiceBaseWrite = (s: EventServiceBase): EventServiceBaseToAPI => ({
  ...s,
  supplier: convertEmptyStringToUndefined(s.supplier),
  lastMessage: convertEmptyStringToUndefined(s.lastMessage)
})

const isCustomizationValid = (c: Customization) => !!c.name

const customizedServiceWrite = async (
  s: EventCustomizedService
): Promise<CustomizedServiceToAPI> => {
  const customizations = await Promise.all(
    s.customizations.filter(isCustomizationValid).map(customizationWrite)
  )
  return {
    ...eventServiceBaseWrite(s),
    customizations
  }
}

const drinkSelectionWrite = (drink: DrinkSelection): DrinkSelectionToAPI => {
  return {
    ...baseProductSelectionWrite(drink),
    quantity: (drink.variants ?? []).sum((e) => e.quantity),
    variants: drink.variants?.map(baseProductSelectionWrite) ?? []
  }
}

const optionSelectionWrite = (
  options: OptionGroupSelection[]
): OptionsSelectionToAPI =>
  options.reduce(
    (acc, opt) => ({
      ...acc,
      [opt.id!]: opt.items.map((i) => ({ id: i.id, quantity: i.quantity }))
    }),
    Object.create(null)
  )

const optionedSelectionWrite =
  (isVirtual = false) =>
  (optioned: OptionedProductSelection): OptionedProductSelectionToAPI => {
    const { upsellingSelection, optionSelections } = optioned
    return {
      id: optioned.id!,
      quantity: optioned.quantity!,
      // apparently upsellingSelection may be undefined if upselling not inserted in the form we should probably handle this in the interface
      upselling:
        upsellingSelection?.map(({ id, quantity }) => ({
          id: id!,
          quantity: isVirtual ? optioned.quantity! : quantity!
        })) ?? [],
      options: optionSelectionWrite(optionSelections ?? [])
    }
  }

const getLogisticSelectionId = (
  food: FoodSelection,
  logistics: LogisticSelectionToAPI[]
): number => {
  return getById(logistics as WithId[], food.logisticSelection!.id)
    ? food.logisticSelection!.id!
    : logistics.find((l) => l.deliveryTime === food.deliveryTime)!.id!
}

const intolerancesSelectionWrite = (intolerances: IntolerancesMap) =>
  Object.entries(intolerances).reduce(
    (acc, [key, value]) => ({
      ...acc,
      // If value is 0, it will be converted to undefined for schema consistency
      [key]: !!value ? value : undefined
    }),
    {}
  )

const foodSelectionWrite =
  (logistics: LogisticSelectionToAPI[]) =>
  (food: FoodSelection): FoodSelectionToAPI => {
    const {
      logisticSelection: _logisticSelection,
      intolerances,
      deliveryTime,
      ...rest
    } = food

    return {
      ...optionedSelectionWrite()(rest),
      intolerances: intolerancesSelectionWrite(intolerances),
      deliveryTime: deliveryTime!,
      logisticSelection: getLogisticSelectionId(food, logistics)!
    }
  }

const professionalSelectionWrite = (
  selection: ProfessionalSelection
): ProfessionalSelectionToAPI => {
  const { id, quantity, startTime, endTime } = selection
  return {
    id: id!,
    quantity: quantity!,
    bookedFrom: startTime!,
    bookedTo: endTime!
  }
}

const professionalServiceWrite = (
  proService: EventProfessionalService
): EventProfessionalServiceToAPI => {
  const { id, notes } = proService
  return {
    ...eventServiceBaseWrite(proService),
    section: id,
    notes
  }
}

const professionalServiceGroupWrite = async (
  wrapper: EventProfessionalServiceGroup
): Promise<EventProfessionalServiceGroupToAPI> => {
  const customizations = (
    await Promise.all(
      wrapper.customizations
        .filter(isCustomizationValid)
        .map(customizationWrite)
    )
  ).filter(isTruthy)

  return {
    customizations,
    services: wrapper.services.map(professionalServiceWrite)
  }
}

const getEventLogisticPrice = (eventLogistic: LogisticSelection): number =>
  Array.isArray(eventLogistic.pricing)
    ? // in the case of logistics we assume that they only have one price range with only fixedPrice, since they always have quantity === 1
      eventLogistic.pricing[0].fixedPrice ?? 0
    : eventLogistic.pricing?.price ?? 0

const logisticSelectionWrite = ({
  deliveryTime,
  logisticSelection
}: FoodSelection): LogisticSelectionToAPI => ({
  id: logisticSelection!.id,
  deliveryTime: deliveryTime!,
  price: getEventLogisticPrice(logisticSelection!)
})

const logisticSelectionsWrite = (
  catalogFoods: Food[],
  foodSelection: FoodSelection[]
): LogisticSelectionToAPI[] => {
  const logistics: LogisticSelectionToAPI[] = foodSelection.map(
    logisticSelectionWrite
  )

  const groupedByDelivery = groupBy(logistics, (l) => l.deliveryTime)
  const essentialLogistics = Object.values(groupedByDelivery).flatMap(
    (group) =>
      group.sort(({ price: price1 }, { price: price2 }) => price2 - price1)[0]
  )

  // here we check that all foods share the essentials logistics we computed.
  // if some foods share the delivery time and do not have the resulting logistic,
  // we add back in the cart the selected logistic for the food
  for (const food of foodSelection) {
    if (
      essentialLogistics.some(
        (l) =>
          l.deliveryTime === food.deliveryTime &&
          !(getById(catalogFoods, food.id)?.logistics ?? []).includes(l.id!)
      )
    ) {
      essentialLogistics.push(logisticSelectionWrite(food))
    }
  }

  return essentialLogistics
}

const writeEventDatesToAPI = ({
  startDate,
  endDate,
  rentPickupDate,
  musicPickupDate
}: EventItem) => ({
  startDate: startDate.toISOString(),
  endDate: endDate.toISOString(),
  rentPickupDate: rentPickupDate?.toISOString(),
  musicPickupDate: musicPickupDate?.toISOString()
})

const productServicesWrite = async (
  productServices: EventProductServicesBundle
) => {
  const mappedServices: [ProductServiceName, CustomizedServiceToAPI][] =
    await Promise.all(
      entries(productServices).map(async ([k, v]) => [
        k,
        await customizedServiceWrite(v)
      ])
    )

  return fromEntries(mappedServices)
}

const writeCheckoutQuestions = (
  questions: CheckoutQuestionReply[]
): CheckoutQuestionReply[] => {
  return questions.map((question) => ({
    ...question,
    responses: question.responses.filter((response) => !!response)
  }))
}

export const eventWrite = async (
  req: EventItem,
  dp: DataProvider
): Promise<EventToAPI> => {
  const {
    productServices,
    virtuals,
    physicals,
    locations,
    briefStatus: _briefStatus,
    supplierStatus: _supplierStatus,
    fullAddress,
    rentDeliveryTime: _rentDeliveryTime,
    musicDeliveryTime: _musicDeliveryTime,
    conciergeId: _conciergeId,
    planSteps,
    purchasedServicesArray: _purchasedServicesArray,
    drinks,
    foods,
    musics,
    rents,
    customizations,
    professionalService,
    confirmedInSf: _confirmedInSf,
    checkoutQuestionResponses,
    ...rest
  } = req

  const {
    virtualService,
    foodService,
    rentService,
    musicService,
    physicalService,
    locationService,
    drinkService
  } = await productServicesWrite(productServices)

  const catalogFoods = await dp
    .getList<Food>('foods', initializeListParams())
    .then((res) => res?.data)

  const professionals =
    professionalService?.services.flatMap(({ selections }) =>
      selections.map((professional) => professionalSelectionWrite(professional))
    ) ?? []

  const kampaayerDocs = await uploadOrKeepMultipleFiles(
    rest.kampaayerDocs ?? []
  )

  const mappedLogistics = logisticSelectionsWrite(catalogFoods, foods ?? [])

  /**
   * This fn takes the list of services to add and then filter the missing
   * ones from the planSteps because we add only the missing ones and don't delete them
   */
  const servicesToAdd: ServiceCollectionNameWithVirtualCatering[] = keys(
    filterObject((value) => !!value?.length, {
      drinks,
      foods,
      musics,
      rents,
      professionals,
      locations,
      physicals,
      virtuals,
      virtualCaterings: virtuals
    })
  ).filter(
    (collection) =>
      !(planSteps ?? []).some((stepName) => stepName === collection)
  )

  return {
    ...rest,
    ...writeEventDatesToAPI(req),
    fullAddress: convertEmptyStringToUndefined(fullAddress),
    kampaayerDocs,
    musics: musics?.map((product) => baseProductSelectionWrite(product)) ?? [],
    rents: rents?.map((product) => baseProductSelectionWrite(product)) ?? [],
    drinks: drinks?.map(drinkSelectionWrite) ?? [],
    foods: foods?.map(foodSelectionWrite(mappedLogistics)) ?? [],
    logistics: mappedLogistics,
    virtualService,
    virtualExperiences: virtuals?.map(optionedSelectionWrite(true)) ?? [],
    physicalExperiences: physicals?.map(optionedSelectionWrite()) ?? [],
    professionals,
    professionalServiceGroup: await professionalServiceGroupWrite(
      professionalService
    ),
    foodService,
    rentService,
    musicService,
    physicalService,
    locations: locations?.map(locationSelectionWrite) ?? [],
    locationService,
    drinkService,
    planSteps: [...(planSteps ?? []), ...servicesToAdd],
    eventCustomizations: (
      await Promise.all(
        customizations.filter(isCustomizationValid).map(customizationWrite)
      )
    ).filter(isTruthy),
    checkoutQuestionResponses: writeCheckoutQuestions(checkoutQuestionResponses)
  }
}
