import {
  EnrichedProfessionalService,
  EnrichedProfessionalServiceGroup
} from 'services/api/entities/events/types/internal/services'
import {
  yupArray,
  yupIgnore,
  yupNumber,
  yupObject,
  YupSchema,
  yupString
} from 'services/yup'
import { professionalSelectionVS } from 'services/validations/operations/events/selections/professionals'
import {
  enrichedProductServiceBaseVS,
  withCustomizationsVS
} from 'services/validations/operations/events/services/productService'

const enrichedProfessionalServiceVS: YupSchema<EnrichedProfessionalService> =
  enrichedProductServiceBaseVS.shape({
    id: yupNumber().required(),
    name: yupIgnore(),
    notes: yupString().optional(),
    specialType: yupIgnore(),
    selections: yupArray(professionalSelectionVS).min(1),
    discriminator: yupIgnore()
  })

export const enrichedProfessionalServiceGroupVS: YupSchema<EnrichedProfessionalServiceGroup> =
  yupObject()
    .shape({
      services: yupArray(enrichedProfessionalServiceVS)
    })
    .concat(withCustomizationsVS)
