import React from 'react'
import { SelectArrayInput } from 'react-admin'
import { SelectArrayInputProps } from 'types/common'
import { KInputProps, useKInput } from 'hooks/useKInput/useKInput'

export type KSelectArrayInputProps = KInputProps & SelectArrayInputProps

const KSelectArrayInput: React.FC<KSelectArrayInputProps> = (props) => {
  const { defaultValue = [], ...rest } = props
  const { isRequired } = useKInput(props)

  return (
    <SelectArrayInput
      defaultValue={defaultValue}
      {...rest}
      isRequired={isRequired}
    />
  )
}

export default KSelectArrayInput
