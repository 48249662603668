import React from 'react'
import { useTranslate } from 'react-admin'
import KAccordion from 'components/UI/Accordions/KAccordion/KAccordion'

type AccordionSuggestionProps = {
  accordionText: string[]
}

/**
 * Accordion used to explain to the user how the feature works
 * @param accordionText - array of strings to be displayed in the accordion
 */
const HowItWorksAccordion: React.FC<AccordionSuggestionProps> = ({
  accordionText
}) => {
  const translate = useTranslate()
  return (
    <KAccordion accordionTitle="form.fields.howItWorks">
      {accordionText!.map((e) => (
        <div key={e}>
          {translate('form.fields.' + e)}
          <br />
          <br />
        </div>
      ))}
    </KAccordion>
  )
}

export default HowItWorksAccordion
