import { ProductCommon } from '@kampaay/common'

/**
 * Used to filter products by geoarea
 * @param geoarea - geoarea id
 */
export const isCompatibleWithGivenGeoarea =
  (geoarea: number) =>
  ({ geoAreaPresets }: ProductCommon): boolean =>
    geoAreaPresets.includes(geoarea)
