export const headers = {
  id: 'ID',
  customer: 'Client',
  email: 'Email',
  creationDate: 'Creation Date',
  startDate: 'Start Date',
  address: 'Address',
  guests: 'Guests',
  purchasedServices: 'Purchased Services',
  isFromConcierge: 'Is from Concierge',
  paymentMethod: 'Payment Method',
  discountedPrice: 'Discounted price',

  geoAreaPresetName: 'Geoarea preset name',
  geoAreaPresetNickname: 'Geoarea preset nickname',

  physicalsName: 'Physicals.Name',
  physicalsQuantity: 'Physicals.Quantity',
  physicalsOptions: 'Physicals.Options',
  physicalsUpselling: 'Physicals.Upselling',
  physicalsSupplier: 'Physicals Supplier',

  locationsName: 'Locations.Name',
  locationsStartTime: 'Locations.Start Date',
  locationsEndTime: 'Locations.End Date',
  locationsUpselling: 'Locations.Upselling',
  locationsSupplier: 'Locations Supplier',

  foodsName: 'Foods.Name',
  foodsQuantity: 'Foods.Quantity',
  foodOptions: 'Foods.Options',
  foodsUpselling: 'Foods.Upselling',
  foodsLogistic: 'Foods.Logistic',
  foodSupplier: 'Food Supplier',

  drinksName: 'Drinks.Name',
  drinksQuantity: 'Drinks.Quantity',
  drinkSupplier: 'Drink Supplier',

  virtualName: 'Virtual.Name',
  virtualQuantity: 'Virtual.Quantity',
  virtualOptions: 'Virtual.Options',
  virtualUpselling: 'Virtual.Upselling',

  staffType: 'Staff.Type',
  staffName: 'Staff.Name',
  staffQuantity: 'Staff.Quantity',
  staffStartTime: 'Staff.Start Service',
  staffEndTime: 'Staff.End Service',
  staffSupplier: 'Staff.Supplier',

  audiovisualsName: 'Audiovisuals.Name',
  audiovisualsQuantity: 'Audiovisuals.Quantity',
  audiovisualsSupplier: 'Audiovisuals Supplier',

  rentsName: 'Rents.Name',
  rentsQuantity: 'Rents.Quantity',
  rentsSupplier: 'Rents Supplier',

  physicalsCustomization: 'Physicals Customization',
  locationsCustomization: 'Locations Customization',
  foodsCustomization: 'Foods Customization',
  drinksCustomization: 'Drinks Customization',
  virtualsCustomization: 'Virtuals Customization',
  audiovisualsCustomization: 'Audiovisuals Customization',
  rentsCustomization: 'Rents Customization',
  staffCustomizations: 'Staff Customizations',

  confirmedInSF: 'Confirmed in SF'
}
