import type { WithCostAndPrice, WithOptionalCostAndPrice } from '../../api'

/**
 * Aggregates cost and price values from an array of items.
 * Each item can have optional cost and price properties.
 * Missing values are treated as 0.
 *
 * @param items - Array of items containing optional cost and price properties
 * @returns Object containing the summed cost and price
 *
 * @example
 * ```ts
 * const items = [
 *   { cost: 100, price: 200 },
 *   { cost: 50 },           // missing price treated as 0
 *   { price: 150 },         // missing cost treated as 0
 *   {}                      // both missing treated as 0
 * ]
 *
 * const total = aggregateCostAndPrices(items)
 * // returns { cost: 150, price: 350 }
 * ```
 */
export const aggregateCostAndPrices = (
  items: WithOptionalCostAndPrice[]
): WithCostAndPrice =>
  items.reduce<WithCostAndPrice>(
    (acc, { cost = 0, price = 0 }) => ({
      cost: cost + acc.cost,
      price: price + acc.price
    }),
    { cost: 0, price: 0 }
  )
