import { useCallback, useMemo } from 'react'
import { NotifySupplierErrors } from 'hooks/events/useEventService'
import { checkBriefQuestionsResponses } from 'services/utils/services/checkBriefQuestionsResponses'
import { EnrichedProductServiceBase } from 'services/api/entities/events/types/internal/services'
import { useForm } from 'hooks/useForm'
import { useKRecordContext } from 'hooks/useKRecordContext'
import get from 'lodash/get'

export const useNotifySupplier = (serviceSource: string) => {
  const { useGetValue, useSetValue } =
    useForm<EnrichedProductServiceBase>(serviceSource)

  const record = useKRecordContext()

  const briefQuestions = useGetValue('briefQuestions')
  const supplier = useGetValue('supplier')

  const setSupplierNotification = useSetValue('notifySupplier')

  const isPreviouslySetToTrue = useMemo(
    () => !!get(record, `${serviceSource}.notifySupplier`)!,
    [serviceSource, record]
  )

  const isBriefFilledOut = useMemo(
    () => checkBriefQuestionsResponses(briefQuestions),
    [briefQuestions]
  )

  const isSupplierAssigned = !!supplier

  const isNotifySupplierDisabled: NotifySupplierErrors | undefined =
    useMemo(() => {
      if (isPreviouslySetToTrue) {
        return 'previouslySetToTrue'
      } else if (!isSupplierAssigned) {
        return 'noSupplier'
      } else if (!isBriefFilledOut) {
        return 'missingBrief'
      } else {
        return undefined
      }
    }, [isPreviouslySetToTrue, isBriefFilledOut])

  const notifyServiceSupplier = useCallback(() => {
    if (!isNotifySupplierDisabled) {
      setSupplierNotification(true)
    }
  }, [isNotifySupplierDisabled, setSupplierNotification])

  return {
    isNotifySupplierDisabled,
    notifyServiceSupplier
  }
}
