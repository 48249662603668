import { KInputProps, useKInput } from 'hooks/useKInput/useKInput'
import React from 'react'
import { AutocompleteInput, AutocompleteInputProps } from 'react-admin'

export type KAutocompleteInputProps = AutocompleteInputProps & KInputProps

/**
 * An autocomplete input that allow undefined as value,
 * this is useful for BE, because it does not like empty strings
 */
export const KAutocompleteInput: React.FC<KAutocompleteInputProps> = (
  props
) => {
  const { source, parse = (v) => v, choices, ...rest } = props
  const {
    k: { sx, variant, helperText },
    isRequired
  } = useKInput(props)

  /**
   * AutocompleteInput has a min width of 160px, which is kinda hard to override ina  generic way with the superDense and `useKInput`
   * Anyways the input breaks if we make it shorter, so we are declaratively setting it as 160px here as well
   */
  const finalSx = {
    ...sx,
    minWidth: '160px'
  }

  return (
    <AutocompleteInput
      {...rest}
      source={source}
      parse={(v) => (v ? parse(v) : undefined)}
      clearOnBlur={false}
      sx={finalSx}
      variant={variant}
      helperText={helperText}
      isRequired={isRequired}
      choices={choices}
    />
  )
}

export default KAutocompleteInput
