import React, { useCallback, useMemo, useState } from 'react'
import {
  RedirectionSideEffect,
  SaveButton,
  SaveButtonProps,
  useNotify,
  useRedirect
} from 'react-admin'
import CheckIcon from '@mui/icons-material/Check'
import BlockIcon from '@mui/icons-material/Block'
import ContentSave from '@mui/icons-material/Save'
import { useSaveContext } from 'ra-core'
import { useFormState } from 'react-hook-form'
import { KError } from 'services/api/errors'
import { useKRecordContext } from 'hooks/useKRecordContext'
import { useQueryClient } from 'react-query'
import { useKResourceContext } from 'hooks/useKResourceContext'

type ButtonResultState = 'error' | 'success'

export type KSaveButtonProps = SaveButtonProps & {
  id?: string
  redirectAfterCreate?: RedirectionSideEffect
  onSuccessCallback?: (arg: any) => void
  beforeSaveCallback?: (...args: any) => Promise<boolean>
}

export type KSaveButtonExternalProps = Pick<
  KSaveButtonProps,
  'redirectAfterCreate' | 'mutationOptions'
>

const KSaveButton: React.FC<KSaveButtonProps> = ({
  id = 'k-save-button',
  redirectAfterCreate = 'edit',
  className: classNameProp,
  mutationOptions: mutationOptionsProp,
  label = 'ra.action.save',
  onSuccessCallback = () => {},
  beforeSaveCallback,
  icon = <ContentSave />,
  ...props
}) => {
  const { alwaysEnable, disabled: disabledProp } = props
  const notify = useNotify()
  const { saving } = useSaveContext()
  const { isDirty, isValidating, isSubmitting } = useFormState()
  const record = useKRecordContext()
  const resource = useKResourceContext()
  const queryClient = useQueryClient()
  const redirect = useRedirect()

  // #region style handlers
  const [submitResultState, setButtonSubmitResultState] =
    useState<ButtonResultState>()

  const shouldBeDisabled = useMemo(
    () => disabledProp || !isDirty || isValidating || saving || isSubmitting,
    [disabledProp, isDirty, isValidating, saving, isSubmitting]
  )

  /**
   * This is computed the same as in the native SaveButton, but it's needed here to manipulate the styles of the button
   */
  const disabled = useMemo(() => {
    if (!(alwaysEnable === false || alwaysEnable === undefined)) return false
    return shouldBeDisabled
  }, [alwaysEnable, shouldBeDisabled])

  const buttonClasses = useMemo(() => {
    if (submitResultState === 'success') return 'bg-success text-white'
    if (submitResultState === 'error') return 'bg-error text-white'

    // error and success statuses take over the styles for the disabled button since they are temporary
    if (disabled) {
      if (saving || isSubmitting || isValidating) {
        return 'bg-secondary text-white'
      }
      return 'bg-neutral-lighten-2 text-neutral'
    }

    return 'primary text-white'
  }, [saving, disabled, submitResultState, isSubmitting, isValidating])

  const className = useMemo(() => {
    if (!!classNameProp) return `${classNameProp} ${buttonClasses}`
    return buttonClasses
  }, [classNameProp, buttonClasses])

  const buttonLabel = useMemo(() => {
    if (saving) return 'button.label.loading'

    if (submitResultState === 'success') return 'button.label.saved'
    if (submitResultState === 'error') return 'button.label.notSaved'

    return label
  }, [saving, submitResultState])

  const buttonIcon = useMemo(() => {
    if (submitResultState === 'success') return <CheckIcon />
    if (submitResultState === 'error') return <BlockIcon />
    return icon
  }, [submitResultState])

  /**
   * This is used to set the temporary state of the button to success or error after we get the result of an API call
   */
  const setTemporaryButtonState = useCallback(
    (state: ButtonResultState) => {
      setButtonSubmitResultState(state)
      setTimeout(() => {
        setButtonSubmitResultState(undefined)
      }, 2000)
    },
    [setButtonSubmitResultState]
  )
  // #endregion

  // #region mutationCallbacks
  const isCreate = useMemo(() => {
    return !record?.id
  }, [record?.id])

  const onCreateSuccess: NonNullable<
    SaveButtonProps['mutationOptions']
  >['onSuccess'] = useCallback(
    (formData: any) => {
      /**
       * React Admin's useCreate hook caches a `getOne` entry for the created element, which we don't want,
       * since we always want to rely on the getOne performed by the edit view.
       * Thus, here we override that behavior by deleting all getOne cached elements
       */
      queryClient.removeQueries([resource])

      notify('ra.notification.created', {
        type: 'info',
        messageArgs: { smart_count: 1 }
      })
      onSuccessCallback(formData)

      if (!!redirectAfterCreate) {
        redirect(redirectAfterCreate, resource, formData.id)
      }
    },
    [queryClient, notify, redirect, resource]
  )

  const onEditSuccess = useCallback(
    (formData?: any) => {
      setTemporaryButtonState('success')

      notify('ra.notification.updated', {
        type: 'info',
        messageArgs: { smart_count: 1 },
        undoable: false
      })
      onSuccessCallback(formData)
    },
    [notify, setTemporaryButtonState]
  )

  const onSuccess = useMemo(() => {
    return isCreate ? onCreateSuccess : onEditSuccess
  }, [isCreate, onCreateSuccess, onEditSuccess])

  const onError = useCallback(
    (err: any) => {
      const error = new KError(err)

      setTemporaryButtonState('error')

      notify(`${error.title}: ${error.errors.map((e) => ` >>> ${e} <<< `)}`)
    },
    [notify, setTemporaryButtonState]
  )

  const mutationOptions = {
    onSuccess: mutationOptionsProp?.onSuccess || onSuccess,
    onError: mutationOptionsProp?.onError || onError
  }
  // #endregion

  // #region onBeforeSave
  const [isCallingBeforeSaveHandler, setIsCallingBeforeSaveHandler] =
    useState(false)

  // The onBeforeSave callback.
  // Optionally, the user can pass a callback that will be called before the save operation is performed.
  // The function has to return a boolean value that indicates whether the save operation should proceed or not.
  const onBeforeSave = async (e: React.MouseEvent) => {
    if (document.querySelectorAll(`#${id}`)?.length > 1) {
      throw new Error(
        `KButton has been instantiated multiple times in this page with the same id: ${id}. Please make sure that the id is unique.`
      )
    }

    // The function prevents the default behavior of the button and then click the button again if
    // it's allowed to proceed to the save.
    // But it has to prevent the default behavior only once otherwise it will loop
    if (isCallingBeforeSaveHandler) return setIsCallingBeforeSaveHandler(false)
    setIsCallingBeforeSaveHandler(true)
    e.preventDefault()
    const proceed = beforeSaveCallback ? await beforeSaveCallback() : true
    if (proceed) {
      document.getElementById(id)?.click()
      return
    }
    setIsCallingBeforeSaveHandler(false)
  }
  // #endregion

  return (
    <SaveButton
      {...props}
      id={id}
      data-testid="k-save-button"
      onClick={beforeSaveCallback ? onBeforeSave : props.onClick}
      className={className}
      disabled={disabled}
      disableElevation
      mutationOptions={mutationOptions}
      label={buttonLabel}
      icon={buttonIcon}
      sx={{
        span: {
          color: 'white !important'
        }
      }}
      type="button"
    />
  )
}

export default KSaveButton
