import { parseFileInfo } from '../fileinfo'
import { parseMultipleImages } from '../images'
import { parseGeocodedAddress } from '../geo-coded-addresses'
import { parseProposalItemGroup } from '../proposals-item-groups'
import type { APIProposal, Proposal } from './types'

export const proposalParse = ({
  validUntil,
  itemGroups,
  documents,
  geoCodedAddress,
  images,
  dedicatedDiscount,
  contractDiscount,
  ...rest
}: APIProposal): Proposal => {
  return {
    ...rest,
    validUntil: new Date(validUntil),
    itemGroups: itemGroups.map(parseProposalItemGroup),
    documents: documents.map((d) => parseFileInfo(d)!),
    geoCodedAddress: parseGeocodedAddress(geoCodedAddress),
    images: parseMultipleImages(images ?? []),
    dedicatedDiscount: dedicatedDiscount ?? undefined,
    contractDiscount: contractDiscount ?? undefined
  }
}
