import { downloadCSV } from 'react-admin'
import jsonExport from 'jsonexport/dist'
import { CSV_CFG } from 'services/csv/entities/index'
import { APIEntity } from 'services/api/entities'
import {
  CSVEntityDescriptor,
  CSVToAPIEntityMap,
  EntityWithCSV,
  KExporter,
  MulticonfigCSVEntityDescriptor
} from 'services/csv/types'
import { RaRecord } from 'ra-core'
import { filterRelatedResponseById } from 'services/csv/utils/filterRelatedResponseById'
import { getDescriptor } from 'services/csv/utils/getDescriptor'
import { KConsole } from 'services/console'

const exportJson = <T extends EntityWithCSV>(
  data: any[],
  entity: APIEntity,
  entityObject: Omit<
    CSVEntityDescriptor<CSVToAPIEntityMap[T]>,
    'relatedRecords'
  >
): void => {
  return jsonExport(
    data,
    {
      headers: entityObject.headers,
      fillTopRow: true,
      fillGaps: true,
      mapHeaders: entityObject.headersMapper
    },
    (err, csv) => {
      if (err) KConsole.log('ERROR EXPORTING JSON DATA: ', err)
      downloadCSV(csv, entity)
    }
  )
}

/**
 * Export a single record from edit/show view
 */
export const exportRecord = async <T extends EntityWithCSV>(
  entity: T,
  record: CSVToAPIEntityMap[T],
  csvEntityDescriptorKey?: keyof MulticonfigCSVEntityDescriptor<T>
): Promise<void> => {
  const { relatedRecords, ...entityObject } = getDescriptor(
    CSV_CFG[entity],
    csvEntityDescriptorKey
  )
  // TODO: related records is probably useless here. Fix that and remove
  // fallback of RelatedRecords
  // TODO: fixalo dopo, ma sappi che 'sto stronzo è spaccato e lancia errore perché non riesce
  // a fetcharsi la merda, maledetto il cane di chi l'ha scritto
  // @ts-expect-error
  const data = Promise.all([entityObject.format(record, relatedRecords ?? {})])
  exportJson(await data, entity, entityObject)
}

/**
 * @returns a function described below
 * ----------------------------------------------------------
 * @param records
 * @param fetchedRelatedRecords React Admin function for calling the dataProvider.getMany() method and getting a Promise for the records indexed by id in return.
 * You can find it in node_modules/ra-core/ export/fetchRelatedRecords.ts
 * @param dataProvider
 * @param entities
 */

export const exportFormatter =
  <T extends EntityWithCSV>(
    csvEntityDescriptorKey?: keyof MulticonfigCSVEntityDescriptor<T>
  ): KExporter<T> =>
  async (
    records: CSVToAPIEntityMap[T][],
    fetchRelatedRecords,
    dataProvider,
    entity
  ) => {
    const { relatedRecords = [], ...entityObject } = getDescriptor(
      CSV_CFG[entity],
      csvEntityDescriptorKey
    )

    if (!entityObject) {
      throw new Error(`No CSV configuration for entity ${entity}`)
    }

    // fetches all the related records
    const relatedResponse: [keyof CSVToAPIEntityMap[T], RaRecord[]][] =
      await Promise.all(
        relatedRecords.map(async ({ key, resource }) => [
          key,
          Object.values(await fetchRelatedRecords(records, key, resource))
        ])
      )
    const formattedData = await Promise.all(
      records.map((record) =>
        entityObject.format(
          record,
          filterRelatedResponseById(relatedResponse, record),
          dataProvider
        )
      )
    )
    exportJson(formattedData, entity, entityObject)
  }
