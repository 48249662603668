import { kampaayerParse, kampaayerWrite } from '@kampaay/common'
import { APICfg } from 'services/api/api'
import { uploadOrKeepFile } from 'services/utils/files'

const apiCfg: APICfg = {
  endpoint: 'userkampaayer',
  parse: kampaayerParse,
  write: kampaayerWrite(uploadOrKeepFile)
}

export default apiCfg
