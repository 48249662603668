import React from 'react'
import UpsellingFormContent from 'components/Forms/UpsellingFormContent'
import SectionWrapper from 'components/UI/SectionWrapper'
import UpsellingFacetSection from 'components/Inputs/Facets/FacetFormSections/UpsellingFacetSection'
import { KSimpleForm } from 'components/Forms/KForms/KSimpleForm'
import KEdit from 'components/Forms/KEdit/KEdit'

const LocationUpsellingEdit: React.FC = () => {
  return (
    <SectionWrapper>
      <KEdit>
        <KSimpleForm>
          <UpsellingFormContent
            relatedProduct="locations"
            facetFields={<UpsellingFacetSection />}
          />
        </KSimpleForm>
      </KEdit>
    </SectionWrapper>
  )
}

export default LocationUpsellingEdit
