import { useTranslate } from 'react-admin'

/**
 * Regex matching our naming convention for labels in BO
 * @example
 * 1. "label" => "label" // does not match
 * 2. "label.label" => "label" // does not match
 * 3. "label.label.label" => "label" // matches our convention
 */
const I18N_LABEL_REGEX = /^[a-zA-Z]+([.][a-zA-Z]+)+$/

/**
 * This hook is the smart version of the translation function.
 * It recognizes the json label and:
 * - if it's a conventional string, it returns the translation
 * - if it is a simple string not translatable, it returns the string
 *
 * @example
 * const translatedLabel = useKTranslate('form.fields.name') // name
 * const translatedLabel = useKTranslate('name') // name
 */
export const useKTranslate = (label: string) => {
  const t = useTranslate()
  return label.match(I18N_LABEL_REGEX) ? t(label) : label
}
