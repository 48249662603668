import {
  ProductTailoring,
  APIPriceRange,
  ProductTailoringToAPI,
  ProfessionalPricingRuleSet,
  APIPricingRuleSet,
  LocationPricingRuleSet,
  OptionedTailoring,
  OptionedTailoringToAPI,
  DrinkTailoring,
  DrinkTailoringToAPI,
  Tailorings,
  ProductTailoringWithStandardPricing,
  TailoringsToAPI,
  ProductTailoringWithStandardPricingToAPI,
  ProductTailoringWithProfessionalPricing,
  ProductTailoringWithByRulePricingToAPI,
  ProductTailoringWithLocationPricing,
  writeProfessionalPricing
} from '@kampaay/common'
import { byRulesPricingWrite } from 'services/api/entities/catalog/location/locationPricing'

// with standard pricing
export const writeProductTailoringItem = (
  data: ProductTailoring<APIPriceRange>
): ProductTailoringToAPI<APIPriceRange> => {
  return {
    id: data.id,
    isHidden: data.isHidden,
    pricing: data.hasCustomPrice ? data.pricing : []
  }
}

// with byRule pricing
// professional
export const writeProfessionalTailoringItem = (
  data: ProductTailoring<ProfessionalPricingRuleSet>
): ProductTailoringToAPI<APIPricingRuleSet> => {
  const parsedPricing = data.hasCustomPrice
    ? data.pricing.map(writeProfessionalPricing)
    : []

  return {
    id: data.id,
    isHidden: data.isHidden,
    pricing: parsedPricing
  }
}

// location
export const writeLocationTailoringItem = (
  data: ProductTailoring<LocationPricingRuleSet>
): ProductTailoringToAPI<APIPricingRuleSet> => {
  const parsedPricing = data.hasCustomPrice
    ? data.pricing.map(byRulesPricingWrite)
    : []

  return {
    id: data.id,
    isHidden: data.isHidden,
    pricing: parsedPricing
  }
}

// optioned
export const writeOptionedTailoringItem = (
  data: OptionedTailoring
): OptionedTailoringToAPI => {
  return {
    id: data.id,
    isHidden: data.isHidden,
    options: data.options.map((option) => ({
      id: option.id,
      isHidden: false, // options cannot be hidden as for now
      pricing: option.pricing
    })),
    pricing: data.hasCustomPrice ? data.pricing : []
  }
}

// drink
export const writeDrinkTailoringItem = (
  data: DrinkTailoring
): DrinkTailoringToAPI => {
  return {
    id: data.id,
    isHidden: data.isHidden,
    variants: data.variants.map((variant) => ({
      id: variant.id,
      isHidden: false, // variants cannot be hidden as for now
      pricing: variant.pricing
    }))
  }
}

// ================================================
// WRITERS
// ================================================
export const writeProductTailorings = async (
  data: Tailorings<ProductTailoringWithStandardPricing>
): Promise<TailoringsToAPI<ProductTailoringWithStandardPricingToAPI>> => ({
  tailorings: data.tailorings.map(writeProductTailoringItem)
})

export const writeProfessionalTailorings = async (
  data: Tailorings<ProductTailoringWithProfessionalPricing>
): Promise<TailoringsToAPI<ProductTailoringWithByRulePricingToAPI>> => ({
  tailorings: data.tailorings.map(writeProfessionalTailoringItem)
})

export const writeLocationTailorings = async (
  data: Tailorings<ProductTailoringWithLocationPricing>
): Promise<TailoringsToAPI<ProductTailoringWithByRulePricingToAPI>> => ({
  tailorings: data.tailorings.map(writeLocationTailoringItem)
})

export const writeOptionedTailorings = async (
  data: Tailorings<OptionedTailoring>
): Promise<TailoringsToAPI<OptionedTailoringToAPI>> => ({
  tailorings: data.tailorings.map(writeOptionedTailoringItem)
})

export const writeDrinkTailorings = async (
  data: Tailorings<DrinkTailoring>
): Promise<TailoringsToAPI<DrinkTailoringToAPI>> => ({
  tailorings: data.tailorings.map(writeDrinkTailoringItem)
})
