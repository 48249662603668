import React from 'react'
import { Datagrid, TextField } from 'react-admin'
import DeleteAndExportBulk from 'components/Buttons/DeleteAndExportBulk'
import KPagination from 'components/Lists/KPagination'
import KList from 'components/Lists/KLists/KList'
import SectionWrapper from 'components/UI/SectionWrapper'
import { BaseListProps } from 'components/Lists/CatalogItemBaseList'

const KampaayerList: React.FC<BaseListProps> = (props) => {
  return (
    <SectionWrapper>
      <KList
        {...props}
        exporter={false}
        pagination={<KPagination />}
        perPage={40}
        sort={{ field: 'lastName', order: 'ASC' }}
      >
        <Datagrid rowClick="edit" bulkActionButtons={<DeleteAndExportBulk />}>
          <TextField source="firstName" sortable={false} />
          <TextField source="lastName" />
          <TextField source="email" sortable={false} />
          <TextField source="phone" sortable={false} />
        </Datagrid>
      </KList>
    </SectionWrapper>
  )
}

export default KampaayerList
