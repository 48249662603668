import { defaultTheme } from 'react-admin'

export const kampaayTheme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      light: '#fc957b', // used by react-admin, don't touch
      main: '#FF714D', // used by react-admin, don't touch
      dark: '#e04d28', // used by react-admin, don't touch
      contrastText: '#fff', // used by react-admin, don't touch
      secondaryAccent: '#306DF1',
      white: '#fff',
      blackLighten1: '#E0E0E0',
      blackLighten2: '#f9f9f9',
      errorText: '#da2200',
      errorBackground: 'rgba(218, 34, 0, 0.05)',
      greyLighten1: '#EEEEEE',
      grey: '#999',
      greyDarken1: '#AAAAAA',
      greyDarken2: '#767676', // aka "Subtle",
      success: '#E4FDE4',
      warning: '#FFFFCC',
      info: '#D9E2FF',
      text: '#00000099',

      // new colors from SF
      'success-base': '#4CAF50',
      'success-lighten-5': '#E4FDE4'
    },
    secondary: {
      light: '#B0C6FF', // used by react-admin, don't touch
      main: '#25427C', // used by react-admin, don't touch
      dark: '#002D6E', // used by react-admin, don't touch
      contrastText: '#fff', // used by react-admin, don't touch
      // new colors from SF
      'secondary-base': '#25427C',
      'secondary-lighten-7': '#EDF0FF',
      'neutral-lighten-3': '#E1E4EB'
    },

    white: {
      main: '#fff'
    }
  }
}

export type KampaayTheme = typeof kampaayTheme
export type SupportedColor =
  | 'error'
  | keyof KampaayTheme['palette']['primary']
  | keyof KampaayTheme['palette']['secondary']
