import { ListAlt } from '@mui/icons-material'
import ProfessionalSectionList from 'resources/catalog/professionals/sections/ProfessionalSectionList'
import ProfessionalSectionCreate from 'resources/catalog/professionals/sections/ProfessionalSectionCreate'
import ProfessionalSectionEdit from 'resources/catalog/professionals/sections/ProfessionalSectionEdit'

const professionalSectionsIndex = {
  icon: ListAlt,
  list: ProfessionalSectionList,
  edit: ProfessionalSectionEdit,
  create: ProfessionalSectionCreate
}

export default professionalSectionsIndex
