import {
  KTestConfig,
  KTestFunction,
  UseValidationSchema,
  yupArray,
  yupBoolean,
  yupIgnore,
  yupObject,
  YupSchema
} from 'services/yup'
import {
  hasAtLeastOneToggleEnabledCustomPrice,
  hasAtLeastOneToggleEnabledIsHidden
} from 'services/validations/crm/oraganizationTailorings/sharedTestConfig'
import { locationByRulePricingArrayVS } from 'services/validations/catalog/location/byRulesPricing'
import {
  ProductTailoring,
  LocationPricingRuleSet,
  ProductTailoringBase,
  Tailorings
} from '@kampaay/common'

// ================================================
// tests
// ================================================
const atLeastOnePricingWhenCustomPriceEnabled: KTestFunction<
  boolean,
  ProductTailoring<LocationPricingRuleSet>
> = (hasCustomPrice, { parent: { pricing } }) => {
  return !!hasCustomPrice ? !!pricing?.length : true
}

export const hasAtLeastOnePricingWhenCustomPriceEnabled: KTestConfig<
  boolean,
  ProductTailoring<LocationPricingRuleSet>
> = {
  name: 'hasAtLeastOnePricingWhenCustomPriceEnabled',
  test: atLeastOnePricingWhenCustomPriceEnabled,
  message: 'form.validations.atLeastOnePricing'
}

// ============================================================
// VS
// ============================================================
export const organizationTailoringsLocationArrayVS: YupSchema<
  ProductTailoring<LocationPricingRuleSet>
> = yupObject().shape({
  id: yupIgnore(),
  isHidden: yupBoolean<ProductTailoringBase>()
    .required()
    .test(hasAtLeastOneToggleEnabledIsHidden),
  hasCustomPrice: yupBoolean<ProductTailoring<LocationPricingRuleSet>>()
    .required()
    .test(hasAtLeastOneToggleEnabledCustomPrice)
    .test(hasAtLeastOnePricingWhenCustomPriceEnabled),
  pricing: locationByRulePricingArrayVS.min(0)
})

export const useOrganizationTailoringsLocationVS: UseValidationSchema<
  Tailorings<ProductTailoring<LocationPricingRuleSet>>
> = () =>
  yupObject().shape({
    id: yupIgnore(),
    tailorings: yupArray(organizationTailoringsLocationArrayVS)
  })
