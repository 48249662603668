import React from 'react'
import { useParams } from 'react-router-dom'
import KHeader from 'components/UI/KHeader'
import KTextInput from 'components/Inputs/KInputs/KTextInput/KTextInput'
import OrganizationSubgroupMemberships from 'components/FormComponents/Organization/SubGroups/OrganizationSubGroups'
import KNumberInput from 'components/Inputs/KInputs/KNumberInput/KNumberInput'

type Props = {
  isMembersSectionShown?: boolean
}

const OrganizationSubGroupFormContent: React.FC<Props> = ({
  isMembersSectionShown = true
}) => {
  const { id: organizationId } = useParams()

  return (
    <>
      <KNumberInput
        source="organizationId"
        defaultValue={parseInt(organizationId!)}
        style={{ display: 'none' }}
      />
      <KHeader title="tab.label.info" headingLevel="h2" />
      <KTextInput label="form.fields.name" source="name" />

      {isMembersSectionShown && (
        <div className="mt-8">
          <OrganizationSubgroupMemberships />
        </div>
      )}
    </>
  )
}

export default OrganizationSubGroupFormContent
