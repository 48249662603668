import React from 'react'
import SectionWrapper from 'components/UI/SectionWrapper'
import LocationFormTab from 'resources/operations/events/eventsFormTabs/LocationTab/LocationFormTab'
import VirtualFormTab from 'resources/operations/events/eventsFormTabs/ExperienceTab/VirtualFormTab'
import EventFormToolbar from 'components/Toolbars/EventFormToolbar'
import BaseProductTab from 'resources/operations/events/eventsFormTabs/BaseProductTab/BaseProductTab'
import DrinksFormTab from 'resources/operations/events/eventsFormTabs/DrinkTab/DrinksFormTab'
import PhysicalFormTab from 'resources/operations/events/eventsFormTabs/ExperienceTab/PhysicalFormTab'
import FoodFormTab from 'resources/operations/events/eventsFormTabs/FoodTab/FoodFormTab'
import ProfessionalsTab from 'resources/operations/events/eventsFormTabs/ProfessionaTab/ProfessionalsTab'
import KTabbedForm from 'components/Forms/KForms/KTabbedForm'
import EventCreateQuestionReplyList from 'resources/operations/events/EventsComponents/CheckoutQuestionReply/EventCreateQuestionReplyList'
import KampaayCustomersInput from 'components/Inputs/KampaayCustomersInput'
import StandardEventGeneralTab from 'resources/operations/events/eventsFormTabs/StandardEventGeneralTab'
import KCreate from 'components/Forms/KCreate/KCreate'
import { useIsGeneralTabURL } from 'hooks/useIsGeneralTabURL'

const EventCreate: React.FC = () => {
  ///////////////////////////
  // JSX
  ///////////////////////////

  const isGeneralTab = useIsGeneralTabURL()

  return (
    <SectionWrapper>
      <KCreate>
        <KTabbedForm
          showHeader={!isGeneralTab}
          toolbar={<EventFormToolbar crudMode="editMode" />}
        >
          <StandardEventGeneralTab
            bottomSlot={<EventCreateQuestionReplyList />}
          >
            <KampaayCustomersInput />
          </StandardEventGeneralTab>
          <PhysicalFormTab path="physicals" />
          <LocationFormTab path="locations" />
          <FoodFormTab path="foods" />
          <DrinksFormTab path="drinks" />
          <ProfessionalsTab path="professionals" />
          <BaseProductTab product="music" path="musics" />
          <BaseProductTab product="rent" path="rents" />
          <VirtualFormTab path="virtuals" />
        </KTabbedForm>
      </KCreate>
    </SectionWrapper>
  )
}

export default EventCreate
