import React from 'react'
import { SimpleFormIterator } from 'react-admin'
import KAccordion from 'components/UI/Accordions/KAccordion/KAccordion'
import KDivider from 'components/UI/KDivider'
import TimePicker from 'components/Inputs/TimePicker'
import PricingByRulesInput from 'components/FormComponents/pricing/byRules/PricingByRulesInput'
import PricingRulePriceInputGroup from 'components/FormComponents/pricing/byRules/PricingRulePriceInputGroup'
import KTextInput from 'components/Inputs/KInputs/KTextInput/KTextInput'
import { RAFormFC } from 'types/common'
import KArrayInput from 'components/Inputs/KInputs/KArrayInput'
import { PricingRuleSet } from '@kampaay/common'

/**
 * A: Why not just creat a constant?
 *
 * Q:
 * Some "defaultValue"s are passed by reference,
 * this cause multiple components to share the same value,
 * and mess up the form state.
 * This will prevent that.
 */
const getDefaultRulesetValue = (): Partial<PricingRuleSet>[] => {
  return [
    {
      periodFrom: '--01-01',
      periodTo: '--12-31',
      dayFrom: 'monday',
      dayTo: 'sunday'
    }
  ]
}

const ProfessionalByRulePricingInput: RAFormFC = ({ source }) => {
  return (
    <KAccordion
      accordionTitle="Cost & Prices"
      errorsDependencies={['pricing']}
      defaultExpanded={false}
    >
      <PricingByRulesInput
        defaultValue={getDefaultRulesetValue()}
        source={source}
      >
        <h2>Base Price</h2>
        <PricingRulePriceInputGroup />
        <KDivider />
        <h2>Shifts</h2>
        <KArrayInput source="shiftPricingRules" label="" defaultValue={[]}>
          <SimpleFormIterator>
            <KTextInput source="name" label="form.fields.name" />
            <TimePicker source="hoursFrom" label="From" />
            <TimePicker source="hoursTo" label="To" />
            <PricingRulePriceInputGroup />
          </SimpleFormIterator>
        </KArrayInput>
      </PricingByRulesInput>
    </KAccordion>
  )
}

export default ProfessionalByRulePricingInput
