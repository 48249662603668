import { ListAlt } from '@mui/icons-material'
import LocationSectionCreate from 'resources/catalog/location/sections/LocationSectionCreate'
import LocationSectionEdit from 'resources/catalog/location/sections/LocationSectionEdit'
import LocationSectionList from 'resources/catalog/location/sections/LocationSectionList'

const locationSectionIndex = {
  icon: ListAlt,
  list: LocationSectionList,
  edit: LocationSectionEdit,
  create: LocationSectionCreate
}

export default locationSectionIndex
