import { Receipt } from '@mui/icons-material'
import TransactionList from 'resources/accounting/transaction/TransactionList'
import TransactionEdit from 'resources/accounting/transaction/TransactionEdit'

const transactionIndex = {
  list: TransactionList,
  edit: TransactionEdit,
  show: TransactionList,
  icon: Receipt
}
export default transactionIndex
