import React from 'react'
import { SimpleFormIterator } from 'react-admin'
import { Customization } from 'services/api/entities/events/types/internal/event-models'
import { useForm } from 'hooks/useForm'
import { RAFormFC } from 'types/common'
import EventCustomizationInput from 'components/FormComponents/Customizations/EventCustomizationInput'
import KArrayInput from 'components/Inputs/KInputs/KArrayInput'

export type EventCustomizationArrayInputProps = {
  maxCustomizations?: number
  label?: string
}

const EventCustomizationArrayInput: RAFormFC<
  EventCustomizationArrayInputProps
> = ({ source, maxCustomizations = Infinity, label }) => {
  const { useGetValue, getSource } = useForm<{
    customizations: Customization[]
  }>(source)
  const customizations = useGetValue('customizations')
  ///////////////////////////
  // JSX
  ///////////////////////////
  return (
    <KArrayInput
      source={getSource('customizations')}
      defaultValue={[]}
      label={label}
    >
      <SimpleFormIterator
        disableReordering
        disableAdd={
          !!maxCustomizations && customizations?.length === maxCustomizations
        }
      >
        <EventCustomizationInput />
      </SimpleFormIterator>
    </KArrayInput>
  )
}

export default EventCustomizationArrayInput
