import React from 'react'
import { useForm } from 'hooks/useForm'
import { RAFormFC } from 'types/common'
import { EventItem } from 'services/api/entities/events/types/internal/event-models'
import { SimpleFormIterator } from 'react-admin'
import CheckoutQuestionReplyItem from 'resources/operations/events/EventsComponents/CheckoutQuestionReply/CheckoutQuestionReplyItem'
import KAccordion from 'components/UI/Accordions/KAccordion/KAccordion'
import KArrayInput from 'components/Inputs/KInputs/KArrayInput'

type Props = {
  defaultExpanded?: boolean
}

const QuestionRepliesAccordion: RAFormFC<Props> = ({
  source,
  defaultExpanded
}) => {
  const { getSource } = useForm<EventItem>(source)

  return (
    <KAccordion
      accordionTitle="form.headers.checkoutQuestionReplyAccordionTitle"
      defaultExpanded={defaultExpanded}
    >
      <KArrayInput source={getSource('checkoutQuestionResponses')}>
        <SimpleFormIterator
          fullWidth
          disableAdd
          disableRemove
          disableReordering
        >
          <CheckoutQuestionReplyItem />
        </SimpleFormIterator>
      </KArrayInput>
    </KAccordion>
  )
}

export default QuestionRepliesAccordion
