import React from 'react'
import OptionedProductTailoringPricesInput from 'resources/crm/clients/organization/tailoring/components/optioned/OptionedProductTailoringPricesInput'
import ServiceTailoringsEdit from 'resources/crm/clients/organization/tailoring/ServiceTailoringsEdit'

const PhysicalTailoringsEdit: React.FC = () => {
  return (
    <ServiceTailoringsEdit
      service="physicals"
      productEntity="physicals"
      tailoringsInput={OptionedProductTailoringPricesInput}
    />
  )
}

export default PhysicalTailoringsEdit
