import React, { useCallback, useState } from 'react'
import { Datagrid, NumberField, TextField } from 'react-admin'
import { transactionStatusChoices } from 'services/cfg/form'
import KBulkExportButton from 'components/Buttons/KBulkExportButton'
import KPagination from 'components/Lists/KPagination'
import { BaseListProps } from 'components/Lists/CatalogItemBaseList'
import SectionWrapper from 'components/UI/SectionWrapper'
import TransactionAsidePanel from 'components/FormComponents/Transaction/TransactionAsidePanel'
import AsideShowButton from 'components/FormComponents/Transaction/ShowAsideButton'
import { Drawer } from '@mui/material'
import KList from 'components/Lists/KLists/KList'
import { Transaction } from 'services/api/entities/accounting/transaction/types/internal/transaction-models'
import { FILTER_SOURCES } from '@kampaay/common'
import KDateField from 'components/Fields/KDateField'
import GeoareaEventAndTransactionField from 'components/FormComponents/Event/GeoareaEventAndTransactionField'
import ExportForPurchaseOrderButton from 'components/Buttons/ExportForPurchaseOrderButton'

const TransactionList: React.FC<BaseListProps> = (props) => {
  const [asideRecord, setAsideRecord] = useState<Transaction | undefined>(
    undefined
  )

  const handleAsideRecord = useCallback(
    (p?: Transaction) => {
      if (asideRecord?.id === p?.id) {
        setAsideRecord(undefined)
      } else {
        setAsideRecord(p)
      }
    },
    [asideRecord, setAsideRecord]
  )

  return (
    <SectionWrapper>
      <KList
        {...props}
        pagination={<KPagination rowsPerPageOptions={[80, 160]} />}
        perPage={80}
        exporter={false}
        filterDefaultValues={{
          [FILTER_SOURCES.eventStatus.in]: [transactionStatusChoices[0].id]
        }}
      >
        <Datagrid
          rowClick="edit"
          bulkActionButtons={
            <div className="flex gap-2">
              <KBulkExportButton />
              <ExportForPurchaseOrderButton />
            </div>
          }
        >
          <NumberField
            source="id"
            label="form.fields.eventId"
            sortable={false}
          />
          <TextField source="customerName" sortable={false} />
          <TextField
            label="form.fields.email"
            source="customerEmail"
            sortable={false}
          />
          <KDateField source="eventDate" />
          <TextField source="customerCluster" sortable={false} />
          <TextField source="invoiceStatus" sortable={false} />
          <TextField source="paymentMethod" sortable={false} />
          <TextField source="paymentStatus" sortable={false} />
          <TextField source="accountingStatus" sortable={false} />

          <GeoareaEventAndTransactionField label="form.fields.geoareaPreset" />

          <AsideShowButton cb={handleAsideRecord} />
        </Datagrid>
      </KList>

      <Drawer variant="persistent" open={!!asideRecord} anchor="right">
        <TransactionAsidePanel
          record={asideRecord}
          handleClose={() => setAsideRecord(undefined)}
        />
      </Drawer>
    </SectionWrapper>
  )
}

export default TransactionList
