import { APIMailingList } from 'services/api/entities/mailing-list/types'
import { createMockBuilder } from '@kampaay/common'

const buildBaseMock = (): APIMailingList => ({
  id: 1,
  name: 'Mocked mailing list name',
  description: 'Mocked mailing list description',
  addresses: ['mocked1@address.com', 'mocked2@address.com']
})

export const buildAPIMailingListMock = createMockBuilder(buildBaseMock)
