import React, { useEffect } from 'react'
import { KBooleanInput } from 'components/Inputs/KInputs/KBooleanInput'
import type { RAFormFC } from 'types/common'
import { useForm } from 'hooks/useForm'
import type { OrganizationBase } from 'services/api/entities/crm/organizations/types/internal/organization-model'
import KAccordion from 'components/UI/Accordions/KAccordion/KAccordion'
import KNumberInput from 'components/Inputs/KInputs/KNumberInput/KNumberInput'
import { useTranslate } from 'ra-core'
import { useFormContext } from 'react-hook-form'
import KArrayInput from 'components/Inputs/KInputs/KArrayInput'
import { SimpleFormIterator } from 'react-admin'
import ApproverOptionInput from 'components/FormComponents/Organization/ApprovalFlows/ApproverOptionInput'
import { Alert } from '@mui/material'
import { emailApprovalTypeChoicesOrganization } from 'services/cfg/form'

const OrganizationApprovalFlowInput: RAFormFC = ({ source }) => {
  const { getSource } = useForm<OrganizationBase>(source)
  const { watch, setValue } = useFormContext()
  const translate = useTranslate()

  const approvalEnabled = watch(getSource('approvalEnabled'))
  const hasCustomApprovalFlow = watch(getSource('customApprovalFlow'))

  const { getValues } = useFormContext<OrganizationBase>()
  const approvalSteps = getValues('approvalSteps')

  const disableActions = approvalSteps?.some(
    (step) => step.approvalType === 'groupAdmin'
  )

  useEffect(() => {
    if (
      approvalSteps?.filter((step) => step.approvalType === 'groupAdmin').length
    ) {
      setValue(
        'approvalSteps',
        approvalSteps?.filter((step) => step.approvalType === 'groupAdmin')
      )
    }
  }, [approvalSteps?.map((approvalStep) => approvalStep.approvalType).join()])

  useEffect(() => {
    if (!approvalEnabled) {
      setValue(getSource('customApprovalFlow'), false)
    }
  }, [approvalEnabled])

  return (
    <KAccordion accordionTitle="form.headers.approvalFlow">
      <div className="w-full rounded border-2 border-solid border-orange-800 bg-orange-300 px-6 py-3">
        <p className="my-0 font-semibold text-orange-800">
          {translate('form.fields.approvalThresholdDisclaimer')}
        </p>
      </div>
      <div className="mt-4 flex items-center gap-x-4">
        <KBooleanInput
          source={getSource('approvalEnabled')}
          label="form.fields.approvalEnabled"
        />

        {approvalEnabled && (
          <KBooleanInput
            source={getSource('customApprovalFlow')}
            label="form.fields.customApprovalFlow"
          />
        )}

        <KNumberInput
          source={getSource('approvalThreshold')}
          label="form.fields.approvalThreshold"
          disabled={!approvalEnabled}
        />
      </div>

      {hasCustomApprovalFlow && (
        <div>
          <Alert severity="info">
            {translate('approvalFlows.alert.customFlowAppliesToAllMembers')}
          </Alert>

          <KArrayInput
            source={getSource('approvalSteps')}
            label={translate('form.fields.approvalSteps')}
          >
            <SimpleFormIterator disableAdd={disableActions}>
              <ApproverOptionInput
                approvalChoices={emailApprovalTypeChoicesOrganization}
              />
            </SimpleFormIterator>
          </KArrayInput>
        </div>
      )}
    </KAccordion>
  )
}

export default OrganizationApprovalFlowInput
