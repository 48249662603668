import React from 'react'
import { KInputProps, useKInput } from 'hooks/useKInput/useKInput'
import { DateTimeInput, DateTimeInputProps } from 'react-admin'

export type KDateTimeInputProps = KInputProps & DateTimeInputProps

const KDateTimeInput: React.FC<KDateTimeInputProps> = (props) => {
  const { isRequired } = useKInput(props)

  return <DateTimeInput {...props} isRequired={isRequired} />
}

export default KDateTimeInput
