import { UseValidationSchema, YupSchema } from 'services/yup/types'
import * as yup from 'yup'
import { yupString } from 'services/yup/custom-schemas/string'
import { yupNumber } from 'services/yup/custom-schemas/number'
import { yupObject } from 'services/yup/custom-schemas/object'
import { yupIgnore } from 'services/yup/helpers'
import { yupArray } from 'services/yup/custom-schemas/array'
import { yupBoolean } from 'services/yup'
import { standardPricingArrayVS } from 'services/validations/catalog/standard-pricing/standardPricingArray'
import { TestConfig, TestFunction } from 'yup'
import { useProductWithUpsellingVS } from 'services/validations/catalog/productWithUpselling'
import {
  OptionItem,
  APIPriceRange,
  OptionGroup,
  OptionedProduct
} from '@kampaay/common'

const validateMaxSelection: TestFunction<number | null> = (v) =>
  (typeof v === 'number' && v > 0) || !v

export const validateMaxSelectionCFG: TestConfig<number | null> = {
  test: validateMaxSelection,
  message: 'form.validations.nullOrGtn0'
}

const formOptionItemVS: YupSchema<OptionItem, APIPriceRange[] | undefined> =
  yupObject().shape({
    id: yupIgnore(),
    name: yupString().required(),
    description: yupString().required(),
    image: yupIgnore(),
    pricing: yup.lazy((_, { parent }) =>
      parent.isPriced ? standardPricingArrayVS() : yupIgnore<undefined>()
    ),
    isPriced: yupBoolean().required(),
    isDefault: yupBoolean()
  })

const formOptionGroupVS: YupSchema<OptionGroup, APIPriceRange[] | undefined> =
  yupObject().shape({
    id: yupIgnore(),
    name: yupString().required(),
    description: yupString().required(),
    maxSelection: yupIgnore<number | null>().test(validateMaxSelectionCFG),
    infoBox: yupBoolean().required(),
    acceptsQuantity: yupBoolean().required(),
    items: yupArray(formOptionItemVS).min(1)
  })

export const useOptionedProductVS: UseValidationSchema<
  OptionedProduct,
  APIPriceRange[] | undefined
> = () =>
  useProductWithUpsellingVS().shape({
    pricing: standardPricingArrayVS().min(1),
    minQuantity: yupNumber().required(),
    options: yupArray(formOptionGroupVS),
    hasFlexibleOptions: yupBoolean()
  })
