import React, { type FC, type ReactNode, useCallback, useMemo } from 'react'
import type { EventPlaceholder } from 'services/api/entities/event-placeholder/types/internal/event-placeholder-models'
import {
  AttachMoney,
  CalendarMonth,
  Flag,
  LocationOn,
  People,
  Person,
  Subject,
  WatchLater
} from '@mui/icons-material'
import styles from 'components/UI/Organization/Calendar/ItemPopover/OrganizationCalendarItemPopover.module.css'
import eventsStyles from 'components/UI/Organization/Calendar/ItemEvents/OrganizationCalendarItemEvents.module.css'
import {
  calendarPlaceholderDefaultColor,
  formatCalendarItemTimeRange
} from 'services/utils/organization/calendar'
import OrganizationCalendarItemEvents from 'components/UI/Organization/Calendar/ItemEvents/OrganizationCalendarItemEvents'
import { Popover } from '@mui/material'
import { useTranslate } from 'react-admin'
import { getAsCurrency } from '@kampaay/common'

type PopoverRow = {
  icon: ReactNode
  value: ReactNode | string | number | undefined
  style?: string
}

type Props = {
  placeholder: EventPlaceholder
  anchor: HTMLElement | null
  setAnchor: (value: HTMLElement | null) => void
}

const OrganizationCalendarItemPopover: FC<Props> = ({
  placeholder,
  anchor,
  setAnchor
}) => {
  const translate = useTranslate()

  const formatBudget = useCallback((budget: number): string => {
    return getAsCurrency(budget, translate('country.currencyName'))
  }, [])

  const placeholderBackground = useMemo(
    (): string =>
      placeholder.category?.color || calendarPlaceholderDefaultColor,
    [placeholder]
  )

  const closePopover = useCallback(() => setAnchor(null), [])

  const popoverRows: PopoverRow[] = useMemo(
    () =>
      [
        {
          icon: <Person />,
          value: placeholder.customerName
        },
        {
          icon: (
            <div
              className={styles.categoryColor}
              style={{ backgroundColor: placeholderBackground }}
            />
          ),
          value: placeholder.category?.name
        },
        {
          icon: <LocationOn />,
          value: placeholder.address
        },
        {
          icon: <CalendarMonth />,
          value: formatCalendarItemTimeRange(
            placeholder.startDate,
            placeholder.endDate
          )
        },
        {
          icon: <WatchLater />,
          value: formatCalendarItemTimeRange(
            placeholder.startTime,
            placeholder.endTime
          )
        },
        {
          icon: <People />,
          value: placeholder.guestsNumber
        },
        {
          icon: <AttachMoney />,
          value: placeholder.budget
            ? formatBudget(placeholder.budget)
            : undefined
        },
        {
          icon: <Flag />,
          value: placeholder.events?.length ? (
            <OrganizationCalendarItemEvents items={placeholder.events} />
          ) : undefined,
          style: eventsStyles.eventsRow
        },
        {
          icon: <Flag />,
          value: placeholder.concierge ? (
            <OrganizationCalendarItemEvents items={[placeholder.concierge]} />
          ) : undefined,
          style: eventsStyles.eventsRow
        },
        {
          icon: <Subject />,
          value: placeholder.notes ? (
            // This is needed as the additional notes of a placeholder could be a string that
            // contains HTML tags, so we need to render it accordingly
            <div dangerouslySetInnerHTML={{ __html: placeholder.notes }} />
          ) : undefined,
          style: eventsStyles.eventsRow
        }
      ].filter((row) => !!row.value),
    [placeholder]
  )

  return (
    <Popover
      anchorEl={anchor}
      open={!!anchor}
      onClose={closePopover}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
    >
      <div className={styles.item}>
        <h4 className={styles.name}>{placeholder.name}</h4>
        {popoverRows.map((row, index) => (
          <div key={index} className={row.style || styles.row}>
            {row.icon} {row.value}
          </div>
        ))}
      </div>
    </Popover>
  )
}

export default OrganizationCalendarItemPopover
