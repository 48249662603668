import { standardPricingVS } from 'services/validations/catalog/standard-pricing/standardPricing'
import { yupArray } from 'services/yup/custom-schemas/array'
import { KTestConfig, KTestFunction } from 'services/yup'
import { APIPriceRange } from '@kampaay/common'

type StandardPricingValidationOptions = {
  allowFree?: boolean
}

// !VALIDATIONS
export const uniqueMaxQuantityValidation: KTestFunction<APIPriceRange[]> = (
  prices
) => {
  return !!prices?.every(
    (currentPrice) =>
      prices.filter((price) => price.maxQuantity === currentPrice.maxQuantity)
        .length === 1
  )
}

export const hasEveryRangeAPriceValidation: KTestFunction<APIPriceRange[]> = (
  prices
) => {
  return !!prices?.every(
    (currentPrice) =>
      currentPrice.fixedPrice !== undefined ||
      currentPrice.variablePrice !== undefined
  )
}

// !CONFIGS
const isEveryMaxQuantityDifferent: KTestConfig<APIPriceRange[]> = {
  name: 'isEveryMaxQuantityDifferent',
  test: uniqueMaxQuantityValidation,
  message: 'form.validations.noDuplicateMaxQuantityInPricing'
}

const hasEveryRangeAPrice: KTestConfig<APIPriceRange[]> = {
  name: 'hasEveryRangeAPrice',
  test: hasEveryRangeAPriceValidation,
  message: 'form.validations.everyPriceRangeHasPrice'
}

// !VS
export const standardPricingArrayVS = (
  options?: StandardPricingValidationOptions
) => {
  if (options?.allowFree) {
    return yupArray(standardPricingVS).test(isEveryMaxQuantityDifferent)
  }
  return yupArray(standardPricingVS)
    .test(isEveryMaxQuantityDifferent)
    .test(hasEveryRangeAPrice)
}
