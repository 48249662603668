import type { ProductServiceName } from 'types/common'

export const PRODUCT_SERVICES_NAMES: ProductServiceName[] = [
  'drinkService',
  'foodService',
  'locationService',
  'musicService',
  'rentService',
  'virtualService',
  'physicalService'
]
