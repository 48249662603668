import { APIWriteFunction } from 'services/api/api'
import { ExportDataToAPI } from 'services/api/entities/accounting/transactionExportForPurchaseOrder/types/api/request-models'
import { ExportData } from 'services/api/entities/accounting/transactionExportForPurchaseOrder/types/internal'

export const transactionExportForPurchaseOrderWrite: APIWriteFunction = async (
  exportData: ExportData
): Promise<ExportDataToAPI> =>
  await {
    eventExportData: exportData.eventExportData.map(
      ({ lastExportDate: _lastExportDate, conciergeExportData, ...rest }) => ({
        ...rest,
        conciergeExportData: conciergeExportData.map(
          ({
            supplierName: _supplierName,
            supplierBusinessName: _supplierBusinessName,
            supplierEmail: _supplierEmail,
            supplierVat: _supplierVat,
            ...rest
          }) => ({
            ...rest
          })
        )
      })
    )
  }
