import React from 'react'

type Props = {
  label: string
  children: JSX.Element
  sortable?: boolean
}

/**
 * This can be used for cases when you need to display a non-field content inside
 * `Datagrid` or other list-like cell, where you can't supply `label` to be used as a column header
 *
 * @param label will be used as column header
 * @returns
 */
const ListItemWithLabel: React.FC<Props> = ({ children }) => <>{children}</>

export default ListItemWithLabel
