import KCreate from 'components/Forms/KCreate/KCreate'
import React from 'react'
import { KSimpleForm } from 'components/Forms/KForms/KSimpleForm'
import KTextInput from 'components/Inputs/KInputs/KTextInput/KTextInput'

const PocCreate: React.FC = () => {
  return (
    <KCreate>
      <KSimpleForm>
        <KTextInput source="title" />
      </KSimpleForm>
    </KCreate>
  )
}

export default PocCreate
