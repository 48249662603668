import React from 'react'
import LocalizedRichTextInput from 'components/Inputs/Localized/LocalizedRichTextInput'
import { useForm } from 'hooks/useForm'
import { RAFormFC } from 'types/common'

/**
 * Description input
 * Is a localized rich text input with description like source
 * @param source
 */
const DescriptionInput: RAFormFC = ({ source }) => {
  const { getSource } = useForm<{ description: string }>(source)

  return (
    <LocalizedRichTextInput
      source={getSource('description')}
      label="form.fields.description"
      fullWidth
    />
  )
}

export default DescriptionInput
