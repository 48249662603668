import React, { useMemo } from 'react'
import { Menu } from 'react-admin'
import { useParams } from 'react-router-dom'
import { ServiceCollectionName } from 'types/common'
import { entries } from 'services/utils'
import { APIEntity, UpsellingEntity } from 'services/api/entities'
import KCollapse from 'components/UI/Accordions/KCollapse'
import OrganizationMenuItem, {
  OrganizationMenuItemProps
} from 'components/FormComponents/Organization/OrganizationMenuItem'

export const mainServiceMenuMap: Record<ServiceCollectionName, string> = {
  foods: 'form.tabs.food',
  locations: 'form.tabs.location',
  physicals: 'form.tabs.physicals',
  virtuals: 'form.tabs.virtuals',
  drinks: 'form.tabs.drink',
  professionals: 'form.tabs.professionals',
  musics: 'form.tabs.music',
  rents: 'form.tabs.rent'
}

const otherServiceMenuMap: Record<
  Extract<APIEntity, UpsellingEntity | 'logistics'>,
  string
> = {
  foodsupselling: 'resources.foodsupselling.name',
  locationupselling: 'resources.locationupselling.name',
  physicalupselling: 'resources.physicalupselling.name',
  virtualupselling: 'pos.menu.virtualsUpselling',
  logistics: 'resources.logistics.name'
}

const OrganizationMenu = () => {
  const { id: organizationId } = useParams()

  const basePath = `/organization/${organizationId}`
  const membershipsPath = `${basePath}/members`
  const budgetsPath = `${basePath}/budgets`
  const subGroupPath = `${basePath}/sub-groups`
  const eventPlaceholdersPath = `${basePath}/event-placeholders`
  const calendarPath = `${basePath}/calendar`
  const tailoringPath = `${basePath}/tailoring`

  const baseItems: OrganizationMenuItemProps[] = useMemo(
    () => [
      {
        link: basePath,
        text: 'resources.organization.menu.general',
        defaultActive: true
      },
      {
        link: membershipsPath,
        text: 'resources.organization.menu.members'
      },
      {
        link: subGroupPath,
        text: 'resources.organization.menu.subGroups'
      },
      {
        link: budgetsPath,
        text: 'resources.organization.menu.budgets'
      },
      {
        link: eventPlaceholdersPath,
        text: 'resources.organization.menu.plannedEvents'
      },
      {
        link: calendarPath,
        text: 'resources.organization.menu.calendar'
      }
    ],
    [organizationId]
  )

  return (
    <Menu className="my-0 min-w-[220px] border-r-2 border-neutral-400 py-0">
      {baseItems.map((item) => (
        <OrganizationMenuItem key={item.link} {...item} />
      ))}
      <KCollapse
        collapseHeadingLevel="h4"
        style={{ width: 'initial', border: 'none', boxShadow: 'none' }}
        collapseTitle="resources.organization.menu.catalog"
        defaultExpanded
      >
        {entries({ ...mainServiceMenuMap, ...otherServiceMenuMap }).map(
          ([collection, name]) => (
            <OrganizationMenuItem
              key={`${tailoringPath}/${collection}`}
              link={`${tailoringPath}/${collection}`}
              text={name}
            />
          )
        )}
      </KCollapse>
    </Menu>
  )
}

export default OrganizationMenu
