import React from 'react'
import { FormTabProps } from 'react-admin'
import ExperienceFormTab from 'resources/operations/events/eventsFormTabs/ExperienceTab/ExperienceFormTab'

type Props = Omit<FormTabProps, 'label'>

const VirtualFormTab: React.FC<Props> = (props) => {
  return (
    <ExperienceFormTab
      {...props}
      experience="virtuals"
      experienceService="virtualService"
    />
  )
}

export default VirtualFormTab
