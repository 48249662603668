import React from 'react'
import { EditProps } from 'react-admin'
import SectionWrapper from 'components/UI/SectionWrapper'
import EventEditContent from 'resources/operations/events/EventsComponents/EventEditContent'
import EventEditReadonlyFields from 'resources/operations/events/EventEditReadOnlyFields'
import { Card, SxProps } from '@mui/material'
import KEdit from 'components/Forms/KEdit/KEdit'

export const editCardSxStyle: SxProps = {
  '& .RaEdit-card': {
    boxShadow: 'none',
    border: 'none',
    backgroundColor: 'transparent',
    overflow: 'visible',
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5em'
  }
}

const EventEdit: React.FC<EditProps> = () => {
  return (
    <SectionWrapper>
      <KEdit sx={editCardSxStyle}>
        <>
          <Card className="pb-1 pt-1">
            <EventEditReadonlyFields />
          </Card>

          <EventEditContent crudMode="editMode" />
        </>
      </KEdit>
    </SectionWrapper>
  )
}

export default EventEdit
