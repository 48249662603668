import { FormDataConsumer } from 'ra-core'
import { styled } from '@mui/material/styles'
import { ArrayInput, SimpleFormIterator } from 'ra-ui-materialui'
import React from 'react'
import { FormDataConsumerProps } from 'types/common'
import { APIOptionGroupSnapshot } from 'services/api/entities/events/types/api/response-models'
import { getSelectionChoices } from 'resources/operations/events/utils'
import { distinctById } from 'services/utils'
import { useLangContext } from 'contexts/langContext'
import {
  OptionGroup,
  OptionedProduct,
  WithIdAndName,
  getById,
  parseMultilangJSON
} from '@kampaay/common'
import EventBaseSelectionArrayInput from 'components/FormComponents/EventBaseSelectionArrayInput'
import {
  OptionGroupSelection,
  OptionedProductSelection
} from 'services/api/entities/events/types/internal/selections'

const PREFIX = 'OptionSelectionInput'

const classes = {
  arrayInputPadding: `${PREFIX}-arrayInputPadding`
}

const StyledArrayInput = styled(ArrayInput)({
  [`&.${classes.arrayInputPadding}`]: {
    padding: '16px 0 0 0'
  }
})

type Props = {
  source: string
  isObsolete?: boolean
  selectedProduct?: OptionedProduct
  optionSelections?: OptionGroupSelection[]
  eventOptioned: OptionedProductSelection
}

// when we are working with an already built selection we can safely assume that all the properties in the `OptionGroupSelection` are defined
type OptionGroupType = Required<OptionGroupSelection> | OptionGroup

const OptionSelectionInput: React.FC<Props> = ({
  source,
  isObsolete,
  selectedProduct,
  eventOptioned
}) => {
  const { currentLang } = useLangContext()

  if (!eventOptioned) return null

  const selectableOptions = isObsolete
    ? eventOptioned.optionSelections ?? []
    : selectedProduct?.options ?? []

  // These checks populate the formData when we add a new food to the selection
  // in order to not have the form break on fields that rely on that data
  if (!eventOptioned.optionSelections?.length)
    eventOptioned.optionSelections = initSelectedOptions(selectedProduct)

  // Here we are setting the default quantity to the option items that do not accept quantity
  if (
    eventOptioned.optionSelections.some(
      (e) =>
        !e.acceptsQuantity &&
        e.items.some((item) => item?.quantity !== eventOptioned.quantity)
    )
  )
    eventOptioned.optionSelections =
      setDefaultQuantityToOptionItemsThatDoesNotAcceptQuantity(eventOptioned)

  return (
    // Option Groups
    <StyledArrayInput
      source={source}
      label="Options"
      className={classes.arrayInputPadding}
    >
      <SimpleFormIterator disableAdd disableRemove disableReordering>
        <FormDataConsumer>
          {({
            scopedFormData: optionGroup,
            getSource
          }: FormDataConsumerProps<unknown, APIOptionGroupSnapshot>) => {
            const selectedOptionGroup = getById(
              selectableOptions as OptionGroupType[],
              optionGroup?.id
            )

            const updatedChoices = distinctById(
              optionGroup?.items.flatMap((i) =>
                getSelectionChoices(
                  i,
                  (selectedOptionGroup?.items ?? []) as WithIdAndName[]
                )
              ) ?? []
            )

            const translatedArrayLabel = parseMultilangJSON(
              selectedOptionGroup?.name ?? ''
            )[currentLang]
            // Option Items

            // if the selected items for an option group are equal to its maxSelection (if it has one), we disallow kampaayers to add further option items to the selection
            const disableAdd =
              (!!optionGroup &&
                optionGroup?.items.length ===
                  selectedOptionGroup?.maxSelection) ||
              isObsolete

            return (
              <EventBaseSelectionArrayInput
                arrayInputLabel={translatedArrayLabel}
                source={getSource!('items')}
                choices={updatedChoices}
                hideQuantity={!selectedOptionGroup?.acceptsQuantity}
                label={translatedArrayLabel}
                disableAdd={disableAdd}
                disableInputs={isObsolete}
              />
            )
          }}
        </FormDataConsumer>
      </SimpleFormIterator>
    </StyledArrayInput>
  )
}

export default OptionSelectionInput

const initSelectedOptions = (
  selectedProduct?: OptionedProduct
): APIOptionGroupSnapshot[] =>
  selectedProduct?.options?.map((o) => ({
    ...o,
    maxSelection: o.maxSelection ? o.maxSelection : undefined,
    items: []
  })) ?? []

const setDefaultQuantityToOptionItemsThatDoesNotAcceptQuantity = (
  formOptionedSelection: OptionedProductSelection
): OptionGroupSelection[] =>
  formOptionedSelection.optionSelections?.map((e) => ({
    ...e,
    items: e.items.map((item) => ({
      ...item,
      quantity:
        (e.acceptsQuantity ? item?.quantity : formOptionedSelection.quantity) ??
        0
    }))
  })) ?? []
