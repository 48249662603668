import { type WithId } from '../../types'
import { getId } from '../object'

export const objArrayIntersection = <T extends WithId>(...arrays: T[][]) => {
  const [first, ...others] = arrays
  return (
    first?.reduce(
      (acc, itm) => [
        ...acc,
        ...(others.every((arr) => arr.map(getId).includes(itm.id)) ? [itm] : [])
      ],
      [] as T[]
    ) ?? []
  )
}

/**
 * Takes a collection containing objects with an id property and an id
 * and returns the object with the given id or undefined if not found
 * @param collection - collection of objects with an id property
 * @param id - id of the object to find
 */
export const getById = <K extends { id: number | string }>(
  collection: K[],
  id?: number | string
): K | undefined => {
  if (!id) return undefined

  return collection.find((item) => item.id === id)
}

export const getWithMaxValue = <T>(array: T[], extractor: (n: T) => number) => {
  const maxValue = Math.max(...array.map(extractor))
  return array.find((n) => extractor(n) === maxValue)
}
