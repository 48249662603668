import { YupSchema } from 'services/yup/types'
import {
  yupArray,
  yupIgnore,
  yupNumber,
  yupObject,
  yupString
} from 'services/yup'
import * as yup from 'yup'
import { TestConfig, TestFunction } from 'yup'
import { getDayOfYear } from 'date-fns'
import {
  areDatesOverlapping,
  areWeekDaysOverlapping,
  PricingRuleDatesNumbers
} from 'services/utils/validation'
import {
  DayMonthLabel,
  PricingRuleSet,
  WeekDay,
  WeekDaysEnum
} from '@kampaay/common'

export const withCostAndPriceVS = yupObject().shape({
  cost: yupNumber().min(0).required(),
  price: yupNumber()
    .min(yup.ref<number>('cost', { map: (cost) => Number(cost) + 1 }))
    .required()
})

const validateRulesNotOverlapping: TestFunction<PricingRuleSet[]> = (
  values
) => {
  const rules = values
    ?.filter((r) => r?.periodFrom && r?.periodTo && r?.dayFrom && r?.dayTo)
    .map((r) => ({
      periodFrom: getDayOfYear(new Date(r!.periodFrom!)),
      periodTo: getDayOfYear(new Date(r!.periodTo!)),
      dayFrom: WeekDaysEnum[r!.dayFrom!],
      dayTo: WeekDaysEnum[r!.dayTo!]
    })) as PricingRuleDatesNumbers[]

  return !rules?.some((r1) =>
    rules
      // Checking that we are not comparing rule with itself
      .filter((r2) => r1 !== r2)
      .some(
        (r2) => areDatesOverlapping(r1, r2) && areWeekDaysOverlapping(r1, r2)
      )
  )
}

export const areRulesNotOverlappingCFG: TestConfig<PricingRuleSet[]> = {
  test: validateRulesNotOverlapping,
  message: 'form.validations.areRulesNotOverlappingCFG'
}

export const byRulePricingCommonVS: YupSchema<PricingRuleSet> =
  yupObject().shape({
    id: yupIgnore(),
    minHours: yupNumber().required().min(0),
    periodFrom: yupString<DayMonthLabel>().required(),
    periodTo: yupString<DayMonthLabel>().required(),
    dayFrom: yupString<WeekDay>().required(),
    dayTo: yupString<WeekDay>().required(),
    minHoursRules: yupArray()
  })
