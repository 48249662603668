import {
  ProposalItem,
  parseMultilangToCurrentLang,
  proposalItemCopyStorage,
  duplicateProposalItem,
  MainProduct
} from '@kampaay/common'
import CopyAllIcon from '@mui/icons-material/CopyAll'
import { Button } from '@mui/material'
import { useKRecordContext } from 'hooks/useKRecordContext'
import { useTranslate } from 'ra-core'
import React, { useCallback } from 'react'
import { useNotify } from 'react-admin'

const transformProductToProposalItem = (
  product: MainProduct
): Omit<ProposalItem, 'id'> => ({
  name: parseMultilangToCurrentLang(product.name),
  presentation: {
    narrativeDescription: parseMultilangToCurrentLang(product.description),
    images: product.images.map((image) => ({ image })),
    showImages: !!product.images.length,
    showSupplier: false,
    showDescription: !!product.description,
    layout: 'standard',
    supplierImages: []
  },
  isSelectable: false,
  isPriceIncluded: true,
  isOutOfScope: false
})

const CopyAsProposalItemButton: React.FC = () => {
  const notify = useNotify()
  const translate = useTranslate()
  const record = useKRecordContext<MainProduct>()

  const onClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()

      if (!!record) {
        const productAsProposalItem = transformProductToProposalItem(record)

        const copiedProposalItem = duplicateProposalItem(
          productAsProposalItem,
          'standardCatalog',
          undefined
        )
        proposalItemCopyStorage.set(copiedProposalItem)
        notify('notification.info.productCopied', {
          type: 'info'
        })
      }
    },
    [notify, record]
  )

  return (
    <Button onClick={onClick}>
      <CopyAllIcon className="mr-1" />
      {translate('button.label.copy')}
    </Button>
  )
}

export default CopyAsProposalItemButton
