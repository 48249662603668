import React, { ReactNode, useState } from 'react'
import { EditProps } from 'react-admin'
import KEdit from 'components/Forms/KEdit/KEdit'

const comparator = (choice: string) => (choices: Record<string, JSX.Element>) =>
  choices[choice]

type Comparator = Record<string, JSX.Element>

/**
 * This component will render buttons to switch between different solutions
 * we want to compare (i.e: for performances reasons)
 *
 * @param param0
 * @returns
 */
export const EditWithComparator: React.FC<
  EditProps & {
    children: (params: (arg0: Comparator) => JSX.Element) => ReactNode
  }
> = ({ children, ...rest }) => {
  const [choices, setChoices] = useState<Comparator>({})
  const [choice, setChoice] = useState('')

  const choiceLabels = Object.keys(choices)
  const comp = (cho: Comparator) => {
    // update choices just when initializing
    if (Object.keys(cho).length !== Object.keys(choices).length) {
      setChoices(cho)
      setChoice(Object.keys(cho)[0])
    }
    return comparator(choice)(cho)
  }

  return (
    <>
      <div>
        {choiceLabels.map((item) => (
          <button
            key={item}
            type="button"
            onClick={() => setChoice(item)}
            disabled={item === choice}
          >
            {item}
          </button>
        ))}
      </div>
      <KEdit {...rest}>{children(comp)}</KEdit>
    </>
  )
}
