import type { FilterOperator, FilterSource, FilterSourcesRecord } from './types'

export * from './types'

const buildFilterSource = <
  Property extends string,
  Operator extends FilterOperator
>(
  property: Property,
  operator: Operator
): { [K in Operator]: FilterSource<Property, Operator> } => {
  return { [operator]: `${property}~${operator}~` } as {
    [K in Operator]: FilterSource<Property, Operator>
  }
}

const buildFilterSourcesRecord = <
  Property extends string,
  Operators extends FilterOperator[]
>(
  fieldName: Property,
  operators: Operators
): FilterSourcesRecord<Property, Operators> => {
  if (operators.length === 0) {
    return {} as FilterSourcesRecord<Property, Operators>
  }

  let sources = {} as FilterSourcesRecord<Property, Operators>

  operators.forEach((operator) => {
    Object.assign(sources, buildFilterSource(fieldName, operator))
  })

  return sources
}

const buildFilters = <T extends Record<string, FilterOperator[]>>(
  filters: T
) => {
  let sources = {} as { [K in keyof T]: FilterSourcesRecord<K & string, T[K]> }

  for (const key in filters) {
    sources[key] = buildFilterSourcesRecord(
      key,
      filters[key]!
    ) as FilterSourcesRecord<
      Extract<keyof T, string> & string,
      T[Extract<keyof T, string>]
    >
  }

  return sources
}

/**
 * The filterable fields exposed by the BE
 */
export const FILTER_SOURCES = buildFilters({
  search: ['eq'], // The search filter is a special case, the operator is ignored and could be anything
  address: ['contains', 'in', 'insensitivein'],
  agreementInForce: ['in'],
  rebateAndCommission: ['gt'],
  areas: ['in'],
  acceptedAt: ['gte', 'lt'],
  capacity: ['gte'],
  customerEmail: ['contains'],
  customerId: ['eq'],
  customerName: ['contains'],
  date: ['gte', 'lte', 'lt'],
  startDate: ['lt', 'gte', 'lte'],
  startDateSort: ['eq'], // Boolean filter. Sorts by start date descending, but items with no start date are always first
  endDate: ['lte', 'lt', 'gte'],
  eventDate: ['gte', 'lt'],
  eventStatus: ['eq', 'neq', 'in'],
  facetCode: ['contains'],
  firstName: ['contains'],
  geoAreaPresets: ['in'],
  geoAreaPresetId: ['in'],
  geoCodedAddress: ['inside', 'eq', 'insideornull'],
  geoCodedAddressSort: ['eq'], // Boolean filter. Sorts by geoCodedAddress based on the truthyness of the value
  salesCoordinatorName: ['contains'],
  kampaayerCoordinatorName: ['contains'],
  kampaayerCoordinators: ['in'],
  role: ['eq', 'in'],
  eventCategory: ['eq', 'in'],
  isPublished: ['eq'],
  hasConcierge: ['eq'],

  // hasAccount and hasNoAccount will be treated as a special case
  // because BE is evil
  // (they will always be set to: true)
  // https://gitlab.com/kampaay/general/-/issues/1978#note_1429089024
  hasAccount: ['eq'],
  hasNoAccount: ['eq'],
  id: ['eq', 'in', 'nin'],
  eventId: ['eq', 'isnotnull'],
  conciergeId: ['eq', 'isnotnull'],
  isFromConcierge: ['eq'],
  lastName: ['contains'],
  location: ['eq'],
  name: ['eq', 'contains', 'in', 'insensitivein'],
  fullName: ['contains'],
  nickname: ['contains'],
  organizationId: ['eq'],
  organizationName: ['contains'],
  lastAcceptedAt: ['gte', 'lte', 'lt'],
  createdAt: ['gte', 'lte', 'lt'],
  confirmedAt: ['gte', 'lte', 'lt'],
  hubSpotIntegration: ['eq'],
  peopleCapacity: ['gte'],
  requestStatus: ['in', 'nin'],
  roomCapacity: ['gte'],
  services: ['allin'],
  supplierService: ['allin', 'in'],
  status: ['nin', 'eq', 'neq', 'in'],
  supplierName: ['contains'],
  supplierType: ['in'],
  supplierId: ['eq', 'in'],
  suppliers: ['in'],
  isPublic: ['eq'],
  isTrial: ['eq'],
  userEmail: ['contains'],
  hasNoBudget: ['eq'],
  hasAttachment: ['eq'],
  hasNoMembership: ['eq'],
  hasApprovalEnabled: ['eq'],
  hasApprovalRequired: ['eq'],
  hasEventPlaceholder: ['eq'],
  isActive: ['eq'],
  hasActiveBudget: ['eq'],
  hasSubGroup: ['eq'],
  organizationBudgetId: ['eq'],
  isOrganizationOwner: ['eq'],
  salesOwnerId: ['in'],
  hasEvents: ['eq'],
  kampaayerId: ['in', 'eq'],
  email: ['contains'],
  nameOrEmail: ['contains'],
  fullNameOrEmail: ['contains'],
  // supplier venue filters
  roomCount: ['gte'],
  meetingSpaceCount: ['gte'],
  totalRoomsCapacity: ['gte'],
  maxMeetingSpacesCapacity: ['gte'],
  isUserConfirmed: ['eq'],
  userId: ['eq'],
  userInfo: ['eq'],
  deadline: ['gte', 'lte'],
  validUntil: ['gte', 'lte'],
  tags: ['in'],
  semanticThreshold: ['eq'],
  budgetPerPerson: ['gte', 'lte'],
  guestsNumber: ['gte', 'lte'],
  month: ['in'],
  publicOrCustomerId: ['eq', 'in'],
  publicOrOrganizationId: ['eq', 'in'],
  userCustomerId: ['eq'],
  userSupplierId: ['eq', 'in'],
  foodId: ['eq', 'in'],
  physicalExperienceId: ['eq', 'in'],
  professionalId: ['eq', 'in'],
  country: ['eq']
})
