import React, { useCallback } from 'react'
import {
  Button,
  ChipField,
  ListContextProvider,
  useGetMany,
  useList,
  useTranslate
} from 'react-admin'
import CustomReferenceInput from 'components/Inputs/CustomReferenceInput'
import KAutocompleteInput from 'components/Inputs/KInputs/KAutocompleteInput'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { FILTER_SOURCES, OrganizationMembership } from '@kampaay/common'
import { useParams } from 'react-router-dom'
import OrganizationSubGroupDatagrid from 'components/FormComponents/Organization/SubGroups/OrganizationSubGroupDatagrid'

const OrganizationSubgroupMemberships = () => {
  const { id: organizationId } = useParams()
  const translate = useTranslate()
  const { setValue, watch } = useFormContext()

  const { append, remove } = useFieldArray({ name: 'membershipsIds' })
  const membershipsIds = watch('membershipsIds')
  const { data } = useGetMany<OrganizationMembership>(
    'organizationmembership',
    {
      ids: membershipsIds
    }
  )

  const listContext = useList({ data })
  const customer = watch('newMembership')

  const addMember = useCallback(() => {
    if (customer) {
      append(customer)
      setValue('newMembership', undefined)
    }
  }, [customer])

  const removeMembers = useCallback(() => {
    remove(listContext.selectedIds.map((id) => membershipsIds.indexOf(id)))
  }, [listContext, membershipsIds])

  return (
    <div className="w-full">
      <div className="flex w-full items-center justify-between">
        <div className="flex w-fit items-center gap-2">
          <h2>{translate('resources.organization.createSubGroupMembers')}</h2>
          {membershipsIds && (
            <ChipField
              record={{ members: membershipsIds.length }}
              source="members"
            />
          )}
        </div>
        <div className="flex w-fit items-center gap-2">
          <CustomReferenceInput
            entity="organizationmembership"
            queryFilter={{
              [FILTER_SOURCES.organizationId.eq]: organizationId!,
              [FILTER_SOURCES.hasSubGroup.eq]: false
            }}
            filterFns={[(m) => !membershipsIds?.includes(m.id)]}
          >
            <KAutocompleteInput
              source="newMembership"
              label="form.fields.searchUser"
              optionText="userEmail"
              className="w-[250px]"
            />
          </CustomReferenceInput>
          <Button
            label="form.fields.addMember"
            onClick={addMember}
            disabled={!customer}
          />
        </div>
      </div>
      <div className="mt-4">
        {/* This context provider allows us to have a list of memberships */}
        <ListContextProvider
          value={{ ...listContext, resource: 'organizationmembership' }}
        >
          <OrganizationSubGroupDatagrid
            data={data}
            removeMembers={removeMembers}
            bulkActions
          />
        </ListContextProvider>
      </div>
    </div>
  )
}

export default OrganizationSubgroupMemberships
