import React, { CSSProperties, PropsWithChildren } from 'react'
import { RAFormFC } from 'types/common'
import { useForm } from 'hooks/useForm'
import {
  ProductTailoringBase,
  WithIdAndName,
  parseMultilangOrFallbackToAnyAvailable
} from '@kampaay/common'
import LocalizedAutocompleteInput from 'components/Inputs/Localized/LocalizedAutocompleteInput'
import KAccordion from 'components/UI/Accordions/KAccordion/KAccordion'
import { KBooleanInput } from 'components/Inputs/KInputs/KBooleanInput'
import { useTailoringItemViewModel } from 'resources/crm/clients/organization/tailoring/hooks/viewModels/useTailoringItemViewModel'
import { APIEntity } from 'services/api/entities'
import { useKRecordContext } from 'hooks/useKRecordContext'
import { useGetIndex } from 'hooks/useGetIndex'

type TailoringInputBaseProps = PropsWithChildren & {
  relatedTailoredProducts: WithIdAndName[]
  showHideProductToggle?: boolean
  productEntity: APIEntity
}

const visibilityHidden: CSSProperties = {
  visibility: 'hidden',
  height: '0px'
}

const TailoringInputBase: RAFormFC<TailoringInputBaseProps> = ({
  source,
  relatedTailoredProducts = [],
  showHideProductToggle = true,
  children,
  productEntity
}) => {
  const tailoringItemViewModel = useTailoringItemViewModel(
    source,
    productEntity,
    relatedTailoredProducts
  )
  const { getSource } = useForm<ProductTailoringBase>(source)
  const filteredProducts = filterProducts(
    tailoringItemViewModel.data.catalogProducts,
    relatedTailoredProducts
  )

  const currentTailoringIndex = useGetIndex(source)
  const record = useKRecordContext()
  const isAnewElementAdded = (currentIndex: number) =>
    currentIndex === record?.tailorings.length

  //===============================================
  // FUNCTIONS
  //===============================================
  // filter out products that are already in the form
  function filterProducts(
    products: WithIdAndName[],
    currentFormTailorings: WithIdAndName[]
  ) {
    const filtered = products.filter((opt) => {
      const isInForm = currentFormTailorings?.find((formTailoring) => {
        return formTailoring.id === opt.id
      })
      return !isInForm
    })
    return filtered
  }

  // ===================================
  // JSX
  // ===================================
  return (
    <>
      {!tailoringItemViewModel.data.productId ? (
        <LocalizedAutocompleteInput
          choices={filteredProducts}
          sx={{ width: '300px' }}
          label={'form.fields.tailorings'}
          source={getSource('id')}
        />
      ) : (
        <KAccordion
          accordionTitle={parseMultilangOrFallbackToAnyAvailable(
            tailoringItemViewModel.state.productName
          )}
          errorsDependencies={tailoringItemViewModel.data.errorDependencies}
          defaultExpanded={isAnewElementAdded(currentTailoringIndex)}
        >
          {/*============ TOGGLES ============*/}
          <div>
            {showHideProductToggle ? (
              <div
                style={
                  tailoringItemViewModel.data.isOnlyPricingProduct
                    ? visibilityHidden
                    : {}
                }
              >
                <KBooleanInput
                  source={getSource('isHidden')}
                  label={'form.fields.isHidden'}
                  defaultValue={false}
                />
              </div>
            ) : null}

            <div
              style={
                tailoringItemViewModel.data.isOnlyPricingProduct ||
                tailoringItemViewModel.data.isDrink
                  ? visibilityHidden
                  : {}
              }
            >
              <KBooleanInput
                source={getSource('hasCustomPrice')}
                label={'form.fields.addCustomPrice'}
                defaultValue={
                  (tailoringItemViewModel.data.hasCustomPrice ||
                    tailoringItemViewModel.data.isDrink) ??
                  false
                }
              />
            </div>
          </div>

          {/*============ ADDITIONAL CONTENT ============*/}
          {children
            ? React.cloneElement(
                children as React.ReactElement,
                {
                  relatedTailoredProducts,
                  source
                },
                null
              )
            : null}
        </KAccordion>
      )}
    </>
  )
}

export default TailoringInputBase
