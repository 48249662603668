import { useFormContext } from 'react-hook-form'
import { MappedEnrichedEvent } from 'services/api/entities/events/types/internal/event-models'
import {
  MainProductSelection,
  UpsellingSelection
} from 'services/api/entities/events/types/internal/selections'
import {
  EnrichedProductService,
  EnrichedProductServiceBase
} from 'services/api/entities/events/types/internal/services'
import { ServiceCollectionName } from 'types/common'
import {
  EventReadonlyServiceSummary,
  useReadonlyEventService
} from 'hooks/events/useReadonlyEventService'

import { useNotifySupplier } from 'hooks/events/useNotifySupplier'

export type SelectionType = MainProductSelection & {
  upsellingSelection?: UpsellingSelection[]
}

export type NotifySupplierErrors =
  | 'previouslySetToTrue'
  | 'noSupplier'
  | 'missingBrief'

export type EventServiceSummary<
  T extends EnrichedProductServiceBase = EnrichedProductServiceBase
> = EventReadonlyServiceSummary<T> & {
  resetSelection: () => void
  notifyServiceSupplier: () => void
  isNotifySupplierDisabled?: NotifySupplierErrors
}

export const useEventService = <
  T extends Exclude<ServiceCollectionName, 'professionals'>
>(
  selectionSource: T
): EventServiceSummary<EnrichedProductService> => {
  const { getValues, setValue } = useFormContext<MappedEnrichedEvent>()

  const formState = getValues()

  const { serviceSource, ...readonlySummary } = useReadonlyEventService(
    formState,
    selectionSource
  )

  const { isNotifySupplierDisabled, notifyServiceSupplier } =
    useNotifySupplier(serviceSource)

  const resetSelection = () => {
    setValue(selectionSource, [] as any)
  }

  return {
    ...readonlySummary,
    serviceSource,
    resetSelection,
    notifyServiceSupplier,
    isNotifySupplierDisabled
  }
}
