import { buildFilterConfig } from 'services/utils/filters'
import { transactionStatusChoices } from 'services/cfg/form'
import { FILTER_SOURCES } from '@kampaay/common'
import { GEO_AREA_FILTER_CFG } from 'services/filters/entities/geoareaFilter'

export const TRANSACTION_FILTERS = [
  buildFilterConfig('selectArray', {
    source: FILTER_SOURCES.eventStatus.in,
    label: 'form.fields.eventStatus',
    alwaysOn: true,
    choices: transactionStatusChoices
  }),
  buildFilterConfig('text', {
    source: FILTER_SOURCES.customerName.contains,
    label: 'form.fields.customerFullName',
    alwaysOn: true
  }),
  buildFilterConfig('date', {
    source: FILTER_SOURCES.eventDate.gte,
    label: 'form.fields.dateFrom',
    alwaysOn: false
  }),
  buildFilterConfig('date', {
    source: FILTER_SOURCES.eventDate.lt,
    label: 'form.fields.dateTo',
    alwaysOn: false
  }),
  buildFilterConfig('nullableBoolean', {
    source: FILTER_SOURCES.hasConcierge.eq,
    allowFalseValues: false,
    label: 'form.fields.isFromConcierge',
    alwaysOn: true
  }),
  buildFilterConfig('date', {
    source: FILTER_SOURCES.acceptedAt.gte,
    label: 'form.fields.acceptedFrom',
    alwaysOn: true
  }),
  buildFilterConfig('date', {
    source: FILTER_SOURCES.acceptedAt.lt,
    label: 'form.fields.acceptedTo',
    alwaysOn: true
  }),
  GEO_AREA_FILTER_CFG
]
