import React from 'react'
import {
  KBooleanInput,
  KBooleanInputProps
} from 'components/Inputs/KInputs/KBooleanInput'
import { Labeled } from 'react-admin'
import { useWatch } from 'react-hook-form'

type LabeledBooleanFieldProps = Pick<KBooleanInputProps, 'source' | 'label'> & {
  label: string
}

/**
 * A readonly boolean field with a label above it
 * - if true shows yes aside
 * - if false shows no aside
 * @param source
 * @param label
 */
const LabeledBooleanField: React.FC<LabeledBooleanFieldProps> = ({
  source,
  label
}) => {
  const value = useWatch({ name: source })

  return (
    <Labeled label={label} sx={{ marginBottom: '16px' }}>
      <KBooleanInput source={source} label={value ? 'Yes' : 'No'} disabled />
    </Labeled>
  )
}

export default LabeledBooleanField
