import { getDateAsString } from '@kampaay/common'
import {
  parseMultilangJSON,
  parseMultilangToCurrentLang
} from '@kampaay/common'
import { EventItem } from 'services/api/entities/events/types/internal/event-models'
import { headers } from 'services/csv/entities/operations/events/headers'
import {
  formatBaseProduct,
  formatMediumProduct,
  getFormattedCheckoutQuestionResponses,
  getFormattedFoods,
  getFormattedLocation,
  getFormattedStaff
} from 'services/csv/entities/operations/events/utils'
import { EventCustomizedService } from 'services/api/entities/events/types/internal/services'
import { CSVEntityDescriptor } from 'services/csv/types'

export const events: CSVEntityDescriptor<EventItem> = {
  headers: Object.values(headers),
  format: async ({
    id,
    customerInfo: { name, email },
    createdAt,
    startDate,
    fullAddress,
    guestsNumber,
    purchasedServices,
    isFromConcierge,
    paymentMethod,
    checkoutQuestionResponses,
    drinks,
    foods,
    physicals,
    locations,
    virtuals,
    professionalService,
    rents,
    musics: audiovisuals,
    productServices: {
      foodService,
      musicService: audiovisualService,
      rentService,
      drinkService,
      virtualService,
      physicalService,
      locationService
    },
    pricing,
    geoAreaPresetFull,
    confirmedInSf
  }: EventItem) => {
    const parseMultilangCustomization = (
      s?: Pick<EventCustomizedService, 'customizations'>
    ) => {
      return s?.customizations
        ?.map((c) => parseMultilangJSON(c.name)['en-US'])
        .join(', ')
    }

    return {
      [headers.id]: id,
      [headers.customer]: name,
      [headers.email]: email,
      [headers.creationDate]: createdAt,
      [headers.startDate]: getDateAsString(startDate),
      [headers.address]: fullAddress,
      [headers.guests]: guestsNumber,
      [headers.purchasedServices]: purchasedServices,
      [headers.isFromConcierge]: isFromConcierge,
      [headers.paymentMethod]: paymentMethod,
      [headers.discountedPrice]: pricing?.price,
      [headers.discountedPrice]: pricing?.price,

      [headers.geoAreaPresetName]: parseMultilangToCurrentLang(
        geoAreaPresetFull?.name
      ),
      [headers.geoAreaPresetNickname]: parseMultilangToCurrentLang(
        geoAreaPresetFull?.nickname
      ),

      Physicals: physicals.map(formatMediumProduct),
      [headers.physicalsSupplier]: physicalService?.supplierName,

      Locations: locations.map(getFormattedLocation),
      [headers.locationsSupplier]: locationService?.supplierName,

      Foods: getFormattedFoods(foods),
      [headers.foodSupplier]: foodService?.supplierName,

      Drinks: drinks?.map(formatBaseProduct),
      [headers.drinkSupplier]: drinkService?.supplierName,

      Virtual: virtuals?.map(formatMediumProduct),

      Audiovisuals: audiovisuals?.map(formatBaseProduct),
      [headers.audiovisualsSupplier]: audiovisualService?.supplierName,

      Rents: rents?.map(formatBaseProduct),
      [headers.rentsSupplier]: rentService?.supplierName,

      [headers.physicalsCustomization]:
        parseMultilangCustomization(physicalService),
      [headers.locationsCustomization]:
        parseMultilangCustomization(locationService),
      [headers.foodsCustomization]: parseMultilangCustomization(foodService),
      [headers.drinksCustomization]: parseMultilangCustomization(drinkService),
      [headers.virtualsCustomization]:
        parseMultilangCustomization(virtualService),
      [headers.audiovisualsCustomization]:
        parseMultilangCustomization(audiovisualService),
      [headers.rentsCustomization]: parseMultilangCustomization(rentService),
      [headers.staffCustomizations]:
        parseMultilangCustomization(professionalService),

      ...getFormattedStaff(professionalService.services),
      ...getFormattedCheckoutQuestionResponses(checkoutQuestionResponses),

      [headers.confirmedInSF]: confirmedInSf
    }
  },
  // We need to keep the dotted notation in the headers to access
  // the properties of objects inside the giant mapped object that
  // we are returning a couple lines above, but we don't really want
  // the final user to see them the way they are. This function's
  // purpose is to remove the dots from the headers
  headersMapper: (csvHeader) => csvHeader.replace('.', ' ')
}
