import React from 'react'
import KFormTab from 'components/Forms/KForms/KFormTab'
import { RAFormFC } from 'types/common'
import OrganizationCheckoutQuestions from 'components/FormComponents/Organization/OrganizationCheckoutQuestions'

const OrganizationCheckoutTab: RAFormFC = ({ source: _source, ...rest }) => {
  return (
    <KFormTab label="tab.label.checkout" {...rest}>
      <OrganizationCheckoutQuestions />
    </KFormTab>
  )
}

export default OrganizationCheckoutTab
