import { buildFilterConfig } from 'services/utils/filters'
import { FILTER_SOURCES } from '@kampaay/common'

export const ORG_MEMBERSHIP_FILTERS = [
  buildFilterConfig('text', {
    source: FILTER_SOURCES.userEmail.contains,
    alwaysOn: true,
    label: 'form.fields.email'
  }),
  buildFilterConfig('nullableBoolean', {
    source: FILTER_SOURCES.hasApprovalRequired.eq,
    alwaysOn: true,
    label: 'form.fields.approvalRequired',
    emptyText: 'form.fields.all'
  })
]
