import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button
} from '@mui/material'
import React, { useState } from 'react'
import { ArrayField, SingleFieldList } from 'react-admin'
import { translate } from 'services/i18n'
import ApprovalStepField from 'components/FormComponents/Organization/ApprovalFlows/ApprovalStepField'
import { APIOrganization } from 'services/api/entities/crm/organizations/types/api/response-models'
import { useFormContext } from 'react-hook-form'
import { ExpandMore } from '@mui/icons-material'

type Props = {
  organization?: APIOrganization
}

const OrgApprovalFlowsReadonlyAccordion: React.FC<Props> = ({
  organization
}) => {
  const { setValue } = useFormContext()
  const [isAccordionOpen, setIsAccordionOpen] = useState(false)

  const onAccordionChange = (_: any, isExpanded: boolean) => {
    setIsAccordionOpen(isExpanded)
  }

  const onCopyFromOrgToMember = () => {
    setValue('approvalSteps', organization?.approvalSteps ?? [])
  }

  const isDisabledByGroupAdmin = organization?.approvalSteps?.some(
    (step) => step.approvalType === 'groupAdmin'
  )

  return (
    <Accordion
      className="my-4"
      expanded={isAccordionOpen}
      onChange={onAccordionChange}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <div className="flex w-full items-center justify-between">
          {translate(
            'form.membership.organizationApprovalStepsAccordionHeader'
          )}

          {isAccordionOpen && (
            <Button
              variant="outlined"
              size="small"
              className="mr-4 capitalize"
              onClick={onCopyFromOrgToMember}
              disabled={isDisabledByGroupAdmin}
            >
              {translate(
                'form.membership.copyOrganizationApprovalConfiguration'
              )}
            </Button>
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <ArrayField record={organization} source="approvalSteps">
          <SingleFieldList linkType={false} className="block">
            <ApprovalStepField />
          </SingleFieldList>
        </ArrayField>
      </AccordionDetails>
    </Accordion>
  )
}

export default OrgApprovalFlowsReadonlyAccordion
