import { useKRecordContext } from 'hooks/useKRecordContext'
import { MappedEnrichedEvent } from 'services/api/entities/events/types/internal/event-models'
import { objectIsEmpty } from 'services/utils'
import { useReadonlyEventProfessionalServiceGroup } from 'hooks/events/useReadonlyEventProfessionalServiceGroup'
import { useReadonlyEventService } from 'hooks/events/useReadonlyEventService'

export const useEventRecord = () => {
  const record = useKRecordContext<MappedEnrichedEvent>()

  if (!record) {
    return {
      summaries: [],
      hasIntolerances: false,
      logistics: []
    }
  }

  const [professionalGroup, ...rest] = [
    useReadonlyEventProfessionalServiceGroup(record),
    useReadonlyEventService(record, 'physicals'),
    useReadonlyEventService(record, 'locations'),
    useReadonlyEventService(record, 'foods'),
    useReadonlyEventService(record, 'drinks'),
    useReadonlyEventService(record, 'musics'),
    useReadonlyEventService(record, 'rents'),
    useReadonlyEventService(record, 'virtuals')
  ]

  const hasIntolerances = record.foods.some(
    (f) => !objectIsEmpty(f.intolerances)
  )

  return {
    summaries: [
      ...rest,
      ...professionalGroup.professionalServiceSummaries
    ].filter((s) => s.hasSelections || s.hasCustomizations),
    hasIntolerances,
    logistics: record.logistics
  }
}
