import { parseCsvFields, WithIdAndName } from '@kampaay/common'

export type WithIdAndNameCSV = {
  Name: string
  Id: number
}

export const formatWithIdAndName = ({
  name,
  id
}: WithIdAndName): WithIdAndNameCSV => ({
  ...parseCsvFields({ Name: name }),
  Id: id
})
