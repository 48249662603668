import { APICfg } from 'services/api/api'
import { parseOptionedTailorings } from 'services/api/entities/crm/organizations/emsTailoring/common/parsers'
import { writeOptionedTailorings } from 'services/api/entities/crm/organizations/emsTailoring/common/writers'

const apiCfg: APICfg = {
  endpoint: '',
  getOneEndpoint: 'organization/:id/tailoring/foods',
  parse: parseOptionedTailorings,
  write: writeOptionedTailorings
}

export default apiCfg
