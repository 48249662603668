import { GroupWork } from '@mui/icons-material'
import PhysicalExperienceEdit from 'resources/catalog/physicalexperiences/product/PhysicalExperienceEdit'
import PhysicalExperienceCreate from 'resources/catalog/physicalexperiences/product/PhysicalExperienceCreate'
import PhysicalExperienceList from 'resources/catalog/physicalexperiences/product/PhysicalExperienceList'

const physicalExperienceIndex = {
  icon: GroupWork,
  list: PhysicalExperienceList,
  edit: PhysicalExperienceEdit,
  create: PhysicalExperienceCreate
}

export default physicalExperienceIndex
