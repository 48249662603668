import { useState } from 'react'
import { getById, ProductTailoringBase, WithIdAndName } from '@kampaay/common'
import { useTailoringsRepository } from 'resources/crm/clients/organization/tailoring/hooks/repositories/useTailoringsRepository'
import { useCatalogRepository } from 'resources/crm/clients/organization/tailoring/hooks/repositories/useCatalogRepository'
import { APIEntity } from 'services/api/entities'

export const useTailoringsViewModel = (
  catalogProductEntity: APIEntity,
  tailoringsSource: string | undefined
) => {
  // ===================================
  // REPOSITORY
  // ===================================
  const { getRelatedCatalogProducts, currentFormTailorings } =
    useTailoringsRepository(catalogProductEntity, tailoringsSource)

  const { products: catalogProducts } =
    useCatalogRepository(catalogProductEntity)

  // ===================================
  // STATE & DATA
  // ===================================
  const [selectedSearchItem, setSelectedSearchItem] = useState<
    number | undefined
  >(undefined)

  const [shouldShowHiddenTailorings, setShouldShowHiddenTailorings] =
    useState(false)

  const [
    shouldShowTailoringsWithCustomPrices,
    setShouldShowTailoringsWithCustomPrices
  ] = useState(false)

  // ===================================
  // EVENT HANDLERS
  // ===================================
  const onHiddenProductsToggleChange = (value: boolean) => {
    setShouldShowHiddenTailorings(value)

    if (shouldShowTailoringsWithCustomPrices)
      setShouldShowTailoringsWithCustomPrices(false)
  }

  const onCustomPricesToggleChange = (value: boolean) => {
    setShouldShowTailoringsWithCustomPrices(value)

    if (setShouldShowHiddenTailorings) setShouldShowHiddenTailorings(false)
  }

  const onSearchBoxChange = (_event: any, newValue: WithIdAndName | null) => {
    if (newValue) {
      setSelectedSearchItem(newValue.id)
    } else setSelectedSearchItem(undefined)
  }

  // ===================================
  // FUNCTIONS
  // ===================================
  const isProductSelectedInSearchBox = (
    productTailoring: ProductTailoringBase
  ) => productTailoring.id === selectedSearchItem

  const shouldBeVisible = (productTailoring: ProductTailoringBase): boolean => {
    // if all filters are off, then show item
    if (
      !selectedSearchItem &&
      !shouldShowHiddenTailorings &&
      !shouldShowTailoringsWithCustomPrices
    )
      return true

    const isSelectedinSearchBox =
      !!selectedSearchItem && isProductSelectedInSearchBox(productTailoring)

    const isFilteredByHiddenStatus =
      shouldShowHiddenTailorings && productTailoring.isHidden

    const isFilteredByHavingCustomPrice =
      shouldShowTailoringsWithCustomPrices && productTailoring.hasCustomPrice

    //
    return (
      isSelectedinSearchBox ||
      isFilteredByHiddenStatus ||
      isFilteredByHavingCustomPrice
    )
  }

  /**
   * Filters search options by toggles
   *
   * @param choices
   * @param fullTailorings
   */
  const isAllowedByOtherFilters = (
    choices: WithIdAndName[],
    fullTailorings: ProductTailoringBase[]
  ) => {
    return choices.filter((opt) => {
      const tailoringItem = getById(fullTailorings, opt.id)

      if (shouldShowHiddenTailorings) return tailoringItem?.isHidden

      if (shouldShowTailoringsWithCustomPrices)
        return tailoringItem?.hasCustomPrice

      // if all filters are off, then show item
      return (
        !shouldShowHiddenTailorings && !shouldShowTailoringsWithCustomPrices
      )
    })
  }

  // ===================================
  // ===================================
  return {
    eventHandlers: {
      onHiddenProductsToggleChange,
      onCustomPricesToggleChange,
      onSearchBoxChange
    },
    state: {
      shouldShowHiddenTailorings,
      shouldShowTailoringsWithCustomPrices,
      relatedTailoredProducts: getRelatedCatalogProducts(catalogProducts)
    },
    functions: {
      shouldBeVisible,
      isAllowedByOtherFilters
    },
    data: {
      catalogProducts: catalogProducts,
      currentFormTailorings
    }
  }
}
