import { CustomerFormITAExt } from 'components/FormComponents/customerAndSupplierForms/CustomerFormITAExt'
import KAutocompleteInput from 'components/Inputs/KInputs/KAutocompleteInput'
import KTextInput from 'components/Inputs/KInputs/KTextInput/KTextInput'
import React, { PropsWithChildren } from 'react'
import { COUNTRIES } from '@kampaay/common'
import { RAFormFC } from 'types/common'
import SalesOwnerAutocompleteInput from 'components/FormComponents/Organization/SalesOwnerAutocompleteInput'

const CustomerFormContentCommon: RAFormFC<
  PropsWithChildren & {
    disableAllInputs?: boolean
    selectInputStatus?: React.ReactElement
  }
> = ({ children, disableAllInputs, selectInputStatus }) => {
  return (
    <>
      <KTextInput
        source="name"
        label="form.fields.name"
        disabled={disableAllInputs}
      />
      <KTextInput
        source="responsibleFullName"
        label="form.fields.responsibleFullName"
        disabled={disableAllInputs}
      />
      <KAutocompleteInput
        source="country"
        choices={[...COUNTRIES]}
        optionText={'countryName'}
        optionValue={'code'}
        disabled={disableAllInputs}
      />
      <KTextInput
        source="responsibleEmail"
        label="form.fields.responsibleEmail"
        disabled={disableAllInputs}
      />
      {selectInputStatus}
      <KTextInput
        source="headQuarters"
        label="form.fields.businessHeadQuarter"
        disabled={disableAllInputs}
      />
      <KTextInput
        source="organizationNumber"
        label="form.fields.organizationNumber"
        disabled={disableAllInputs}
      />
      <CustomerFormITAExt disabled={disableAllInputs} />
      <SalesOwnerAutocompleteInput />
      {children}
    </>
  )
}

export default CustomerFormContentCommon
