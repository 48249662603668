import React from 'react'
import CustomReferenceInput from 'components/Inputs/CustomReferenceInput'
import KAutocompleteInput, {
  KAutocompleteInputProps
} from 'components/Inputs/KInputs/KAutocompleteInput'
import { APIEntity } from 'services/api/entities'

export type KAutocompleteReferenceInputProps = KAutocompleteInputProps & {
  relatedEntity: APIEntity
}

const KAutocompleteReferenceInput: React.FC<
  KAutocompleteReferenceInputProps
> = ({ relatedEntity, ...props }) => {
  //=========================
  // JSX
  //=========================
  return (
    <CustomReferenceInput entity={relatedEntity}>
      <KAutocompleteInput {...props} />
    </CustomReferenceInput>
  )
}

export default KAutocompleteReferenceInput
