import React from 'react'
import {
  DeleteWithConfirmButton,
  RedirectionSideEffect,
  ToolbarProps
} from 'react-admin'
import { Toolbar } from 'ra-ui-materialui'
import KSaveButton, {
  KSaveButtonExternalProps
} from 'components/Buttons/KSaveButton'
import { useKRecordContext } from 'hooks/useKRecordContext'

export type KToolbarProps = ToolbarProps &
  KSaveButtonExternalProps & {
    redirectAfterDelete?: RedirectionSideEffect
  }

const KDefaultFormToolbar: React.FC<KToolbarProps> = ({
  children,
  redirectAfterCreate,
  mutationOptions,
  redirectAfterDelete = 'list',
  ...props
}) => {
  const record = useKRecordContext()

  return (
    <Toolbar className="flex justify-between" {...props}>
      <div>
        <KSaveButton
          redirectAfterCreate={redirectAfterCreate}
          mutationOptions={mutationOptions}
        />
        {children}
      </div>

      {/* Not rendering the delete button in create forms */}
      {!!record?.id && (
        <DeleteWithConfirmButton redirect={redirectAfterDelete} />
      )}
    </Toolbar>
  )
}

export default KDefaultFormToolbar
