import React from 'react'
import { RAFormFC } from 'types/common'
import { useForm } from 'hooks/useForm'
import { MailingList } from 'services/api/entities/mailing-list/types'
import KTextInput from 'components/Inputs/KInputs/KTextInput/KTextInput'
import KArrayInput from 'components/Inputs/KInputs/KArrayInput'
import { SimpleFormIterator } from 'react-admin'

const MailingListFormContent: RAFormFC = ({ source }) => {
  const { getSource } = useForm<MailingList>(source)

  return (
    <React.Fragment>
      <KTextInput
        source={getSource('description')}
        label="form.fields.description"
      />
      <KArrayInput source={getSource('addresses')}>
        <SimpleFormIterator>
          <KTextInput
            source=""
            label="form.fields.address"
            className="w-[500px]"
          />
        </SimpleFormIterator>
      </KArrayInput>
    </React.Fragment>
  )
}

export default MailingListFormContent
