import { FILTER_SOURCES } from '@kampaay/common'
import { buildFilterConfig } from 'services/utils/filters'

export const LOCATION_FEATURES_FILTERS = [
  buildFilterConfig('text', {
    source: FILTER_SOURCES.name.contains,
    label: 'form.fields.name',
    alwaysOn: true
  })
]
