import { APICfg } from 'services/api/api'
import { APIDrink } from 'services/api/entities/catalog/drink/types/api/response-models'
import {
  ProductSection,
  ProductSectionToAPI
} from 'services/api/entities/catalog/types'
import { productSectionParse } from 'services/api/entities/catalog/utils'

export type DrinkSpecialType =
  | 'alcoholicCocktail'
  | 'analcoholicCocktail'
  | 'beer'
  | 'wine'
  | 'softDrink'

export type DrinkSection = ProductSection<APIDrink> & {
  specialType: DrinkSpecialType
}

type DrinkSectionToAPI = ProductSectionToAPI

const apiCfg: APICfg = {
  endpoint: 'drinksections',
  parse: productSectionParse,
  write: async (req: DrinkSection): Promise<DrinkSectionToAPI> => req
}

export default apiCfg
