import { useKGetList } from 'hooks/useKGetList'
import { WithIdAndName } from '@kampaay/common'
import { APIEntity } from 'services/api/entities'

/**
 * NOTE:
 * the returned type is WithIdAndName because some products like
 * logistics and upsellings are not "MainProduct"
 *
 * @param catalogProductEntity
 */
export const useCatalogRepository = <
  TProduct extends WithIdAndName = WithIdAndName
>(
  catalogProductEntity: APIEntity
) => {
  const products: TProduct[] = useKGetList<TProduct>(catalogProductEntity).list

  return {
    products
  }
}
