import React from 'react'
import ProductSectionFormContent from 'components/Forms/ProductSectionFormContent'
import SectionWrapper from 'components/UI/SectionWrapper'
import { KSimpleForm } from 'components/Forms/KForms/KSimpleForm'
import KEdit from 'components/Forms/KEdit/KEdit'

const RentSectionEdit: React.FC = () => {
  return (
    <SectionWrapper title="resources.rentsections.editSection">
      <KEdit>
        <KSimpleForm>
          <ProductSectionFormContent />
        </KSimpleForm>
      </KEdit>
    </SectionWrapper>
  )
}

export default RentSectionEdit
