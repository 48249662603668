import { ListAlt } from '@mui/icons-material'
import VirtualSectionCreate from 'resources/catalog/virtualexperiences/sections/VirtualSectionCreate'
import VirtualSectionEdit from 'resources/catalog/virtualexperiences/sections/VirtualSectionEdit'
import VirtualSectionList from 'resources/catalog/virtualexperiences/sections/VirtualSectionList'

const virtualSectionIndex = {
  icon: ListAlt,
  list: VirtualSectionList,
  edit: VirtualSectionEdit,
  create: VirtualSectionCreate
}

export default virtualSectionIndex
