import { styled } from '@mui/material/styles'
import React, { useEffect } from 'react'
import { SimpleFormIterator } from 'react-admin'
import {
  activityStatusChoices,
  ruleOperatorChoices,
  serviceDiscriminatorChoices
} from 'services/cfg/form'
import { SERVICE_WHERE_AVAILABLE } from 'services/cfg/rules'
import HowItWorksAccordion from 'components/UI/Accordions/HowItWorksAccordion'
import LastEditDetailsField from 'components/FormComponents/LastEditDetailsFields'
import KTooltip from 'components/UI/KTooltip'
import DescriptionInput from 'components/Inputs/DescriptionInput'
import KSelectInput from 'components/Inputs/KInputs/KSelectInput'
import KSelectArrayInput from 'components/Inputs/KInputs/KSelectArrayInput'
import IfClauseInput from 'components/FormComponents/Rules/IfClauseInput'
import ThenClauseInput from 'components/FormComponents/Rules/ThenClauseInput'
import KTextInput from 'components/Inputs/KInputs/KTextInput/KTextInput'
import KDivider from 'components/UI/KDivider'
import { useForm } from 'hooks/useForm'
import { RAFormFC } from 'types/common'
import { KBooleanInput } from 'components/Inputs/KInputs/KBooleanInput'
import KArrayInput from 'components/Inputs/KInputs/KArrayInput'
import { Rule } from '@kampaay/common'

const PREFIX = 'RuleForm'

const classes = {
  dFlexRow: `${PREFIX}-dFlexRow`,
  inputW250px: `${PREFIX}-inputW250px`,
  inputW800px: `${PREFIX}-inputW800px`,
  w100: `${PREFIX}-w100`
}

const StyledDiv = styled('div')({
  [`& .${classes.dFlexRow}`]: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    width: '100%'
  },
  [`& .${classes.inputW250px}`]: {
    width: '250px'
  },
  [`& .${classes.inputW800px}`]: {
    width: '800px'
  },
  [`& .${classes.w100}`]: {
    width: '100%'
  }
})

const RuleFormContent: RAFormFC = ({ source }) => {
  const { getSource, useGetValue, useSetValue } = useForm<Rule>(source)
  const setServiceWhere = useSetValue('servicesWhere')
  const setApplyInverseLogic = useSetValue('applyInverseLogic')
  const hasIncompatibilityRule = useGetValue('then', { exact: false })?.some(
    (thenClause) => thenClause.action === 'incompatibility'
  )

  useEffect(() => {
    if (hasIncompatibilityRule) {
      setServiceWhere(undefined)
      setApplyInverseLogic(true)
    } else {
      setApplyInverseLogic(undefined)
    }
  }, [hasIncompatibilityRule])

  const serviceWhereChoices = serviceDiscriminatorChoices.filter((e) =>
    SERVICE_WHERE_AVAILABLE.includes(e.id)
  )
  return (
    <StyledDiv>
      <LastEditDetailsField />
      <KTextInput
        source={getSource('name')}
        label="form.fields.name"
        required
        fullWidth
      />
      <DescriptionInput />

      <KSelectInput
        source={getSource('status')}
        choices={activityStatusChoices}
        className={classes.inputW250px}
      />

      <br />

      <KSelectInput
        label="form.fields.rulesOperator"
        source={getSource('operator')}
        choices={ruleOperatorChoices}
        className={classes.inputW250px}
      />

      {/* IF CLAUSE */}
      <KDivider title="IF" />
      <KArrayInput
        source={getSource('if')}
        label=""
        fullWidth
        className={classes.w100}
      >
        <SimpleFormIterator>
          <IfClauseInput />
        </SimpleFormIterator>
      </KArrayInput>

      {/* THEN CLAUSE */}
      <KDivider title="THEN" />
      <KArrayInput
        source={getSource('then')}
        label=""
        fullWidth
        className={classes.w100}
      >
        <SimpleFormIterator>
          <ThenClauseInput />
        </SimpleFormIterator>
      </KArrayInput>

      {/* SERVICE WHERE */}
      {!hasIncompatibilityRule && (
        <KTooltip title="form.fields.servicesWhereTooltip" placement="top">
          <KSelectArrayInput
            source={getSource('servicesWhere')}
            className={classes.inputW250px}
            choices={serviceWhereChoices}
          />
        </KTooltip>
      )}

      {/* APPLY INVERSE LOGIC */}
      {hasIncompatibilityRule && (
        <KTooltip title="tooltip.applyInverseLogic" placement="top-start">
          <KBooleanInput
            source={getSource('applyInverseLogic')}
            label="form.fields.applyInverseLogic"
            disabled={!hasIncompatibilityRule}
          />
        </KTooltip>
      )}

      <HowItWorksAccordion
        accordionText={['ruleHowItWorks1', 'ruleHowItWorks2']}
      />
    </StyledDiv>
  )
}

export default RuleFormContent
