import { Customer } from '@kampaay/common'
import { Supplier } from '@kampaay/common'

export const formatBusinessUser = (record: Customer | Supplier) => {
  const { name, id, ...rest } = record
  return {
    id,
    name,
    ...rest
  }
}
