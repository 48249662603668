import type { OrganizationMembership } from './types'
import type { APIOrganizationMembership } from './types/response-models'

export const organizationMembershipParse = ({
  approvalSteps,
  ...rest
}: APIOrganizationMembership): OrganizationMembership => ({
  ...rest,
  approvalSteps: approvalSteps ?? []
})
