import React from 'react'
import { BulkDeleteWithConfirmButton, Datagrid, ListProps } from 'react-admin'
import { useKResourceContext } from 'hooks/useKResourceContext'
import ReorderableList from 'components/Lists/DraggableList/ReorderableList'
import KFilters from 'services/filters/KFilters'
import SectionWrapper from 'components/UI/SectionWrapper'
import LocalizedTextField from 'components/Fields/Localized/LocalizedTextField'
import { KExporter } from 'services/csv/types'

export type BaseListProps = Omit<
  ListProps,
  'title' | 'exporter' | 'resource' | 'children'
> & {
  exporter?: KExporter | false
  children?: React.ReactNode
}

export type CatalogItemBaseListProps = BaseListProps

const CatalogItemBaseList: React.FC<CatalogItemBaseListProps> = ({
  children,
  exporter = false,
  pagination = false as false,
  filters,
  ...rest
}) => {
  const resource = useKResourceContext()

  return (
    <SectionWrapper title={`resources.${resource}.name`}>
      <ReorderableList
        {...rest}
        exporter={exporter}
        pagination={pagination}
        filters={filters ?? <KFilters />}
      >
        <Datagrid
          rowClick="edit"
          bulkActionButtons={
            <BulkDeleteWithConfirmButton mutationMode="optimistic" />
          }
        >
          <LocalizedTextField source="name" sortable={false} />
          {children}
        </Datagrid>
      </ReorderableList>
    </SectionWrapper>
  )
}

export default CatalogItemBaseList
