import React from 'react'
import LocalizedAutoCompleteArrayInput from 'components/Inputs/Localized/LocalizedAutoCompleteArrayInput'
import { RAFormFC } from 'types/common'
import CustomReferenceInput from 'components/Inputs/CustomReferenceInput'

const SuppliersAutocompleteArrayFilterInput: RAFormFC = ({ source }) => {
  return (
    <CustomReferenceInput entity="suppliers">
      <LocalizedAutoCompleteArrayInput
        label="resources.suppliers.name"
        optionText="name"
        source={source}
      />
    </CustomReferenceInput>
  )
}

export default SuppliersAutocompleteArrayFilterInput
