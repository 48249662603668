import * as yup from 'yup'
import { AnyObject } from 'yup/lib/types'

/**
 * Enhances yup.date() with the possibility to pass the context as a generic parameter:
 *
 * @returns a yup.date constructor
 */
export const yupDate = <TContext extends AnyObject = AnyObject>() =>
  new yup.DateSchema<Date | undefined, TContext>()
