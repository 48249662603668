import React from 'react'
import SectionWrapper from 'components/UI/SectionWrapper'
import KEdit from 'components/Forms/KEdit/KEdit'
import { KSimpleForm } from 'components/Forms/KForms/KSimpleForm'
import { useParams } from 'react-router-dom'
import OrganizationMembershipFormContent from 'components/Forms/OrganizationMembershipFormContent'

const OrganizationMembershipEdit: React.FC = () => {
  const { membershipId, id: organizationId } = useParams()

  return (
    <SectionWrapper title="resources.organization.editMember">
      <KEdit resource="organizationmembership" id={membershipId}>
        <KSimpleForm
          redirectAfterDelete={() => `organization/${organizationId}/members`}
        >
          <OrganizationMembershipFormContent />
        </KSimpleForm>
      </KEdit>
    </SectionWrapper>
  )
}

export default OrganizationMembershipEdit
