import React, { useState, useEffect } from 'react'
import { Button } from 'react-admin'
import { entries, filterObject, keys, mapValues } from 'services/utils'
import {
  PlatformCode,
  getI18nBundleOperations,
  type I18nMap,
  type UpdateFileFunction
} from 'i18n/components/I18nFileProvider'
import I18nLabelsForm from 'i18n/components/I18nLabelsForm'
import type { I18nLocale } from '@kampaay/common'
import { ResettableFilterInput } from 'components/Inputs/ResettableFilterInput'
import {
  Table,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@mui/material'
import pick from 'lodash/pick'
import isEmpty from 'lodash/isEmpty'

const ROWS_PER_PAGE = 20
const PLACEHOLDER_STRING = 'TBD'
const MAIN_LANG_FILECODE: I18nLocale = 'en-IT' // should be supported by all domains (be, sf, bo)

export type UpdateTranslationFn = (
  key: string,
  code: string
) => ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => void

export type DeleteLabelFn = (key: string) => void
export type DuplicateLabelFn = (key: string) => void

type Props = {
  apiI18nBundle: I18nMap
  saveFunction: UpdateFileFunction
  isSaving: boolean
  requiredFiles: I18nLocale[]
  setDuplicatedMap: (map: I18nMap) => void
  duplicatedLabels: string[]
  setDuplicatedLabels: (labels: string[]) => void
  platformCode: PlatformCode
}

export type TranslationFilter = {
  lang: I18nLocale
  value: string
}

const I18nFormHandler: React.FC<Props> = ({
  apiI18nBundle,
  saveFunction,
  isSaving,
  requiredFiles,
  setDuplicatedMap,
  duplicatedLabels,
  setDuplicatedLabels,
  platformCode
}) => {
  const [i18nMap, setI18nMap] = useState<I18nMap>(apiI18nBundle)
  const [newLabel, setNewLabel] = useState<string>('')
  const [keyFilterText, setKeyFilterText] = useState<string>('')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE)
  const [isDeleteEnabled, setIsDeleteEnabled] = useState<boolean>(false)
  const [isDuplicateEnabled, setIsDuplicateEnabled] = useState<boolean>(false)
  const [translationFilter, setTranslationFilter] = useState<TranslationFilter>(
    { lang: MAIN_LANG_FILECODE, value: '' }
  )

  useEffect(() => {
    setI18nMap(apiI18nBundle)
  }, [apiI18nBundle])

  const jsonWidthDiffs = entries(i18nMap).map(
    ([code, json]) => !!getI18nBundleOperations(json, apiI18nBundle[code])
  )

  const disableSaveButton =
    (isSaving || jsonWidthDiffs.every((langsModifieds) => !langsModifieds)) &&
    !duplicatedLabels.length

  const addLabel = (key: string) => {
    setI18nMap({
      ...mapValues((v) => ({ [key.trim()]: PLACEHOLDER_STRING, ...v }), i18nMap)
    })
    setNewLabel('')
  }

  const updateTranslation: UpdateTranslationFn =
    (key, code) =>
    ({ target: { value } }) => {
      if (!!translationFilter.value) {
        setKeyFilterText(key)
      }

      setTranslationFilter({ lang: code as I18nLocale, value: '' })
      setI18nMap({ ...i18nMap, [code]: { ...i18nMap[code], [key]: value } })
    }

  const deleteLabel: DeleteLabelFn = (key) => {
    setI18nMap({
      ...mapValues(
        (v) => ({ ...filterObject((_v, name) => name !== key, v) }),
        i18nMap
      )
    } as I18nMap)
    setIsDeleteEnabled(false)
  }

  const duplicateLabel: DuplicateLabelFn = (key) => {
    let newLabels: string[] = []

    if (!duplicatedLabels.includes(key)) {
      newLabels = [...duplicatedLabels, key]
    } else {
      newLabels = duplicatedLabels.filter((label) => label !== key)
    }

    setDuplicatedLabels(newLabels)
    setDuplicatedMap(
      Object.fromEntries(
        Object.entries(i18nMap).flatMap(([code, labels]) => {
          const result = pick(labels, newLabels)

          if (isEmpty(result)) {
            return []
          }

          return [[code, result]]
        })
      )
    )
  }

  const handlePageChange = (
    _e: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(Number(event.target.value))
    setPage(0)
  }

  const toggleEnableDuplicate = () => {
    if (isDuplicateEnabled) {
      setDuplicatedLabels([])
      setDuplicatedMap({})
      setIsDuplicateEnabled(false)
    } else {
      setIsDuplicateEnabled(true)
    }
  }

  const filteredRows = keys(i18nMap[MAIN_LANG_FILECODE]).filterEvery([
    (k) => k.toLowerCase().includes(keyFilterText.toLowerCase()),
    (k) =>
      !!i18nMap[translationFilter.lang][k]
        ?.toLowerCase()
        .includes(translationFilter.value.toLowerCase())
  ])

  const labelsToRender = filteredRows.slice(
    rowsPerPage * page,
    rowsPerPage * page + rowsPerPage
  )

  // Used to set page 0 only when rowsPerPage or LabelsTorender.length change.
  // IF not do this, the page will be set to 0 in other cases.
  useEffect(() => {
    if (labelsToRender.length < rowsPerPage) {
      setPage(0)
    }
  }, [rowsPerPage, labelsToRender])

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ResettableFilterInput
            value={newLabel}
            handleChange={(e) => setNewLabel(e.target.value)}
            label="New Label"
            onKeyDown={(e: React.KeyboardEvent) => {
              if (e.key === 'Enter' && !!newLabel) {
                addLabel(newLabel)
              }
            }}
            handleClear={() => setNewLabel('')}
          />
          <Button
            label="Add label"
            variant="contained"
            disabled={!newLabel}
            onClick={() => addLabel(newLabel!)}
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
          <Button
            onClick={saveFunction(i18nMap)}
            label="Save"
            variant="contained"
            disabled={disableSaveButton}
          />
          <Button
            onClick={() => setIsDeleteEnabled(!isDeleteEnabled)}
            label={isDeleteEnabled ? 'Disable delete' : 'Enable delete'}
            variant="contained"
          />
          {platformCode !== 'be' && (
            <Button
              onClick={toggleEnableDuplicate}
              label={
                isDuplicateEnabled ? 'Disable duplicate' : 'Enable duplicate'
              }
              variant="contained"
            />
          )}
        </div>
      </div>
      <TableContainer style={{ height: '78vh' }}>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <ResettableFilterInput
                value={keyFilterText}
                handleChange={(e) => setKeyFilterText(e.target.value)}
                label="Filter by key"
                handleClear={() => setKeyFilterText('')}
              />
              {requiredFiles.map((i18nFileCode, i) => (
                <ResettableFilterInput
                  key={i}
                  value={
                    translationFilter.lang === i18nFileCode
                      ? translationFilter.value
                      : ''
                  }
                  handleChange={(e) =>
                    setTranslationFilter({
                      value: e.target.value,
                      lang: i18nFileCode
                    })
                  }
                  label={`Filter by ${i18nFileCode}`}
                  handleClear={() =>
                    setTranslationFilter({ ...translationFilter, value: '' })
                  }
                />
              ))}
            </TableRow>
          </TableHead>

          <I18nLabelsForm
            labelsToRender={labelsToRender}
            i18nMap={i18nMap}
            updateTranslation={updateTranslation}
            deleteLabel={deleteLabel}
            duplicateLabel={duplicateLabel}
            isDeleteEnabled={isDeleteEnabled}
            isDuplicateEnabled={isDuplicateEnabled}
            duplicatedLabels={duplicatedLabels}
            platformCode={platformCode}
          />
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        rowsPerPageOptions={[
          ROWS_PER_PAGE,
          ROWS_PER_PAGE * 2,
          ROWS_PER_PAGE * 3
        ]}
        count={filteredRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: {
            'aria-label': 'rows per page'
          },
          native: true
        }}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  )
}

export default I18nFormHandler
