import { APICfg } from 'services/api/api'
import { parseProfessionalTailorings } from 'services/api/entities/crm/organizations/emsTailoring/common/parsers'
import { writeProfessionalTailorings } from 'services/api/entities/crm/organizations/emsTailoring/common/writers'

const apiCfg: APICfg = {
  endpoint: '',
  getOneEndpoint: 'organization/:id/tailoring/professionals',
  parse: parseProfessionalTailorings,
  write: writeProfessionalTailorings
}

export default apiCfg
