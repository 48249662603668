import React from 'react'
import { KBooleanInput } from 'components/Inputs/KInputs/KBooleanInput'

import { RAFormFC } from 'types/common'
import { NotifySupplierErrors } from 'hooks/events/useEventService'
import { useTranslate } from 'react-admin'

type Props = {
  isNotifySupplierDisabled?: NotifySupplierErrors
  notifyServiceSupplier?: () => void
  serviceSource: string
}

const NotifySupplierSwitch: RAFormFC<Props> = ({
  isNotifySupplierDisabled,
  serviceSource
}) => {
  const translate = useTranslate()

  return (
    <>
      <KBooleanInput
        source={`${serviceSource}.notifySupplier`}
        defaultValue={false}
        label="form.fields.notifySupplier"
        disabled={!!isNotifySupplierDisabled}
        data-testid="notify-supplier-switch--toggler"
      />
      {isNotifySupplierDisabled &&
        isNotifySupplierDisabled !== 'previouslySetToTrue' && (
          <div style={{ color: '#da2200' }}>
            <span>
              {translate(
                isNotifySupplierDisabled === 'missingBrief'
                  ? 'form.fields.briefIsNotFilledMessage'
                  : 'form.fields.briefSupplierMissingMessage'
              )}
            </span>
          </div>
        )}
    </>
  )
}

export default NotifySupplierSwitch
