import React from 'react'
import KNumberInput from 'components/Inputs/KInputs/KNumberInput/KNumberInput'
import { RAFormFC } from 'types/common'
import { useForm } from 'hooks/useForm'
import { ProductSelectionBase } from 'services/api/entities/events/types/internal/selections'

type ProductQuantityInputProps = {
  disabled?: boolean
}

const EventProductQuantityInput: RAFormFC<ProductQuantityInputProps> = ({
  disabled,
  source
}) => {
  const { getSource } = useForm<ProductSelectionBase>(source)

  return (
    <KNumberInput
      source={getSource('quantity')}
      label="form.fields.quantity"
      disabled={disabled}
      style={{ marginRight: '16px' }}
    />
  )
}

export default EventProductQuantityInput
