import React from 'react'
import { styled } from '@mui/material/styles'
import { serviceDiscriminatorChoices } from 'services/cfg/form'
import { DISCRIMINATOR_COLLECTION_NAMES_MAP } from 'services/cfg/name-maps'
import { RAFormFC } from 'types/common'
import CustomReferenceInput, {
  isProductVisibleInStore
} from 'components/Inputs/CustomReferenceInput'
import LocalizedAutoCompleteArrayInput from 'components/Inputs/Localized/LocalizedAutoCompleteArrayInput'
import KToolTip from 'components/UI/KTooltip'
import KSelectInput from 'components/Inputs/KInputs/KSelectInput'
import { useForm } from 'hooks/useForm'
import {
  IfClause,
  ProductServiceDiscriminator,
  RuleAction,
  RuleTargetType,
  ThenClause,
  TypedChoices
} from '@kampaay/common'

const PREFIX = 'ProductsByServiceSelector'

const classes = {
  dFlexRow: `${PREFIX}-dFlexRow`,
  inputW250px: `${PREFIX}-inputW250px`,
  inputW800px: `${PREFIX}-inputW800px`,
  w100: `${PREFIX}-w100`
}

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')({
  [`& .${classes.dFlexRow}`]: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    width: '100%'
  },
  [`& .${classes.inputW250px}`]: {
    width: '250px'
  },
  [`& .${classes.inputW800px}`]: {
    width: '800px'
  },
  [`& .${classes.w100}`]: {
    width: '100%'
  }
})

type Props = {
  serviceChoices?: TypedChoices<ProductServiceDiscriminator>
  serviceTooltipText: string
  productsTooltipText: string
  targetType?: RuleTargetType
  action?: RuleAction
}

/**
 * This component shows an input for the service of the product
 * Then fetches the correct products for the selected service
 * Displays a select input to select the wanted service for the rule form
 * @param serviceChoices - the available choices for the service
 * @param serviceTooltipText - the tooltip text for the service input
 * @param productsTooltipText - the tooltip text for the products input
 * @param source - the source of the form
 * @param targetType - the target type of the rule (product)
 */
const ProductsByServiceSelector: RAFormFC<Props> = ({
  serviceChoices = serviceDiscriminatorChoices,
  serviceTooltipText,
  productsTooltipText,
  source,
  targetType,
  action
}) => {
  const { getSource, useSetValue, useGetValue } = useForm<
    IfClause & ThenClause
  >(source)
  const setProducts = useSetValue('products')
  const service = useGetValue('service')

  const entityMap = DISCRIMINATOR_COLLECTION_NAMES_MAP
  const selectedEntity = entityMap[service as keyof typeof entityMap]

  // with the correct map we can access our entity to fetch the correct products
  return (
    <Root>
      <KToolTip title={serviceTooltipText} placement="top">
        <KSelectInput
          label="form.fields.service"
          className={classes.inputW250px}
          source={getSource('service')}
          choices={serviceChoices}
          onChange={() => setProducts([])}
        />
      </KToolTip>
      {/* Hide the "products" input if we're compiling an incompatibility service rule */}
      {action === 'incompatibility' && targetType === 'service' ? null : (
        <KToolTip title={productsTooltipText} placement="top">
          <div className={classes.inputW800px}>
            <CustomReferenceInput
              entity={selectedEntity}
              filterFns={[isProductVisibleInStore]}
            >
              <LocalizedAutoCompleteArrayInput
                label="form.fields.products"
                source={getSource('products')}
                fullWidth
              />
            </CustomReferenceInput>
          </div>
        </KToolTip>
      )}
    </Root>
  )
}

export default ProductsByServiceSelector
