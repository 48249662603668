import * as yup from 'yup'
import { ObjectShape } from 'yup/lib/object'
import { AnyObject } from 'yup/lib/types'

/**
 * Enhances yup.object() with the possibility to pass the context as a second generic parameter
 *
 * @returns a yup.object constructor
 */
export const yupObject = <
  TObjectShape extends ObjectShape,
  Context extends AnyObject = AnyObject
>() => new yup.ObjectSchema<TObjectShape, Context>()
