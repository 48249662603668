import { createContext, useContext } from 'react'

export type KAccordionContextType = {
  toggleExpanded: () => void
}

const KAccordionContext = createContext<KAccordionContextType>({
  toggleExpanded: () => {}
})

export const KAccordionContextProvider = KAccordionContext.Provider

export const useKAccordionContext = () => {
  const context = useContext(KAccordionContext)

  if (context === undefined) {
    throw new Error(
      'hook useKAccordionContext must be used within a KAccordionContext'
    )
  }

  return context
}
