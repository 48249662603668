import { uploadOrKeepImg } from 'services/utils/images'
import { DrinkVariantToApi } from 'services/api/entities/catalog/drink/types/api/request-models'
import { APIDrinkVariant } from 'services/api/entities/catalog/drink/types/api/response-models'
import { DrinkVariant } from 'services/api/entities/catalog/drink/types/internal/drink-models'
import { parseRaImage } from 'services/api/raParsers'

// ============
// PARSE
// ============

export const drinkVariantParse = ({
  image,
  ...rest
}: APIDrinkVariant): DrinkVariant => ({
  image: parseRaImage(image),
  ...rest
})

// ============
// WRITE
// ============

export const drinkVariantWrite = async ({
  image,
  ...rest
}: DrinkVariant): Promise<DrinkVariantToApi> => ({
  ...rest,
  image: await uploadOrKeepImg(image)
})
