import React from 'react'
import { BaseListProps } from 'components/Lists/CatalogItemBaseList'
import EventListCommon from 'resources/operations/events/EventListCommon'
import { RAFormFC } from 'types/common'
import { BooleanField } from 'react-admin'
import StatusChipField from 'components/Inputs/Events/EventStatusChipField'
import { FILTER_SOURCES } from '@kampaay/common'
import KDateField from 'components/Fields/KDateField'

const EventList: RAFormFC<BaseListProps> = () => (
  <EventListCommon
    filterDefaultValues={{
      [FILTER_SOURCES.status.in]: ['confirmed']
    }}
    filter={{ [FILTER_SOURCES.status.nin]: ['draft', 'created'] }}
  >
    <KDateField label="form.fields.confirmationDate" source="confirmedAt" />
    <BooleanField
      source="isFromConcierge"
      label="form.fields.isFromConcierge"
    />
    <StatusChipField source="briefStatus" label="Brief status" />
    <StatusChipField source="supplierStatus" label="Supplier status" />
    <StatusChipField
      label="form.fields.supplierMessage"
      source="supplierMessagesStatus"
    />
  </EventListCommon>
)
export default EventList
