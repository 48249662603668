import { QueryObject } from 'services/queryEncoding/types'
import { encodeFilters } from 'services/queryEncoding/filter'
import { encodeSort } from 'services/queryEncoding/sort/sort'
import { encodePaginationParams } from 'services/queryEncoding/pagination'
import { encodeSpacesAndPlus } from 'services/queryEncoding/utils/utils'

/**
 * Used to encode the query string to provide to BE request pagination sorting and filters
 * @param  q query object containing pagination filtering and sorting data
 * @returns the query string to send with the get all request
 */
export const encodeQueryString = (q: QueryObject): string => {
  const { filter, sort, pagination } = q
  const encodedFilters = encodeFilters(filter!)
  const encodedSort = encodeSort(sort)
  const { page, perPage } = encodePaginationParams(pagination)

  const queryString = [
    [page, 'page'],
    [perPage, 'perPage'],
    [encodedFilters, 'filter'],
    [encodedSort, 'sort']
  ]
    .filter(
      ([encodedValue]) => encodedValue !== undefined && encodedValue !== ''
    )
    .map(([encodedValue, qsKey]) => `${qsKey}=${encodedValue}`)
    .join('&')
  return encodeSpacesAndPlus(queryString)
}
