import { createMockBuilder } from '../../../../utils'
import type { EmailMessage } from '../types'

const buildBaseMock = (): EmailMessage => ({
  id: 1,
  html: '',
  sentAt: '2021-05-18T20:44:06.940Z',
  attachments: [],
  isUserConfirmed: false,
  sender: 'gaiovolusenoquadrato@spqr.it',
  recipients: ['marcoulpiotraiano@spqr.it'],
  areSuggestionsChecked: false
})

export const buildEmailMessageMock = createMockBuilder(buildBaseMock)
