import { RAFormFC } from 'types/common'
import { TransactionService } from 'services/api/entities/accounting/transaction/types/internal/transaction-models'
import { useForm } from 'hooks/useForm'
import { PRODUCT_SERVICES_NAMES } from 'services/cfg'
import TransactionServiceInput, {
  ServiceComponentProps
} from 'components/FormComponents/Transaction/TransactionServiceInput'
import KAccordion from 'components/UI/Accordions/KAccordion/KAccordion'
import React from 'react'

type TransactionServiceAccordionProps = Pick<
  ServiceComponentProps,
  'formId' | 'updateServiceBillingInfo' | 'customerBusinessCountry'
>

const TransactionServiceAccordion: RAFormFC<
  TransactionServiceAccordionProps
> = ({ source, formId, customerBusinessCountry, updateServiceBillingInfo }) => {
  const { useGetValue } = useForm<TransactionService>(source)

  const serviceName = useGetValue('serviceName')
  const price = useGetValue('price')
  const customizations = useGetValue('customizations')

  return (
    <KAccordion
      accordionTitle={
        PRODUCT_SERVICES_NAMES.some((n) => n === serviceName)
          ? `form.headers.${serviceName}`
          : serviceName
      }
      defaultExpanded={price > 0 || !!customizations?.length}
    >
      <TransactionServiceInput
        source={source}
        formId={formId}
        updateServiceBillingInfo={updateServiceBillingInfo}
        customerBusinessCountry={customerBusinessCountry}
      />
    </KAccordion>
  )
}

export default TransactionServiceAccordion
