import { MappedEnrichedEvent } from 'services/api/entities/events/types/internal/event-models'
import { EventSize, TimeLabel } from '@kampaay/common'
import {
  KTestConfig,
  UseValidationSchema,
  yupArray,
  yupBoolean,
  yupDate,
  yupIgnore,
  yupNumber,
  yupObject,
  YupSchema,
  yupString
} from 'services/yup'
import {
  EventProductStatus,
  EventStatus,
  PaymentMethod,
  ProfessionalOutfit
} from 'types/form'
import { customizationVS } from 'services/validations/operations/events/customization'
import { mainProductSelectionVS } from 'services/validations/operations/events/selections/common/mainProduct'
import { drinkSelectionVS } from 'services/validations/operations/events/selections/drinks'
import { foodSelectionVS } from 'services/validations/operations/events/selections/foods'
import { locationSelectionVS } from 'services/validations/operations/events/selections/locations'
import { physicalExperienceSelectionVS } from 'services/validations/operations/events/selections/physicals'
import { virtualExperienceSelectionVS } from 'services/validations/operations/events/selections/virtuals'
import { eventGeneralServiceVS } from 'services/validations/operations/events/services/generalService'
import { enrichedProductServicesBundleVS } from 'services/validations/operations/events/services/productService'
import { enrichedProfessionalServiceGroupVS } from 'services/validations/operations/events/services/professionalService'
import { CheckoutQuestionReply } from '@kampaay/common'
import { docVS } from 'services/validations/doc'

export const checkoutQuestionResponseVS: YupSchema<CheckoutQuestionReply> =
  yupObject().shape({
    id: yupIgnore(),
    type: yupIgnore(),
    question: yupString(),
    response: yupString().optional(),
    responses: yupArray(yupString()).optional(),
    options: yupIgnore()
  })

const paymentMethodRequiredValidation: KTestConfig<PaymentMethod> = {
  name: 'paymentMethodRequiredValidation',
  message: 'Please select a payment method for confirmed status',
  test: (value, context) => {
    // the validation should fail when the event status is in a status after the confirmation
    return !(
      ['confirmed', 'invoiced', 'closed'].includes(
        context.parent.eventStatus
      ) && value === 'none'
    )
  }
}

export const useEventVS: UseValidationSchema<MappedEnrichedEvent> = () =>
  yupObject().shape({
    id: yupIgnore(),
    name: yupIgnore(),
    allowHubSpotIntegration: yupBoolean(),
    fullAddress: yupString(),
    guestsNumber: yupNumber().required().min(1),
    startDate: yupDate().required(),
    endDate: yupDate().required(),
    briefStatus: yupIgnore(),
    createdAt: yupIgnore(),
    storeUpdatedAt: yupIgnore(),
    customer: yupNumber().required(),
    customerInfo: yupObject(),
    customerDocs: yupArray(docVS).optional(),
    kampaayerDocs: yupArray(docVS).optional(),
    drinkNotes: yupString(),
    foodNotes: yupString(),
    virtualExperiencesNotes: yupString(),
    physicalExperienceNotes: yupString(),
    rentNotes: yupString(),
    musicNotes: yupString(),
    locationNotes: yupString(),
    purchasedServicesArray: yupArray().optional(),
    eventStatus: yupString<EventStatus>().required(),
    drinkStatus: yupString<EventProductStatus>(),
    foodStatus: yupString<EventProductStatus>(),
    rentStatus: yupString<EventProductStatus>(),
    musicStatus: yupString<EventProductStatus>(),
    locationStatus: yupString<EventProductStatus>(),
    professionalStatus: yupString<EventProductStatus>(),
    physicalExperienceStatus: yupString<EventProductStatus>(),
    virtualExperiencesStatus: yupString<EventProductStatus>(),
    professionalOutfit: yupString<ProfessionalOutfit>().optional(),
    purchasedServices: yupIgnore(),
    paymentStatus: yupIgnore(),
    supplierStatus: yupIgnore(),
    supplierMessagesStatus: yupIgnore(),
    customizations: yupArray(customizationVS),
    drinks: yupArray(drinkSelectionVS),
    rents: yupArray(mainProductSelectionVS),
    musics: yupArray(mainProductSelectionVS),
    locations: yupArray(locationSelectionVS),
    virtuals: yupArray(virtualExperienceSelectionVS),
    physicals: yupArray(physicalExperienceSelectionVS),
    foods: yupArray(foodSelectionVS),
    professionalService: enrichedProfessionalServiceGroupVS,
    generalService: eventGeneralServiceVS.optional(),
    productServices: enrichedProductServicesBundleVS,
    surveyResponses: yupArray(),
    musicDeliveryTime: yupString<TimeLabel>(),
    rentDeliveryTime: yupString<TimeLabel>(),
    musicPickupDate: yupDate().optional(),
    rentPickupDate: yupDate().optional(),
    conciergeId: yupNumber().optional(),
    isFromConcierge: yupIgnore(),
    paymentMethod: yupString<PaymentMethod>()
      .required()
      .test(paymentMethodRequiredValidation),
    planSteps: yupArray(),
    customerOrganizationId: yupIgnore(),
    checkoutQuestionResponses: yupArray(checkoutQuestionResponseVS),
    discountedPrice: yupIgnore(),
    pricing: yupIgnore(),
    geoAreaPresetFull: yupIgnore(),
    // The geoarea preset is mandatory only for standard events
    // concierge events can have a preset or not
    geoAreaPreset: yupNumber().when('isFromConcierge', {
      is: (isFromConcierge: boolean) => isFromConcierge,
      then: (s) => s.optional(),
      otherwise: (s) => s.required()
    }),
    kampaayerCoordinators: yupArray(yupNumber()).optional(),
    logistics: yupArray(), // TODO add proper validation
    confirmedInSf: yupIgnore(),
    guestManagementRequestId: yupIgnore(),
    size: yupString<EventSize>().optional(),
    confirmedAt: yupIgnore(),
    budgetId: yupNumber().when('paymentMethod', {
      is: 'organizationBudget',
      then: yupNumber().required(),
      otherwise: yupNumber().optional()
    }),
    color: yupString(),
    approverEmails: yupArray(),
    needsAllApprovals: yupBoolean(),
    pendingApproverEmails: yupArray()
  })
