import React from 'react'
import { WithKey, WithSource } from 'types/common'
import {
  getCurrentLang,
  WithIdAndName,
  parseMultilangJSON
} from '@kampaay/common'
import { useLangContext } from 'contexts/langContext'
import { List, arrayMove } from 'react-movable'

type Props = {
  onReorder: React.Dispatch<React.SetStateAction<WithIdAndName[]>>
  records: WithIdAndName[]
  itemNameSource?: string
}

const DraggableItem = <T extends any>({
  source,
  item
}: WithSource & { item: WithKey<T> }) => {
  const currentLang = getCurrentLang()
  const parsed = {
    id: 0,
    ...item,
    [source]: parseMultilangJSON(item[source as unknown as keyof typeof item])[
      currentLang
    ]
  }
  return (
    <div
      style={{
        width: '100%',
        padding: '10px',
        backgroundColor: '#ffaa0033',
        margin: '10px 0'
      }}
    >
      {parsed[source]}
    </div>
  )
}

const DraggableList: React.FC<Props> = ({
  onReorder,
  records = [],
  itemNameSource = 'name'
}) => {
  useLangContext()

  return (
    <div>
      <div style={{ marginLeft: '24px' }}>Name</div>
      <List
        values={records}
        onChange={({ oldIndex, newIndex }) =>
          onReorder(arrayMove([...records], oldIndex, newIndex))
        }
        renderList={({ children, props }) => (
          <div {...props} style={{ padding: '24px' }}>
            {children}
          </div>
        )}
        renderItem={({ value, props }) => (
          <div {...props}>
            <DraggableItem item={value} source={itemNameSource} />
          </div>
        )}
      />
    </div>
  )
}

export default DraggableList
