import { ProductCommon } from '@kampaay/common'
import {
  CatalogItemBaseCSV,
  formatCatalogItemBase
} from 'services/csv/entities/catalog/common/catalogItemBase'

export type ProductCommonCSV = CatalogItemBaseCSV

export const formatProductCommon = (p: ProductCommon): ProductCommonCSV =>
  formatCatalogItemBase(p)
