import React from 'react'
import ProductCommonTailoringInput from 'resources/crm/clients/organization/tailoring/components/productCommon/ProductCommonTailoringInput'
import ProductCommonTailoringsEdit from 'resources/crm/clients/organization/tailoring/ProductCommonTailoringsEdit'

const LogisticTailoringsEdit: React.FC = () => {
  return (
    <ProductCommonTailoringsEdit
      productEntity="logistics"
      tailoringsInput={ProductCommonTailoringInput}
    />
  )
}

export default LogisticTailoringsEdit
