import type { APISupplierType, SupplierType, SupplierTypeToAPI } from './types'

export * from './types'

export const parseSupplierType = (
  APISupplierService: APISupplierType
): SupplierType => APISupplierService
export const writeSupplierType = async (
  supplierServiceToAPI: SupplierType
): Promise<SupplierTypeToAPI> => await supplierServiceToAPI
