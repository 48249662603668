import { createMockBuilder } from '../../../../utils'
import type { TimeLog } from '../types'

const buildBaseMock = (): TimeLog => ({
  id: 1,
  date: new Date('2021-01-01'),
  timeSpent: {
    hours: 1,
    minutes: 15
  },
  activity: 'administrativeManagement',
  kampaayerId: 1,
  deal: {
    id: 1,
    type: 'event'
  }
})

export const buildTimeLogMock = createMockBuilder(buildBaseMock)
