import { useContext } from 'react'
import { LoggedUserContext } from 'contexts/loggedUserContext'

export const useLoggedUser = () => {
  const context = useContext(LoggedUserContext)
  if (context === undefined) {
    throw new Error(
      'hook useLoggedUser must be used within a LoggedUserContextProvider'
    )
  }
  return {
    loggedUser: context.loggedUser,
    loggedUserRole: context.loggedUser?.role
  }
}
