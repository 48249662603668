import React from 'react'
import { Datagrid, DatagridHeader, DatagridProps } from 'react-admin'
import EditableDatagridBody from 'components/Lists/EditableList/EditableDatagridBody'
import EditableDatagridCreateButton from 'components/Lists/EditableList/EditableDatagridCreateButton'
import KList, { KListProps } from 'components/Lists/KLists/KList'
import { EditableListDynamicForm } from 'components/Lists/EditableList/EditableListDynamicForm'
import EmptyEditableListPage from 'components/Lists/EditableList/EmptyEditableListPage'
import EmptyEditableListCreateForm from 'components/Lists/EditableList/EmptyEditableListCreateForm'

type EditableDatagridProps = Pick<
  DatagridProps,
  'children' | 'bulkActionButtons'
> &
  Omit<KListProps, 'children' | 'bulkActionButtons'> & {
    formContent: JSX.Element
    showCreateInRow?: boolean
    onRowClick?: 'edit' | 'expand'
    allowDelete?: boolean
    hasBulkActions?: boolean
  }

/**
 * A list that allows you to create new records and edit existing ones
 * directly in the datagrid using a form components that are passed as props
 * @param children - The columns of the datagrid
 * @param formContent - The form component to be used in the datagrid
 * @param showCreateInRow - If we should show the creation of the entity in the row or inside the create page
 * @param onRowClick - The type of action to be performed when the row is clicked (edit or expand)
 * @param filters - Filters for the list
 * @param exporter - The function that will be used to export the list
 * @param allowDelete - If the delete action should be allowed
 */
const EditableList: React.FC<EditableDatagridProps> = ({
  children,
  formContent,
  showCreateInRow = true,
  onRowClick = 'expand',
  exporter = false,
  allowDelete = true,
  actions: actionsProp,
  hasBulkActions = true,
  bulkActionButtons,
  ...rest
}) => {
  // This state is used to show an empty row in the form within a Create component
  const [isCreateVisible, setCreateVisibility] = React.useState(false)
  const [showEmptyPage, setShowEmptyPage] = React.useState(true)

  // If we should show the creation of the entity in the row we show our custom
  // create button that handle the state of the creation row
  const actions = showCreateInRow
    ? actionsProp ?? (
        <EditableDatagridCreateButton
          onClick={() => setCreateVisibility(true)}
        />
      )
    : undefined

  const handleCreateFromEmptyPage = () => {
    setShowEmptyPage(false)
    setCreateVisibility(true)
  }

  //===========================
  // JSX
  //===========================
  return (
    <KList
      exporter={exporter}
      actions={actions}
      // with 'false' we don't show any empty page
      // https://marmelab.com/react-admin/List.html#empty
      empty={
        showEmptyPage ? (
          <EmptyEditableListPage onCreateClick={handleCreateFromEmptyPage} />
        ) : (
          false
        )
      }
      {...rest}
    >
      <Datagrid
        empty={
          <EmptyEditableListCreateForm
            formContent={formContent}
            setCreateVisibility={setCreateVisibility}
          />
        }
        body={<EditableDatagridBody isCreateVisible={isCreateVisible} />}
        expand={
          <EditableListDynamicForm
            allowDelete={allowDelete}
            formContent={formContent}
            setCreateVisibility={setCreateVisibility}
          />
        }
        bulkActionButtons={bulkActionButtons}
        header={
          <DatagridHeader hasBulkActions={hasBulkActions} hasExpand={false} />
        }
        rowClick={onRowClick}
      >
        {children}
      </Datagrid>
    </KList>
  )
}

export default EditableList
