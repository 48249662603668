import { parseFileInfo } from '../../fileinfo'
import { productCommonParse } from '../common'
import type { APILogistic, Logistic } from './types'

export const logisticParse = (logistic: APILogistic): Logistic => {
  const { images, ...rest } = logistic
  return {
    ...rest,
    ...productCommonParse(logistic),
    image: parseFileInfo(images[0])
  }
}
