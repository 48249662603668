import React from 'react'
import KAccordion, {
  KAccordionProps
} from 'components/UI/Accordions/KAccordion/KAccordion'
import KFileInput from 'components/Inputs/KInputs/KFileInput'

type KFileInputAccordionProps = Pick<
  KAccordionProps,
  'accordionHeadingLevel' | 'asideContent' | 'defaultExpanded'
> & {
  source: string
  accept?: string
  multiple?: boolean
  label?: string
  placeholderText?: string
  accordionTitle?: string
}

const FileInputAccordion: React.FC<KFileInputAccordionProps> = ({
  source,
  accept = 'application/pdf',
  multiple,
  label,
  placeholderText,
  accordionTitle = 'Documents',
  accordionHeadingLevel = 'h2',
  defaultExpanded,
  asideContent
}) => {
  return (
    <KAccordion
      accordionTitle={accordionTitle}
      accordionHeadingLevel={accordionHeadingLevel}
      defaultExpanded={defaultExpanded}
      asideContent={asideContent}
    >
      <KFileInput
        source={source}
        accept={accept}
        multiple={multiple}
        label={label}
        placeholder={placeholderText}
      />
    </KAccordion>
  )
}

export default FileInputAccordion
