import { ISODate } from '@kampaay/common'

export {}

/**
 * A list of browsers to exclude from the date extension.
 */
const POLYFILL_BROWSERS_TO_EXCLUDE = ['Chrome']

/**
 * If we are in one of the allowed browsers by the plugin we override the constructor of date
 * Because  '--01-01' when passed to new date breaks the app in browsers like firefox
 */
if (
  !POLYFILL_BROWSERS_TO_EXCLUDE.some((browser) =>
    navigator.userAgent.includes(browser)
  )
) {
  // @ts-ignore
  // eslint-disable-next-line no-global-assign
  Date = class extends Date {
    constructor(...options: string[]) {
      // If the new Date was called with --01-01 format
      if (
        options.length === 1 &&
        typeof options[0] === 'string' &&
        options[0].match(/--\d{2}-\d{2}/)
      ) {
        const [month, day] = options[0].slice(2, 7).split('-')
        super(`2021-${month}-${day}`)
      } else {
        // @ts-ignore
        super(...options)
      }
    }
  }
}

declare global {
  interface Date {
    toISOString(): ISODate
  }
}
