import {
  type CountryInfo,
  type I18nBinding,
  getCountryLanguages
} from '@kampaay/common'

const getI18nFileFromLang = (lang: I18nBinding) => lang.i18nLocale

export const getI18nFileCodes = (countries: CountryInfo[]) =>
  countries.flatMap(getCountryLanguages).map(getI18nFileFromLang)
