import type { AnyFacet, FacetToAPI } from './types'

export const writeFacet = (f: AnyFacet): FacetToAPI => {
  // products and code are destructured because they DON'T have to be stringyfied
  const { id, description, products: _products, code: _code, ...rest } = f

  return {
    id,
    description,
    code: JSON.stringify(rest)
  }
}
