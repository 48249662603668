import { Button, useExpanded, useListContext } from 'react-admin'
import React, { useEffect } from 'react'
import { useKResourceContext } from 'hooks/useKResourceContext'

type EditableDatagridCreateButtonProps = {
  onClick: () => void
}

/**
 * The confirm button of the EditableDatagrid that on click expands the new empty row
 * @param onClick - The function to be called when the button is clicked
 */
const EditableDatagridCreateButton: React.FC<
  EditableDatagridCreateButtonProps
> = ({ onClick }) => {
  const { total } = useListContext()
  const resource = useKResourceContext()

  // used to expand the first row of the list
  const [isExpanded, setExpanded] = useExpanded(resource, 'row0')

  useEffect(() => {
    // Close the first row if it's open when the page is opened
    if (isExpanded) {
      setExpanded()
    }
  }, [])

  return (
    <>
      {!!total ? (
        <Button
          label="Create"
          onClick={() => {
            onClick()

            // after we enable the first row for the creation in order to see it empty
            // we expand it to show it to the user ready to be changed
            setExpanded()
          }}
          disabled={isExpanded}
        />
      ) : null}
    </>
  )
}

export default EditableDatagridCreateButton
