import { MainProductAPIEntity } from 'services/api/entities'
import { Supplier } from '@kampaay/common'
import { arrayIncludesEvery } from 'services/utils'

type Props = {
  serviceName: MainProductAPIEntity
  selectionIds: (number | undefined)[]
}
const filterSupplierBuilder = ({ serviceName, selectionIds }: Props) => {
  const filterSupplierWithService = (supplier: Supplier) =>
    supplier.suppliedServicesProducts.filter(
      (ssp) =>
        ssp.products.length &&
        ssp.service === serviceName &&
        arrayIncludesEvery(ssp.products, selectionIds)
    ).length > 0
  return filterSupplierWithService
}
export default filterSupplierBuilder
