import React from 'react'
import SectionWrapper from 'components/UI/SectionWrapper'
import VirtualExperiencesFormContent from 'resources/catalog/virtualexperiences/product/VirtualExperiencesFormContent'
import { KSimpleForm } from 'components/Forms/KForms/KSimpleForm'
import KEdit from 'components/Forms/KEdit/KEdit'

const VirtualExperienceEdit: React.FC = () => (
  <SectionWrapper>
    <KEdit>
      <KSimpleForm>
        <VirtualExperiencesFormContent />
      </KSimpleForm>
    </KEdit>
  </SectionWrapper>
)

export default VirtualExperienceEdit
