import React from 'react'
import { ArrayField, ChipField, RaRecord, SingleFieldList } from 'react-admin'
import { useKRecordContext } from 'hooks/useKRecordContext'
import {
  LinkedCustomer,
  LinkedOrganization,
  TailoredProduct,
  WithId
} from '@kampaay/common'

type Props<T extends 'customersList' | 'organizationsList'> = {
  record?: RaRecord
  arrayFieldSource: T
  chipFieldSource: T extends 'customersList' ? 'email' : 'name'
  label: T extends 'customersList'
    ? 'form.fields.customers'
    : 'form.fields.organizations'
  tailoredListField: T extends 'customersList'
    ? 'customersFull'
    : 'organizationsFull'
}

type LinkedUserOrOrganizations = LinkedCustomer[] | LinkedOrganization[]

/**
 * if there is one tailored field returns it
 * else if there is more than one show the first with ...
 * else don't show anything
 */
const getTailoredList = (field: string, records: LinkedUserOrOrganizations) => {
  return records.length > 1
    ? [
        {
          [field]: records[0][field as keyof LinkedUserOrOrganizations[number]]
        },
        { [field]: '...' }
      ]
    : records.length
    ? [
        {
          [field]: records[0][field as keyof LinkedUserOrOrganizations[number]]
        }
      ]
    : []
}

/**
 * This field shows in the list of tailored products the bound organizations or customers
 * @param chipFieldSource - the field to show in the chip
 * @param label - the label of the field
 * @param arrayFieldSource - the field to show in the array
 * @param tailoredListField - the field to show in the array
 * @param record - the record to show
 * @constructor
 */
const TailoredField = <T extends 'customersList' | 'organizationsList'>({
  chipFieldSource,
  label,
  arrayFieldSource,
  tailoredListField,
  record
}: Props<T>) => {
  // FIXME: this is a bad workaround to enforce backwards compatibility, but we should handle it in a more solid way
  const recordFromContext = useKRecordContext<WithId & TailoredProduct>()

  const tailoredParsedRecord = {
    ...recordFromContext,
    [arrayFieldSource]: getTailoredList(
      chipFieldSource,
      recordFromContext?.[tailoredListField] ?? []
    )
  }

  return (
    <ArrayField
      record={record ?? tailoredParsedRecord}
      source={arrayFieldSource}
      sortable={false}
      label={label}
    >
      <SingleFieldList>
        <ChipField source={chipFieldSource} />
      </SingleFieldList>
    </ArrayField>
  )
}

export default TailoredField
