import { bundleFacetIds } from '../../../../utils'
import type { FileUploader, MultipleFileUploader } from '../../../types'
import { optionedProductWrite } from '../common'
import type { Food, FoodToAPI } from './types'

export const foodWrite =
  (imageUploader: FileUploader, imagesUploader: MultipleFileUploader) =>
  async ({
    tagFacets = [],
    filterTypeFacet,
    logistics,
    ...rest
  }: Food): Promise<FoodToAPI> => {
    const facets = bundleFacetIds(filterTypeFacet, ...tagFacets)

    return {
      ...(await optionedProductWrite(imageUploader, imagesUploader)(rest)),
      logistics,
      facets
    }
  }
