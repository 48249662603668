import { dateToDateOnly } from '../../../../utils'
import type { OrganizationBudgetToAPI } from './types/api/request-models'
import type { OrganizationBudget } from './types/internal/budget-models'

export const organizationBudgetWrite = async ({
  startDate,
  endDate,
  membershipsIds,
  ...rest
}: OrganizationBudget): Promise<OrganizationBudgetToAPI> => {
  return {
    ...rest,
    startDate: dateToDateOnly(startDate),
    endDate: dateToDateOnly(endDate),
    membershipsIds: membershipsIds ?? []
  }
}
