import {
  EventServiceSummary,
  useEventService
} from 'hooks/events/useEventService'
import {
  EventProfessionalServiceSummary,
  useEventProfessionalServiceGroup
} from 'hooks/events/useEventProfessionalServiceGroup'
import { useForm } from 'hooks/useForm'
import { MappedEnrichedEvent } from 'services/api/entities/events/types/internal/event-models'
import { useCallback, useMemo } from 'react'
import { EnrichedProductService } from 'services/api/entities/events/types/internal/services'
import { isTruthy } from '@kampaay/common'

const checkIfAbleToNotify = (
  summary:
    | EventServiceSummary<EnrichedProductService>
    | EventProfessionalServiceSummary
) => !summary.isNotifySupplierDisabled && !summary.service.notifySupplier

export const useEventForm = () => {
  const { useGetValue } = useForm<MappedEnrichedEvent>(undefined)

  const foodSelections = useGetValue('foods') ?? []
  const logistics = useGetValue('logistics') ?? []

  const [professionalsGroupSummary, ...standardServiceSummaries] = [
    useEventProfessionalServiceGroup(),
    useEventService('physicals'),
    useEventService('locations'),
    useEventService('foods'),
    useEventService('drinks'),
    useEventService('musics'),
    useEventService('rents'),
    useEventService('virtuals')
  ]

  const globalCustomizations = useGetValue('customizations') ?? []
  const kampaayerDocs = useGetValue('kampaayerDocs') ?? []
  // checking in each selections array whether there is a tailored product
  const selections = useMemo(
    () =>
      [
        ...standardServiceSummaries,
        ...professionalsGroupSummary.professionalServiceSummaries
      ].flatMap((s) => s.selections),
    [professionalsGroupSummary, standardServiceSummaries]
  )

  const hasTailoredProducts = useMemo(
    () => selections.some((selection) => !!selection?.isPrivate),
    [selections]
  )

  const serviceSummaries = useMemo(
    () =>
      [
        ...standardServiceSummaries,
        ...professionalsGroupSummary.professionalServiceSummaries
      ].filter((s) => s.hasSelections || s.hasCustomizations),
    [professionalsGroupSummary, standardServiceSummaries]
  )

  const hasProducts = !!selections.length
  const haServiceCustomizations = useMemo(
    () => serviceSummaries.some((s) => s.hasCustomizations),
    [serviceSummaries]
  )

  const resetAllSelections = useCallback(() => {
    standardServiceSummaries.forEach((s) => s.resetSelection())
    professionalsGroupSummary.professionalServiceSummaries.forEach((s) =>
      s.resetSelection()
    )
  }, [professionalsGroupSummary, standardServiceSummaries])

  const notifyAllSuppliers = () => {
    standardServiceSummaries.forEach((s) => s.notifyServiceSupplier())
    professionalsGroupSummary.professionalServiceSummaries.forEach((s) =>
      s.notifyServiceSupplier()
    )
  }

  const ableToNotifyAllSuppliers: boolean = useMemo(() => {
    const ableToNotifyStandards =
      standardServiceSummaries.some(checkIfAbleToNotify)
    const ableToNotifyProfessionals =
      professionalsGroupSummary.professionalServiceSummaries.some(
        checkIfAbleToNotify
      )

    return ableToNotifyStandards || ableToNotifyProfessionals
  }, [standardServiceSummaries, professionalsGroupSummary])

  const services = [professionalsGroupSummary, ...standardServiceSummaries]

  /**
   * The sum of all the customizations of all the services and the global ones
   */
  const numberOfCustomizations = useMemo(
    () =>
      services.map((s) => s.numberOfCustomizations).sum() +
      (globalCustomizations?.length ?? 0),
    [services, globalCustomizations]
  )
  /**
   * The total number of kampaayer docs
   */
  const numberOfKampaayerDocks = kampaayerDocs?.length ?? 0

  const hasIntolerances = useMemo(
    () =>
      foodSelections.some(
        // intolerances gets initialized as null for some reason
        (f) => Object.values(f.intolerances ?? {}).filter(isTruthy).length
      ),
    [foodSelections]
  )

  return {
    hasTailoredProducts,
    hasProducts,
    hasSelections: hasProducts || haServiceCustomizations,
    summaries: serviceSummaries,
    numberOfCustomizations,
    numberOfKampaayerDocks,
    hasIntolerances,
    logistics,
    resetAllSelections,
    notifyAllSuppliers,
    ableToNotifyAllSuppliers
  }
}
