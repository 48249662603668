import React, { PropsWithChildren } from 'react'
import { useLangContext } from 'contexts/langContext'
import { useKRecordContext } from 'hooks/useKRecordContext'
import { parseMultilangOrFallbackToAnyAvailable } from '@kampaay/common'
import { RaRecord } from 'ra-core'
import get from 'lodash/get'

export type LocalizedFieldProps = PropsWithChildren & {
  source: string
  children: React.ReactElement
  record?: RaRecord
}

/**
 * This is a wrapper for fields that translates the text from multilang
 * @param children - the field to translate
 * @param source - the source of the text to translate
 * @param record - the record to translate - if not passed it will be retrieved from the context
 */
const LocalizedField: React.FC<LocalizedFieldProps> = ({
  children,
  source,
  record
}) => {
  /**
   * Here we retrieve record from the context
   * If a record is passed as a prop, it will be used instead
   */
  const currentRecord = useKRecordContext({ record })

  const { currentLang } = useLangContext()

  const finalValue = get(currentRecord, source)

  const translated = parseMultilangOrFallbackToAnyAvailable(
    finalValue,
    currentLang
  )

  return React.cloneElement(children, {
    ...children.props,
    record: {
      id: currentRecord?.id,
      [source!]: translated
    },
    source
  })
}

export default LocalizedField
