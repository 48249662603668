import { useForm } from 'hooks/useForm'
import React from 'react'
import { RAFormFC } from 'types/common'
import { parseMultilangOrFallbackToAnyAvailable } from '@kampaay/common'
import KNumberInput from 'components/Inputs/KInputs/KNumberInput/KNumberInput'
import { Customization } from 'services/api/entities/events/types/internal/event-models'

const TransactionCustomizationInput: RAFormFC = ({ source }) => {
  const { getSource, useGetValue } = useForm<Customization>(source)
  const transactionsName = useGetValue('name')
  const translatedCustomizationName =
    parseMultilangOrFallbackToAnyAvailable(transactionsName)

  return (
    <>
      {translatedCustomizationName && (
        <div style={{ margin: '12px 0px' }}>{translatedCustomizationName}</div>
      )}

      <KNumberInput
        source={getSource('price')}
        label="form.fields.customizationPrice"
      />
      <KNumberInput
        source={getSource('cost')}
        label="form.fields.customizationCost"
      />
    </>
  )
}

export default TransactionCustomizationInput
