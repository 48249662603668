import { FileInput } from 'ra-ui-materialui'
import React from 'react'
import { FileInputProps, useNotify } from 'react-admin'
import { KInputProps, useKInput } from 'hooks/useKInput/useKInput'
import { KFileField } from 'components/Fields/KFileField'

type FileError = {
  rawFile?: {
    message?: string
  }
}

type KFileInputProps = KInputProps &
  FileInputProps & {
    disabled?: boolean
  }

const DEFAULT_DOC_MAX_FILE_SIZE = 5_000_000

type FileCodeError = 'file-too-large'

export const FILE_ERRORS_MAP: Record<FileCodeError, string> = {
  'file-too-large': 'File is larger than 5MB'
}

const KFileInput: React.FC<KFileInputProps> = (props) => {
  const {
    placeholder = 'Drop a file to upload, or click to select it (only PDF format allowed)',
    disabled,
    source,
    maxSize = DEFAULT_DOC_MAX_FILE_SIZE,
    label: _labelProp,
    ...restProps
  } = props

  const {
    isRequired,
    fieldState: { error },
    k: { label }
  } = useKInput(props)

  const typedErrors = (error ?? []) as FileError[]
  const errorMessage = typedErrors[0]?.rawFile?.message
  const notify = useNotify()

  return !disabled ? (
    <>
      <FileInput
        placeholder={placeholder}
        maxSize={maxSize}
        source={source}
        label={label}
        options={{
          onDropRejected: (files) => {
            const codeError = files[0]?.errors[0]?.code
            if (codeError && codeError === 'file-too-large') {
              notify(FILE_ERRORS_MAP['file-too-large'], { type: 'error' })
            }
          }
        }}
        isRequired={isRequired}
        {...restProps}
      >
        <KFileField source="src" title="title" target="_blank" />
      </FileInput>
      {!!errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
    </>
  ) : (
    <KFileField source={source} label={label} title="title" target="_blank" />
  )
}

export default KFileInput
