import { RaRecord, useRecordContext } from 'ra-core'

type PropWithRecord<T extends RaRecord | Omit<RaRecord, 'id'> = RaRecord> = {
  record?: T
}

/**
 * Returns the record from the RecordContext.
 * The record context can be overridden by passing a prop with record
 * @param props
 */
export const useKRecordContext = <
  T extends RaRecord | Omit<RaRecord, 'id'> = RaRecord
>(
  props?: PropWithRecord<T>
) => {
  return useRecordContext<T>(props) as T | undefined
}
