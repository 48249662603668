import { byRulesPricingParse } from 'services/api/entities/catalog/location/locationPricing'
import {
  APIDrinkTailoring,
  APIOptionedTailoring,
  APIPriceRange,
  APIPricingRuleSet,
  APIProductTailoring,
  APIProductTailoringWithByRulePricing,
  APIProductTailoringWithStandardPricing,
  APITailorings,
  DrinkTailoring,
  LocationPricingRuleSet,
  OptionedTailoring,
  parseProfessionalPricing,
  ProductTailoring,
  ProductTailoringWithLocationPricing,
  ProductTailoringWithProfessionalPricing,
  ProductTailoringWithStandardPricing,
  ProfessionalPricingRuleSet,
  Tailorings
} from '@kampaay/common'

// ================================================
// PARSERS HELPERS
// ================================================
// with standard pricing
// product
// with standard pricing
export const parseProductTailoring = (
  data: APIProductTailoring<APIPriceRange>
): ProductTailoring<APIPriceRange> => {
  return {
    ...data,
    hasCustomPrice: !!data.pricing.length
  }
}

// with byRule pricing
// professional
export const parseProfessionalTailoring = (
  data: APIProductTailoring<APIPricingRuleSet>
): ProductTailoring<ProfessionalPricingRuleSet> => {
  return {
    ...data,
    pricing: data.pricing.map(parseProfessionalPricing),
    hasCustomPrice: !!data.pricing.length
  }
}

// location
export const parseLocationTailoring = (
  data: APIProductTailoring<APIPricingRuleSet>
): ProductTailoring<LocationPricingRuleSet> => {
  return {
    ...data,
    pricing: data.pricing.map(byRulesPricingParse),
    hasCustomPrice: !!data.pricing.length
  }
}

// optioned
export const parseOptionedTailoring = (
  data: APIOptionedTailoring
): OptionedTailoring => {
  return {
    ...data,
    options: data.options.map((option) => ({
      ...option,
      hasCustomPrice: !!option.pricing.length
    })),
    hasCustomPrice: !!data.pricing.length
  }
}

// drink
export const parseDrinkTailoring = (
  data: APIDrinkTailoring
): DrinkTailoring => {
  return {
    ...data,
    variants: data.variants.map((variant) => ({
      ...variant,
      hasCustomPrice: !!variant.pricing.length
    })),
    hasCustomPrice: false
  }
}

// ================================================
// PARSERS
// ================================================
export const parseProductTailorings = (
  data: APITailorings<APIProductTailoringWithStandardPricing>
): Tailorings<ProductTailoringWithStandardPricing> => {
  return { id: data.id, tailorings: data.tailorings.map(parseProductTailoring) }
}

export const parseProfessionalTailorings = (
  data: APITailorings<APIProductTailoringWithByRulePricing>
): Tailorings<ProductTailoringWithProfessionalPricing> => {
  return {
    id: data.id,
    tailorings: data.tailorings.map(parseProfessionalTailoring)
  }
}

export const parseLocationTailorings = (
  data: APITailorings<APIProductTailoringWithByRulePricing>
): Tailorings<ProductTailoringWithLocationPricing> => {
  return {
    id: data.id,
    tailorings: data.tailorings.map(parseLocationTailoring)
  }
}

export const parseOptionedTailorings = (
  data: APITailorings<APIOptionedTailoring>
): Tailorings<OptionedTailoring> => {
  return {
    id: data.id,
    tailorings: data.tailorings.map(parseOptionedTailoring)
  }
}

export const parseDrinkTailorings = (
  data: APITailorings<APIDrinkTailoring>
): Tailorings<DrinkTailoring> => {
  return {
    id: data.id,
    tailorings: data.tailorings.map(parseDrinkTailoring)
  }
}
