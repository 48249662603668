import React from 'react'
import TransactionFormContent from 'components/FormComponents/Transaction/TransactionFormContent'
import SectionWrapper from 'components/UI/SectionWrapper'
import NoDeleteButtonToolbar from 'components/Toolbars/NoDeleteButtonToolbar'
import { KSimpleForm } from 'components/Forms/KForms/KSimpleForm'
import KEdit from 'components/Forms/KEdit/KEdit'

const TransactionEdit: React.FC = () => {
  return (
    <SectionWrapper>
      <KEdit actions={<></>}>
        <KSimpleForm toolbar={<NoDeleteButtonToolbar />}>
          <TransactionFormContent />
        </KSimpleForm>
      </KEdit>
    </SectionWrapper>
  )
}

export default TransactionEdit
