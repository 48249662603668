import PocEdit from 'poc/poc2/PocEdit'
import { PocDescriptor } from 'poc/factory'
import { apiMocker } from 'poc/apiMock'
import { Datagrid, List, TextField } from 'react-admin'
import React from 'react'
import { KSimpleForm } from 'components/Forms/KForms/KSimpleForm'
import KTextInput from 'components/Inputs/KInputs/KTextInput/KTextInput'
import KCreate from 'components/Forms/KCreate/KCreate'

type Model = {
  id: number
  title: string
  highlights: { item: string }[]
}

const MOCK: Model[] = [
  {
    id: 1,
    title: 'test1',
    highlights: Array.from({ length: 100 }, (_, i) => ({
      item: `highlight ${i}`
    }))
  },
  {
    id: 2,
    title: 'test2',
    highlights: Array.from({ length: 100 }, (_, i) => ({
      item: `highlight ${i}`
    }))
  }
]

const PocCreate = () => {
  return (
    <KCreate>
      <KSimpleForm>
        <KTextInput source="title" />
      </KSimpleForm>
    </KCreate>
  )
}

const PocList = () => (
  <List exporter={false}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="title" />
    </Datagrid>
  </List>
)

const cfg: PocDescriptor = {
  dp: apiMocker(MOCK),
  forms: { list: PocList, edit: PocEdit, create: PocCreate }
}

export default cfg
