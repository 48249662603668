import { useTranslate } from 'ra-core'
import React from 'react'
import { productStatusChoices } from 'services/cfg/form'
import EventServiceBriefSection from 'components/FormComponents/EventServiceBriefSection'
import KRichTextInput from 'components/Inputs/KInputs/KRichTextInput'
import { TailoredEventSelectionInput } from 'components/FormComponents/TailoredEventSelectionInput'
import KDivider from 'components/UI/KDivider'
import EventServiceSection from 'resources/operations/events/EventsComponents/EventServiceSection'
import { isFoodObsolete } from 'resources/operations/events/utils'
import FoodSelectionDetailsFormContent from 'resources/operations/events/eventsFormTabs/FoodTab/FoodSelectionDetailsFormContent'
import KFormTab from 'components/Forms/KForms/KFormTab'
import { Badge } from '@mui/material'
import KSelectInput from 'components/Inputs/KInputs/KSelectInput'
import LabeledBooleanField from 'components/Fields/LabeledFields/LabeledBooleanField'
import { RAFormFC } from 'types/common'
import { MappedEnrichedEvent } from 'services/api/entities/events/types/internal/event-models'
import { useForm } from 'hooks/useForm'
import PricingSummary from 'components/FormComponents/PricingSummary/PricingSummary'
import { FormTabProps } from 'react-admin'
import FlexBox from 'components/UI/layout/FlexBox'
import EventCustomizationArrayInputAccordion from 'components/FormComponents/Customizations/EventCustomizationArrayInputAccordion'
import { useEventService } from 'hooks/events/useEventService'

type Props = Omit<FormTabProps, 'label'>

const FoodFormTab: RAFormFC<Props> = ({ source, ...rest }) => {
  const {
    hasSelections,
    hasCustomizations,
    notifyServiceSupplier,
    isNotifySupplierDisabled
  } = useEventService('foods')

  const translate = useTranslate()
  const { useGetValue, getSource } = useForm<MappedEnrichedEvent>(source)

  const productServices = useGetValue('productServices')
  const service = productServices?.foodService

  const isGeoareaSelected = !!useGetValue('geoAreaPreset')

  return (
    <KFormTab
      {...rest}
      disabled={!isGeoareaSelected}
      label={
        <Badge
          badgeContent={hasSelections || hasCustomizations ? 1 : 0}
          color="primary"
          variant="dot"
        >
          {translate('form.tabs.food')}
        </Badge>
      }
    >
      <FlexBox justifyContent="space-between">
        <KSelectInput
          source="foodStatus"
          label="form.fields.status"
          choices={productStatusChoices}
          defaultValue={productStatusChoices[0].id}
        />
        <PricingSummary
          source={getSource('productServices.foodService.pricing')}
          title={'form.headers.servicePricing'}
        />
      </FlexBox>
      <KRichTextInput source="foodNotes" label="form.fields.notes" />
      <EventServiceSection
        source={getSource('productServices.foodService')}
        serviceName={'foods'}
      />
      {/* foods input */}
      <TailoredEventSelectionInput
        entity="foods"
        label="form.fields.orders"
        source="foods"
        getIsProductObsolete={isFoodObsolete}
        disableProductChoiceAfterSelections
      >
        <FoodSelectionDetailsFormContent />
      </TailoredEventSelectionInput>
      <>
        <FlexBox>
          <KDivider />
          <EventCustomizationArrayInputAccordion
            source="productServices.foodService"
            maxCustomizations={1}
          />
        </FlexBox>

        {!!service?.briefQuestions && !!service?.briefQuestions.length && (
          <>
            <KDivider
              title="Event Brief"
              headingLevel="h3"
              style={{
                width: '100%'
              }}
            />
            <LabeledBooleanField
              source={`productServices.foodService.briefConfirmed`}
              label="form.fields.briefConfirmation"
            />
            <EventServiceBriefSection
              serviceSource={`productServices.foodService`}
              notifyServiceSupplier={notifyServiceSupplier}
              isNotifySupplierDisabled={isNotifySupplierDisabled}
            />
          </>
        )}
      </>
    </KFormTab>
  )
}

export default FoodFormTab
