import { mainProductParse, mainProductWrite } from '@kampaay/common'
import { APICfg } from 'services/api/api'
import {
  drinkVariantParse,
  drinkVariantWrite
} from 'services/api/entities/catalog/drink/drinkVariant'
import { DrinkToAPI } from 'services/api/entities/catalog/drink/types/api/request-models'
import { APIDrink } from 'services/api/entities/catalog/drink/types/api/response-models'
import { Drink } from 'services/api/entities/catalog/drink/types/internal/drink-models'
import { uploadOrKeepMultipleImgs } from 'services/utils/images'

// ============
// PARSE
// ============

const drinkParse = (res: APIDrink): Drink => ({
  ...res,
  ...mainProductParse(res),
  hasMultipleVariants: res.variants.length > 1,
  variants: res.variants.map(drinkVariantParse)
})

// ============
// WRITE
// ============

const drinkWrite = async ({
  variants,
  drinksPerUnit,
  minQuantity,
  ...req
}: Drink): Promise<DrinkToAPI> => ({
  ...(await mainProductWrite(uploadOrKeepMultipleImgs)(req)),
  variants: await Promise.all(variants.map(drinkVariantWrite)),
  drinksPerUnit,
  minQuantity
})

// ============
// CFG
// ============

const apiCfg: APICfg = {
  endpoint: 'drink',
  parse: drinkParse,
  write: drinkWrite
}

export default apiCfg
