import { UseValidationSchema } from 'services/yup/types'
import { yupIgnore } from 'services/yup'
import { useExperienceVS } from 'services/validations/catalog/experience'
import { VirtualExperience } from 'services/api/entities/catalog/virtual/types/internal/virtual-models'
import { APIPriceRange } from '@kampaay/common'

export const useVirtualVS: UseValidationSchema<
  VirtualExperience,
  APIPriceRange[] | undefined
> = () =>
  useExperienceVS().shape({
    virtualCategoryFacet: yupIgnore()
  })
