import { LocalBar } from '@mui/icons-material'
import DrinkList from 'resources/catalog/drink/product/DrinkList'
import DrinEdit from 'resources/catalog/drink/product/DrinkEdit'
import DrinkCreate from 'resources/catalog/drink/product/DrinkCreate'

const drinkIndex = {
  icon: LocalBar,
  list: DrinkList,
  edit: DrinEdit,
  create: DrinkCreate
}

export default drinkIndex
