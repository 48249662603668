import React, { useMemo } from 'react'
import { useKRecordContext } from 'hooks/useKRecordContext'
import { EventItem } from 'services/api/entities/events/types/internal/event-models'
import EventFromConciergeEdit from 'resources/operations/events/EventsComponents/EventFromConciergeEdit'
import EventFormContent from 'resources/operations/events/EventsComponents/EventFormContent'
import { CRUDMode } from 'services/utils/concierge'
import EventFormToolbar from 'components/Toolbars/EventFormToolbar'
import { Card } from '@mui/material'
import { useTranslate } from 'react-admin'
import { Toolbar } from 'ra-ui-materialui'
import KSaveButton from 'components/Buttons/KSaveButton'
import { WarningTwoTone } from '@mui/icons-material'

type Props = {
  crudMode: CRUDMode
}
const EventEditContent: React.FC<Props> = ({
  crudMode: _crudMode,
  ...props
}) => {
  const record = useKRecordContext<EventItem>()
  const translate = useTranslate()

  const warningImage = useMemo(
    () => (
      <WarningTwoTone className="animate-none text-3xl group-hover:animate-spin motion-reduce:animate-none" />
    ),
    []
  )

  return (
    <>
      {record?.eventStatus === 'pendingApproval' && (
        <div
          className="border-3 group flex items-center justify-between gap-2 rounded border-solid border-error px-4 py-2 text-lg font-bold text-error motion-reduce:animate-none"
          style={{
            background:
              'repeating-linear-gradient(-45deg, transparent, transparent 40px, #FFDF67 40px, #FFDF67 80px), linear-gradient(to bottom, #FFA72D, #FFC24B)'
          }}
        >
          {warningImage}
          <span className="drop-shadow">
            {translate('form.event.pendingApprovalWarning')}
          </span>
          {warningImage}
        </div>
      )}
      {record?.isFromConcierge ? (
        <EventFromConciergeEdit
          toolbar={
            <Toolbar>
              <KSaveButton
                disabled={record.eventStatus === 'pendingApproval'}
              />
            </Toolbar>
          }
          {...props}
        />
      ) : (
        <Card>
          <EventFormContent
            toolbar={<EventFormToolbar crudMode="editMode" />}
            {...props}
          />
        </Card>
      )}
    </>
  )
}

export default EventEditContent
