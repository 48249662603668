import type { FileUploader } from '../../types'
import type { Kampaayer, KampaayerToAPI } from './types'

export const kampaayerWrite =
  (uploadFile: FileUploader) =>
  async (req: Kampaayer): Promise<KampaayerToAPI> => {
    const kContract = await uploadFile(req.kContract)
    const managedCountries = [req.managedCountries]

    return {
      ...req,
      kContracts: kContract ? [kContract] : [],
      managedCountries
    }
  }
