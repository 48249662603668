import type { APIKampaayer, Kampaayer } from '.'
import { DEFAULT_COUNTRY_ID, parseFileInfo } from '../../..'

export const kampaayerParse = ({
  kContracts,
  ...res
}: APIKampaayer): Kampaayer => {
  return {
    ...res,
    fullName: `${res.firstName} ${res.lastName}`,
    kContract: kContracts[0] ? parseFileInfo(kContracts[0]) : undefined,
    // TODO unmock: remove this check once all kampaayers have countries
    managedCountries: res.managedCountries?.[0]
      ? res.managedCountries[0].id
      : DEFAULT_COUNTRY_ID
  }
}
