import {
  UseValidationSchema,
  yupArray,
  yupBoolean,
  yupIgnore,
  yupNumber,
  yupObject,
  yupString
} from 'services/yup'
import {
  ActivityStatus,
  IfClause,
  ProductServiceDiscriminator,
  Rule,
  RuleAction,
  RuleOperator,
  RuleTargetType,
  RuleWhat,
  ThenClause
} from '@kampaay/common'
import { YupSchema } from 'services/yup/types'
import { TARGET_TYPE_FOR_RULE_ACTION } from 'services/cfg/rules'

const thenClauseBaseVS: YupSchema<ThenClause> = yupObject().shape({
  action: yupString<RuleAction>().required(),
  targetType: yupString<RuleTargetType>()
    .required()
    .when('action', {
      is: (action: RuleAction) => action === 'incompatibility',
      then: yupString<RuleTargetType>().oneOf(
        TARGET_TYPE_FOR_RULE_ACTION['incompatibility']
      ),
      otherwise: yupString<RuleTargetType>().oneOf(
        TARGET_TYPE_FOR_RULE_ACTION['suggest']
      )
    }),
  service: yupString<ProductServiceDiscriminator>().required(),
  products: yupArray(yupNumber()).when(['targetType', 'action'], {
    is: (targetType: RuleTargetType, action: RuleAction) =>
      targetType === 'service' && action === 'incompatibility',
    then: (schema) => schema.max(0), // We want the array to be empty
    otherwise: (schema) => schema.min(1)
  })
})

const ifClauseVS: YupSchema<IfClause> = yupObject().shape({
  what: yupString<RuleWhat>().required(),
  service: yupString<ProductServiceDiscriminator>().required(),
  products: yupArray(yupNumber()).min(1)
})

const suggestThenClauseVS: YupSchema<ThenClause> = thenClauseBaseVS.shape({
  action: yupString<RuleAction>().oneOf(
    ['suggest'],
    'form.validations.onlySuggestActionAllowed'
  )
})

const incompatibilityThenClauseVS: YupSchema<ThenClause> =
  thenClauseBaseVS.shape({
    action: yupString<RuleAction>().oneOf(
      ['incompatibility'],
      'form.validations.onlyBlockSelectionOfActionAllowed'
    )
  })

export const useRulesVS: UseValidationSchema<Rule> = () =>
  yupObject().shape({
    id: yupIgnore(),
    description: yupString().required(),
    operator: yupString<RuleOperator>().required(),
    name: yupString().required(),
    status: yupString<ActivityStatus>().required(),
    then: yupArray(thenClauseBaseVS)
      .when('operator', {
        is: (operator: RuleOperator) => operator === 'and',
        then: yupArray(suggestThenClauseVS),
        otherwise: yupArray(incompatibilityThenClauseVS)
      })
      .min(1)
      // Check that all then clauses have the same action
      .test({
        test: (rules) => {
          const firstRule = rules[0]
          if (!firstRule) return true
          return rules.every((rule) => rule.action === firstRule.action)
        },
        message: 'form.validations.allThenClausesHaveTheSameAction'
      }),
    if: yupArray(ifClauseVS).min(1),
    servicesWhere: yupArray().of(yupString()),
    applyInverseLogic: yupBoolean().optional()
  })
