import { mainServiceMenuMap } from 'components/FormComponents/Organization/OrganizationMenu'
import { entries, keys } from 'services/utils'
import { ServiceCollectionName } from 'types/common'
import { HiddenServices } from 'services/api/entities/crm/organizations/types/internal/organization-model'

export const hiddenServicesParse = (
  servicesToHide: ServiceCollectionName[]
): HiddenServices => {
  // Drinks are removed because they are managed in the form via the toggle for the food service, so they do not need a specific entry
  // ISSUE: https://gitlab.com/kampaay/general/-/issues/1697
  const collectionNamesWithoutDrinks: ServiceCollectionName[] = keys(
    mainServiceMenuMap
  ).filter((s) => s !== 'drinks')

  return Object.fromEntries(
    collectionNamesWithoutDrinks.map((s) => [s, servicesToHide.includes(s)])
  ) as HiddenServices
}

export const hiddenServicesWrite = (
  hiddenServices: HiddenServices
): ServiceCollectionName[] => {
  const hiddenServicesArray: ServiceCollectionName[] = entries(hiddenServices)
    .filter(([_key, isHidden]) => isHidden)
    .map(([serviceCollectionName]) => serviceCollectionName)

  if (hiddenServicesArray.includes('foods')) {
    hiddenServicesArray.push('drinks')
  }

  // As requested here https://gitlab.com/kampaay/general/-/issues/1697
  // we want foods and drinks to show and be hidden together.
  // The form only shows the food input with a label of "Foods and drinks",
  // so we need to hide both foods and drinks if the user hides foods.

  return hiddenServicesArray
}
