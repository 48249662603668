import {
  yupArrayWithContext,
  yupBoolean,
  yupIgnore,
  yupNumber,
  YupSchema,
  yupString
} from 'services/yup'
import { withCostAndPriceVS } from 'services/validations/catalog/by-rule-pricing/byRulePricing'
import { LocationRule, NHR, RulePricingType, TimeLabel } from '@kampaay/common'
import { isNameUniqueCFG } from 'services/validations/common'
import {
  hasDefaultNHRCFG,
  isAllExtraRuleCFG,
  isAtLeastOneRuleFullDayCFG
} from 'services/validations/catalog/location/byRulePricing.validations'

const pricingRuleVS: YupSchema<LocationRule> = withCostAndPriceVS.shape({
  mode: yupIgnore().required(),
  name: yupString(),
  priceType: yupString<RulePricingType>(),
  isExtra: yupBoolean().required(),
  atLeast: yupNumber<NHR>().when('mode', {
    is: 'nhr',
    then: (schema) => schema.min(0.5).required()
  }),
  hoursFrom: yupString<TimeLabel>().when('mode', {
    is: 'tpr',
    then: (schema) => schema.required()
  }),
  hoursTo: yupString<TimeLabel>().when('mode', {
    is: 'tpr',
    then: (schema) => schema.required()
  })
})

export const pricingRulesVS = yupArrayWithContext<LocationRule>()(pricingRuleVS)
  .min(1)
  .test(isNameUniqueCFG)
  .test(isAllExtraRuleCFG)
  .test(hasDefaultNHRCFG)
  .test(isAtLeastOneRuleFullDayCFG)
