import React from 'react'
import EventBaseSelectionInputWrapper, {
  EventBaseSelectionInputWrapperProps
} from 'components/FormComponents/EventBaseSelectionInputWrapper'
import EventSubSelectionInputContent, {
  EventSubSelectionInputContentProps
} from 'components/FormComponents/EventSubSelectionInputContent'

export type EventBaseSelectionArrayInputProps = Omit<
  EventBaseSelectionInputWrapperProps,
  'children'
> &
  EventSubSelectionInputContentProps

/**
 * Simple selection array input used for base entities
 * like: Options, Upsellings and Variants
 * Used in event form
 * @param label - label for input
 * @param choices - choices for select input
 * @param disableInputs - disable all inputs
 * @param hideQuantity - hide quantity input
 * @param arrayInputLabel - label for array input
 * @param source - source for array input
 * @param disableAdd - disable add button
 */
const EventBaseSelectionArrayInput: React.FC<
  EventBaseSelectionArrayInputProps
> = ({
  label,
  choices = [],
  disableInputs,
  hideQuantity = false,
  arrayInputLabel,
  source,
  disableAdd
}) => (
  <EventBaseSelectionInputWrapper
    disableInputs={disableInputs}
    arrayInputLabel={arrayInputLabel}
    source={source}
    disableAdd={disableAdd}
  >
    <EventSubSelectionInputContent
      label={label}
      choices={choices}
      hideQuantity={hideQuantity}
      disableInputs={disableInputs}
    />
  </EventBaseSelectionInputWrapper>
)

export default EventBaseSelectionArrayInput
