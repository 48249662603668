import { sortAscByMaxParticipants } from '../../../../../utils/sorting'
import type {
  APIWithQuantityHint,
  APIWithTimeBeforeAfterHint,
  APIWithTimeBeforeHint,
  QuantityHint,
  TimeBeforeAfterHint,
  TimeBeforeHint,
  WithQuantityHint,
  WithTimeBeforeAfterHint,
  WithTimeBeforeHint
} from './types'

const DEFAULT_HINT_MAX_PARTICIPANTS = 1000000
const DEFAULT_HINT_HOURS_BEFORE = 0
const DEFAULT_HINT_HOURS_AFTER = 0

export const DEFAULT_QUANTITY_HINT: QuantityHint = {
  maxParticipants: DEFAULT_HINT_MAX_PARTICIPANTS,
  quantityMultiplier: 100
}

export const DEFAULT_TIME_BEFORE_HINT: TimeBeforeHint = {
  maxParticipants: DEFAULT_HINT_MAX_PARTICIPANTS,
  hoursBefore: DEFAULT_HINT_HOURS_BEFORE
}

export const DEFAULT_TIME_BEFORE_AFTER_HINT: TimeBeforeAfterHint = {
  maxParticipants: DEFAULT_HINT_MAX_PARTICIPANTS,
  hoursBefore: DEFAULT_HINT_HOURS_BEFORE,
  hoursAfter: DEFAULT_HINT_HOURS_AFTER
}

/**
 * @returns the quantity hint, the default and the toggle value
 */
export const parseQuantityHint = <T extends APIWithQuantityHint>({
  quantityHint
}: T): WithQuantityHint => {
  quantityHint.sort(sortAscByMaxParticipants)
  return {
    showQuantityHint: !!quantityHint.length,
    defaultQuantityHint: quantityHint.pop() ?? DEFAULT_QUANTITY_HINT,
    quantityHint,
    guestsNumberMock: 0
  }
}

/**
 * @returns the time before hint, the default and the toggle value
 */
export const parseTimeBeforeHint = <T extends APIWithTimeBeforeHint>({
  timeBeforeHint
}: T): WithTimeBeforeHint => {
  timeBeforeHint.sort(sortAscByMaxParticipants)
  return {
    showTimeBeforeHint: !!timeBeforeHint.length,
    defaultTimeBeforeHint: timeBeforeHint.pop() ?? DEFAULT_TIME_BEFORE_HINT,
    timeBeforeHint
  }
}

/**
 * @returns the time before after hint, the default and the toggle value
 */
export const parseTimeBeforeAfterHint = <T extends APIWithTimeBeforeAfterHint>({
  timeBeforeAfterHint
}: T): WithTimeBeforeAfterHint => {
  timeBeforeAfterHint.sort(sortAscByMaxParticipants)
  return {
    showTimeBeforeAfterHint: !!timeBeforeAfterHint.length,
    defaultTimeBeforeAfterHint:
      timeBeforeAfterHint.pop() ?? DEFAULT_TIME_BEFORE_AFTER_HINT,
    timeBeforeAfterHint
  }
}
