import { LocationSelection } from 'services/api/entities/events/types/internal/selections'
import { TimeLabel } from '@kampaay/common'
import { yupIgnore, YupSchema, yupString } from 'services/yup'
import { LocationArrangementName } from 'types/form'
import { mainProductWithUpsellingSelectionVS } from 'services/validations/operations/events/selections/common/mainProductWithUpselling'

export const locationSelectionVS: YupSchema<LocationSelection> =
  mainProductWithUpsellingSelectionVS.shape({
    arrangementCapacity: yupIgnore(),
    arrangementName: yupString<LocationArrangementName>().optional(),
    bookedFrom: yupString<TimeLabel>().required(),
    bookedTo: yupString<TimeLabel>().required(),
    quantity: yupIgnore()
  })
