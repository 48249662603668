/**
 * Max upload file size in bytes
 */
export const MAX_FILE_SIZE_BYTES = 30000000

/**
 * Allowed upload file size in MB
 */
export const MAX_FILE_SIZE_MB = MAX_FILE_SIZE_BYTES / 1000000

/**
 * Allowed upload file types
 */
export const ALLOWED_FILE_TYPE = 'application/pdf'
