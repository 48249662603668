import { APICfg } from 'services/api/api'
import { physicalParse, physicalWrite } from '@kampaay/common'
import {
  uploadOrKeepImg,
  uploadOrKeepMultipleImgs
} from 'services/utils/images'

const apiCfg: APICfg = {
  endpoint: 'physicalexperiences',
  parse: physicalParse,
  write: physicalWrite(uploadOrKeepImg, uploadOrKeepMultipleImgs)
}

export default apiCfg
