import React from 'react'
import LocalizedWithChoicesInput from 'components/Inputs/Localized/LocalizedWithChoicesInput'
import KAutocompleteInput, {
  KAutocompleteInputProps
} from 'components/Inputs/KInputs/KAutocompleteInput'

export type LocalizedAutocompleteInputProps = KAutocompleteInputProps

const LocalizedAutocompleteInput: React.FC<LocalizedAutocompleteInputProps> = (
  props
) => (
  <LocalizedWithChoicesInput {...props}>
    <KAutocompleteInput />
  </LocalizedWithChoicesInput>
)

export default LocalizedAutocompleteInput
