import KTextInput from 'components/Inputs/KInputs/KTextInput/KTextInput'
import FlexBox from 'components/UI/layout/FlexBox'
import { useForm } from 'hooks/useForm'
import React from 'react'
import { Customer } from '@kampaay/common'
import { RAFormFC } from 'types/common'

export type PecSdiInputsProps = {
  disabled?: boolean
}

const PecSdiInputs: RAFormFC<PecSdiInputsProps> = ({ source, disabled }) => {
  const { useGetValue } = useForm<Customer>(source)
  const status = useGetValue('status')
  const disableAllInputs = status === 'inactive' || disabled

  return (
    <FlexBox flexDirection="column">
      <KTextInput
        source="pec"
        className="w-64"
        label="form.fields.pec"
        disabled={disableAllInputs}
      />
      <KTextInput
        source="sdi"
        className="w-64"
        label="form.fields.sdi"
        disabled={disableAllInputs}
      />
    </FlexBox>
  )
}

export default PecSdiInputs
