import { useKInput } from 'hooks/useKInput/useKInput'
import React, { Children, useCallback } from 'react'
import { FieldPath, useWatch, PathValue } from 'react-hook-form'

/**
 *
 * @example
 *
 * type RecordType = { name: string; description: string; quantity: number }
 *
 *  const ExampleComponent: RAFormFC = ({ source }) => {
 *  // RecordType is the object of the current scope
 *  const { getSource, useGetValue, useSetValue } = useForm<RecordType>(source)
 *
 *  // with useSetValue passing like source name we retrieve the setName fn
 *  const setName = useSetValue('name')
 *
 *  // this performs a watch on the name of the current scope
 *  const name = useGetValue('name')
 *
 *  // by invoking setName we are setting to name 'hello' here below
 *  if (name !== 'hello') setName('hello')
 *
 *  return (
 *   <>
 *     <KTextInput source={getSource('name')} />
 *     <KTextInput source={getSource('description')} />
 *   </>
 *  )
 * }
 *
 * @generic TObject - is the object of the current scope on with this hooks will work
 * @param rootSource - the source passed down from the parent on undefined if on the form level
 * @returns - the object containing the get source fn and two hooks that can be used to get or set values
 * from the current scope
 */
export const useForm = <TObject extends object>(
  rootSource: string | undefined
) => ({
  getSource: useGetSource<TObject>(rootSource),
  useGetValue: useInputGetValue<TObject>(rootSource),
  useSetValue: useInputSetValue<TObject>(rootSource),
  injectChildContext: injectChildContext(rootSource)
})

/**
 * This function should be used to calculate the source of a field inside a form
 * or the source of the scoped form data inside of an array input if necessary to watch on
 * @param sourceRootWithIndex the source passed down from the array input to the FC or
 * undefined if is used outside of an array input
 * @returns the getSource function that taking a field source as argument returns the full source
 * completely typed
 */
export const useGetSource =
  <TScoped extends object>(sourceRootWithIndex: string | undefined) =>
  <TSource extends FieldPath<TScoped>>(fieldSource: TSource): TSource => {
    // ES. useGetSource(undefined)('name') => 'name'
    if (!sourceRootWithIndex) {
      return fieldSource as TSource
    }
    // ES. useGetSource('pricing.0')('name') => 'pricing.0.name'
    else {
      return `${sourceRootWithIndex}.${fieldSource}` as TSource
    }
  }

/**
 * @param parentSource the source passed down from the array input to the FC or
 * undefined if we are at the form level
 * @returns return a useGetValue hook that taking a field source as argument returns the value
 * on witch performs a watch and triggers the re-rendering of the component on change of it
 */
const useInputGetValue = <TObject extends object>(
  parentSource: string | undefined
) => {
  const useGetValue = <KPath extends FieldPath<TObject>>(
    source: KPath,
    options?: { exact?: boolean }
  ) => {
    const getSource = useGetSource<TObject>(parentSource)
    return useWatch<TObject, KPath>({
      name: getSource(source),
      exact: options?.exact ?? true
    })
  }

  return useGetValue
}

/**
 * @param parentSource the source passed down from the array input to the FC or undefined if at form level
 * @returns the useInputSetValue hook that allow you passing a source and value to change
 * the value of the field in the current scope
 */
export const useInputSetValue = <TObject extends object>(
  parentSource: string | undefined
) => {
  const getSource = useGetSource<TObject>(parentSource)
  const useSetValue = <TPath extends FieldPath<TObject>>(source: TPath) => {
    const {
      field: { onChange }
    } = useKInput({ source: getSource(source) })
    return useCallback(
      (v: PathValue<TObject, TPath> | undefined) => onChange(v),
      [onChange]
    )
  }

  return useSetValue
}

/**
 * This function injects the source of the context to each child
 * If the child is a simple input with a source like <KTextInput source="name" />
 * the source injected will use automatically useForm and getSource injecting the final source
 * else if the child is a component then we inject only context source in order to allow to the
 * component to invoke itself useForm and getSource inside on each one of its children
 *
 * @example
 * const Test1: RAFormFC = ({ source, children }) => {
 *   return (
 *     <div>{injectChildContext(source, children)}</div>
 *  )
 * }
 *
 * const Test2: RAFormFC = ({ source, children }) => {
 *   return (
 *     <div>{injectChildContext(source, children)}</div>
 *  )
 * }
 *
 * const UseTest: RAFormFC = ({ source }) => {
 *   const { getSource } = useForm(source)
 *   return (
 *     <Test1 source={getSource('pricing')}>
 *       // in this case if the context is the one of th array input
 *       // we will have here a source like arraySource.0.name
 *       <KTextInput source="name" />
 *       // here we simply inject the context source because in this case
 *       // will be our component that will invoke useForm and getSource
 *       <Test2 />
 *     </test1>
 *   )
 * }
 *
 * @param source the source of the current scope
 * @param children the children to clone
 * @returns the children with the correct context injected in the scope
 */
const injectChildContext =
  (source: string | undefined) =>
  (children: JSX.Element | JSX.Element[] | undefined) => {
    const useInjectChildContext = ():
      | JSX.Element
      | JSX.Element[]
      | undefined => {
      const { getSource } = useForm<any>(source)

      return Children.map(children, (child) => {
        if (!child) return undefined
        else if (child.props.source) {
          return React.cloneElement(child, {
            source: getSource(child.props.source)
          })
        } else {
          return React.cloneElement(child, {
            source
          })
        }
      })
    }

    return useInjectChildContext()
  }
