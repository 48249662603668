import { CreateParams } from 'react-admin'
import { MappedEnrichedEvent } from 'services/api/entities/events/types/internal/event-models'
import { mapEventToAPI } from 'services/api/entities/events/eventsMultiplexMap'
import { eventWrite } from 'services/api/entities/events/write'
import kampaayDataProvider from 'services/api/dataProvider'
import { callPost, getPath } from 'services/api/dataProvider/common'
import apiCfg from 'services/api/entities/events/index'

export const eventCreate = async (
  _resource: string,
  params: CreateParams<MappedEnrichedEvent>
) => {
  const { surveyResponses: _surveyResponses, ...event } = mapEventToAPI(
    params.data as MappedEnrichedEvent
  )
  const mappedEvent = await eventWrite(event, kampaayDataProvider)
  return callPost(apiCfg, getPath(apiCfg, params), 'POST', mappedEvent)
}
