import React, { PropsWithChildren } from 'react'
import { ObjectSchema } from 'yup'
import { KFormContext } from 'contexts/kform-context/KFormContext'
import { getRequiredFieldSources } from 'services/yup/utils'

type Props = PropsWithChildren<{
  schema?: ObjectSchema<any>
}>

const KFormContextProvider: React.FC<Props> = ({ schema, children }) => {
  const mandatoryFieldsSources = schema
    ? getRequiredFieldSources(schema.describe())
    : []

  return (
    <KFormContext.Provider value={{ mandatoryFieldsSources }}>
      {children}
    </KFormContext.Provider>
  )
}

export default KFormContextProvider
