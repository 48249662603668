import { APICfg } from 'services/api/api'
import {
  APIRule,
  APIRuleProduct,
  getId,
  Rule,
  RuleToAPI
} from '@kampaay/common'

type WithProducts = { products: APIRuleProduct[] }

/**
 * @param obj with products records array
 * @returns the same object with products number []
 */
const mapIdsFromProducts = <T extends WithProducts>(obj: T) => ({
  ...obj,
  products: obj.products.map(getId)
})

export const ruleParse = (res: APIRule): Rule => ({
  ...res,
  if: res.if.map(mapIdsFromProducts),
  then: res.then.map(mapIdsFromProducts)
})

const ruleWrite = async (req: Rule): Promise<RuleToAPI> => req

const apiCfg: APICfg = {
  endpoint: 'rule',
  parse: ruleParse,
  write: ruleWrite
}

export default apiCfg
