import type { ProposalItemGroup } from '../../../../api'
import { KStorage } from '../../storage'
import type { StorageProposalItem } from '../proposalItem'

export type StorageProposalItemGroup = Omit<
  ProposalItemGroup,
  'items' | 'id'
> & {
  items: StorageProposalItem[]
  proposalId?: number
}

export const proposalItemGroupStorage = new KStorage<StorageProposalItemGroup>(
  '__proposalItemGroup',
  localStorage
)
