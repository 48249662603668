import sumBy from 'lodash/sumBy'
import { getMargin, getMarginPerc } from '../../prices'
import { getProposalItemPricing } from '../proposal-item/getProposalItemPricing'
import type { Proposal } from '../../../api'
import { getProposalLogisticsPricing } from './getProposalLogisticsPricing'

export type ProposalTotalPricing = {
  /**
   * Total price of all items in the proposal
   */
  totalPrice: number
  /**
   * Final price of the proposal, after applying all discounts
   * to the total price
   */
  finalPrice: number
  /**
   * Total cost of all items in the proposal
   */
  totalCost: number
  /**
   * Margin of the proposal, calculated as the difference between
   * the total price and the total cost
   */
  preDiscountMarginality: number
  /**
   * Margin of the proposal, calculated as the difference between
   * the final price and the total cost
   */
  postDiscountMarginality: number
  /**
   * Pre-discount margin percentage, calculated from the total price
   */
  preDiscountMarginalityPercentage: number
  /**
   * Post-discount margin percentage, calculated from the final price
   */
  postDiscountMarginalityPercentage: number
  /**
   * Total commission of the proposal, calculated as the sum of all
   * commission amounts of all items in the proposal
   */
  totalCommission: number
}

export const getProposalTotalPricing = (
  proposal: Pick<
    Proposal,
    'itemGroups' | 'dedicatedDiscount' | 'contractDiscount'
  >
): ProposalTotalPricing => {
  const itemGroups = proposal.itemGroups ?? []
  const items = itemGroups.flatMap((group) => group.items)

  const itemPricings = items
    .filter((i) => i.isPriceIncluded)
    .map(getProposalItemPricing)

  const logisticsPricing = getProposalLogisticsPricing(itemGroups)

  const totalPrice =
    sumBy(itemPricings, (item) => item.totalPrice ?? 0) + logisticsPricing.price
  const totalCost =
    sumBy(itemPricings, (item) => item.totalCost ?? 0) + logisticsPricing.cost
  const totalCommission = sumBy(
    itemPricings,
    (item) => item.commissionAmount ?? 0
  )

  const dedicatedDiscount = proposal?.dedicatedDiscount ?? 0
  const contractDiscount = proposal?.contractDiscount ?? 0

  const finalPrice = totalPrice - dedicatedDiscount - contractDiscount
  const preDiscountMarginality = getMargin(totalPrice, totalCost)
  const preDiscountMarginalityPercentage = getMarginPerc(
    preDiscountMarginality,
    totalPrice
  )

  const postDiscountMarginality = getMargin(finalPrice, totalCost)
  const postDiscountMarginalityPercentage = getMarginPerc(
    postDiscountMarginality,
    finalPrice
  )

  return {
    totalPrice,
    totalCost,
    totalCommission,
    preDiscountMarginality,
    postDiscountMarginality,
    preDiscountMarginalityPercentage,
    postDiscountMarginalityPercentage,
    finalPrice
  }
}
