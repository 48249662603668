import React, {
  type CSSProperties,
  type FC,
  type PropsWithChildren,
  useMemo
} from 'react'
import {
  calendarEventDefaultColor,
  calendarPlaceholderDefaultColor
} from 'services/utils/organization/calendar'
import type { EventPlaceholderStatus } from 'services/api/entities/event-placeholder/types/internal/event-placeholder-models'
import type { EventStatus } from 'types/form'
import type { ConciergeStatus } from '@kampaay/common'

export type CalendarEventVariant =
  | EventPlaceholderStatus
  | EventStatus
  | ConciergeStatus

export type OrganizationCalendarItemBaseProps = {
  variant: CalendarEventVariant
  className?: string
  color?: string
  isPlaceholder?: boolean
}

const OrganizationCalendarItemBase: FC<
  PropsWithChildren<OrganizationCalendarItemBaseProps>
> = ({ variant, children, className, color, isPlaceholder }) => {
  const itemBackground = useMemo((): string => {
    if (isPlaceholder) {
      return color || calendarPlaceholderDefaultColor
    }

    return calendarEventDefaultColor
  }, [isPlaceholder, color])

  const itemStripedBackground = useMemo((): string => {
    if (isPlaceholder) {
      return 'repeating-linear-gradient(-45deg, #B1B3BB, #B1B3BB 10px, #CFD1DB 10px, #CFD1DB 20px)'
    }

    return 'repeating-linear-gradient(-45deg, #FFDAD3, #FFDAD3 10px, #FFEDE9 10px, #FFEDE9 20px)'
  }, [isPlaceholder, color])

  const itemBorder = useMemo(
    (): string => `2px solid ${itemBackground}`,
    [itemBackground]
  )

  // Here we are not using our beloved Tailwind as BE sends us the color for each defined
  // category, which is chosen by users in SF. It's cleaner and easier fo us to use the
  // style property instead of classes where possible
  const variants: Partial<Record<CalendarEventVariant, CSSProperties>> =
    useMemo(
      () => ({
        noEvents: {
          color: calendarPlaceholderDefaultColor,
          background: 'white',
          border: itemBorder
        },
        pending: {
          color: calendarPlaceholderDefaultColor,
          background: itemStripedBackground,
          border: itemBorder
        },
        confirmed: {
          color: 'white',
          background: itemBackground,
          border: itemBorder
        },
        closed: {
          color: 'white',
          background: itemBackground,
          border: itemBorder
        },
        lost: {
          color: calendarPlaceholderDefaultColor,
          background: 'white',
          border: itemBorder
        }
      }),
      [itemBackground, itemStripedBackground, itemBorder]
    )

  return (
    <div
      className={className || 'h-full rounded px-1 text-black'}
      style={variants[variant] || variants.pending}
    >
      {children}
    </div>
  )
}

export default OrganizationCalendarItemBase
