import SUPPORTED_COUNTRIES, {
  type CountryInfo,
  DEFAULT_COUNTRY_ID,
  ENGLISH_LANGUAGE,
  type I18nBinding
} from './countries-cfg'
import { getCountryLanguages } from './utils'

export const getCountryById = (id: number) => (): CountryInfo =>
  SUPPORTED_COUNTRIES.find((g) => g.id === id)!

/**
 * The function used to load the country from the local storage
 * If not localstorage is found defaults to Italy
 */
export const loadCountry = (): number => {
  const country = parseInt(localStorage.getItem('country')!)
  if (!country) return getCountryById(DEFAULT_COUNTRY_ID)().id
  const isSupportedCountry = SUPPORTED_COUNTRIES?.some(
    ({ id }) => id === country
  )
  // for kampaayers not logged or without an assigned country in we default to the milan country for language picking
  return isSupportedCountry ? country : getCountryById(DEFAULT_COUNTRY_ID)().id
}
/**
 * The geo exposed as variable to avoid retrieving it from localStorage
 * This should be never exported but only used with getGeo
 */
let _countryCode = loadCountry()

/**
 * Used to get the value of geo
 */
export const getCountry = () => _countryCode
/**
 * Sets to the localstorage the value of the currentCountry
 * @param country
 */
export const setCountry = (country: number) => {
  localStorage.setItem('country', JSON.stringify(country))
  _countryCode = country
}

/**
 * Taking the geo id returns the country config attached
 */
export const getCurrentCountry = (): CountryInfo =>
  getCountryById(getCountry())()

export const getDefaultLang = () => ENGLISH_LANGUAGE.localeCode

/**
 * Returns the list of languages supported by the current country
 */
export const getCurrentCatalogLanguages = (): I18nBinding[] =>
  getCountryLanguages(getCurrentCountry())
