import type {
  APIGuestManagementEmail,
  APIGuestManagement,
  APIGuestManagementStandard
} from './types'
import type {
  GuestManagementEmail,
  GuestManagementProfessional,
  GuestManagement,
  GuestManagementStandard
} from './types/internal/guest-management-models'
import { parseFileInfo } from '../fileinfo'

const parseGuestManagementEmail = (
  apiModel: APIGuestManagementEmail
): GuestManagementEmail => ({
  subject: apiModel.subject,
  body: apiModel.body,
  template: parseFileInfo(apiModel.template),
  graphics: !!apiModel.graphics?.length
    ? apiModel.graphics.map((doc) => parseFileInfo(doc)!)
    : undefined,
  dispatchDate: apiModel.dispatchDate
    ? new Date(apiModel.dispatchDate)
    : undefined
})

const parseStandardGuestManagement = (
  apiModel?: APIGuestManagementStandard
): GuestManagementStandard | undefined => {
  if (!apiModel) return undefined

  return {
    eventName: apiModel.eventName,
    eventDescription: apiModel.eventDescription,
    eventType: apiModel.eventType,
    companyLogo: parseFileInfo(apiModel.companyLogo)!,
    graphics: apiModel.graphics?.map((doc) => parseFileInfo(doc)!),
    availableLanguages: apiModel.availableLanguages,
    formFields: apiModel.formFields,
    registrationDeadline: !!apiModel.registrationDeadline
      ? new Date(apiModel.registrationDeadline)
      : undefined,
    urlPath: apiModel.urlPath,
    confirmationEmail: parseGuestManagementEmail(apiModel.confirmationEmail)
  }
}

const parseProfessionalGuestManagement = (
  apiModel?: APIGuestManagementStandard
): GuestManagementProfessional | undefined => {
  if (!apiModel) return undefined

  return {
    guestsList: parseFileInfo(apiModel?.guestsList),
    invitationEmail: !!apiModel?.invitationEmail
      ? parseGuestManagementEmail(apiModel.invitationEmail)
      : undefined,
    reminderEmails:
      apiModel?.reminderEmails?.map(parseGuestManagementEmail) ?? []
  }
}

export const parseGuestManagement = (
  apiModel: APIGuestManagement
): GuestManagement => ({
  id: apiModel.id,
  package: apiModel.package,
  standard: parseStandardGuestManagement(apiModel.standard),
  professional: parseProfessionalGuestManagement(apiModel.standard),
  customBrief: apiModel.customBrief,
  eventId: apiModel.eventId,
  conciergeId: apiModel.conciergeId,
  winstonUrlPath: apiModel.winstonUrlPath
})
