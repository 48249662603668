import React from 'react'
import { getSelectionChoices } from 'resources/operations/events/utils'
import { UpsellingSelection } from 'services/api/entities/events/types/internal/selections'
import { distinctById } from 'services/utils'
import EventBaseSelectionArrayInput, {
  EventBaseSelectionArrayInputProps
} from 'components/FormComponents/EventBaseSelectionArrayInput'
import { ProductWithUpselling } from '@kampaay/common'

type EventUpsellingSelectionInputProps = Omit<
  EventBaseSelectionArrayInputProps,
  'label'
> & {
  selectedProduct?: ProductWithUpselling
  upsellingSelection?: UpsellingSelection[]
}

const EventUpsellingSelectionInput: React.FC<
  EventUpsellingSelectionInputProps
> = ({ selectedProduct, upsellingSelection = [], ...rest }) => {
  const choices = distinctById(
    upsellingSelection.flatMap((u) =>
      getSelectionChoices(u, selectedProduct?.upsellingItemsFull)
    )
  )
  return (
    <EventBaseSelectionArrayInput
      {...rest}
      arrayInputLabel="form.fields.upsellingItems"
      label={'form.fields.upsellingItems'}
      choices={choices}
    />
  )
}

export default EventUpsellingSelectionInput
