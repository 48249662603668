import { findMultipleFacets, findSingleFacet } from '../../../../utils'
import { parseGeocodedAddress } from '../../geo-coded-addresses'
import {
  type APIPhysicalOptionedTailoring,
  optionedProductParse,
  type PhysicalOptionedTailoring,
  upsellingParse
} from '../common'
import { parseFacet } from '../facets'
import type {
  APIExperience,
  APIPhysicalExperience,
  Experience,
  PhysicalExperience
} from './types'

/**
 * @deprecated Kept for backwards compatibility to use for the obsolete virtual experience models. This level of abstraction is not needed anymore for the remaining products: food, physicals and professionals.
 */
export const experienceParse = (res: APIExperience): Experience => {
  // objects full of all Experiences item information. Used inside event edit and create

  const upsellingItemsFull = (res.upselling ?? []).map(upsellingParse)
  const facets = res.facets.map(parseFacet)
  const tagFacets = findMultipleFacets(facets, 'tag')
  const skillFacet = findSingleFacet(facets, 'productFilter', 'Skill')
  const maxQuantity = Math.max(...res.pricing.map((e) => e.maxQuantity))

  return {
    ...res,
    ...optionedProductParse(res),
    upsellingItemsFull,
    tagFacets,
    maxQuantity,
    skillFacet
  }
}

const parsePhysicalOptionedTailoring = (
  data: APIPhysicalOptionedTailoring
): PhysicalOptionedTailoring => {
  return {
    ...data,
    options: data.options.map((option) => ({
      ...option,
      hasCustomPrice: !!option.pricing.length
    })),
    hasCustomPrice: !!data.pricing.length
  }
}

export const physicalParse = (
  res: APIPhysicalExperience
): PhysicalExperience => {
  const { minDuration, maxDuration, tailorings: apiTailorings } = res
  const facets = res.facets.map(parseFacet)
  const settingFacets = findMultipleFacets(facets, 'productFilter', 'Setting')
  const languageFacets = findMultipleFacets(facets, 'productFilter', 'Language')

  const tailorings = apiTailorings.map((t) => parsePhysicalOptionedTailoring(t))

  const teambuildingCategoryFacet = findSingleFacet(
    facets,
    'productFilter',
    'TeamBuildingCategory'
  )

  return {
    ...experienceParse(res),
    settingFacets,
    languageFacets,
    teambuildingCategoryFacet,
    minDuration,
    maxDuration,
    tailorings,
    geoCodedAddress: !!res.geoCodedAddress
      ? parseGeocodedAddress(res.geoCodedAddress)
      : undefined
  }
}
