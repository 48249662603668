import { Area } from '@kampaay/common'
import {
  UseValidationSchema,
  yupIgnore,
  yupObject,
  yupString
} from 'services/yup'

const useAreaVS: UseValidationSchema<Area> = () =>
  yupObject().shape({
    id: yupIgnore(),
    name: yupString()
  })

export default useAreaVS
