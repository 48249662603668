import React from 'react'
import HowItWorksAccordion from 'components/UI/Accordions/HowItWorksAccordion'
import DefaultTimeBeforeAfterHint from 'components/FormComponents/Hints/TimeBeforeAfterHint/DefaultTimeBeforeAfterHint'
import KAccordion from 'components/UI/Accordions/KAccordion/KAccordion'
import ContentToggler from 'components/UI/ContentToggler'
import BeforeAfterHintArrayInput from 'components/FormComponents/Hints/TimeBeforeAfterHint/BeforeAfterHintArrayInput'

type Props = {
  accordionText: string[]
}

const BeforeAfterHintFormContent: React.FC<Props> = ({
  accordionText,
  ...props
}) => {
  return (
    <KAccordion
      accordionTitle="Time"
      errorsDependencies={['defaultTimeBeforeAfterHint']}
    >
      <ContentToggler
        {...props}
        source="showTimeBeforeAfterHint"
        label="form.fields.showTimeHint"
      >
        <BeforeAfterHintArrayInput />
        <DefaultTimeBeforeAfterHint />
      </ContentToggler>
      <HowItWorksAccordion accordionText={accordionText} />
    </KAccordion>
  )
}

export default BeforeAfterHintFormContent
