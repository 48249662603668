import { Pagination } from 'ra-ui-materialui'
import React from 'react'
import { PaginationProps } from 'react-admin'

const PER_PAGE_OPTIONS = [20, 40]

const KPagination: React.FC<PaginationProps> = ({
  rowsPerPageOptions = PER_PAGE_OPTIONS,
  ...props
}) => {
  return <Pagination rowsPerPageOptions={rowsPerPageOptions} {...props} />
}

export default KPagination
