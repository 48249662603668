import { isEveryNameOfKeyUnique } from 'services/utils'
import {
  KTestConfig,
  KTestFunction,
  yupArray,
  yupNumber,
  yupObject,
  YupSchema,
  yupString
} from 'services/yup'
import { isIntersecting } from 'services/utils/array'
import { include } from 'resources/operations/events/utils'
import * as yup from 'yup'
import {
  WithId,
  WithIdAndName,
  GeoLocation,
  GeoCodedAddress,
  GeoPoint,
  getById
} from '@kampaay/common'

// !Tests Fns
const validateUniqueName: KTestFunction<{ name?: string }[]> = (objects) =>
  isEveryNameOfKeyUnique(
    objects?.filter((e) => !!e.name),
    'name'
  )

const validateUniqueId: KTestFunction<{ id?: number }[]> = (objects) =>
  isEveryNameOfKeyUnique(objects, 'id')

// !CFGs
export const isNameUniqueCFG: KTestConfig<{ name?: string }[]> = {
  test: validateUniqueName,
  message: 'form.validations.uniqueName'
}

export const isIdUniqueCFG: KTestConfig<{ id?: number }[]> = {
  test: validateUniqueId,
  message: 'form.validations.uniqueId'
}

/**
 * check whether a collection of items
 * has consistent logistics
 * with target logistics
 *
 * @param selectedItemsIds - items with logistics
 * @param targetLogisticIds - target logistics to be tested with
 * @param selectedItemsFull - full data required to get item logistics
 */
export const hasConsistentLogistics = <
  TFullData extends WithId & { logistics: number[] }
>(
  selectedItemsIds: number[],
  targetLogisticIds: number[],
  selectedItemsFull: TFullData[]
) => {
  const extractedData = include(selectedItemsIds, selectedItemsFull)
  const selectedItemsLogisticsIds = extractedData.map(
    (item) => item?.logistics ?? []
  )

  return isIntersecting(targetLogisticIds, ...selectedItemsLogisticsIds)
}

export type WithIdAndGeoPresets = {
  id: number
  geoAreaPresets: number[]
}

export const extractGeoPresetsFromCatalogList = (
  itemIds: number[],
  catalogList: WithIdAndGeoPresets[]
) => {
  const extractedData = itemIds.map((id) => getById(catalogList, id))
  return extractedData.map((item) => item?.geoAreaPresets ?? [])
}

type GetKeysWithGivenValueType<TObject, KTargetValue> = {
  [k in keyof TObject]: TObject[k] extends KTargetValue ? k : never
}[keyof TObject]
type GetKeysWithNumArrValueType<TObject> = GetKeysWithGivenValueType<
  TObject,
  number[]
>

/**
 * Builds a yup test function for validating geoarea presets.
 *
 * @param relatedProducts - An array of objects containing the list of presets and the corresponding key in the parent object.
 * @returns A yup test function.
 */
export const buildGeoareaPresetTest =
  <TContext extends WithIdAndName>(
    ...relatedProducts: {
      list: WithIdAndGeoPresets[]
      key: GetKeysWithNumArrValueType<TContext>
    }[]
  ): KTestFunction<number[], TContext> =>
  (geoareaPresets, { parent }) =>
    relatedProducts.every(({ key, list }) => {
      // Extract the presets from the catalog list based on the parent's key
      const presets = extractGeoPresetsFromCatalogList(
        (parent[key] ?? []) as number[],
        list
      )

      // Check if all geoarea presets are present in the extracted presets
      return !!geoareaPresets?.every((presetId) =>
        isIntersecting([presetId], ...presets)
      )
    })

/**
 * Builds a yup validation schema for geoarea presets.
 *
 * @param relatedProducts - An array of objects containing the list of presets and the corresponding key in the parent object.
 * @returns An object with 'test' and 'message' properties for yup validation.
 */
export const buildGeoareaPresetValidation = <TContext extends WithIdAndName>(
  ...relatedProducts: {
    list: WithIdAndGeoPresets[]
    key: GetKeysWithNumArrValueType<TContext>
  }[]
) => {
  const keys = relatedProducts.map(({ key }) => `"${key as string}"`).join(', ')

  return {
    test: buildGeoareaPresetTest<TContext>(...relatedProducts),
    message: `Selected geoarea preset(s) should also be present in: ${keys}`
  }
}

export const geoLocationVS: YupSchema<GeoLocation> = yupObject().shape({
  lat: yupNumber(),
  lng: yupNumber()
})

export const getGeoCodedAddressVS = (
  insertedAddressRequired: boolean = false
): YupSchema<GeoCodedAddress> => {
  const userInsertedAddressSchema = yupString()
    .oneOf(
      [yup.ref('geoPoint.formattedAddress')],
      'Please insert a valid address'
    )
    // This is a workaround to make the error message appear under the text input
    // instead of in a random error snackbar.
    .when('geoPoint.addressTypes', {
      is: (addressTypes: GeoPoint['addressTypes']) => {
        return addressTypes?.length
          ? !addressTypes.includes('street_address') &&
              !addressTypes.includes('premise') &&
              !addressTypes.includes('subpremise') &&
              !addressTypes.includes('point_of_interest') &&
              !addressTypes.includes('establishment')
          : false
        // "Route" and "intersection" may contain a street number
        // as well, but it's not very likely. We exclude them for now,
        // but we may want to include them in the future.
      },
      then: yupString().test(
        'invalidAddress',
        'Please include a street number to complete the address',
        () => false
      )
    })
  return yupObject().shape({
    geoPoint: yupObject()
      .shape({
        formattedAddress: yupString(),
        location: geoLocationVS.optional(),
        addressTypes: yupArray(yupString()).optional()
      })
      .optional(),
    userInsertedAddress: insertedAddressRequired
      ? userInsertedAddressSchema.required(
          'form.validations.proposal.requiredAddress'
        )
      : userInsertedAddressSchema
  })
}
