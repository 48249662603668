import { ProductSelectionBase } from 'services/api/entities/events/types/internal/selections'
import { yupIgnore, yupNumber, yupObject, YupSchema } from 'services/yup'

export const productSelectionBaseVS: YupSchema<ProductSelectionBase> =
  yupObject().shape({
    id: yupNumber().required(),
    name: yupIgnore(),
    description: yupIgnore(),
    quantity: yupNumber().required().min(1)
  })
