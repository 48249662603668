import React from 'react'
import SectionWrapper from 'components/UI/SectionWrapper'
import ProfessionalSectionFormContent from 'resources/catalog/professionals/sections/ProfessionalSectionFormContent'
import { KSimpleForm } from 'components/Forms/KForms/KSimpleForm'
import KCreate from 'components/Forms/KCreate/KCreate'

const ProfessionalSectionCreate: React.FC = () => (
  <SectionWrapper>
    <KCreate>
      <KSimpleForm>
        <ProfessionalSectionFormContent />
      </KSimpleForm>
    </KCreate>
  </SectionWrapper>
)

export default ProfessionalSectionCreate
