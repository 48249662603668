import React from 'react'
import { SimpleFormIterator } from 'react-admin'
import KAccordion from 'components/UI/Accordions/KAccordion/KAccordion'
import {
  DEFAULT_PRICE_RANGE_MAX_QUANTITY,
  getStandardPricingStartup
} from 'services/cfg/form'
import KNumberInput from 'components/Inputs/KInputs/KNumberInput/KNumberInput'
import VariablePricesInput from 'components/FormComponents/pricing/standard/VariablePricesInput'
import FixedPricesInput from 'components/FormComponents/pricing/standard/FixedPricesInput'
import { WithDataTestId } from 'test/utils'
import KArrayInput from 'components/Inputs/KInputs/KArrayInput'

type Props = WithDataTestId & {
  hideFixedPrice?: boolean
  hideVariablePrice?: boolean
  source?: string
  label?: string
  isSinglePriceRange?: boolean
}

const StandardPricingInput: React.FC<Props> = ({
  hideFixedPrice = false,
  hideVariablePrice = false,
  source = 'pricing',
  label = 'form.fields.pricing',
  isSinglePriceRange = false,
  'data-testid': dataTestId = 'standard-pricing-input'
}) => {
  // this has been moved inside due to the "pass by reference"

  return (
    <KAccordion
      accordionTitle="form.headers.standardPricingAccordion"
      errorsDependencies={[source]}
      data-testid={dataTestId}
    >
      <KArrayInput
        source={source}
        label={label}
        defaultValue={getStandardPricingStartup()}
      >
        <SimpleFormIterator
          disableReordering
          disableAdd={isSinglePriceRange}
          disableRemove={isSinglePriceRange}
        >
          <KNumberInput
            source={'maxQuantity'}
            label="form.fields.maxQuantity"
            step={1}
            defaultValue={DEFAULT_PRICE_RANGE_MAX_QUANTITY}
          />
          <VariablePricesInput
            style={
              hideVariablePrice ? { visibility: 'hidden', height: '0' } : {}
            }
            data-testid="standard-pricing-input--variable-prices-input"
          />

          <FixedPricesInput
            style={hideFixedPrice ? { visibility: 'hidden', height: '0' } : {}}
            data-testid="standard-pricing-input--fixed-prices-input"
          />
        </SimpleFormIterator>
      </KArrayInput>
    </KAccordion>
  )
}

export default StandardPricingInput
