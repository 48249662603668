import { yupArray } from 'services/yup/custom-schemas/array'
import { mainProductVS } from 'services/validations/catalog/mainProduct'
import { yupObject } from 'services/yup/custom-schemas/object'
import { yupNumber } from 'services/yup/custom-schemas/number'
import { yupIgnore } from 'services/yup/helpers'
import { UseValidationSchema, YupSchema } from 'services/yup/types'
import { standardPricingArrayVS } from 'services/validations/catalog/standard-pricing/standardPricingArray'
import { yupBoolean, yupString } from 'services/yup'
import {
  DrinkVariant,
  Drink
} from 'services/api/entities/catalog/drink/types/internal/drink-models'

const drinkVariantsVS: YupSchema<DrinkVariant> = yupObject().shape({
  id: yupIgnore(),
  name: yupString().required(),
  description: yupString(),
  pricing: standardPricingArrayVS().min(1),
  image: yupIgnore()
})

export const useDrinkVS: UseValidationSchema<Drink> = () =>
  mainProductVS.shape({
    drinksPerUnit: yupNumber().required().min(1),
    minQuantity: yupNumber().required().min(1),
    variants: yupArray(drinkVariantsVS).min(1),
    hasMultipleVariants: yupBoolean().required()
  })
