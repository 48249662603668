import { ARRANGEMENT_NAMES_ARRAY } from 'services/cfg/form'
import { mapValues } from 'services/utils'
import { LocationArrangementStatus } from 'types/form'
import { APILocationArrangementMap } from 'services/api/entities/catalog/location/types/api/response-models'
import { LocationArrangementMap } from 'services/api/entities/catalog/location/types/internal/location-models'

// ============
// DEFAULTS
// ============

export const LOCATION_ARRANGEMENTS_STARTUP: LocationArrangementMap = {
  boardroom: {
    status: 'hidden',
    capacity: undefined
  },
  cabaret: {
    status: 'hidden',
    capacity: undefined
  },
  classroom: {
    status: 'hidden',
    capacity: undefined
  },
  standing: {
    status: 'hidden',
    capacity: undefined
  },
  theater: {
    status: 'hidden',
    capacity: undefined
  },
  ushape: {
    status: 'hidden',
    capacity: undefined
  }
}

// ============
// UTILS
// ============

const getLocationArrangementStatus = (
  capacity?: number
): LocationArrangementStatus => {
  if (capacity === undefined) return 'hidden'
  if (capacity === 0) return 'inactive'
  return 'active'
}

// ============
// PARSE
// ============

export const locationArrangementsParse = (
  arrangements: APILocationArrangementMap
) =>
  ARRANGEMENT_NAMES_ARRAY.reduce(
    (acc, arr) => ({
      ...acc,
      [arr]: {
        capacity: arrangements[arr],
        status: getLocationArrangementStatus(arrangements[arr])
      }
    }),
    {} as LocationArrangementMap
  )

// ============
// WRITE
// ============

export const locationArrangementsWrite = (
  arrangements: LocationArrangementMap
): APILocationArrangementMap =>
  mapValues(
    ({ capacity, status }) => (status === 'hidden' ? undefined : capacity),
    arrangements
  )
