import drinksections from 'services/api/entities/catalog/sections/drinkSections'
import facets from 'services/api/entities/catalog/facets'
import foods from 'services/api/entities/catalog/food'
import drinks from 'services/api/entities/catalog/drink'
import locations from 'services/api/entities/catalog/location'
import foodsupselling from 'services/api/entities/catalog/foodUpselling'
import foodsections from 'services/api/entities/catalog/sections/foodSections'
import kampaayers from 'services/api/entities/crm/kampaayer'
import musics from 'services/api/entities/catalog/baseProduct/musics'
import musicsections from 'services/api/entities/catalog/sections/musicSections'
import events from 'services/api/entities/events'
import concierge from 'services/api/entities/concierge'
import rents from 'services/api/entities/catalog/baseProduct/rents'
import rentsections from 'services/api/entities/catalog/sections/rentSections'
import suppliers from 'services/api/entities/crm/supplier'
import virtuals from 'services/api/entities/catalog/virtual'
import virtualexperiencessections from 'services/api/entities/catalog/sections/virtualSections'
import virtualupselling from 'services/api/entities/catalog/upselling/virtualupselling'
import physicals from 'services/api/entities/catalog/physical'
import physicalsections from 'services/api/entities/catalog/sections/physicalSections'
import physicalupselling from 'services/api/entities/catalog/upselling/physicalUpselling'
import logistics from 'services/api/entities/catalog/logistic'
import geoareapresets from 'services/api/entities/geoAreaPresets'
import servicesconfig from 'services/api/entities/services-config'
import professionalsections from 'services/api/entities/catalog/sections/professionalSections'
import professionals from 'services/api/entities/catalog/professional'
import locationupselling from 'services/api/entities/catalog/upselling/locationUpselling'
import locationfeatures from 'services/api/entities/catalog/locationFeatures'
import locationsections from 'services/api/entities/catalog/sections/locationSections'
import eventbrief from 'services/api/entities/eventBrief'
import eventsdraft from 'services/api/entities/events-draft'
import { APICfg } from 'services/api/api'
import organization from 'services/api/entities/crm/organizations'
import area from 'services/api/entities/area'
import rules from 'services/api/entities/catalog/rules'
import proposal from 'services/api/entities/proposal'
import proposalHistory from 'services/api/entities/proposal-history'
import suppliertype from 'services/api/entities/supplier-types'
import organizationbudget from 'services/api/entities/crm/organizations/budget'
import organizationsubgroup from 'services/api/entities/crm/organizations/sub-group'
import organizationmembership from 'services/api/entities/crm/organizations/membership'
import emailmessage from 'services/api/entities/emailMessage'
import transactionexportforpurchaseorder from 'services/api/entities/accounting/transactionExportForPurchaseOrder'
import eventPlaceholder from 'services/api/entities/event-placeholder'
import mailingList from 'services/api/entities/mailing-list'
import eventplaceholdercategory from 'services/api/entities/event-placeholder-category'
import guestmanagement from 'services/api/entities/guestmanagement'

// ========================
// EMS TAILORINGS
// ========================
// optioned (with standard pricing)
import foodTailorings from 'services/api/entities/crm/organizations/emsTailoring/foodTailoringsIndex'
import virtualTailorings from 'services/api/entities/crm/organizations/emsTailoring/virtualTailoringsIndex'
import physicalTailorings from 'services/api/entities/crm/organizations/emsTailoring/physicalTailoringsIndex'
// by rule
import locationTailorings from 'services/api/entities/crm/organizations/emsTailoring/locationTailoringsIndex'
import professionalTailorings from 'services/api/entities/crm/organizations/emsTailoring/professionalTailoringsIndex'
// standard pricing
// with hidden and pricing
import musicTailorings from 'services/api/entities/crm/organizations/emsTailoring/musicTailoringsIndex'
import rentTailorings from 'services/api/entities/crm/organizations/emsTailoring/rentTailoringsIndex'
// only with pricing
import foodUpsellingTailorings from 'services/api/entities/crm/organizations/emsTailoring/foodUpsellingTailoringsIndex'
import locationUpsellingTailorings from 'services/api/entities/crm/organizations/emsTailoring/locationUpsellingTailoringsIndex'
import physicalUpsellingTailorings from 'services/api/entities/crm/organizations/emsTailoring/physicalUpsellingTailoringsIndex'
import virtualUpsellingTailorings from 'services/api/entities/crm/organizations/emsTailoring/virtualUpsellingTailoringsIndex'
import logisticTailorings from 'services/api/entities/crm/organizations/emsTailoring/logisticTailoringsIndex'
// with variants
import drinkTailorings from 'services/api/entities/crm/organizations/emsTailoring/drinkTailoringsIndex'
import transaction from 'services/api/entities/accounting/transaction'
import customers from 'services/api/entities/crm/customers'
import setConciergeStatus from 'services/api/entities/conciergeActions/setConciergeStatus'

export type UpsellingEntity =
  | 'foodsupselling'
  | 'locationupselling'
  | 'virtualupselling'
  | 'physicalupselling'

export type ProductWithUpsellingEntity =
  | 'foods'
  | 'virtuals'
  | 'locations'
  | 'physicals'

export type MainProductAPIEntity =
  | ProductWithUpsellingEntity
  | 'professionals'
  | 'musics'
  | 'drinks'
  | 'rents'

export type ListedProductEntity = MainProductAPIEntity

export type EventEntity = 'events' | 'eventsdraft'

export type SectionEntity =
  | 'drinksections'
  | 'foodsections'
  | 'musicsections'
  | 'rentsections'
  | 'virtualexperiencessections'
  | 'professionalsections'
  | 'locationsections'
  | 'physicalsections'

export type OrganizationTailoredServiceEntity = `organization/:id/tailoring/${
  | MainProductAPIEntity
  | UpsellingEntity
  | 'logistics'}`

export type APIEntity =
  | UpsellingEntity
  | MainProductAPIEntity
  | EventEntity
  | SectionEntity
  | OrganizationTailoredServiceEntity
  | 'customers'
  | 'facets'
  | 'kampaayers'
  | 'concierge'
  | 'suppliers'
  | 'transaction'
  | 'transactionexportforpurchaseorder'
  | 'logistics'
  | 'geoareapresets'
  | 'servicesconfig'
  | 'eventbrief'
  | 'organization'
  | 'locationfeatures'
  | 'rules'
  | 'setConciergeStatus'
  | 'proposal'
  | 'proposalHistory'
  | 'area'
  | 'suppliertype'
  | 'organizationbudget'
  | 'organizationsubgroup'
  | 'organizationmembership'
  | 'eventPlaceholder'
  | 'eventplaceholdercategory'
  | 'emailmessage'
  | 'mailingList'
  | 'guestmanagement'

export type ResourceApiMap = Record<APIEntity, APICfg>

const resourceApiMap: ResourceApiMap = {
  customers,
  drinks,
  drinksections,
  facets,
  foods,
  foodsections,
  foodsupselling,
  kampaayers,
  musics,
  musicsections,
  events,
  concierge,
  rents,
  rentsections,
  suppliers,
  transaction,
  transactionexportforpurchaseorder,
  virtuals,
  virtualexperiencessections,
  virtualupselling,
  logistics,
  geoareapresets,
  servicesconfig,
  professionalsections,
  professionals,
  eventbrief,
  locations,
  locationupselling,
  locationsections,
  physicals,
  physicalsections,
  physicalupselling,
  eventsdraft,
  organization,
  locationfeatures,
  rules,
  setConciergeStatus,
  area,
  proposal,
  proposalHistory,
  suppliertype,
  organizationbudget,
  organizationmembership,
  organizationsubgroup,
  eventPlaceholder,
  eventplaceholdercategory,
  emailmessage,
  mailingList,
  guestmanagement,
  'organization/:id/tailoring/foods': foodTailorings,
  'organization/:id/tailoring/locations': locationTailorings,
  'organization/:id/tailoring/drinks': drinkTailorings,
  'organization/:id/tailoring/virtuals': virtualTailorings,
  'organization/:id/tailoring/physicals': physicalTailorings,
  'organization/:id/tailoring/musics': musicTailorings,
  'organization/:id/tailoring/rents': rentTailorings,
  'organization/:id/tailoring/professionals': professionalTailorings,
  'organization/:id/tailoring/foodsupselling': foodUpsellingTailorings,
  'organization/:id/tailoring/virtualupselling': virtualUpsellingTailorings,
  'organization/:id/tailoring/physicalupselling': physicalUpsellingTailorings,
  'organization/:id/tailoring/locationupselling': locationUpsellingTailorings,
  'organization/:id/tailoring/logistics': logisticTailorings
}

export default resourceApiMap
