import React, { useCallback, useEffect, useState } from 'react'
import { httpClient } from 'services/api/dataProvider/common'
import { I18nLocale } from '@kampaay/common'
import I18nFormHandler from 'i18n/components/I18nFormHandler'
import { KConsole } from 'services/console'
import pick from 'lodash/pick'

const API_URL = process.env.REACT_APP_API_BASE_URL

export type PlatformCode = 'be' | 'fe' | 'bo'

type Props = {
  platformCode: PlatformCode
  requiredFiles: I18nLocale[]
  onSaved?: (i18nMap: I18nMap) => void
}

export type I18nJson = Record<string, string>
export type I18nMap = Record<string, I18nJson>
export type UpdateFileFunction = (
  bundle: I18nMap
) => (e: unknown) => Promise<void>

type I18nDelete = {
  path: string
  op: 'remove'
}

type I18nUpsert = {
  path: string
  op: 'add'
  value: string
}

type I18nUpdateRequest = Array<I18nDelete | I18nUpsert>

export const getI18nBundleOperations = (
  old: I18nJson,
  current: I18nJson
): I18nUpdateRequest | undefined => {
  const deletes = Object.keys(old)
    .filter((key) => current[key] === undefined)
    .map((value: string): I18nDelete => {
      return {
        path: value,
        op: 'remove'
      }
    })

  const upserts = Object.keys(current)
    .filter((key) => old[key] !== current[key])
    .map((item): I18nUpsert => {
      return { path: item, value: current[item], op: 'add' }
    })

  return deletes.length || upserts.length ? [...deletes, ...upserts] : undefined
}

const getBundle = (platformCode: PlatformCode, locale: I18nLocale) =>
  httpClient(`${API_URL}/i18n/${platformCode}-${locale}`, {
    method: 'GET'
  }).then((res) => res.json)

const duplicateLabels = async (
  duplicatedMap: I18nMap,
  duplicatedLabels: string[],
  platformCode: PlatformCode
) => {
  const finalPlatformCode = platformCode === 'bo' ? 'fe' : 'bo'
  const reducedLocales = ['en-IT', 'en-SE']
  const additionalLocales = ['it-IT', 'sv-SE']
  const tbdLabels = Object.fromEntries(duplicatedLabels.map((l) => [l, 'TBD']))
  const finalResult =
    finalPlatformCode === 'bo'
      ? pick(duplicatedMap, reducedLocales)
      : {
          ...duplicatedMap,
          ...Object.fromEntries(additionalLocales.map((l) => [l, tbdLabels]))
        }
  const finalLocales = Object.keys(finalResult)

  await Promise.all(
    finalLocales.map((locale) => {
      return httpClient(`${API_URL}/i18n/${finalPlatformCode}-${locale}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json-patch+json'
        },
        body: JSON.stringify(
          Object.entries(finalResult[locale]).map(([path, value]) => ({
            op: 'add',
            path,
            value
          }))
        )
      })
    })
  )
}

const batchI18nOps = (
  platformCode: PlatformCode,
  code: string,
  ops: I18nUpdateRequest
) => {
  httpClient(`${API_URL}/i18n/${platformCode}-${code}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json-patch+json'
    },
    body: JSON.stringify(ops)
  })
}

const I18nFileProvider: React.FC<Props> = ({
  platformCode,
  requiredFiles,
  onSaved
}) => {
  const [apiI18nBundle, setApiI18nBundle] = useState<I18nMap | undefined>()
  const [isSaving, setIsSaving] = useState(false)
  const [duplicatedMap, setDuplicatedMap] = useState<I18nMap>({})
  const [duplicatedLabels, setDuplicatedLabels] = useState<string[]>([])

  const fetchFile = useCallback(async () => {
    try {
      const responses = await Promise.all(
        requiredFiles.map((f) => getBundle(platformCode, f))
      )

      setApiI18nBundle(
        requiredFiles.reduce(
          (acc, code, idx) => ({ ...acc, [code]: responses[idx] }),
          {}
        )
      )
    } catch (error) {
      KConsole.error(`ERROR fetching i18n files for ${platformCode}: ${error}`)
    }
  }, [platformCode, requiredFiles])

  const updateFile: UpdateFileFunction = useCallback(
    (bundle) => async (_e) => {
      try {
        setIsSaving(true)

        await Promise.all(
          Object.keys(bundle).map<Promise<any>>((code) => {
            const ops = getI18nBundleOperations(
              apiI18nBundle![code],
              bundle[code]
            )

            if (!!ops) {
              batchI18nOps(platformCode, code, ops)
            }

            return Promise.resolve()
          })
        )

        if (!!Object.keys(duplicatedMap).length) {
          await duplicateLabels(duplicatedMap, duplicatedLabels, platformCode)
          setDuplicatedMap({})
          setDuplicatedLabels([])
        }

        setApiI18nBundle({ ...bundle })
        onSaved?.(bundle)
      } catch (error) {
        KConsole.error(
          `ERROR updating i18n files for ${platformCode}: ${error}`
        )
      } finally {
        setIsSaving(false)
      }
    },
    [apiI18nBundle, platformCode, duplicatedLabels, duplicatedMap]
  )

  useEffect(() => {
    fetchFile()
  }, [fetchFile])

  return !!apiI18nBundle ? (
    <I18nFormHandler
      apiI18nBundle={apiI18nBundle}
      saveFunction={updateFile}
      isSaving={isSaving}
      requiredFiles={requiredFiles}
      setDuplicatedMap={setDuplicatedMap}
      duplicatedLabels={duplicatedLabels}
      setDuplicatedLabels={setDuplicatedLabels}
      platformCode={platformCode}
    />
  ) : null
}

export default I18nFileProvider
