import { mapValues } from '../../utils/object'
import { getCurrentCatalogLanguages, type LocaleCode } from '../countries'
import { getCurrentLang } from './multilang-accessors'

const HTML_TAGS_REGEX = /<(.|\n)*?>/g

const supportedLangs = getCurrentCatalogLanguages()

type SupportedLangsCode = string

export type MultiLanguageString = Record<SupportedLangsCode, string>

export type MultilangParseFunction = (v?: string) => MultiLanguageString
export type MultilangWriteFunction = (
  lang: SupportedLangsCode,
  v: string,
  parsed: MultiLanguageString
) => string | undefined

/**
 * This function is used to parse the multilang JSON string to a string in the
 * current system language. If the string is not found in the current language,
 * it will try to find it in English. If it is not found in English, it will
 * return the stringNotFound translation.
 * @param v the string you want to translate
 * @returns undefined if no value is passed, a string otherwise
 */
export const parseMultilangOrFallbackEng = (v?: string): string | undefined => {
  const currentLang = getCurrentLang()
  if (typeof v === 'undefined') {
    return undefined
  }
  if (parseMultilang(currentLang)(v)?.length) {
    return parseMultilang(currentLang)(v)
  }
  if (parseMultilang('en-US')(v)?.length) {
    return parseMultilang('en-US')(v)
  }
  return 'TRANSLATION_NOT_FOUND'
}

const createMultilangJSONWithValue = (value: string): MultiLanguageString =>
  supportedLangs.reduce<MultiLanguageString>(
    (acc, { localeCode }) => ({ ...acc, [localeCode]: value }),
    {}
  )

// JSON
// Parsing logic
export const parseMultilangJSON: MultilangParseFunction = (v) => {
  if (!v || !stripHTMLTags(v ?? '')) {
    return createMultilangJSONWithValue('')
  }

  if (typeof v === 'object') return v

  try {
    const result = JSON.parse(v) as MultiLanguageString

    if (typeof result !== 'object') {
      throw new Error('Invalid JSON')
    }

    return result
  } catch (error) {
    return createMultilangJSONWithValue(v)
  }
}

export const stripHTMLTags = (text: string) => text.replace(HTML_TAGS_REGEX, '')

// Writing logic
export const writeMultilangJSON: MultilangWriteFunction = (
  localeCode,
  v,
  parsed
) => {
  const updatedObject = {
    ...parsed,
    [localeCode]: v
  }
  if (Object.values(updatedObject).every((v) => isTextOrRichTextEmpty(v))) {
    return ''
  } else {
    return JSON.stringify(updatedObject)
  }
}

/**
 * Used to parse the multilang JSON string to a given language string
 * @param lang - The language to parse the JSON to
 */
const parseMultilang =
  (lang: LocaleCode) =>
  <T extends unknown>(v: T) =>
    typeof v === 'string' ? parseMultilangJSON(v)[lang] : v

/**
 * Takes the language from the context and parses the multilang JSON string
 * to the current language
 * @param v - The multilang JSON string
 */
export const parseMultilangToCurrentLang = (v: string | undefined): string => {
  const currentLang = getCurrentLang()
  return parseMultilang(currentLang)(v) ?? ''
}

/**
 * Takes an object with multilang JSON strings and parses them to
 * the current language. Returns the same object with translated values.
 * @param fields - The object with multilang JSON strings
 */
export const parseCsvFields = <T extends Record<string, string>>(
  fields: T
): T => {
  return mapValues(parseMultilangToCurrentLang, fields) as T
}

export const translateChoices = (choices: Object[], optionText: string) =>
  choices?.map((c) => ({
    ...c,
    [optionText]: parseMultilangToCurrentLang(
      (c as { [optionText: string]: string })[optionText]
    )
  }))

const isTextOrRichTextEmpty = (str: string | undefined) => {
  if (!str) return true

  const stripped = stripHTMLTags(str)
  return !stripped
}

/**
 * (for text or richtext) translate to
 * - current language if available
 * - or any other language if any
 * - or empty string if any translation available
 * @param multilangValue
 * @param lang
 */
export const parseMultilangOrFallbackToAnyAvailable = (
  multilangValue: string | undefined,
  lang?: LocaleCode
) => {
  const finaleLanguage = lang || getCurrentLang()
  const currentTranslation = parseMultilangJSON(multilangValue)[finaleLanguage]

  if (isTextOrRichTextEmpty(currentTranslation)) {
    const availableTranslations = Object.values(
      parseMultilangJSON(multilangValue)
    )

    const nonEmptyTranslations = availableTranslations.filter(
      (t) => !isTextOrRichTextEmpty(t)
    )

    if (!nonEmptyTranslations.length) return ''

    // return the first available
    return nonEmptyTranslations[0] ?? ''
  } else return currentTranslation ?? ''
}
