import { Badge } from '@mui/material'
import LabeledBooleanField from 'components/Fields/LabeledFields/LabeledBooleanField'
import EventServiceBriefSection from 'components/FormComponents/EventServiceBriefSection'
import { TailoredEventSelectionInput } from 'components/FormComponents/TailoredEventSelectionInput'
import KFormTab from 'components/Forms/KForms/KFormTab'
import KRichTextInput from 'components/Inputs/KInputs/KRichTextInput'
import KSelectInput from 'components/Inputs/KInputs/KSelectInput'
import KDivider from 'components/UI/KDivider'
import { useForm } from 'hooks/useForm'
import { useTranslate } from 'ra-core'
import React from 'react'
import { MappedEnrichedEvent } from 'services/api/entities/events/types/internal/event-models'
import { productStatusChoices } from 'services/cfg/form'
import { RAFormFC } from 'types/common'
import EventServiceSection from 'resources/operations/events/EventsComponents/EventServiceSection'
import { isDrinkObsolete } from 'resources/operations/events/utils'
import DrinkVariantsSelectionInput from 'resources/operations/events/eventsFormTabs/DrinkTab/DrinkVariantsSelectionInput'
import PricingSummary from 'components/FormComponents/PricingSummary/PricingSummary'
import { FormTabProps } from 'react-admin'
import FlexBox from 'components/UI/layout/FlexBox'
import EventCustomizationArrayInputAccordion from 'components/FormComponents/Customizations/EventCustomizationArrayInputAccordion'
import { useEventService } from 'hooks/events/useEventService'

type Props = Omit<FormTabProps, 'label'>

const DrinksFormTab: RAFormFC<Props> = (props) => {
  const {
    hasSelections,
    hasCustomizations,
    isNotifySupplierDisabled,
    notifyServiceSupplier
  } = useEventService('drinks')
  const translate = useTranslate()
  const { useGetValue, getSource } = useForm<MappedEnrichedEvent>(undefined)

  const service = useGetValue('productServices.drinkService')
  const isGeoareaSelected = !!useGetValue('geoAreaPreset')

  return (
    <KFormTab
      {...props}
      disabled={!isGeoareaSelected}
      label={
        <Badge
          badgeContent={hasSelections || hasCustomizations ? 1 : 0}
          color="primary"
          variant="dot"
        >
          {translate('form.tabs.drink')}
        </Badge>
      }
    >
      <FlexBox justifyContent="space-between">
        <KSelectInput
          source="drinkStatus"
          label="form.fields.status"
          defaultValue={productStatusChoices[0].id}
          choices={productStatusChoices}
        />
        <PricingSummary
          source={getSource('productServices.drinkService.pricing')}
          title={'form.headers.servicePricing'}
        />
      </FlexBox>

      <KRichTextInput source="drinkNotes" label="form.fields.notes" />

      <EventServiceSection
        source={getSource('productServices.drinkService')}
        serviceName={'drinks'}
      />

      {/* drinks input */}
      <TailoredEventSelectionInput
        entity="drinks"
        label="form.fields.drinks"
        source="drinks"
        getIsProductObsolete={isDrinkObsolete}
        hideQuantity
        disableAdd
      >
        <DrinkVariantsSelectionInput />
      </TailoredEventSelectionInput>

      <>
        <FlexBox>
          <KDivider />
          <EventCustomizationArrayInputAccordion
            source="productServices.drinkService"
            maxCustomizations={1}
          />
        </FlexBox>

        {!!service?.briefQuestions && !!service?.briefQuestions.length && (
          <>
            <KDivider
              title="Event Brief"
              headingLevel="h3"
              style={{
                width: '100%'
              }}
            />
            <LabeledBooleanField
              label="form.fields.briefConfirmation"
              source={`productServices.drinkService.briefConfirmed`}
            />

            <EventServiceBriefSection
              serviceSource="productServices.drinkService"
              isNotifySupplierDisabled={isNotifySupplierDisabled}
              notifyServiceSupplier={notifyServiceSupplier}
            />
          </>
        )}
      </>
    </KFormTab>
  )
}

export default DrinksFormTab
