import React from 'react'
import FacetFilterLocation from 'components/Inputs/Facets/Wrappers/FacetFilterLocation'
import FacetTag from 'components/Inputs/Facets/Wrappers/FacetTag'
import FacetMultiSelectInput from 'components/Inputs/Facets/BaseComponents/FacetMultiSelectInput'

const LocationFacetSection: React.FC = () => {
  return (
    <>
      <FacetTag />
      <FacetFilterLocation />
      <FacetMultiSelectInput
        source="eventTypeFacet"
        facetCategory="productFilter"
        filterGroup="EventType"
      />
    </>
  )
}

export default LocationFacetSection
