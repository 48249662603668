import type { StorageKey } from './keys'
import { defaultReviver, isJSONString } from '../../utils/json'

export const get = (key: string) => () => localStorage.getItem(key)
export const set = (key: string) => (value: string) =>
  localStorage.setItem(key, value)

/**
 * Builds a storage service
 * Date objects are transformed to ISO strings before being stored and transformed back to Date objects on get automatically
 */
export class KStorage<T extends Record<string, any>> {
  /**
   * @param {string} id the id key to be used in the browser storage, must belong to the StorageKey enum
   * @param {Storage} storage the storage mode to be used, must implement the Storage interface (localStorage, sessionStorage or custom implementation)
   */
  constructor(
    private id: StorageKey,
    private storage: Storage
  ) {}

  /**
   * Sets the value in the storage in the specified mode (local/session) with the id passed to KStorage.
   * @param newValue the value to be set in the storage
   */
  set(newValue: T) {
    this.storage.setItem(this.id, JSON.stringify(newValue))
  }

  /**
   * Gets the value from the storage in the specified mode (local/session) with the id passed to KStorage.
   * It will be parsed and transformed according to the dates passed to KStorage.
   * If the value you are trying to get is not a valid JSON string, an error will be thrown.
   * @returns the value stored in the STORAGE_MODES_MAP[mode] with the id passed to KStorage
   */
  get(): T | undefined {
    const serialized = this.storage.getItem(this.id)
    if (serialized === null) {
      return undefined
    }
    let parsedJson = isJSONString(serialized, defaultReviver) as T | false
    if (!parsedJson) {
      throw new Error(
        `KStorage: "Storage" item with id "${this.id}" is not a valid JSON string`
      )
    }

    return parsedJson
  }

  /**
   * Clears the value from the storage in the specified mode (local/session) with the id passed to KStorage.
   */
  clear() {
    this.storage.removeItem(this.id)
  }

  /**
   * Returns true if the value stored in the storage in the specified mode (local/session) with the id passed to KStorage is null.
   * @returns true if the value is empty, false otherwise
   */
  get isEmpty() {
    return this.storage.getItem(this.id) === null
  }
}
