import React from 'react'
import { ImageField, ImageInput, ImageInputProps, useNotify } from 'react-admin'
import { KInputProps, useKInput } from 'hooks/useKInput/useKInput'
import { WithStyle } from 'types/ui'
import ImageTooLargeNotification from 'components/Inputs/ImageTooLargeNotification'

export type KImageInputProps = KInputProps &
  Omit<ImageInputProps, 'source'> &
  WithStyle & {
    source?: string
    'data-testid'?: string
    disabled?: boolean
    showInnerImages?: boolean
  }

export const DEFAULT_IMAGE_MAX_FILE_SIZE = 5_000_000

/**
 * Wrapper of react-admins ImageInput.
 * @param label - the label of the input
 * @param accept - the accepted file types (ex: .png), COMMA SEPARATED e.g. .png, .jpeg, .tiff
 * @param source - the source of the input (if not provided defaults to 'image' or 'images' depending on multiple)
 * @param multiple - if multiple images can be uploaded or just a single one
 * @param maxSize - maxSize in bytes, if the images exceeds maxSize, will be rejected
 * @param options - react-dropzone useDropzone() hook options
 * @param disabled
 * @param dataTestId
 */
const KImageInput: React.FC<KImageInputProps> = (props) => {
  const [isNotificationOpen, setIsNotificationOpen] = React.useState(false)
  const notify = useNotify()

  const {
    label = 'form.fields.newImage',
    accept = '.jpeg, .jpg, .png',
    source,
    multiple = false,
    maxSize = DEFAULT_IMAGE_MAX_FILE_SIZE, // default 5MB
    options = {},
    disabled,
    showInnerImages = true,
    'data-testid': dataTestId,
    ...restProps
  } = props

  const { isRequired } = useKInput(props)

  const defaultSource = multiple ? 'images' : 'image'

  return (
    <>
      <ImageInput
        source={source ?? defaultSource}
        maxSize={maxSize}
        label={label}
        accept={accept}
        multiple={multiple}
        options={{
          disabled,
          onDropRejected: (files) => {
            const codeError = files[0]?.errors[0]?.code

            if (codeError && codeError === 'file-too-large') {
              return setIsNotificationOpen(true)
            }

            if (codeError && codeError === 'file-invalid-type') {
              // TODO: use our notification component
              notify(`ERROR: File type must be ${accept}`, { type: 'error' })
              return setIsNotificationOpen(false)
            }
          },
          ...options
        }}
        sx={{
          '& .RaFileInputPreview-removeButton': {
            display: disabled ? 'none' : 'revert'
          }
        }}
        inputProps={{
          ['data-testid']: dataTestId
        }}
        isRequired={isRequired}
        {...restProps}
      >
        {showInnerImages ? (
          <ImageField
            source="src"
            sx={{ '& .RaImageField-image': { width: '150px' } }}
          />
        ) : null}
      </ImageInput>

      <ImageTooLargeNotification
        isNotificationOpen={isNotificationOpen}
        setIsNotificationOpen={setIsNotificationOpen}
      />
    </>
  )
}

export default KImageInput
