import React from 'react'
import LocalizedWithChoicesInput from 'components/Inputs/Localized/LocalizedWithChoicesInput'
import KAutocompleteArrayInput, {
  KAutocompleteArrayInputProps
} from 'components/Inputs/KInputs/KAutocompleteArrayInput'

type Props = KAutocompleteArrayInputProps & { visible?: boolean }

const LocalizedAutoCompleteArrayInput: React.FC<Props> = (props) => (
  <LocalizedWithChoicesInput {...props}>
    <KAutocompleteArrayInput />
  </LocalizedWithChoicesInput>
)

export default LocalizedAutoCompleteArrayInput
