import { isISODate } from './date'

/**
 * Checks if a string is a valid JSON string.
 * If it is, it returns the parsed JSON object.
 * If it's not, it returns false.
 *
 * The valid JSON object is returned to avoid having to parse it again.
 *
 * @param str - The string to check if it is a valid JSON string.
 * @returns If the string is a valid JSON string, returns the parsed JSON object. Otherwise, returns false.
 */
export const isJSONString = (
  str: string,
  reviver?: (key: string, value: any) => any
): false | object => {
  let parsedJSON
  try {
    parsedJSON = JSON.parse(str, reviver)
  } catch (e) {
    return false
  }
  return parsedJSON
}

/**
 * Default reviver function used in JSON.parse to handle special value conversions.
 *
 * @param _ - The key of the property being parsed.
 * @param value - The value of the property being parsed.
 * @returns If the value is a valid ISO date string, returns a Date object. Otherwise, returns the original value.
 */
export const defaultReviver = (_: string, value: any) => {
  if (isISODate(value)) {
    return new Date(value)
  }

  return value
}
