import React from 'react'
import BriefQuestionArrayInput from 'components/FormComponents/Brief/BriefQuestionArrayInput'
import KDivider from 'components/UI/KDivider'
import KFormTab from 'components/Forms/KForms/KFormTab'

// FIXME: find out if we can change this any typing
const GeneralCfgTab: React.FC<any> = (props) => {
  // we need to remove these entries from the props to avoid triggering errors in the nested components
  const { undoable: _undoable, saving: _saving, save: _save, ...rest } = props

  return (
    <KFormTab {...rest} label="General">
      <KDivider title="Brief Questions" />
      <BriefQuestionArrayInput
        source="generalService.brief"
        isGeneralQuestion
      />
    </KFormTab>
  )
}

export default GeneralCfgTab
