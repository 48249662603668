import { OrganizationApproverConfig } from '@kampaay/common'
import { useKRecordContext } from 'hooks/useKRecordContext'
import { useTranslate } from 'ra-core'
import React from 'react'

const ApprovalStepField: React.FC = () => {
  const record = useKRecordContext<OrganizationApproverConfig>()
  const translate = useTranslate()

  if (!record) return null

  return (
    <div className="mb-3 flex gap-3 rounded-lg bg-secondary-lighten-7 px-4">
      <div className="my-4">
        {translate('form.fields.organizationApprovalType')}:{' '}
        {translate(
          `form.choices.organizationApprovalType.${record.approvalType}`
        )}
      </div>
      <ul>
        {record.approverOptions.map((option, index) => (
          <li key={index}>
            {option.email} - {option.name}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default ApprovalStepField
