import { isTruthy } from '../../../utils'
import { type APIFileInfo, parseFileInfo } from '../fileinfo'
import { type ImageFileInfo } from '../images-with-description'

export const parseImage = (doc?: APIFileInfo): ImageFileInfo | undefined => {
  const image = parseFileInfo(doc)

  if (!image || !doc) {
    return
  }

  return {
    ...image,
    src: doc?.url
  }
}

export const parseMultipleImages = (docs: APIFileInfo[]): ImageFileInfo[] =>
  docs.map(parseImage).filter(isTruthy)
