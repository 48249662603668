import React from 'react'
import { CreateBase, useListContext, useListFilterContext } from 'react-admin'
import { KSimpleForm } from 'components/Forms/KForms/KSimpleForm'
import EditableDatagridToolbar from 'components/Lists/EditableList/EditableDatagridToolbar'
import { WithId } from '@kampaay/common'
import { objectIsEmpty } from 'services/utils'

type Props = {
  record?: WithId
  formContent: JSX.Element
  setCreateVisibility: (value: boolean) => void
}

/**
 * This component shows a Create or Edit component with the form inside
 * depending on the fact if the record contains the id
 * @param record - the record taken from the list
 * @param formContent - the content that will be placed in the form
 * @param setCreateVisibility - function that places first row of the list as empty element creation
 */
const EmptyEditableListCreateForm: React.FC<Props> = ({
  record,
  formContent,
  setCreateVisibility
}) => {
  const { total } = useListContext()
  const filterData = useListFilterContext()
  const isNotFiltering = !total && objectIsEmpty(filterData.filterValues)

  const { refetch } = useListContext()
  const mutationOptions = {
    onSuccess: () => {
      // This is essential when you ssave a new record in order
      // to let to the list know that it needs to refetch the data
      refetch()

      if (!record?.id) {
        // Hides the empty row at the beginning of the list used to create a new record
        setCreateVisibility(false)
      }
    }
  }

  return (
    <>
      {isNotFiltering ? (
        <CreateBase redirect={false} mutationOptions={mutationOptions}>
          <KSimpleForm
            record={record}
            toolbar={
              <EditableDatagridToolbar
                id={record?.id}
                setCreateVisibility={setCreateVisibility}
                allowDelete={false}
              />
            }
          >
            {formContent}
          </KSimpleForm>
        </CreateBase>
      ) : null}
    </>
  )
}

export default EmptyEditableListCreateForm
