import React from 'react'
import { useFormContext } from 'react-hook-form'
import { DevTool } from '@hookform/devtools'
import { styled } from '@mui/system'

const StyledDiv = styled('div')(() => ({
  position: 'absolute',
  '& div': {
    top: '22px !important'
  }
}))

/**
 * The React hook form devtool
 * @see https://react-hook-form.com/dev-tools
 *
 * The styling is to put the devtool lower than the toolbar to avoid it from being covered
 */
const ReactHookDevtool: React.FC = () => {
  const { control } = useFormContext()
  return (
    <StyledDiv>
      <DevTool control={control} placement="bottom-right" />
    </StyledDiv>
  )
}

export default ReactHookDevtool
