import { useEffect, useState } from 'react'
import { useForm } from 'hooks/useForm'
import { ProductTailoringBase, WithIdAndName } from '@kampaay/common'
import { APIEntity } from 'services/api/entities'
import { useCatalogRepository } from 'resources/crm/clients/organization/tailoring/hooks/repositories/useCatalogRepository'

const onlyPricingProducts: APIEntity[] = [
  'foodsupselling',
  'virtualupselling',
  'physicalupselling',
  'locationupselling',
  'logistics'
]

export const useTailoringItemViewModel = <
  TProduct extends WithIdAndName = WithIdAndName
>(
  tailoringSource: string | undefined,
  productEntity: APIEntity,
  relatedTailoredProducts: WithIdAndName[]
) => {
  // ===================================
  // STATE & DATA
  // ===================================
  // STATE
  const [productName, setProductName] = useState('')

  // DATA
  const { products } = useCatalogRepository<TProduct>(productEntity)
  const { useSetValue, useGetValue } =
    useForm<ProductTailoringBase>(tailoringSource)
  const productId = useGetValue('id')
  const hasCustomPrice = useGetValue('hasCustomPrice')
  const setHasCustomPrice = useSetValue('hasCustomPrice')
  const errorDependencies = [
    'pricing',
    'hasCustomPrice',
    'isHidden',
    'option',
    'variants'
  ]
  const isOnlyPricingProduct = onlyPricingProducts.includes(productEntity)
  const isDrink = productEntity === 'drinks'

  // ===================================
  // EFFECTS
  // ===================================
  useEffect(() => {
    // used to set the product name properly
    // whether is:
    // - a tailored product
    // - or a catalog product chosen
    //   from the catalog products search box
    let pn = relatedTailoredProducts.find((p) => p.id === productId)?.name || ''

    if (!pn) {
      pn = products.find((p) => p.id === productId)?.name || ''
    }

    setProductName(pn)
  }, [productId, relatedTailoredProducts, products, setProductName])

  useEffect(() => {
    setHasCustomPrice(isOnlyPricingProduct || hasCustomPrice)
  }, [])

  // ===================================
  // ===================================
  return {
    state: { productName },
    data: {
      productId,
      isDrink,
      errorDependencies,
      hasCustomPrice,
      isOnlyPricingProduct,
      catalogProducts: products,
      filteredCatalogProducts: products
    }
  }
}
