import React from 'react'
import { drinkSpecialTypeChoices } from 'services/cfg/form'
import ProductSectionFormContent from 'components/Forms/ProductSectionFormContent'
import KSelectInput from 'components/Inputs/KInputs/KSelectInput'

const DrinkSectionFormContent: React.FC = () => (
  <ProductSectionFormContent canAssignProductToMultipleSections={false}>
    <KSelectInput source="specialType" choices={drinkSpecialTypeChoices} />
  </ProductSectionFormContent>
)

export default DrinkSectionFormContent
