import React from 'react'
import {
  Button,
  Datagrid,
  Identifier,
  TextField,
  useCreatePath,
  useTranslate
} from 'react-admin'
import { RaRecord } from 'ra-core'
import { useParams } from 'react-router-dom'
import { openInternalLinkInNewWindow } from 'services/utils/navigationUtils'
import { OrganizationSubGroup } from '@kampaay/common'

type Props = {
  bulkActions?: boolean
  data?: OrganizationSubGroup[]
  removeSubGroups?: () => void
}

const OrganizationBudgetSubGroupsDatagrid: React.FC<Props> = ({
  bulkActions,
  ...props
}) => {
  const createPath = useCreatePath()
  const { id: organizationId } = useParams()
  const translate = useTranslate()

  const openSubGroupEditPage = (
    _id: Identifier,
    _resource: string,
    record: RaRecord
  ) => {
    const subGroup = record as OrganizationSubGroup
    const path = createPath({
      resource: `organization/${organizationId}/sub-groups`,
      id: subGroup.id,
      type: 'edit'
    })

    openInternalLinkInNewWindow(path)

    return ''
  }

  return (
    <Datagrid
      {...props}
      bulkActionButtons={
        bulkActions ? (
          <Button label="ra.action.remove" onClick={props.removeSubGroups} />
        ) : (
          false
        )
      }
      rowClick={openSubGroupEditPage}
      sort={{ field: 'id', order: 'ASC' }}
      empty={
        <em className="opacity-50">
          {'(' + translate('form.fields.budget.noResults') + ')'}
        </em>
      }
    >
      <TextField source="name" label="Name" />
    </Datagrid>
  )
}

export default OrganizationBudgetSubGroupsDatagrid
