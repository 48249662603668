import { Customization } from 'services/api/entities/events/types/internal/event-models'
import {
  yupArray,
  yupLocalizedString,
  yupNumber,
  yupObject,
  YupSchema,
  yupString
} from 'services/yup'
import { docVS } from 'services/validations/doc'

export const customizationVS: YupSchema<Customization> = yupObject().shape({
  name: yupLocalizedString().required(),
  description: yupString().optional(),
  supplierName: yupString().optional(),
  images: yupArray(),
  documents: yupArray(docVS),
  cost: yupNumber().optional(),
  price: yupNumber().optional()
})
