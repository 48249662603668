import { KampaayDataProvider } from 'services/api/dataProvider'
import { Transaction } from 'services/api/entities/accounting/transaction/types/internal/transaction-models'
import { ExportData } from 'services/api/entities/accounting/transactionExportForPurchaseOrder/types/internal'
import {
  Proposal,
  ProposalCostPerSupplier,
  getProposalCostPerSupplier
} from '@kampaay/common'
import { CSVEntityDescriptor, CSVEntityFormatFn } from 'services/csv/types'
import { translate } from 'services/i18n'
import {
  formatDateForPurchaseOrderCSV,
  mapConciergePricingDataFromLatestExport,
  mapCurrentExportData,
  mergeCurrentlyExistingWithPreviouslyExistingCosts,
  patchLatestExportData
} from 'services/csv/entities/accounting/transactionForPurchaseOrder/utils'
import { Supplier, SupplierITA } from '@kampaay/common'

// Reference https://gitlab.com/kampaay/general/-/issues/2337
type TransactionForPurchaseOrderCSV = {
  conciergeId: number
  proposalId: number
  transactionId: number
  customerName: string
  dealName: `${TransactionForPurchaseOrderCSV['customerName']}:${TransactionForPurchaseOrderCSV['transactionId']}`
  eventDate: string
  supplierVat: string | '-'
  supplierBusinessName: string | '-'
  supplierName: string | '-'
  supplierEmail: string | '-'
  // Product name is always 'Extra'. It's needed for finance to automatically
  // link the transaction to a generic collector of Purchase Orders
  productName: 'Extra'
  currentTotalCost: number
  previousTotalCost: number
  currentTotalCommission: number
  previousTotalCommission: number
  currentExportDate: string
  previousExportDate: string
}

/**
 * This format function does three things:
 * - Gets the data of the previous export, if any
 * - Groups the costs and commissions of the proposal items linked to the transaction by supplier. All proposal items without a supplier
 * are grouped under the same supplier
 * - Updates the latest export data with the current export data
 *
 * @param transaction
 * @param relatedEntities
 * @param dataProvider
 * @returns an array of TransactionForPurchaseOrderCSV objects
 */
const formatTransactionForCSV: CSVEntityFormatFn<Transaction> = async (
  transaction: Transaction,
  { proposalId: relatedProposals }: { proposalId: Proposal[] },
  dataProvider: KampaayDataProvider
): Promise<TransactionForPurchaseOrderCSV[]> => {
  if (relatedProposals.length !== 1) {
    throw new Error(
      `Transaction ${transaction.id}: transactions coming from concierge must have one and only one related proposal!`
    )
  }

  const relatedProposal = relatedProposals[0]
  const costsPerSupplier: ProposalCostPerSupplier[] =
    getProposalCostPerSupplier(relatedProposal)

  let previousExportData: ExportData

  try {
    previousExportData = await patchLatestExportData(
      dataProvider,
      mapCurrentExportData(costsPerSupplier, transaction.id)
    )
  } catch (error) {
    throw new Error(
      `Error updating latest export data for transaction ${transaction.id}: ${error}`
    )
  }

  const previousEventExportData = previousExportData.eventExportData.find(
    (eventExportData) => eventExportData.eventId === transaction.id
  )!

  const suppliersToFetchIds = costsPerSupplier
    .filter(
      (costsPerSupplier) =>
        // Filter out suppliers that are already in the previous export data
        // and suppliers that are not in the previous export data but are
        // unknown
        costsPerSupplier.supplierId !== 'unknown' &&
        previousEventExportData.conciergeExportData.every(
          (conciergeExportData) =>
            conciergeExportData.supplierId !== costsPerSupplier.supplierId
        )
    )
    .map((costPerSupplier) => costPerSupplier.supplierId)
  if (suppliersToFetchIds.length !== 0) {
    const suppliers: (Supplier | SupplierITA)[] = (
      await dataProvider.getMany('suppliers', {
        ids: suppliersToFetchIds
      })
    ).data
    suppliers.forEach((supplier) =>
      previousEventExportData.conciergeExportData.push({
        supplierId: supplier.id,
        supplierBusinessName: supplier.businessName,
        supplierEmail: supplier.email,
        supplierName: supplier.name,
        supplierVat: 'vat' in supplier ? supplier.vat : '-',
        commission: 0,
        cost: 0
      })
    )
  }

  const costs = mergeCurrentlyExistingWithPreviouslyExistingCosts(
    costsPerSupplier,
    previousEventExportData
  )
  return costs.map<TransactionForPurchaseOrderCSV>((costPerSupplier) => {
    const previousConciergeExportPricingData =
      mapConciergePricingDataFromLatestExport(
        costPerSupplier.supplierId,
        previousEventExportData
      )

    return {
      conciergeId: relatedProposal.conciergeId!,
      proposalId: relatedProposal.id,
      transactionId: transaction.id,
      customerName: transaction.customerName,
      dealName: `${transaction.customerName}:${transaction.id}`,
      eventDate: formatDateForPurchaseOrderCSV(new Date(transaction.eventDate)),
      supplierVat: previousConciergeExportPricingData.supplierVat ?? '-',
      supplierBusinessName:
        previousConciergeExportPricingData.supplierBusinessName ?? '-',
      supplierName: previousConciergeExportPricingData.supplierName ?? '-',
      supplierEmail: previousConciergeExportPricingData.supplierEmail ?? '-',
      productName: 'Extra',
      currentTotalCost: costPerSupplier.costAndCommission.totalCost,
      previousTotalCost: previousConciergeExportPricingData.cost,
      currentTotalCommission: costPerSupplier.costAndCommission.totalCommission,
      previousTotalCommission: previousConciergeExportPricingData.commission,
      currentExportDate: formatDateForPurchaseOrderCSV(new Date(), {
        includeTime: true
      }),
      previousExportDate: formatDateForPurchaseOrderCSV(
        previousEventExportData.lastExportDate,
        {
          includeTime: true
        }
      )
    }
  })
}

export const transactionForPurchaseOrder: CSVEntityDescriptor<Transaction> = {
  format: formatTransactionForCSV,
  relatedRecords: [
    {
      key: 'proposalId',
      resource: 'proposal'
    }
  ],
  headersMapper: (header) =>
    translate(`csv.transactionForPurchaseOrder.${header}`)
}
