import React from 'react'
import SectionWrapper from 'components/UI/SectionWrapper'
import PhysicalExperiencesFormContent from 'resources/catalog/physicalexperiences/product/PhysicalExperiencesFormContent'
import { KSimpleForm } from 'components/Forms/KForms/KSimpleForm'
import KCreate from 'components/Forms/KCreate/KCreate'

const PhysicalExperienceCreate: React.FC = () => {
  return (
    <SectionWrapper>
      <KCreate>
        <KSimpleForm>
          <PhysicalExperiencesFormContent />
        </KSimpleForm>
      </KCreate>
    </SectionWrapper>
  )
}

export default PhysicalExperienceCreate
