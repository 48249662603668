import React, { type FC, useCallback, useMemo, useState } from 'react'
import { isEventPlaceholder } from 'services/utils/organization/calendar'
import OrganizationCalendarItemPopover from 'components/UI/Organization/Calendar/ItemPopover/OrganizationCalendarItemPopover'
import type { EventPlaceholder } from 'services/api/entities/event-placeholder/types/internal/event-placeholder-models'
import type { WithCalendarOriginalItem } from 'components/UI/Organization/Calendar/OrganizationCalendar'
import OrganizationCalendarItemBase, {
  type CalendarEventVariant
} from 'components/UI/Organization/Calendar/Item/OrganizationCalendarItemBase'

type Props = WithCalendarOriginalItem

const OrganizationCalendarItemContent: FC<Props> = ({ originalItem }) => {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null)

  const openPopover = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (isEventPlaceholder(originalItem)) {
        setAnchor(anchor ? null : event.currentTarget)
      }
    },
    [anchor]
  )

  const address = useMemo(
    () =>
      isEventPlaceholder(originalItem)
        ? originalItem.address
        : originalItem.fullAddress,
    [originalItem]
  )

  const status = useMemo((): CalendarEventVariant => {
    if (isEventPlaceholder(originalItem)) {
      return originalItem.status
    }

    if ('guid' in originalItem) {
      return originalItem.status
    }

    return originalItem.eventStatus
  }, [originalItem])

  return (
    <div className="h-full" onClick={openPopover}>
      <OrganizationCalendarItemBase
        variant={status || 'noEvents'}
        color={
          isEventPlaceholder(originalItem)
            ? originalItem.category?.color
            : undefined
        }
        isPlaceholder={isEventPlaceholder(originalItem)}
      >
        <div className="font-semibold">{originalItem.name}</div>
        <div className="text-sm">{address}</div>
      </OrganizationCalendarItemBase>
      {isEventPlaceholder(originalItem) && (
        <OrganizationCalendarItemPopover
          placeholder={originalItem as EventPlaceholder}
          anchor={anchor}
          setAnchor={setAnchor}
        />
      )}
    </div>
  )
}

export default OrganizationCalendarItemContent
