import { UseValidationSchema, YupSchema } from 'services/yup/types'
import { yupArray, yupIgnore, yupNumber, yupString } from 'services/yup'
import { mainProductVS } from 'services/validations/catalog/mainProduct'
import {
  areRulesNotOverlappingCFG,
  byRulePricingCommonVS,
  withCostAndPriceVS
} from 'services/validations/catalog/by-rule-pricing/byRulePricing'
import { isNameUniqueCFG } from 'services/validations/common'
import {
  withQuantityHintVS,
  withTimeBeforeAfterHintVS
} from 'services/validations/catalog/hints'
import {
  ProfessionalFigure,
  ProfessionalPricingRuleSet,
  TimeLabel
} from '@kampaay/common'

type ProfessionalShift = ProfessionalPricingRuleSet['shiftPricingRules'][number]

const pricingShiftVS: YupSchema<ProfessionalShift> = withCostAndPriceVS.shape({
  name: yupString().required(),
  hoursFrom: yupString<TimeLabel>().required(),
  hoursTo: yupString<TimeLabel>().required()
})

export const professionalByRulePricingVS: YupSchema<ProfessionalPricingRuleSet> =
  byRulePricingCommonVS.concat(withCostAndPriceVS).shape({
    shiftPricingRules: yupArray(pricingShiftVS).test(isNameUniqueCFG),
    /**
     * minHoursRules are added here only for purposes of computing the professional't pricing
     * they are not used in the form or in any other places
     */
    minHoursRules: yupArray()
  })

export const useProfessionalVS: UseValidationSchema<ProfessionalFigure> = () =>
  mainProductVS
    .concat(withQuantityHintVS)
    .concat(withTimeBeforeAfterHintVS)
    .shape({
      pricing: yupArray(professionalByRulePricingVS)
        .test(areRulesNotOverlappingCFG)
        .min(1),
      sectionName: yupIgnore(),
      section: yupNumber().required()
    })
