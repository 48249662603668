import {
  CreateResult,
  DeleteResult,
  GetListResult,
  GetOneResult,
  UpdateResult
} from 'react-admin'
import type { KampaayDataProvider } from 'services/api/dataProvider'
import { KConsole } from 'services/console'

/**
 *
 * @param seed init resource collection that will be used to prepopulate the API mocker
 * @returns partial of KampaayDataProvider
 */
export const apiMocker = <TModel extends { id: number }>(seed: TModel[]) => {
  const pocHandler: Partial<KampaayDataProvider> = {
    getList: (_resource, _params): Promise<GetListResult> => {
      // here we can do some logic and make data dynamic
      const data = seed
      return Promise.resolve({ data, total: data.length })
    },
    create(resource, { data }): Promise<CreateResult> {
      KConsole.log('create', resource, data)
      data.id = seed.reduce((acc, curr) => Math.max(acc, curr.id), 0) + 1
      seed.push(data)
      return Promise.resolve({ data })
    },
    update(resource, { data, id }): Promise<UpdateResult> {
      KConsole.log('update', resource, data)
      const obj = seed.find((m) => m.id === Number(id))!
      Object.keys(obj).forEach((key) => ((obj as any)[key] = data[key]))
      return Promise.resolve({ data })
    },
    delete(resource, { id }): Promise<DeleteResult> {
      KConsole.log('delete', resource, id)
      seed.splice(
        seed.findIndex((m) => m.id === Number(id)),
        1
      )
      return Promise.resolve({ data: { id } })
    },
    getOne(resource, params): Promise<GetOneResult> {
      KConsole.log('getOne', resource, params)
      return Promise.resolve({
        data: seed.find((m) => m.id === Number(params.id))!
      })
    }
  }

  return pocHandler
}
