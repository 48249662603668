import React from 'react'
import MainProductList, {
  MainProductListProps
} from 'components/Lists/MainProductList'

const RentList: React.FC<MainProductListProps> = (props) => (
  <MainProductList {...props} />
)

export default RentList
