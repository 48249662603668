import {
  Concierge,
  Proposal,
  ProposalHistory,
  getCountry
} from '@kampaay/common'
import KDateField from 'components/Fields/KDateField'
import { useKGetList } from 'hooks/useKGetList'
import { useKRecordContext } from 'hooks/useKRecordContext'
import OkrCountryViewSwitch, { OKRCountryView } from 'okrs/OkrCountryViewSwitch'
import React, { useEffect, useMemo, useState } from 'react'
import {
  BooleanField,
  Datagrid,
  FunctionField,
  NumberField,
  TextField,
  useListContext
} from 'react-admin'
import { include } from 'resources/operations/events/utils'
import { callApi, getApiCfg, getPath } from 'services/api/dataProvider/common'

type Props = {
  rangeStart: string
  rangeEnd: string
}

type EnrichedConcierge = Concierge & {
  hasDocuments: boolean
  hasReferencedProposals: boolean
}

const ReferencedProposalLink: React.FC<{ label: string }> = () => {
  const record = useKRecordContext<Concierge>()

  if (!record?.referencedProposalData?.proposalId) return <span>N/A</span>

  return (
    <span className="cursor-pointer font-black">
      #{record.referencedProposalData?.proposalId}
    </span>
  )
}

const getConciergeWithDocumentsBg = (perc: number) => {
  if (perc < 0.9) return 'bg-red-200'
  return 'bg-green-200'
}

const getConciergeWithReferencedProposalBg = (perc: number) => {
  if (perc > 0.08) return 'bg-green-200'
  if (perc >= 0.05) return 'bg-yellow-200'
  return 'bg-red-200'
}

const enrichConcierge =
  (histories: ProposalHistory[], proposals: Proposal[]) =>
  (c: Concierge): EnrichedConcierge => {
    const conciergeHistories = include(c.proposalHistories, histories)
    const conciergeProposals = include(
      conciergeHistories.flatMap((h) => h.proposals),
      proposals
    )

    return {
      ...c,
      hasReferencedProposals: !!c.referencedProposalData?.proposalId,
      hasDocuments:
        !!c.kampaayerDocs.length ||
        !!conciergeProposals.flatMap((p) => p.documents).length
    }
  }

const useHistories = () => {
  const { list: historiesIta } = useKGetList<ProposalHistory>('proposalHistory')
  const { list: historiesSwe } = useKGetList<ProposalHistory>(
    'proposalHistory',
    {
      sort: { field: 'id', order: 'DESC' },
      meta: {
        headers: { 'x-kampaay-country': getCountry() === 1 ? '2' : '1' }
      }
    }
  )

  return useMemo(
    () => [...historiesIta, ...historiesSwe],
    [historiesSwe, historiesIta]
  )
}

const useProposals = () => {
  const { list: proposalsIta } = useKGetList<Proposal>('proposal')
  const { list: proposalsSwe } = useKGetList<Proposal>('proposal', {
    sort: { field: 'id', order: 'DESC' },
    meta: { headers: { 'x-kampaay-country': getCountry() === 1 ? '2' : '1' } }
  })

  return useMemo(
    () => [...proposalsIta, ...proposalsSwe],
    [proposalsSwe, proposalsIta]
  )
}

const useSwedishConcierges = (filter: any, pagination: any) => {
  const [list, setList] = useState<Concierge[]>([])
  const apiCfg = getApiCfg('concierge')

  useEffect(() => {
    const fetchConcierges = async () => {
      const res = await callApi(
        apiCfg,
        getPath(apiCfg, {
          pagination,
          filter
        }),
        'concierge',
        true,
        'GET',
        undefined,
        { 'x-kampaay-country': getCountry() === 1 ? '2' : '1' }
      )

      setList(res?.data ?? [])
    }

    fetchConcierges()
  }, [JSON.stringify(filter)])

  return list
}

const ConciergeOkrsTabContent: React.FC<Props> = ({ rangeEnd, rangeStart }) => {
  const [currentView, setCurrentView] = useState<OKRCountryView>('all')

  const filters = useMemo(
    () => ({
      'createdAt~gte~': rangeStart,
      'createdAt~lte~': rangeEnd
    }),
    [rangeStart, rangeEnd]
  )

  const { perPage, page } = useListContext()
  const { data } = useListContext<Concierge>()
  const swedishConcierges = useSwedishConcierges(filters, {
    page,
    perPage
  })
  const histories = useHistories()
  const proposals = useProposals()

  const enrichedItalianConcierges = useMemo(
    () =>
      (data ?? [])
        .map((c) => ({ ...c, country: 'Italy' }))
        .map(enrichConcierge(histories, proposals)),
    [data, histories, proposals]
  )

  const enrichedSwedishConcierges = useMemo(
    () =>
      swedishConcierges
        .map((c) => ({ ...c, country: 'Sweden' }))
        .map(enrichConcierge(histories, proposals)),
    [swedishConcierges, histories, proposals]
  )

  const enrichedConcierges = useMemo(() => {
    if (currentView === 'ita') return enrichedItalianConcierges
    if (currentView === 'swe') return enrichedSwedishConcierges
    return [...enrichedItalianConcierges, ...enrichedSwedishConcierges]
  }, [enrichedItalianConcierges, enrichedSwedishConcierges])

  const conciergesAfterProposalStep = useMemo(
    () =>
      enrichedConcierges.filter(
        (c) => c.status !== 'received' && c.status !== 'takenInCharge'
      ),
    [enrichedConcierges]
  )

  const amountOfConciergesFromSF = useMemo(
    () => enrichedConcierges.filter((c) => !!c.request).length,
    [enrichedConcierges]
  )

  const amountOfConciergesWithOutDocuments = useMemo(
    () => conciergesAfterProposalStep.filter((c) => !c.hasDocuments).length,
    [enrichedConcierges]
  )

  const amountOfConciergesWithReferencedProposal = useMemo(
    () => enrichedConcierges.filter((c) => c.hasReferencedProposals).length,
    [enrichedConcierges]
  )

  const conciergesWithoutDocumentsPerc = useMemo(
    () =>
      amountOfConciergesWithOutDocuments / conciergesAfterProposalStep.length,
    [amountOfConciergesWithOutDocuments, enrichedConcierges]
  )

  const conciergesWithReferencedProposalPerc = useMemo(
    () => amountOfConciergesWithReferencedProposal / amountOfConciergesFromSF,
    [amountOfConciergesWithReferencedProposal, amountOfConciergesFromSF]
  )

  return (
    <div>
      <div className="flex justify-around">
        <OkrCountryViewSwitch
          value={currentView}
          updateValue={setCurrentView}
          title={`Currently viewed concierges: ${enrichedConcierges.length}`}
        />
        <div
          className={`m-3 rounded-2xl ${getConciergeWithDocumentsBg(
            conciergesWithoutDocumentsPerc
          )} p-4`}
        >
          <div>
            Total concierges after proposal step:{' '}
            {conciergesAfterProposalStep.length}
          </div>
          <div>
            Concierges without documents: {amountOfConciergesWithOutDocuments}
          </div>
          <div>
            Percentage of concierge without documents:{' '}
            {(conciergesWithoutDocumentsPerc * 100).toFixed(2)}%
          </div>
        </div>
        <div
          className={`m-3 rounded-2xl ${getConciergeWithReferencedProposalBg(
            conciergesWithReferencedProposalPerc
          )} p-4`}
        >
          <div>Total concierges from SF: {amountOfConciergesFromSF}</div>
          <div>
            Concierges with referenced proposal:{' '}
            {amountOfConciergesWithReferencedProposal}
          </div>
          <div>
            Percentage of concierge with referenced proposal:{' '}
            {(conciergesWithReferencedProposalPerc * 100).toFixed(2)}%
          </div>
        </div>
      </div>
      <Datagrid data={enrichedConcierges}>
        <NumberField source={'id'} sortable={false} />
        <TextField source={'country'} sortable={false} />
        <KDateField source={'createdAt'} sortable={false} />
        <TextField source={'status'} sortable={false} />
        <BooleanField source={'hasDocuments'} sortable={false} />
        <BooleanField source={'hasReferencedProposals'} sortable={false} />
        <ReferencedProposalLink label="Referenced Proposal" />
        <FunctionField
          label="Has Request"
          render={(c?: Concierge) => (!!c?.request ? 'Yes' : 'No')}
          sortable={false}
        />
      </Datagrid>
    </div>
  )
}

export default ConciergeOkrsTabContent
