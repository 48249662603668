import React from 'react'
import { Datagrid, TextField, BulkDeleteWithConfirmButton } from 'react-admin'
import KList from 'components/Lists/KLists/KList'
import SectionWrapper from 'components/UI/SectionWrapper'
import LocalizedTextField from 'components/Fields/Localized/LocalizedTextField'

const FacetList: React.FC = () => {
  return (
    <SectionWrapper title={'resources.facet.sectionName'}>
      <KList pagination={false} exporter={false}>
        <Datagrid
          rowClick="edit"
          bulkActionButtons={
            <BulkDeleteWithConfirmButton mutationMode="optimistic" />
          }
        >
          <LocalizedTextField
            source="description"
            label="form.fields.description"
          />
          <TextField source="category" label="form.fields.category" />
        </Datagrid>
      </KList>
    </SectionWrapper>
  )
}

export default FacetList
