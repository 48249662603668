import { LocaleCode, getCurrentLang, isJSONString } from '@kampaay/common'
import * as yup from 'yup'
import Reference from 'yup/lib/Reference'
import { MatchOptions } from 'yup/lib/string'
import { AnyObject, Maybe, Message } from 'yup/lib/types'

/**
 * Enhances yup.string() with the possibility to pass 2 generic parameters:
 * - The type of the string, which can be a union type to restrict the typing
 * - The type of the context of the field
 *
 * @returns a yup.string constructor
 */
export const yupString = <
  T extends string = string,
  K extends AnyObject = AnyObject
>() => new yup.StringSchema<T, K>()

/**
 * A schema that can validate (through the same api of yup.string()) a "Localized string",
 * i.e. a string in the form of a JSON with the format `{"it-IT": "...", "en-US": "..."}`
 *
 * The validation is done on every locale
 */
export class LocalizedStringSchema<
  TType extends Maybe<string> = string | undefined,
  TContext extends AnyObject = AnyObject,
  TOut extends TType = TType
> extends yup.BaseSchema<TType, TContext, TOut> {
  constructor() {
    super({ type: 'localizedstring' })
  }

  length(
    length: number | Reference<number>,
    message?: Message<{ length: number }> | undefined
  ): this {
    return this.onEachLocale((s) => s.length(length, message))
  }
  min(
    min: number | Reference<number>,
    message?: Message<{ min: number }> | undefined
  ): this {
    return this.onEachLocale((s) => s.min(min, message))
  }
  max(
    max: number | Reference<number>,
    message?: Message<{ max: number }> | undefined
  ): this {
    return this.onEachLocale((s) => s.max(max, message))
  }
  matches(
    regex: RegExp,
    options?: MatchOptions | Message<{ regex: RegExp }> | undefined
  ): this {
    return this.onEachLocale((s) => s.matches(regex, options))
  }
  email(message?: Message<{ regex: RegExp }> | undefined): this {
    return this.onEachLocale((s) => s.email(message))
  }
  url(message?: Message<{ regex: RegExp }> | undefined): this {
    return this.onEachLocale((s) => s.url(message))
  }
  uuid(message?: Message<{ regex: RegExp }> | undefined): this {
    return this.onEachLocale((s) => s.uuid(message))
  }
  trim(message?: Message<{}> | undefined): this {
    return this.onEachLocale((s) => s.trim(message))
  }
  lowercase(message?: Message<{}> | undefined): this {
    return this.onEachLocale((s) => s.lowercase(message))
  }
  uppercase(message?: Message<{}> | undefined): this {
    return this.onEachLocale((s) => s.uppercase(message))
  }
  /**
   * Checks if at least the current lang is populated
   * @param message
   * @returns
   */
  required(message?: Message<{}> | undefined): this {
    const parseValue = this.parseValue.bind(this)
    const testOptions = this.getTestOptions((s) => s.required(message))

    return this.test({
      ...testOptions,
      test: function (unparsedValue, context) {
        const locale = getCurrentLang()
        const value = parseValue(unparsedValue)
        try {
          yup
            .string()
            .required()
            .validateSync(typeof value === 'string' ? value : value[locale], {
              context
            })
          return true
        } catch {
          return false
        }
      }
    })
  }

  override _typeCheck(value: any): value is NonNullable<TType> {
    if (value instanceof String) value = value.valueOf()
    if (typeof value !== 'string') return false

    const localeRegex = /^[a-z]{2}-[A-Z]{2}$/

    try {
      const body = JSON.parse(value)
      return Object.entries(body).every(
        ([key, value]) => key.match(localeRegex) && typeof value === 'string'
      )
    } catch {
      return true
    }
  }

  protected override _isPresent(unparsedValue: any) {
    const value = this.parseValue(unparsedValue)
    return value != null && Object.values(value).every((v: any) => v.length)
  }

  private getTestOptions(
    applyTest: (schema: yup.StringSchema) => yup.StringSchema
  ) {
    const stringSchema = applyTest(yup.string())

    return stringSchema.tests.pop()!.OPTIONS
  }

  private parseValue(
    value: Maybe<string>
  ): Record<LocaleCode, string> | string {
    if (value && isJSONString(value) && this.isType(value)) {
      return JSON.parse(value)
    }

    return value!
  }

  /**
   * This function is the core of this schema. It accept a function that will setup a `yup.string()`
   * schema with some with some kind of validation. Then it uses that validation on every localized
   * string in the JSON to be validated
   *
   * @param applyTest A function that sets up a `yup.string()`
   * @returns An instance of `this` Schema, to allows fluent API
   */
  private onEachLocale(
    applyTest: (schema: yup.StringSchema) => yup.StringSchema
  ) {
    const parseValue = this.parseValue.bind(this)

    const testOptions = this.getTestOptions(applyTest)

    return this.test({
      ...testOptions,
      test: function (unparsedValue, context) {
        const testFn = testOptions.test.bind(this)

        const value = parseValue(unparsedValue)

        const checkValue = (value: string) => {
          const result = testFn(value, context)

          if (result !== true) {
            return testOptions.message
              ? context.createError({
                  message: testOptions.message
                })
              : false
          }

          return true
        }

        if (typeof value === 'string') {
          return checkValue(value)
        }

        for (const locale in value) {
          const checkedValue = checkValue(value[locale as LocaleCode])

          if (checkedValue !== true) {
            return checkedValue
          }
        }

        return true
      }
    })
  }
}

export const yupLocalizedString = <
  T extends string = string,
  K extends AnyObject = AnyObject
>() => new LocalizedStringSchema<T, K>()
