import { styled } from '@mui/material/styles'
import React from 'react'
import { ArrayInput, SimpleFormIterator } from 'react-admin'
import { RAFormFC } from 'types/common'
import { QuantityHintProps } from 'components/FormComponents/Hints/QuantityHint/DefaultQuantityHint'
import QuantityHintInput from 'components/FormComponents/Hints/QuantityHint/QuantityHintInput'

const PREFIX = 'QuantityHintArrayInput'

const classes = {
  fieldsBox: `${PREFIX}-fieldsBox`,
  fieldWidth: `${PREFIX}-fieldWidth`,
  ml55: `${PREFIX}-ml55`
}

const StyledArrayInput = styled(ArrayInput)(() => ({
  [`& .${classes.fieldsBox}`]: {
    display: 'flex',
    flexDirection: 'row',
    gap: '100px',
    width: '100%'
  },

  [`& .${classes.fieldWidth}`]: {
    width: '300px'
  },

  [`& .${classes.ml55}`]: {
    marginLeft: '50px'
  }
}))

const QuantityHintArrayInput: RAFormFC<QuantityHintProps> = ({
  guestsNumberMock,
  quantityHint
}) => {
  return (
    <StyledArrayInput source="quantityHint">
      <SimpleFormIterator>
        <QuantityHintInput
          guestsNumberMock={guestsNumberMock}
          quantityHint={quantityHint}
        />
      </SimpleFormIterator>
    </StyledArrayInput>
  )
}

export default QuantityHintArrayInput
