import { IWithCustomizations } from 'services/api/entities/events/types/internal/event-models'
import {
  EventBriefQuestion,
  EnrichedProductService,
  EnrichedProductServiceBundle,
  EnrichedProductServiceBase
} from 'services/api/entities/events/types/internal/services'
import {
  yupArray,
  yupBoolean,
  yupIgnore,
  yupNumber,
  yupObject,
  YupSchema,
  yupString
} from 'services/yup'
import { customizationVS } from 'services/validations/operations/events/customization'

export const withCustomizationsVS: YupSchema<IWithCustomizations> =
  yupObject().shape({
    customizations: yupArray(customizationVS)
  })

export const eventBriefQuestionVS: YupSchema<EventBriefQuestion> =
  yupObject().shape({
    id: yupIgnore(),
    mandatory: yupIgnore(),
    type: yupIgnore(),
    response: yupString().optional(),
    text: yupIgnore(),
    options: yupArray(),
    status: yupIgnore(),
    notActiveWhenServices: yupArray(),
    products: yupArray(yupNumber())
  })

export const enrichedProductServiceBaseVS: YupSchema<EnrichedProductServiceBase> =
  yupObject().shape({
    briefConfirmed: yupIgnore(),
    briefQuestions: yupArray(eventBriefQuestionVS).optional(),
    lastMessage: yupString().optional(),
    lastMessageConfirmed: yupIgnore(),
    supplier: yupIgnore(),
    supplierName: yupIgnore(),
    supplyConfirmed: yupIgnore(),
    triggerAssignmentEmail: yupBoolean(),
    pricing: yupIgnore(),
    notifySupplier: yupBoolean(),
    assignmentEmailSent: yupIgnore()
  })

export const enrichedProductServiceWithCustomizationsVS: YupSchema<EnrichedProductService> =
  enrichedProductServiceBaseVS.concat(withCustomizationsVS).shape({
    discriminator: yupIgnore()
  })

/**
 * VirtualService does not have the supplier details, so we ignore all the related data
 */
const enrichedVirtualServiceVS: YupSchema<EnrichedProductService> =
  enrichedProductServiceWithCustomizationsVS.shape({
    lastMessage: yupIgnore(),
    lastMessageConfirmed: yupIgnore(),
    supplier: yupIgnore(),
    supplierName: yupIgnore(),
    supplyConfirmed: yupIgnore(),
    triggerAssignmentEmail: yupIgnore()
  })

export const enrichedProductServicesBundleVS: YupSchema<EnrichedProductServiceBundle> =
  yupObject().shape({
    drinkService: enrichedProductServiceWithCustomizationsVS,
    foodService: enrichedProductServiceWithCustomizationsVS,
    rentService: enrichedProductServiceWithCustomizationsVS,
    musicService: enrichedProductServiceWithCustomizationsVS,
    locationService: enrichedProductServiceWithCustomizationsVS,
    physicalService: enrichedProductServiceWithCustomizationsVS,
    virtualService: enrichedVirtualServiceVS
  })
