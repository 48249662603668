export enum WeekDaysEnum {
  sunday,
  monday,
  tuesday,
  wednesday,
  thursday,
  friday,
  saturday
}

type SingleDigit = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9
type DayFirstDigit = 0 | 1 | 2 | 3
type MonthFirstDigit = 0 | 1
export type HoursDigits = `${0 | 1}${SingleDigit}` | `2${0 | 1 | 2 | 3}`
export type MinutesDigits = `${0 | 1 | 2 | 3 | 4 | 5}${SingleDigit}`
export type TimeLabel = `${HoursDigits}:${MinutesDigits}`

export type DayLabel = `${DayFirstDigit}${SingleDigit}`
export type MonthLabel = `${MonthFirstDigit}${SingleDigit}`
export type DayMonthLabel = `--${MonthLabel}-${DayLabel}`

export type DayOrMonth = 'day' | 'month'
export type NullableWithDayAndMonth = {
  month: MonthLabel | ''
  day: DayLabel | ''
}

export type ISODate = string & {
  _brand: 'ISODate'
}

export type DateOnly = string

export type APIDate = ISODate

export type WithLoginAndRegistrationDates = {
  createdAt?: ISODate
  lastLogin?: ISODate
}

export type WeekDay =
  | 'sunday'
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'

export enum EWeekDays {
  sunday,
  monday,
  tuesday,
  wednesday,
  thursday,
  friday,
  saturday
}
