import React from 'react'
import { translate } from 'services/i18n'
import { RAFormFC } from 'types/common'

type Props = {
  isSelectionsNotInCatalog: boolean
  isSupplierProvideProduct: boolean
  ifSupplierExist: boolean
  ifProductSelected: boolean
}

// To choose the appropriate message to show to the user => To avoid showing two messages at the same product
const SupplierAssignmentErrorSpan: RAFormFC<Props> = ({
  isSelectionsNotInCatalog,
  isSupplierProvideProduct,
  ifSupplierExist,
  ifProductSelected
}) => {
  if (isSelectionsNotInCatalog && ifProductSelected)
    return (
      <div style={{ color: 'red', border: '3px solid red', padding: '4px' }}>
        {translate('common.label.selectionsNotInCatalog')}
      </div>
    )
  if (!isSupplierProvideProduct && ifSupplierExist)
    //If the supplier was already assigned to the product, we want to show this message
    return (
      <div style={{ color: 'red', border: '3px solid red', padding: '4px' }}>
        {translate('common.label.supplierProvideProduct')}
      </div>
    )
  return <></>
}

export default SupplierAssignmentErrorSpan
