import React, { PropsWithChildren } from 'react'
import KFormTab from 'components/Forms/KForms/KFormTab'
import {
  EventItem,
  MappedEnrichedEvent
} from 'services/api/entities/events/types/internal/event-models'
import { useKRecordContext } from 'hooks/useKRecordContext'
import StandardEventGeneralInput from 'resources/operations/events/eventsFormTabs/StandardEventGeneralInput'
import QuestionRepliesAccordion from 'resources/operations/events/EventsComponents/CheckoutQuestionReply/QuestionRepliesAccordion'
import { someRepliesIsFilled } from 'resources/operations/events/utils'
import { useForm } from 'hooks/useForm'
import { RAFormFC } from 'types/common'
import KBanner from 'components/UI/KBanner'
import { Warning } from '@mui/icons-material'
import EventGeneralInfoAccordion from 'resources/operations/events/eventsFormTabs/EventGeneralInfoAccordion'
import EventServicesSummary from 'resources/operations/events/EventsComponents/EventServicesSummary/EventServicesSummary'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useTranslate } from 'ra-core'
import FlexBox from 'components/UI/layout/FlexBox'

type StandardEventGeneralTab = PropsWithChildren & {
  // this slot is used for appending react elements
  bottomSlot?: React.ReactElement | null
  editMode?: boolean
}

const StandardEventGeneralTab: RAFormFC<StandardEventGeneralTab> = ({
  children,
  bottomSlot,
  source,
  editMode = false,
  ...props
}) => {
  const { useGetValue } = useForm<MappedEnrichedEvent>(source)
  const record = useKRecordContext<EventItem>()
  const translate = useTranslate()
  // question replies
  const customerOrganizationId = record && record.customerOrganizationId
  const checkoutQuestionResponses = record && record.checkoutQuestionResponses
  const showReplies =
    !!customerOrganizationId && checkoutQuestionResponses?.length
  const expandCheckoutRepliesAccordion =
    checkoutQuestionResponses && someRepliesIsFilled(checkoutQuestionResponses)

  const isGeoareaSelected = !!useGetValue('geoAreaPreset')
  const shouldShowGeoareaBanner = !isGeoareaSelected

  return (
    <KFormTab
      label={
        <FlexBox
          alignItems="center"
          color="primary"
          justifyContent="space-between"
        >
          <ArrowBackIcon color="primary" />
          <span style={{ color: '#FF714D' }}>
            {translate('form.tabs.summary')}
          </span>
        </FlexBox>
      }
      {...props}
    >
      {shouldShowGeoareaBanner && (
        <KBanner icon={<Warning />} text="form.banners.missingGeoarea" />
      )}

      <EventServicesSummary isGeoareaSelected={isGeoareaSelected} />
      <EventGeneralInfoAccordion editMode={editMode}>
        {children}
      </EventGeneralInfoAccordion>
      <StandardEventGeneralInput />
      {showReplies ? (
        <QuestionRepliesAccordion
          defaultExpanded={expandCheckoutRepliesAccordion}
        />
      ) : null}

      {bottomSlot}
    </KFormTab>
  )
}

export default StandardEventGeneralTab
