import {
  OptionItemSelection,
  OptionGroupSelection
} from 'services/api/entities/events/types/internal/selections'
import { yupArray, yupIgnore, yupObject, YupSchema } from 'services/yup'
import { productSelectionBaseVS } from 'services/validations/operations/events/selections/common/base'

export const optionItemSelectionVS: YupSchema<OptionItemSelection> =
  productSelectionBaseVS

export const optionGroupSelectionVS: YupSchema<OptionGroupSelection> =
  yupObject().shape({
    id: yupIgnore(),
    name: yupIgnore(),
    acceptsQuantity: yupIgnore(),
    infoBox: yupIgnore(),
    maxSelection: yupIgnore(),
    items: yupArray(optionItemSelectionVS)
  })
