import type { MultipleFileUploader } from '../../../types'
import {
  type APIPricingRuleSet,
  mainProductWrite,
  writeQuantityHint,
  writeTimeBeforeAfterHint
} from '../common'
import type {
  ProfessionalFigure,
  ProfessionalFigureToAPI,
  ProfessionalPricingRuleSet
} from './types'

export const writeProfessionalPricing = ({
  cost,
  price,
  shiftPricingRules,
  ...rest
}: ProfessionalPricingRuleSet): APIPricingRuleSet => ({
  ...rest,
  // professional pricing uses a wider API contract (see https://gitlab.com/kampaay/general/-/wikis/ByRules-Pricing)
  // but we narrow it down with hardcoded values
  minHoursRules: [
    {
      cost,
      price,
      atLeast: 0.5,
      isExtra: false,
      priceType: 'proportional',
      name: undefined
    }
  ],
  timePeriodRules:
    shiftPricingRules?.map((rule) => ({
      ...rule,
      isExtra: false,
      priceType: 'proportional'
    })) ?? []
})

export const professionalWrite =
  (imagesUploader: MultipleFileUploader) =>
  async (req: ProfessionalFigure): Promise<ProfessionalFigureToAPI> => {
    const {
      section,
      sectionName: _sectionName,
      defaultTimeBeforeAfterHint: _defaultTimeBeforeAfterHint,
      timeBeforeAfterHint: _timeBeforeAfterHint,
      showTimeBeforeAfterHint: _showTimeBeforeAfterHint,
      pricing,
      ...rest
    } = req

    const sections = section ? [section] : []
    const base = await mainProductWrite(imagesUploader)(rest)

    return {
      ...base,
      sections,
      quantityHint: writeQuantityHint(req),
      timeBeforeAfterHint: writeTimeBeforeAfterHint(req),
      pricing: pricing.map(writeProfessionalPricing)
    }
  }
