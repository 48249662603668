import React from 'react'
import { RAFormFC } from 'types/common'
import { useForm } from 'hooks/useForm'
import { HiddenServices } from 'services/api/entities/crm/organizations/types/internal/organization-model'
import KAccordion from 'components/UI/Accordions/KAccordion/KAccordion'
import OrganizationHiddenServiceToggle from 'components/FormComponents/Organization/OrganizationHiddenServiceToggle'

type OrganizationHiddenServicesInputProps = {
  source: string
  organizationId: number
}

const OrganizationHiddenServicesInput: RAFormFC<
  OrganizationHiddenServicesInputProps
> = ({ source, organizationId }) => {
  const { getSource } = useForm<HiddenServices>(source)

  return (
    <KAccordion accordionTitle="form.fields.servicesConfiguration">
      <OrganizationHiddenServiceToggle
        label="resources.customers.hiddenServices.hide.physical"
        organizationId={organizationId}
        source={getSource('physicals')}
        servicesToCheck={'physicals'}
      />
      <OrganizationHiddenServiceToggle
        label="resources.customers.hiddenServices.hide.foodAndDrink"
        organizationId={organizationId}
        source={getSource('foods')}
        servicesToCheck={['foods', 'drinks']}
      />

      <OrganizationHiddenServiceToggle
        label="resources.customers.hiddenServices.hide.virtual"
        organizationId={organizationId}
        source={getSource('virtuals')}
        servicesToCheck={'virtuals'}
      />
      <OrganizationHiddenServiceToggle
        label="resources.customers.hiddenServices.hide.location"
        organizationId={organizationId}
        source={getSource('locations')}
        servicesToCheck={'locations'}
      />
      <OrganizationHiddenServiceToggle
        label="resources.customers.hiddenServices.hide.professional"
        organizationId={organizationId}
        source={getSource('professionals')}
        servicesToCheck={'professionals'}
      />
      <OrganizationHiddenServiceToggle
        label="resources.customers.hiddenServices.hide.music"
        organizationId={organizationId}
        source={getSource('musics')}
        servicesToCheck={'musics'}
      />
      <OrganizationHiddenServiceToggle
        label="resources.customers.hiddenServices.hide.rent"
        organizationId={organizationId}
        source={getSource('rents')}
        servicesToCheck={'rents'}
      />
    </KAccordion>
  )
}

export default OrganizationHiddenServicesInput
