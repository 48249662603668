import React from 'react'
import { ReferenceInput } from 'react-admin'
import type { RAFormFC } from 'types/common'
import { useForm } from 'hooks/useForm'
import type { OrganizationBase } from 'services/api/entities/crm/organizations/types/internal/organization-model'
import KAutocompleteInput from 'components/Inputs/KInputs/KAutocompleteInput'
import { FILTER_SOURCES } from '@kampaay/common'

const SalesOwnerAutocompleteInput: RAFormFC = ({ source }) => {
  const { getSource } = useForm<OrganizationBase>(source)

  return (
    <ReferenceInput
      name="salesOwnerId"
      source={getSource('salesOwnerId')}
      reference="kampaayers"
      filter={{
        [FILTER_SOURCES.role.in]: ['customerSuccess', 'accountExecutive']
      }}
    >
      <KAutocompleteInput
        label="resources.organization.salesOwner"
        optionText={(choice) =>
          `${choice.firstName} ${choice.lastName} (${choice.email})`
        }
        className="w-[350px]"
      />
    </ReferenceInput>
  )
}

export default SalesOwnerAutocompleteInput
