import React from 'react'
import { Labeled } from 'react-admin'
import { useForm } from 'hooks/useForm'
import { RAFormFC } from 'types/common'
import KNumberInput from 'components/Inputs/KInputs/KNumberInput/KNumberInput'
import { computeQuantityByMaxParticipants } from 'components/FormComponents/Hints/common'
import { QuantityHintProps } from 'components/FormComponents/Hints/QuantityHint/DefaultQuantityHint'
import { QuantityHintScopedFormDataType } from 'components/FormComponents/Hints/types'

const PREFIX = 'QuantityHintArrayInput'

const classes = {
  fieldsBox: `${PREFIX}-fieldsBox`,
  fieldWidth: `${PREFIX}-fieldWidth`,
  ml55: `${PREFIX}-ml55`
}

const QuantityHintInput: RAFormFC<QuantityHintProps> = ({
  guestsNumberMock,
  quantityHint,
  source
}) => {
  const { getSource, useGetValue } =
    useForm<QuantityHintScopedFormDataType>(source)

  const maxParticipants = useGetValue('maxParticipants')
  const quantityMultiplier = useGetValue('quantityMultiplier')
  return (
    <div className={classes.fieldsBox}>
      <KNumberInput
        source={getSource!('maxParticipants')}
        label="form.fields.maxParticipants"
        className={classes.fieldWidth}
      />
      <KNumberInput
        source={getSource!('quantityMultiplier')}
        label="form.fields.quantityMultiplier"
        className={classes.fieldWidth}
        defaultValue={100}
      />
      <Labeled label="Simulated quantity" className={classes.fieldWidth}>
        <div>
          {computeQuantityByMaxParticipants(
            quantityHint,
            maxParticipants,
            quantityMultiplier,
            guestsNumberMock
          )}
        </div>
      </Labeled>
    </div>
  )
}
export default QuantityHintInput
