import React from 'react'
import { ChipField } from 'react-admin'
import { MappedEnrichedEvent } from 'services/api/entities/events/types/internal/event-models'
import { RAFormFC } from 'types/common'
import EventServiceBriefSection from 'components/FormComponents/EventServiceBriefSection'
import KDivider from 'components/UI/KDivider'
import { useForm } from 'hooks/useForm'
import KAccordion from 'components/UI/Accordions/KAccordion/KAccordion'
import EventCustomizationArrayInput from 'components/FormComponents/Customizations/EventCustomizationArrayInput'
import { COLLECTION_NAME_SERVICE_MAP } from 'services/cfg/name-maps'
import KHeader from 'components/UI/KHeader'
import FileInputAccordion from 'components/Inputs/FileInputAccordion'
import { useEventForm } from 'hooks/events/useEventForm'
import { KFileField } from 'components/Fields/KFileField'

const StandardEventGeneralInput: RAFormFC = ({ source }) => {
  const { useGetValue, getSource } = useForm<MappedEnrichedEvent>(source)
  const briefQuestions = useGetValue('generalService.briefQuestions')
  const hasBriefQuestions = !!briefQuestions?.length
  const serviceNames = Object.values(COLLECTION_NAME_SERVICE_MAP)
  const eventFormComputed = useEventForm()

  return (
    <>
      <KDivider title="Client Docs" />

      <KFileField source="customerDocs" title="title" target="_blank" />

      {hasBriefQuestions && (
        <>
          <KDivider title="Event Brief" headingLevel="h3" />
          <EventServiceBriefSection serviceSource="generalService" />
        </>
      )}

      <KAccordion
        accordionTitle="form.headers.customization"
        asideContent={
          <ChipField
            source="numberOfCustomizations"
            record={eventFormComputed}
            style={{ marginLeft: '12px' }}
          />
        }
      >
        <KHeader headingLevel="h3" title="form.headers.GeneralConfigurator" />
        <EventCustomizationArrayInput label="" />

        {serviceNames.map((serviceName) => (
          <React.Fragment key={serviceName}>
            <KDivider headingLevel="h3" title={`form.headers.${serviceName}`} />

            <EventCustomizationArrayInput
              maxCustomizations={1}
              label=""
              source={getSource(
                serviceName === 'professionalService'
                  ? 'professionalService'
                  : `productServices.${serviceName}`
              )}
            />
          </React.Fragment>
        ))}
      </KAccordion>

      <FileInputAccordion
        accept="application/pdf,.xls,.xlsx,.docx"
        source="kampaayerDocs"
        multiple
        asideContent={
          <ChipField
            source="numberOfKampaayerDocks"
            record={eventFormComputed}
            style={{ marginLeft: '12px' }}
          />
        }
      />
    </>
  )
}

export default StandardEventGeneralInput
