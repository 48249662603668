import React from 'react'
import { styled } from '@mui/material'
import KNumberInput from 'components/Inputs/KInputs/KNumberInput/KNumberInput'
import MarginPercAndVatFields from 'components/FormComponents/pricing/MarginPercAndVatFields'
import { useForm } from 'hooks/useForm'
import { RAFormFC } from 'types/common'
import { WithCostAndPrice } from '@kampaay/common'

const PREFIX = 'PricingRulePriceInputGroup'

const classes = {
  fieldsBox: `${PREFIX}-fieldsBox`,
  fieldWidth: `${PREFIX}-fieldWidth`
}

const StyledDiv = styled('div')(() => ({
  [`&.${classes.fieldsBox}`]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '100px',
    width: '100%'
  },

  [`& .${classes.fieldWidth}`]: {
    width: '300px'
  }
}))

const PricingRulePriceInputGroup: RAFormFC = ({ source }) => {
  const { getSource } = useForm<WithCostAndPrice>(source)

  return (
    <StyledDiv className={classes.fieldsBox}>
      <KNumberInput
        source={getSource('cost')}
        label="form.fields.cost"
        className={classes.fieldWidth}
      />
      <KNumberInput
        source={getSource('price')}
        label="form.fields.price"
        className={classes.fieldWidth}
      />

      <MarginPercAndVatFields
        costSource={getSource('cost')}
        priceSource={getSource('price')}
      />
    </StyledDiv>
  )
}

export default PricingRulePriceInputGroup
