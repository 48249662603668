import type { FileUploader, MultipleFileUploader } from '../../types'
import { writeGeocodedAddress } from '../geo-coded-addresses'
import { writeImageWithDescription } from '../images-with-description'
import type { SuppliedServiceProducts, Supplier, SupplierToAPI } from './types'
import type {
  APISupplierProductsByService,
  LocationArrangementType
} from './types/api/common'
import { SUPPLIER_SERVICE_NAMES_MAP } from './utils'

const convertSuppliedServicesToSupplier = (
  suppliedServicesProducts: SuppliedServiceProducts[]
) =>
  Object.entries(SUPPLIER_SERVICE_NAMES_MAP).reduce(
    (acc, [collectionName, apiName]) => ({
      ...acc,
      [apiName]:
        suppliedServicesProducts.find(
          ({ service }) => service === collectionName
        )?.products || []
    }),
    {} as APISupplierProductsByService
  )

const convertAIFilledProperty = (prop: string): string => {
  const propMap: Record<string, string> = {
    geoAddress: 'geoCodedAddress',
    supplierType: 'supplierTypeId'
  }
  return propMap[prop] || prop
}

export const writeSupplier =
  (imageUploader: FileUploader, multipleDocsUploader: MultipleFileUploader) =>
  async ({
    suppliedServicesProducts = [],
    images,
    documents,
    additionalEmails,
    geoCodedAddress,
    documentItems: _,
    aiFilledProperties,
    ...rest
  }: Supplier): Promise<SupplierToAPI> => {
    const mappedFromSuppliedServices = convertSuppliedServicesToSupplier(
      suppliedServicesProducts
    )

    const writtenImages = await Promise.all(
      images.map(writeImageWithDescription(imageUploader))
    )
    const writtenDocuments = await multipleDocsUploader(documents)

    const rooms = rest.rooms?.map(({ arrangementStructure, ...room }) => ({
      ...room,
      arrangementStructure: arrangementStructure
        ? arrangementStructure.reduce(
            (acc, { arrangementType, capacity }) => ({
              ...acc,
              [arrangementType]: capacity
            }),
            {}
          )
        : ({} as Partial<Record<LocationArrangementType, number>>)
    }))

    return {
      ...rest,
      ...mappedFromSuppliedServices,
      rooms,
      images: writtenImages,
      documents: writtenDocuments,
      geoCodedAddress: writeGeocodedAddress(geoCodedAddress),
      additionalEmails: additionalEmails?.length ? additionalEmails : undefined,
      aiFilledProperties: aiFilledProperties?.remainingProperties?.map(
        convertAIFilledProperty
      )
    }
  }
