import { useMemo } from 'react'
import { MappedEnrichedEvent } from 'services/api/entities/events/types/internal/event-models'
import { EnrichedProfessionalServiceGroup } from 'services/api/entities/events/types/internal/services'
import {
  EventReadonlyProfessionalServiceSummary,
  getReadonlyEventProfessionalService
} from 'hooks/events/getReadonlyEventProfessionalService'

export type EventReadonlyProfessionalServiceGroupSummary = {
  hasCustomizations: boolean
  numberOfCustomizations: number
  hasSelections: boolean
  professionalServiceGroup: EnrichedProfessionalServiceGroup
  professionalServiceSummaries: EventReadonlyProfessionalServiceSummary[]
}

// This hook is used to bring back the read-only version of the professional service group summary
// It is using useMemo to avoid re-rendering the component when the record changes
export const useReadonlyEventProfessionalServiceGroup = (
  record: MappedEnrichedEvent
): EventReadonlyProfessionalServiceGroupSummary => {
  const professionalServiceGroup = useMemo(
    () => record.professionalService,
    [record]
  )

  const hasCustomizations = useMemo(
    () => !!professionalServiceGroup?.customizations?.length,
    [professionalServiceGroup]
  )
  const numberOfCustomizations = useMemo(
    () => professionalServiceGroup?.customizations?.length ?? 0,
    [professionalServiceGroup]
  )

  const professionalServiceSummaries =
    professionalServiceGroup?.services?.map((s, idx) =>
      getReadonlyEventProfessionalService(s, hasCustomizations, idx)
    ) ?? []

  const hasSelections = useMemo(
    () => professionalServiceSummaries.some((summary) => summary.hasSelections),
    [professionalServiceGroup]
  )

  return {
    hasCustomizations,
    numberOfCustomizations,
    hasSelections,
    professionalServiceGroup,
    professionalServiceSummaries
  }
}
