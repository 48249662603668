import { MappedEnrichedEvent } from 'services/api/entities/events/types/internal/event-models'
import { EnrichedProfessionalService } from 'services/api/entities/events/types/internal/services'
import {
  EventServiceSummary,
  NotifySupplierErrors
} from 'hooks/events/useEventService'
import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { getReadonlyEventProfessionalService } from 'hooks/events/getReadonlyEventProfessionalService'
import {
  EventReadonlyProfessionalServiceGroupSummary,
  useReadonlyEventProfessionalServiceGroup
} from 'hooks/events/useReadonlyEventProfessionalServiceGroup'
import { checkBriefQuestionsResponses } from 'services/utils/services/checkBriefQuestionsResponses'
import get from 'lodash/get'

export type EventProfessionalServiceSummary = Omit<
  EventServiceSummary<EnrichedProfessionalService>,
  'numberOfCustomizations'
> & { professionalServiceId: number }

type EventProfessionalServiceGroupSummary = Omit<
  EventReadonlyProfessionalServiceGroupSummary,
  'professionalServiceSummaries'
> & {
  professionalServiceSummaries: EventProfessionalServiceSummary[]
}

export const useEventProfessionalServiceGroup =
  (): EventProfessionalServiceGroupSummary => {
    const { setValue, getValues, watch } = useFormContext<MappedEnrichedEvent>()

    const record = getValues()

    const { professionalServiceGroup, hasCustomizations, ...rest } =
      useReadonlyEventProfessionalServiceGroup(record)

    const professionalServiceSummaries: EventProfessionalServiceSummary[] =
      useMemo(
        () =>
          professionalServiceGroup?.services?.map((proService, idx) => {
            const { serviceSource, ...rest } =
              getReadonlyEventProfessionalService(
                proService,
                hasCustomizations,
                idx
              )

            // A temporary solution to mimic the functionality of useNotifySupplier
            // Should be addressed in https://gitlab.com/kampaay/kampaay-backoffice/-/issues/578
            const briefQuestions = watch(`${serviceSource}.briefQuestions`)
            const supplier = watch(`${serviceSource}.supplier`)

            const setSupplierNotification = () =>
              setValue(`${serviceSource}.notifySupplier`, true)

            const isPreviouslySetToTrue = !!get(
              record,
              `${serviceSource}.notifySupplier`
            )!

            const isBriefFilledOut =
              checkBriefQuestionsResponses(briefQuestions)

            const isSupplierAssigned = !!supplier

            const isNotifySupplierDisabled: () =>
              | NotifySupplierErrors
              | undefined = () => {
              if (isPreviouslySetToTrue) {
                return 'previouslySetToTrue'
              } else if (!isSupplierAssigned) {
                return 'noSupplier'
              } else if (!isBriefFilledOut) {
                return 'missingBrief'
              } else {
                return undefined
              }
            }

            const notifyServiceSupplier = () => {
              if (!isNotifySupplierDisabled) {
                setSupplierNotification()
              }
            }

            const resetSelection = () => {
              setValue(`${serviceSource}.selections`, [] as any)
            }

            return {
              serviceSource,
              ...rest,
              resetSelection,
              professionalServiceId: proService.id,
              isNotifySupplierDisabled: undefined,
              notifyServiceSupplier
            }
          }) ?? [],
        [professionalServiceGroup]
      )

    return {
      professionalServiceGroup,
      hasCustomizations,
      ...rest,
      professionalServiceSummaries
    }
  }
