import { I18nProvider, Translate, TranslationMessages } from 'ra-core'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import { fetchUtils } from 'react-admin'
import { useState } from 'react'
import { KConsole } from 'services/console'
import { CURRENT_I18N_LOCALE } from '@kampaay/common'

const loadBoI18nBundle = () =>
  fetchUtils
    .fetchJson(
      `${process.env.REACT_APP_I18N_BUNDLE_BASE_URL}bo-${CURRENT_I18N_LOCALE}.json`
    )
    .then((res) => res.json as Promise<TranslationMessages>)

const buildProvider = (transMessages: TranslationMessages) =>
  polyglotI18nProvider(
    () => {
      return transMessages
    },
    'en',
    [{ locale: 'en', name: 'English' }]
  )

let i18nProvider: I18nProvider | undefined

let loadBundleInProvider = (_transMessages: TranslationMessages): void => {
  KConsole.error('loadBundleInProvider not initialized')
}

export const reloadI18nBundle = (transMessages: TranslationMessages) =>
  loadBundleInProvider(transMessages)

export const useI18nProvider = () => {
  const [provider, setProvider] = useState<I18nProvider | undefined>()
  const [fetchTriggered, setFetchTriggered] = useState(false)

  loadBundleInProvider = (transMessages: TranslationMessages) => {
    KConsole.log('>>>> GOT BUNDLE - BUILDING PROVIDER')
    i18nProvider = buildProvider(transMessages)
    setProvider(i18nProvider)
  }

  if (!fetchTriggered) {
    setFetchTriggered(true)
    loadBoI18nBundle().then(loadBundleInProvider)
  }

  return provider
}

export const translate: Translate = (...args) => {
  if (i18nProvider === undefined) {
    KConsole.error(`translate called before language bundle fetch:`, args)
    return args[0]
  }
  return i18nProvider.translate(...args)
}
