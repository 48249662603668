import React from 'react'
import { RAFormFC } from 'types/common'
import KampaayerSelectInput, {
  KampaayerSelectInputProps
} from 'components/Inputs/KampaayerSelectInput'
import { FILTER_SOURCES } from '@kampaay/common'

const SalesOwnerSelectInput: RAFormFC<KampaayerSelectInputProps> = ({
  source,
  sx,
  fullWidth
}) => (
  <KampaayerSelectInput
    source={source}
    sx={sx}
    fullWidth={fullWidth}
    label="form.fields.salesOwner"
    queryFilter={{
      [FILTER_SOURCES.role.in]: ['customerSuccess', 'accountExecutive'],
      [FILTER_SOURCES.isOrganizationOwner.eq]: true
    }}
  />
)

export default SalesOwnerSelectInput
