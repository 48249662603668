import React from 'react'
import { OrganizationBase } from 'services/api/entities/crm/organizations/types/internal/organization-model'
import { useKGetList } from 'hooks/useKGetList'
import KToolTip from 'components/UI/KTooltip'
import KAutocompleteArrayInput, {
  KAutocompleteArrayInputProps
} from 'components/Inputs/KInputs/KAutocompleteArrayInput'

const OrganizationAutocompleteArrayInput: React.FC<
  KAutocompleteArrayInputProps
> = (props) => {
  const organizations = useKGetList<OrganizationBase>('organization').list

  return (
    <KToolTip
      title="form.fields.tailoredOrganizationTooltip"
      placement="top-start"
    >
      <KAutocompleteArrayInput
        {...props}
        source="organizations"
        choices={organizations.map((e) => ({
          id: e.id,
          name: `${e.name} - ${e.responsibleFullName}`
        }))}
        defaultValue={[]}
      />
    </KToolTip>
  )
}

export default OrganizationAutocompleteArrayInput
