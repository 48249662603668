import { Cast } from '@mui/icons-material'
import VirtualExperienceEdit from 'resources/catalog/virtualexperiences/product/VirtualExperiencesEdit'
import VirtualExperienceCreate from 'resources/catalog/virtualexperiences/product/VirtualExperienceCreate'
import VirtualExperienceList from 'resources/catalog/virtualexperiences/product/VirtualExperiencesList'

const virtualExperienceIndex = {
  icon: Cast,
  list: VirtualExperienceList,
  edit: VirtualExperienceEdit,
  create: VirtualExperienceCreate
}

export default virtualExperienceIndex
