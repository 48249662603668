import KAccordion from 'components/UI/Accordions/KAccordion/KAccordion'
import KHeader from 'components/UI/KHeader'
import React from 'react'
import { PaymentConfig, RAFormFC } from 'types/common'
import { styled } from '@mui/material'
import { useForm } from 'hooks/useForm'
import PaymentConfigurationBaseInput from 'resources/crm/clients/organization/OrganizationCheckoutPersonalization/PaymentConfigurationBaseInput'

const OrganizationCheckoutPersonalization: RAFormFC = () => {
  const { getSource } = useForm<PaymentConfig>('paymentConfiguration')

  const SubTitle = styled('div')(() => ({
    marginBottom: '3em'
  }))

  return (
    <KAccordion accordionTitle="form.headers.paymentMethodConfigurations">
      <KHeader title="form.headers.checkoutPersonalization" headingLevel="h4" />
      <SubTitle>
        Here you can set the name, the title and the description of all the
        payment methods. You can also determine if deactivate the bank transfer
        and/or the credit card method.
      </SubTitle>

      <PaymentConfigurationBaseInput
        source={getSource('bankTransfer')}
        title="Bank Transfer"
        showDisabledToggle
      />
      <hr />
      <PaymentConfigurationBaseInput
        source={getSource('card')}
        title="Credit Card"
        showDisabledToggle
      />
      <hr />
      <PaymentConfigurationBaseInput
        source={getSource('organizationBudget')}
        title="Company Budget"
      />
    </KAccordion>
  )
}

export default OrganizationCheckoutPersonalization
