import { EventItem } from 'services/api/entities/events/types/internal/event-models'
import { useKRecordContext } from 'hooks/useKRecordContext'
import { useTranslate } from 'ra-core'
import { useMemo } from 'react'

export const useEventApprovalStatus = () => {
  const record = useKRecordContext<EventItem>()
  const translate = useTranslate()

  if (!record) return null

  const tooltipLabel = useMemo(
    () =>
      `${translate('tooltip.label.amountOfGrantedApprovals')} ${
        record.approverEmails.length
      } / ${record.pendingApproverEmails.length}`,
    [record.approverEmails.length, record.pendingApproverEmails.length]
  )

  const showTooltip = useMemo(
    () =>
      record.eventStatus === 'pendingApproval' &&
      record.pendingApproverEmails.length > 0,
    [record.eventStatus, record.pendingApproverEmails.length]
  )

  return {
    tooltipLabel,
    showTooltip
  }
}
