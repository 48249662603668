import { FILTER_SOURCES } from '@kampaay/common'
import { Grid } from '@mui/material'
import KDateField from 'components/Fields/KDateField'
import CustomReferenceInput from 'components/Inputs/CustomReferenceInput'
import LocalizedAutocompleteInput from 'components/Inputs/Localized/LocalizedAutocompleteInput'
import { useLoggedUser } from 'contexts/loggedUserContext/useLoggedUser'
import { useForm } from 'hooks/useForm'
import React from 'react'
import { Labeled, useGetOne } from 'react-admin'

type Props = {
  customerId?: number
}

const EventBudgetInput: React.FC<Props> = ({ customerId }) => {
  const { useGetValue, getSource } = useForm<{ budgetId?: number }>(undefined)
  const { loggedUserRole } = useLoggedUser()

  const budgetId = useGetValue(getSource('budgetId'))
  const { data: budgetData } = useGetOne('organizationbudget', {
    id: budgetId
  })

  if (
    !loggedUserRole ||
    !['admin', 'accountExecutive', 'customerSuccess'].includes(loggedUserRole)
  )
    return null

  return (
    <Grid container alignItems="center" spacing={2} width="100%">
      <Grid item xs={3}>
        <CustomReferenceInput
          entity="organizationbudget"
          queryFilter={{
            [FILTER_SOURCES.customerId.eq]: customerId
          }}
        >
          <LocalizedAutocompleteInput
            source={getSource('budgetId')}
            label="form.fields.budget"
          />
        </CustomReferenceInput>
      </Grid>

      {budgetId && budgetData && (
        <>
          <Grid item xs={1}>
            <Labeled>
              <KDateField
                record={budgetData}
                source="startDate"
                label="form.fields.startDate"
              />
            </Labeled>
          </Grid>
          <Grid item xs={1}>
            <Labeled>
              <KDateField
                record={budgetData}
                source="endDate"
                label="form.fields.endDate"
              />
            </Labeled>
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default EventBudgetInput
