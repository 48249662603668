import React from 'react'
import SectionWrapper from 'components/UI/SectionWrapper'
import KEdit from 'components/Forms/KEdit/KEdit'
import KTabbedForm from 'components/Forms/KForms/KTabbedForm'
import OrganizationConfigurationTab from 'components/FormComponents/Organization/General/OrganizationConfigurationTab'
import OrganizationCheckoutTab from 'components/FormComponents/Organization/General/OrganizationCheckoutTab'
import OrganizationMenuWrapper from 'components/FormComponents/Organization/OrganizationMenuWrapper'

const OrganizationEdit: React.FC = () => {
  return (
    <SectionWrapper>
      <KEdit>
        <OrganizationMenuWrapper hasPadding>
          <KTabbedForm>
            <OrganizationConfigurationTab />
            <OrganizationCheckoutTab />
          </KTabbedForm>
        </OrganizationMenuWrapper>
      </KEdit>
    </SectionWrapper>
  )
}

export default OrganizationEdit
