import React, { useEffect } from 'react'
import {
  RedirectionSideEffect,
  SimpleForm,
  useNotify,
  useTranslate
} from 'react-admin'
import { SimpleFormProps } from 'ra-ui-materialui'
import { useFormVS } from 'hooks/useFormVS'
import ReactHookDevtool from 'components/DebugUtils/ReactHookDevtool'
import ValidationErrorLogger from 'components/DebugUtils/ValidationErrorLogger'
import { useFormState } from 'react-hook-form'
import { buildErrorMessage, getFirstError } from 'services/utils/validation'
import KDefaultFormToolbar from 'components/Toolbars/KDefaultFormToolbar'
import { yupResolver } from '@hookform/resolvers/yup'
import KFormContextProvider from 'contexts/kform-context/KFormContextProvider'
import { isProduction } from 'services/env'

export const KErrorNotifier: React.FC = React.memo(() => {
  const notify = useNotify()
  const { errors, submitCount } = useFormState()
  const trans = useTranslate()

  useEffect(() => {
    // const firstErrorName = Object.keys(errors)[0]
    const firstErr = getFirstError(errors)
    if (firstErr) {
      // RA calls notify by default when a validation error occurs
      // see: https://github.com/Kampaay/react-admin/blob/03f081cc2605e398773df8865e045f816cc24df4/packages/ra-core/ form/useNotifyIsFormInvalid.ts#L26
      // since we are not going to change RA source code and there's no way to override that code,
      // we are overriding that notification with a new one that will be triggered after it just by adding a delay.
      // as a result the first one is not even visible anymore
      queueMicrotask(() =>
        notify(buildErrorMessage(firstErr, trans), {
          type: 'warning',
          multiLine: true,
          autoHideDuration: 10000
        })
      )

      // Sometimes the error has a HTMLInputElement as ref, in that case we focus on it (I guess just when we set input-level validation).
      // We can find a way to couple yup with the form templates so that we always have a HTMLInputElement ref.
      firstErr.ref?.focus?.()
    }
  }, [errors, submitCount, notify, trans])

  return <></>
})

type KSimpleFormProps = Pick<
  SimpleFormProps,
  'toolbar' | 'children' | 'record' | 'className' | 'sx'
> & {
  redirectAfterCreate?: RedirectionSideEffect
  redirectAfterDelete?: RedirectionSideEffect
}

/**
 * This is the wrapper of react-admin's SimpleForm.
 * We should always use this component instead of SimpleForm because provides more functionalities and resolver.
 * @param children
 * @param rest
 */
export const KSimpleForm: React.FC<KSimpleFormProps> = ({
  children,
  redirectAfterCreate,
  redirectAfterDelete,
  toolbar = (
    <KDefaultFormToolbar
      redirectAfterCreate={redirectAfterCreate}
      redirectAfterDelete={redirectAfterDelete}
    />
  ),
  ...restProps
}) => {
  const schema = useFormVS()

  return (
    <SimpleForm
      toolbar={toolbar}
      resolver={schema && yupResolver(schema)}
      warnWhenUnsavedChanges={isProduction}
      {...restProps}
    >
      <KFormContextProvider schema={schema}>
        <ReactHookDevtool />
        <ValidationErrorLogger />
        <KErrorNotifier />
        {children}
      </KFormContextProvider>
    </SimpleForm>
  )
}
