import React, { useEffect } from 'react'
import KSelectInput from 'components/Inputs/KInputs/KSelectInput'
import { RAFormFC } from 'types/common'
import { useForm } from 'hooks/useForm'
import KTextInput from 'components/Inputs/KInputs/KTextInput/KTextInput'
import KArrayInput from 'components/Inputs/KInputs/KArrayInput'
import { SimpleFormIterator } from 'react-admin'
import {
  EmailApprovalType,
  OrganizationApproverConfig,
  TypedChoices
} from '@kampaay/common'

const ApproverOptionInput: RAFormFC<{
  approvalChoices: TypedChoices<EmailApprovalType>
}> = ({ source, approvalChoices }) => {
  const { getSource, useGetValue, useSetValue } =
    useForm<OrganizationApproverConfig>(source)

  const setOptions = useSetValue('approverOptions')
  const approvalType = useGetValue('approvalType')

  useEffect(() => {
    if (approvalType === 'freeText') {
      setOptions([])
    }
  }, [approvalType])

  return (
    <>
      <KSelectInput
        source={getSource('approvalType')}
        label="form.fields.organizationApprovalType"
        choices={approvalChoices}
        defaultValue={approvalChoices[0].id}
      />

      {approvalType === 'pickList' && (
        <KArrayInput source={getSource('approverOptions')}>
          <SimpleFormIterator inline>
            <KTextInput source="email" label="form.fields.email" />

            <KTextInput source="name" label="form.fields.name" />
          </SimpleFormIterator>
        </KArrayInput>
      )}

      {approvalType === 'groupAdmin' && (
        <>
          <KArrayInput source={getSource('fallbackApprovers')}>
            <SimpleFormIterator inline>
              <KTextInput source="" label="form.fields.email" />
            </SimpleFormIterator>
          </KArrayInput>

          <KArrayInput source={getSource('fallbackCCs')}>
            <SimpleFormIterator inline>
              <KTextInput source="" label="form.fields.email" />
            </SimpleFormIterator>
          </KArrayInput>
        </>
      )}
    </>
  )
}

export default ApproverOptionInput
