import { BaseProduct } from 'services/api/entities/catalog/baseProduct/types/internal/baseProduct-models'
import {
  formatMainProduct,
  MainProductCSV
} from 'services/csv/entities/catalog/common/mainProduct'
import {
  formatStandardPricing,
  StandardPricingCSV
} from 'services/csv/entities/catalog/common/standardPricing'

type BaseProductCSV = MainProductCSV & {
  Pricing: StandardPricingCSV[]
}

export const formatBaseProduct = ({
  pricing,
  ...rest
}: BaseProduct): BaseProductCSV => ({
  ...formatMainProduct(rest),
  Pricing: pricing.map(formatStandardPricing)
})
