import { styled } from '@mui/material/styles'
import React from 'react'
import { useTranslate } from 'react-admin'
import { Location } from 'services/api/entities/catalog/location/types/internal/location-models'
import { LocationSelection } from 'services/api/entities/events/types/internal/selections'
import EventUpsellingSelectionInput from 'components/FormComponents/EventUpsellingSelectionInput'
import { SelectionDetailsProps } from 'components/FormComponents/MainProductSelectionInputContent'
import KSelectInput from 'components/Inputs/KInputs/KSelectInput'
import TimePicker from 'components/Inputs/TimePicker'
import { getLocationArrangementChoices } from 'resources/operations/events/utils'

const PREFIX = 'LocationSelectionDetailsForm'

const classes = {
  defaultWidth: `${PREFIX}-defaultWidth`
}

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')({
  [`& .${classes.defaultWidth}`]: {
    width: '256px'
  }
})

type Props = SelectionDetailsProps<Location, LocationSelection>

const LocationSelectionDetailsFormContent: React.FC<Props> = ({
  scopedFormData,
  selectedProduct,
  getSource,
  isObsolete
}) => {
  const translate = useTranslate()

  if (!scopedFormData || !getSource) return null

  const arrangementChoices = getLocationArrangementChoices(
    translate,
    scopedFormData,
    selectedProduct
  )

  return (
    <Root>
      <TimePicker
        source={getSource('bookedFrom')}
        label="Start Date"
        disabled={isObsolete}
      />
      <TimePicker
        source={getSource('bookedTo')}
        label="End Date"
        disabled={isObsolete}
      />
      <KSelectInput
        source={getSource('arrangementName')}
        label="Arrangement"
        choices={arrangementChoices}
        disabled={isObsolete}
        className={classes.defaultWidth}
      />
      <EventUpsellingSelectionInput
        source={getSource('upsellingSelection')}
        disableInputs={isObsolete}
        selectedProduct={selectedProduct}
        upsellingSelection={scopedFormData.upsellingSelection}
      />
    </Root>
  )
}

export default LocationSelectionDetailsFormContent
