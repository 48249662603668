import React from 'react'
import LocalizedTextInput from 'components/Inputs/Localized/LocalizedTextInput'

type AreaFormContentProps = {}

const AreaFormContent: React.FC<AreaFormContentProps> = () => {
  return <LocalizedTextInput source="name" label="form.fields.name" />
}

export default AreaFormContent
