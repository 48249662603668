import { GetListParams, RaRecord, useInfiniteGetList } from 'ra-core'
import { APIEntity } from 'services/api/entities'
import { initializeListParams } from 'services/utils'
import { useQueryClient } from 'react-query'
import { useEffect } from 'react'

type Params = Partial<Omit<GetListParams, 'pagination'>>

export const buildKGetListParams = (params: Params = {}) => {
  return initializeListParams({
    ...params,
    pagination: {
      page: 1,
      perPage: 250
    }
  })
}

export const useKGetList = <T extends RaRecord>(
  resource: APIEntity,
  params: Params = {}
) => {
  const queryClient = useQueryClient()

  const finalParams = buildKGetListParams(params)

  /**
   * React Admin's getList hook caches a `getOne` query for every element in the list response
   * This will make the edit views use the list model and not re-fetch the detail model, which is not always the same of the list model
   * Thus here we override that behavior by deleting all getOne cached elements
   */
  const clearDetailCache = () => queryClient.removeQueries([resource, 'getOne'])

  const queryOptions = {
    onSuccess: clearDetailCache
  }

  const { data, fetchNextPage, hasNextPage, isFetching, total, refetch } =
    useInfiniteGetList<T>(resource, finalParams, queryOptions)

  useEffect(() => {
    if (!isFetching && hasNextPage) {
      fetchNextPage()
    }
  }, [hasNextPage, fetchNextPage, isFetching])

  return {
    list: data?.pages?.flatMap((p) => p.data) ?? [],
    isLoading: isFetching,
    total,
    refetch
  }
}
