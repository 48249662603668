import React from 'react'
import { styled } from '@mui/material/styles'
import { RaRecord } from 'react-admin'
import {
  pricingRulesModeChoices,
  locationPricingNameChoices
} from 'services/cfg/form'
import KToolTip from 'components/UI/KTooltip'
import HalfStepNumberInput from 'components/Inputs/HalfStepNumberInput/HalfStepNumberInput'
import TimePicker from 'components/Inputs/TimePicker'
import KSelectInput from 'components/Inputs/KInputs/KSelectInput'
import { RAFormFC } from 'types/common'
import { useForm } from 'hooks/useForm'
import { useGetIndex } from 'hooks/useGetIndex'
import { LocationRule, PricingRuleMode } from '@kampaay/common'

const PREFIX = 'PricingRuleIfClauseInputGroup'

const classes = {
  flex: `${PREFIX}-flex`,
  fieldWidth: `${PREFIX}-fieldWidth`,
  halfFieldWidth: `${PREFIX}-halfFieldWidth`,
  clauseText: `${PREFIX}-clauseText`
}

const StyledDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '100px',
  width: '100%',

  [`& .${classes.flex}`]: {
    display: 'flex'
  },

  [`& .${classes.fieldWidth}`]: {
    width: '300px'
  },

  [`& .${classes.halfFieldWidth}`]: {
    width: '150px'
  },

  [`& .${classes.clauseText}`]: {
    fontSize: '20px',
    fontWeight: 'bold',
    width: '10px',
    marginBottom: '20px'
  }
}))

type Props = {
  firstNHRIndex: number | undefined
}

const PricingRuleIfClauseInputGroup: RAFormFC<Props> = React.memo(
  ({ source, firstNHRIndex }) => {
    const { getSource, useGetValue, useSetValue } =
      useForm<LocationRule>(source)

    const index = useGetIndex(source)

    const mode = useGetValue('mode')
    const atLeast = useGetValue('atLeast')
    const setAtLeast = useSetValue('atLeast')
    const setHoursFrom = useSetValue('hoursFrom')
    const setHoursTo = useSetValue('hoursTo')

    const isFirstNHR = firstNHRIndex === index

    // this is not a default value because if I delete the first one
    // and I have another already set this one don't use the default value
    if (isFirstNHR && atLeast !== 0.5) setAtLeast(0.5)

    const resetMode = ({
      target: { value }
    }: React.ChangeEvent<{ value: any }> | RaRecord) => {
      const newMode = value as PricingRuleMode

      if (newMode === 'nhr') {
        setHoursFrom(undefined)
        setHoursTo(undefined)
      } else {
        setAtLeast(undefined)
      }
    }

    return (
      <StyledDiv>
        <span className={classes.clauseText}>IF</span>
        <KSelectInput
          label="form.fields.ruleWhat"
          source={getSource('mode')}
          defaultValue={pricingRulesModeChoices[1].id} // 'nhr'
          choices={pricingRulesModeChoices}
          className={classes.fieldWidth}
          onChange={resetMode}
        />

        <span className={classes.clauseText}>IS</span>
        {mode === 'tpr' ? (
          <div className={classes.flex}>
            <TimePicker
              source={getSource('hoursFrom')}
              label="From"
              className={classes.halfFieldWidth}
            />
            <TimePicker
              source={getSource('hoursTo')}
              label="To"
              className={classes.halfFieldWidth}
              style={{ marginLeft: '4px' }}
            />
          </div>
        ) : (
          <HalfStepNumberInput
            source={getSource('atLeast')}
            label="form.fields.moreThanHours"
            disabled={isFirstNHR}
            className={classes.fieldWidth}
          />
        )}
        <KToolTip title="tooltip.pricingRule.pricingName" placement="top">
          <KSelectInput
            source={getSource('name')}
            label="form.fields.pricingName"
            choices={locationPricingNameChoices}
            className={classes.fieldWidth}
            emptyText="None"
          />
        </KToolTip>
      </StyledDiv>
    )
  }
)

export default PricingRuleIfClauseInputGroup
