import React from 'react'
import { ArrayField, SingleFieldList } from 'react-admin'
import { Drink } from 'services/api/entities/catalog/drink/types/internal/drink-models'
import { useKRecordContext } from 'hooks/useKRecordContext'
import LocalizedChipField from 'components/Fields/Localized/LocalizedChipField'

const DrinkVariantsListPart: React.FC = (props) => {
  // #ra4Migration check if wtill works as expected
  const record = useKRecordContext<Drink>()

  const parsedDrinkRecord = record?.hasMultipleVariants
    ? record
    : { ...record!, variants: [] }
  return (
    <ArrayField
      {...props}
      record={parsedDrinkRecord}
      source="variants"
      sortable={false}
    >
      <SingleFieldList linkType={false}>
        <LocalizedChipField source="name" />
      </SingleFieldList>
    </ArrayField>
  )
}

export default DrinkVariantsListPart
