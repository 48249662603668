import { createMockBuilder } from '../../../../utils'
import type { APIFileInfo } from '../types'

const buildBaseMock = (): APIFileInfo => ({
  fileName: 'file-name',
  id: '1',
  url: 'url',
  createdAt: '2024-09-24'
})

export const buildAPIFileInfoMock = createMockBuilder(buildBaseMock)
