import React from 'react'
import { FacetSelectInput } from 'components/Inputs/Facets/BaseComponents/FacetSelectInput'
import ExperienceFacetSection from 'components/Inputs/Facets/FacetFormSections/ExperienceFacetSection'

const VirtualFacetSection: React.FC = () => (
  <>
    <ExperienceFacetSection />
    <FacetSelectInput
      source="virtualCategoryFacet"
      facetCategory="productFilter"
      filterGroup="VirtualCategory"
    />
  </>
)

export default VirtualFacetSection
