import { keys, reverseMap } from '../../../utils'
import { parseFileInfo, parseMultipleFiles } from '../fileinfo'
import { parseGeocodedAddress } from '../geo-coded-addresses'
import { parseMultipleImagesWithDescription } from '../images-with-description'
import type {
  APIDocumentExtractedService,
  APISupplier,
  DocumentExtractedService,
  RoomInfoModel,
  Supplier
} from './types'
import type {
  APIRoomInfoModel,
  LocationArrangementType
} from './types/api/common'
import { SUPPLIER_SERVICE_NAMES_MAP } from './utils'

const API_TO_INNER_SERVICE_NAMES_MAP = reverseMap(SUPPLIER_SERVICE_NAMES_MAP)

const convertAPISupplierToSuppliedServices = (data: APISupplier) =>
  keys(API_TO_INNER_SERVICE_NAMES_MAP)
    .map((apiServiceName) => ({
      products: data[apiServiceName] ?? [],
      service: API_TO_INNER_SERVICE_NAMES_MAP[apiServiceName]
    }))
    .filter((binding) => !!binding.products.length)

export const parseSupplierRoomInfo = ({
  arrangementStructure,
  ...room
}: APIRoomInfoModel): RoomInfoModel => ({
  ...room,
  arrangementStructure: arrangementStructure
    ? Object.entries(arrangementStructure).map(
        ([arrangementType, capacity]) => ({
          arrangementType: arrangementType as LocationArrangementType,
          capacity
        })
      )
    : []
})

const parseDocumentItem = (
  service: APIDocumentExtractedService
): DocumentExtractedService => ({
  ...service,
  documents: service.documents.map(({ document, pages }) => ({
    document: parseFileInfo(document)!,
    page: pages.at(0)
  }))
})

const parseAIFilledProperty = (prop: string): string => {
  const propMap: Record<string, string> = {
    supplierTypeId: 'supplierType'
  }
  return propMap[prop] || prop
}

export const parseSupplier = (data: APISupplier): Supplier => {
  const {
    images,
    documents,
    geoCodedAddress,
    additionalEmails,
    documentItems,
    aiFilledProperties
  } = data

  const parsedImages = images ? parseMultipleImagesWithDescription(images) : []
  const parsedDocuments = documents ? parseMultipleFiles(documents) : []

  const rooms = data.rooms?.map(parseSupplierRoomInfo)

  const aiProperties = (aiFilledProperties ?? []).map(parseAIFilledProperty)
  return {
    ...data,
    rooms,
    suppliedServicesProducts: convertAPISupplierToSuppliedServices(data),
    images: parsedImages,
    documents: parsedDocuments,
    geoCodedAddress: parseGeocodedAddress(geoCodedAddress),
    additionalEmails: additionalEmails ?? [],
    services: data.services ?? [],
    documentItems: (documentItems ?? []).map(parseDocumentItem),
    aiFilledProperties: {
      initialProperties: aiProperties,
      remainingProperties: aiProperties
    }
  }
}
