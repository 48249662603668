import { ListAlt } from '@mui/icons-material'
import DrinkSectionList from 'resources/catalog/drink/sections/DrinkSectionList'
import DrinkSectionCreate from 'resources/catalog/drink/sections/DrinkSectionCreate'
import DrinkSectionEdit from 'resources/catalog/drink/sections/DrinkSectionEdit'

const drinkSectionIndex = {
  icon: ListAlt,
  list: DrinkSectionList,
  edit: DrinkSectionEdit,
  create: DrinkSectionCreate
}

export default drinkSectionIndex
