import React from 'react'
import EventServiceSection from 'resources/operations/events/EventsComponents/EventServiceSection'
import ExperienceFormTab from 'resources/operations/events/eventsFormTabs/ExperienceTab/ExperienceFormTab'
import { RAFormFC } from 'types/common'
import { useForm } from 'hooks/useForm'
import { MappedEnrichedEvent } from 'services/api/entities/events/types/internal/event-models'
import { FormTabProps } from 'react-admin'

type Props = Omit<FormTabProps, 'label'>

const PhysicalFormTab: RAFormFC<Props> = ({ source, ...rest }) => {
  const { getSource } = useForm<MappedEnrichedEvent>(source)
  return (
    <ExperienceFormTab
      {...rest}
      experience="physicals"
      experienceService="physicalService"
    >
      <EventServiceSection
        source={getSource('productServices.physicalService')}
        serviceName={'physicals'}
      />
    </ExperienceFormTab>
  )
}

export default PhysicalFormTab
