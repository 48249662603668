import { styled } from '@mui/material/styles'
import React from 'react'
import { useTranslate } from 'react-admin'
import { virtualExpChoices } from 'services/cfg/form'
import KSelectInput from 'components/Inputs/KInputs/KSelectInput'
import KToolTip from 'components/UI/KTooltip'

const PREFIX = 'VirtualExpSpecialTypeInput'

const classes = {
  tooltip: `${PREFIX}-tooltip`
}

const Root = styled('div')(({ theme }: any) => ({
  [`& .${classes.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 16,
    border: '1px solid black'
  }
}))

const VirtualExpSpecialTypeInput = () => {
  const translate = useTranslate()

  return (
    <Root>
      <KToolTip
        title={translate('form.fields.virtualExpSpecialTypeTooltip')}
        placement="right-end"
        classes={{
          tooltip: classes.tooltip
        }}
      >
        <KSelectInput
          source="specialType"
          choices={virtualExpChoices}
          style={{ width: '250px' }}
        />
      </KToolTip>
    </Root>
  )
}

export default VirtualExpSpecialTypeInput
