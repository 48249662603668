/**
 * Applies the step to a number
 * @example
 * applyStep(1.7, 0.5) // 2
 * applyStep(1.7, 1)   // 2
 * applyStep(1.7, 0.1) // 1.7
 * @param value
 * @param step
 */
const applyStep = (value: number, step: number): number =>
  Math.ceil(value / step) * step

/**
 * This function converts empty string to undefined
 * And applies the step to the string converted to number
 * @param value
 * @param step
 */
export const parseKNumberInputValue = (
  value: string | undefined,
  step: number | undefined
): Number | undefined => {
  if (value === undefined || value === '') {
    return undefined
  }

  if (step === undefined) {
    return Number(value)
  }

  return applyStep(Number(value), step)
}
