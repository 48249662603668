import * as yup from 'yup'
import Lazy from 'yup/lib/Lazy'
import { AnyObject } from 'yup/lib/types'

/**
 * A wrapper of the yup.array() meant to be used for simple validations, not needing to pass the context.
 * It accepts the schema of the items contained in the array as a parameter, and validates the items according to that schema.
 *
 * @param type the schema of the items in the content of the array
 * @returns a yup.ArraySchema with correctly inferred items schema type
 */
export const yupArray = <
  TSchema extends yup.AnySchema | Lazy<any, any> = yup.AnySchema,
  TContext extends AnyObject = AnyObject
>(
  type?: TSchema
) => new yup.ArraySchema<TSchema, TContext, yup.Asserts<TSchema>[]>(type)

/**
 * Enhances yupArray with an upfront invocation to pass the context type.
 * This is a workaround to enable us to NOT have to pass the TSchema parameter type,
 * and have it inferred from the type of the param of the second function call
 *
 * Example: `yupArrayWithContext<ContextType>()(ContentSchema)` --> the TSchema is correctly inferred from "ContentSchema"
 *
 * @returns a yup.ArraySchema with correctly inferred types
 */
export const yupArrayWithContext =
  <TContext extends AnyObject>() =>
  <TSchema extends yup.AnySchema | Lazy<any, any> = yup.AnySchema>(
    type?: TSchema
  ) =>
    yupArray<TSchema, TContext>(type)
