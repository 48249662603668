import { createMockBuilder } from '../../../../utils'
import type { ImageFileInfo } from '../../images-with-description'

const buildBaseMock = (): ImageFileInfo => ({
  title: 'Image',
  createdAt: new Date('2024-09-24'),
  src: 'https://example.com/image.jpg'
})

export const buildFileInfoMock = createMockBuilder(buildBaseMock)
