import { TextField } from '@mui/material'
import { KInputProps, useKInput } from 'hooks/useKInput/useKInput'
import React, {
  ChangeEvent,
  useState,
  useEffect,
  useRef,
  useCallback
} from 'react'
import { TextInputProps } from 'react-admin'

// format is not needed here because since we are keeping in sync the value of the field with the value directly from the field's value
// we do not need to transform it back from the form state to the field value since the form state never gets set as value of the field.
export type KTextInputProps = Omit<TextInputProps, 'format'> &
  KInputProps & {
    isPlainField?: boolean
    valueWhenField?: string | number
  }

/**
 * This is a wrapper of react-admin's TextInput.
 * The aim of this component is to keep the functionality of TextInput with undefined allowed
 */
const KTextInput: React.FC<KTextInputProps> = ({
  'data-testid': dataTestId,
  ...props
}) => {
  const {
    type,
    className,
    multiline,
    rows,
    disabled,
    style,
    fullWidth,
    valueWhenField,
    defaultValue,
    isPlainField = false,
    format = (v) => v
  } = props

  const {
    field: { value: formInputValue, onChange: formOnChange, ...fieldRest },
    k: { hasError, helperText, variant, sx, label },
    isRequired
  } = useKInput(props)

  const isUpdatingInternallyRef = useRef(false)

  /**
   * This state is used to keep the value of the field itself. The TextField does not support nullish values, therefore we need to always feed an empty string to it
   * But we want to have the form state to be undefined instead when the field is empty.
   * The field is going to be using the localFieldValue state, while the form state is going to be using the formInputValue state, updated with the formOnChange method.
   *
   * When there is a value from the record we want the state to initialize as that value. If there is no value, yet we want to use the default value that we assign the input.
   */
  const [localFieldValue, setLocalFieldValue] = useState(
    formInputValue || defaultValue || ''
  )

  useEffect(() => {
    if (isUpdatingInternallyRef.current) {
      // We don't want to react to the form's state updates when the input value is changed by the user through the input itself,
      // we only want to react to form state changes due to external factors
      isUpdatingInternallyRef.current = false
      return
    } else {
      // When the input value is changed by an external agent we need to sync the local state of the field with the new value
      setLocalFieldValue(format(formInputValue ?? ''))
    }
  }, [formInputValue])

  /**
   * This method overrides the default onChange method of the TextField.
   * It turns any empty string value to undefined when committing the update to the form state.
   *
   * Also, it keeps the local state of the field in sync with the updates and never allows the value of it to be set as undefined.
   */
  const onChange = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      isUpdatingInternallyRef.current = true
      if (value === '') {
        formOnChange(undefined)
        // here we need to apply the format function so that the transformation is applied to the local state of the input that we pass to it
        setLocalFieldValue(format(''))
      } else {
        formOnChange(value)
        // here we need to apply the format function so that the transformation is applied to the local state of the input that we pass to it
        setLocalFieldValue(format(value))
      }
    },
    [format, formOnChange]
  )

  return (
    <TextField
      label={label}
      type={type}
      className={className}
      required={isRequired}
      multiline={multiline}
      rows={rows}
      disabled={isPlainField ? true : disabled}
      style={style}
      error={hasError}
      fullWidth={fullWidth}
      inputProps={{
        'data-testid': dataTestId
      }}
      variant={variant}
      sx={sx}
      helperText={helperText}
      value={isPlainField ? valueWhenField : localFieldValue}
      onChange={onChange}
      {...fieldRest}
    />
  )
}

export default KTextInput
