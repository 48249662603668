import React from 'react'
import FacetGroup from 'components/Inputs/Facets/Wrappers/FacetGroup'
import PricingFacet from 'components/Inputs/Facets/Wrappers/PricingFacet'

const UpsellingFacetSection: React.FC = () => {
  return (
    <>
      <FacetGroup />
      <PricingFacet initialValue="perPerson" />
    </>
  )
}

export default UpsellingFacetSection
