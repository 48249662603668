import { Sell } from '@mui/icons-material'
import FacetList from 'resources/facets/FacetList'
import FacetCreate from 'resources/facets/FacetCreate'
import FacetEdit from 'resources/facets/FacetEdit'

const facetsIndex = {
  icon: Sell,
  list: FacetList,
  edit: FacetEdit,
  create: FacetCreate
}

export default facetsIndex
