import React from 'react'
import { FileField, FileFieldProps } from 'react-admin'
import { httpClient } from 'services/api/dataProvider/common'

const BE_BASE_URL = process.env.REACT_APP_API_BASE_URL!.replace('/admin', '')

const getPresignedUrl = async (file: string) => {
  const response = await httpClient(`${BE_BASE_URL}/docs/presignedurl`, {
    method: 'POST',
    body: JSON.stringify({
      documentId: file
    })
  })

  return response.body
}

const openFile = async (file: string) => {
  const url = await getPresignedUrl(file)
  window.open(url, '_blank')
}

export function KFileField(props: FileFieldProps) {
  return (
    <div
      onClick={(e) => {
        const target = e.target as HTMLElement

        if (target.tagName === 'A') {
          const src = target.getAttribute('href')

          if (src?.startsWith('https://api')) {
            e.preventDefault()
            openFile(src.split('/').pop() ?? '')
          }
        }
      }}
    >
      <FileField {...props} source="src" />
    </div>
  )
}
