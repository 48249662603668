import { buildFilterConfig } from 'services/utils/filters'
import { FILTER_SOURCES } from '@kampaay/common'

export const USER_FILTERS = [
  buildFilterConfig('text', {
    source: FILTER_SOURCES.firstName.contains,
    label: 'form.fields.firstName',
    alwaysOn: true
  }),
  buildFilterConfig('text', {
    source: FILTER_SOURCES.lastName.contains,
    label: 'form.fields.lastName',
    alwaysOn: true
  })
]
