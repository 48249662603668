import React from 'react'
import { AutocompleteInputProps } from 'react-admin'
import { useLangContext } from 'contexts/langContext'
import {
  parseMultilangJSON,
  getCurrentLang,
  translateChoices
} from '@kampaay/common'
import { SelectArrayInputProps } from 'types/common'

type Props = Omit<
  AutocompleteInputProps | SelectArrayInputProps,
  'children'
> & {
  children: JSX.Element
}

const LocalizedWithChoicesInput: React.FC<Props> = ({
  choices = [],
  optionText = 'name',
  label,
  children,
  ...props
}) => {
  useLangContext()
  const currentLang = getCurrentLang()
  const translatedChoices = translateChoices(choices, optionText)
  const translatedLabel = parseMultilangJSON(label)[currentLang]
  return React.cloneElement(children, {
    ...props,
    optionText,
    label: translatedLabel,
    choices: translatedChoices
  })
}

export default LocalizedWithChoicesInput
