import { APICfg } from 'services/api/api'
import { APIBaseProduct } from 'services/api/entities/catalog/baseProduct/types/api/response-models'
import {
  ProductSection,
  ProductSectionToAPI
} from 'services/api/entities/catalog/types'
import { productSectionParse } from 'services/api/entities/catalog/utils'

type MusicSection = ProductSection<APIBaseProduct>

type MusicSectionToApi = ProductSectionToAPI

const apiCfg: APICfg = {
  endpoint: 'musicsections',
  parse: productSectionParse,
  write: async (req: MusicSection): Promise<MusicSectionToApi> => req
}
export default apiCfg
