import {
  APIEventPlaceholder,
  APILinkedConcierge,
  APILinkedEvent
} from 'services/api/entities/event-placeholder/types/api/response-models'
import {
  EventPlaceholder,
  LinkedConcierge,
  LinkedEvent
} from 'services/api/entities/event-placeholder/types/internal/event-placeholder-models'
import { APICfg } from 'services/api/api'

const parseLinkedConcierge = (
  apiModel: APILinkedConcierge
): LinkedConcierge => ({
  ...apiModel
})

const parseLinkedEvent = (apiModel: APILinkedEvent): LinkedEvent => ({
  ...apiModel,
  startDate: new Date(apiModel.startDate)
})

export const eventPlaceholderParse = (
  apiModel: APIEventPlaceholder
): EventPlaceholder => ({
  ...apiModel,
  startDate: new Date(apiModel.startDate),
  endDate: apiModel.endDate && new Date(apiModel.endDate),
  events: apiModel.events.map((e) => parseLinkedEvent(e)),
  concierge: apiModel.concierge && parseLinkedConcierge(apiModel.concierge)
})

const apiCfg = {
  endpoint: 'eventPlaceholder',
  parse: eventPlaceholderParse
} as APICfg

export default apiCfg
