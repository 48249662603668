import { uploadOrKeepMultipleFiles } from 'services/utils/files'
import { APICfg } from 'services/api/api'
import { parseSupplier, writeSupplier } from '@kampaay/common'
import { uploadOrKeepImg } from 'services/utils/images'
//=========================
// API CFG
//=========================
const apiCfg: APICfg = {
  endpoint: 'usersupplier',
  parse: parseSupplier,
  write: writeSupplier(uploadOrKeepImg, uploadOrKeepMultipleFiles)
}

export default apiCfg
