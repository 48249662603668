import React from 'react'
import { TabbedFormProps } from 'react-admin'
import LabeledNumberField from 'components/Fields/LabeledFields/LabeledNumberField'
import KTabbedForm from 'components/Forms/KForms/KTabbedForm'
import KTextInput from 'components/Inputs/KInputs/KTextInput/KTextInput'
import ConciergeEventGeneralTab from 'resources/operations/events/eventsFormTabs/ConciergeEventGeneralTab'

const EventFromConciergeEdit: React.FC<Pick<TabbedFormProps, 'toolbar'>> = ({
  toolbar
}) => {
  ///////////////////////////////
  // JSX
  ///////////////////////////////
  return (
    <KTabbedForm toolbar={toolbar}>
      <ConciergeEventGeneralTab>
        <LabeledNumberField source="id" label="form.fields.eventId" />
        <KTextInput source="name" label="form.fields.name" />
      </ConciergeEventGeneralTab>
    </KTabbedForm>
  )
}

export default EventFromConciergeEdit
