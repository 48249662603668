import { useJsApiLoader } from '@react-google-maps/api'

const LIBRARIES: any = ['places'] as const

/**
 * Centralizes the loading of the maps apis and libraries for the whole app.
 */
export const useGoogleMapsLoader = () =>
  useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? '',
    libraries: LIBRARIES
  })
