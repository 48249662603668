import React from 'react'
import { Datagrid, TextField } from 'react-admin'
import DeleteAndExportBulk from 'components/Buttons/DeleteAndExportBulk'
import KPagination from 'components/Lists/KPagination'
import KList from 'components/Lists/KLists/KList'
import SectionWrapper from 'components/UI/SectionWrapper'
import { BaseListProps } from 'components/Lists/CatalogItemBaseList'
import KDateField from 'components/Fields/KDateField'

const CustomersList: React.FC<BaseListProps> = (props) => {
  return (
    <SectionWrapper>
      <KList
        {...props}
        pagination={<KPagination />}
        perPage={40}
        exporter={false}
        sort={{ field: 'name', order: 'ASC' }}
      >
        <Datagrid rowClick="edit" bulkActionButtons={<DeleteAndExportBulk />}>
          <TextField source="name" />
          <TextField source="email" />

          <TextField
            source="membership.organizationName"
            label="form.fields.organizationName"
            sortable={false}
          />

          <KDateField
            source="createdAt"
            label="Registation Date"
            sortable={false}
          />
          <KDateField source="lastLogin" label="Last Login Date" />
        </Datagrid>
      </KList>
    </SectionWrapper>
  )
}

export default CustomersList
