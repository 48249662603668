import { isMultilangString } from '@kampaay/common'
import React from 'react'
import { useTranslate } from 'react-admin'
import { WithDataTestId } from 'test/utils'
import { WithClassName } from 'types/ui'

export type HeadingLevel = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'

export type KHeaderProps = WithDataTestId &
  WithClassName & {
    title: string
    headingLevel?: HeadingLevel
    style?: React.CSSProperties
  }

/**
 * This is a simple wrapper of text that wraps the text with given level
 * @param title the title of the text
 * @param headingLevel the level of heading
 * @param style
 * @param children
 * @param testId
 * @param className
 */
const KHeader: React.FC<KHeaderProps> = ({
  title,
  headingLevel = 'h2',
  style,
  'data-testid': testId,
  className
}) => {
  const Title = headingLevel
  const translate = useTranslate()
  const translatedTitle = isMultilangString(title) ? translate(title) : title

  return (
    <Title style={style} data-testid={testId} className={className}>
      {translatedTitle}
    </Title>
  )
}

export default KHeader
