/**
 * This method extends native js prototypes and infers defined declarations to extension methods.
 * @param target
 * @param key
 * @param extension
 */
export const extend = <
  TTarget extends { prototype: unknown },
  TKey extends keyof TTarget['prototype'],
  TValue extends TTarget['prototype'][TKey]
>(
  target: TTarget,
  key: TKey,
  extension: TValue
) => {
  Object.defineProperty(target.prototype, key, {
    writable: false,
    value: extension
  })
}

/**
 *
 * This method extends native js prototypes and infers defined declarations to extension methods.
 * It also injects the context of the caller to the extension method.
 *
 * TODO: better inner typing management (any to be removed)
 * @param target
 * @param key
 * @param extension
 */
export const extendWithCtx = <
  TTarget extends { prototype: unknown },
  TKey extends keyof TTarget['prototype'],
  TValue extends TTarget['prototype'][TKey]
>(
  target: TTarget,
  key: TKey,
  extension: (thisValue: TTarget['prototype']) => TValue
) => {
  Object.defineProperty(target.prototype, key, {
    writable: false,
    value: function (...args: any[]) {
      return (extension(this) as any)(...args)
    }
  })
}
