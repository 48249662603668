import React from 'react'
import FacetToggle from 'components/Inputs/Facets/BaseComponents/FacetToggle'

type PricingFacetProps = {
  initialValue?: string
}

const PricingFacet: React.FC<PricingFacetProps> = ({ initialValue }) => (
  <FacetToggle
    facetCategory="pricing"
    source="pricingFacet"
    initialValue={initialValue}
  />
)

export default PricingFacet
