import React, { useState } from 'react'
import { ListItem, ListItemIcon, ListItemText, Switch } from '@mui/material'
import { Flag } from '@mui/icons-material'
import {
  FeatureFlagConfig,
  FeatureFlag as FeatureFlagType,
  toggleFeatureFlag
} from '@kampaay/common'

type Props = {
  featureFlagKey: FeatureFlagType
  config: FeatureFlagConfig
}

const FeatureFlag: React.FC<Props> = ({ featureFlagKey, config }) => {
  const [isEnabled, setEnabled] = useState<boolean>(
    localStorage.getItem(`kFeat_${featureFlagKey}`) === 'true'
  )

  return (
    <ListItem>
      <ListItemIcon>
        <Flag />
      </ListItemIcon>
      <ListItemText primary={config.title} secondary={config.description} />
      <Switch
        edge="end"
        checked={isEnabled}
        onChange={() => {
          toggleFeatureFlag(featureFlagKey)
          setEnabled(!isEnabled)
        }}
      />
    </ListItem>
  )
}

export default FeatureFlag
