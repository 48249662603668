export const COUNTRIES = [
  { code: 'AD', countryName: 'Andorra' },
  { code: 'AE', countryName: 'United Arab Emirates' },
  { code: 'AF', countryName: 'Afghanistan' },
  { code: 'AG', countryName: 'Antigua and Barbuda' },
  { code: 'AI', countryName: 'Anguilla' },
  { code: 'AL', countryName: 'Albania' },
  { code: 'AM', countryName: 'Armenia' },
  { code: 'AO', countryName: 'Angola' },
  { code: 'AQ', countryName: 'Antarctica' },
  { code: 'AR', countryName: 'Argentina' },
  { code: 'AS', countryName: 'American Samoa' },
  { code: 'AT', countryName: 'Austria' },
  { code: 'AU', countryName: 'Australia' },
  { code: 'AW', countryName: 'Aruba' },
  { code: 'AX', countryName: 'Åland Islands' },
  { code: 'AZ', countryName: 'Azerbaijan' },
  { code: 'BA', countryName: 'Bosnia and Herzegovina' },
  { code: 'BB', countryName: 'Barbados' },
  { code: 'BD', countryName: 'Bangladesh' },
  { code: 'BE', countryName: 'Belgium' },
  { code: 'BF', countryName: 'Burkina Faso' },
  { code: 'BG', countryName: 'Bulgaria' },
  { code: 'BH', countryName: 'Bahrain' },
  { code: 'BI', countryName: 'Burundi' },
  { code: 'BJ', countryName: 'Benin' },
  { code: 'BL', countryName: 'Saint Barthélemy' },
  { code: 'BM', countryName: 'Bermuda' },
  { code: 'BN', countryName: 'Brunei Darussalam' },
  { code: 'BO', countryName: 'Bolivia (Plurinational State of)' },
  { code: 'BQ', countryName: 'Bonaire, Sint Eustatius and Saba' },
  { code: 'BR', countryName: 'Brazil' },
  { code: 'BS', countryName: 'Bahamas' },
  { code: 'BT', countryName: 'Bhutan' },
  { code: 'BV', countryName: 'Bouvet Island' },
  { code: 'BW', countryName: 'Botswana' },
  { code: 'BY', countryName: 'Belarus' },
  { code: 'BZ', countryName: 'Belize' },
  { code: 'CA', countryName: 'Canada' },
  { code: 'CC', countryName: 'Cocos (Keeling) Islands' },
  { code: 'CD', countryName: 'Congo, Democratic Republic of the' },
  { code: 'CF', countryName: 'Central African Republic' },
  { code: 'CG', countryName: 'Congo' },
  { code: 'CH', countryName: 'Switzerland' },
  { code: 'CI', countryName: "Côte d'Ivoire" },
  { code: 'CK', countryName: 'Cook Islands' },
  { code: 'CL', countryName: 'Chile' },
  { code: 'CM', countryName: 'Cameroon' },
  { code: 'CN', countryName: 'China' },
  { code: 'CO', countryName: 'Colombia' },
  { code: 'CR', countryName: 'Costa Rica' },
  { code: 'CU', countryName: 'Cuba' },
  { code: 'CV', countryName: 'Cabo Verde' },
  { code: 'CW', countryName: 'Curaçao' },
  { code: 'CX', countryName: 'Christmas Island' },
  { code: 'CY', countryName: 'Cyprus' },
  { code: 'CZ', countryName: 'Czechia' },
  { code: 'DE', countryName: 'Germany' },
  { code: 'DJ', countryName: 'Djibouti' },
  { code: 'DK', countryName: 'Denmark' },
  { code: 'DM', countryName: 'Dominica' },
  { code: 'DO', countryName: 'Dominican Republic' },
  { code: 'DZ', countryName: 'Algeria' },
  { code: 'EC', countryName: 'Ecuador' },
  { code: 'EE', countryName: 'Estonia' },
  { code: 'EG', countryName: 'Egypt' },
  { code: 'EH', countryName: 'Western Sahara' },
  { code: 'ER', countryName: 'Eritrea' },
  { code: 'ES', countryName: 'Spain' },
  { code: 'ET', countryName: 'Ethiopia' },
  { code: 'FI', countryName: 'Finland' },
  { code: 'FJ', countryName: 'Fiji' },
  { code: 'FK', countryName: 'Falkland Islands (Malvinas)' },
  { code: 'FM', countryName: 'Micronesia (Federated States of)' },
  { code: 'FO', countryName: 'Faroe Islands' },
  { code: 'FR', countryName: 'France' },
  { code: 'GA', countryName: 'Gabon' },
  {
    code: 'GB',
    countryName: 'United Kingdom of Great Britain and Northern Ireland'
  },
  { code: 'GD', countryName: 'Grenada' },
  { code: 'GE', countryName: 'Georgia' },
  { code: 'GF', countryName: 'French Guiana' },
  { code: 'GG', countryName: 'Guernsey' },
  { code: 'GH', countryName: 'Ghana' },
  { code: 'GI', countryName: 'Gibraltar' },
  { code: 'GL', countryName: 'Greenland' },
  { code: 'GM', countryName: 'Gambia' },
  { code: 'GN', countryName: 'Guinea' },
  { code: 'GP', countryName: 'Guadeloupe' },
  { code: 'GQ', countryName: 'Equatorial Guinea' },
  { code: 'GR', countryName: 'Greece' },
  { code: 'GS', countryName: 'South Georgia and the South Sandwich Islands' },
  { code: 'GT', countryName: 'Guatemala' },
  { code: 'GU', countryName: 'Guam' },
  { code: 'GW', countryName: 'Guinea-Bissau' },
  { code: 'GY', countryName: 'Guyana' },
  { code: 'HK', countryName: 'Hong Kong' },
  { code: 'HM', countryName: 'Heard Island and McDonald Islands' },
  { code: 'HN', countryName: 'Honduras' },
  { code: 'HR', countryName: 'Croatia' },
  { code: 'HT', countryName: 'Haiti' },
  { code: 'HU', countryName: 'Hungary' },
  { code: 'ID', countryName: 'Indonesia' },
  { code: 'IE', countryName: 'Ireland' },
  { code: 'IL', countryName: 'Israel' },
  { code: 'IM', countryName: 'Isle of Man' },
  { code: 'IN', countryName: 'India' },
  { code: 'IO', countryName: 'British Indian Ocean Territory' },
  { code: 'IQ', countryName: 'Iraq' },
  { code: 'IR', countryName: 'Iran (Islamic Republic of)' },
  { code: 'IS', countryName: 'Iceland' },
  { code: 'IT', countryName: 'Italy' },
  { code: 'JE', countryName: 'Jersey' },
  { code: 'JM', countryName: 'Jamaica' },
  { code: 'JO', countryName: 'Jordan' },
  { code: 'JP', countryName: 'Japan' },
  { code: 'KE', countryName: 'Kenya' },
  { code: 'KG', countryName: 'Kyrgyzstan' },
  { code: 'KH', countryName: 'Cambodia' },
  { code: 'KI', countryName: 'Kiribati' },
  { code: 'KM', countryName: 'Comoros' },
  { code: 'KN', countryName: 'Saint Kitts and Nevis' },
  { code: 'KP', countryName: "Korea (Democratic People's Republic of)" },
  { code: 'KR', countryName: 'Korea, Republic of' },
  { code: 'KW', countryName: 'Kuwait' },
  { code: 'KY', countryName: 'Cayman Islands' },
  { code: 'KZ', countryName: 'Kazakhstan' },
  { code: 'LA', countryName: "Lao People's Democratic Republic" },
  { code: 'LB', countryName: 'Lebanon' },
  { code: 'LC', countryName: 'Saint Lucia' },
  { code: 'LI', countryName: 'Liechtenstein' },
  { code: 'LK', countryName: 'Sri Lanka' },
  { code: 'LR', countryName: 'Liberia' },
  { code: 'LS', countryName: 'Lesotho' },
  { code: 'LT', countryName: 'Lithuania' },
  { code: 'LU', countryName: 'Luxembourg' },
  { code: 'LV', countryName: 'Latvia' },
  { code: 'LY', countryName: 'Libya' },
  { code: 'MA', countryName: 'Morocco' },
  { code: 'MC', countryName: 'Monaco' },
  { code: 'MD', countryName: 'Moldova, Republic of' },
  { code: 'ME', countryName: 'Montenegro' },
  { code: 'MF', countryName: 'Saint Martin (French part)' },
  { code: 'MG', countryName: 'Madagascar' },
  { code: 'MH', countryName: 'Marshall Islands' },
  { code: 'MK', countryName: 'North Macedonia' },
  { code: 'ML', countryName: 'Mali' },
  { code: 'MM', countryName: 'Myanmar' },
  { code: 'MN', countryName: 'Mongolia' },
  { code: 'MO', countryName: 'Macao' },
  { code: 'MP', countryName: 'Northern Mariana Islands' },
  { code: 'MQ', countryName: 'Martinique' },
  { code: 'MR', countryName: 'Mauritania' },
  { code: 'MS', countryName: 'Montserrat' },
  { code: 'MT', countryName: 'Malta' },
  { code: 'MU', countryName: 'Mauritius' },
  { code: 'MV', countryName: 'Maldives' },
  { code: 'MW', countryName: 'Malawi' },
  { code: 'MX', countryName: 'Mexico' },
  { code: 'MY', countryName: 'Malaysia' },
  { code: 'MZ', countryName: 'Mozambique' },
  { code: 'NA', countryName: 'Namibia' },
  { code: 'NC', countryName: 'New Caledonia' },
  { code: 'NE', countryName: 'Niger' },
  { code: 'NF', countryName: 'Norfolk Island' },
  { code: 'NG', countryName: 'Nigeria' },
  { code: 'NI', countryName: 'Nicaragua' },
  { code: 'NL', countryName: 'Netherlands' },
  { code: 'NO', countryName: 'Norway' },
  { code: 'NP', countryName: 'Nepal' },
  { code: 'NR', countryName: 'Nauru' },
  { code: 'NU', countryName: 'Niue' },
  { code: 'NZ', countryName: 'New Zealand' },
  { code: 'OM', countryName: 'Oman' },
  { code: 'PA', countryName: 'Panama' },
  { code: 'PE', countryName: 'Peru' },
  { code: 'PF', countryName: 'French Polynesia' },
  { code: 'PG', countryName: 'Papua New Guinea' },
  { code: 'PH', countryName: 'Philippines' },
  { code: 'PK', countryName: 'Pakistan' },
  { code: 'PL', countryName: 'Poland' },
  { code: 'PM', countryName: 'Saint Pierre and Miquelon' },
  { code: 'PN', countryName: 'Pitcairn' },
  { code: 'PR', countryName: 'Puerto Rico' },
  { code: 'PS', countryName: 'Palestine, State of' },
  { code: 'PT', countryName: 'Portugal' },
  { code: 'PW', countryName: 'Palau' },
  { code: 'PY', countryName: 'Paraguay' },
  { code: 'QA', countryName: 'Qatar' },
  { code: 'RE', countryName: 'Réunion' },
  { code: 'RO', countryName: 'Romania' },
  { code: 'RS', countryName: 'Serbia' },
  { code: 'RU', countryName: 'Russian Federation' },
  { code: 'RW', countryName: 'Rwanda' },
  { code: 'SA', countryName: 'Saudi Arabia' },
  { code: 'SB', countryName: 'Solomon Islands' },
  { code: 'SC', countryName: 'Seychelles' },
  { code: 'SD', countryName: 'Sudan' },
  { code: 'SE', countryName: 'Sweden' },
  { code: 'SG', countryName: 'Singapore' },
  { code: 'SH', countryName: 'Saint Helena, Ascension and Tristan da Cunha' },
  { code: 'SI', countryName: 'Slovenia' },
  { code: 'SJ', countryName: 'Svalbard and Jan Mayen' },
  { code: 'SK', countryName: 'Slovakia' },
  { code: 'SL', countryName: 'Sierra Leone' },
  { code: 'SM', countryName: 'San Marino' },
  { code: 'SN', countryName: 'Senegal' },
  { code: 'SO', countryName: 'Somalia' },
  { code: 'SR', countryName: 'Suriname' },
  { code: 'SS', countryName: 'South Sudan' },
  { code: 'ST', countryName: 'Sao Tome and Principe' },
  { code: 'SV', countryName: 'El Salvador' },
  { code: 'SX', countryName: 'Sint Maarten (Dutch part)' },
  { code: 'SY', countryName: 'Syrian Arab Republic' },
  { code: 'SZ', countryName: 'Eswatini' },
  { code: 'TC', countryName: 'Turks and Caicos Islands' },
  { code: 'TD', countryName: 'Chad' },
  { code: 'TF', countryName: 'French Southern Territories' },
  { code: 'TG', countryName: 'Togo' },
  { code: 'TH', countryName: 'Thailand' },
  { code: 'TJ', countryName: 'Tajikistan' },
  { code: 'TK', countryName: 'Tokelau' },
  { code: 'TL', countryName: 'Timor-Leste' },
  { code: 'TM', countryName: 'Turkmenistan' },
  { code: 'TN', countryName: 'Tunisia' },
  { code: 'TO', countryName: 'Tonga' },
  { code: 'TR', countryName: 'Turkey' },
  { code: 'TT', countryName: 'Trinidad and Tobago' },
  { code: 'TV', countryName: 'Tuvalu' },
  { code: 'TW', countryName: 'Taiwan, Province of China' },
  { code: 'TZ', countryName: 'Tanzania, United Republic of' },
  { code: 'UA', countryName: 'Ukraine' },
  { code: 'UG', countryName: 'Uganda' },
  { code: 'UM', countryName: 'United States Minor Outlying Islands' },
  { code: 'US', countryName: 'United States of America' },
  { code: 'UY', countryName: 'Uruguay' },
  { code: 'UZ', countryName: 'Uzbekistan' },
  { code: 'VA', countryName: 'Holy See' },
  { code: 'VC', countryName: 'Saint Vincent and the Grenadines' },
  { code: 'VE', countryName: 'Venezuela (Bolivarian Republic of)' },
  { code: 'VG', countryName: 'Virgin Islands (British)' },
  { code: 'VI', countryName: 'Virgin Islands (U.S.)' },
  { code: 'VN', countryName: 'Viet Nam' },
  { code: 'VU', countryName: 'Vanuatu' },
  { code: 'WF', countryName: 'Wallis and Futuna' },
  { code: 'WS', countryName: 'Samoa' },
  { code: 'YE', countryName: 'Yemen' },
  { code: 'YT', countryName: 'Mayotte' },
  { code: 'ZA', countryName: 'South Africa' },
  { code: 'ZM', countryName: 'Zambia' },
  { code: 'ZW', countryName: 'Zimbabwe' }
] as const

export type CountryCode = (typeof COUNTRIES)[number]['code']
