/**
 * Returns the object from a collection with the given id
 * @param collection
 * @param id
 */
import { WithId } from '@kampaay/common'

/**
 * Given a collection returns only distinct values
 * @param collection
 * @param selectorFn
 * @example
 * distinct([{name: 'a'},{name: 'b'},{name: 'a'}], (e) => e.name) // [{name: 'a'},{name: 'b'}]
 */
export const distinct = <TItem>(
  collection: TItem[],
  selectorFn: (item: TItem) => unknown
) => {
  const dict: unknown[] = []
  return collection.filter((i) => {
    const selected = selectorFn(i)
    if (!dict.includes(selected)) {
      dict.push(selected)
      return true
    }
    return false
  })
}

/**
 * Given a collection returns only objects with distinct ids
 * @param collection
 */
export const distinctById = <TItem extends WithId>(collection: TItem[]) =>
  distinct(collection, (x) => x.id)

/**
 * Given a collection and an id returns the index of that element in the collection
 * @param collection
 * @param id
 * @example
 * getIndexById([{id: 5},{id: 3},{id: 1}], 3) // 1
 */
export const getIndexById = <T extends WithId>(
  collection: T[],
  id?: number
): number | undefined => {
  const index = collection.findIndex((item) => item.id === id)
  return index !== -1 ? index : undefined
}

export const arrayIncludesEvery = <T extends string | number>(
  arr: T[],
  target: (T | undefined)[]
) => target.every((v) => arr?.includes(v!))
