export {}

declare global {
  interface Number {
    /**
     * @param {number} lowEnd
     * @param {number} highEnd
     * @returns {boolean} true if the number is between the range
     */
    isWithin(lowEnd: number, highEnd: number): boolean
  }
}
// eslint-disable-next-line no-extend-native
Number.prototype.isWithin = function (lowEnd: number, highEnd: number) {
  return (
    (Number(this) >= lowEnd && Number(this) <= highEnd) ||
    (highEnd < lowEnd && (Number(this) <= highEnd || Number(this) >= lowEnd))
  )
}
