import React, { useEffect, useState } from 'react'
import { APIEntity } from 'services/api/entities'
import CustomReferenceInput, {
  isProductVisibleInStore
} from 'components/Inputs/CustomReferenceInput'
import EventMainProductSelectionInput, {
  EventMainProductSelectionInputProps
} from 'components/FormComponents/EventMainProductSelectionInput'
import { useFormContext } from 'react-hook-form'
import { MappedEnrichedEvent } from 'services/api/entities/events/types/internal/event-models'
import KDivider from 'components/UI/KDivider'
import { translate } from 'services/i18n'
import {
  Customer,
  MainProduct,
  TailoredProduct,
  WithIdAndName
} from '@kampaay/common'
import { useDataProvider } from 'ra-core'
import { Organization } from 'services/api/entities/crm/organizations/types/internal/organization-model'

type Props<T extends MainProduct> = Omit<
  EventMainProductSelectionInputProps<T>,
  'arrayInputLabel'
> & {
  entity: APIEntity
}

export const TailoredEventSelectionInput = <T extends MainProduct>({
  entity,
  children,
  ...rest
}: Props<T>) => {
  const customerIdAndOrg = useCustomerIdAndOrganizationFromEvent()

  return (
    <div
      style={{
        width: '100%'
      }}
    >
      <KDivider title={translate('form.headers.orders')} />
      <CustomReferenceInput
        entity={entity}
        filterFns={[
          filterByTailored({
            organizationId: customerIdAndOrg.organization?.id,
            customerId: customerIdAndOrg.customerId
          })
        ]}
      >
        <EventMainProductSelectionInput
          {...rest}
          label="form.fields.name"
          arrayInputLabel="orders"
        >
          {children}
        </EventMainProductSelectionInput>
      </CustomReferenceInput>
    </div>
  )
}

/**
 * The filter fn that returns true when
 * - there is not a tailored customer and organization and the product is visible in SF
 * - there is a tailored and the selected customer is the correct one
 * @param organizationId the organization id if present
 * @param customerId the customer id if there is one
 * @param product the product to filter
 * @returns true if one of the above conditions is met
 */
export const filterByTailored =
  <T extends TailoredProduct & WithIdAndName>({
    organizationId,
    customerId
  }: {
    organizationId?: number
    customerId?: number
  }) =>
  (product: T): boolean => {
    if (
      !product.customers.length &&
      !product.organizations.length &&
      isProductVisibleInStore(product)
    )
      return true

    return (
      (!!customerId && product.customers.includes(customerId)) ||
      (!!organizationId && product.organizations.includes(organizationId))
    )
  }

/**
 * Function to invoke in event form that returns cliecustomernt id if pmi and organization id if presetn
 * @returns object containing organization id and customer id
 */
export const useCustomerIdAndOrganizationFromEvent = () => {
  const dataProvider = useDataProvider()
  const { getValues } = useFormContext<MappedEnrichedEvent>()

  const selectedCustomer = getValues('customer')

  const [customer, setCustomer] = useState<Customer>()
  const [organization, setOrganization] = useState<Organization | undefined>()

  useEffect(() => {
    if (selectedCustomer) {
      dataProvider
        .getOne('customers', { id: selectedCustomer })
        .then(({ data }) => setCustomer(data))
    }
  }, [selectedCustomer])

  useEffect(() => {
    if (customer) {
      fetchOrganization().then((org) => {
        setOrganization(org)
      })
    }
  }, [customer])

  const fetchOrganization = async (): Promise<Organization | undefined> => {
    if (!customer?.membershipId) return undefined

    const membership = await dataProvider.getOne('organizationmembership', {
      id: customer?.membershipId
    })

    const organization = await dataProvider.getOne('organization', {
      id: membership.data.organizationId
    })

    return organization.data
  }

  return {
    organization,
    customerId: selectedCustomer
  }
}
