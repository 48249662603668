import { DataProvider } from 'react-admin'
import { APIEntity } from 'services/api/entities'
import dataProviderExtension, {
  CustomDpExtension
} from 'services/api/dataProvider/dataProviderExtension'
import {
  callApi,
  callPost,
  callUpdate,
  getApiCfg,
  getPath
} from 'services/api/dataProvider/common'
import { proxyDpPoc } from 'poc'
import invalidateDependentQueries from 'services/api/dataProvider/dependencies'
import { KConsole } from 'services/console'

export type KampaayDataProvider = DataProvider & CustomDpExtension

const kampaayDataProvider = {
  getList: async (resource: APIEntity, params) => {
    KConsole.log('GETLIST', resource, params)
    const apiCfg = getApiCfg(resource)

    const promise = apiCfg.getList
      ? apiCfg.getList(resource, params)
      : callApi(
          apiCfg,
          getPath(apiCfg, params),
          resource,
          true,
          'GET',
          undefined,
          params.meta?.headers
        )
    const response = await promise

    return { ...response, data: response?.data ?? [] }
  },
  getOne: (resource: APIEntity, params) => {
    KConsole.log('GETONE', resource, params)
    const apiCfg = getApiCfg(resource)
    return apiCfg.getOne
      ? apiCfg.getOne(resource, params)
      : callApi(apiCfg, getPath(apiCfg, params), resource)
  },
  getMany: async (resource: APIEntity, params) => {
    KConsole.log('GETMANY', resource, params)
    const apiCfg = getApiCfg(resource)

    return apiCfg.getMany
      ? apiCfg.getMany(resource, params)
      : callApi(
          apiCfg,
          getPath(apiCfg, { filter: { 'id~in~': params.ids } }, true),
          resource,
          true
        )
  },
  getManyReference: async (
    resource: APIEntity,
    { target, id, filter: _filter, ...params }
  ) => {
    KConsole.log('GETMANY REFERENCE', resource, params)

    if (target !== 'id') {
      KConsole.warn(
        'CAREFUL! Get many reference is likely not yet implemented for parameters other than the id'
      )
    }

    const apiCfg = getApiCfg(resource)

    // IMPORTANT: the `getManyReference` method natively only supports a single id param to use to perform the getMany.
    // This can be easily extended to support multiple ids, but it is not covered by the current typing, this cast should be safe though since we could also use a list of ids.
    const payload = id as unknown as number | number[]

    return callApi(
      apiCfg,
      getPath(
        apiCfg,
        {
          filter: { 'id~in~': Array.isArray(payload) ? payload : [payload] },
          ...params
        },
        true
      ),
      resource
    )
  },
  delete: async (resource: APIEntity, params) => {
    KConsole.log('DELETE', resource, params)
    const apiCfg = getApiCfg(resource)

    const result = apiCfg.delete
      ? await apiCfg.delete(resource, params)
      : await callApi(
          apiCfg,
          getPath(apiCfg, params),
          resource,
          false,
          'DELETE'
        )

    await invalidateDependentQueries(resource)

    return result
  },
  deleteMany: async (resource: APIEntity, params) => {
    KConsole.log('DELETEMANY', resource, params)
    const apiCfg = getApiCfg(resource)

    const result = apiCfg.deleteMany
      ? await apiCfg.deleteMany(resource, params)
      : Promise.all(
          params.ids.map((id) =>
            callApi(apiCfg, getPath(apiCfg, { id }), resource, false, 'DELETE')
          )
        ).then(() => ({ data: [] }))

    await invalidateDependentQueries(resource)

    return result
  },
  update: async (resource: APIEntity, params) => {
    KConsole.log('UPDATE', resource, params)
    const apiCfg = getApiCfg(resource)
    const { UPDATE_METHOD, write } = apiCfg
    const formattedRequest = await write(params.data, kampaayDataProvider)

    const result = apiCfg.update
      ? await apiCfg.update(resource, params)
      : await callUpdate(
          apiCfg,
          getPath(apiCfg, params),
          UPDATE_METHOD,
          formattedRequest
        )

    await invalidateDependentQueries(resource)
    return result
  },
  updateMany: async (resource: APIEntity, params) => {
    KConsole.log('UPDATEMANY', resource, params)
    const apiCfg = getApiCfg(resource)
    const { UPDATE_METHOD } = apiCfg

    const result = apiCfg.updateMany
      ? await apiCfg.updateMany(resource, params)
      : params.ids.map((id) =>
          callUpdate(
            apiCfg,
            getPath(apiCfg, { id }),
            UPDATE_METHOD,
            params.data
          )
        )

    await invalidateDependentQueries(resource)

    return result
  },
  create: async (resource: APIEntity, params) => {
    KConsole.log('CREATE', resource, params)
    const apiCfg = getApiCfg(resource)
    const formattedRequest = await apiCfg.write(
      params.data,
      kampaayDataProvider
    )

    const result = apiCfg.create
      ? await apiCfg.create(resource, params)
      : await callPost(
          apiCfg,
          getPath(apiCfg, params),
          'POST',
          formattedRequest
        )

    await invalidateDependentQueries(resource)

    return result
  },
  ...dataProviderExtension
} as KampaayDataProvider

export default proxyDpPoc(kampaayDataProvider)
