import { CheckoutQuestionReply } from '@kampaay/common'
import {
  FoodSelection,
  LocationSelection,
  MainProductSelection,
  OptionedProductSelection,
  OptionGroupSelection,
  ProductSelectionBase
} from 'services/api/entities/events/types/internal/selections'
import { EventProfessionalService } from 'services/api/entities/events/types/internal/services'
import { parseCsvFields, parseMultilangToCurrentLang } from '@kampaay/common'

export const formatBaseProduct = (m: MainProductSelection, obj?: Object) => {
  return {
    ...obj,
    ...parseCsvFields({ Name: m.name! }),
    Quantity: m.quantity
  }
}

export const formatMediumProduct = (m: OptionedProductSelection) => {
  return {
    ...formatBaseProduct(m),
    Options: formatEventOptions(m.optionSelections ?? []),
    Upselling: formatEventUpselling(m.upsellingSelection)
  }
}

export const formatCheckoutQuestions = (reply: CheckoutQuestionReply) => {
  const { question, responses } = reply
  return [parseMultilangToCurrentLang(question), responses.join(' / ')]
}

//-----------------------------
export const getFormattedStaff = (
  proTypes: EventProfessionalService[] = []
) => {
  return proTypes.reduce((acc, p) => {
    const translated = parseMultilangToCurrentLang(p?.name)
    return {
      ...acc,
      Staff: p.selections.map((f) => ({
        ...formatBaseProduct(f),
        'Start Service': f.startTime,
        'End Service': f.endTime,
        Supplier: p.supplierName,
        Type: translated
      }))
    }
  }, {})
}

export const formatEventUpselling = (
  upsellingSelection: ProductSelectionBase[]
) =>
  parseMultilangToCurrentLang(
    upsellingSelection.map((u) => u.name ?? '').join(', ')
  )

export const formatEventOptions = (groups: OptionGroupSelection[]) =>
  groups
    .flatMap((grp) =>
      grp.items.map(({ name }) => parseMultilangToCurrentLang(name))
    )
    .filter((arr) => !!arr)
    .join(', ')

export const getFormattedLocation = (m: LocationSelection) => ({
  ...parseCsvFields({ Name: m.name! }),
  'Start Date': m.bookedFrom,
  'End Date': m.bookedTo,
  Upselling: formatEventUpselling(m.upsellingSelection)
})

export const getFormattedFoods = (foods: FoodSelection[]) =>
  foods?.map(
    ({
      deliveryTime: _deliveryTime,
      intolerances: _intolerances,
      logisticSelection,
      ...rest
    }) => ({
      ...formatMediumProduct(rest),
      Logistic: parseMultilangToCurrentLang(logisticSelection!.name)
    })
  )

export const getFormattedCheckoutQuestionResponses = (
  questionResponses: CheckoutQuestionReply[]
) =>
  questionResponses.length
    ? Object.fromEntries(questionResponses.map(formatCheckoutQuestions))
    : {}
