import React, { useEffect } from 'react'

const DemoContainer = () => {
  useEffect(() => {
    throw new Error('Demo Sentry Error')
  }, [])

  return <></>
}

export default DemoContainer
