import { createMockBuilder } from '../../../../utils'
import type { KampaayEvent } from '../types'
import { buildCustomerMock } from '../../customers/mocks/customer-mocks'

const buildBaseMock = (): KampaayEvent => ({
  id: 1,
  name: 'Kampaay Event',
  conciergeId: 1,
  customer: 1,
  customerFull: buildCustomerMock(),
  startDate: new Date(),
  eventStatus: 'draft',
  activities: [],
  kampaayerDocs: [],
  color: '#ffffff',
  guestsNumber: 0,
  approverEmails: [],
  needsAllApprovals: false,
  pendingApproverEmails: []
})

export const buildKampaayEventMock = createMockBuilder(buildBaseMock)
