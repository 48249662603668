import React from 'react'
import LogisticSelectInput from 'components/Inputs/LogisticSelectInput'
import UpsellingFacetSection from 'components/Inputs/Facets/FacetFormSections/UpsellingFacetSection'
import UpsellingFormContent from 'components/Forms/UpsellingFormContent'

const FoodUpsellingFormContent: React.FC = () => {
  return (
    <UpsellingFormContent
      relatedProduct="foods"
      facetFields={<UpsellingFacetSection />}
    >
      <LogisticSelectInput />
    </UpsellingFormContent>
  )
}

export default FoodUpsellingFormContent
