import { ProfessionalSelection } from 'services/api/entities/events/types/internal/selections'
import { TimeLabel } from '@kampaay/common'
import { yupIgnore, YupSchema, yupString } from 'services/yup'
import { mainProductSelectionVS } from 'services/validations/operations/events/selections/common/mainProduct'

export const professionalSelectionVS: YupSchema<ProfessionalSelection> =
  mainProductSelectionVS.shape({
    sectionId: yupIgnore(),
    sectionName: yupIgnore(),
    specialType: yupIgnore(),
    startTime: yupString<TimeLabel>().required(),
    endTime: yupString<TimeLabel>().required()
  })
