import React from 'react'
import {
  invoiceStatusList,
  accountingStatusList,
  paymentStatusList
} from 'services/cfg/form'
import KSelectInput from 'components/Inputs/KInputs/KSelectInput'
import LabeledDateField from 'components/Fields/LabeledFields/LabeledDateField'
import KTextInput from 'components/Inputs/KInputs/KTextInput/KTextInput'
import KDateInput from 'components/Inputs/KInputs/KDateInput'
import LabeledTextField from 'components/Fields/LabeledFields/LabeledTextField'

const ConfirmedTransactionFields: React.FC = () => {
  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', maxWidth: '256px' }}
    >
      <LabeledDateField label="Confirmed at" source="confirmedAt" />
      <KSelectInput source="invoiceStatus" choices={invoiceStatusList} />
      <KSelectInput source="accountingStatus" choices={accountingStatusList} />
      <KSelectInput source="paymentStatus" choices={paymentStatusList} />
      <KDateInput source="invoiceExpiryDate" />
      <KTextInput source="invoiceNumber" label="Invoice number" />
      <LabeledTextField label="Payment Method" source="paymentMethod" />
    </div>
  )
}

export default ConfirmedTransactionFields
