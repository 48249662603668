import { BaseProductToApi } from 'services/api/entities/catalog/baseProduct/types/api/request-models'
import { BaseProduct } from 'services/api/entities/catalog/baseProduct/types/internal/baseProduct-models'
import { APIBaseProduct } from 'services/api/entities/catalog/baseProduct/types/api/response-models'
import { mainProductParse, mainProductWrite } from '@kampaay/common'
import { uploadOrKeepMultipleImgs } from 'services/utils/images'

// ============
// PARSE
// ============

export const baseProductParse = ({
  pricing,
  ...rest
}: APIBaseProduct): BaseProduct => ({ ...mainProductParse(rest), pricing })

// ============
// WRITE
// ============

export const baseProductWrite = async ({
  pricing,
  ...rest
}: BaseProduct): Promise<BaseProductToApi> => ({
  ...(await mainProductWrite(uploadOrKeepMultipleImgs)(rest)),
  pricing
})
