import { ProductTailoringBase } from '@kampaay/common'
import { KTestConfig, KTestFunction } from 'services/yup'

export const atLeastOneToggleIsEnabledCustomPrice: KTestFunction<
  boolean,
  ProductTailoringBase
> = (hasCustomPrice, { parent: { isHidden } }) => {
  return hasCustomPrice || isHidden
}

export const hasAtLeastOneToggleEnabledCustomPrice: KTestConfig<
  boolean,
  ProductTailoringBase
> = {
  name: 'hasAtLeastOneToggleEnabledCustomPrice',
  test: atLeastOneToggleIsEnabledCustomPrice,
  message: 'form.validations.atLeastOneToggleIsEnabled'
}

export const atLeastOneToggleIsEnabledIsHidden: KTestFunction<
  boolean,
  ProductTailoringBase
> = (isHidden, { parent: { hasCustomPrice } }) => {
  return isHidden || hasCustomPrice
}

export const hasAtLeastOneToggleEnabledIsHidden: KTestConfig<
  boolean,
  ProductTailoringBase
> = {
  name: 'hasAtLeastOneToggleEnabledIsHidden',
  test: atLeastOneToggleIsEnabledIsHidden,
  message: 'form.validations.atLeastOneToggleIsEnabled'
}
