import React from 'react'
import { RAFormFC } from 'types/common'
import { useForm } from 'hooks/useForm'
import StandardPricingInput from 'components/FormComponents/pricing/standard/StandardPricingInput'
import LocalizedAutocompleteInput from 'components/Inputs/Localized/LocalizedAutocompleteInput'
import KDivider from 'components/UI/KDivider'
import { DrinkVariant } from 'services/api/entities/catalog/drink/types/internal/drink-models'
import {
  APIPriceRange,
  getById,
  parseMultilangOrFallbackToAnyAvailable,
  ProductTailoring
} from '@kampaay/common'
import { KBooleanInput } from 'components/Inputs/KInputs/KBooleanInput'

type Props = {
  allVariants: DrinkVariant[]
  filteredVariants: DrinkVariant[]
}

const VariantTailoringInput: RAFormFC<Props> = ({
  source,
  allVariants = [],
  filteredVariants = []
}) => {
  const { getSource, useGetValue } =
    useForm<ProductTailoring<APIPriceRange>>(source)
  const variantId = useGetValue('id')
  const currentFullVariant = getById(allVariants, variantId)
  const variantName = currentFullVariant?.name ?? ''

  ///////////////////////////
  // JSX
  ///////////////////////////
  return (
    <>
      <div style={{ visibility: 'hidden', height: '0px' }}>
        <KBooleanInput
          source={getSource('isHidden')}
          label={'form.fields.isHidden'}
          defaultValue={false}
        />
        <KBooleanInput
          source={getSource('hasCustomPrice')}
          label={'form.fields.addCustomPrice'}
          defaultValue={true}
        />
      </div>
      {variantId ? (
        <>
          <KDivider />
          <h3>{parseMultilangOrFallbackToAnyAvailable(variantName)}</h3>
          <StandardPricingInput source={getSource('pricing')} hideFixedPrice />
        </>
      ) : (
        <LocalizedAutocompleteInput
          choices={filteredVariants}
          source={getSource('id')}
        />
      )}
    </>
  )
}

export default VariantTailoringInput
