import { fetchUtils, GetOneParams } from 'react-admin'
import { getCountry, getToken } from '@kampaay/common'
import getMockedResponse from 'services/mockResponses'
import { keys } from 'services/utils'
import kampaayDataProvider from '.'
import {
  APICfg,
  GetPathParams,
  HttpClientResponse,
  HttpOptions,
  RESTMethod,
  UpdateMethod
} from 'services/api/api'
import RESOURCE_API_MAP, { APIEntity } from 'services/api/entities'
import { encodeQueryString } from 'services/queryEncoding'
import { auth0 } from 'auth0Provider'
import { BASE_URL } from 'services/utils/urlUtils'

const cache: Record<
  string,
  Promise<{
    status: number
    headers: Headers
    body: string
    json: any
  }>
> = {}

export const httpClient = async (
  url: string,
  options: HttpOptions,
  headers: any = {}
) => {
  const jwt = await getToken(auth0)

  const fetch = () =>
    fetchUtils.fetchJson(url, {
      ...options,

      headers: new Headers({
        'x-kampaay-country':
          headers['x-kampaay-country'] ?? getCountry().toString(),
        ...(jwt?.authToken ? { Authorization: `Bearer ${jwt.authToken}` } : {}),
        ...(options.headers ? options.headers : {})
      })
    })

  // for other methods that GET, we just fetch the resource
  if (options.method !== 'GET') {
    return fetch()
  }

  // If the same url is already being fetched, return the same promise
  // when the promise is then resolved the cache entry is self destructed
  // This is useful to avoid multiple requests to the same url at the same time
  if (!cache[url] && options.method === 'GET') {
    cache[url] = fetch().then((r) => {
      delete cache[url]
      return r
    })
  }

  return cache[url]
}

// FIXME: Investigate and rewrite this in a better way!!
export const throwErrorWithMessage = (err: any) => {
  if (err.body) {
    const { errors } = err.body
    if (errors) {
      err.message = keys(errors)
        .reduce((acc, key) => [...acc, ...errors[key]], [] as string[])
        .join('\n')
    } else if (err.body.title) {
      err.message = err.body.title
    } else {
      err.message = err.body
    }
  }
  throw err
}

export const getPath = (
  apiCfg: APICfg,
  params: Partial<GetPathParams> = {},
  isMany = false
) => {
  const { id, pagination, sort } = params

  // If we are calling the /many endpoint we can't pass any custom query
  // the only things we need are the ids
  let filter = { ...params.filter, ...(!isMany ? apiCfg.query : {}) }

  const { endpoint, isSingleEntity = false, getOneEndpoint } = apiCfg

  if (!isMany && !isSingleEntity && !!id)
    return (getOneEndpoint ?? `${endpoint}/:id`).replace(':id', id.toString())

  const path = isMany ? `${endpoint}/many` : endpoint
  return `${path}?${encodeQueryString({
    pagination,
    filter,
    sort
  })}`
}

export const callApi = async (
  apiCfg: APICfg,
  path: string,
  resource: APIEntity, // NB Resource is only used for mocking purposes
  isList = false,
  method: RESTMethod = 'GET',
  data?: unknown,
  headers: any = {}
) => {
  const url = `${BASE_URL}/${path}`
  try {
    const res: HttpClientResponse = await httpClient(url, {
      method,
      body: JSON.stringify(data),
      headers
    })
    return formatResponse(apiCfg, res, method)
  } catch (error: any) {
    // TODO: remove this when we'll unmock EVERYTHING!
    if (error.status === 404) {
      const FAKE_HEADERS = new Headers()
      FAKE_HEADERS.append('x-kampaay-total-count', '50')
      return formatResponse(
        apiCfg,
        {
          status: 200,
          json: getMockedResponse(resource, isList ? 'list' : 'detail', url),
          headers: FAKE_HEADERS
        },
        method
      )
    }
    throwErrorWithMessage(error)
  }
}

const getTotalFromHeaders = (h: Headers) =>
  !!h.get('x-kampaay-total-count')
    ? parseInt(h.get('x-kampaay-total-count')!)
    : 500

export const formatResponse = (
  { parse }: APICfg,
  { json, status, headers }: HttpClientResponse,
  method: RESTMethod
) => {
  const data =
    method !== 'DELETE'
      ? Array.isArray(json)
        ? json.map(parse)
        : parse(json)
      : json
  return {
    status: status,
    data: data ?? {},
    total: getTotalFromHeaders(headers)
  }
}

export const callUpdate = async (
  apiCfg: APICfg,
  path: string,
  method: UpdateMethod = 'PUT',
  data: any
) => {
  const url = `${BASE_URL}/${path}`
  return httpClient(url, { method, body: JSON.stringify(data) })
    .then((res) => {
      return formatResponse(apiCfg, res, method)
    })
    .catch((err) => {
      throwErrorWithMessage(err)
    })
}

export const callPost = async (
  apiCfg: APICfg,
  path: string,
  method: RESTMethod,
  data: any,
  skipWriteFormat = false
) => {
  const url = `${BASE_URL}/${path}`
  return httpClient(url, { method, body: JSON.stringify(data) })
    .then((res) =>
      skipWriteFormat ? res : formatResponse(apiCfg, res, method)
    )
    .catch((err) => {
      throwErrorWithMessage(err)
    })
}

export const getApiCfg = (resource: APIEntity) => RESOURCE_API_MAP[resource]

export const upload = async (
  resource: 'image' | 'doc',
  rawFile: any,
  key: string
) => {
  const formData = new FormData()
  formData.append(key, rawFile)
  const url = `${BASE_URL}/uploads/${resource}`
  const { json } = await httpClient(url, {
    method: 'POST',
    body: formData
  })
  return json
}

export const uploadDoc = async (file: File) => {
  const res = await upload('doc', file, 'Document')
  return res.id as string
}

export const uploadImg = async (file: File) => {
  const res = await upload('image', file, 'image')
  return res.id as string
}

/**
 * This function takes multiple entities and for each one of them it calls the
 * getOne to get the full record.
 * @param params the params to the gets
 * @param entities
 */
export const multiPlexGetOneHandler = async (
  params: GetOneParams,
  entities: APIEntity[]
) => {
  const apiCfgArray = entities.map(getApiCfg)
  return await Promise.all(
    apiCfgArray.map((config) =>
      callApi(config, getPath(config, params), entities[0] as APIEntity)
    )
  )
}

type MultiPlexUpdateTarget = {
  resource: APIEntity
  params: { data: any; id: number }
}

export const multiPlexUpdateHandler = async (
  { resource: mainResource, params: mainParams }: MultiPlexUpdateTarget,
  ...rest: MultiPlexUpdateTarget[]
) => {
  // this executes all the promises sequentially and in the order that we pass the targets
  // we should always pass the main resource first
  let cachedError
  let mainResponse: any

  try {
    const mainApiCfg = getApiCfg(mainResource)
    const mappedMain = await mainApiCfg.write(
      mainParams.data,
      kampaayDataProvider
    )

    mainResponse = await callUpdate(
      mainApiCfg,
      getPath(mainApiCfg, mainParams),
      mainApiCfg.UPDATE_METHOD,
      mappedMain
    )
  } catch (error) {
    cachedError = error
  }

  for (const { resource, params } of rest) {
    try {
      const apiCfg = getApiCfg(resource)
      const mappedData = await apiCfg.write(params.data, kampaayDataProvider)
      await callUpdate(
        apiCfg,
        getPath(apiCfg, params),
        apiCfg.UPDATE_METHOD,
        mappedData
      )
    } catch (error) {
      cachedError = error
    }
  }
  if (!!cachedError) throw cachedError

  return mainResponse
}
