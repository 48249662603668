import { Facet, FacetCategory, FacetFilterGroup } from '@kampaay/common'
import { useKGetList } from 'hooks/useKGetList'
import { alphaNumericSorter } from 'services/utils'

function useFacets<T extends Facet = Facet>(
  facetCategory: Omit<FacetCategory, 'filterTag'>,
  filterGroup?: FacetFilterGroup
): T[] {
  const facets = useKGetList<T>('facets')
    .list.filter((f) => f.category === facetCategory)
    .filter((f) => f.filterGroup === filterGroup)

  return facets.sort(alphaNumericSorter<T>('ASC', (f) => f.description))
}

export default useFacets
