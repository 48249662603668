import { translate } from 'services/i18n'
import {
  APICustomization,
  APIEvent,
  APIEventProfessionalService,
  APIEventServiceBase,
  APIFoodSnapshot,
  APILogisticSnapshot,
  APIProfessionalServiceGroup,
  APIProfessionalSnapshot,
  APIWithPricingSnapshot
} from 'services/api/entities/events/types/api/response-models'
import {
  EventProductServicesBundle,
  EventProfessionalService,
  EventProfessionalServiceGroup,
  EventServiceBase
} from 'services/api/entities/events/types/internal/services'
import { SuppliedService } from 'types/common'
import {
  FoodSelection,
  ProfessionalSelection
} from 'services/api/entities/events/types/internal/selections'
import {
  Customization,
  EventItem,
  WithPricingSnapshot
} from 'services/api/entities/events/types/internal/event-models'
import capitalize from 'lodash/capitalize'
import { PRODUCT_SERVICES_NAMES } from 'services/cfg'
import { getById, isTruthy } from '@kampaay/common'
import { parseRaImage, parseRaMultipleDocs } from 'services/api/raParsers'

const SEPARATOR = ', '

export const customizationParse = (
  customization: APICustomization
): Customization => {
  return {
    ...customization,
    documents: parseRaMultipleDocs(customization.documents),
    images: customization.images
      .map((img) => parseRaImage(img))
      .filter(isTruthy)
  }
}

const professionalsSelectionParseByType = (
  figureSelection: APIProfessionalSnapshot[],
  serviceSectionId: number
): ProfessionalSelection[] =>
  figureSelection
    .filter(({ sectionId }) => sectionId === serviceSectionId)
    .map(({ bookedFrom, bookedTo, ...rest }) => ({
      ...rest,
      startTime: bookedFrom,
      endTime: bookedTo
    }))

const foodSelectionParse =
  (logistics: APILogisticSnapshot[]) =>
  (food: APIFoodSnapshot): FoodSelection => ({
    ...food,
    logisticSelection: getById(logistics, food.logisticSelection)!
  })

const eventDatesParse = ({
  startDate,
  endDate,
  rentPickupDate,
  musicPickupDate,
  confirmedAt
}: APIEvent) => ({
  startDate: new Date(startDate),
  endDate: new Date(endDate),
  rentPickupDate: rentPickupDate ? new Date(rentPickupDate) : undefined,
  musicPickupDate: musicPickupDate ? new Date(musicPickupDate) : undefined,
  confirmedAt: confirmedAt ? new Date(confirmedAt) : undefined
})

const getEventName = (id: number) => `${translate('common.label.event')} #${id}`

const parseServiceBase = (service?: APIEventServiceBase): EventServiceBase => ({
  supplyConfirmed: service?.supplyConfirmed === 'confirmed',
  supplier: service?.supplier?.id,
  supplierName: service?.supplier?.name,
  lastMessage: service?.lastMessage,
  lastMessageConfirmed: !!service?.lastMessageConfirmed,
  pricing: service?.pricing,
  notifySupplier: service?.notifySupplier,
  assignmentEmailSent: !!service?.assignmentEmailSent
})

const professionalServiceParse =
  (proFigureSelection: APIProfessionalSnapshot[]) =>
  (
    professionalService: APIEventProfessionalService
  ): EventProfessionalService => {
    const { sectionId, sectionName, notes, ...rest } = professionalService
    return {
      ...parseServiceBase(rest),
      id: sectionId,
      name: sectionName,
      notes,
      selections: professionalsSelectionParseByType(
        proFigureSelection,
        professionalService.sectionId
      )
    }
  }

const parseProfessionalWrapper = (
  professionals: APIProfessionalSnapshot[],
  w?: APIProfessionalServiceGroup
): EventProfessionalServiceGroup => ({
  customizations: w?.customizations.map(customizationParse) ?? [],
  services: w?.services?.map(professionalServiceParse(professionals)) ?? []
})

export const computePricing = (
  pricing: APIWithPricingSnapshot['pricing'] | undefined
): WithPricingSnapshot['pricing'] => {
  return {
    price: pricing?.price ?? 0,
    costNoVat: pricing?.costNoVat ?? 0
  }
}

export const eventParse = (res: APIEvent): EventItem => {
  const {
    foodService: _foodService,
    rentService: _rentService,
    musicService: _musicService,
    kampaayerDocs,
    conciergeId,
    virtualExperiences = [],
    physicalExperiences = [],
    customer,
    professionalServiceGroup,
    geoAreaPreset,
    pricing,
    needsAllApprovals,
    approverEmails,
    pendingApproverEmails,
    ...rest
  } = res

  const productServices =
    PRODUCT_SERVICES_NAMES.reduce<EventProductServicesBundle>((acc, serv) => {
      const service = res[serv as SuppliedService]
      const mappedCustomizations =
        service?.customizations?.map(customizationParse) ?? []
      const pricing = computePricing(service?.pricing)

      return {
        ...acc,
        [serv]: {
          customizations: mappedCustomizations,
          ...parseServiceBase(service),
          pricing: pricing
        }
      }
    }, Object.create(null))

  const foods = rest.foods?.map(foodSelectionParse(rest.logistics)) ?? []
  const locations = rest.locations ?? []

  const purchasedServices = rest.purchasedServices
    ?.map(capitalize)
    .join(SEPARATOR)

  const mappedCustomizations =
    rest.eventCustomizations?.map(customizationParse) ?? []
  const computedPricing = computePricing(pricing)

  return {
    ...rest,
    ...eventDatesParse(res),
    name: rest.name ?? getEventName(rest.id),
    customer: customer.id,
    customerInfo: customer,
    purchasedServices,
    customerDocs: parseRaMultipleDocs(rest.customerDocs),
    kampaayerDocs: parseRaMultipleDocs(kampaayerDocs),
    purchasedServicesArray: rest.purchasedServices,
    professionalService: parseProfessionalWrapper(
      rest.professionals,
      professionalServiceGroup
    ),
    professionalStatus: rest.professionalStatus,
    productServices,
    virtuals: virtualExperiences,
    physicals: physicalExperiences,
    foods,
    locations,
    conciergeId,
    isFromConcierge: !!conciergeId,
    customizations: mappedCustomizations,
    geoAreaPreset: geoAreaPreset?.id,
    geoAreaPresetFull: geoAreaPreset,
    pricing: computedPricing,
    needsAllApprovals: needsAllApprovals ?? false,
    approverEmails: approverEmails ?? [],
    pendingApproverEmails: pendingApproverEmails ?? []
  }
}
