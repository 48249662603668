import React from 'react'
import { ProfessionalSection } from 'services/api/entities/catalog/sections/professionalSections'
import {
  EnrichedProductService,
  EnrichedProfessionalService
} from 'services/api/entities/events/types/internal/services'
import { professionalOutfitChoices } from 'services/cfg/form'
import EventServiceBriefSection from 'components/FormComponents/EventServiceBriefSection'
import { SelectionDetailsProps } from 'components/FormComponents/MainProductSelectionInputContent'
import KDivider from 'components/UI/KDivider'
import KRichTextInput from 'components/Inputs/KInputs/KRichTextInput'
import KSelectInput from 'components/Inputs/KInputs/KSelectInput'
import ProfessionalFigureArrayInput from 'resources/operations/events/eventsFormTabs/ProfessionaTab/ProfessionalFigureArrayInput'
import { KBooleanInput } from 'components/Inputs/KInputs/KBooleanInput'
import LabeledBooleanField from 'components/Fields/LabeledFields/LabeledBooleanField'
import SupplierSelectInput from 'components/Inputs/SupplierSelectInput'
import { useEventProfessionalServiceGroup } from 'hooks/events/useEventProfessionalServiceGroup'
import { translate } from 'services/i18n'
import { useNotifySupplier } from 'hooks/events/useNotifySupplier'
import { Supplier } from '@kampaay/common'
import { arrayIncludesEvery } from 'services/utils'
import { RAFormFC } from 'types/common'
import SupplierAssignmentErrorSpan from 'resources/operations/events/common/SupplierAssignmentErrorSpan'
import useCheckIfSelectionsHasCommonSupplier from 'resources/operations/events/common/useCheckIfSelectionsHasCommonSupplier'
import { useCheckIfSelectionsNotInCatalog } from 'resources/operations/events/common/useCheckIfSelectionsNotInCatalog'
import { useCheckIfSupplierProvideProduct } from 'resources/operations/events/common/useCheckIfSupplierProvideProduct'
import { Warning } from '@mui/icons-material'
import KBanner from 'components/UI/KBanner'
import { kampaayTheme } from 'layout/config/themes'
import { useForm } from 'hooks/useForm'

type Props = SelectionDetailsProps<
  ProfessionalSection,
  EnrichedProfessionalService
>

const ProfessionalsSelectionDetailsFormContent: RAFormFC<Props> = ({
  scopedFormData,
  getSource,
  selectedProduct: selectedProfessionalSection,
  isObsolete
}) => {
  if (!scopedFormData || !getSource) return null

  const { briefQuestions, notifySupplier } = scopedFormData

  const serviceName = 'professionals'
  const { useGetValue } = useForm<EnrichedProductService>(getSource(''))

  // There is an issue with useEventProfessionalServiceGroup hook and it's not working properly
  // It is not reactive and it's not updating when new product is selected
  // Please remove those comments when the issue is fixed in here: https://gitlab.com/kampaay/kampaay-backoffice/-/issues/592
  const { professionalServiceSummaries } = useEventProfessionalServiceGroup()
  const professionalServiceSelectedId =
    scopedFormData.selections?.[0]?.sectionId

  const selectionIds = professionalServiceSummaries
    .filter(
      (summary) =>
        summary.professionalServiceId === professionalServiceSelectedId
    )
    .flatMap((summary) => summary.selections)
    .map((selection) => selection.id)

  const isSelectionsNotInCatalog = useCheckIfSelectionsNotInCatalog({
    SCN: serviceName,
    selectionIds
  })

  const assignmentEmailSent = useGetValue('assignmentEmailSent')
  const supplier = scopedFormData.supplier
  const { isNotifySupplierDisabled, notifyServiceSupplier } = useNotifySupplier(
    getSource!('')
  )

  const filterSupplierWithService = (supplier: Supplier) =>
    supplier.suppliedServicesProducts.filter(
      (ssp) =>
        ssp.products.length &&
        ssp.service === 'professionals' &&
        arrayIncludesEvery(ssp.products, selectionIds)
    ).length > 0

  const isSupplierProvideProduct = useCheckIfSupplierProvideProduct({
    selectedSupplierId: supplier,
    serviceName,
    selectionIds
  })

  const ifSelectionsHasCommonSupplier = useCheckIfSelectionsHasCommonSupplier({
    selectionIds: selectionIds,
    serviceName,
    ifSupplierNotAssigned: !supplier
  })

  return (
    <>
      <KRichTextInput source={getSource('notes')} label="form.fields.notes" />
      <SupplierSelectInput
        disabled={notifySupplier}
        source={getSource('supplier')}
        filterFns={isSelectionsNotInCatalog ? [] : [filterSupplierWithService]}
      />
      <SupplierAssignmentErrorSpan
        isSelectionsNotInCatalog={isSelectionsNotInCatalog}
        isSupplierProvideProduct={isSupplierProvideProduct}
        ifSupplierExist={!!supplier}
        ifProductSelected={!!(selectionIds.length > 0)}
      />
      {ifSelectionsHasCommonSupplier ? (
        <div
          style={{
            marginBottom: '1.5rem',
            backgroundColor: 'rgba(255, 0, 0, 0.15)',
            border: '3px solid black',
            padding: '0.5rem'
          }}
        >
          {translate('common.label.notHaveCommonSupplier')}
        </div>
      ) : (
        <></>
      )}
      <div className="align-center mb-4 flex gap-4">
        <KBooleanInput
          className="mt-3"
          label="form.fields.triggerAssignmentEmail"
          source={getSource(`triggerAssignmentEmail`)}
        />
        {assignmentEmailSent && (
          <KBanner
            backgroundColor={kampaayTheme.palette.primary.warning}
            text="form.fields.assignmentEmailAlreadySent"
            icon={<Warning />}
          />
        )}
      </div>
      <KBooleanInput
        label={
          !!scopedFormData.supplyConfirmed
            ? 'Supply confirmed'
            : 'Supply not yet confirmed'
        }
        source={getSource!('supplyConfirmed')}
        disabled
      />
      {!!scopedFormData.supplier && (
        <KRichTextInput
          source={getSource('lastMessage')}
          label="form.fields.lastSupplierMessage"
        />
      )}
      <KBooleanInput
        label={
          scopedFormData.lastMessageConfirmed
            ? 'form.fields.lastSupplierMessageConfirmed'
            : 'form.fields.lastSupplierMessageNotConfirmed'
        }
        source={getSource!('lastMessageConfirmed')}
        disabled
      />
      {[
        selectedProfessionalSection?.specialType,
        scopedFormData.specialType
      ].includes('waitingStaff') && (
        <KSelectInput
          source="professionalOutfit"
          label="Professional Outfit"
          choices={professionalOutfitChoices}
          defaultValue={professionalOutfitChoices[0].id}
          fullWidth
          disabled={isObsolete}
        />
      )}
      <ProfessionalFigureArrayInput
        professionals={selectedProfessionalSection?.productsFull ?? []}
        isObsolete={!!isObsolete}
        scopedFormData={scopedFormData}
        getSource={getSource}
      />
      {!!briefQuestions?.length && (
        <>
          <KDivider title="Event Brief" headingLevel="h3" />
          <LabeledBooleanField
            label="form.fields.briefConfirmation"
            source={getSource!('briefConfirmed')}
          />

          <EventServiceBriefSection
            serviceSource={getSource!('')}
            isNotifySupplierDisabled={isNotifySupplierDisabled}
            notifyServiceSupplier={notifyServiceSupplier}
          />
        </>
      )}
    </>
  )
}

export default ProfessionalsSelectionDetailsFormContent
