import I18nFileProvider, {
  I18nMap,
  PlatformCode
} from 'i18n/components/I18nFileProvider'
import { getI18nFileCodes } from 'i18n/helpers'
import { TranslationMessages } from 'ra-core'
import React from 'react'
import { reloadI18nBundle } from 'services/i18n'
import OrganizationBudgetList from 'resources/crm/clients/organization/budget/OrganizationBudgetList'
import OrganizationBudgetCreate from 'resources/crm/clients/organization/budget/OrganizationBudgetCreate'
import OrganizationBudgetEdit from 'resources/crm/clients/organization/budget/OrganizationBudgetEdit'
import OrganizationMembershipList from 'resources/crm/clients/organization/membership/OrganizationMembershipList'
import OrganizationMembershipCreate from 'resources/crm/clients/organization/membership/OrganizationMembershipCreate'
import OrganizationMembershipEdit from 'resources/crm/clients/organization/membership/OrganizationMembershipEdit'
import OrganizationEventPlaceholdersList from 'resources/crm/clients/organization/placeholders/OrganizationEventPlaceholdersList'
import {
  CURRENT_I18N_LOCALE,
  type I18nLocale,
  SUPPORTED_COUNTRIES,
  isFeatureFlagEnabled
} from '@kampaay/common'
import OrganizationCalendarList from 'resources/crm/clients/organization/calendar/OrganizationCalendarList'
import OrganizationSubGroupList from 'resources/crm/clients/organization/sub-group/OrganizationSubGroupList'
import OrganizationSubGroupCreate from 'resources/crm/clients/organization/sub-group/OrganizationSubGroupCreate'
import OrganizationSubGroupEdit from 'resources/crm/clients/organization/sub-group/OrganizationSubGroupEdit'

const buildI18nComponent =
  (
    platformCode: PlatformCode,
    i18nFileCodes: I18nLocale[] = getI18nFileCodes(SUPPORTED_COUNTRIES),
    onSaved?: (i18nMap: I18nMap) => void
  ): React.FC =>
  () =>
    isFeatureFlagEnabled('i18n') ? (
      <I18nFileProvider
        platformCode={platformCode}
        requiredFiles={i18nFileCodes}
        onSaved={onSaved}
      />
    ) : (
      <img
        src="https://cdn.vox-cdn.com/uploads/chorus_asset/file/2664638/Goat.1351089485.jpg"
        style={{ width: '50%', margin: 'auto' }}
        alt="goat"
      />
    )

export const I18nBeComponent = buildI18nComponent('be')

export const I18nFeComponent = buildI18nComponent('fe')

export const I18nBoComponent = buildI18nComponent(
  'bo',
  ['en-IT', 'en-SE'],
  (i18nMap) =>
    reloadI18nBundle(i18nMap[CURRENT_I18N_LOCALE] as TranslationMessages)
)

export const organizationBudgetRoutes = [
  {
    path: ':id/budgets',
    element: OrganizationBudgetList
  },
  {
    path: ':id/budgets/create',
    element: OrganizationBudgetCreate
  },
  {
    path: ':id/budgets/:budgetId',
    element: OrganizationBudgetEdit
  }
]

export const organizationSubGroupRoutes = [
  {
    path: ':id/sub-groups',
    element: OrganizationSubGroupList
  },
  {
    path: ':id/sub-groups/create',
    element: OrganizationSubGroupCreate
  },
  {
    path: ':id/sub-groups/:subGroupId',
    element: OrganizationSubGroupEdit
  }
]

export const organizationMembershipRoutes = [
  {
    path: ':id/members',
    element: OrganizationMembershipList
  },
  {
    path: ':id/members/create',
    element: OrganizationMembershipCreate
  },
  {
    path: ':id/members/:membershipId',
    element: OrganizationMembershipEdit
  }
]

export const organizationEventPlaceholdersRoutes = [
  {
    path: ':id/event-placeholders',
    element: OrganizationEventPlaceholdersList
  }
]

export const organizationCalendarRoutes = [
  {
    path: ':id/calendar',
    element: OrganizationCalendarList
  }
]
