import React from 'react'
import OptionedProductTailoringPricesInput from 'resources/crm/clients/organization/tailoring/components/optioned/OptionedProductTailoringPricesInput'
import ServiceTailoringsEdit from 'resources/crm/clients/organization/tailoring/ServiceTailoringsEdit'

const FoodTailoringsEdit: React.FC = () => {
  return (
    <ServiceTailoringsEdit
      service="foods"
      productEntity="foods"
      tailoringsInput={OptionedProductTailoringPricesInput}
    />
  )
}

export default FoodTailoringsEdit
