import { styled } from '@mui/material/styles'
import React from 'react'
import { useTranslate } from 'react-admin'
import HalfStepNumberInput from 'components/Inputs/HalfStepNumberInput/HalfStepNumberInput'

const PREFIX = 'DefaultTimeBeforeHint'

const classes = {
  fieldsBox: `${PREFIX}-fieldsBox`,
  fieldWidth: `${PREFIX}-fieldWidth`,
  ml55: `${PREFIX}-ml55`
}

const Root = styled('div')(() => ({
  [`& .${classes.fieldsBox}`]: {
    display: 'flex',
    flexDirection: 'row',
    gap: '100px',
    width: '100%'
  },

  [`& .${classes.fieldWidth}`]: {
    width: '300px'
  },

  [`& .${classes.ml55}`]: {
    marginLeft: '50px'
  }
}))

type Props = {
  source: string
}

const DefaultTimeBeforeHint: React.FC<Props> = ({ source, ...props }) => {
  const translate = useTranslate()
  return (
    <Root className={[classes.fieldsBox, classes.ml55].join(' ')}>
      <div className={classes.fieldWidth}>
        {translate('form.fields.default')}
      </div>
      <HalfStepNumberInput
        {...props}
        source={`${source}.hoursBefore`}
        label="form.fields.hoursBefore"
        className={classes.fieldWidth}
      />
    </Root>
  )
}

export default DefaultTimeBeforeHint
