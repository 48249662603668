import React, { useEffect } from 'react'
import { Admin, CustomRoutes, Resource } from 'react-admin'
import kampaayDataProvider from 'services/api/dataProvider'
import 'services/native-extensions'
import 'App.css'
import Dashboard from 'resources/dashboard/dashboard'
import events from 'resources/operations/events'
import transaction from 'resources/accounting/transaction'
import kampaayers from 'resources/crm/kampaayers'
import drinksSection from 'resources/catalog/drink/sections'
import foodsSection from 'resources/catalog/food/sections'
import drink from 'resources/catalog/drink/product'
import food from 'resources/catalog/food/product'
import foodupselling from 'resources/catalog/food/upselling'
import music from 'resources/catalog/music/product'
import musicSections from 'resources/catalog/music/sections'
import location from 'resources/catalog/location/product'
import locationUpselling from 'resources/catalog/location/upselling'
import locationsFeatures from 'resources/catalog/location/features'
import locationSections from 'resources/catalog/location/sections'
import rent from 'resources/catalog/rent/product'
import rentSections from 'resources/catalog/rent/sections'
import virtualexperiences from 'resources/catalog/virtualexperiences/product'
import virtualexperiencesSection from 'resources/catalog/virtualexperiences/sections'
import virtualexperiencesupselling from 'resources/catalog/virtualexperiences/upselling'
import physicalexperiences from 'resources/catalog/physicalexperiences/product'
import physicalexperiencesSection from 'resources/catalog/physicalexperiences/sections'
import physicalexperiencesUpselling from 'resources/catalog/physicalexperiences/upselling'
import facets from 'resources/facets'
import logistics from 'resources/catalog/logistics'
import professionalSections from 'resources/catalog/professionals/sections'
import professionalFigures from 'resources/catalog/professionals/p-figures'
import organization from 'resources/crm/clients/organization'
import rules from 'resources/catalog/rule'
import customers from 'resources/crm/clients/customers'
import { KLayout, Login } from 'layout'
import authProvider from 'auth0Provider'
import { useI18nProvider } from 'services/i18n'
import { LangContextProvider } from 'contexts/langContext'
import { kampaayTheme } from 'layout/config/themes'
import servicesConfig from 'resources/services-config'
import area from 'resources/area'
import mailingList from 'resources/mailing-list'
import {
  I18nBeComponent,
  I18nBoComponent,
  I18nFeComponent,
  organizationBudgetRoutes,
  organizationCalendarRoutes,
  organizationEventPlaceholdersRoutes,
  organizationMembershipRoutes,
  organizationSubGroupRoutes
} from 'routes'
import { Route, useLocation } from 'react-router-dom'
import { PocResources } from 'poc'
import FoodTailoringsEdit from 'resources/crm/clients/organization/tailoring/FoodTailoringsEdit'
import DrinksTailoringsEdit from 'resources/crm/clients/organization/tailoring/DrinkTailoringsEdit'
import ProfessionalTailoringsEdit from 'resources/crm/clients/organization/tailoring/ProfessionalTailoringsEdit'
import LocationTailoringsEdit from 'resources/crm/clients/organization/tailoring/LocationTailoringsEdit'
import VirtualTailoringsEdit from 'resources/crm/clients/organization/tailoring/VirtualTailoringsEdit'
import PhysicalTailoringsEdit from 'resources/crm/clients/organization/tailoring/PhysicalTailoringsEdit'
import MusicTailoringsEdit from 'resources/crm/clients/organization/tailoring/MusicTailoringsEdit'
import RentTailoringsEdit from 'resources/crm/clients/organization/tailoring/RentTailoringsEdit'
import FoodUpsellingTailoringsEdit from 'resources/crm/clients/organization/tailoring/FoodUpsellingTailoringsEdit'
import VirtualUpsellingTailoringsEdit from 'resources/crm/clients/organization/tailoring/VirtualUpsellingTailoringsEdit'
import PhysicalUpsellingTailoringsEdit from 'resources/crm/clients/organization/tailoring/PhysicalUpsellingTailoringsEdit'
import LocationUpsellingTailoringsEdit from 'resources/crm/clients/organization/tailoring/LocationUpsellingTailoringsEdit'
import LogisticTailoringsEdit from 'resources/crm/clients/organization/tailoring/LogisticTailoringsEdit'
import eventsDraft from 'resources/operations/eventsDraft'
import { queryClient } from 'services/queryClient'
import { initSentry } from 'sentry'
import { isDev } from 'services/env'
import DemoContainer from 'prodDemo/DemoContainer'
import OkrsPage from 'okrs/OkrsPage'
import { LoggedUserContextProvider } from 'contexts/loggedUserContext/LoggedUserContextProvider'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { navigate } from 'services/utils/navigationUtils'
import ConfigurationPanel from 'resources/dashboard/ConfigurationPanel'

!isDev && initSentry()

const App = () => {
  const i18nProvider = useI18nProvider()

  if (i18nProvider === undefined) {
    return <div>Loading language bundle...</div>
  }

  return (
    <LangContextProvider>
      <LoggedUserContextProvider>
        <Admin
          dataProvider={kampaayDataProvider}
          loginPage={Login}
          authProvider={authProvider}
          layout={KLayout}
          dashboard={Dashboard}
          i18nProvider={i18nProvider}
          theme={kampaayTheme}
          queryClient={queryClient}
          catchAll={RedirectToNuxtAdmin}
        >
          <CustomRoutes>
            <Route path="/i18n-panel-be" element={<I18nBeComponent />} />
            <Route path="/i18n-panel-fe" element={<I18nFeComponent />} />
            <Route path="/i18n-panel-bo" element={<I18nBoComponent />} />
            <Route path="/okrs" element={<OkrsPage />} />
            <Route path="__dev__" element={<DemoContainer />} />
            <Route path="/config" element={<ConfigurationPanel />} />
          </CustomRoutes>
          {/* operations */}
          <Resource name="events" {...events} />
          <Resource name="eventsdraft" {...eventsDraft} />

          {/* accounting */}
          <Resource name="transaction" {...transaction} />
          {/* crm */}
          <Resource name="customers" {...customers} />
          <Resource name="kampaayers" {...kampaayers} />
          <Resource name="organization" {...organization}>
            {[
              ...organizationBudgetRoutes,
              ...organizationMembershipRoutes,
              ...organizationSubGroupRoutes,
              ...organizationEventPlaceholdersRoutes,
              ...organizationCalendarRoutes
            ].map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={<route.element />}
              />
            ))}
          </Resource>
          {/* catalog */}
          <Resource name="drinksections" {...drinksSection} />
          <Resource name="drinks" {...drink} />
          <Resource name="foods" {...food} />
          <Resource name="foodsections" {...foodsSection} />
          <Resource name="foodsupselling" {...foodupselling} />
          <Resource name="musics" {...music} />
          <Resource name="musicsections" {...musicSections} />
          <Resource name="rents" {...rent} />
          <Resource name="rentsections" {...rentSections} />
          <Resource
            name="virtualexperiencessections"
            {...virtualexperiencesSection}
          />
          <Resource name="virtuals" {...virtualexperiences} />
          <Resource name="virtualupselling" {...virtualexperiencesupselling} />
          <Resource name="professionalsections" {...professionalSections} />
          <Resource name="professionals" {...professionalFigures} />
          <Resource name="locations" {...location} />
          <Resource name="locationupselling" {...locationUpselling} />
          <Resource name="locationfeatures" {...locationsFeatures} />
          <Resource name="locationsections" {...locationSections} />
          <Resource name="physicals" {...physicalexperiences} />
          <Resource name="physicalsections" {...physicalexperiencesSection} />
          <Resource
            name="physicalupselling"
            {...physicalexperiencesUpselling}
          />
          <Resource name="rules" {...rules} />
          {/* catalog */}
          <Resource name="facets" {...facets} />
          <Resource name="logistics" {...logistics} />
          <Resource name="servicesconfig" list={servicesConfig.edit} />
          <Resource name="area" {...area} />
          <Resource name="mailingList" {...mailingList} />
          {/* Organization EMS tailorings */}
          <Resource
            name="organization/:id/tailoring/foods"
            list={<FoodTailoringsEdit />}
          />
          <Resource
            name="organization/:id/tailoring/locations"
            list={<LocationTailoringsEdit />}
          />
          <Resource
            name="organization/:id/tailoring/drinks"
            list={<DrinksTailoringsEdit />}
          />
          <Resource
            name="organization/:id/tailoring/virtuals"
            list={<VirtualTailoringsEdit />}
          />
          <Resource
            name="organization/:id/tailoring/physicals"
            list={<PhysicalTailoringsEdit />}
          />
          <Resource
            name="organization/:id/tailoring/musics"
            list={<MusicTailoringsEdit />}
          />
          <Resource
            name="organization/:id/tailoring/rents"
            list={<RentTailoringsEdit />}
          />
          <Resource
            name="organization/:id/tailoring/professionals"
            list={<ProfessionalTailoringsEdit />}
          />
          <Resource
            name="organization/:id/tailoring/foodsupselling"
            list={<FoodUpsellingTailoringsEdit />}
          />
          <Resource
            name="organization/:id/tailoring/virtualupselling"
            list={<VirtualUpsellingTailoringsEdit />}
          />
          <Resource
            name="organization/:id/tailoring/physicalupselling"
            list={<PhysicalUpsellingTailoringsEdit />}
          />
          <Resource
            name="organization/:id/tailoring/locationupselling"
            list={<LocationUpsellingTailoringsEdit />}
          />
          <Resource
            name="organization/:id/tailoring/logistics"
            list={<LogisticTailoringsEdit />}
          />
          {PocResources()}
        </Admin>
      </LoggedUserContextProvider>
    </LangContextProvider>
  )
}

const RedirectToNuxtAdmin = () => {
  const router = useLocation()

  useEffect(() => {
    navigate(`${router.pathname.substring(1)}${router.search}`, 'vue-app')
  })

  return <></>
}

export default App
