import React, { type FC, useCallback } from 'react'
import styles from 'components/UI/Organization/Calendar/ItemEvents/OrganizationCalendarItemEvents.module.css'
import { Link, useCreatePath, useTranslate } from 'react-admin'
import type {
  LinkedConcierge,
  LinkedEvent
} from 'services/api/entities/event-placeholder/types/internal/event-placeholder-models'
import { getCalendarItemResourceName } from 'services/utils/organization/calendar'

type Props = {
  items: LinkedEvent[] | LinkedConcierge[]
}

const OrganizationCalendarItemEvents: FC<Props> = ({ items }) => {
  const translate = useTranslate()
  const createPath = useCreatePath()

  const createItemPath = useCallback((item: LinkedEvent | LinkedConcierge) => {
    return createPath({
      resource: `/${getCalendarItemResourceName(item)}`,
      id: item.id,
      type: 'edit'
    })
  }, [])

  return (
    <div className={styles.eventsRowColumn}>
      <span>
        {translate('resources.organization.calendar.popover.linkedEvents')}
      </span>
      <div className={styles.eventsRowItems}>
        {items.map((item) => (
          <Link
            key={item.id}
            to={createItemPath(item)}
            target="_blank"
            className={styles.eventsRowItem}
          >
            {item.name || `${translate('common.label.event')} #${item.id}`}
          </Link>
        ))}
      </div>
    </div>
  )
}

export default OrganizationCalendarItemEvents
