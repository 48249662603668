import { Food, Logistic } from '@kampaay/common'
import {
  formatOptionedProduct,
  OptionedProductCSV
} from 'services/csv/entities/catalog/common/optionedProduct'
import {
  formatWithIdAndName,
  WithIdAndNameCSV
} from 'services/csv/entities/catalog/common/withIdAndName'
import { CSVEntityDescriptor, CSVEntityFormatFn } from 'services/csv/types'

type FoodCSV = OptionedProductCSV & {
  'Booking time-limit': number | undefined
  Logistic: WithIdAndNameCSV[]
}

const formatFood: CSVEntityFormatFn<Food> = (
  { advanceReservationMin, ...rest },
  { logistics: relatedLogistics }: { logistics: Logistic[] }
): FoodCSV => {
  return {
    ...formatOptionedProduct(rest),
    'Booking time-limit': advanceReservationMin,
    Logistic: relatedLogistics?.map(formatWithIdAndName)
  }
}

export const foods: CSVEntityDescriptor<Food> = {
  format: formatFood,
  relatedRecords: [
    {
      key: 'logistics',
      resource: 'logistics'
    }
  ]
}
