import React, { useState } from 'react'
import { FormTab, FormTabProps } from 'react-admin'
import { useFormState } from 'react-hook-form'

type Props = FormTabProps

/**
 * this custom tab improves drastically the performance of the form
 * because it does not render the components if this tab is not active preventing the
 * rendering of each of its fields when the state changes this allows to us to improve
 * performances of event and serviceCfg forms
 */
const KFormTab: React.FC<Props> = ({ hidden, children, ...rest }) => {
  const [shouldNotRender, setShouldNotRender] = useState(false)

  // if the form has a validation error we need to show all tabs in order to properly have the errored tabs show the red header
  const { isValid } = useFormState(rest.value as any)

  // since many inputs when rendering default their values we need to wait for the form to render before unmounting
  // the tabs if hidden, so that the record gets correctly populated with the initial values
  setTimeout(() => setShouldNotRender(true), 2000)

  return (
    <FormTab {...rest} hidden={hidden}>
      {shouldNotRender && hidden && isValid ? null : children}
    </FormTab>
  )
}

export default KFormTab
