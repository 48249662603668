import React from 'react'
import { RAFormFC } from 'types/common'
import { useForm } from 'hooks/useForm'
import LocationByRulePricingInput from 'components/FormComponents/pricing/byRules/LocationByRulePricingInput'
import { LocationPricingRuleSet, ProductTailoring } from '@kampaay/common'

const LocationTailoringsInput: RAFormFC = ({ source }) => {
  const { useGetValue } =
    useForm<ProductTailoring<LocationPricingRuleSet>>(source)
  const hasCustomPrice = useGetValue('hasCustomPrice')

  // =======================
  // JSX
  // =======================
  return (
    <>
      {hasCustomPrice ? <LocationByRulePricingInput source={source} /> : null}
    </>
  )
}

export default LocationTailoringsInput
