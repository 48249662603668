import {
  callUpdate,
  getApiCfg,
  getPath
} from 'services/api/dataProvider/common'
import invalidateDependentQueries from 'services/api/dataProvider/dependencies'
import { UpdateManyParams, UpdateManyResult } from 'ra-core'
import { APIEntity } from 'services/api/entities'
import { KConsole } from 'services/console'

export const exportForPOUpdateMany = async (
  resource: string | undefined = 'transactionexportforpurchaseorder',
  params: UpdateManyParams<any>
): Promise<UpdateManyResult<any>> => {
  KConsole.log('CUSTOM UPDATEMANY', resource, params)
  const apiCfg = getApiCfg(resource as APIEntity)

  const result = await callUpdate(apiCfg, getPath(apiCfg), 'PATCH', params.data)

  await invalidateDependentQueries(resource as APIEntity)
  return { data: [result?.data] }
}
