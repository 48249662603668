import React, { PropsWithChildren, useEffect } from 'react'
import KFormTab from 'components/Forms/KForms/KFormTab'
import { BooleanField, Labeled } from 'react-admin'
import PaymentMethodGeneralInput from 'resources/operations/events/eventsFormTabs/PaymentMethodGeneralInput'
import KNumberInput from 'components/Inputs/KInputs/KNumberInput/KNumberInput'
import {
  EventItem,
  MappedEnrichedEvent
} from 'services/api/entities/events/types/internal/event-models'
import { useKRecordContext } from 'hooks/useKRecordContext'
import EditCustomerField from 'resources/operations/events/EventsComponents/EditCustomerField'
import LabeledTextField from 'components/Fields/LabeledFields/LabeledTextField'
import { Button } from '@mui/material'
import KTextInput from 'components/Inputs/KInputs/KTextInput/KTextInput'
import QuestionRepliesAccordion from 'resources/operations/events/EventsComponents/CheckoutQuestionReply/QuestionRepliesAccordion'
import { someRepliesIsFilled } from 'resources/operations/events/utils'
import { useForm } from 'hooks/useForm'
import { RAFormFC } from 'types/common'
import { useGeoareaPreset } from 'hooks/useGeoAreaPreset'
import EEAssigneeSelectInput from 'resources/operations/events/EEAssigneeSelectInput'
import KDateTimeInput from 'components/Inputs/KInputs/KDateTimeInput'
import EventGeoareaPresetInput from 'components/FormComponents/Event/EventGeoareaPresetInput'
import EventBudgetInput from 'resources/operations/events/common/EventBudgetInput'
import { EventColorSelectInput } from 'components/FormComponents/EventColorSelectInput'
import { navigate } from 'services/utils/navigationUtils'

type ConciergeEventGeneralTab = PropsWithChildren<{
  // this slot is used for appending react elements
  bottomSlot?: React.ReactElement | null
}>

const ConciergeEventGeneralTab: RAFormFC<ConciergeEventGeneralTab> = ({
  children,
  bottomSlot,
  source,
  ...props
}) => {
  const { useGetValue, useSetValue, getSource } =
    useForm<MappedEnrichedEvent>(source)
  const setBudgetId = useSetValue('budgetId')
  const record = useKRecordContext<EventItem>()

  // question replies
  const customerOrganizationId = record && record.customerOrganizationId
  const checkoutQuestionResponses = record && record.checkoutQuestionResponses
  const showReplies =
    !!customerOrganizationId && checkoutQuestionResponses?.length
  const expandCheckoutRepliesAccordion =
    checkoutQuestionResponses && someRepliesIsFilled(checkoutQuestionResponses)

  const geoareaPresetId = useGetValue('geoAreaPreset')
  const { isGeoAreaPresetPublic } = useGeoareaPreset(geoareaPresetId!)

  const paymentMethod = useGetValue('paymentMethod')
  const budgetId = useGetValue('budgetId')

  useEffect(() => {
    if (paymentMethod !== 'organizationBudget' && budgetId !== undefined) {
      setBudgetId(undefined)
    }
  }, [paymentMethod])

  // =====================
  // JSX
  // =====================
  return (
    <KFormTab label="form.tabs.general" {...props}>
      {children}

      <EEAssigneeSelectInput
        sx={{ width: '22em' }}
        source={getSource('kampaayerCoordinators')}
      />

      <EventColorSelectInput source={getSource('color')} />

      {/* If our event has conciergeId we forbid to users the possibilty of change eventStatus */}
      <LabeledTextField source="eventStatus" label="form.fields.eventStatus" />

      {record?.confirmedInSf !== undefined && (
        <Labeled label="form.fields.confirmedInSf">
          <BooleanField source={'confirmedInSf'} />
        </Labeled>
      )}

      <EditCustomerField customerEditable={isGeoAreaPresetPublic} />

      <PaymentMethodGeneralInput />
      {paymentMethod === 'organizationBudget' && (
        <EventBudgetInput customerId={record?.customer} />
      )}

      <KDateTimeInput source="startDate" label="form.fields.startDate" />
      <KDateTimeInput source="endDate" label="form.fields.endDate" />

      <KNumberInput source="guestsNumber" label="form.fields.guests" />

      <EventGeoareaPresetInput />

      <KTextInput source="fullAddress" label="form.fields.address" />

      {showReplies ? (
        <QuestionRepliesAccordion
          defaultExpanded={expandCheckoutRepliesAccordion}
        />
      ) : null}

      <Button
        variant="contained"
        onClick={() => navigate(`concierge/${record?.conciergeId}`, 'vue-app')}
        style={{ marginTop: '4em' }}
      >
        Go to the Concierge Event
      </Button>

      {bottomSlot}
    </KFormTab>
  )
}

export default ConciergeEventGeneralTab
