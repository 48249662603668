import { CheckoutQuestionReply } from '@kampaay/common'
import { useGetOne } from 'react-admin'
import { OrganizationBase } from 'services/api/entities/crm/organizations/types/internal/organization-model'
import { CheckoutQuestion } from 'services/api/entities/services-config/types/internal/question-models'
import { Customer, OrganizationMembership } from '@kampaay/common'

const mapQuestionsToReplies = (
  checkoutQuestions: CheckoutQuestion[]
): CheckoutQuestionReply[] =>
  checkoutQuestions.map((question) => ({
    id: question.id,
    type: question.type,
    question: question.text,
    response: '',
    responses: []
  }))

/**
 * retrieve checkout questions replies only related to
 * a PMI's organization
 *
 * @param customerId
 */
const useGetEventCheckoutRepliesFromCustomer = (
  customerId: number | undefined
) => {
  // fetch full customer data
  const {
    data: customerData,
    isLoading: customerDataFetchIsLoading,
    error: customerDataFetchError
  } = useGetOne<Customer>(
    'customers',
    {
      id: customerId ?? 0 // the zero 0 will stop the compiler complaining required id
    },
    { enabled: !!customerId } // stop fetching if id is not defined
  )

  const membership = useGetOne<OrganizationMembership>(
    'organizationmembership',
    {
      id: customerData?.membershipId ?? 0
    },
    { enabled: !!customerData?.membershipId }
  )

  // fetch organization data by id
  const {
    data: organizationData,
    isLoading: organizationDataFetchIsLoading,
    error: organizationDataFetchError
  } = useGetOne<OrganizationBase>(
    'organization',
    {
      id: membership.data?.organizationId ?? 0 // the zero 0 will stop the compiler complaining required id
    },
    { enabled: !!membership.data?.organizationId }
  )

  // mapping checkout questions to checkoutQuestionReplies
  const checkoutQuestions = organizationData?.checkoutQuestions || []
  const questionReplies = mapQuestionsToReplies(checkoutQuestions)

  return {
    isLoading: customerDataFetchIsLoading || organizationDataFetchIsLoading,
    error: customerDataFetchError || organizationDataFetchError,
    questionReplies: questionReplies,
    customerData
  }
}

export default useGetEventCheckoutRepliesFromCustomer
