import { ListAlt } from '@mui/icons-material'
import PhysicalExperienceSectionList from 'resources/catalog/physicalexperiences/sections/PhysicalExperienceSectionList'
import PhysicalExperienceSectionEdit from 'resources/catalog/physicalexperiences/sections/PhysicalExperienceSectionEdit'
import PhysicalSectionCreate from 'resources/catalog/physicalexperiences/sections/PhysicalSectionCreate'

const physicalExperienceIndex = {
  icon: ListAlt,
  list: PhysicalExperienceSectionList,
  edit: PhysicalExperienceSectionEdit,
  create: PhysicalSectionCreate
}

export default physicalExperienceIndex
