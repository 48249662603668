import { APICfg } from 'services/api/api'
import { transactionExportForPurchaseOrderParse } from 'services/api/entities/accounting/transactionExportForPurchaseOrder/parse'
import { transactionExportForPurchaseOrderWrite } from 'services/api/entities/accounting/transactionExportForPurchaseOrder/write'
import { exportForPOUpdateMany } from 'services/api/entities/accounting/transactionExportForPurchaseOrder/patch'

const apiCfg: APICfg = {
  endpoint: 'event-account/export',
  UPDATE_METHOD: 'PATCH',
  updateMany: exportForPOUpdateMany,
  parse: transactionExportForPurchaseOrderParse,
  write: transactionExportForPurchaseOrderWrite
}

export default apiCfg
