import { TabbedForm, TabbedFormProps } from 'react-admin'
import React from 'react'
import ReactHookDevtool from 'components/DebugUtils/ReactHookDevtool'
import { useFormVS } from 'hooks/useFormVS'
import ValidationErrorLogger from 'components/DebugUtils/ValidationErrorLogger'
import { yupResolver } from '@hookform/resolvers/yup'
import KDefaultFormToolbar from 'components/Toolbars/KDefaultFormToolbar'
import KFormContextProvider from 'contexts/kform-context/KFormContextProvider'
import { KErrorNotifier } from 'components/Forms/KForms/KSimpleForm'
import { isProduction } from 'services/env'
import { KSaveButtonExternalProps } from 'components/Buttons/KSaveButton'

type KTabbedFormProps = Pick<TabbedFormProps, 'children' | 'toolbar'> &
  KSaveButtonExternalProps & {
    showHeader?: boolean
  }
const hideHeader = {
  '& .MuiTabs-root': {
    visibility: 'hidden',
    maxHeight: 0,
    minHeight: 0
  }
}

/**
 * This is the wrapper of react-admin's TabbedForm.
 * We should always use this component instead of TabbedForm because provides more functionalities and resolver.
 * Pay attention the props passed to the tabs form don't work in a deep way so need spread!
 */
const KTabbedForm: React.FC<KTabbedFormProps> = ({
  children,
  redirectAfterCreate,
  mutationOptions,
  toolbar = (
    <KDefaultFormToolbar
      redirectAfterCreate={redirectAfterCreate}
      mutationOptions={mutationOptions}
    />
  ),
  showHeader = true,
  ...rest
}) => {
  const schema = useFormVS()

  return (
    // We need to put the context provider outside the form because TabbedForm clones its children and manipulated them and the provider would mess with that
    <KFormContextProvider schema={schema}>
      <TabbedForm
        toolbar={toolbar}
        resolver={schema && yupResolver(schema)}
        sx={!showHeader ? hideHeader : undefined}
        warnWhenUnsavedChanges={isProduction}
        {...rest}
      >
        {children}
        <ReactHookDevtool />
        <ValidationErrorLogger />
        <KErrorNotifier />
      </TabbedForm>
    </KFormContextProvider>
  )
}

export default KTabbedForm
