import { extendWithCtx } from 'services/native-extensions/extend'

export {}

declare global {
  interface Array<T> {
    remove(fn: (n: T) => boolean): void
    filterEvery(filterFns: ((n: T) => boolean)[]): T[]
    sum(fn?: (n: T) => T[keyof T]): number
  }
}

extendWithCtx(Array, 'remove', (that) => (fn) => {
  const idxToRemove = that.findIndex((t) => {
    return fn(t)
  })
  if (idxToRemove !== -1) {
    that.splice(idxToRemove, 1)
  }
})

extendWithCtx(
  Array,
  'filterEvery',
  (that) => (filterFns) =>
    that.filter((element) => filterFns.every((fn) => fn(element)))
)

extendWithCtx(Array, 'sum', (that) => (fn) => {
  return that.reduce((acc, e) => {
    const extracted = fn?.(e) ?? e
    if (typeof extracted !== 'number') {
      throw new TypeError(
        `ERROR in array.sum!! The result of the extractor function should be a number, but it is a ${typeof extracted} instead`
      )
    }
    return acc + extracted
  }, 0)
})
