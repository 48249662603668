import { LocalPizza } from '@mui/icons-material'
import FoodEdit from 'resources/catalog/food/product/FoodEdit'
import FoodCreate from 'resources/catalog/food/product/FoodCreate'
import FoodList from 'resources/catalog/food/product/FoodList'

const foodIndex = {
  icon: LocalPizza,
  list: FoodList,
  edit: FoodEdit,
  create: FoodCreate
}

export default foodIndex
