import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const isGeneralTabURL = (pathname: string): boolean => {
  const regex = /\/(events|eventsdraft)\/\d+\/\w+/
  const createRegex = /\/(events|eventsdraft)\/create\/\w+/

  return !(regex.test(pathname) || createRegex.test(pathname))
}

export const useIsGeneralTabURL = (): boolean => {
  const location = useLocation()
  const [isGeneralTab, setIsGeneralTab] = useState<boolean>(
    isGeneralTabURL(location.pathname)
  )
  useEffect(() => {
    setIsGeneralTab(isGeneralTabURL(location.pathname))
  }, [location])
  return isGeneralTab
}
