import { styled } from '@mui/material/styles'
import React from 'react'
import { pricingRulesTypeChoices } from 'services/cfg/form'
import KToolTip from 'components/UI/KTooltip'
import KSelectInput from 'components/Inputs/KInputs/KSelectInput'
import { useForm } from 'hooks/useForm'
import { KBooleanInput } from 'components/Inputs/KInputs/KBooleanInput'
import { RAFormFC } from 'types/common'
import { PricingRule } from '@kampaay/common'

const PREFIX = 'PricingRuleThenClauseInputGroup'

const classes = {
  fieldsBox: `${PREFIX}-fieldsBox`,
  fieldWidth: `${PREFIX}-fieldWidth`,
  clauseText: `${PREFIX}-clauseText`
}

const StyledDiv = styled('div')(() => ({
  [`&.${classes.fieldsBox}`]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '100px',
    width: '100%'
  },

  [`& .${classes.fieldWidth}`]: {
    width: '300px'
  },

  [`& .${classes.clauseText}`]: {
    fontSize: '20px',
    fontWeight: 'bold',
    width: '10px',
    marginBottom: '20px'
  }
}))

const PricingRuleThenClauseInputGroup: RAFormFC = ({ source }) => {
  const { getSource } = useForm<PricingRule>(source)

  return (
    <StyledDiv className={classes.fieldsBox}>
      <span className={classes.clauseText}>THEN</span>
      <KSelectInput
        source={getSource('priceType')}
        label="Price type"
        defaultValue={pricingRulesTypeChoices[0].id}
        choices={pricingRulesTypeChoices}
        className={classes.fieldWidth}
      />
      <KToolTip title="tooltip.pricingRule.isExtra" placement="top">
        <KBooleanInput
          source={getSource('isExtra')}
          label="form.fields.isExtra"
          defaultValue={false}
        />
      </KToolTip>
    </StyledDiv>
  )
}

export default PricingRuleThenClauseInputGroup
