import React from 'react'

const Footer = () => {
  return (
    <>
      <div data-testid="footer--general-info" className="footer-text f-size-12">
        Kampaay SRL – Startup Innovativa, Via Malaga,4, 20143, Milano, Italia |
        P.Iva 11046500960
      </div>
      <div className="f-size-12">
        <a data-testid="footer--email" href="mailto:info@kampaay.com">
          info@kampaay.com
        </a>
        |
        <a
          data-testid="footer--website"
          rel="noreferrer"
          href="https://www.kampaay.com/"
          target="_blank"
        >
          www.kampaay.com
        </a>
      </div>
    </>
  )
}

export default Footer
