import React, { type MouseEvent, useState, useEffect } from 'react'
import { Button, Menu, MenuItem } from '@mui/material'
import { useKRecordContext } from 'hooks/useKRecordContext'
import { navigate } from 'services/utils/navigationUtils'
import { useDataProvider, useNotify, useTranslate } from 'react-admin'
import {
  ExpandMore,
  Group,
  Settings,
  ToggleOn,
  Visibility
} from '@mui/icons-material'
import type { EventItem } from 'services/api/entities/events/types/internal/event-models'
import type { Concierge, GuestManagement } from '@kampaay/common'
import { httpClient } from 'services/api/dataProvider/common'
import { useSearchParams } from 'react-router-dom'

const menuItemStyle = { columnGap: 5 }

type Props = {
  entity: 'event' | 'concierge'
}

const GuestManagementRequestMenu: React.FC<Props> = ({ entity }) => {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null)
  const [request, setRequest] = useState<GuestManagement | null>(null)
  const [searchParams] = useSearchParams()
  const record = useKRecordContext<EventItem | Concierge>()
  const translate = useTranslate()
  const dataProvider = useDataProvider()
  const notify = useNotify()

  const winstonError = searchParams.get('winston_error')

  useEffect(() => {
    if (record?.guestManagementRequestId) {
      dataProvider
        .getOne('guestmanagement', { id: record.guestManagementRequestId })
        .then(({ data }) => setRequest(data))
    }
  }, [record])

  useEffect(() => {
    if (winstonError) {
      notify('guestManagementRequest.winstonError', { type: 'error' })
    }
  }, [winstonError])

  const openMenu = (event: MouseEvent<HTMLElement>) =>
    setAnchor(event.currentTarget)

  const closeMenu = () => setAnchor(null)

  const openGuestManagement = () => {
    if (record?.guestManagementRequestId) {
      navigate(`guestManagement/${record?.guestManagementRequestId}`, 'vue-app')
    } else {
      const params = new URLSearchParams({
        source: JSON.stringify({
          [`${entity}Id`]: record?.id
        })
      })

      navigate(`guestManagement/create?${params}`, 'vue-app')
    }
  }

  const viewWinston = async () => {
    const { body } = await httpClient(
      `${process.env.REACT_APP_API_BASE_URL}/${entity}/${record?.id}/winston`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )

    window.open(body, '_blank')
  }

  return (
    <>
      <Button onClick={openMenu}>
        <Group />
        <span className="ml-1">
          {translate('guestManagementRequest.button')}
        </span>
        <ExpandMore />
      </Button>
      <Menu anchorEl={anchor} open={!!anchor} onClose={closeMenu}>
        {!!record?.guestManagementRequestId ? (
          <MenuItem style={menuItemStyle} onClick={openGuestManagement}>
            <Visibility />
            {translate('guestManagementRequest.button.view')}
          </MenuItem>
        ) : (
          <MenuItem style={menuItemStyle} onClick={openGuestManagement}>
            <ToggleOn />
            {translate('guestManagementRequest.button.activate')}
          </MenuItem>
        )}
        {!!request?.winstonUrlPath && (
          <MenuItem style={menuItemStyle} onClick={viewWinston}>
            <Settings />
            {translate('guestManagementRequest.button.open')}
          </MenuItem>
        )}
      </Menu>
    </>
  )
}

export default GuestManagementRequestMenu
