import {
  ColorButtons,
  FormatButtons,
  LevelSelect,
  LinkButtons,
  ListButtons,
  QuoteButtons,
  RichTextInput,
  RichTextInputProps
} from 'ra-input-rich-text'
import React from 'react'
import { KInputProps, useKInput } from 'hooks/useKInput/useKInput'

export type KRichTextInputProps = KInputProps & RichTextInputProps

const RichTextInputToolbar: React.FC = () => (
  <div
    style={{
      display: 'flex',
      padding: '5px',
      gap: '5px',
      marginRight: 'auto',
      marginBottom: '.6em'
    }}
  >
    <LevelSelect />
    <FormatButtons />
    <ColorButtons />
    <ListButtons />
    <LinkButtons />
    <QuoteButtons />
  </div>
)

const KRichTextInput: React.FC<KRichTextInputProps> = (props) => {
  const { parse = (v) => v } = props

  const { isRequired } = useKInput(props)

  return (
    <RichTextInput
      {...props}
      toolbar={<RichTextInputToolbar />}
      isRequired={isRequired}
      parse={(value: string) => {
        return parse(value === null || value === '' ? undefined : value)
      }}
    />
  )
}

export default KRichTextInput
