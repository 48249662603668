import { APICfg } from 'services/api/api'
import { eventWrite } from 'services/api/entities/events/write'
import { eventParse } from 'services/api/entities/events/parse'
import { eventGetOne } from 'services/api/entities/events/getOne'
import { eventUpdate } from 'services/api/entities/events/update'
import { eventCreate } from 'services/api/entities/events/create'

const apiCfg = {
  endpoint: 'event',
  parse: eventParse,
  write: eventWrite,
  getOne: eventGetOne,
  update: eventUpdate,
  create: eventCreate
} as APICfg

export default apiCfg
