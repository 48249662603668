import React from 'react'
import { styled } from '@mui/material/styles'
import { Button, FormTab, FormTabProps, useTranslate } from 'react-admin'
import BriefPdfRenderer from 'resources/operations/events/EventsComponents/PdfRenderers/BriefPdfRenderer'
import { printReactComponent } from 'services/pdf-print/pdf-print'
import { serviceDiscriminatorArray } from 'services/cfg/form'
import { Divider } from '@mui/material'
import { RAFormFC } from 'types/common'
import { useForm } from 'hooks/useForm'
import LocalizedHeader from 'components/Fields/Localized/LocalizedHeader'
import { MappedEnrichedEvent } from 'services/api/entities/events/types/internal/event-models'

const PREFIX = 'BriefRecapTab'

const classes = {
  marginY: `${PREFIX}-marginY`,
  bold: `${PREFIX}-bold`
}

const StyledFormTab = styled(FormTab)({
  [`& .${classes.marginY}`]: {
    margin: '16px 0'
  },
  [`& .${classes.bold}`]: {
    fontWeight: 'bold'
  }
})

type Props = Omit<FormTabProps, 'label'>

const BriefRecapTab: RAFormFC<Props> = ({ source, ...props }) => {
  const { useGetValue } = useForm<MappedEnrichedEvent>(source)
  const translate = useTranslate()

  const surveyResponses = useGetValue('surveyResponses')

  const PdfRenderer: JSX.Element = BriefPdfRenderer({})!

  return (
    <StyledFormTab {...props} label="Event Brief">
      {!surveyResponses?.length ? (
        <div>...no responses have been submitted yet</div>
      ) : (
        <>
          {surveyResponses.map(
            ({ supplieredService, questionResponses }, idx) => (
              <div key={`question_header_${idx}`} className={classes.marginY}>
                <h2>
                  {(serviceDiscriminatorArray as string[]).includes(
                    supplieredService
                  )
                    ? translate(`form.headers.${supplieredService}`)
                    : supplieredService}
                </h2>
                {questionResponses?.map(({ question, response }, idx) => (
                  <div
                    key={`question_values_${idx}`}
                    className={classes.marginY}
                  >
                    <LocalizedHeader title={question} headingLevel="h3" />
                    <div className={classes.marginY}>{response}</div>
                  </div>
                ))}
                <Divider />
              </div>
            )
          )}

          <Button
            label="Download Pdf"
            variant="contained"
            onClick={printReactComponent(PdfRenderer)}
          />
        </>
      )}
    </StyledFormTab>
  )
}

export default BriefRecapTab
