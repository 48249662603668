import { buildFilterConfig } from 'services/utils/filters'
import { FILTER_SOURCES } from '@kampaay/common'

export const EMAIL_MESSAGE_FILTERS = [
  buildFilterConfig('nullableBoolean', {
    source: FILTER_SOURCES.hasAttachment.eq,
    allowFalseValues: false,
    alwaysOn: true,
    label: 'form.fields.hasAttachments'
  })
]
