import { activityStatusChoices } from 'services/cfg/form'
import { FILTER_SOURCES } from '@kampaay/common'
import { buildFilterConfig } from 'services/utils/filters'
import { CATALOG_FILTERS } from 'services/filters/entities/catalog'

export const LOCATION_FILTERS = [
  ...CATALOG_FILTERS,
  buildFilterConfig('text', {
    label: 'form.fields.address',
    source: FILTER_SOURCES.address.contains,
    alwaysOn: true
  }),
  buildFilterConfig('text', {
    label: 'form.fields.nickname',
    source: FILTER_SOURCES.nickname.contains,
    alwaysOn: false
  }),
  buildFilterConfig('text', {
    label: 'form.fields.supplierResponsibleName',
    source: FILTER_SOURCES.supplierName.contains,
    alwaysOn: false
  }),
  buildFilterConfig('number', {
    label: 'form.fields.peopleCapacity',
    source: FILTER_SOURCES.peopleCapacity.gte,
    alwaysOn: false
  }),
  buildFilterConfig('number', {
    label: 'form.fields.roomCapacity',
    source: FILTER_SOURCES.roomCapacity.gte,
    alwaysOn: false
  }),
  buildFilterConfig('select', {
    label: 'form.fields.locationStatus',
    alwaysOn: false,
    source: FILTER_SOURCES.status.eq,
    choices: activityStatusChoices
  }),
  buildFilterConfig('text', {
    label: 'form.fields.facetCode',
    alwaysOn: false,
    source: FILTER_SOURCES.facetCode.contains
  })
]
