import React from 'react'
import KSelectInput, {
  KSelectInputProps
} from 'components/Inputs/KInputs/KSelectInput'
import LocalizedWithChoicesInput from 'components/Inputs/Localized/LocalizedWithChoicesInput'

type Props = Omit<KSelectInputProps, 'disabled'> & {
  forceDisabled?: boolean
}

const LocalizedSelectInput: React.FC<Props> = ({
  forceDisabled = false,
  ...props
}) => (
  <LocalizedWithChoicesInput {...props} disabled={forceDisabled}>
    <KSelectInput />
  </LocalizedWithChoicesInput>
)

export default LocalizedSelectInput
