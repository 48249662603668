import React from 'react'
import SectionWrapper from 'components/UI/SectionWrapper'
import DrinkSectionFormContent from 'resources/catalog/drink/sections/DrinkSectionFormContent'
import { KSimpleForm } from 'components/Forms/KForms/KSimpleForm'
import KEdit from 'components/Forms/KEdit/KEdit'

const DrinkSectionEdit: React.FC = () => (
  <SectionWrapper title={'resources.drinksections.editSection'}>
    <KEdit>
      <KSimpleForm>
        <DrinkSectionFormContent />
      </KSimpleForm>
    </KEdit>
  </SectionWrapper>
)

export default DrinkSectionEdit
