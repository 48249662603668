import { createContext, useContext } from 'react'

type KFormContextType = {
  mandatoryFieldsSources: string[]
}

export const KFormContext = createContext<KFormContextType>({
  mandatoryFieldsSources: []
})

export const useKFormContext = () => {
  const context = useContext(KFormContext)
  if (context === undefined) {
    throw new Error(
      'hook useLangContext must be used within a LangContextProvider'
    )
  }
  return context
}
