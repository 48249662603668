import {
  OptionedProduct,
  OptionGroup,
  OptionItem,
  parseCsvFields
} from '@kampaay/common'
import {
  formatProductWithUpselling,
  ProductWithUpsellingCSV
} from 'services/csv/entities/catalog/common/productWithUpselling'
import {
  formatStandardPricing,
  StandardPricingCSV
} from 'services/csv/entities/catalog/common/standardPricing'

export type OptionedProductCSV = ProductWithUpsellingCSV & {
  Pricing: StandardPricingCSV[]
  'Min quantity': number
  Options: OptionCSV[][]
}

type OptionCSV = {
  Name: string
  'Max selection': string
  'Item name': string
  'Item description': string
}

const BASE_OPTION_GROUP = {
  Name: ' ',
  'Max selection': ' ',
  'Item name': ' ',
  'Item description': ' '
}

export const formatOptionItem = (item: OptionItem) => ({
  ...parseCsvFields({
    'Item name': item.name,
    'Item description': item.description
  })
})

export const formatOptionGroups = (options: OptionGroup[]): OptionCSV[][] =>
  options.map((optGrp) =>
    optGrp.items.map((itm, idx) => {
      // if the option item is the first we leave the option group fields empty
      const optionGroupNameAndDescription =
        idx === 0
          ? parseCsvFields({
              Name: optGrp.name,
              Description: optGrp.description
            })
          : {}

      return {
        ...BASE_OPTION_GROUP,
        ...optionGroupNameAndDescription,
        ...formatOptionItem(itm)
      }
    })
  )

export const formatOptionedProduct = ({
  pricing,
  minQuantity,
  options,
  ...rest
}: OptionedProduct): OptionedProductCSV => ({
  ...formatProductWithUpselling(rest),
  Pricing: pricing.map(formatStandardPricing),
  ['Min quantity']: minQuantity,
  Options: formatOptionGroups(options)
})
