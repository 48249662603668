import KChip from 'components/UI/KChip'
import LocalizedChipField from 'components/Fields/Localized/LocalizedChipField'
import React from 'react'
import { ArrayField, SingleFieldList } from 'react-admin'
import { LogisticSelection } from 'services/api/entities/events/types/internal/selections'

type Props = {
  hasIntolerances: boolean
  logistics: LogisticSelection[]
}

const EventListServicesTableFoodAdditionalInfo: React.FC<Props> = ({
  hasIntolerances,
  logistics
}) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', marginTop: '16px' }}>
      {hasIntolerances && (
        <KChip
          customBackgroundColor="error"
          size="small"
          label="Intolerances"
        />
      )}
      <ArrayField record={{ logistics }} source="logistics">
        <SingleFieldList linkType={false}>
          <LocalizedChipField source="name" size="small" />
        </SingleFieldList>
      </ArrayField>
    </div>
  )
}

export default EventListServicesTableFoodAdditionalInfo
