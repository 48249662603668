/**
 * Extracts array indices from the result of a `getSource()` function
 *
 * #ra4Migration this function is now broken since sources when expressed as strings
 * are in dot notation (users.1.name) and not square bracket notation (users[1].name)
 * FIXME!!
 *
 * @example
 * getIndicesFromGetSourceString('pricing[0].pricingRules[2].') // [0,2]
 *
 * @param s `getSource('')`  result string
 */
export const getIndicesFromGetSourceString = (s: string): number[] =>
  s.match(/([0-9]+)/g)?.map((e) => Number(e))!

/**
 * @example
 *
 * const ExampleComponent: RAFormFC = ({ source }) => {
 *  const getSource = useGetSource<RecordType>(source)
 *
 *  // if we are in an ArrayInput this
 *  // returns the current index in witch we are
 *  const index = useGetIndex(source)
 *
 *  return (
 *    <>
 *      <KTextInput source={getSource('name')} />
 *      <KTextInput source={getSource('description')} />
 *    </>
 *  )
 * }
 *
 * @param source the source from the parent component
 * @returns
 */
export const useGetIndex = (source: string | undefined) => {
  if (!source)
    throw new Error(
      'useGetIndex: you are trying to pass a source undefined this means that or you are not inside an ArrayInput or you are not passing the source prop to the parent component'
    )
  const indexes = getIndicesFromGetSourceString(source)
  return indexes[indexes.length - 1]
}
