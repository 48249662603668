import React from 'react'
import TailoredInput from 'components/Inputs/Tailored/TailoredInput'
import ProductCommonFormContent, {
  ProductCommonFormContentProps
} from 'components/Forms/ProductCommonFormContent'
import KImageInput from 'components/Inputs/KImageInput'

export type MainProductFormContentProps = ProductCommonFormContentProps & {
  disableTailoredValidation?: boolean
}

const MainProductFormContent: React.FC<MainProductFormContentProps> = ({
  facetFields,
  children,
  disableTailoredValidation = false,
  ...props
}) => {
  return (
    <ProductCommonFormContent
      {...props}
      slotBeforeFacets={<KImageInput multiple />}
    >
      {facetFields}
      {children}

      <TailoredInput
        disableValidation={disableTailoredValidation}
        validationMessage="form.fields.productInSectionValidation"
      />
    </ProductCommonFormContent>
  )
}

export default MainProductFormContent
