import { KBooleanInput } from 'components/Inputs/KInputs/KBooleanInput'
import React from 'react'
import { RAFormFC, ServiceCollectionName } from 'types/common'
import CantHideServiceAlert from 'components/FormComponents/Organization/CantHideServiceAlert'
import { useCatalogProductsMap } from 'hooks/eventPackage/useCatalogProductsMap'

type OrganizationHiddenServiceToggleProps = {
  source: string
  organizationId: number
  label?: string
  servicesToCheck: ServiceCollectionName | ServiceCollectionName[]
}

const OrganizationHiddenServiceToggle: RAFormFC<
  OrganizationHiddenServiceToggleProps
> = ({ source, organizationId, label, servicesToCheck }) => {
  if (typeof servicesToCheck === 'string') {
    servicesToCheck = [servicesToCheck]
  }

  const servicesLists = useCatalogProductsMap()

  const hasPrivateService = servicesToCheck.some((service) =>
    servicesLists[service].some(({ organizations }) =>
      organizations.includes(organizationId)
    )
  )

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <KBooleanInput
        source={source}
        label={label}
        disabled={hasPrivateService}
      />
      {hasPrivateService ? <CantHideServiceAlert /> : <div />}
    </div>
  )
}

export default OrganizationHiddenServiceToggle
