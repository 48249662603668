import React from 'react'
import { TabbedFormProps } from 'react-admin'
import LabeledNumberField from 'components/Fields/LabeledFields/LabeledNumberField'
import BaseProductTab from 'resources/operations/events/eventsFormTabs/BaseProductTab/BaseProductTab'
import BriefRecapTab from 'resources/operations/events/eventsFormTabs/BriefRecapTab'
import DrinksFormTab from 'resources/operations/events/eventsFormTabs/DrinkTab/DrinksFormTab'
import PhysicalFormTab from 'resources/operations/events/eventsFormTabs/ExperienceTab/PhysicalFormTab'
import VirtualFormTab from 'resources/operations/events/eventsFormTabs/ExperienceTab/VirtualFormTab'
import FoodFormTab from 'resources/operations/events/eventsFormTabs/FoodTab/FoodFormTab'
import LocationFormTab from 'resources/operations/events/eventsFormTabs/LocationTab/LocationFormTab'
import ProfessionalsTab from 'resources/operations/events/eventsFormTabs/ProfessionaTab/ProfessionalsTab'
import KTabbedForm from 'components/Forms/KForms/KTabbedForm'
import KTextInput from 'components/Inputs/KInputs/KTextInput/KTextInput'
import StandardEventGeneralTab from 'resources/operations/events/eventsFormTabs/StandardEventGeneralTab'
import PrintEventSummaryButton from 'components/Buttons/PrintEventSummaryButton'
import FlexBox from 'components/UI/layout/FlexBox'
import { useIsGeneralTabURL } from 'hooks/useIsGeneralTabURL'

const EventEditInputs: React.FC<Pick<TabbedFormProps, 'toolbar'>> = ({
  toolbar
}) => {
  const isGeneralTab = useIsGeneralTabURL()

  ///////////////////////////////
  // JSX
  ///////////////////////////////
  return (
    <KTabbedForm toolbar={toolbar} showHeader={!isGeneralTab}>
      <StandardEventGeneralTab editMode>
        <FlexBox justifyContent="space-between">
          <LabeledNumberField source="id" label="form.fields.eventId" />
          <PrintEventSummaryButton />
        </FlexBox>
        <KTextInput source="name" label="form.fields.name" />
      </StandardEventGeneralTab>
      <PhysicalFormTab path="physicals" />
      <LocationFormTab path="locations" />
      <FoodFormTab path="foods" />
      <DrinksFormTab path="drinks" />
      <ProfessionalsTab path="professionals" />
      <BaseProductTab product="music" path="musics" />
      <BaseProductTab product="rent" path="rents" />
      <VirtualFormTab path="virtuals" />
      <BriefRecapTab path="brief" />
    </KTabbedForm>
  )
}

export default EventEditInputs
