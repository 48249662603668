import PecSdiInputs from 'components/Inputs/PecSdiInputs'
import VatCodeInput from 'components/Inputs/VatCodeInput'
import React from 'react'
import { countrySwitchFC } from 'services/geo-management'

export const CustomerFormITAExt = countrySwitchFC({
  IT: ({ disabled }: { disabled?: boolean }) => (
    <>
      <VatCodeInput disabled={disabled} />
      <PecSdiInputs />
    </>
  )
})
