import { Proposal, parseMultilangToCurrentLang } from '@kampaay/common'
import { Transaction } from 'services/api/entities/accounting/transaction/types/internal/transaction-models'
import {
  formatBillingInfoWithServiceLabel,
  getComputed,
  getEventOrProposalBasedBillingInfo,
  getProfessionalServiceAggregate,
  getServicesBillingInfoMap,
  roundAllObjectNumberValuesTo2Decimals
} from 'services/csv/entities/accounting/transaction/utils'
import { getFormattedCheckoutQuestionResponses } from 'services/csv/entities/operations/events/utils'
import { CSVEntityDescriptor, CSVEntityFormatFn } from 'services/csv/types'

const formatTransaction: CSVEntityFormatFn<Transaction> = async (
  {
    id,
    eventStatus,
    eventDate,
    monthYearDate,
    services,
    customerName,
    discount,
    customerPhone,
    customizations,
    invoiceExpiryDate,
    customerFiscalCode,
    professionalService,
    checkoutQuestionResponses,
    geoAreaPresetFull,
    geoAreaPreset: _geoAreaPreset,
    ...rest
  },
  { proposalId: relatedProposals }: { proposalId: Proposal[] }
) => {
  // Because professional service is composed by a list of services (sections)
  // we need to aggregate them to get the billing info for the whole professional service
  const professionalServiceAggregate =
    getProfessionalServiceAggregate(professionalService)

  const serviceBillingInfos = [services, professionalServiceAggregate]
    .flat()
    .map(getComputed)

  const fetchedProposal = relatedProposals?.at(0) // when exporting a single transaction relatedProposals is undefined, to be fixed in the future

  const { eventOnlyBillingInfo, generalBillingInfo, outOfScopeBillingInfo } =
    getEventOrProposalBasedBillingInfo({
      serviceBillingInfos,
      customizations,
      professionalService,
      transactionRelatedProposal: fetchedProposal,
      eventDiscount: discount
    })

  const labeledGeneralBillingInfo = formatBillingInfoWithServiceLabel(
    generalBillingInfo,
    'General'
  )

  const labeledEventOnlyBillingInfo = formatBillingInfoWithServiceLabel(
    {
      price: eventOnlyBillingInfo.price,
      discountedPrice: eventOnlyBillingInfo.discountedPrice,
      costNoVat: eventOnlyBillingInfo.costNoVat,
      margin: eventOnlyBillingInfo.margin,
      discountedTotalMargin: eventOnlyBillingInfo.discountedTotalMargin,
      marginPerc: eventOnlyBillingInfo.marginPerc,
      discountedTotalMarginPerc: eventOnlyBillingInfo.discountedTotalMarginPerc
    },
    'General eventOnly'
  )

  const labeledOutOfScopeBillingInfo = formatBillingInfoWithServiceLabel(
    {
      price: outOfScopeBillingInfo.price,
      costNoVat: outOfScopeBillingInfo.costNoVat
    },
    'Out of scope'
  )

  const servicesBillingInfo = getServicesBillingInfoMap(serviceBillingInfos)

  const checkoutQuestions = getFormattedCheckoutQuestionResponses(
    checkoutQuestionResponses
  )

  const mapped = {
    // This casting prevents the id from being mapped to 2 decimal digits
    id: String(id),
    eventStatus,
    eventDate,
    monthYearDate,
    customerName,
    ...labeledGeneralBillingInfo,
    ...labeledEventOnlyBillingInfo,
    ...labeledOutOfScopeBillingInfo,
    ...rest,
    customerFiscalCode,
    ['Geoarea preset name']: parseMultilangToCurrentLang(
      geoAreaPresetFull?.name
    ),
    ['Geoarea preset nickname']: parseMultilangToCurrentLang(
      geoAreaPresetFull?.nickname
    ),
    customerPhone,
    invoiceExpiryDate,
    ...servicesBillingInfo,
    ...checkoutQuestions
  }

  return roundAllObjectNumberValuesTo2Decimals(mapped)
}

export const transaction: CSVEntityDescriptor<Transaction> = {
  format: formatTransaction,
  relatedRecords: [
    {
      key: 'proposalId',
      resource: 'proposal'
    }
  ]
}
