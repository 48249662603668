import React from 'react'
import TagFacet from 'components/Inputs/Facets/Wrappers/FacetTag'
import SkillFacetInput from 'components/Inputs/Facets/Wrappers/SkillFacetInput'

const ExperienceFacetSection = () => {
  return (
    <>
      <TagFacet />
      <SkillFacetInput />
    </>
  )
}

export default ExperienceFacetSection
