import React, { useCallback } from 'react'
import {
  Button,
  ChipField,
  ListContextProvider,
  useGetMany,
  useList,
  useTranslate
} from 'react-admin'
import CustomReferenceInput from 'components/Inputs/CustomReferenceInput'
import KAutocompleteInput from 'components/Inputs/KInputs/KAutocompleteInput'
import { useFieldArray, useFormContext } from 'react-hook-form'
import OrganizationBudgetMembershipsDatagrid from 'components/FormComponents/Organization/Budget/Memberships/OrganizationBudgetMembershipsDatagrid'
import { FILTER_SOURCES, OrganizationMembership } from '@kampaay/common'
import { useParams } from 'react-router-dom'

const OrganizationBudgetMemberships = () => {
  const { id: organizationId } = useParams()
  const translate = useTranslate()
  const { setValue, watch } = useFormContext()

  /**
   * Create a field array of members and watch for changes. Whenever we append a new element
   * to the list, a request to the API is triggered to get the data of all the members.
   */
  const { append, remove } = useFieldArray({ name: 'membershipsIds' })
  const membershipsIds = watch('membershipsIds')
  const { data } = useGetMany<OrganizationMembership>(
    'organizationmembership',
    {
      ids: membershipsIds
    }
  )

  //to keep the context separated with other list, add resource to useList
  const listContextMembers = useList({
    data,
    resource: 'organizationmembership'
  })
  const customer = watch('newMembership')

  /**
   * Append a new member to the field array of members manually
   * when the "Add member" button is clicked, and set the value
   * of the search input to undefined, so clear it.
   *
   * This is needed because we want the members table to be filled
   * with new data every time we add an element via the input.
   */
  const addMember = useCallback(() => {
    if (customer) {
      append(customer)
      setValue('newMembership', undefined)
    }
  }, [customer])

  /**
   * Remove a member from the field array of members manually
   * when the "Remove" button of the datagrid is clicked.
   *
   * This is needed because we want to remove the element from
   * the list and not delete it from the database completely.
   */

  const removeMembers = useCallback(() => {
    remove(
      listContextMembers.selectedIds.map((id) => membershipsIds.indexOf(id))
    )
  }, [listContextMembers, membershipsIds])

  return (
    <div className="w-full">
      <div className="flex w-full items-center justify-between">
        <div className="flex w-fit items-center gap-2">
          <h2>{translate('resources.organization.budgetMembers')}</h2>
          {membershipsIds && (
            <ChipField
              record={{ members: membershipsIds.length }}
              source="members"
            />
          )}
        </div>
        <div className="flex w-fit items-center gap-2">
          <CustomReferenceInput
            entity="organizationmembership"
            queryFilter={{
              [FILTER_SOURCES.organizationId.eq]: organizationId!
            }}
            filterFns={[(m) => !membershipsIds?.includes(m.id)]}
          >
            <KAutocompleteInput
              source="newMembership"
              label="form.fields.searchUser"
              optionText="userEmail"
              className="w-[250px]"
            />
          </CustomReferenceInput>
          <Button
            label="form.fields.addMember"
            onClick={addMember}
            disabled={!customer}
          />
        </div>
      </div>
      <div className="mt-4">
        {/* This context provider allows us to have a list of memberships */}
        <ListContextProvider value={listContextMembers}>
          <OrganizationBudgetMembershipsDatagrid
            data={data}
            removeMembers={removeMembers}
            bulkActions
          />
        </ListContextProvider>
      </div>
    </div>
  )
}

export default OrganizationBudgetMemberships
