import { ListAlt } from '@mui/icons-material'
import FoodSectionCreate from 'resources/catalog/food/sections/FoodSectionCreate'
import FoodSectionEdit from 'resources/catalog/food/sections/FoodSectionEdit'
import FoodSectionList from 'resources/catalog/food/sections/FoodSectionList'

const foodSectionIndex = {
  icon: ListAlt,
  list: FoodSectionList,
  edit: FoodSectionEdit,
  create: FoodSectionCreate
}

export default foodSectionIndex
