import { yupArray } from 'services/yup/custom-schemas/array'
import { useUpsellingVS } from 'services/validations/catalog/upselling'
import { UseValidationSchema } from 'services/yup'
import { useKGetList } from 'hooks/useKGetList'
import { hasConsistentFoods } from 'services/validations/catalog/food-upselling/foodUpselling.validations'
import { Food, FoodUpselling } from '@kampaay/common'

export const useFoodUpsellingVS: UseValidationSchema<FoodUpselling> = () => {
  const { list: foodsFull } = useKGetList<Food>('foods')

  return useUpsellingVS().shape({
    logistics: yupArray().min(1),
    logisticsFull: yupArray(),
    products: yupArray().test(hasConsistentFoods(foodsFull))
  })
}
