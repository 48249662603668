import type { WithCode, WithId } from '../../types/common'
import isEmpty from 'lodash/isEmpty'
import type {
  GenericObject,
  Paths,
  PathValue,
  ReverseMap,
  ValueOf
} from './types'
import { isDate } from '../date'
import type { Entries } from '../../types'

export * from './types'

/**
 * Returns the typed version of Object.keys
 *
 * @param obj
 * @returns
 */
export const keys = <T extends object>(obj: T): (keyof T)[] =>
  Object.keys(obj) as (keyof T)[]

/**
 * Creates an object with the same keys as object and values generated by running each own enumerable
 * string keyed property of object through iteratee. The iteratee is invoked with three arguments: (value, key, object).
 * @param {Function} mapFn
 * @param {Object} obj
 */
export const mapValues = <Source extends object, TargetVal>(
  mapFn: (value: ValueOf<Source>, key: keyof Source, obj: Source) => TargetVal,
  obj: Source
) =>
  keys(obj).reduce(
    (acc, key) => ({ ...acc, [key]: mapFn(obj[key], key, obj) }),
    {}
  ) as Record<keyof Source, TargetVal>

/**
 * Returns the id of the given object
 * @param item
 */
export const getId = <TObject extends WithId>(item: TObject) => item.id

export const getCode = <T extends WithCode>(item: T) => ({
  code: item.code
})

/**
 * Given an object, this function returns an object with the keys and values
 * of the original object swapped
 * @param input an object whose values are either strings, numbers or symbols
 * @returns an object with the keys and values of the original object swapped
 */
export const reverseMap = <T extends Record<keyof T, keyof any>>(input: T) =>
  Object.fromEntries(
    Object.entries(input).map(([key, value]) => [value, key])
  ) as ReverseMap<T>

/**
 * util to filter objects
 * @param keepFn filter function that accept as first param value and second key of object
 * @param obj object we want filter
 * @returns object with keys filtered
 */
export const filterObject = <T extends object>(
  keepFn: (val: ValueOf<T>, key?: keyof T, obj?: T) => boolean,
  obj: T
): Partial<T> =>
  keys(obj).reduce(
    (acc, key) => ({
      ...acc,
      ...(keepFn(obj[key], key, obj) ? { [key]: obj[key] } : {})
    }),
    {}
  )

/**
 * Gets the keys of an object or array, including the keys of nested objects,
 * but anything that is not an object or an array containing objects will return an array with an empty string
 * @param deepItem any value
 * @param includeArrayIndices if true, the function will include the indexes of the arrays in the path
 * @param previousPath parameter used to recursively build the path of the keys
 * @returns an array of string paths of the keys of the object.
 * An object like {cat: {name: 1, age: 2}, id: 3} will return ['cat.name', 'cat.age', 'id']
 * An array like [{name: 1, age: 2}, 3] will return ['name', 'age']
 * If includeArrayIndices is true, the array will return ['0.name', '0.age', '1']
 */

// TODO: improve type safety by using the Paths type
export const getDeepKeys = (
  deepItem: unknown,
  includeArrayIndices: boolean = false,
  previousPath?: string
): string[] => {
  if (Array.isArray(deepItem)) {
    return deepItem.length === 0
      ? [previousPath || '']
      : [
          ...new Set(
            deepItem
              .map((item, index) =>
                getDeepKeys(
                  item,
                  includeArrayIndices,
                  // If includeArrayIndices is true,
                  includeArrayIndices
                    ? // then check if the previousPath exists
                      previousPath
                      ? // If it exists, add the index to the path
                        `${previousPath}.${index}`
                      : // If it doesn't exist, set the path to the index
                        `${index}`
                    : // If includeArrayIndices is false, then use the previousPath
                      previousPath
                )
              )
              .flat()
          )
        ]
  }

  if (typeof deepItem === 'object' && !!deepItem) {
    return isEmpty(deepItem)
      ? [previousPath || '']
      : [
          ...new Set(
            [
              ...Object.entries(deepItem).map(([key, value]) => {
                const path = previousPath ? `${previousPath}.${key}` : key
                return getDeepKeys(value, includeArrayIndices, path)
              })
            ].flat()
          )
        ]
  }

  return [previousPath || '']
}

/**
 * Returns the typed version of Object.entries
 * @param obj
 */
export const entries = <T extends Object>(obj: T): Entries<T> =>
  Object.entries(obj) as any

type FromEntries<T extends PropertyKey, K> = Record<T, K>

export const fromEntries = <T extends PropertyKey, K>(
  entries: [T, K][]
): FromEntries<T, K> => Object.fromEntries(entries) as any

/**
 * NON-efficient way to compare 2 objects.
 * Use with caution & at your own risk.
 *
 * @param obj1
 * @param obj2
 * @returns true if obj are equals
 */
export const equals = (obj1: any, obj2: any) =>
  JSON.stringify(obj1) === JSON.stringify(obj2)

/**
 * util to check if object is empty
 * @param obj
 * @returns boolean
 */
export const objectIsEmpty = (obj: Object): boolean => {
  return Object.keys(obj).length === 0 && obj.constructor === Object
}

/**
 * This function is used to map the values of an object with an
 * async function, all the map functions are awaited and this fn
 * returns a promise of the whole object mapped
 * @param mapFn
 * @param obj
 */
export const asyncMapValues = async <Source extends object, TargetVal>(
  mapFn: (
    value: ValueOf<Source>,
    key: keyof Source,
    obj: Source
  ) => Promise<TargetVal>,
  obj: Source
) => {
  const arrayMapped = await Promise.all(
    keys(obj).map(async (key) => ({ [key]: await mapFn(obj[key], key, obj) }))
  )

  return arrayMapped.reduce((acc, curr) => ({ ...acc, ...curr }), {}) as Record<
    keyof Source,
    Awaited<TargetVal>
  >
}

/**
 * Given an object and a valid path to a property of that object, this function
 * returns an object containing the last key in the path and a plain
 * object containing the last key and its respective value
 * @param obj
 * @param path a path to an object property, even nested  e.g. 'a.b.c'
 * @returns {lastKey, lastKeyValueCouple}
 */
const getNestedPathInfo = <T extends GenericObject, P extends Paths<T, 4>>(
  obj: T,
  path: P
) => {
  if (typeof path === 'string') {
    const pList = path.split('.')
    const lastKey = pList.pop()
    const lastKeyValueCouple = pList.reduce(
      (fullObject: { [x: string]: any }, currentObjectKey: string | number) => {
        if (fullObject[currentObjectKey] === undefined)
          fullObject[currentObjectKey] = {}
        return fullObject[currentObjectKey]
      },
      obj
    )
    if (typeof lastKey !== 'undefined') {
      return { lastKey, lastKeyValueCouple }
    }
  }
  return undefined
}

/**
 * Given an object and a valid path to a property of that object, this function
 * returns the value of the nested property if it exists, otherwise
 * returns undefined
 * @param obj
 * @param path a path to an object property e.g. 'a.b.c'
 * @returns the value of the nested property if it exists, otherwise undefined
 */
export const getPathValue = <T extends GenericObject, P extends Paths<T>>(
  obj: T,
  path: P
): PathValue<T, P> | undefined => {
  const leaf = getNestedPathInfo(obj, path)
  if (leaf) {
    return leaf.lastKeyValueCouple[leaf.lastKey]
  }
}

/**
 * Given an object and a valid path to a property of that object, this function
 * sets a given value of the nested property if it exists, and returns the object
 * Otherwise, it just returns the object without any changes
 * @param obj
 * @param path a path to an object property e.g. 'a.b.c'
 * @param value the value to be set
 * @returns the object with the value set in the given path if it exists, otherwise the object itself
 */
export const setPathValue = <T extends GenericObject, P extends Paths<T>>(
  obj: T,
  path: P,
  value: PathValue<T, P>
) => {
  const leaf = getNestedPathInfo(obj, path)
  if (leaf) {
    leaf.lastKeyValueCouple[leaf.lastKey] = value
  }
  return obj
}

/**
 * @param val Checks if the value is an actual object, not null nor array nor date
 */
export const isObject = (val: unknown) =>
  typeof val === 'object' && val !== null && !Array.isArray(val) && !isDate(val)

/**
 * Recursively removes any id property from an object or array of objects
 * @param obj
 * @returns
 */
// TODO: fix the types of this function: isObject should return a "param is object" type instead of a simple boolean
// but doing this will show that some of the cases taken into account in this function can never happen
// We have to fix all the typings and rewrite the function
export const removeIdsFromObject = (
  obj: any,
  omittedKeys: string[] = []
): any => {
  if (Array.isArray(obj)) {
    return obj.map((item) => removeIdsFromObject(item, omittedKeys))
  }
  if (isObject(obj)) {
    return Object.keys(obj).reduce((acc, key) => {
      if (key === 'id') {
        return acc
      } else if (omittedKeys.includes(key)) {
        const child = obj[key]
        if (isObject(child)) {
          const { id, ...rest } = obj[key]

          acc[key] = { ...removeIdsFromObject(rest), ...(!!id && { id }) }
        } else {
          acc[key] = removeIdsFromObject(obj[key])
        }
        return acc
      } else {
        acc[key] = removeIdsFromObject(obj[key], omittedKeys)
        return acc
      }
    }, {} as Record<string, any>)
  }
  return obj
}
