import { styled } from '@mui/material/styles'
import React from 'react'
import { APILogisticSnapshot } from 'services/api/entities/events/types/api/response-models'
import { FoodSelection } from 'services/api/entities/events/types/internal/selections'
import EventUpsellingSelectionInput from 'components/FormComponents/EventUpsellingSelectionInput'
import { SelectionDetailsProps } from 'components/FormComponents/MainProductSelectionInputContent'
import LocalizedSelectInput from 'components/Inputs/Localized/LocalizedSelectInput'
import TimePicker from 'components/Inputs/TimePicker'
import IntolerancesInput from 'resources/operations/events/EventsComponents/IntolerancesInput'
import OptionSelectionInput from 'resources/operations/events/EventsComponents/OptionSelectionInput'
import { getSelectionChoices } from 'resources/operations/events/utils'
import { Food, getId, Logistic, objArrayIntersection } from '@kampaay/common'

const PREFIX = 'FoodSelectionDetailsForm'

const classes = {
  dFlex: `${PREFIX}-dFlex`,
  mR: `${PREFIX}-mR`,
  displayVertically: `${PREFIX}-displayVertically`,
  defaultWidth: `${PREFIX}-defaultWidth`,
  arrayInputPadding: `${PREFIX}-arrayInputPadding`
}

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')({
  [`& .${classes.dFlex}`]: {
    display: 'flex',
    width: '100%'
  },
  [`& .${classes.mR}`]: {
    marginRight: '8px'
  },
  [`& .${classes.displayVertically}`]: {
    display: 'flex',
    flexDirection: 'column',
    width: '256px'
  },
  [`& .${classes.defaultWidth}`]: {
    width: '256px'
  },
  [`& .${classes.arrayInputPadding}`]: {
    padding: '16px 0 0 0'
  }
})

const FoodSelectionDetailsFormContent: React.FC<
  SelectionDetailsProps<Food, FoodSelection>
> = ({ scopedFormData, selectedProduct, getSource, isObsolete, formData }) => {
  if (!scopedFormData || !formData) return null

  const { upsellingSelection = [], logisticSelection } = scopedFormData

  const formSelectedUpsellingsFull =
    selectedProduct?.upsellingItemsFull.filter((ups) =>
      upsellingSelection.map((u) => u?.id).includes(ups.id)
    ) ?? []

  const availableLogistics = upsellingSelection.length
    ? (objArrayIntersection(
        selectedProduct?.logisticsFull ?? [],
        ...formSelectedUpsellingsFull.map((ups) => ups.logisticsFull)
      ) as Logistic[])
    : selectedProduct?.logisticsFull ?? []

  const logisticChoices = getSelectionChoices(
    logisticSelection,
    availableLogistics
  )

  if (
    !!logisticSelection?.id &&
    !availableLogistics.map(getId).includes(logisticSelection.id)
  ) {
    // the first logistic is mandatory for each foodBundle and upselling and is the default one in the case
    // selecting an upselling reduces the overall selectable logistic options
    scopedFormData.logisticSelection =
      logisticChoices.find((e) => e.id === logisticSelection.id) ??
      selectedProduct?.logisticsFull[0]
  }

  return (
    <Root>
      <TimePicker
        source={getSource!('deliveryTime')}
        label="form.fields.deliveryTime"
        disabled={isObsolete}
      />

      <IntolerancesInput
        disabled={isObsolete}
        source={getSource!('intolerances')}
      />

      {/* if the product is obsolete and there is no selection we do not show the empty disabled component */}
      {(!isObsolete || !!upsellingSelection?.length) && (
        <EventUpsellingSelectionInput
          selectedProduct={selectedProduct}
          source={getSource!('upsellingSelection')}
          upsellingSelection={upsellingSelection}
          disableInputs={isObsolete}
        />
      )}

      <LocalizedSelectInput
        source={getSource!('logisticSelection')}
        label="Catering Logistic"
        format={(v: APILogisticSnapshot) => v?.id ?? ''}
        parse={(v: number) => logisticChoices.find((l) => l.id === v)}
        choices={logisticChoices}
        forceDisabled={isObsolete}
        fullWidth
      />

      {!!selectedProduct?.options?.length && (
        <OptionSelectionInput
          selectedProduct={selectedProduct}
          eventOptioned={scopedFormData}
          source={getSource!('optionSelections')}
          isObsolete={isObsolete}
        />
      )}
    </Root>
  )
}

export default FoodSelectionDetailsFormContent
