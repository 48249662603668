import CustomReferenceInput, {
  FilterFn
} from 'components/Inputs/CustomReferenceInput'
import KAutocompleteArrayInput from 'components/Inputs/KInputs/KAutocompleteArrayInput'
import React from 'react'
import { composeSalesFullNameFromChoices } from 'services/utils/concierge'
import { RAFormFC } from 'types/common'
import { WithMUIStyle } from 'types/ui'
import { FILTER_SOURCES } from '@kampaay/common'

export type KampaayerSelectInputProps = WithMUIStyle & {
  fullWidth?: boolean
  filterFns?: FilterFn<any>[]
  queryFilter?: Record<string, unknown>
  label?: string
}

const KampaayerSelectInput: RAFormFC<KampaayerSelectInputProps> = ({
  source,
  sx,
  fullWidth,
  filterFns,
  queryFilter = {
    [FILTER_SOURCES.role.in]: ['eventExcellence', 'admin']
  },
  label = 'form.fields.eeAssignee'
}) => (
  <CustomReferenceInput
    entity="kampaayers"
    queryFilter={queryFilter}
    filterFns={filterFns}
    sort={{
      field: 'firstName',
      order: 'asc'
    }}
  >
    <KAutocompleteArrayInput
      source={source}
      label={label}
      optionText={composeSalesFullNameFromChoices}
      fullWidth={fullWidth}
      sx={sx}
    />
  </CustomReferenceInput>
)

export default KampaayerSelectInput
