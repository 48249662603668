import React, { CSSProperties } from 'react'
import {
  NumberField,
  ReferenceField,
  SimpleShowLayout,
  useTranslate
} from 'react-admin'
import { EventItem } from 'services/api/entities/events/types/internal/event-models'
import { useKRecordContext } from 'hooks/useKRecordContext'
import ShareEventButton from 'components/Buttons/ShareEventButton'
import { RAFormFC } from 'types/common'
import KHeader from 'components/UI/KHeader'
import { Chip } from '@mui/material'
import KTextField from 'components/Fields/KTextField'
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined'
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined'
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import CallOutlinedIcon from '@mui/icons-material/CallOutlined'
import { ROUND_TWO_DECIMALS } from 'components/Fields/LabeledFields/LabeledNumberField'
import { useForm } from 'hooks/useForm'
import { PaymentMethod } from 'types/form'
import KDateField from 'components/Fields/KDateField'
import FlexBox from 'components/UI/layout/FlexBox'
import { kampaayTheme } from 'layout/config/themes'
import GuestManagementRequestMenu from 'resources/operations/events/GuestManagementRequestMenu'

export const greyedStyle: CSSProperties = {
  color: kampaayTheme.palette.primary.grey
}

const PaymentMethodKeysMap: Record<PaymentMethod, string> = {
  card: 'form.fields.card',
  bankTransfer: 'form.fields.bankTransfer',
  organizationBudget: 'form.fields.organizationBudget',
  none: 'form.fields.none'
}

const EventEditReadonlyFields: RAFormFC = ({ source }) => {
  const { getSource } = useForm<EventItem>(source)
  const record = useKRecordContext<EventItem>()
  const translate = useTranslate()

  const purchasedServices = record?.purchasedServices
    ? record?.purchasedServices.split(',')
    : []

  const paymentMethod = record?.paymentMethod
    ? PaymentMethodKeysMap[record?.paymentMethod]
    : '-'

  //=================================
  // JSX
  //=================================
  return (
    <SimpleShowLayout>
      <FlexBox justifyContent="space-between" gap={'1em'}>
        <KHeader title={record?.name ?? ''} style={{ margin: '0' }} />
        <FlexBox justifyContent="end" gap={'1em'}>
          <GuestManagementRequestMenu entity="event" />
          <ShareEventButton />
        </FlexBox>
      </FlexBox>

      <FlexBox justifyContent="space-between">
        {/*=========== LEFT CONTAINER ===========*/}
        <FlexBox
          flexDirection="column"
          justifyContent="space-between"
          gap={'.5em'}
        >
          {/*======= row 1 - chips =======*/}
          <FlexBox gap={'1em'}>
            {purchasedServices.map((service) => (
              <Chip key={service} label={service} />
            ))}
          </FlexBox>

          {/*======= row 2 =======*/}
          <FlexBox gap={'1em'}>
            <div>
              <KTextField
                source={getSource('guestsNumber')}
                startIcon={<GroupsOutlinedIcon style={greyedStyle} />}
                endLabel={'form.fields.guests'}
                labelStyle={{ textTransform: 'lowercase' }}
              />
            </div>
            {record?.fullAddress && (
              <KTextField
                source={getSource('fullAddress')}
                startIcon={<FmdGoodOutlinedIcon style={greyedStyle} />}
              />
            )}
          </FlexBox>

          {/*======= row 3 =======*/}
          <FlexBox gap={'1em'}>
            <KDateField
              source={getSource('startDate')}
              startIcon={<TodayOutlinedIcon style={greyedStyle} />}
            />
            <FlexBox alignItems="center" gap={'.2em'}>
              <AccessTimeOutlinedIcon style={greyedStyle} />
              <KDateField source={getSource('startDate')} showTime />
              -
              <KDateField source={getSource('endDate')} showTime />
            </FlexBox>
          </FlexBox>

          {/*======= row 4 =======*/}
          <FlexBox gap={'1em'}>
            {/* ORGANIZATION TODO: Make sure this actually works! */}
            <ReferenceField
              reference="organizationmembership"
              source={getSource('customerInfo.membershipId')}
            >
              <ReferenceField reference="organization" source="organizationId">
                <KTextField
                  source="name"
                  startIcon={<CorporateFareOutlinedIcon style={greyedStyle} />}
                />
              </ReferenceField>
            </ReferenceField>
            <KTextField
              source={getSource('customerInfo.email')}
              startIcon={<EmailOutlinedIcon style={greyedStyle} />}
            />
            <KTextField
              source={getSource('customerInfo.phone')}
              startIcon={<CallOutlinedIcon style={greyedStyle} />}
            />
          </FlexBox>
        </FlexBox>

        {/*=========== RIGHT CONTAINER ===========*/}
        <FlexBox
          flexDirection="column"
          alignItems="end"
          justifyContent={'space-between'}
          gap={'.5em'}
        >
          {/*======= pricing ======= */}
          <FlexBox flexDirection="column" alignItems="end">
            <div>
              <NumberField
                source={getSource('pricing.price')}
                options={ROUND_TWO_DECIMALS}
                sx={{
                  fontSize: '1.5rem',
                  fontWeight: '700',
                  marginRight: '.2em'
                }}
              />
              <span>{translate('country.currency')}</span>
            </div>
            <div style={greyedStyle}>
              <NumberField
                options={ROUND_TWO_DECIMALS}
                source={getSource('pricing.costNoVat')}
                sx={{
                  marginRight: '.2em'
                }}
              />
              <span>{translate('country.currency')}</span>
            </div>
          </FlexBox>

          <div>{translate(paymentMethod)}</div>

          {/*======= dates ======= */}
          <div>
            <FlexBox gap={'.5em'} justifyContent="end">
              <span style={greyedStyle}>
                {translate('form.fields.created')}
              </span>
              <KDateField source={getSource('createdAt')} showTime showDate />
            </FlexBox>
            {!!record?.storeUpdatedAt && (
              <FlexBox gap={'.5em'} justifyContent="end">
                <span style={greyedStyle}>
                  {translate('form.fields.lastSync')}
                </span>
                <KDateField
                  source={getSource('storeUpdatedAt')}
                  showTime
                  showDate
                />
              </FlexBox>
            )}
          </div>
        </FlexBox>
      </FlexBox>
    </SimpleShowLayout>
  )
}

export default EventEditReadonlyFields
