import { RAFormFC } from 'types/common'
import { useForm } from 'hooks/useForm'
import React from 'react'
import LocalizedAutocompleteInput from 'components/Inputs/Localized/LocalizedAutocompleteInput'
import CustomReferenceInput from 'components/Inputs/CustomReferenceInput'
import { EventItem } from 'services/api/entities/events/types/internal/event-models'
import SimpleModal from 'components/UI/SimpleModal'
import { useCustomerIdAndOrganizationFromEvent } from 'components/FormComponents/TailoredEventSelectionInput'
import { useEventForm } from 'hooks/events/useEventForm'
import { GeoAreaPreset } from '@kampaay/common'

/**
 * Input of the geoarea preset of an event
 * It handles the confirmation modal when the user change the value,
 * and if confirmed it update the event selection accordingly
 */
const EventGeoareaPresetInput: RAFormFC = ({ source }) => {
  const { getSource, useGetValue, useSetValue } = useForm<EventItem>(source)

  const { resetAllSelections, hasProducts } = useEventForm()

  const [isOpen, setIsOpen] = React.useState<boolean>(false)

  const geoAreaPreset = useGetValue('geoAreaPreset')
  const setGeoAreaPreset = useSetValue('geoAreaPreset')

  /**
   * used to keep track of the geoarea preset value before the modal is opened
   * in order to reset to it if the user press on cancel CTA of the modal
   */
  const [cachedGeoareaPreset, setCachedGeoareaPreset] = React.useState<
    number | undefined
  >(geoAreaPreset)

  /**
   * If the user refuse to change the geoarea preset, we reset the value to the one
   * before the modal was opened, and close the modal
   */
  const onModalCancel = () => {
    setGeoAreaPreset(cachedGeoareaPreset)
    setIsOpen(false)
  }

  /**
   * If the user confirm to change the geoarea preset, we set update the value
   * of the cached geoarea preset, and close the modal
   */
  const onModalConfirm = () => {
    setCachedGeoareaPreset(geoAreaPreset)
    resetAllSelections()
    setIsOpen(false)
  }

  /**
   * When the user change the geoarea preset, we open the modal to confirm the change
   * else if the user is trying to add form the first time, we set the cached geoarea preset
   * @param e - the new geoarea preset value
   */
  const onGeoareaPresetChange = (e: number) => {
    if (cachedGeoareaPreset && hasProducts) {
      setIsOpen(true)
    } else {
      setCachedGeoareaPreset(e)
    }
  }

  const customerIdAndOrg = useCustomerIdAndOrganizationFromEvent()

  return (
    <>
      <CustomReferenceInput<GeoAreaPreset>
        entity="geoareapresets"
        filterFns={[
          filterByOrgId(customerIdAndOrg.organization?.id),
          filterByVisiblePresets(
            customerIdAndOrg.organization?.visiblePublicGeoAreaPresets
          )
        ]}
      >
        <LocalizedAutocompleteInput
          source={getSource('geoAreaPreset')}
          label="form.fields.geoareaPreset"
          optionText="name"
          defaultValue={undefined}
          onChange={onGeoareaPresetChange}
        />
      </CustomReferenceInput>

      <SimpleModal
        title="form.modals.changeGeoarea.title"
        description="form.modals.changeGeoarea.description"
        secondCTALabel="form.modals.changeGeoarea.confirm"
        firstCTALabel="form.modals.changeGeoarea.cancel"
        isOpen={isOpen}
        onFirstCTAClick={onModalCancel}
        onSecondCTAClick={onModalConfirm}
      />
    </>
  )
}

export default EventGeoareaPresetInput

export const isGeoAreaPresetPublic = (geoareapreset: GeoAreaPreset) =>
  !geoareapreset.organizations.length

const filterByOrgId =
  (organizationId?: number) => (geoareapresets: GeoAreaPreset) => {
    if (!organizationId) return !geoareapresets.organizations.length
    return (
      geoareapresets.organizations.includes(organizationId) ||
      !geoareapresets.organizations.length
    )
  }

const filterByVisiblePresets =
  (visiblePublicPresets?: number[]) => (geoareapreset: GeoAreaPreset) => {
    if (!visiblePublicPresets || !visiblePublicPresets.length) {
      return true
    }

    return visiblePublicPresets.includes(geoareapreset.id)
  }
