import React from 'react'
import { RAFormFC } from 'types/common'
import LocalizedTextInput from 'components/Inputs/Localized/LocalizedTextInput'
import LocalizedRichTextInput from 'components/Inputs/Localized/LocalizedRichTextInput'
import { useForm } from 'hooks/useForm'
import { Customization } from 'services/api/entities/events/types/internal/event-models'
import KToolTip from 'components/UI/KTooltip'
import KImageInput from 'components/Inputs/KImageInput'
import FileInputAccordion from 'components/Inputs/FileInputAccordion'
import { DropEvent, FileRejection } from 'react-dropzone'
import { useNotify } from 'ra-core'
import KNumberInput from 'components/Inputs/KInputs/KNumberInput/KNumberInput'

const CUSTOMIZATION_IMAGE_MAX_SIZE = 800_000 // 800 KB

const EventCustomizationInput: RAFormFC = ({ source }) => {
  const { getSource } = useForm<Customization>(source)

  const notify = useNotify()

  ///////////////////////////
  // JSX
  ///////////////////////////
  return (
    <>
      <LocalizedTextInput
        source={getSource('name')}
        label="form.fields.customizationName"
      />
      <LocalizedRichTextInput
        source={getSource('description')}
        label="form.fields.customizationDescription"
      />

      <br />

      <KNumberInput
        source={getSource('cost')}
        label="form.event.customization.cost"
      />
      <KNumberInput
        source={getSource('price')}
        label="form.event.customization.price"
      />
      <br />

      <KToolTip title="tooltip.hint.eventCustomizationsDocumentsUpload">
        <FileInputAccordion
          source={getSource('documents')}
          accordionHeadingLevel={'h3'}
          label={' '}
          accept="application/pdf,.xls,.xlsx,.docx"
          multiple
        />
      </KToolTip>

      <br />
      <br />

      <KToolTip title="tooltip.hint.eventCustomizationsImagesUpload">
        <KImageInput
          accept=".png, .jpeg, .jpg"
          multiple
          source={getSource('images')}
          maxSize={CUSTOMIZATION_IMAGE_MAX_SIZE}
          options={{
            onDropRejected: (
              fileRejections: FileRejection[],
              _event: DropEvent
            ): void => {
              fileRejections.forEach((_fr) => {
                notify('IMAGE REJECTED (larger than 800 KB)')
              })
            }
          }}
        />
      </KToolTip>
    </>
  )
}

export default EventCustomizationInput
