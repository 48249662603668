import React from 'react'
import OrganizationBudgetInfo from 'components/FormComponents/Organization/Budget/OrganizationBudgetInfo'
import OrganizationBudgetMemberships from 'components/FormComponents/Organization/Budget/Memberships/OrganizationBudgetMemberships'
import OrganizationMenuWrapper from 'components/FormComponents/Organization/OrganizationMenuWrapper'
import { useParams } from 'react-router-dom'
import KNumberInput from 'components/Inputs/KInputs/KNumberInput/KNumberInput'
import OrganizationBudgetEvents from 'components/FormComponents/Organization/Budget/Events/OrganizationBudgetEvents'
import OrganizationBudgetSubGroups from 'components/FormComponents/Organization/SubGroups/OrganizationBudgetSubGroups'

const OrganizationBudgetFormContent: React.FC = () => {
  const { id: organizationId } = useParams()

  return (
    <OrganizationMenuWrapper>
      <KNumberInput
        source="organizationId"
        defaultValue={parseInt(organizationId!)}
        className="hidden"
      />
      <OrganizationBudgetInfo />
      <div className="mt-8">
        <OrganizationBudgetMemberships />
      </div>
      <div className="mt-8">
        <OrganizationBudgetSubGroups />
      </div>
      <div className="mt-8">
        <OrganizationBudgetEvents />
      </div>
    </OrganizationMenuWrapper>
  )
}

export default OrganizationBudgetFormContent
