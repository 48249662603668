import { LocalShipping } from '@mui/icons-material'
import LogisticsList from 'resources/catalog/logistics/LogisticsList'
import LogisticsEdit from 'resources/catalog/logistics/LogisticsEdit'
import LogisticsCreate from 'resources/catalog/logistics/LogisticsCreate'

const rentIndex = {
  icon: LocalShipping,
  list: LogisticsList,
  edit: LogisticsEdit,
  create: LogisticsCreate
}

export default rentIndex
