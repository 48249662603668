import { Badge } from '@mui/material'
import { TailoredEventSelectionInput } from 'components/FormComponents/TailoredEventSelectionInput'
import KFormTab from 'components/Forms/KForms/KFormTab'
import KRichTextInput from 'components/Inputs/KInputs/KRichTextInput'
import KSelectInput from 'components/Inputs/KInputs/KSelectInput'
import { useTranslate } from 'ra-core'
import React from 'react'
import { APIEntity } from 'services/api/entities'
import { productStatusChoices } from 'services/cfg/form'
import { PRODUCT_SERVICE_ENTITY_MAP } from 'services/cfg/name-maps'
import { NamedService, RAFormFC } from 'types/common'
import { isOptionedObsolete } from 'resources/operations/events/utils'
import ExperienceFormTabInputs from 'resources/operations/events/eventsFormTabs/ExperienceTab/ExperienceFormTabInput'
import ExperienceSelectionDetailsFormContent from 'resources/operations/events/eventsFormTabs/ExperienceTab/ExperienceSelectionDetailsFormContent'
import PricingSummary from 'components/FormComponents/PricingSummary/PricingSummary'
import { useForm } from 'hooks/useForm'
import { MappedEnrichedEvent } from 'services/api/entities/events/types/internal/event-models'
import FlexBox from 'components/UI/layout/FlexBox'
import { useEventService } from 'hooks/events/useEventService'

type Props = {
  experience: Extract<APIEntity, 'virtuals' | 'physicals'>
  experienceService: Extract<NamedService, 'physicalService' | 'virtualService'>
  children?: React.ReactNode
  disabled?: boolean
}

const ExperienceFormTab: RAFormFC<Props> = ({
  experience,
  children,
  experienceService,
  source,
  ...rest
}) => {
  const { useGetValue } = useForm<MappedEnrichedEvent>(source)
  const linkedProductType = PRODUCT_SERVICE_ENTITY_MAP[experience]

  const { hasSelections, hasCustomizations } = useEventService(experience)

  const isGeoareaSelected = !!useGetValue('geoAreaPreset')

  const translate = useTranslate()

  return (
    <KFormTab
      {...rest}
      disabled={!isGeoareaSelected}
      label={
        <Badge
          badgeContent={hasSelections || hasCustomizations ? 1 : 0}
          color="primary"
          variant="dot"
        >
          {translate(`form.tabs.${experience}`)}
        </Badge>
      }
    >
      <FlexBox justifyContent="space-between">
        <KSelectInput
          source={`${linkedProductType}ExperiencesStatus`}
          label="form.fields.status"
          defaultValue={productStatusChoices[0].id}
          choices={productStatusChoices}
        />
        <PricingSummary
          source={`productServices.${experienceService}.pricing`}
          title={'form.headers.servicePricing'}
        />
      </FlexBox>

      <KRichTextInput
        source={`${linkedProductType}ExperiencesNotes`}
        label="form.fields.notes"
      />

      {children}

      {/* Experience input */}
      <TailoredEventSelectionInput
        entity={experience}
        label="form.fields.orders"
        source={experience}
        getIsProductObsolete={isOptionedObsolete}
        disableProductChoiceAfterSelections
        disableAdd={experience === 'virtuals'}
      >
        <ExperienceSelectionDetailsFormContent experience={experience} />
      </TailoredEventSelectionInput>

      <ExperienceFormTabInputs
        experience={experience}
        experienceService={experienceService}
      />
    </KFormTab>
  )
}

export default ExperienceFormTab
