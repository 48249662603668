import { BusinessCenter } from '@mui/icons-material'
import CustomersCreate from 'resources/crm/clients/customers/CustomersCreate'
import CustomersEdit from 'resources/crm/clients/customers/CustomersEdit'
import CustomersList from 'resources/crm/clients/customers/CustomersList'

const businessIndex = {
  icon: BusinessCenter,
  create: CustomersCreate,
  edit: CustomersEdit,
  list: CustomersList
}

export default businessIndex
