import { Experience } from '@kampaay/common'
import {
  formatOptionedProduct,
  OptionedProductCSV
} from 'services/csv/entities/catalog/common/optionedProduct'

type ExperienceCSV = OptionedProductCSV & {
  'Booking time-limit': number | undefined
}

export const formatExperience = ({
  advanceReservationMin,
  ...rest
}: Experience): ExperienceCSV => ({
  ...formatOptionedProduct(rest),
  'Booking time-limit': advanceReservationMin
})
