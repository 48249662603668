import React from 'react'
import { RAFormFC } from 'types/common'
import { TableRow, TableCell } from '@mui/material'
import ChipFieldWithQuantity from 'components/Fields/ChipFieldWithQuantity'
import { KBooleanInput } from 'components/Inputs/KInputs/KBooleanInput'
import SupplierSelectInput from 'components/Inputs/SupplierSelectInput'
import KChip from 'components/UI/KChip'
import { EventProfessionalServiceSummary } from 'hooks/events/useEventProfessionalServiceGroup'
import { EventServiceSummary } from 'hooks/events/useEventService'
import {
  ArrayField,
  SingleFieldList,
  BooleanField,
  NumberField,
  useCreatePath,
  Link
} from 'react-admin'
import { LogisticSelection } from 'services/api/entities/events/types/internal/selections'
import { translate } from 'services/i18n'
import EventListServicesTableFoodAdditionalInfo from 'resources/operations/events/list-components/EventListServicesTableFoodAdditionalInfo'
import SupplierAssignmentErrorSpan from 'resources/operations/events/common/SupplierAssignmentErrorSpan'
import { useCheckIfSelectionsNotInCatalog } from 'resources/operations/events/common/useCheckIfSelectionsNotInCatalog'
import { useCheckIfSupplierProvideProduct } from 'resources/operations/events/common/useCheckIfSupplierProvideProduct'
import useCheckIfSelectionsHasCommonSupplier from 'resources/operations/events/common/useCheckIfSelectionsHasCommonSupplier'
import filterSupplierBuilder from 'resources/operations/events/common/filterSupplierWithServiceBuilder'

type Props = {
  eventId?: number
  summary:
    | Omit<EventServiceSummary, 'numberOfCustomizations'>
    | EventProfessionalServiceSummary
  hasIntolerances: boolean
  logistics: LogisticSelection[]
}

const EventServiceSummaryTableRow: RAFormFC<Props> = ({
  eventId,
  summary,
  hasIntolerances,
  logistics
}) => {
  const createPath = useCreatePath()

  // if the event id is defined then we are in the edit view, otherwise we are in the create view
  const resource = !!eventId ? `events/${eventId}` : 'events/create'

  const linkToTab = createPath({
    resource,
    id: summary.serviceTabPath,
    type: 'edit'
  })

  const selectedSupplierId = summary.service?.supplier
  const notifySupplier = summary.service.notifySupplier
  const selectionIds = summary.selections.map((selection) => selection.id)
  const SCN = summary.serviceTabPath

  const filterSupplierWithService = filterSupplierBuilder({
    serviceName: SCN,
    selectionIds
  })

  const isSelectionsNotInCatalog: boolean = useCheckIfSelectionsNotInCatalog({
    SCN,
    selectionIds
  })
  const isSupplierProvideProduct: boolean = useCheckIfSupplierProvideProduct({
    selectedSupplierId,
    serviceName: summary.serviceTabPath,
    selectionIds
  })

  const ifSelectionsHasCommonSupplier = useCheckIfSelectionsHasCommonSupplier({
    selectionIds: selectionIds,
    serviceName: summary.serviceTabPath,
    ifSupplierNotAssigned: !selectedSupplierId
  })

  return (
    <TableRow>
      <TableCell>
        <Link to={linkToTab}>{summary.serviceName}</Link>
      </TableCell>

      <TableCell>
        <ArrayField record={summary} source="selections">
          <SingleFieldList linkType={false}>
            <ChipFieldWithQuantity />
          </SingleFieldList>
        </ArrayField>

        {summary.serviceTabPath === 'foods' ? (
          <EventListServicesTableFoodAdditionalInfo
            hasIntolerances={hasIntolerances}
            logistics={logistics}
          />
        ) : null}
      </TableCell>

      <TableCell>
        {summary.serviceTabPath !== 'virtuals' ? (
          <>
            <SupplierSelectInput
              source={`${summary.serviceSource}.supplier`}
              disabled={notifySupplier}
              margin="none"
              filterFns={
                isSelectionsNotInCatalog ? [] : [filterSupplierWithService]
              }
            />
            <SupplierAssignmentErrorSpan
              isSelectionsNotInCatalog={isSelectionsNotInCatalog}
              isSupplierProvideProduct={isSupplierProvideProduct}
              ifSupplierExist={!!summary.service.supplier}
              ifProductSelected={!!(selectionIds.length > 0)}
            />
            {ifSelectionsHasCommonSupplier ? (
              <div
                style={{
                  marginBottom: '1.5rem',
                  backgroundColor: 'rgba(255, 0, 0, 0.15)',
                  border: '3px solid black',
                  padding: '0.5rem'
                }}
              >
                {translate('common.label.notHaveCommonSupplier')}
              </div>
            ) : (
              <></>
            )}
          </>
        ) : (
          <KChip customBackgroundColor="error" label="N/A" />
        )}
      </TableCell>
      <TableCell>
        <KBooleanInput
          source={`${summary.serviceSource}.triggerAssignmentEmail`}
          label=""
        />
      </TableCell>

      <TableCell>
        <BooleanField record={summary} source="hasCustomizations" />
      </TableCell>
      <TableCell>
        <BooleanField record={summary} source="hasUpselling" />
      </TableCell>
      <TableCell>
        <NumberField record={summary} source="service.pricing.price" />{' '}
        <span>{translate('country.currency')}</span>
      </TableCell>
    </TableRow>
  )
}

export default EventServiceSummaryTableRow
