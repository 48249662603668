import React from 'react'
import { FunctionField } from 'react-admin'
import { getDateAsString, getTimeLabelFromDate } from '@kampaay/common'

const lastEditRender = (record?: any) => {
  if (!record) {
    return ''
  } else {
    return `Last edit ${getDateAsString(
      record?.updatedAt
    )} at ${getTimeLabelFromDate(record?.updatedAt)} by ${
      record?.updatedBy ?? '---'
    }`
  }
}
const LastEditDetailsField: React.FC = (props) => {
  return <FunctionField {...props} render={lastEditRender} />
}

export default LastEditDetailsField
