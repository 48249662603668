import React from 'react'
import FacetMultiSelectInput from 'components/Inputs/Facets/BaseComponents/FacetMultiSelectInput'
import { FacetSelectInput } from 'components/Inputs/Facets/BaseComponents/FacetSelectInput'
import ExperienceFacetSection from 'components/Inputs/Facets/FacetFormSections/ExperienceFacetSection'

const PhysicalFacetSection: React.FC = () => (
  <>
    <ExperienceFacetSection />
    <FacetMultiSelectInput
      source="settingFacets"
      facetCategory="productFilter"
      filterGroup="Setting"
    />
    <FacetSelectInput
      source="teambuildingCategoryFacet"
      facetCategory="productFilter"
      filterGroup="TeamBuildingCategory"
    />
    <FacetMultiSelectInput
      source="languageFacets"
      facetCategory="productFilter"
      filterGroup="Language"
    />
  </>
)

export default PhysicalFacetSection
