import React from 'react'
import ProductCommonTailoringInput from 'resources/crm/clients/organization/tailoring/components/productCommon/ProductCommonTailoringInput'
import ProductCommonTailoringsEdit from 'resources/crm/clients/organization/tailoring/ProductCommonTailoringsEdit'

const LocationUpsellingTailoringsEdit: React.FC = () => {
  return (
    <ProductCommonTailoringsEdit
      productEntity="locationupselling"
      tailoringsInput={ProductCommonTailoringInput}
    />
  )
}

export default LocationUpsellingTailoringsEdit
