import { PhysicalExperience } from 'services/api/entities/catalog/physical/types/internal/physical-models'
import { CSVEntityDescriptor } from 'services/csv/types'
import { formatExperience } from 'services/csv/entities/catalog/common/experience'

const formatPhysical = ({ geoCodedAddress, ...rest }: PhysicalExperience) => ({
  ...formatExperience(rest),
  Address: geoCodedAddress?.geoPoint?.formattedAddress
})

export const physicals: CSVEntityDescriptor<PhysicalExperience> = {
  format: formatPhysical
}
