import React from 'react'
import BaseProductFormContent from 'components/Forms/BaseProductFormContent'
import SectionWrapper from 'components/UI/SectionWrapper'
import { KSimpleForm } from 'components/Forms/KForms/KSimpleForm'
import KCreate from 'components/Forms/KCreate/KCreate'

const MusicCreate: React.FC = () => {
  return (
    <SectionWrapper>
      <KCreate>
        <KSimpleForm>
          <BaseProductFormContent />
        </KSimpleForm>
      </KCreate>
    </SectionWrapper>
  )
}

export default MusicCreate
