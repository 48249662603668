import React from 'react'
import KFormTab from 'components/Forms/KForms/KFormTab'
import { RAFormFC } from 'types/common'
import { KBooleanInput } from 'components/Inputs/KInputs/KBooleanInput'
import KImageInput from 'components/Inputs/KImageInput'
import MailingListSection from 'components/FormComponents/Organization/MailingListSection'
import OrganizationCheckoutPersonalization from 'resources/crm/clients/organization/OrganizationCheckoutPersonalization'
import { useTranslate } from 'react-admin'
import OrganizationHiddenServicesInput from 'components/FormComponents/Organization/OrganizationHiddenServicesInput'
import CustomerFormContentCommon from 'components/FormComponents/customerAndSupplierForms/CustomerFormContentCommon'
import { useForm } from 'hooks/useForm'
import { Organization } from 'services/api/entities/crm/organizations/types/internal/organization-model'
import GeoAreaPresetInput from 'components/Inputs/Localized/GeoAreaPresetInput'
import OrganizationApprovalFlowInput from 'components/FormComponents/Organization/ApprovalFlows/OrganizationApprovalFlowInput'

const OrganizationConfigurationTab: RAFormFC = ({ source, ...rest }) => {
  const translate = useTranslate()
  const { useGetValue } = useForm<Organization>(source)

  return (
    <KFormTab label="tab.label.configuration" {...rest}>
      <CustomerFormContentCommon>
        <KBooleanInput
          source="isTrial"
          label="form.fields.isTrial"
          className="mr-10"
        />
        <KImageInput label="form.fields.organizationImage" />
        <OrganizationApprovalFlowInput />
        <MailingListSection />
        <OrganizationCheckoutPersonalization />
        <OrganizationHiddenServicesInput
          source="hiddenServices"
          organizationId={useGetValue('id')}
        />

        <h2>{translate('form.fields.visibleGeoareaPresets')}</h2>
        <GeoAreaPresetInput source="visiblePublicGeoAreaPresets" />
      </CustomerFormContentCommon>
    </KFormTab>
  )
}

export default OrganizationConfigurationTab
