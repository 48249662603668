import { yupNumber, yupObject, YupSchema, yupString } from 'services/yup'
import {
  LocationArrangementConfig,
  LocationArrangementMap
} from 'services/api/entities/catalog/location/types/internal/location-models'
import { LocationArrangementStatus } from 'types/form'

const locationArrangementCFGVS: YupSchema<LocationArrangementConfig> =
  yupObject().shape({
    capacity: yupNumber(),
    status: yupString<LocationArrangementStatus>().required()
  })

export const locationArrangementMapVS: YupSchema<LocationArrangementMap> =
  yupObject().shape({
    classroom: locationArrangementCFGVS,
    theater: locationArrangementCFGVS,
    cabaret: locationArrangementCFGVS,
    boardroom: locationArrangementCFGVS,
    ushape: locationArrangementCFGVS,
    standing: locationArrangementCFGVS
  })
