import type { CountryCode } from './countries-list'

// For convention and compatibility with languages we use ISO 3166-1 alpha-2 codes (https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2) as country codes
export const SUPPORTED_COUNTRY_CODES: Extract<CountryCode, 'IT' | 'SE'>[] = [
  'IT',
  'SE'
]
// export const SUPPORTED_COUNTRY_CODES: CountryCode[] = [ 'IT', 'SE' ] as const
export type SupportedCountryCode = (typeof SUPPORTED_COUNTRY_CODES)[number]
// the language type is the identifier of the language ISO 639-1 (https://www.w3schools.com/tags/ref_language_codes.asp) that we use to store user preference in the browser
export type LanguageCode = 'it' | 'sv' | 'en'

// LanguageCode is a string chosen between the IETF language tag convention, we use it for localizing date formats
// we can find all entries at this address http://www.lingoes.net/en/translator/langcode.htm
export type LocaleCode = 'it-IT' | 'sv-SE' | 'en-US'

/**
 * Used as the url portion that identifies the language and also as the name of the JSON with the i18n translations for the language.
 *
 * The i18nLocale is a string that combines the languageCode and the countryCode.
 * Defines the language chosen, and the reference country.
 *
 * Only the currently supported combinations are listed here.
 */
export type I18nLocale = 'it-IT' | 'sv-SE' | 'en-IT' | 'en-SE'

export type I18nBinding = {
  languageCode: LanguageCode
  localeCode: LocaleCode
  i18nLocale: I18nLocale
  langLabel: string
}
export type CountryInfo = I18nBinding & {
  id: number
  code: SupportedCountryCode
  name: CountryName
  secondaryLangs: I18nBinding[]
}

export type CountryName = 'Italia' | 'Sweden'

export const ENG_LANG_TYPE: LanguageCode = 'en'

export const ENGLISH_LANGUAGE: Omit<I18nBinding, 'i18nLocale'> = {
  languageCode: ENG_LANG_TYPE,
  localeCode: 'en-US',
  langLabel: 'language.label.english'
}

export const COUNTRY_IT: CountryInfo = {
  id: 1,
  name: 'Italia',
  code: 'IT',
  localeCode: 'it-IT',
  languageCode: 'it',
  i18nLocale: 'it-IT',
  langLabel: 'language.label.italian',
  secondaryLangs: [
    {
      ...ENGLISH_LANGUAGE,
      i18nLocale: 'en-IT'
    }
  ]
}

export const COUNTRY_SV: CountryInfo = {
  id: 2,
  name: 'Sweden',
  code: 'SE',
  localeCode: 'sv-SE',
  languageCode: 'sv',
  i18nLocale: 'sv-SE',
  langLabel: 'language.label.swedish',
  secondaryLangs: [
    {
      ...ENGLISH_LANGUAGE,
      i18nLocale: 'en-SE'
    }
  ]
}

export const DEFAULT_COUNTRY_ID = COUNTRY_IT.id
export const SUPPORTED_COUNTRIES: CountryInfo[] = [COUNTRY_IT, COUNTRY_SV]

export default SUPPORTED_COUNTRIES
