import React from 'react'
import EventBaseSelectionInputWrapper, {
  EventBaseSelectionInputWrapperProps
} from 'components/FormComponents/EventBaseSelectionInputWrapper'
import MainProductSelectionInputContent, {
  MainProductSelectionInputContentProps
} from 'components/FormComponents/MainProductSelectionInputContent'
import { isCompatibleWithGivenGeoarea } from 'services/utils/products/productCommon'
import { useWatch } from 'react-hook-form'
import { MainProduct } from '@kampaay/common'

export type EventMainProductSelectionInputProps<T extends MainProduct> = Omit<
  EventBaseSelectionInputWrapperProps,
  'children'
> &
  MainProductSelectionInputContentProps<T> & {
    children?: React.ReactNode
  }

const EventMainProductSelectionInput = <T extends MainProduct>({
  children,
  label,
  disableInputs,
  choices = [],
  hideQuantity,
  getIsProductObsolete,
  disableProductChoiceAfterSelections,
  arrayInputLabel,
  source,
  disableAdd
}: EventMainProductSelectionInputProps<T>) => {
  const geoAreaPresetId = useWatch({ name: 'geoAreaPreset' })!
  const filteredChoices = choices.filter(
    isCompatibleWithGivenGeoarea(geoAreaPresetId)
  )
  return (
    <EventBaseSelectionInputWrapper
      arrayInputLabel={arrayInputLabel}
      source={source}
      disableAdd={disableAdd}
    >
      <MainProductSelectionInputContent
        label={label}
        disableInputs={disableInputs}
        choices={filteredChoices}
        hideQuantity={hideQuantity}
        getIsProductObsolete={getIsProductObsolete}
        disableProductChoiceAfterSelections={
          disableProductChoiceAfterSelections
        }
      >
        {children}
      </MainProductSelectionInputContent>
    </EventBaseSelectionInputWrapper>
  )
}

export default EventMainProductSelectionInput
