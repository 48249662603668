import React from 'react'
import LocalizedSelectInput from 'components/Inputs/Localized/LocalizedSelectInput'
import useFacets from 'hooks/useFacets'
import { FacetSelectComponentProps } from 'services/utils'
import {
  FacetFilterGroup,
  SingleSelectableFacetCategory
} from '@kampaay/common'

type SinglePickListFacetCategory = Extract<
  SingleSelectableFacetCategory,
  'group' | 'productFilter'
>

type SinglePickListFacetFilterGroup = Extract<
  FacetFilterGroup,
  'TeamBuildingCategory' | 'Skill' | 'VirtualCategory' | 'Type' | 'Location'
>

type Props = FacetSelectComponentProps<
  SinglePickListFacetCategory,
  SinglePickListFacetFilterGroup
>

/**
 * We use this component for Drink, Group and productFilter facets.
 * In case of Drink or Group facets we just need to pass facetCategory prop as string.
 * In case of productFilter facets, we need to specify as well the filterGroup, since productFilter facets are used for different products.
 */
export const FacetSelectInput: React.FC<Props> = ({
  facetCategory,
  source,
  filterGroup
}) => {
  const facets = useFacets(facetCategory, filterGroup)
  if (!facets.length) return null

  return (
    <LocalizedSelectInput
      label={`form.fields.${source}`}
      optionText="description"
      fullWidth
      source={source}
      choices={facets}
    />
  )
}
