import { FILTER_SOURCES, Kampaayer } from '@kampaay/common'

export type CRUDMode = 'editMode' | 'createMode'
export type WithCRUDMode = {
  crudMode: CRUDMode
}

export const composeSalesFullNameFromChoices = (choice: Kampaayer) =>
  `${choice.firstName} ${choice.lastName}`

// those filters below will be treated as a special case
// because BE is evil.
// (they will be always set to: true)
// https://gitlab.com/kampaay/general/-/issues/1978#note_1429089024
//
// usage:
// therefore if you want:
// - to filter out entities that have an account, use the hasNoAccountQueryFilter,
// - to filter out entities that don't have an account, use the hasNoAccountQueryFilter
// in the latter case, create a new filter object
// e.g.
// export const hasNoAccountQueryFilter = { [FILTER_SOURCES.hasNoAccount_eq]: 'true' }
//
export const hasAccountQueryFilter = {
  [FILTER_SOURCES.hasAccount.eq]: 'true'
}
