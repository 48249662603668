import { buildFilterConfig } from 'services/utils/filters'
import { FILTER_SOURCES } from '@kampaay/common'

export const ORG_BUDGET_FILTERS = [
  buildFilterConfig('text', {
    source: FILTER_SOURCES.name.contains,
    alwaysOn: true,
    label: 'form.fields.name'
  }),
  buildFilterConfig('nullableBoolean', {
    source: FILTER_SOURCES.isActive.eq,
    alwaysOn: true,
    label: 'form.fields.isActive'
  })
]
