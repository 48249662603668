import React, { CSSProperties, PropsWithChildren } from 'react'
import { RAFormFC } from 'types/common'
import { Autocomplete } from '@mui/material'
import {
  parseMultilangToCurrentLang,
  ProductTailoringBase,
  Tailorings
} from '@kampaay/common'
import SwitchInput from 'components/Inputs/SwitchInput'
import TextField from '@mui/material/TextField'
import { useTailoringsViewModel } from 'resources/crm/clients/organization/tailoring/hooks/viewModels/useTailoringsViewModel'
import { useForm } from 'hooks/useForm'
import { APIEntity } from 'services/api/entities'
import TailoringInputBase from 'resources/crm/clients/organization/tailoring/components/TailoringInputBase'
import KSimpleFormIterator from 'components/Inputs/KSimpleFormIterator'
import KArrayInput from 'components/Inputs/KInputs/KArrayInput'

type ProductTailoringsArrayInputProps = PropsWithChildren & {
  productEntity: APIEntity
}

const upsellingAndLogisitcs: APIEntity[] = [
  'foodsupselling',
  'locationupselling',
  'physicalupselling',
  'virtualupselling',
  'logistics'
]

const visibilityHidden: CSSProperties = {
  visibility: 'hidden',
  height: 0
}

/**
 * NOTE: by "Tailoring" we mean EMS Tailoring.
 *
 * This component is only used for EMS Tailoring.
 */
const TailoringsArrayInput: RAFormFC<ProductTailoringsArrayInputProps> = ({
  source,
  productEntity,
  children
}) => {
  /**
   * using the MVVM pattern here,
   * to separate the view from the logic
   * and also to make the code more readable reducing the noise
   */
  const tailoringsViewModel = useTailoringsViewModel(productEntity, source)
  const { getSource } = useForm<Tailorings<ProductTailoringBase>>(source)
  const hiddenStyle = upsellingAndLogisitcs.includes(productEntity)
    ? visibilityHidden
    : {}

  // ===================================
  // JSX
  // ===================================
  return (
    <div
      style={{
        display: 'flex',
        gap: '3em',
        flexDirection: 'column'
      }}
    >
      {/*============== FILTERS SECTION ==============*/}
      <div
        style={{
          display: 'flex',
          gap: '2.5em',
          alignItems: 'center',
          flexWrap: 'wrap'
        }}
      >
        {/*==== tailorings search box ====*/}
        <Autocomplete
          disablePortal
          options={tailoringsViewModel.functions.isAllowedByOtherFilters(
            tailoringsViewModel.state.relatedTailoredProducts,
            tailoringsViewModel.data.currentFormTailorings
          )}
          getOptionLabel={(cp) => parseMultilangToCurrentLang(cp.name)}
          renderInput={(params) => (
            <TextField {...params} label="Search item" />
          )}
          sx={{ width: '300px' }}
          onChange={tailoringsViewModel.eventHandlers.onSearchBoxChange}
        />

        {/*==== toggles ====*/}
        <div
          style={{
            ...hiddenStyle,
            display: 'flex',
            gap: '2.5em',
            alignItems: 'center',
            flexWrap: 'wrap'
          }}
        >
          {/*==== hidden products toggle ====*/}
          <SwitchInput
            onChange={
              tailoringsViewModel.eventHandlers.onHiddenProductsToggleChange
            }
            checked={tailoringsViewModel.state.shouldShowHiddenTailorings}
            label="form.fields.onlyHiddenProducts"
          />
          `{/*==== custom prices toggle ====*/}
          <SwitchInput
            onChange={
              tailoringsViewModel.eventHandlers.onCustomPricesToggleChange
            }
            checked={
              tailoringsViewModel.state.shouldShowTailoringsWithCustomPrices
            }
            label="form.fields.onlyCustomPrices"
          />
        </div>
      </div>

      {/*============== tailorings array input ==============*/}
      <KArrayInput source={getSource('tailorings')} defaultValue={[]}>
        <KSimpleFormIterator
          disableReordering
          fullWidth
          showFn={tailoringsViewModel.functions.shouldBeVisible}
        >
          <TailoringInputBase
            relatedTailoredProducts={
              tailoringsViewModel.state.relatedTailoredProducts
            }
            productEntity={productEntity}
          >
            {children}
          </TailoringInputBase>
        </KSimpleFormIterator>
      </KArrayInput>
    </div>
  )
}

export default TailoringsArrayInput
