import React from 'react'
import ProductSectionFormContent from 'components/Forms/ProductSectionFormContent'
import SectionWrapper from 'components/UI/SectionWrapper'
import { KSimpleForm } from 'components/Forms/KForms/KSimpleForm'
import KCreate from 'components/Forms/KCreate/KCreate'

const LocationSectionCreate: React.FC = () => (
  <SectionWrapper title={'resources.locationsections.createSection'}>
    <KCreate>
      <KSimpleForm>
        <ProductSectionFormContent />
      </KSimpleForm>
    </KCreate>
  </SectionWrapper>
)

export default LocationSectionCreate
