import React from 'react'
import { Button, useRedirect } from 'react-admin'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'

type BackButtonProps = {
  redirectPath: string
  label?: string
}

const BackButton: React.FC<BackButtonProps> = ({
  redirectPath,
  label = 'button.label.back'
}) => {
  const redirect = useRedirect()

  return (
    <Button
      variant="text"
      startIcon={<KeyboardBackspaceIcon />}
      label={label}
      className="capitalize"
      size="large"
      onClick={() => redirect(redirectPath)}
    />
  )
}

export default BackButton
