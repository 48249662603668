import React from 'react'
import { Layout, LayoutProps } from 'react-admin'
import AppBar from 'layout/KAppBar'
import RAMenu from 'layout/RAMenu'
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react'

const KLayout: React.FC<LayoutProps> = (props) => {
  const { children, ...rest } = props

  return (
    <Layout {...rest} appBar={AppBar} menu={RAMenu}>
      {/* Here we are catching the error with sentry before it goes to Layout's ErrorBoundary */}
      <SentryErrorBoundary
        onError={(err) => {
          // Let Sentry do its magic and pass the error to Layout's ErrorBoundary
          throw err
        }}
      >
        {children}
      </SentryErrorBoundary>
    </Layout>
  )
}

export default KLayout
