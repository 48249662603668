import { YupSchema } from 'services/yup/types'
import { yupArray, yupIgnore, yupNumber, yupObject } from 'services/yup'
import * as yup from 'yup'
import {
  TimeBeforeHint,
  TimeBeforeAfterHint,
  QuantityHint,
  WithQuantityHint,
  WithTimeBeforeAfterHint,
  WithTimeBeforeHint
} from '@kampaay/common'

const timeBeforeHintVS: YupSchema<TimeBeforeHint> = yupObject().shape({
  maxParticipants: yupNumber().required(),
  hoursBefore: yupNumber().required()
})

export const withTimeBeforeHintVS: YupSchema<WithTimeBeforeHint> =
  yupObject().shape({
    timeBeforeHint: yupArray(timeBeforeHintVS).optional(),
    defaultTimeBeforeHint: yup.lazy((_, { parent }) =>
      parent.showTimeBeforeAfterHint ? timeBeforeHintVS : yupObject()
    ),
    showTimeBeforeHint: yupIgnore()
  })

const timeBeforeAfterHintVS: YupSchema<TimeBeforeAfterHint> =
  timeBeforeHintVS.shape({
    hoursAfter: yupNumber().required()
  })

export const withTimeBeforeAfterHintVS: YupSchema<WithTimeBeforeAfterHint> =
  yupObject().shape({
    timeBeforeAfterHint: yupArray(timeBeforeAfterHintVS).optional(),
    defaultTimeBeforeAfterHint: yup.lazy((_, { parent }) =>
      parent.showTimeBeforeAfterHint ? timeBeforeAfterHintVS : yupObject()
    ),
    showTimeBeforeAfterHint: yupIgnore()
  })

export const quantityHintVS: YupSchema<QuantityHint> = yupObject().shape({
  maxParticipants: yupNumber().required(),
  quantityMultiplier: yupNumber().required().max(100)
})

export const withQuantityHintVS: YupSchema<WithQuantityHint> =
  yupObject().shape({
    quantityHint: yupArray(quantityHintVS).optional(),
    defaultQuantityHint: yup.lazy((_, { parent }) =>
      parent.showQuantityHint ? quantityHintVS : yupObject()
    ),
    showQuantityHint: yupIgnore(),
    guestsNumberMock: yupIgnore()
  })
