import { KTestContext, KTestFunction } from 'services/yup/types'
import {
  SchemaDescription,
  SchemaInnerTypeDescription,
  SchemaObjectDescription
} from 'yup/lib/schema'
import { entries } from 'services/utils'

/**
 * Returns a mock context object for use in testing Yup validation functions.
 * this beacuse Yup validation functions require a context object to be called.
 * @param context The context object to use as the parent of the mock context.
 * @returns The mock context object.
 */
export const mockKTestContext = <TContext extends {} = {}>(
  context: TContext = {} as TContext
): KTestContext<TContext> => ({
  path: '',
  options: {},
  parent: context,
  schema: {},
  resolve: ((a: boolean) => a) as any,
  createError: (() => {}) as any
})

/**
 * Wrapper useful to call a Yup validation function with the given value and mocked context.
 *
 * This function is used to test Yup validation functions considering that our validations functions
 * used in a Yup schema inject Context, but in dev environment we don't have a context object.
 * It calls the given validation function with a mock context object and the given value.
 *
 * @param fn The Yup validation function to test.
 * @param value The value to validate.
 * @param context The context object to use for validation. Optional, defaults to an empty object.
 * @returns The result of the validation function.
 *
 * when we want use with JEST we need mock context object so instead call directly the validation function you can use this function
 *
 *
 * @example
 *
 * ```ts
    callYupTest(uniqueMaxQuantityValidation, notUniqueMaxQuantityMOCK)
 * ```
 */
export const callYupTestFunction = <
  TValue,
  TFunction extends KTestFunction<TValue, TContext>,
  TContext extends {} = {}
>(
  fn: TFunction,
  value: TValue,
  context: TContext = {} as TContext
) => {
  return fn.call(mockKTestContext(context), value, mockKTestContext(context))
}

export type SchemaDesc = Partial<
  SchemaDescription & SchemaObjectDescription & SchemaInnerTypeDescription
>

/**
 * Recursive function that parses a validation schema description and returns an array of sources of required fields.
 * Arrays are not going to have any index notation.
 *
 * @example
 * ```ts
 * const schema = yup.object().shape({
 *   name: yupString().required(),
 *   items: yupArray(yupObject().shape({ arrayItemName: yupString().required() })),
 *   nestedObj: yupObject().shape({ nestedName: yupString().required() }
 * })
 *
 * const sources = getRequiredFieldSources(schema.describe())
 *
 * Result:
 * sources = ['name', 'items.arrayItemName','nestedObj.nestedName']
 * ```
 *
 * @param schemaDescription the schema description of a validation schema: validationSchema.describe()
 * @param source the source of the node that we want to scan for required fields, defaulted as the root of the schema
 * @param sources the array of sources of the required fields that will be mutated by the function by adding all the ones found
 */
export const getRequiredFieldSources = (
  schemaDescription: SchemaDesc | null,
  source = '',
  sources: string[] = []
) => {
  // if the schema has a "required" test its source is added to the array
  if (schemaDescription?.tests?.find((t) => t.name === 'required')) {
    sources.push(source)
  }

  // if the schema is an array that has a min test its source is added to the array, to show that it's required to insert at least one element
  if (
    schemaDescription?.type === 'array' &&
    schemaDescription?.tests?.find((t) => t.name === 'min')
  ) {
    sources.push(source)
  }

  // if the schema is an object we check every one of its fields
  if (schemaDescription?.fields) {
    const nestedSource = !!source ? `${source}.` : ''
    entries(schemaDescription?.fields).forEach(([key, value]) => {
      getRequiredFieldSources(value, `${nestedSource}${key}`, sources)
    })
  }

  // if the schema is an array of objects we check that schema as well
  if (schemaDescription?.innerType) {
    getRequiredFieldSources(schemaDescription.innerType, source, sources)
  }

  return sources
}
