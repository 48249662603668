import React from 'react'
import LocalizedTextField from 'components/Fields/Localized/LocalizedTextField'
import { useKRecordContext } from 'hooks/useKRecordContext'
import { useKTranslate } from 'hooks/useKTranslate'
import { APILinkedGeoArea, WithId } from '@kampaay/common'

type WithIdAndGeoAreaPresetFull = WithId & {
  geoAreaPresetFull: APILinkedGeoArea | undefined
}

/**
 * The field that displays the geoarea name
 * Because the geoarea name is a multilang field, we need to translate it
 * if there is no geoarea associated we show the common.label.other
 *
 * The label prop is used by the DataGrid in order to show the header table title,
 * so we cannot hard-code the label here inside!!
 */
const GeoareaEventAndTransactionField: React.FC<{
  label?: 'form.fields.geoareaPreset'
}> = () => {
  const record = useKRecordContext<WithIdAndGeoAreaPresetFull>()!
  const translatedRecord = {
    ...record,
    geoAreaPresetName: useKTranslate(
      record.geoAreaPresetFull?.nickname ?? 'common.label.other'
    )
  }

  return (
    <LocalizedTextField source="geoAreaPresetName" record={translatedRecord} />
  )
}

export default GeoareaEventAndTransactionField
