import {
  EntityWithCSV,
  MulticonfigCSVEntityDescriptor
} from 'services/csv/types'

/**
 * Checks if the export config has multiple configs
 * @param csvEntityDescriptor CSVEntityDescriptorMap value
 * @returns true if the config has multiple configs, false otherwise
 */
export const isMulticonfigCSVEntityDescriptor = <T extends EntityWithCSV>(
  csvEntityDescriptor: object
): csvEntityDescriptor is MulticonfigCSVEntityDescriptor<T> => {
  return 'primary' in csvEntityDescriptor
}
