import React from 'react'
import { getMarginPercFromPriceAndCost, getVatFromNet } from '@kampaay/common'
import { useTranslate } from 'react-admin'
import { useFormContext } from 'react-hook-form'

type Props = {
  priceSource: string
  costSource: string
}

const MarginPercAndVatFields: React.FC<Props> = ({
  priceSource,
  costSource
}) => {
  const translate = useTranslate()
  const { getValues } = useFormContext()
  const [price, cost] = getValues([priceSource, costSource])

  if (!price || !cost) return null

  return (
    <>
      <span style={{ marginRight: '24px' }}>
        {`${translate(
          'form.fields.marginPercentage'
        )}: ${getMarginPercFromPriceAndCost(price, cost).toFixed(2)}%`}{' '}
      </span>

      <span>
        {`${translate('form.fields.inboundVat')}: ${getVatFromNet(
          price
        ).toFixed(2)}`}
      </span>
    </>
  )
}

export default MarginPercAndVatFields
