import React from 'react'
import SectionWrapper from 'components/UI/SectionWrapper'
import KCreate from 'components/Forms/KCreate/KCreate'
import { KSimpleForm } from 'components/Forms/KForms/KSimpleForm'
import OrganizationBudgetFormContent from 'components/Forms/OrganizationBudgetFormContent'
import { useParams } from 'react-router-dom'

const OrganizationBudgetCreate: React.FC = () => {
  const { id: organizationId } = useParams()

  return (
    <SectionWrapper title="resources.organization.createBudget">
      <KCreate resource="organizationbudget">
        <KSimpleForm
          redirectAfterCreate={(_, id) =>
            `organization/${organizationId}/budgets/${id}`
          }
        >
          <OrganizationBudgetFormContent />
        </KSimpleForm>
      </KCreate>
    </SectionWrapper>
  )
}

export default OrganizationBudgetCreate
