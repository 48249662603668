import React from 'react'
import SectionWrapper from 'components/UI/SectionWrapper'
import FacetCommon from 'resources/facets/FacetFormContent'
import { KSimpleForm } from 'components/Forms/KForms/KSimpleForm'
import KCreate from 'components/Forms/KCreate/KCreate'

const FacetCreate: React.FC = () => {
  return (
    <SectionWrapper>
      <KCreate>
        <KSimpleForm>
          <FacetCommon disable={false} />
        </KSimpleForm>
      </KCreate>
    </SectionWrapper>
  )
}

export default FacetCreate
