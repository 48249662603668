import React from 'react'
import LogisticSelectInput from 'components/Inputs/LogisticSelectInput'
import OptionedProductFormContent from 'components/Forms/OptionedProductFormContent'
import FoodFacetSection from 'components/Inputs/Facets/FacetFormSections/FoodFacetSection'
import LinkedRulesField from 'components/Fields/LinkedRulesField'

const FoodFormContent: React.FC = () => (
  <OptionedProductFormContent
    facetFields={<FoodFacetSection />}
    slotBeforeHighlights={<LogisticSelectInput />}
    upsellingEntity="foodsupselling"
  >
    <LinkedRulesField />
  </OptionedProductFormContent>
)

export default FoodFormContent
