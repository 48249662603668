import {
  filterNHRRules,
  filterTPRRules
} from 'services/validations/catalog/location/utils'
import {
  APIPricingRuleSet,
  LocationPricingRuleSet,
  LocationRule
} from '@kampaay/common'

// ============
// PARSE
// ============

export const byRulesPricingParse = ({
  timePeriodRules,
  minHoursRules,
  ...rest
}: APIPricingRuleSet): LocationPricingRuleSet => ({
  ...rest,
  minHoursRules,
  pricingRules: [
    ...timePeriodRules.map((r) => ({
      ...r,
      mode: 'tpr'
    })),
    ...minHoursRules
      .map((r) => ({
        ...r,
        mode: 'nhr'
      }))
      .sort((a, b) => (a.atLeast > b.atLeast ? 1 : -1))
  ] as LocationRule[]
})

// ============
// WRITE
// ============

export const byRulesPricingWrite = ({
  pricingRules,
  ...rest
}: LocationPricingRuleSet): APIPricingRuleSet => {
  return {
    ...rest,
    timePeriodRules: filterTPRRules(pricingRules),
    minHoursRules: filterNHRRules(pricingRules)
  }
}
