import React from 'react'
import LocalizedTextField from 'components/Fields/Localized/LocalizedTextField'
import MainProductList, {
  MainProductListProps
} from 'components/Lists/MainProductList'

const ProfessionalFiguresList: React.FC<MainProductListProps> = (props) => {
  return (
    <MainProductList {...props}>
      <LocalizedTextField source="sectionName" label="Type" sortable={false} />
    </MainProductList>
  )
}

export default ProfessionalFiguresList
