import type { FileUploader, MultipleFileUploader } from '../../../../types'
import { mainProductWrite } from '../products'
import type {
  OptionedProduct,
  OptionedProductToApi,
  OptionGroup,
  OptionGroupToAPI,
  ProductWithUpselling,
  ProductWithUpsellingToApi
} from './types'

/**
 * @deprecated Kept for backwards compatibility to use for the obsolete location model. This level of abstraction is not needed anymore for the remaining products: food, physicals and professionals.
 */
export const productWithUpsellingWrite =
  (imageUploader: MultipleFileUploader) =>
  async ({
    upsellingItems,
    ...rest
  }: ProductWithUpselling): Promise<ProductWithUpsellingToApi> => ({
    ...(await mainProductWrite(imageUploader)(rest)),
    upselling: upsellingItems
  })

const optionImageWrite = (
  options: OptionGroup[],
  imagesArray: (string | undefined)[]
) =>
  options.map((option) => ({
    ...option,
    items: option.items.map((choice) => ({
      ...choice,
      image: imagesArray.shift()
    }))
  }))

const uploadOptionImages =
  (imageUploader: FileUploader) => (optionGroup: OptionGroup[]) =>
    optionGroup
      .map((option) => option.items.map((i) => imageUploader(i.image)))
      .flat()

export const optionedProductWrite =
  (imageUploader: FileUploader, imagesUploader: MultipleFileUploader) =>
  async ({
    options,
    pricing,
    minQuantity,
    hasFlexibleOptions,
    ...rest
  }: OptionedProduct): Promise<OptionedProductToApi> => {
    const optionsImgUpl = uploadOptionImages(imageUploader)(options ?? [])
    const optionsImg = await Promise.all([...optionsImgUpl])

    const opt = optionImageWrite(options ?? [], optionsImg)

    // here we remove isPriced flag from the object we send to api
    // and if pricing is not set for an option, we send an empty pricing
    const optionsToAPI = opt.map(
      (opt): OptionGroupToAPI => ({
        ...opt,
        maxSelection: opt.maxSelection ? opt.maxSelection : undefined,
        items: opt.items.map((optItem) => {
          const { isPriced, ...rest } = optItem
          return {
            ...rest,
            pricing: isPriced ? rest.pricing : []
          }
        })
      })
    )

    return {
      ...(await productWithUpsellingWrite(imagesUploader)(rest)),
      options: optionsToAPI,
      pricing,
      minQuantity,
      hasFlexibleOptions
    }
  }
