import { buildFilterConfig } from 'services/utils/filters'
import { FILTER_SOURCES } from '@kampaay/common'
import { TAILORED_FILTERS } from 'services/filters/entities/tailored'

export const CATALOG_FILTERS = [
  ...TAILORED_FILTERS,
  buildFilterConfig('supplierAutocompleteArray', {
    source: FILTER_SOURCES.supplierId.in,
    alwaysOn: true
  })
]
