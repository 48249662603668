import React from 'react'
import { RAFormFC } from 'types/common'
import EventCustomizationArrayInput, {
  EventCustomizationArrayInputProps
} from 'components/FormComponents/Customizations/EventCustomizationArrayInput'
import KAccordion from 'components/UI/Accordions/KAccordion/KAccordion'
import { useForm } from 'hooks/useForm'
import { Customization } from 'services/api/entities/events/types/internal/event-models'

const EventCustomizationArrayInputAccordion: RAFormFC<
  EventCustomizationArrayInputProps
> = ({ source, maxCustomizations }) => {
  const { useGetValue } = useForm<{
    customizations: Customization[]
  }>(source)
  const customizations = useGetValue('customizations')
  const hasItems = !!customizations?.length

  return (
    <KAccordion
      accordionTitle="form.headers.customization"
      shouldDisplayAlertIcon={hasItems}
      defaultExpanded={hasItems}
    >
      <EventCustomizationArrayInput
        maxCustomizations={maxCustomizations}
        source={source}
      />
    </KAccordion>
  )
}

export default EventCustomizationArrayInputAccordion
