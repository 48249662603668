import {
  getCurrentCountry,
  type LocaleCode,
  countrySwitchStrict,
  type I18nLocale
} from '../countries'

let currentLang = getCurrentCountry().localeCode

export const getCurrentLang = () => currentLang
export const setCurrentLang = (lang: LocaleCode) => (currentLang = lang)

export const CURRENT_I18N_LOCALE = countrySwitchStrict<I18nLocale>({
  IT: 'en-IT',
  SE: 'en-SE'
})
