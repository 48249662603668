import React from 'react'
import { useGetOne } from 'react-admin'
import KToolTip from 'components/UI/KTooltip'
import { useForm } from 'hooks/useForm'
import { RAFormFC } from 'types/common'
import KNumberInput from 'components/Inputs/KInputs/KNumberInput/KNumberInput'
import { ServicesConfig } from 'services/api/entities/services-config/types/internal/services-config-models'
import { MainProduct } from '@kampaay/common'

const BookingTimeLimitInput: RAFormFC = ({ source }) => {
  const { data: servicesConfig } = useGetOne<ServicesConfig>('servicesconfig', {
    id: 'servicesconfig'
  })

  const { getSource } = useForm<MainProduct>(source)

  if (!servicesConfig) return null

  return (
    <KToolTip title="tooltip.hint.bookingTimeLimit">
      <KNumberInput
        source={getSource('advanceReservationMin')}
        label="form.fields.bookingTimeLimit"
        fullWidth
        defaultValue=""
      />
    </KToolTip>
  )
}

export default BookingTimeLimitInput
