import React from 'react'
import { CatalogItemBaseListProps } from 'components/Lists/CatalogItemBaseList'
import { exportFormatter } from 'services/csv/exporter'
import TailoredFields from 'components/Fields/TailoredFields'
import { FILTER_SOURCES } from '@kampaay/common'
import KCloneButton from 'components/Buttons/KCloneButton'
import { VISIBLE_TO_ALL_CHOICES } from 'components/Inputs/FilterInputs/TailoredVisibleToAllFilter'
import ProductCommonList from 'components/Lists/ProductCommonList'
import CopyAsProposalItemButton from 'components/Lists/CopyAsProposalItemButton'
import ListItemWithLabel from 'components/UI/ListItemWithLabel'

export type MainProductListProps = CatalogItemBaseListProps

const MainProductList: React.FC<MainProductListProps> = ({
  children,
  exporter = exportFormatter(),
  ...rest
}) => {
  return (
    <ProductCommonList
      exporter={exporter}
      {...rest}
      filterDefaultValues={{
        [FILTER_SOURCES.isPublic.eq]: [VISIBLE_TO_ALL_CHOICES[0].id]
      }}
    >
      {children}
      <TailoredFields
        arrayFieldSource={'customersList'}
        chipFieldSource={'email'}
        label={'form.fields.customers'}
        tailoredListField={'customersFull'}
      />
      <TailoredFields
        arrayFieldSource={'organizationsList'}
        chipFieldSource={'name'}
        label={'form.fields.organizations'}
        tailoredListField={'organizationsFull'}
      />
      <KCloneButton />
      <ListItemWithLabel label={'form.fields.copyAsProposalItem'}>
        <CopyAsProposalItemButton />
      </ListItemWithLabel>
    </ProductCommonList>
  )
}

export default MainProductList
