import type { GeoAreaPreset, GeoAreaPresetToAPI } from './types'

export const geoareaPresetWrite = async ({
  geoArea,
  ...req
}: GeoAreaPreset): Promise<GeoAreaPresetToAPI> => ({
  ...req,
  geoArea: {
    ...geoArea,
    radius: geoArea.radius * 1000 // turning kilometers into meters
  }
})
