import { getSetFromHalfSteps } from 'services/utils/validation'
import { areSetsEqual } from 'services/utils'
import {
  LocationPricingRuleSet,
  LocationRule,
  NHR,
  TimeLabel,
  TPR
} from '@kampaay/common'

/**
 * Given rules returns only the NHRs
 * @param rules
 */
export const filterNHRRules = (rules: LocationRule[] | undefined) =>
  (rules ?? []).filter((r): r is NHR => r.mode === 'nhr')

/**
 * Given rules returns only the TPRs
 * @param rules
 */
export const filterTPRRules = (rules: LocationRule[] | undefined) =>
  (rules ?? []).filter((r): r is TPR => r.mode === 'tpr')

/**
 * Returns true if the NHR rule is the default one
 * The default NHR has 0.5 atLeast and is not extra and is required when
 * there is at least one NHR in the rule group
 * @param nhr
 */
export const isDefaultNHR = (nhr: NHR) => nhr.atLeast === 0.5 && !nhr.isExtra

/**
 * Returns true if the having some TPRs they cover all the available period
 * If there is at least one NHR this validation is skipped
 * @param pricingRules
 * @param availableFrom
 * @param availableTo
 */
export const hasRuleSetValidCoverage = (
  { pricingRules }: LocationPricingRuleSet,
  availableFrom: TimeLabel,
  availableTo: TimeLabel
): boolean => {
  const tpr = filterTPRRules(pricingRules)

  const hasNHRRules = tpr.length < pricingRules.length

  // if there are no TPRs, the validation is true
  if (hasNHRRules || !tpr.length || !availableTo || !availableTo) return true

  const timeRangeNeeded = getSetFromHalfSteps({
    start: availableFrom,
    end: availableTo
  })

  const timeRangeCoveredByRules = getSetFromHalfSteps(
    ...tpr.map((r) => ({ start: r.hoursFrom, end: r.hoursTo }))
  )

  return areSetsEqual(timeRangeNeeded, timeRangeCoveredByRules)
}
