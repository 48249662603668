import { Cast } from '@mui/icons-material'
import VirtualExperienceUpsellingList from 'resources/catalog/virtualexperiences/upselling/VirtualExperiencesUpsellingList'
import VirtualExperienceUpsellingEdit from 'resources/catalog/virtualexperiences/upselling/VirtualExperiencesUpsellingEdit'
import VirtualExperienceUpsellingCreate from 'resources/catalog/virtualexperiences/upselling/VirtualExperienceUpsellingCreate'

const virtualUpsellingIndex = {
  icon: Cast,
  list: VirtualExperienceUpsellingList,
  edit: VirtualExperienceUpsellingEdit,
  create: VirtualExperienceUpsellingCreate
}

export default virtualUpsellingIndex
