import { Switch } from '@mui/material'
import React from 'react'
import { useTranslate } from 'react-admin'

type SwitchInputProps = {
  defaultValue?: boolean
  onChange: (value: boolean) => void
  label: string
  checked?: boolean
}

/**
 * Simple switch with no state, this is a wrapper of MUI switch
 * @param defaultValue
 * @param onChange
 * @param label
 * @param checked
 */
const SwitchInput: React.FC<SwitchInputProps> = ({
  defaultValue,
  onChange,
  label,
  checked
}) => {
  const translate = useTranslate()

  return (
    <div>
      <Switch
        defaultChecked={defaultValue}
        checked={checked}
        onChange={(_, value) => onChange(value)}
      />
      {translate(label)}
    </div>
  )
}

export default SwitchInput
