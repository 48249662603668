import { UseValidationSchema } from 'services/yup/types'
import { yupArray, yupIgnore, yupNumber, yupString } from 'services/yup'
import { ActivityStatus, TimeLabel } from '@kampaay/common'
import { Location } from 'services/api/entities/catalog/location/types/internal/location-models'
import { locationArrangementMapVS } from 'services/validations/catalog/location/arrangement'
import { locationByRulePricingArrayVS } from 'services/validations/catalog/location/byRulesPricing'
import { useProductWithUpsellingVS } from 'services/validations/catalog/productWithUpselling'
import { getGeoCodedAddressVS } from 'services/validations/common'

export const useLocationVS: UseValidationSchema<Location> = () =>
  useProductWithUpsellingVS().shape({
    nickname: yupString().required(),
    status: yupString<ActivityStatus>().required(),
    peopleCapacity: yupNumber().required().min(0),
    roomCapacity: yupNumber().required().min(0),
    defaultHoursBefore: yupNumber().required().min(0),
    defaultHoursAfter: yupNumber().required().min(0),
    availableFrom: yupString<TimeLabel>().required(),
    availableTo: yupString<TimeLabel>().required(),
    pricing: locationByRulePricingArrayVS,
    planimetry: yupIgnore(),
    locationZone: yupIgnore(),
    supplierName: yupIgnore(),
    equipments: yupArray(),
    characteristics: yupArray(),
    filterLocationFacet: yupIgnore(),
    tagFacets: yupIgnore(),
    arrangements: locationArrangementMapVS,
    eventTypeFacet: yupIgnore(),
    geoCodedAddress: getGeoCodedAddressVS(true).required()
  })
