import React from 'react'
import KHeader, { KHeaderProps } from 'components/UI/KHeader'
import {
  getCurrentLang,
  parseMultilangOrFallbackToAnyAvailable
} from '@kampaay/common'

type LocalizedHeaderProps = KHeaderProps

const LocalizedHeader: React.FC<LocalizedHeaderProps> = ({
  title,
  headingLevel
}) => {
  const currentLang = getCurrentLang()
  const translatedLabel = parseMultilangOrFallbackToAnyAvailable(
    title,
    currentLang
  )

  ///////////////////////////
  // JSX
  ///////////////////////////
  return <KHeader headingLevel={headingLevel} title={translatedLabel} />
}

export default LocalizedHeader
