import React, { type FC, useMemo, useState } from 'react'
import SectionWrapper from 'components/UI/SectionWrapper'
import OrganizationMenuWrapper from 'components/FormComponents/Organization/OrganizationMenuWrapper'
import { Paper } from '@mui/material'
import OrganizationCalendar from 'components/UI/Organization/Calendar/OrganizationCalendar'
import { useKGetList } from 'hooks/useKGetList'
import type { EventPlaceholder } from 'services/api/entities/event-placeholder/types/internal/event-placeholder-models'
import {
  getCalendarSurroundingPeriod,
  getToday
} from 'services/utils/organization/calendar'
import { type Concierge, FILTER_SOURCES } from '@kampaay/common'
import { dateToDateOnly } from '@kampaay/common'
import type { EventItem } from 'services/api/entities/events/types/internal/event-models'
import { useParams } from 'react-router-dom'

// Here we are creating a new type that extends Concierge and overwrites the startDate property
// making it required. This is because BE will always return concierges which have a defined start date
export type CalendarConcierge = Concierge & {
  startDate: Date
}

const OrganizationCalendarList: FC = () => {
  const { id: organizationId } = useParams()
  const surroundingPeriod = getCalendarSurroundingPeriod(getToday())
  const [currentPeriod, setCurrentPeriod] = useState(surroundingPeriod)

  const sharedFilters = useMemo(() => {
    return {
      [FILTER_SOURCES.organizationId.eq]: organizationId,
      [FILTER_SOURCES.startDate.lte]: dateToDateOnly(currentPeriod.end),
      [FILTER_SOURCES.endDate.gte]: dateToDateOnly(currentPeriod.start)
    }
  }, [currentPeriod])

  const { isLoading: arePlaceholdersLoading, list: placeholders } =
    useKGetList<EventPlaceholder>('eventPlaceholder', { filter: sharedFilters })

  const { isLoading: areEventsLoading, list: events } = useKGetList<EventItem>(
    'events',
    {
      filter: {
        ...sharedFilters,
        [FILTER_SOURCES.status.neq]: 'created',
        [FILTER_SOURCES.isFromConcierge.eq]: false,
        [FILTER_SOURCES.hasEventPlaceholder.eq]: false
      }
    }
  )

  const { isLoading: areConciergeEventsLoading, list: conciergeEvents } =
    useKGetList<CalendarConcierge>('concierge', {
      filter: {
        ...sharedFilters,
        [FILTER_SOURCES.hasEventPlaceholder.eq]: false
      }
    })

  const isLoading =
    arePlaceholdersLoading || areEventsLoading || areConciergeEventsLoading

  return (
    <SectionWrapper>
      <Paper className="mt-4 h-[90vh]">
        <OrganizationMenuWrapper hasPadding>
          <OrganizationCalendar
            isLoading={isLoading}
            items={[...placeholders, ...events, ...conciergeEvents]}
            currentPeriod={currentPeriod}
            setCurrentPeriod={setCurrentPeriod}
          />
        </OrganizationMenuWrapper>
      </Paper>
    </SectionWrapper>
  )
}

export default OrganizationCalendarList
