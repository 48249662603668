import {
  type CountryCode,
  type SupportedCountryCode,
  countrySwitch,
  getCurrentCountry
} from '../geo-management'

export const DEFAULT_GUESTS_FOR_PRICING = 30
export const GLOBAL_VAT_RATE = countrySwitch(22, {
  SE: 25
})

export const round =
  (decimals = 2) =>
  (num: number) => {
    const roundFactor = Math.pow(10, decimals)
    return Math.round(num * roundFactor) / roundFactor
  }
export const roundTwoDecimals = round(2)

export const getNetPriceFromMargin = (marginPerc: number, costNoVat: number) =>
  costNoVat / (1 - marginPerc / 100)
export const getGrossPriceFromMargin = (margin: number, costNoVat: number) =>
  getNetPriceFromMargin(margin, costNoVat) * (1 + GLOBAL_VAT_RATE / 100)

export const getDiscountedPrice = (price: number, discount: number) =>
  price * discount

export const getVatFromNet = (netPrice: number, vatRate = GLOBAL_VAT_RATE) =>
  (netPrice / 100) * vatRate

export const getMargin = (netPrice: number, costNoVat: number) =>
  roundTwoDecimals(netPrice - costNoVat)

export const getMarginPerc = (margin: number, price: number) =>
  roundTwoDecimals((margin / price) * 100)

export const getMarginPercFromPriceAndCost = (price: number, cost: number) =>
  roundTwoDecimals(getMarginPerc(getMargin(price, cost), price))

export const getVatEffect = (serviceVat: number, vat: number) =>
  serviceVat - vat

/**
 * Formats a number as a currency string.
 *
 * @param {number} value - The number to be formatted.
 * @param {string} currencyString - The ISO 4217 currency code to use for formatting.
 * @param {Object} fractionDigits - An object specifying the minimum and maximum number of fraction digits, both defaulting to 0.
 * @returns {string} The formatted currency string.
 *
 * @example
 * // Returns "$1,000"
 * getAsCurrency(1000, 'USD')
 *
 * // Returns "1,000.00€"
 * getAsCurrency(1000, 'EUR', { min: 2, max: 2 })
 */
export const getAsCurrency = (
  value: number,
  currencyString: string,
  fractionDigits: {
    min: number
    max: number
  } = {
    min: 0,
    max: 0
  }
) =>
  Intl.NumberFormat(navigator.language, {
    style: 'currency',
    currency: currencyString,
    minimumFractionDigits: fractionDigits.min,
    maximumFractionDigits: fractionDigits.max
  }).format(value)

/**
 * Returns the VAT rate based on the business country.
 *
 * If the customer has a businessCountry associated, and it's different
 * from the country we are operating in, we apply a 0 VAT rate.
 * Otherwise, we apply the global VAT rate.
 *
 * @param {SupportedCountryCode} countryCode - The country code where we are operating.
 * @param {CountryCode} [businessCountry] - The country code of the business. Optional.
 * @returns {number} The VAT rate to be applied.
 */
export const getVatRateByBusinessCountry = (
  countryCode: SupportedCountryCode,
  businessCountry?: CountryCode
) => {
  if (businessCountry && businessCountry !== countryCode) {
    return 0
  }
  return GLOBAL_VAT_RATE
}

type GetBillingInfoParams = {
  price: number
  costNoVat: number
  costVatPerc?: number
  customerBusinessCountry?: CountryCode
}
export type BillingInfo = {
  vatRate: number
  price: number
  costNoVat: number
  margin: number
  marginPerc: number
  costVatPerc: number | undefined
}
export const getBillingInfo = ({
  price,
  costNoVat,
  costVatPerc,
  customerBusinessCountry
}: GetBillingInfoParams): BillingInfo => {
  const countryCode = getCurrentCountry().code

  const vatRate = getVatRateByBusinessCountry(
    countryCode,
    customerBusinessCountry
  )
  const margin = getMargin(price, costNoVat)
  const marginPerc = getMarginPerc(margin, price)
  return {
    vatRate,
    price,
    costNoVat,
    margin,
    marginPerc,
    costVatPerc
  }
}
