import React from 'react'
import BaseTailoringsEdit from 'resources/crm/clients/organization/tailoring/BaseTailoringsEdit'
import { RAFormFC } from 'types/common'
import TailoringsArrayInput from 'resources/crm/clients/organization/tailoring/components/TailoringsArrayInput'
import { APIEntity } from 'services/api/entities'

type ProductCommonTailoringsEditProps = {
  productEntity: APIEntity
  tailoringsInput: RAFormFC<{ productEntity: APIEntity }>
}

const ProductCommonTailoringsEdit: React.FC<
  ProductCommonTailoringsEditProps
> = ({ productEntity, tailoringsInput: TailoringsInput }) => {
  return (
    <BaseTailoringsEdit>
      <TailoringsArrayInput productEntity={productEntity}>
        <TailoringsInput productEntity={productEntity} />
      </TailoringsArrayInput>
    </BaseTailoringsEdit>
  )
}

export default ProductCommonTailoringsEdit
