import React, { useEffect } from 'react'
import { RAFormFC } from 'types/common'
import { EventItem } from 'services/api/entities/events/types/internal/event-models'
import useGetEventCheckoutRepliesFromCustomer from 'hooks/useGetEventCheckoutRepliesFromCustomer'
import { useFormContext } from 'react-hook-form'
import QuestionRepliesAccordion from 'resources/operations/events/EventsComponents/CheckoutQuestionReply/QuestionRepliesAccordion'
import { useForm } from 'hooks/useForm'

const EventCreateQuestionReplyList: RAFormFC = ({ source }) => {
  const { useGetValue } = useForm<EventItem>(source)
  const selectedCustomerId = useGetValue('customer')
  const { questionReplies, isLoading, error, customerData } =
    useGetEventCheckoutRepliesFromCustomer(selectedCustomerId)
  const { setValue } = useFormContext<EventItem>()

  useEffect(() => {
    setValue('checkoutQuestionResponses', questionReplies)

    // use stringify to allow the questionReplies array to be used as dependency
    // https://stackoverflow.com/questions/59467758/passing-array-to-useeffect-dependency-list
  }, [JSON.stringify(questionReplies)])

  const showReplies = !!customerData?.membershipId && questionReplies.length

  /************************************************************************
   * TODO: investigate on useFrom reactivity problems (by kris)
   * **********************************************************************
   */
  // the problem with useForm is that: the checkoutQuestions accordion content
  // is rendered only the second time the user is selected.
  // steps to reproduce:
  // - select a customer (that belong to an organization, eg urjah201)
  // (arrayInput is NOT populated)
  // - clear customer selection
  // - re-elect a customer (that belong to an organization, eg urjah201)
  // (now the arrayInput is populated)
  //
  // const { getSource, useSetValue, useGetValue } = useForm<EventItem>(source)
  //
  // const checkoutQuestionResponses = useGetValue('checkoutQuestionResponses')
  // const setReplies = useSetValue('checkoutQuestionResponses')
  // if (
  //   checkoutQuestionResponses &&
  //   questionReplies.length &&
  //   checkoutQuestionResponses.every((cq, i) => cq.id !== questionReplies[i].id)
  // )
  //   setReplies(questionReplies)
  //
  // KConsole.log(
  //     'fffffffffffffff checkoutQuestionResponses: ',
  //     checkoutQuestionResponses
  // )
  //
  // KConsole.log(
  //     "fffffffffffffff getSource('checkoutQuestionResponses'): ",
  //     getSource('checkoutQuestionResponses')
  // )
  /************************************************************************
   * END TESTING
   * **********************************************************************
   */

  ///////////////////////////
  // JSX
  ///////////////////////////
  if (isLoading) return <>Loading</>
  if (error) return <>Error</>

  return showReplies ? (
    <QuestionRepliesAccordion defaultExpanded={true} />
  ) : null
}

export default EventCreateQuestionReplyList
