import {
  addDays,
  addMonths,
  addWeeks,
  endOfMonth,
  isSameDay,
  startOfMonth
} from 'date-fns'
import type { NavigateAction, View } from 'react-big-calendar'
import { getDateFromDateAndTime, getTimeLabelFromDate } from '@kampaay/common'
import type { CalendarItem } from 'components/UI/Organization/Calendar/OrganizationCalendar'
import type { TimeLabel } from '@kampaay/common'
import type {
  EventPlaceholder,
  LinkedConcierge,
  LinkedEvent
} from 'services/api/entities/event-placeholder/types/internal/event-placeholder-models'
import type { APIEntity } from 'services/api/entities'
import { kampaayTheme } from 'layout/config/themes'

export type CalendarDateRange<T extends Date | TimeLabel | undefined = Date> = {
  start: T
  end: T
}

export type CalendarSurroundingPeriod = CalendarDateRange & {
  date: Date
}

/**
 * Returns the current date at midnight.
 * @returns {Date} The current date
 */
export const getToday = (): Date => {
  const today = new Date()

  today.setHours(0, 0, 0, 0)

  return today
}

/**
 * Returns the correct date based on the given action.
 * @param {Date} date The current date
 * @param {(date: Date, amount: number) => Date} sumDate A function to sum dates
 * @param {NavigateAction} action The action performed on the calendar
 * @returns {Date} The updated date
 */
const getCalendarDate = (
  date: Date,
  sumDate: (date: Date, amount: number) => Date,
  action?: NavigateAction
): Date => {
  if (action === 'NEXT') {
    return sumDate(date, 1)
  }

  if (action === 'PREV') {
    return sumDate(date, -1)
  }

  return date
}

/**
 * Returns the start and end of the period surrounding the given date, based on the current view.
 * @param {Date} currentDate The date to surround
 * @param {View} view The current calendar view
 * @param {NavigateAction} action The action performed on the calendar
 * @returns {CalendarSurroundingPeriod} A date range and the selected date
 */
export const getCalendarSurroundingPeriod = (
  currentDate: Date,
  view?: View,
  action?: NavigateAction
): CalendarSurroundingPeriod => {
  let date

  switch (view) {
    case 'day':
      date = getCalendarDate(currentDate, addDays, action)
      break
    case 'week':
      date = getCalendarDate(currentDate, addWeeks, action)
      break
    default:
      date = getCalendarDate(currentDate, addMonths, action)
      break
  }

  return {
    date: date,
    start: startOfMonth(date),
    end: endOfMonth(date)
  }
}

/**
 * Returns the updated surrounding month based on the given action.
 * @param {Date} date The current date
 * @param {View} view The current calendar view
 * @param {NavigateAction} action The action performed on the calendar
 * @returns {CalendarSurroundingPeriod} A date range and the selected date
 */
export const getCalendarUpdatedPeriod = (
  date: Date,
  view?: View,
  action?: NavigateAction
): CalendarSurroundingPeriod => {
  if (action === 'TODAY') {
    return getCalendarSurroundingPeriod(getToday(), view)
  }

  return getCalendarSurroundingPeriod(date, view, action)
}

/**
 * Returns a human-friendly formatted date.
 * @param {Date} date The date to format
 * @returns {string} A formatted date
 */
export const formatCalendarItemDate = (date: Date | string): string => {
  if (date instanceof Date) {
    return Intl.DateTimeFormat('en-US', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    }).format(date)
  } else {
    return date
  }
}

/**
 * Returns a human-friendly formatted series of dates or time labels given a time range.
 * @param {Date | string} start The starting timestamp
 * @param {Date | string} end The ending timestamp
 * @returns {string | undefined} A series of formatted dates or time labels, or undefined
 */
export const formatCalendarItemTimeRange = (
  start?: Date | string,
  end?: Date | string
): string | undefined => {
  if (!start) return

  const formattedStart = formatCalendarItemDate(start)

  if (!end) {
    return formattedStart
  } else {
    const formattedEnd = formatCalendarItemDate(end)

    return `${formattedStart} - ${formattedEnd}`
  }
}

/**
 * A fallback time label to use when no time label could be retrieved.
 */
const fallbackStartTime: TimeLabel = '00:00'

/**
 * Returns the correct start and end time labels given a calendar item.
 * @param {CalendarItem} item The item where time labels are taken
 * @returns {CalendarDateRange<TimeLabel>} A range of time labels
 */
const getCalendarItemTimeLabels = (
  item: CalendarItem
): CalendarDateRange<TimeLabel | undefined> => {
  const startTime =
    'startTime' in item ? item.startTime : getTimeLabelFromDate(item.startDate)
  const endTime =
    'endTime' in item ? item.endTime : getTimeLabelFromDate(item.endDate)

  // FIXME: This is a bit ugly, but we'll keep it for now until we make getTimeLabelFromDate return undefined instead
  return {
    start: startTime === '---' ? undefined : startTime,
    end: endTime === '---' ? undefined : endTime
  }
}

/**
 * Returns the correct start and end dates given dates and time labels.
 * @param {CalendarItem} item The item where dates and time labels are taken
 * @returns {CalendarDateRange} A range of dates
 */
export const getCalendarItemDates = (item: CalendarItem): CalendarDateRange => {
  const { start: startTime, end: endTime } = getCalendarItemTimeLabels(item)
  const start = getDateFromDateAndTime(
    item.startDate,
    startTime || fallbackStartTime
  )
  const endDate = item.endDate || item.startDate

  if (endTime) {
    const end = getDateFromDateAndTime(endDate, endTime)

    return { start, end }
  }

  if (isSameDay(item.startDate, endDate)) {
    const end = getDateFromDateAndTime(addDays(endDate, 1), fallbackStartTime)

    return { start, end }
  }

  return { start, end: endDate }
}

/**
 * Returns a list of calendar compatible formatted items.
 * @param {CalendarItem[]} items The items to format
 * @returns A list of formatted items
 */
export const formatCalendarItems = (items: CalendarItem[]) => {
  return items.map((item) => {
    const { start, end } = getCalendarItemDates(item)

    return {
      startDate: start,
      endDate: end,
      originalItem: item
    }
  })
}

/**
 * The default color for a placeholder.
 */
export const calendarPlaceholderDefaultColor = '#52545E'

/**
 * The default color for an event.
 */
export const calendarEventDefaultColor = kampaayTheme.palette.primary.main

/**
 * Returns whether the given item is a placeholder.
 * @param {CalendarItem} item The item to check
 * @returns {item is EventPlaceholder} Whether the item is a placeholder
 */
export const isEventPlaceholder = (
  item: CalendarItem
): item is EventPlaceholder => {
  return 'customerId' in item
}

/**
 * Returns the correct entity name for the given item.
 * @param {LinkedEvent | LinkedConcierge} item The item to check
 * @param {boolean} generalizeDrafts Whether to generalize drafts and turn them into events
 * @returns {APIEntity} The entity name
 */
export const getCalendarItemResourceName = (
  item: LinkedEvent | LinkedConcierge,
  generalizeDrafts?: boolean
): Extract<APIEntity, 'concierge' | 'events' | 'eventsdraft'> => {
  return 'guid' in item
    ? 'concierge'
    : `events${
        item.eventStatus === 'draft' && !generalizeDrafts ? 'draft' : ''
      }`
}
