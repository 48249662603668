import { getId, findMultipleFacets, findSingleFacet } from '../../../../utils'
import { optionedProductParse } from '../common'
import { parseFacet } from '../facets'
import { parseFoodUpselling } from '../food-upselling'
import { logisticParse } from '../logistics'
import type { APIFood, Food } from './types'

export const foodParse = (res: APIFood): Food => {
  const logistics = (res.logistics ?? []).map(getId)
  const upsellingItemsFull = res.upselling.map(parseFoodUpselling) // these objects are full of all Food item informations. Used inside event edit and create
  const facets = res.facets.map(parseFacet)
  const logisticsFull = res.logistics.map(logisticParse)
  const tagFacets = findMultipleFacets(facets, 'tag')
  const filterTypeFacet = findSingleFacet(facets, 'productFilter', 'Type')

  return {
    ...res,
    ...optionedProductParse(res),
    upsellingItemsFull,
    logistics,
    logisticsFull,
    tagFacets,
    filterTypeFacet
  }
}
