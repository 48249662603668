import React from 'react'
import PhysicalFacetSection from 'components/Inputs/Facets/FacetFormSections/PhysicalFacetSection'
import ExperienceFormContent from 'components/Forms/ExperienceFormContent'
import PhysicalKeyInfoSection from 'resources/catalog/physicalexperiences/product/PhysicalKeyInfoSection'
import PhysicalTailoringSection from 'resources/catalog/physicalexperiences/product/PhysicalTailoringSection'

const PhysicalExperiencesFormContent: React.FC = () => (
  <>
    <ExperienceFormContent
      upsellingEntity="physicalupselling"
      facetFields={<PhysicalFacetSection />}
      slotBeforeHighlights={<PhysicalKeyInfoSection />}
    />
    <PhysicalTailoringSection />
  </>
)

export default PhysicalExperiencesFormContent
