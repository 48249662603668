import { YupSchema } from 'services/yup/types'
import { catalogItemBaseVS } from 'services/validations/catalog/catalogItemBase'
import { yupString } from 'services/yup/custom-schemas/string'
import { yupArray } from 'services/yup/custom-schemas/array'
import { ProductCommon } from '@kampaay/common'

export const productCommonVS: YupSchema<ProductCommon> =
  catalogItemBaseVS.shape({
    description: yupString().required(),
    facetCodes: yupArray(),
    facets: yupArray(),
    images: yupArray(),
    geoAreaPresets: yupArray().min(1)
  })
