import type { APIFacet, Facet } from './types'

export const parseFacet = (f: APIFacet): Facet => {
  const { code, ...rest } = f

  return {
    ...rest,
    code,
    ...JSON.parse(code)
  }
}
