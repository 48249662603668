import React from 'react'
import { Labeled, NumberFieldProps, NumberField } from 'react-admin'
import { WithDataTestId } from 'test/utils'
import { buildStyledComponent } from 'services/utils/style/buildStyledComponent'

type LabeledNumberFieldProps = WithDataTestId &
  Omit<NumberFieldProps, 'label'> & {
    label: string
    roundTwoDecimals?: boolean
    filled?: boolean
  }

export const ROUND_TWO_DECIMALS = {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2
}

const [StyledDiv, classes] = buildStyledComponent(
  'labeled-text-field',
  'div',
  undefined,
  (theme) => ({
    filled: {
      backgroundColor: theme.palette.primary.greyLighten1,
      border: `2px solid ${theme.palette.primary.greyDarken1}`,
      margin: '8px 0 0 0',
      padding: '2px 1rem',
      width: '100%',
      borderRadius: '4px',
      userSelect: 'none',
      boxSizing: 'border-box'
    },
    marginBottom: {
      marginBottom: '16px'
    }
  })
)

/**
 * NumberField with a label above
 * @param label - label of the field
 * @param source - source of the field
 * @param record - record of the field
 * @param roundTwoDecimals - if true rounds to two decimals
 * @param filled - if the field should have a filled style
 * @param style
 * @param dataTestId
 */
const LabeledNumberField: React.FC<LabeledNumberFieldProps> = ({
  label,
  source,
  record,
  roundTwoDecimals,
  style,
  sx,
  filled,
  'data-testid': dataTestId = 'labeled-number-field'
}) => {
  return (
    <StyledDiv>
      <Labeled
        className={[filled ? classes.filled : '', classes.marginBottom].join(
          ' '
        )}
        label={label}
        sx={{ marginBottom: '16px', ...sx }}
      >
        <NumberField
          source={source}
          record={record}
          options={roundTwoDecimals ? ROUND_TWO_DECIMALS : {}}
          style={style}
          data-testid={dataTestId}
        />
      </Labeled>
    </StyledDiv>
  )
}

export default LabeledNumberField
