import { LocationFeature } from 'services/api/entities/catalog/locationFeatures/types/internal/locationFeatures-models'
import { CSVEntityDescriptor } from 'services/csv/types'
import {
  CatalogItemBaseCSV,
  formatCatalogItemBase
} from 'services/csv/entities/catalog/common/catalogItemBase'

type LocationfeaturesCSV = CatalogItemBaseCSV & {
  Category: string
}

const formatLocationFeature = ({
  category,
  ...rest
}: LocationFeature): LocationfeaturesCSV => ({
  ...formatCatalogItemBase(rest),
  Category: category
})

export const locationfeatures: CSVEntityDescriptor<LocationFeature> = {
  format: formatLocationFeature
}
