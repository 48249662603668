import { eventSizeChoices } from '@kampaay/common'
import EventGeoareaPresetInput from 'components/FormComponents/Event/EventGeoareaPresetInput'
import { EventColorSelectInput } from 'components/FormComponents/EventColorSelectInput'
import { KBooleanInput } from 'components/Inputs/KInputs/KBooleanInput'
import KDateTimeInput from 'components/Inputs/KInputs/KDateTimeInput'
import KNumberInput from 'components/Inputs/KInputs/KNumberInput/KNumberInput'
import KSelectInput from 'components/Inputs/KInputs/KSelectInput'
import KTextInput from 'components/Inputs/KInputs/KTextInput/KTextInput'
import KAccordion from 'components/UI/Accordions/KAccordion/KAccordion'
import KToolTip from 'components/UI/KTooltip'
import FlexBox from 'components/UI/layout/FlexBox'
import { useLoggedUser } from 'contexts/loggedUserContext/useLoggedUser'
import { useEventApprovalStatus } from 'hooks/useEventApprovalStatus'
import { useForm } from 'hooks/useForm'
import { useGeoareaPreset } from 'hooks/useGeoAreaPreset'
import { useKRecordContext } from 'hooks/useKRecordContext'
import { useKResourceContext } from 'hooks/useKResourceContext'
import React, { PropsWithChildren, useEffect } from 'react'
import { BooleanField, Labeled } from 'react-admin'
import EEAssigneeSelectInput from 'resources/operations/events/EEAssigneeSelectInput'
import EditCustomerField from 'resources/operations/events/EventsComponents/EditCustomerField'
import EventBudgetInput from 'resources/operations/events/common/EventBudgetInput'
import PaymentMethodGeneralInput from 'resources/operations/events/eventsFormTabs/PaymentMethodGeneralInput'
import { MappedEnrichedEvent } from 'services/api/entities/events/types/internal/event-models'
import { eventStatusChoices } from 'services/cfg/form'
import { RAFormFC } from 'types/common'

const EventGeneralInfoAccordion: RAFormFC<
  PropsWithChildren<{ editMode: boolean }>
> = ({ children, source, editMode }) => {
  const record = useKRecordContext<MappedEnrichedEvent>()
  const { useGetValue, useSetValue, getSource } =
    useForm<MappedEnrichedEvent>(source)
  const setBudgetId = useSetValue('budgetId')

  const eventApprovalStatus = useEventApprovalStatus()

  const resource = useKResourceContext()
  const eventStatusDefault =
    resource === 'events' ? eventStatusChoices[0].id : eventStatusChoices[3].id
  const geoareaPresetId = useGetValue('geoAreaPreset')

  const { isGeoAreaPresetPublic } = useGeoareaPreset(geoareaPresetId!)
  const { loggedUserRole } = useLoggedUser()

  const paymentMethod = useGetValue('paymentMethod')
  const budgetId = useGetValue('budgetId')

  useEffect(() => {
    if (paymentMethod !== 'organizationBudget' && budgetId !== undefined) {
      setBudgetId(undefined)
    }
  }, [paymentMethod])

  return (
    <KAccordion accordionTitle="Info" defaultExpanded={!editMode}>
      <FlexBox flexDirection="column">
        <KBooleanInput source={getSource('allowHubSpotIntegration')} />
        {children}
        <EEAssigneeSelectInput
          fullWidth
          source={getSource('kampaayerCoordinators')}
        />

        <KSelectInput
          disabled={loggedUserRole !== 'admin'}
          source={getSource('size')}
          label="form.fields.size"
          choices={eventSizeChoices}
        />
        <EventColorSelectInput source={getSource('color')} />

        <KToolTip
          title={eventApprovalStatus?.tooltipLabel ?? ''}
          disabled={!eventApprovalStatus?.showTooltip}
          placement="top-start"
        >
          <KSelectInput
            source={getSource('eventStatus')}
            label="form.fields.eventStatus"
            choices={eventStatusChoices}
            defaultValue={eventStatusDefault}
          />
        </KToolTip>

        {record?.confirmedInSf !== undefined && (
          <Labeled label="form.fields.confirmedInSf">
            <BooleanField source={getSource('confirmedInSf')} />
          </Labeled>
        )}

        <EditCustomerField customerEditable={isGeoAreaPresetPublic} />

        <PaymentMethodGeneralInput />

        {paymentMethod === 'organizationBudget' && (
          <EventBudgetInput customerId={record?.customer} />
        )}

        <KDateTimeInput
          source={getSource('startDate')}
          label="form.fields.startDate"
        />
        <KDateTimeInput
          source={getSource('endDate')}
          label="form.fields.endDate"
        />

        <KNumberInput
          source={getSource('guestsNumber')}
          label="form.fields.guests"
        />

        <EventGeoareaPresetInput />

        <KTextInput
          source={getSource('fullAddress')}
          label="form.fields.address"
        />
      </FlexBox>
    </KAccordion>
  )
}
export default EventGeneralInfoAccordion
