import React from 'react'
import LocalizedSelectArrayInput from 'components/Inputs/Localized/LocalizedSelectArrayInput'
import { useKGetList } from 'hooks/useKGetList'

const LogisticSelectInput: React.FC = () => {
  const choices = useKGetList('logistics').list

  return (
    <LocalizedSelectArrayInput
      source="logistics"
      label="Catering Logistics"
      choices={choices}
      // Quickfix: defaultValue was breaking the behaviour of the component, we should study it and re-add it later
      // defaultValue={[choices[0].id]}
      fullWidth
    />
  )
}

export default LogisticSelectInput
