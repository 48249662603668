import { APIParseFunction } from 'services/api/api'
import { APIExportData } from 'services/api/entities/accounting/transactionExportForPurchaseOrder/types/api/response-models'
import { ExportData } from 'services/api/entities/accounting/transactionExportForPurchaseOrder/types/internal'

export const transactionExportForPurchaseOrderParse: APIParseFunction = (
  apiExportData: APIExportData
): ExportData => ({
  eventExportData: apiExportData.eventExportData.map((eventExportDatum) => ({
    ...eventExportDatum,
    lastExportDate: eventExportDatum.lastExportDate
      ? new Date(eventExportDatum.lastExportDate)
      : undefined
  }))
})
