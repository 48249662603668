import React from 'react'
import CustomReferenceInput from 'components/Inputs/CustomReferenceInput'
import { APIEntity } from 'services/api/entities'
import KAutocompleteArrayInput, {
  KAutocompleteArrayInputProps
} from 'components/Inputs/KInputs/KAutocompleteArrayInput'

export type KAutocompleteReferenceArrayInputProps =
  KAutocompleteArrayInputProps & {
    relatedEntity: APIEntity
  }

const KAutocompleteReferenceArrayInput: React.FC<
  KAutocompleteReferenceArrayInputProps
> = ({ relatedEntity, ...props }) => {
  //=========================
  // JSX
  //=========================
  return (
    <CustomReferenceInput entity={relatedEntity}>
      <KAutocompleteArrayInput {...props} />
    </CustomReferenceInput>
  )
}

export default KAutocompleteReferenceArrayInput
