import { Toolbar, ToolbarProps } from 'ra-ui-materialui'
import React from 'react'
import KSaveButton from 'components/Buttons/KSaveButton'

const NoDeleteButtonToolbar: React.FC<ToolbarProps> = (props) => {
  return (
    <Toolbar {...props}>
      <KSaveButton />
    </Toolbar>
  )
}

export default NoDeleteButtonToolbar
