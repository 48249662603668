import { useKResourceContext } from 'hooks/useKResourceContext'
import { useGetResourceLabel, useTranslate } from 'ra-core'
import Inbox from '@mui/icons-material/Inbox'
import { Typography } from '@mui/material'
import { Button } from 'react-admin'
import { styled } from '@mui/material/styles'
import React from 'react'

type Props = {
  onCreateClick: () => void
}

/**
 * this is the same empty page as the one in ra-ui-materialui/ list/Empty.tsx
 * but that expose the create button event,
 * useful to make the creation suitable for the EditableList
 */
const EmptyEditableListPage: React.FC<Props> = ({ onCreateClick }) => {
  const resource = useKResourceContext()
  const translate = useTranslate()

  const getResourceLabel = useGetResourceLabel()
  const resourceName = translate(`resources.${resource}.forcedCaseName`, {
    smart_count: 0,
    _: getResourceLabel(resource, 0)
  })

  const emptyMessage = translate('ra.page.empty', { name: resourceName })
  const inviteMessage = translate('ra.page.invite')

  return (
    <Root>
      <div className={EmptyClasses.message}>
        <Inbox className={EmptyClasses.icon} />
        <Typography variant="h4" paragraph>
          {translate(`resources.${resource}.empty`, {
            _: emptyMessage
          })}
        </Typography>
        <Typography variant="body1">
          {translate(`resources.${resource}.invite`, {
            _: inviteMessage
          })}
        </Typography>
      </div>
      <div className={EmptyClasses.toolbar}>
        <Button
          variant="contained"
          onClick={onCreateClick}
          label="button.label.create"
        />
      </div>
    </Root>
  )
}

const PREFIX = 'RaEmpty'

export const EmptyClasses = {
  message: `${PREFIX}-message`,
  icon: `${PREFIX}-icon`,
  toolbar: `${PREFIX}-toolbar`
}

const Root = styled('span', {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root
})(({ theme }) => ({
  flex: 1,
  [`& .${EmptyClasses.message}`]: {
    textAlign: 'center',
    opacity: theme.palette.mode === 'light' ? 0.5 : 0.8,
    margin: '0 1em',
    color:
      theme.palette.mode === 'light' ? 'inherit' : theme.palette.text.primary
  },

  [`& .${EmptyClasses.icon}`]: {
    width: '9em',
    height: '9em'
  },

  [`& .${EmptyClasses.toolbar}`]: {
    textAlign: 'center',
    marginTop: '2em'
  }
}))

export default EmptyEditableListPage
