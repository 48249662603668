import React, { useCallback, useMemo } from 'react'
import { Menu, useTranslate } from 'react-admin'
import { useNavigate } from 'react-router-dom'
import { useFormContext } from 'react-hook-form'
import { useSaveContext } from 'ra-core'

const boldWhenActive = {
  '&.RaMenuItemLink-active': {
    fontWeight: 'bold'
  }
}

export type OrganizationMenuItemProps = {
  link: string
  text: string
  defaultActive?: boolean
}

const OrganizationMenuItem: React.FC<OrganizationMenuItemProps> = ({
  link,
  text,
  defaultActive = false
}) => {
  const translate = useTranslate()
  const navigate = useNavigate()
  const formContext = useFormContext()
  const saveContext = useSaveContext()

  const handleClick = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault()

      if (
        !!saveContext &&
        !!formContext &&
        // We are making sure the form is dirty by checking if there are dirty fields
        // as isDirty returns true even if there are no fields touched for some reason
        Object.keys(formContext.formState.dirtyFields).length
      ) {
        saveContext.save!(formContext.getValues(), {
          onSuccess: () => {
            navigate(link)
          }
        })
      } else {
        navigate(link)
      }
    },
    [saveContext, formContext]
  )

  const isSaving = useMemo(
    () => !!saveContext && saveContext.saving,
    [saveContext]
  )

  return (
    <Menu.Item
      to={link}
      primaryText={translate(text)}
      sx={defaultActive ? undefined : boldWhenActive}
      onClick={handleClick}
      disabled={isSaving}
    />
  )
}

export default OrganizationMenuItem
