import React from 'react'
import { ChipField, Labeled, TextField, TextFieldProps } from 'react-admin'
import { buildStyledComponent } from 'services/utils/style/buildStyledComponent'
import { WithDataTestId } from 'test/utils'

type Props = Pick<
  TextFieldProps,
  'label' | 'source' | 'record' | 'sortable' | 'emptyText'
> &
  WithDataTestId & {
    label?: string
    filled?: boolean
    chip?: boolean
  }

const [StyledDiv, classes] = buildStyledComponent(
  'labeled-text-field',
  'div',
  undefined,
  (theme) => ({
    filled: {
      backgroundColor: theme.palette.primary.greyLighten1,
      border: `2px solid ${theme.palette.primary.greyDarken1}`,
      margin: '8px 0 0 0',
      padding: '2px 1rem',
      width: '100%',
      borderRadius: '4px',
      userSelect: 'none'
    },
    marginBottom: {
      marginBottom: '16px'
    }
  })
)

/**
 * TextField with a label above
 * @param label - label of the field
 * @param source - source of the field
 * @param record - record of the field
 * @param filled - if the field should have a filled style
 * @param chip - use the ChipField instead of the TextField if true
 * @param dataTestId
 */
const LabeledTextField: React.FC<Props> = ({
  label,
  source,
  record,
  filled,
  chip = false,
  'data-testid': dataTestId,
  emptyText
}) => {
  const Component = chip ? ChipField : TextField

  return (
    <StyledDiv>
      <Labeled
        className={[filled ? classes.filled : '', classes.marginBottom].join(
          ' '
        )}
        label={label}
      >
        <Component
          source={source}
          record={record}
          data-testid={dataTestId}
          emptyText={emptyText}
        />
      </Labeled>
    </StyledDiv>
  )
}

export default LabeledTextField
