import { useFormContext } from 'react-hook-form'
import { parseKNumberInputValue } from 'components/Inputs/KInputs/KNumberInput/utils'
import { useEffect } from 'react'
import {
  KNumberInputProps,
  NumberInputChangeEvent
} from 'components/Inputs/KInputs/KNumberInput/KNumberInput'
import { useKInput } from 'hooks/useKInput/useKInput'

export const useKNumberInput = (props: KNumberInputProps) => {
  const {
    source,
    min,
    step,
    defaultValue,
    parse = (v) => v,
    format = (v) => v
  } = props

  const { setValue, watch } = useFormContext()
  const value = watch(props.source)

  const {
    field: { onChange: inputOnChange, value: _fieldVal, ...fieldRest },
    ...useKInputRest
  } = useKInput(props)

  /**
   * This onChange is executed before the native onChange f the input.
   * We use it to keep the local state where the input reads its value
   */
  const onChange: NumberInputChangeEvent = ({ target: { value } }) => {
    // We should call first onChange of RA hook to make the form dirty
    inputOnChange(value)
    const val = parseKNumberInputValue(value, step)
    const valWithMin =
      val !== undefined && min !== undefined && val < min ? min : val
    const parsedValue = parse(valWithMin)
    setValue(source, parsedValue)
  }

  /**
   * This is to delete the empty string value from the form
   * when the input defaults to empty string replacing it with the correct
   * default value
   */
  useEffect(() => {
    if (value === '') {
      onChange({ target: { value: defaultValue } } as any)
    }
  }, [value])

  // this is done because value results null and not undefined
  // so to avoid a long error in console we use undefined
  const inputValue = format(value === undefined ? '' : value)

  return {
    field: {
      ...fieldRest,
      value: inputValue,
      onChange
    },
    ...useKInputRest
  }
}
