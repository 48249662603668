import React from 'react'
import MainProductList, {
  MainProductListProps
} from 'components/Lists/MainProductList'
import { TextField } from 'react-admin'

const PhysicalExperienceList: React.FC<MainProductListProps> = (props) => (
  <MainProductList {...props}>
    <TextField
      label="form.fields.address"
      source="geoCodedAddress.geoPoint.formattedAddress"
    />
  </MainProductList>
)

export default PhysicalExperienceList
