import React from 'react'
import { translate } from 'services/i18n'
import { EventBriefQuestion } from 'services/api/entities/events/types/internal/services'
import {
  parseMultilangOrFallbackEng,
  parseMultilangToCurrentLang
} from '@kampaay/common'

type BriefSectionProps = {
  briefQuestions: EventBriefQuestion[]
}

const BriefSection: React.FC<BriefSectionProps> = ({ briefQuestions }) => {
  return (
    <>
      <p>{translate('form.fields.brief')}: </p>
      <ul style={{ paddingLeft: '60px' }}>
        {briefQuestions.map(
          (briefQuestion, idx) =>
            !!parseMultilangToCurrentLang(briefQuestion.response).length && (
              <li key={`${briefQuestion.id}-${briefQuestion.status}-${idx}`}>
                <p data-testid="brief-section--question" className="font-bold">
                  {parseMultilangOrFallbackEng(briefQuestion.text)}
                </p>
                <p data-testid="brief-section--response" className="italic">
                  {parseMultilangOrFallbackEng(briefQuestion.response)}
                </p>
              </li>
            )
        )}
      </ul>
    </>
  )
}

export default BriefSection
