import { Typography } from '@mui/material'
import { useKTranslate } from 'hooks/useKTranslate'
import React from 'react'

export const CantHideServiceAlert = () => {
  return (
    <Typography variant="caption">
      {useKTranslate('resources.customers.hiddenServices.cantBeHidden')}
    </Typography>
  )
}

export default CantHideServiceAlert
