import React from 'react'
import { styled } from '@mui/material/styles'
import { Cancel, Check } from '@mui/icons-material'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material'
import { Button } from 'react-admin'
import { useKTranslate } from 'hooks/useKTranslate'
import { WithDataTestId } from 'test/utils'

const PREFIX = 'RaConfirm'

const classes = {
  content: `${PREFIX}-content`,
  titleStyle: `${PREFIX}-titleStyle`,
  buttonsContainer: `${PREFIX}-buttonsContainer`,
  buttonStyle: `${PREFIX}-buttonStyle`
}

const StyledDialog = styled(Dialog)(() => ({
  [`& .${classes.titleStyle}`]: {
    fontWeight: 'bold',
    alignSelf: 'center',
    fontSize: '30px'
  },
  [`& .${classes.content}`]: {
    minWidth: 400,
    alignSelf: 'center'
  },
  [`& .${classes.buttonsContainer}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px'
  },
  [`& .${classes.buttonStyle}`]: {
    paddingTop: '10px',
    paddingBottom: '10px',
    margin: '0 !important'
  }
}))

type OnClickFunction = (event?: React.MouseEvent<HTMLButtonElement>) => void

export type SimpleModalProps = WithDataTestId & {
  title: string
  description?: string
  secondCTALabel?: string
  firstCTALabel?: string
  isOpen: boolean
  loading?: boolean
  onFirstCTAClick: OnClickFunction
  onSecondCTAClick: OnClickFunction
  children?: React.ReactNode
  firstCTADisabled?: boolean
  secondCTADisabled?: boolean
}

/**
 * Simple modal component exposing 2 CTAs by default:
 * - Confirm: calls onConfirm
 * - Cancel: calls onClose
 */
const SimpleModal: React.FC<SimpleModalProps> = ({
  title,
  description = '',
  firstCTALabel = 'Cancel',
  secondCTALabel = 'Confirm',
  isOpen,
  loading,
  onFirstCTAClick,
  onSecondCTAClick,
  firstCTADisabled,
  secondCTADisabled,
  children,
  'data-testid': dataTestId
}) => {
  // Translation of labels
  const translatedTitle = useKTranslate(title)
  const translatedFirstCTALabel = useKTranslate(firstCTALabel)
  const translatedSecondCTALabel = useKTranslate(secondCTALabel)
  const translatedDescription = useKTranslate(description)

  const handleFirstCTAClick = (event?: React.MouseEvent<HTMLButtonElement>) => {
    event?.stopPropagation()
    onFirstCTAClick(event)
  }

  const handleSecondCTAClick = (
    event?: React.MouseEvent<HTMLButtonElement>
  ) => {
    event?.stopPropagation()
    onSecondCTAClick(event)
  }

  return (
    <StyledDialog open={isOpen} data-testid={dataTestId}>
      <DialogTitle id="alert-dialog-title" className={classes.titleStyle}>
        {translatedTitle}
      </DialogTitle>

      <DialogContent className={classes.content}>
        <p>{translatedDescription}</p>
        {children}
      </DialogContent>

      <DialogActions className={classes.buttonsContainer}>
        {/* First CTA */}
        <Button
          className={classes.buttonStyle}
          disabled={loading || firstCTADisabled}
          onClick={handleFirstCTAClick}
          fullWidth
          variant="outlined"
          startIcon={<Cancel />}
          label={translatedFirstCTALabel}
          data-testid={`${dataTestId}--firstCTA`}
        />

        {/* Second CTA */}
        <Button
          className={classes.buttonStyle}
          disabled={loading || secondCTADisabled}
          onClick={handleSecondCTAClick}
          autoFocus
          variant="contained"
          fullWidth
          startIcon={<Check />}
          label={translatedSecondCTALabel}
          data-testid={`${dataTestId}--secondCTA`}
        />
      </DialogActions>
    </StyledDialog>
  )
}

export default SimpleModal
