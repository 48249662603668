import React from 'react'
import KSelectInput, {
  KSelectInputProps
} from 'components/Inputs/KInputs/KSelectInput'
import CustomReferenceInput, {
  FilterFn
} from 'components/Inputs/CustomReferenceInput'
import { Supplier } from '@kampaay/common'
import { hasAccountQueryFilter } from 'services/utils/concierge'

type Props = KSelectInputProps & {
  label?: string
  filterFns?: FilterFn<Supplier>[]
  queryFilter?: Record<string, string>
  fetchWithAccountOnly?: boolean
}

const SupplierSelectInput: React.FC<Props> = ({
  label = 'form.fields.supplier',
  filterFns = [],
  queryFilter = {},
  fetchWithAccountOnly = true,
  ...props
}) => {
  const finalQueryFilter = {
    ...queryFilter,
    ...(fetchWithAccountOnly ? hasAccountQueryFilter : {})
  }

  return (
    <CustomReferenceInput
      entity="suppliers"
      filterFns={filterFns}
      queryFilter={finalQueryFilter}
    >
      <KSelectInput {...props} label={label} />
    </CustomReferenceInput>
  )
}

export default SupplierSelectInput
