import React from 'react'
import { KInputProps, useKInput } from 'hooks/useKInput/useKInput'
import {
  AutocompleteArrayInput,
  AutocompleteArrayInputProps
} from 'react-admin'
import LocalizedWithChoicesInput from 'components/Inputs/Localized/LocalizedWithChoicesInput'

export type KAutocompleteArrayInputProps = KInputProps &
  AutocompleteArrayInputProps

const KAutocompleteArrayInput: React.FC<KAutocompleteArrayInputProps> = ({
  label,
  ...props
}) => {
  const { isRequired } = useKInput(props)

  return (
    <LocalizedWithChoicesInput {...props} label={label}>
      <AutocompleteArrayInput isRequired={isRequired} />
    </LocalizedWithChoicesInput>
  )
}

export default KAutocompleteArrayInput
