import type { MultipleFileUploader } from '../../../types'
import { upsellingWrite } from '../common'
import type { FoodUpselling, FoodUpsellingToAPI } from './types'

export const foodUpsellingWrite =
  (imagesUploader: MultipleFileUploader) =>
  async ({
    logistics,
    ...rest
  }: FoodUpselling): Promise<FoodUpsellingToAPI> => {
    return {
      ...(await upsellingWrite(imagesUploader)(rest)),
      logistics
    }
  }
