import {
  UseValidationSchema,
  yupArray,
  yupIgnore,
  yupNumber,
  yupObject,
  yupString
} from 'services/yup'
import { OrganizationSubGroup } from '@kampaay/common'

const useOrganizationSubGroupVS: UseValidationSchema<
  OrganizationSubGroup
> = () =>
  yupObject().shape({
    id: yupIgnore(),
    organizationId: yupIgnore(),
    name: yupString().required(),
    membershipsIds: yupArray(yupNumber())
  })

export default useOrganizationSubGroupVS
