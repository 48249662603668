import { SimpleFormIterator } from 'react-admin'
import React from 'react'
import PricingRuleIfClauseInputGroup from 'components/FormComponents/pricing/byRules/PricingRuleIfClauseInputGroup'
import PricingRuleThenClauseInputGroup from 'components/FormComponents/pricing/byRules/PricingRuleThenClauseInputGroup'
import PricingRulePriceInputGroup from 'components/FormComponents/pricing/byRules/PricingRulePriceInputGroup'
import { useForm } from 'hooks/useForm'
import { RAFormFC } from 'types/common'
import KArrayInput from 'components/Inputs/KInputs/KArrayInput'
import { LocationPricingRuleSet } from '@kampaay/common'

const PricingRulesArrayInput: RAFormFC = ({ source }) => {
  const { getSource, useGetValue, useSetValue } =
    useForm<LocationPricingRuleSet>(source)

  const rules = useGetValue('pricingRules')
  const setRules = useSetValue('pricingRules')

  /**
   * TODO check the status of this issue for fix
   * @see https://github.com/marmelab/react-admin/issues/8475
   */
  if ((rules as any) === '') {
    setRules([])
  }

  const firstNHRIndex = rules?.findIndex((r) => r?.mode === 'nhr')

  return (
    <KArrayInput source={getSource('pricingRules')} label="" defaultValue={[]}>
      <SimpleFormIterator>
        <PricingRuleIfClauseInputGroup firstNHRIndex={firstNHRIndex} />
        <PricingRuleThenClauseInputGroup />

        <PricingRulePriceInputGroup />
      </SimpleFormIterator>
    </KArrayInput>
  )
}

export default PricingRulesArrayInput
