import { bundleFacetIds } from '../../../../../utils'
import type { MultipleFileUploader } from '../../../../types'
import { writeQuantityHint } from '../hints'
import { productCommonWrite } from '../products'
import type { Upselling, UpsellingToAPI } from './types'

export const upsellingWrite =
  (imagesUploader: MultipleFileUploader) =>
  async ({
    pricingFacet,
    groupFacet,
    isPerItem,
    ...req
  }: Upselling): Promise<UpsellingToAPI> => {
    const facets = bundleFacetIds(groupFacet, pricingFacet)

    return {
      ...req,
      ...(await productCommonWrite(imagesUploader)(req)),
      facets,
      products: req.products ?? [],
      quantityHint: isPerItem ? writeQuantityHint(req) : []
    }
  }
