import { UseValidationSchema, yupArray, yupString } from 'services/yup'
import {
  DrinkSection,
  DrinkSpecialType
} from 'services/api/entities/catalog/sections/drinkSections'
import { useProductSectionVS } from 'services/validations/catalog/sections/productSection'

export const useDrinkSectionVS: UseValidationSchema<DrinkSection> = () =>
  useProductSectionVS().shape({
    specialType: yupString<DrinkSpecialType>().required(),
    productsFull: yupArray()
  })
