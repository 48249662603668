import HowItWorksAccordion from 'components/UI/Accordions/HowItWorksAccordion'
import ContentToggler from 'components/UI/ContentToggler'
import React from 'react'
import { useWatch } from 'react-hook-form'
import DefaultQuantityHint from 'components/FormComponents/Hints/QuantityHint/DefaultQuantityHint'
import QuantityHintArrayInput from 'components/FormComponents/Hints/QuantityHint/QuantityHintArrayInput'
import QuantityHintGuestMockField from 'components/FormComponents/Hints/QuantityHint/QuantityHintGuestMockField'
import KAccordion from 'components/UI/Accordions/KAccordion/KAccordion'
import { WithQuantityHint } from '@kampaay/common'

type Props = {
  accordionText: string[]
}

const QuantityHintFormContent: React.FC<Props> = ({
  accordionText,
  ...props
}) => {
  const errorsDependencies = ['defaultQuantityHint']

  const guestsNumberMock = useWatch<WithQuantityHint, 'guestsNumberMock'>({
    name: 'guestsNumberMock'
  })
  const quantityHint = useWatch<WithQuantityHint, 'quantityHint'>({
    name: 'quantityHint'
  })

  return (
    <KAccordion
      accordionTitle="Quantity"
      errorsDependencies={errorsDependencies}
      defaultExpanded={false}
    >
      <ContentToggler
        {...props}
        source="showQuantityHint"
        label="form.fields.showQuantityHint"
      >
        <QuantityHintArrayInput
          guestsNumberMock={guestsNumberMock}
          quantityHint={quantityHint}
        />
        <DefaultQuantityHint
          guestsNumberMock={guestsNumberMock}
          quantityHint={quantityHint}
        />
        <QuantityHintGuestMockField />
      </ContentToggler>
      <HowItWorksAccordion accordionText={accordionText} />
    </KAccordion>
  )
}

export default QuantityHintFormContent
