import { TestConfig, TestFunction } from 'yup'
import { Location } from 'services/api/entities/catalog/location/types/internal/location-models'
import { locationPricingNameChoices } from 'services/cfg/form'
import { KTestConfig, KTestFunction } from 'services/yup'
import {
  filterNHRRules,
  hasRuleSetValidCoverage,
  isDefaultNHR
} from 'services/validations/catalog/location/utils'
import { LocationRule, LocationPricingRuleSet } from '@kampaay/common'

/**
 * !Validations declared all here!
 * @see https://gitlab.com/kampaay/general/-/issues/936#note_1016582293
 */

///////////////
// FUNCTIONS //
///////////////
/**
 * Checks if at least one rule inside a rule group has a full day name
 */
export const validateHasFullDay: TestFunction<LocationRule[]> = (
  pricingRules
) => pricingRules?.some((r) => r.name === locationPricingNameChoices[2]?.id)

/**
 * Checks if all the rules are not NHR
 * @param rules
 */
const validateNotAllExtraRules: KTestFunction<LocationRule[]> = (rules) =>
  !rules?.every((r) => r?.isExtra)

/**
 * Checks if having at least NHR in the rule group there is at leas one default NHR
 * @param rules
 */
const hasDefaultNHR: KTestFunction<LocationRule[]> = (rules) => {
  const nhrs = filterNHRRules(rules)
  return !nhrs.length || nhrs.some(isDefaultNHR)
}

/**
 * Checks if every not default NHR is greater than minHours
 * @param ruleSet
 */
const validateAtLeastGreaterThanMinHours: KTestFunction<
  LocationPricingRuleSet[]
> = (ruleSet) =>
  !!ruleSet?.every((p) => {
    const nhrs = filterNHRRules(p.pricingRules)

    const nonDefaultNhrs = nhrs.filter((r) => !isDefaultNHR(r))

    return nonDefaultNhrs.every((nhr) => nhr.atLeast >= p.minHours)
  })

/**
 * Validate if all the rules are TPR if the coverage of the rules contains the
 * whole available time period of the location
 * N.B. This should be done on the array of sets because here we have the context
 * Of availableFrom and availableTo
 * @param rules
 * @param parent
 */
const validateTPRCoverage: KTestFunction<LocationPricingRuleSet[], Location> = (
  ruleSets,
  { parent: { availableFrom, availableTo } }
) => {
  return !!ruleSets?.every((ruleSet) =>
    hasRuleSetValidCoverage(ruleSet, availableFrom, availableTo)
  )
}

////////////////////
// CONFIGURATIONS //
////////////////////
export const isAtLeastOneRuleFullDayCFG: TestConfig<LocationRule[]> = {
  test: validateHasFullDay,
  message: 'form.validations.atLeastOneRuleWithFullDayName'
}
export const hasDefaultNHRCFG: TestConfig<LocationRule[]> = {
  test: hasDefaultNHR,
  message: 'form.validations.minimumNHRRequired'
}
export const isAllExtraRuleCFG: KTestConfig<LocationRule[]> = {
  test: validateNotAllExtraRules,
  message: 'form.validations.notAllExtraRules'
}
export const isAtLeastOneNHRGreaterThanMinHoursCFG: KTestConfig<
  LocationPricingRuleSet[]
> = {
  test: validateAtLeastGreaterThanMinHours,
  message: 'form.validations.atLeastOneNHRGreaterThanMinHours'
}
export const areAllTPRCoveringTheAvailableTimePeriodCFG: KTestConfig<
  LocationPricingRuleSet[],
  Location
> = {
  test: validateTPRCoverage,
  message: 'form.validations.areAllTPRCoveringTheAvailableTimePeriod'
}
