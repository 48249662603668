import { PaymentConfig, PaymentConfigurationBase } from 'types/common'
const PAYMENT_CFG_KEYS: (keyof PaymentConfigurationBase)[] = [
  'name',
  'title',
  'description'
]

export const isPaymentConfigValid = (
  paymentConfig?: PaymentConfigurationBase
) => {
  return paymentConfig
    ? PAYMENT_CFG_KEYS.some((e) => !!paymentConfig[e])
    : false
}

export const writePaymentConfig = <
  T extends PaymentConfigurationBase & { isDisabled?: boolean }
>(
  paymentConfig?: T
) => {
  if (
    !!paymentConfig?.isDisabled === false &&
    !isPaymentConfigValid(paymentConfig)
  )
    return undefined
  return paymentConfig
}

export const writePaymentConfiguration = (
  paymentConfiguration?: PaymentConfig
) => {
  const bankTransfer = writePaymentConfig(paymentConfiguration?.bankTransfer)
  const card = writePaymentConfig(paymentConfiguration?.card)
  const organizationBudget = writePaymentConfig(
    paymentConfiguration?.organizationBudget
  )

  return { bankTransfer, card, organizationBudget }
}
