import { LocationCity } from '@mui/icons-material'
import LocationFeatureList from 'resources/catalog/location/features/LocationFeatureList'
import LocationFeatureEdit from 'resources/catalog/location/features/LocationFeatureEdit'
import LocationFeatureCreate from 'resources/catalog/location/features/LocationFeatureCreate'

const locationIndex = {
  icon: LocationCity,
  list: LocationFeatureList,
  edit: LocationFeatureEdit,
  create: LocationFeatureCreate
}

export default locationIndex
