import { dateToISODate } from '../../../utils'
import type { FileUploader, MultipleFileUploader } from '../../types'
import { writeGeocodedAddress } from '../geo-coded-addresses'
import { proposalItemWrite } from '../proposal-item'
import type {
  ProposalItemGroup,
  ProposalItemGroupToAPI
} from '../proposals-item-groups'
import type { Proposal, ProposalToAPI } from './types'

export const writeProposalItemGroup =
  (imageUploader: FileUploader) =>
  async (data: ProposalItemGroup): Promise<ProposalItemGroupToAPI> => {
    return {
      ...data,
      items: await Promise.all(data.items.map(proposalItemWrite(imageUploader)))
    }
  }

export const proposalWrite =
  (
    imageUploader: FileUploader,
    multipleImagesUploader: MultipleFileUploader,
    multipleDocsUploader: MultipleFileUploader
  ) =>
  async (data: Proposal): Promise<ProposalToAPI> => {
    const documents = await multipleDocsUploader(data.documents)
    const itemGroups = await Promise.all(
      data.itemGroups?.map(writeProposalItemGroup(imageUploader)) ?? []
    )
    const {
      hasSupplierAssigned: _hasSupplierAssigned,
      dedicatedDiscount,
      contractDiscount,
      ...rest
    } = data

    return {
      ...rest,
      validUntil: dateToISODate(data.validUntil!),
      itemGroups,
      documents,
      geoCodedAddress: writeGeocodedAddress(data.geoCodedAddress),
      images: await multipleImagesUploader(data.images ?? []),
      dedicatedDiscount: dedicatedDiscount ? dedicatedDiscount : null,
      contractDiscount: contractDiscount ? contractDiscount : null
    }
  }
