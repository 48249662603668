import { UseValidationSchema, yupArray, yupNumber } from 'services/yup'
import { ProductSection } from 'services/api/entities/catalog/types'
import { catalogItemBaseVS } from 'services/validations/catalog/catalogItemBase'

export const useProductSectionVS: UseValidationSchema<
  ProductSection<any>
> = () =>
  catalogItemBaseVS.shape({
    products: yupArray(yupNumber()),
    productsFull: yupArray()
  })
