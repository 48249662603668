import React from 'react'
import { KInputProps, useKInput } from 'hooks/useKInput/useKInput'
import { ArrayInput, ArrayInputProps } from 'react-admin'

type KArrayInputProps = KInputProps & ArrayInputProps

const KArrayInput: React.FC<KArrayInputProps> = (props) => {
  const { children } = props
  const { isRequired } = useKInput(props)

  // isRequired actually does not work on the 4.5.1 version of RA, check it out when we are on a newer version of the project
  return (
    <ArrayInput {...props} isRequired={isRequired}>
      {children}
    </ArrayInput>
  )
}

export default KArrayInput
