import { useKGetList } from 'hooks/useKGetList'
import { MainProductAPIEntity } from 'services/api/entities'
import { arrayIncludesEvery } from 'services/utils'
import { Supplier } from '@kampaay/common'

type Payload = {
  selectedSupplierId: number | '' | undefined
  serviceName: MainProductAPIEntity
  selectionIds: (number | undefined)[]
}
//When having selected a supplier that is no longer able to provide us with all of the selected products, then i will see a red notification
export const useCheckIfSupplierProvideProduct = ({
  selectedSupplierId,
  serviceName,
  selectionIds
}: Payload) => {
  const supplierList = useKGetList<Supplier>('suppliers').list
  const supplier = supplierList.find(
    (supplier) => supplier.id === selectedSupplierId
  )
  const selectedServiceArray = supplier?.suppliedServicesProducts.length
    ? supplier.suppliedServicesProducts
        .filter(
          (ssp) =>
            ssp.service === serviceName &&
            arrayIncludesEvery(ssp.products, selectionIds)
        )
        .flatMap((ssp) => ssp.products)
    : []
  return arrayIncludesEvery(selectedServiceArray, selectionIds) ? true : false
}
