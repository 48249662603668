import React, { PropsWithChildren } from 'react'
import RendererTranslatedParagraph from 'resources/operations/events/EventsComponents/PdfRenderers/commonRenderers/TranslatedParagraph'
import FlexBox from 'components/UI/layout/FlexBox'

type GenericSectionProps = PropsWithChildren & {
  title?: string
  footerTextI18nKey?: string
  footerValue?: string | number | undefined
}

const GenericSection: React.FC<GenericSectionProps> = ({
  children,
  footerTextI18nKey,
  footerValue
}) => {
  return (
    <div data-testid="generic-section--container">
      {children}
      <div style={{ marginTop: '40px', marginBottom: '10px' }}>
        {footerTextI18nKey && (
          <FlexBox justifyContent="end">
            <RendererTranslatedParagraph
              data-testid="footer--additional-text"
              i18nKey={footerTextI18nKey}
              value={footerValue}
              keyClass="font-bold"
            />
          </FlexBox>
        )}
        <hr />
      </div>
    </div>
  )
}

export default GenericSection
