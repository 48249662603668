import { Autocomplete } from '@react-google-maps/api'
import React, { useCallback, useState } from 'react'
import KTextInput, {
  KTextInputProps
} from 'components/Inputs/KInputs/KTextInput/KTextInput'
import { LinearProgress, useTranslate } from 'react-admin'
import { GeoCodedAddress } from '@kampaay/common'
import { useForm } from 'hooks/useForm'
import { useKInput } from 'hooks/useKInput/useKInput'
import { useGoogleMapsLoader } from 'hooks/useGoogleMapsLoader'

type Props = KTextInputProps & {
  source: string
  label: string
  checkAddressType?: boolean
}

const AddressAutocompleteInput: React.FC<Props> = ({
  source,

  checkAddressType = false,
  ...props
}) => {
  const { useSetValue, getSource } = useForm<GeoCodedAddress>(source)
  const translate = useTranslate()

  const setGeoPoint = useSetValue('geoPoint')

  const { isLoaded, loadError } = useGoogleMapsLoader()

  const [autoComplete, setAutoComplete] =
    useState<google.maps.places.Autocomplete>()

  const {
    field: { onChange: onInputChange }
  } = useKInput({ source: getSource('userInsertedAddress') })

  const onLoad = (a: google.maps.places.Autocomplete) => {
    setAutoComplete(a)
  }

  const onPlaceChanged = () => {
    const place = autoComplete?.getPlace()
    if (!!place) {
      const [lat, lng] = [
        place.geometry?.location?.lat(),
        place.geometry?.location?.lng()
      ]

      if (lat && lng) {
        setGeoPoint({
          formattedAddress: place?.formatted_address!,
          location: { lat, lng },
          addressTypes: checkAddressType ? place?.types : undefined
        })
      }

      onInputChange(place.formatted_address)
    }
  }

  const clearValues = useCallback(() => {
    setGeoPoint(undefined)
  }, [setGeoPoint])

  if (!isLoaded) {
    return <LinearProgress />
  }

  if (loadError) {
    return (
      <div className="text-error">{translate('googleMaps.errorLabel')}</div>
    )
  }

  return (
    <div style={{ width: '100%' }}>
      <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
        <KTextInput
          onChange={(v) => !v?.trim() && clearValues()}
          resettable
          source={getSource('userInsertedAddress')}
          {...props}
        />
      </Autocomplete>
    </div>
  )
}

export default AddressAutocompleteInput
