import { Organization } from 'services/api/entities/crm/organizations/types/internal/organization-model'
import { CSVEntityDescriptor } from 'services/csv/types'

const formatOrganization = ({ name }: Organization) => ({
  'Organization Name': name
})

export const organization: CSVEntityDescriptor<Organization> = {
  format: formatOrganization
}
