import { APIPriceRange } from '@kampaay/common'

export type StandardPricingCSV = {
  'Max quantity': number
  'Fixed cost': number
  'Variable cost': number
  'Fixed price': number
  'Variable price': number
}

export const formatStandardPricing = (
  p: APIPriceRange
): StandardPricingCSV => ({
  ['Max quantity']: p.maxQuantity,
  ['Fixed cost']: p.fixedCost,
  ['Variable cost']: p.variableCost,
  ['Fixed price']: p.fixedPrice,
  ['Variable price']: p.variablePrice
})
