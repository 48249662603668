export const createMockBuilder =
  <T extends object>(buildBaseMock: () => T) =>
  (overrides: Partial<T> = {}): T => ({ ...buildBaseMock(), ...overrides })

type DatePeriod = 'yesterday' | 'today' | 'tomorrow'
export const buildSimpleDateObject = (
  period: DatePeriod = 'today',
  hours: number = 0
) => {
  const date = new Date()
  switch (period) {
    case 'yesterday':
      date.setDate(date.getDate() - 1)
      break
    case 'tomorrow':
      date.setDate(date.getDate() + 1)
      break
    default:
      break
  }

  const time = hours * 60 * 60 * 1000
  if (!!hours) return new Date(date.getTime() + time)

  return date
}

/**
 * Builds a multilang string from the given values
 * @param it - italian value
 * @param en - english value
 */
export const buildMultilangString = ({ it, en }: { it: string; en: string }) =>
  `{"it-IT":"${it}","en-US":"${en}"}`
