import { yupString } from 'services/yup/custom-schemas/string'
import {
  UseValidationSchema,
  yupBoolean,
  yupIgnore,
  yupObject,
  YupSchema
} from 'services/yup'
import { Customer, ActivityStatus, APIAccountingITA } from '@kampaay/common'
import { customerBaseVS } from 'services/validations/crm/customerBase'

export const accountingITAVS: YupSchema<APIAccountingITA> = yupObject().shape({
  vat: yupString().required(),
  pec: yupString().required(),
  sdi: yupString().required()
})

// TODO: currently suppliers inherit the customer validation schema, but
// the two models differ.  So it would be better to create a separate VS
// with the common fields and then extend it with the supplier specific fields.
export const useCustomerVS: UseValidationSchema<Customer> = () =>
  customerBaseVS.shape({
    email: yupString().required(),
    phone: yupString().required(),
    address: yupString(),
    promotionalActivitiesConsensus: yupBoolean().required(),
    privacyPolicyAccepted: yupBoolean().required(),
    // Terms acceptance is not in the form, but it's set when a new customer registers
    // using the signup flow in storefront
    termsAcceptance: yupBoolean(),
    dataProcessingConsensus: yupIgnore(),
    createdAt: yupIgnore(),
    lastLogin: yupIgnore(),
    emailVerified: yupIgnore(),
    phoneVerified: yupIgnore(),
    organizationNumber: yupString(),
    membershipId: yupIgnore(),
    status: yupString<ActivityStatus>().required()
  })

export const useCustomerITAVS = () => useCustomerVS().concat(accountingITAVS)
