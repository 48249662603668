import * as React from 'react'
import { styled } from '@mui/material/styles'
import { useState } from 'react'
import { Lock } from '@mui/icons-material'
import {
  Notification,
  useTranslate,
  useLogin,
  useNotify,
  LoginComponent,
  Button
} from 'react-admin'
import {
  DEFAULT_COUNTRY_ID,
  JwtData,
  getCountryById,
  setCountry
} from '@kampaay/common'
import { Avatar, Card, CardActions, CircularProgress } from '@mui/material'

const PREFIX = 'Login'

const classes = {
  main: `${PREFIX}-main`,
  card: `${PREFIX}-card`,
  avatar: `${PREFIX}-avatar`,
  icon: `${PREFIX}-icon`,
  hint: `${PREFIX}-hint`,
  form: `${PREFIX}-form`,
  input: `${PREFIX}-input`,
  actions: `${PREFIX}-actions`
}

const Root = styled('div')(({ theme }: any) => ({
  [`&.${classes.main}`]: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
    background: 'url(https://source.unsplash.com/random/1600x900)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },

  [`& .${classes.card}`]: {
    minWidth: 300,
    marginTop: '6em'
  },

  [`& .${classes.avatar}`]: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center'
  },

  [`& .${classes.icon}`]: {
    backgroundColor: theme.palette.secondary.main
  },

  [`& .${classes.hint}`]: {
    marginTop: '1em',
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.grey[500]
  },

  [`& .${classes.form}`]: {
    padding: '0 1em 1em 1em'
  },

  [`& .${classes.input}`]: {
    marginTop: '1em'
  },

  [`& .${classes.actions}`]: {
    padding: '0 1em 1em 1em'
  }
}))

const Login: LoginComponent = () => {
  const [loading, setLoading] = useState(false)
  const translate = useTranslate()

  const notify = useNotify()
  const login = useLogin()

  const handleSubmit = () => {
    setLoading(true)
    login(undefined, '/')
      .then((res: JwtData | undefined) => {
        // here read jwt and get the kampaayer's managed countries => for now we consider just the first one

        if (res) {
          const countryId = res.countries[0]
          setCountry(
            countryId ? countryId : getCountryById(DEFAULT_COUNTRY_ID)().id
          )
        }
      })
      .catch((error: Error) => {
        setLoading(false)
        notify(
          typeof error === 'string'
            ? error
            : typeof error === 'undefined' || !error.message
            ? 'ra.auth.sign_in_error'
            : error.message,
          {
            type: 'warning'
          }
          // {
          //   _:
          //     typeof error === 'string'
          //       ? error
          //       : error && error.message
          //       ? error.message
          //       : undefined,
          // }
        )
      })
  }

  return (
    <Root className={classes.main}>
      <Card className={classes.card}>
        <div className={classes.avatar}>
          <Avatar className={classes.icon}>
            <Lock />
          </Avatar>
        </div>
        <CardActions className={classes.actions}>
          <Button
            onClick={handleSubmit}
            variant="contained"
            type="submit"
            color="primary"
            disabled={loading}
            fullWidth
          >
            <>
              {loading && <CircularProgress size={25} thickness={2} />}
              {translate('ra.auth.sign_in')}
            </>
          </Button>
        </CardActions>
      </Card>
      <Notification />
    </Root>
  )
}

export default Login
