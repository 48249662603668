import React from 'react'
import { ArrayField, Datagrid, FunctionField } from 'react-admin'
import KAccordion from 'components/UI/Accordions/KAccordion/KAccordion'
import LocalizedTextField from 'components/Fields/Localized/LocalizedTextField'
import { useKRecordContext } from 'hooks/useKRecordContext'
import KDivider from 'components/UI/KDivider'
import { MainProduct } from '@kampaay/common'
// import { APILinkedThenClauseRule } from 'services/api/entities/catalog/types'
// can't on <FunctionField render type as ApiLinkedThenClauseRule actually is any line 47

type Props = {
  supportsIfClause?: boolean
}

const LinkedRulesField: React.FC<Props> = ({ supportsIfClause = true }) => {
  const record = useKRecordContext<MainProduct>()
  const hasThenClauses = !!record?.thenClauseLinkedRules?.length
  const hasIfClauses = !!record?.ifClauseLinkedRules?.length

  return (
    <KAccordion accordionTitle="Associated Rules">
      {supportsIfClause ? (
        hasIfClauses ? (
          <>
            <h3>
              This product is associated to the the following if Conditions:
            </h3>
            <ArrayField source="ifClauseLinkedRules" sortable={false}>
              <Datagrid>
                <LocalizedTextField source="ruleName" />
              </Datagrid>
            </ArrayField>
          </>
        ) : (
          <h3>This product has not associated if Conditions:</h3>
        )
      ) : null}
      <KDivider />
      {hasThenClauses ? (
        <>
          <h3>
            This product is associated to the the following then Conditions:
          </h3>
          <ArrayField source="thenClauseLinkedRules">
            <Datagrid>
              <LocalizedTextField source="ruleName" sortable={false} />
              <LocalizedTextField source="action" sortable={false} />

              <FunctionField
                label="Rules services Where"
                render={(thenClauseLinkedRules: any) =>
                  thenClauseLinkedRules.ruleServicesWhere.join(' ')
                }
              />
            </Datagrid>
          </ArrayField>
        </>
      ) : (
        <h3>This product has not associated then Conditions:</h3>
      )}
    </KAccordion>
  )
}

export default LinkedRulesField
