import React from 'react'
import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { KConsole } from 'services/console'
import { keys } from 'services/utils'

/**
 * This component logs warns in console if there are validation errors in the form
 */
const ValidationErrorLogger: React.FC = () => {
  const {
    formState: { errors }
  } = useFormContext()

  useEffect(() => {
    if (keys(errors).length > 0) {
      KConsole.warn('VALIDATION ERRORS: ', errors)
    }
  }, [errors])

  return null
}

export default ValidationErrorLogger
