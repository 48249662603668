import React from 'react'
import { Exporter, List, ListProps } from 'react-admin'
import { useKResourceContext } from 'hooks/useKResourceContext'
import { useQueryClient } from 'react-query'
import { KExporter } from 'services/csv/types'
import KFilters from 'services/filters/KFilters'

export type KListProps = Omit<ListProps, 'exporter'> & {
  exporter?: KExporter | false
}

const KList: React.FC<KListProps> = ({
  children,
  queryOptions: additionalQueryOptions,
  sort,
  exporter,
  filters = <KFilters />,
  ...rest
}) => {
  const resource = useKResourceContext()
  const queryClient = useQueryClient()

  /**
   * React Admin's getList hook caches a `getOne` query for every element in the list response
   * This will make the edit views use the list model and not re-fetch the detail model, which is not always the same of the list model
   * Thus here we override that behavior by deleting all getOne cached elements
   */
  const clearDetailCache = () => {
    return queryClient.removeQueries([resource, 'getOne'])
  }

  const queryOptions = {
    ...additionalQueryOptions,
    onSuccess: clearDetailCache
  }

  return (
    <List
      {...rest}
      filters={filters}
      queryOptions={queryOptions}
      sort={sort ?? { field: '', order: '' }}
      exporter={exporter as Exporter}
    >
      {children}
    </List>
  )
}

export default KList
