import { Badge } from '@mui/material'
import LabeledBooleanField from 'components/Fields/LabeledFields/LabeledBooleanField'
import EventServiceBriefSection from 'components/FormComponents/EventServiceBriefSection'
import { TailoredEventSelectionInput } from 'components/FormComponents/TailoredEventSelectionInput'
import KFormTab from 'components/Forms/KForms/KFormTab'
import KSelectInput from 'components/Inputs/KInputs/KSelectInput'
import TimePicker from 'components/Inputs/TimePicker'
import KDivider from 'components/UI/KDivider'
import { useForm } from 'hooks/useForm'
import React from 'react'
import { FormTabProps, useTranslate } from 'react-admin'
import { MappedEnrichedEvent } from 'services/api/entities/events/types/internal/event-models'
import { productStatusChoices } from 'services/cfg/form'
import { ENTITY_SERVICE_MAP } from 'services/cfg/name-maps'
import { ProductServiceName, RAFormFC } from 'types/common'
import EventServiceSection from 'resources/operations/events/EventsComponents/EventServiceSection'
import KTextInput from 'components/Inputs/KInputs/KTextInput/KTextInput'
import PricingSummary from 'components/FormComponents/PricingSummary/PricingSummary'
import FlexBox from 'components/UI/layout/FlexBox'
import EventCustomizationArrayInputAccordion from 'components/FormComponents/Customizations/EventCustomizationArrayInputAccordion'
import { useEventService } from 'hooks/events/useEventService'
import KDateTimeInput from 'components/Inputs/KInputs/KDateTimeInput'
import { ProductName } from '@kampaay/common'

type Props = Omit<FormTabProps, 'label'> & {
  product: Extract<ProductName, 'rent' | 'music'>
}

type BaseProductService = Extract<
  ProductServiceName,
  'musicService' | 'rentService'
>

const BaseProductTab: RAFormFC<Props> = ({ product, source, ...props }) => {
  const entity = ENTITY_SERVICE_MAP[product]
  const {
    hasSelections,
    hasCustomizations,
    notifyServiceSupplier,
    isNotifySupplierDisabled
  } = useEventService(`${product}s`)
  const translate = useTranslate()
  const { useGetValue, getSource } = useForm<MappedEnrichedEvent>(source)

  const productServices = useGetValue('productServices')
  const service = productServices?.[`${product}Service` as BaseProductService]
  const isGeoareaSelected = !!useGetValue('geoAreaPreset')

  return (
    <KFormTab
      {...props}
      path={product}
      disabled={!isGeoareaSelected}
      label={
        <Badge
          badgeContent={hasSelections || hasCustomizations ? 1 : 0}
          color="primary"
          variant="dot"
        >
          {translate(`form.tabs.${product}`)}
        </Badge>
      }
    >
      <FlexBox justifyContent="space-between">
        <KSelectInput
          source={`${product}Status`}
          label="form.fields.status"
          choices={productStatusChoices}
          defaultValue={productStatusChoices[0].id}
        />
        <PricingSummary
          source={getSource(`productServices.${product}Service.pricing`)}
          title={'form.headers.servicePricing'}
        />
      </FlexBox>

      <KTextInput
        source={`${product}Notes`}
        multiline
        label="form.fields.notes"
      />

      <EventServiceSection
        source={getSource(`productServices.${product}Service`)}
        serviceName={`${product}s`}
      />

      <TimePicker
        source={`${product}DeliveryTime`}
        label="form.fields.deliveryTime"
      />
      <KDateTimeInput
        source={`${product}PickupDate`}
        label="form.fields.pickupDate"
        // find a way to restore the locales to format the date
        // locales={getGeoLang()}
      />

      {/* Base Product input */}
      <TailoredEventSelectionInput
        entity={entity}
        source={entity}
        disableAdd
        label="form.fields.name"
      />

      <>
        <FlexBox>
          <KDivider />
          <EventCustomizationArrayInputAccordion
            source={`productServices.${product}Service`}
            maxCustomizations={1}
          />
        </FlexBox>
        {!!service?.briefQuestions && !!service?.briefQuestions.length && (
          <>
            <KDivider title="Event Brief" headingLevel="h3" />
            <LabeledBooleanField
              label="form.fields.briefConfirmation"
              source={`productServices.${product}Service.briefConfirmed`}
            />
            <EventServiceBriefSection
              serviceSource={`productServices.${product}Service`}
              notifyServiceSupplier={notifyServiceSupplier}
              isNotifySupplierDisabled={isNotifySupplierDisabled}
            />
          </>
        )}
      </>
    </KFormTab>
  )
}

export default BaseProductTab
