import { isTruthy } from '../../../utils/misc/isTruthy'
import type { APIFileInfo, FileInfo } from './types'

export const parseFileInfo = (doc?: APIFileInfo): FileInfo | undefined => {
  if (!doc) return undefined

  return {
    id: doc.id,
    title: doc.fileName,
    createdAt: doc.createdAt ? new Date(doc.createdAt) : undefined,
    updatedAt: doc.updatedAt ? new Date(doc.updatedAt) : undefined,
    documentType: doc.documentType
  }
}

export const parseMultipleFiles = (docs: APIFileInfo[]): FileInfo[] =>
  docs.map(parseFileInfo).filter(isTruthy)
