import { APICfg } from 'services/api/api'
import { uploadOrKeepImg } from 'services/utils/images'
import { logisticParse, logisticWrite } from '@kampaay/common'

// ============
// WRITE
// ============

// ============
// CFG
// ============

const apiCfg: APICfg = {
  endpoint: 'logistic',
  parse: logisticParse,
  write: logisticWrite(uploadOrKeepImg)
}

export default apiCfg
