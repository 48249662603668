import { styled } from '@mui/material/styles'
import React from 'react'
import KHeader, { HeadingLevel } from 'components/UI/KHeader'
import { WithStyle } from 'types/ui'

const PREFIX = 'Kdivider'

const classes = {
  divider: `${PREFIX}-divider`
}

const Root = styled('div')(({ theme }: any) => ({
  [`& .${classes.divider}`]: {
    width: '100%',
    height: '3px',
    backgroundColor: theme.palette.primary.light,
    marginTop: '20px'
  }
}))

type KDividerProps = WithStyle & {
  title?: string
  headingLevel?: HeadingLevel
  fullWidth?: boolean
}

/**
 * A divider with a title optional
 * It displays a simple line with a title on top of it if present
 * @param props
 */
const KDivider: React.FC<KDividerProps> = ({
  title,
  headingLevel,
  style,
  fullWidth
}) => {
  const fullWidthStyle: React.CSSProperties = fullWidth ? { width: '100%' } : {}

  return (
    <Root style={{ ...style, ...fullWidthStyle }}>
      <div className={classes.divider}></div>
      {!!title && <KHeader title={title} headingLevel={headingLevel} />}
    </Root>
  )
}

export default KDivider
