import React from 'react'
import SectionWrapper from 'components/UI/SectionWrapper'
import KampaayerFormContent from 'resources/crm/kampaayers/KampaayerFormContent'
import { KSimpleForm } from 'components/Forms/KForms/KSimpleForm'
import KCreate from 'components/Forms/KCreate/KCreate'

const KampaayerCreate: React.FC = () => {
  return (
    <SectionWrapper>
      <KCreate>
        <KSimpleForm>
          <KampaayerFormContent />
        </KSimpleForm>
      </KCreate>
    </SectionWrapper>
  )
}

export default KampaayerCreate
