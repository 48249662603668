import type {
  AIConciergeResponse,
  APIConcierge,
  APIConciergeRequest,
  Concierge,
  ConciergeFormPayload,
  ConciergeRequest
} from '../../../'
import { parseMultipleFiles } from '../fileinfo'

export const parsePayloadFromAIResponse = (
  apiModel: AIConciergeResponse
): Omit<ConciergeFormPayload, 'proposalDeadline'> => ({
  ...apiModel,
  when: {
    ...apiModel.when,
    hasDate: {
      startDate: !!apiModel.when?.hasDate?.startDate
        ? new Date(apiModel.when.hasDate?.startDate)
        : undefined,
      endDate: !!apiModel.when?.hasDate?.startDate
        ? new Date(apiModel.when.hasDate?.endDate)
        : undefined
    }
  },
  where: {
    address: apiModel.where?.address,
    need: apiModel.where?.need
  },
  howMany: {
    guestsNumber: apiModel.howMany?.guestsNumber,
    guestsNumberRange: apiModel.howMany?.guestsNumberRange
  },
  budget: {
    budgetPlanned: { amount: apiModel.budget?.budgetPlanned?.amount },
    budgetRange: apiModel.budget?.budgetRange
  }
})

export const parseConciergeRequest = (
  apiModel: APIConciergeRequest,
  aiResponseModel?: AIConciergeResponse
): ConciergeRequest => ({
  ...apiModel,
  formPayload: !!aiResponseModel
    ? parsePayloadFromAIResponse(aiResponseModel)
    : !!apiModel.formPayload
    ? {
        ...apiModel.formPayload,
        when: {
          ...apiModel.formPayload.when,
          hasDate: !!apiModel.formPayload?.when?.hasDate
            ? {
                ...apiModel.formPayload.when.hasDate,
                startDate: !!apiModel.formPayload.when.hasDate.startDate
                  ? new Date(apiModel.formPayload.when.hasDate.startDate)
                  : undefined,
                endDate: !!apiModel.formPayload.when.hasDate.endDate
                  ? new Date(apiModel.formPayload.when.hasDate.endDate)
                  : undefined
              }
            : undefined
        },
        proposalDeadline: !!apiModel.formPayload.proposalDeadline
          ? new Date(apiModel.formPayload.proposalDeadline)
          : undefined
      }
    : undefined
})

export const conciergeParse = (data: APIConcierge): Concierge => {
  const customerFull = data.customer
  const customer = data.customer.id
  const name = data.name ? data.name : `#Event${data.id}`

  return {
    ...data,
    name,
    customer,
    customerFull,
    startDate: data.startDate ? new Date(data.startDate) : undefined,
    endDate: data.endDate ? new Date(data.endDate) : undefined,
    customerDocs: parseMultipleFiles(data.customerDocs),
    kampaayerDocs: parseMultipleFiles(data.kampaayerDocs),
    proposalHistories: data.proposalHistories,
    originalRequest: !!data.originalRequest
      ? parseConciergeRequest(data.originalRequest, data.aiGeneratedForm)
      : undefined,
    request: !!data.currentRequest
      ? parseConciergeRequest(data.currentRequest)
      : undefined,
    referencedProposalData: data.originalRequest?.referencedProposalId
      ? {
          proposalId: data.originalRequest.referencedProposalId,
          conciergeId: data.originalRequest.referencedConciergeId
        }
      : undefined
  }
}
