import React, { useCallback } from 'react'
import {
  Button,
  ChipField,
  ListContextProvider,
  useGetMany,
  useList,
  useTranslate
} from 'react-admin'
import CustomReferenceInput from 'components/Inputs/CustomReferenceInput'
import KAutocompleteInput from 'components/Inputs/KInputs/KAutocompleteInput'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { FILTER_SOURCES, OrganizationSubGroup } from '@kampaay/common'
import { useParams } from 'react-router-dom'
import OrganizationBudgetSubGroupsDatagrid from 'components/FormComponents/Organization/SubGroups/OrganizationBudgetSubGroupsDatagrid'

const OrganizationBudgetSubGroup = () => {
  const { id: organizationId } = useParams()
  const translate = useTranslate()
  const { setValue, watch } = useFormContext()

  /**
   * Create a field array of subGroups and watch for changes. Whenever we append a new element
   * to the list, a request to the API is triggered to get the data of all the subGroups.
   */
  const { append, remove } = useFieldArray({ name: 'organizationSubGroups' })
  const subGroupdIds = watch('organizationSubGroups')
  const { data } = useGetMany<OrganizationSubGroup>('organizationsubgroup', {
    ids: subGroupdIds
  })

  //to keep the context separated with other list, add resource to useList
  const listContextSubGroups = useList({
    data,
    resource: 'organizationsubgroup'
  })
  const subGroup = watch('newSubGroup')

  /**
   * Append a new subGroup to the field array of subGroups manually
   * when the "Add subGroup" button is clicked, and set the value
   * of the search input to undefined, so clear it.
   *
   * This is needed because we want the subGroups table to be filled
   * with new data every time we add an element via the input.
   */
  const addSubGroup = useCallback(() => {
    if (subGroup) {
      append(subGroup)
      setValue('newSubGroup', undefined)
    }
  }, [subGroup])

  /**
   * Remove a subGroup from the field array of subGroups manually
   * when the "Remove" button of the datagrid is clicked.
   *
   * This is needed because we want to remove the element from
   * the list and not delete it from the database completely.
   */

  const removeSubGroups = useCallback(() => {
    remove(
      listContextSubGroups.selectedIds.map((id) => subGroupdIds.indexOf(id))
    )
  }, [listContextSubGroups, subGroupdIds])

  return (
    <div className="w-full">
      <div className="flex w-full items-center justify-between">
        <div className="flex w-fit items-center gap-2">
          <h2>{translate('resources.organization.budgetGroups')}</h2>
          {subGroupdIds && (
            <ChipField
              record={{ groups: subGroupdIds.length }}
              source="groups"
            />
          )}
        </div>
        <div className="flex w-fit items-center gap-2">
          <CustomReferenceInput
            entity="organizationsubgroup"
            queryFilter={{
              [FILTER_SOURCES.organizationId.eq]: organizationId!
            }}
            filterFns={[(m) => !subGroupdIds?.includes(m.id)]}
          >
            <KAutocompleteInput
              source="newSubGroup"
              label="form.fields.searchGroup"
              optionText="name"
              className="w-[250px]"
            />
          </CustomReferenceInput>
          <Button
            label="form.fields.addGroup"
            onClick={addSubGroup}
            disabled={!subGroup}
          />
        </div>
      </div>
      <div className="mt-4">
        {/* This context provider allows us to have a list of subGroups */}
        <ListContextProvider value={listContextSubGroups}>
          <OrganizationBudgetSubGroupsDatagrid
            data={data}
            removeSubGroups={removeSubGroups}
            bulkActions
          />
        </ListContextProvider>
      </div>
    </div>
  )
}

export default OrganizationBudgetSubGroup
