import { buildFilterConfig } from 'services/utils/filters'
import { FILTER_SOURCES } from '@kampaay/common'

export const AREA_FILTERS = [
  buildFilterConfig('text', {
    label: 'form.fields.name',
    source: FILTER_SOURCES.name.contains,
    alwaysOn: true
  })
]
