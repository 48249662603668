import { countrySwitchStrict } from '@kampaay/common'
import { getStoreFrontBaseUrlByEnvironment } from 'resources/operations/events/utils'

const SF_BASE_URL = getStoreFrontBaseUrlByEnvironment()

export const BASE_SF_EVENT_ROUTE = countrySwitchStrict({
  IT: `${SF_BASE_URL}/event`,
  SE: `${SF_BASE_URL}/sv-SE/event`
})

export const BASE_SF_PROPOSAL_ROUTE = countrySwitchStrict({
  IT: `${SF_BASE_URL}/proposal-preview`,
  SE: `${SF_BASE_URL}/sv-SE/proposal-preview`
})

export const BASE_URL = process.env.REACT_APP_API_BASE_URL
