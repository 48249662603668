import { Tooltip, TooltipProps } from '@mui/material'
import React from 'react'
import { useKTranslate } from 'hooks/useKTranslate'

export type KTooltipProps = Omit<TooltipProps, 'title' | 'classes'> & {
  title: string
  classes?: TooltipProps['classes'] & {
    wrapper?: string
  }
  disabled?: boolean
}

/**
 * This is a wrapper around the Tooltip component from Material UI.
 * @param title The text of the tooltip
 * @param placement the placement of the tooltip
 * @param style
 * @param children
 * @param classes
 * @param disabled
 *
 * @see https://mui.com/material-ui/react-tooltip/
 */
const KToolTip: React.FC<KTooltipProps> = ({
  children,
  title,
  placement = 'bottom-start',
  disabled,
  ...rest
}) => {
  const translatedTitle = useKTranslate(title)

  return (
    <Tooltip
      title={disabled ? '' : translatedTitle}
      placement={placement}
      {...rest}
    >
      <div className={rest.classes?.wrapper}>{children}</div>
    </Tooltip>
  )
}

export default KToolTip
