import React from 'react'
import { FacetSelectInput } from 'components/Inputs/Facets/BaseComponents/FacetSelectInput'

const FacetFilterType = () => (
  <FacetSelectInput
    facetCategory="productFilter"
    filterGroup="Type"
    source="filterTypeFacet"
  />
)

export default FacetFilterType
