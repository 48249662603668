import React from 'react'
import VirtualFacetSection from 'components/Inputs/Facets/FacetFormSections/VirtualFacetSection'
import ExperienceFormContent from 'components/Forms/ExperienceFormContent'

const VirtualExperiencesFormContent: React.FC = () => (
  <ExperienceFormContent
    facetFields={<VirtualFacetSection />}
    upsellingEntity="virtualupselling"
  />
)

export default VirtualExperiencesFormContent
