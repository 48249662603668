import React from 'react'
import SectionWrapper from 'components/UI/SectionWrapper'
import ProfessionalSectionFormContent from 'resources/catalog/professionals/sections/ProfessionalSectionFormContent'
import { KSimpleForm } from 'components/Forms/KForms/KSimpleForm'
import KEdit from 'components/Forms/KEdit/KEdit'

const ProfessionalSectionEdit: React.FC = () => (
  <SectionWrapper>
    <KEdit>
      <KSimpleForm>
        <ProfessionalSectionFormContent />
      </KSimpleForm>
    </KEdit>
  </SectionWrapper>
)

export default ProfessionalSectionEdit
