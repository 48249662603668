import OrganizationMenu from 'components/FormComponents/Organization/OrganizationMenu'
import React, { PropsWithChildren } from 'react'

type Props = {
  hasPadding?: boolean
}

/**
 * A simple wrapper that provides the organization menu aside
 * @param children - the content to place on the right of the menu
 * @param hasPadding - whether the content should have padding or not
 */
const OrganizationMenuWrapper: React.FC<PropsWithChildren<Props>> = ({
  children,
  hasPadding = false
}) => {
  return (
    <div
      className="flex h-full w-full gap-8"
      style={{ padding: hasPadding ? '16px' : '' }}
    >
      <OrganizationMenu />
      <div className="w-full">{children}</div>
    </div>
  )
}

export default OrganizationMenuWrapper
