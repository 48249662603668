import React from 'react'
import { Close } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { SimpleShowLayout, TextField } from 'ra-ui-materialui'
import { NumberField, RecordContextProvider } from 'react-admin'
import {
  Transaction,
  TransactionService
} from 'services/api/entities/accounting/transaction/types/internal/transaction-models'
import { getDiscountedPrice } from '@kampaay/common'
import KDateField from 'components/Fields/KDateField'
import { getAggregateValue } from 'services/utils'
import { ROUND_TWO_DECIMALS } from 'components/Fields/LabeledFields/LabeledNumberField'

type Props = {
  record?: Transaction
  handleClose: () => void
}

const TransactionAsidePanel: React.FC<Props> = ({ record, handleClose }) => {
  const price =
    record?.services.reduce(
      (acc: number, { price }: TransactionService) => acc + price,
      0
    ) ?? 0

  const customizationsPrice =
    record?.customizations.reduce(
      (acc: number, { price }) => acc + (price ?? 0),
      0
    ) ?? 0

  const finalPrice = price + customizationsPrice

  const customizationsCost = [...(record?.customizations ?? [])].reduce(
    (acc, itm) => acc + (itm?.cost ?? 0),
    0
  )

  const professionalCost = getAggregateValue(
    record?.professionalService.services ?? [],
    (itm) => itm.costNoVat
  )

  const costNoVat =
    getAggregateValue(record?.services ?? [], (itm) => itm.costNoVat) +
    customizationsCost +
    professionalCost

  const discountedPrice = getDiscountedPrice(finalPrice, record?.discount ?? 0)

  const discount = finalPrice - discountedPrice

  const enrichedRecord = {
    ...record,
    finalPrice,
    discountedPrice,
    discount,
    costNoVat
  }

  return (
    <RecordContextProvider value={enrichedRecord}>
      <SimpleShowLayout>
        <IconButton onClick={handleClose} style={{ marginTop: '48px' }}>
          <Close />
        </IconButton>
        <TextField source="id" label="form.fields.eventId" />
        <TextField source="customerName" />
        <TextField source="customerEmail" />
        <KDateField source="eventDate" label="form.fields.eventDate" />

        <NumberField source="finalPrice" options={ROUND_TWO_DECIMALS} />
        <NumberField source="discount" options={ROUND_TWO_DECIMALS} />
        <NumberField source="discountedPrice" options={ROUND_TWO_DECIMALS} />
        <NumberField source="costNoVat" options={ROUND_TWO_DECIMALS} />
      </SimpleShowLayout>
    </RecordContextProvider>
  )
}

export default TransactionAsidePanel
