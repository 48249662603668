import { Queue } from '@mui/icons-material'
import { Button, CloneButtonProps, useCreatePath } from 'react-admin'
import React from 'react'
import { useKRecordContext } from 'hooks/useKRecordContext'
import { Link } from 'react-router-dom'
import { stringify } from 'query-string'
import { filterObject, keys } from 'services/utils'
import {
  FileInfo,
  LocationPricingRuleSet,
  MultiLanguageString,
  OptionGroup,
  parseMultilangJSON,
  ProfessionalPricingRuleSet,
  removeIdsFromObject,
  WithAPIStandardPricing,
  WithByRulesPricing,
  WithIdAndName
} from '@kampaay/common'
import { useKResourceContext } from 'hooks/useKResourceContext'
import { DrinkVariant } from 'services/api/entities/catalog/drink/types/internal/drink-models'

type Props<TRecordType extends CloneRecord> = CloneButtonProps & {
  omittedProperties?: (keyof TRecordType)[]
}

const defaultIcon = <Queue />

// useful to prevent click bubbling in a datagrid with rowClick
const stopPropagation: React.MouseEventHandler<HTMLButtonElement> = (e) =>
  e.stopPropagation()

const sanitizeRestProps = ({
  resource: _resource,
  record: _record,
  ...rest
}: Omit<CloneButtonProps, 'label' | 'scrollToTop' | 'icon'>) => rest

type CloneRecordBase = WithIdAndName & {
  variants?: DrinkVariant[]
  options?: OptionGroup[]
  image?: FileInfo
  images?: FileInfo[]
}

type CloneRecord =
  | (CloneRecordBase & WithAPIStandardPricing)
  | (CloneRecordBase &
      WithByRulesPricing<ProfessionalPricingRuleSet | LocationPricingRuleSet>)

/**
 * This is a copy of the native React Admin CloneButton
 *
 * The copy was made in order to enhance the original with the functionality of omitting properties to clone from the old record to the new one
 * Most of the code here is copied and pasted fro th original, and slightly modified in some aspects
 */
const KCloneButton = <TRecordType extends CloneRecord>(
  props: Props<TRecordType>
) => {
  const {
    label = 'button.label.duplicate',
    scrollToTop = true,
    icon = defaultIcon,
    omittedProperties = [],
    ...rest
  } = props

  const resource = useKResourceContext()
  const record = useKRecordContext(props)
  const createPath = useCreatePath()
  const pathname = createPath({ resource, type: 'create' })

  const propertiesToOmit = [
    'organizations',
    'organizationsFull', // Removes organization binding within main products
    'sections', // Removes section binding within main products
    'section', // Removes professionals section
    'sectionName', // Removes professionals sectionName
    'image', // Removes image binding within products that have an image
    'images', // Removes images binding within products that have images
    ...omittedProperties
  ]

  const omit = (record: TRecordType) => {
    return {
      ...filterObject(
        (_v, key) => key !== 'id' && !propertiesToOmit.includes(key!),
        record
      ),
      // We need to remove the ids from the pricing object and the variants array
      pricing: removeIdsFromObject(record.pricing) as TRecordType['pricing'],
      variants: record.variants?.map((v) =>
        removeIdsFromObject(v)
      ) as TRecordType['variants'],
      options: record.options?.map((option) => ({
        ...removeIdsFromObject(option),
        items: option.items?.map((item) => ({
          ...removeIdsFromObject(item),
          image: undefined // We are not able to duplicate image associations between multiple products on the BE side, so here we need to remove the association
        }))
      })) as TRecordType['options']
    }
  }

  const appendCopyToName = (record: TRecordType) => {
    const name = parseMultilangJSON(record.name)
    // Name is a multilang string, so we need to append ' COPY' to each language
    return JSON.stringify(
      keys(name).reduce((acc, key) => {
        // Only append ' COPY' if the name is not empty
        acc[key] = !!name[key] ? name[key] + ' COPY' : ''
        return acc
      }, {} as MultiLanguageString)
    )
  }

  return (
    <Button
      component={Link}
      to={
        record
          ? {
              pathname,
              search: stringify({
                source: JSON.stringify({
                  ...omit(record),
                  name: appendCopyToName(record),
                  ...(!!record.status ? { status: 'inactive' } : {})
                })
              }),
              state: { _scrollToTop: scrollToTop }
            }
          : pathname
      }
      label={label}
      onClick={stopPropagation}
      {...sanitizeRestProps(rest)}
    >
      {icon}
    </Button>
  )
}

export default KCloneButton
