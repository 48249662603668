import {
  AnyFacet,
  Facet,
  FacetToAPI,
  parseFacet,
  writeFacet
} from '@kampaay/common'
import { APIFacet } from '@kampaay/common/src/api/resources/catalog/facets/types/response-models'
import { APICfg } from 'services/api/api'

const apiCfg: APICfg = {
  endpoint: 'facets',
  parse: (res: APIFacet): Facet => parseFacet(res),
  write: async (req: AnyFacet): Promise<FacetToAPI> => writeFacet(req)
}

export default apiCfg
