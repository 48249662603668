import KChip from 'components/UI/KChip'
import { useForm } from 'hooks/useForm'
import React from 'react'
import { ChipField } from 'react-admin'
import { RAFormFC } from 'types/common'
import LocalizedTextField from 'components/Fields/Localized/LocalizedTextField'
import { WithId } from '@kampaay/common'

type Context = WithId & {
  name: string
  quantity: number
}

const ChipFieldWithQuantity: RAFormFC = ({ source }) => {
  const { getSource } = useForm<Context>(source)

  return (
    <KChip
      sx={{ marginBlock: '2px' }}
      label={
        <>
          <LocalizedTextField source={getSource('name')} />
          <ChipField
            source={getSource('quantity')}
            size="small"
            style={{ marginRight: '-0.5rem' }}
          />
        </>
      }
    />
  )
}

export default ChipFieldWithQuantity
