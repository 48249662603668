import { yupObject } from 'services/yup/custom-schemas/object'
import { yupNumber } from 'services/yup/custom-schemas/number'
import { KTestConfig, KTestFunction } from 'services/yup/types'
import { APIPriceRange } from '@kampaay/common'

/**
 * @returns an error message if the price is inserted, and the cost is not
 */
const isCostValid = (cost?: number, price?: number) =>
  !price || (!!price && !!cost)

// !VALIDATIONS
// specs are defined here: https://gitlab.com/kampaay/general/-/issues/1055#bo-catalog-upselling-of-all-services
const variableCostValidation: KTestFunction<number, APIPriceRange> = (
  variableCost,
  { parent: { variablePrice } }
) => isCostValid(variableCost, variablePrice)

const fixedCostValidation: KTestFunction<number, APIPriceRange> = (
  fixedCost,
  { parent: { fixedPrice } }
) => isCostValid(fixedCost, fixedPrice)

const fixedPriceValidation: KTestFunction<number, APIPriceRange> = (
  fixedPrice,
  { parent: { fixedCost } }
) => !fixedPrice || !fixedCost || fixedPrice >= fixedCost

const variablePriceValidation: KTestFunction<number, APIPriceRange> = (
  variablePrice,
  { parent: { variableCost } }
) => !variablePrice || !variableCost || variablePrice >= variableCost

// !CONFIGS
const isVariableCostValid: KTestConfig<number, APIPriceRange> = {
  test: variableCostValidation,
  message: 'form.validations.variableCost'
}

const isFixedCostValid: KTestConfig<number, APIPriceRange> = {
  test: fixedCostValidation,
  message: 'form.validations.fixedCost'
}

const isVariablePriceValid: KTestConfig<number, APIPriceRange> = {
  test: variablePriceValidation,
  message: 'form.validations.variablePrice'
}

const isFixedPriceValid: KTestConfig<number, APIPriceRange> = {
  test: fixedPriceValidation,
  message: 'form.validations.fixedPrice'
}

// !VS
export const standardPricingVS = yupObject().shape({
  variableCost: yupNumber<APIPriceRange>().required().test(isVariableCostValid),
  fixedCost: yupNumber<APIPriceRange>().test(isFixedCostValid),
  variablePrice: yupNumber<APIPriceRange>()
    .required()
    .test(isVariablePriceValid),
  fixedPrice: yupNumber<APIPriceRange>().test(isFixedPriceValid),
  maxQuantity: yupNumber().required()
})
