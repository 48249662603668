import {
  getServiceBillingInfo,
  getTransactionBillingInfoFromProposal,
  getTransactionRecapBillingInfo,
  TransactionRecapBillingInfo,
  TransactionServiceBillingInfoForAggregates
} from 'resources/accounting/transaction/computedGetters'
import { keys, mapValues } from 'services/utils'
import { parseMultilangToCurrentLang, Proposal } from '@kampaay/common'
import { PRODUCT_SERVICES_NAMES } from 'services/cfg'
import { translate } from 'services/i18n'
import {
  TransactionProfessionalServiceGroup,
  TransactionService
} from 'services/api/entities/accounting/transaction/types/internal/transaction-models'
import { mapToObject } from 'services/utils/array'
import { Customization } from 'services/api/entities/events/types/internal/event-models'

/**
 * Returns the label of a service, if it's a product service it returns the translated label
 * otherwise it returns the service name itself (because it is taken from the name of the professional section)
 * @param servName - the name of the service or the name of the professional section
 */
const getServiceLabel = (servName: string): string =>
  PRODUCT_SERVICES_NAMES.some((s) => s === servName)
    ? translate(`form.headers.${servName}`)
    : parseMultilangToCurrentLang(servName)

/**
 * Given billing infos format the keys with the service name
 * @param billingInfo - the billing info to format
 * @param serviceName - the name of the service
 *
 * @example
 *
 * // returns { 'Food Service price': 10, ...}
 * formatBillingInfoWithServiceLabel({price: 10, ...}, 'foodService')
 */
export const formatBillingInfoWithServiceLabel = (
  billingInfo:
    | Omit<TransactionServiceBillingInfoForAggregates, 'serviceName'>
    | Partial<TransactionRecapBillingInfo>,
  serviceName: string
) =>
  mapToObject(
    keys(billingInfo),
    (key) => `${getServiceLabel(serviceName)} ${key}`,
    (key) => billingInfo[key]
  )

export const getServicesBillingInfoMap = (
  servicesArr: TransactionServiceBillingInfoForAggregates[]
) =>
  servicesArr.reduce(
    (acc, { serviceName, ...serviceBillingInfo }) => ({
      ...acc,
      ...formatBillingInfoWithServiceLabel(
        serviceBillingInfo,
        getServiceLabel(serviceName)
      )
    }),
    {}
  )

export const getComputed = ({
  experienceNames,
  ...rest
}: TransactionService) => {
  const serviceBillingInfo = getServiceBillingInfo(rest)

  return experienceNames
    ? {
        ...serviceBillingInfo,
        experienceNames: experienceNames.join(' - ')
      }
    : serviceBillingInfo
}

export const getProfessionalServiceAggregate = ({
  services,
  customizations
}: TransactionProfessionalServiceGroup) =>
  services.reduce<TransactionService>(
    (
      acc,
      {
        price,
        costNoVat,
        netDiscount,
        deltaCostNoVat,
        deliveryCost,
        costVatPerc
      }
    ) => ({
      ...acc,
      price: acc.price + price,
      costNoVat: acc.costNoVat + costNoVat,
      costVatPerc, // We take the last vat perc
      deltaCostNoVat: acc.deltaCostNoVat + deltaCostNoVat,
      deliveryCost: deliveryCost ? acc.deliveryCost ?? deliveryCost : undefined,
      netDiscount: netDiscount ? acc.netDiscount ?? netDiscount : undefined
    }),
    {
      price: 0,
      costNoVat: 0,
      costVatPerc: 0,
      customizations, // Professional service customizations are the same for all services
      deltaCostNoVat: 0,
      serviceName: 'Professional',
      deliveryCost: 0
    }
  )

/**
 * Takes an object and rounds all the number values of type number to 2 decimals
 * @param obj - the object whose numeric values are to be rounded
 */
export const roundAllObjectNumberValuesTo2Decimals = (obj: object) =>
  mapValues((v: unknown) => (typeof v === 'number' ? v.toFixed(2) : v), obj)

// Sets the order of the keys in transaction recap billing info
const reorderTransactionRecapBillingInfo = ({
  price,
  contractDiscount,
  dedicatedDiscount,
  costNoVat,
  discount,
  discountedPrice,
  discountedTotalMargin,
  discountedTotalMarginPerc,
  margin,
  marginPerc,
  totalCommission
}: TransactionRecapBillingInfo): TransactionRecapBillingInfo => ({
  price,
  discountedPrice,
  discount,
  contractDiscount,
  dedicatedDiscount,
  costNoVat,
  margin,
  discountedTotalMargin,
  marginPerc,
  discountedTotalMarginPerc,
  totalCommission
})

const emptyTransactionRecapBillingInfo: TransactionRecapBillingInfo =
  reorderTransactionRecapBillingInfo({
    price: 0,
    discountedPrice: 0,
    discount: 0,
    contractDiscount: 0,
    dedicatedDiscount: 0,
    costNoVat: 0,
    margin: 0,
    discountedTotalMargin: 0,
    marginPerc: 0,
    discountedTotalMarginPerc: 0,
    totalCommission: 0
  })

type GetEventOrProposalBasedBillingInfoParams = {
  transactionRelatedProposal: Proposal | undefined
  serviceBillingInfos: TransactionServiceBillingInfoForAggregates[]
  professionalService: TransactionProfessionalServiceGroup
  customizations: Customization[]
  eventDiscount?: number
}

/**
 * Returns the billing information for a transaction, based on either an event or a proposal.
 *
 * If a transaction-related proposal is provided, it generates the billing information based on the proposal,
 * excluding any out-of-scope items. If no proposal is provided, it generates the billing information based on the service.
 *
 * @param {GetEventOrProposalBasedBillingInfoParams} params - The parameters for the function.
 * @param {Customization[]} params.customizations - The customizations for the service.
 * @param {ProfessionalService} params.professionalService - The professional service related to the transaction.
 * @param {ServiceBillingInfo[]} params.serviceBillingInfos - The billing information for the service.
 * @param {Proposal} params.transactionRelatedProposal - The proposal related to the transaction. Optional.
 * @returns {Object} An object containing the general billing information and the event-only billing information.
 * @returns {TransactionRecapBillingInfo} return.generalBillingInfo - The general billing information.
 * @returns {TransactionRecapBillingInfo} return.eventOnlyBillingInfo - The event-only billing information.
 */
export const getEventOrProposalBasedBillingInfo = ({
  customizations,
  professionalService,
  serviceBillingInfos,
  transactionRelatedProposal,
  eventDiscount = 0
}: GetEventOrProposalBasedBillingInfoParams): {
  generalBillingInfo: TransactionRecapBillingInfo
  eventOnlyBillingInfo: TransactionRecapBillingInfo
  outOfScopeBillingInfo: TransactionRecapBillingInfo
} => {
  if (!transactionRelatedProposal) {
    return {
      generalBillingInfo: reorderTransactionRecapBillingInfo(
        getTransactionRecapBillingInfo(
          serviceBillingInfos,
          professionalService.customizations,
          customizations,
          eventDiscount,
          transactionRelatedProposal
        )
      ),
      eventOnlyBillingInfo: emptyTransactionRecapBillingInfo,
      outOfScopeBillingInfo: emptyTransactionRecapBillingInfo
    }
  }

  const proposalWithoutOutOfScopeItems: Proposal = {
    ...transactionRelatedProposal,
    itemGroups:
      transactionRelatedProposal.itemGroups.map((group) => ({
        ...group,
        items: group.items.filter((item) => !item.isOutOfScope)
      })) ?? []
  }
  const proposalWithOutOfScopeItems: Proposal = {
    ...transactionRelatedProposal,
    itemGroups:
      transactionRelatedProposal.itemGroups.map((group) => ({
        ...group,
        items: group.items.filter((item) => item.isOutOfScope)
      })) ?? []
  }

  return {
    generalBillingInfo: reorderTransactionRecapBillingInfo(
      getTransactionBillingInfoFromProposal(transactionRelatedProposal)
    ),
    eventOnlyBillingInfo: getTransactionBillingInfoFromProposal(
      proposalWithoutOutOfScopeItems
    ),
    outOfScopeBillingInfo: getTransactionBillingInfoFromProposal(
      proposalWithOutOfScopeItems
    )
  }
}
