import React from 'react'
import { useKRecordContext } from 'hooks/useKRecordContext'
import { EventPlaceholder } from 'services/api/entities/event-placeholder/types/internal/event-placeholder-models'
import FlexBox from 'components/UI/layout/FlexBox'
import {
  ArrayField,
  Datagrid,
  ReferenceField,
  RichTextField,
  TextField,
  useTranslate
} from 'react-admin'
import Groups2Icon from '@mui/icons-material/Groups2'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import EventOutlinedIcon from '@mui/icons-material/EventOutlined'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import FormatAlignLeftOutlinedIcon from '@mui/icons-material/FormatAlignLeftOutlined'
import KDateField from 'components/Fields/KDateField'
import EventStatusChipField from 'components/Inputs/Events/EventStatusChipField'
import ListItemWithLabel from 'components/UI/ListItemWithLabel'
import EventPlaceholderLinkedEntityIdField from 'resources/crm/clients/organization/placeholders/EventPlaceholderLinkedEntityIdField'
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined'

const EventPlaceholderDetailsCard = () => {
  const record = useKRecordContext<EventPlaceholder>()
  const translate = useTranslate()

  if (!record) {
    return null
  }

  return (
    <div className="border-1 my-2 rounded-lg border-solid border-neutral-lighten-1 p-6">
      <div className="text-truncate text-2xl font-semibold">{record.name}</div>
      <FlexBox
        className="mt-3"
        justifyContent="space-between"
        alignContent="center"
      >
        <FlexBox
          flex={2}
          alignContent="center"
          alignItems="center"
          justifyContent="start"
          gap={3}
        >
          {record.guestsNumber && (
            <div className="flex content-center items-center">
              <Groups2Icon color="disabled" />
              <span className="ml-2">
                {record.guestsNumber}{' '}
                {translate('org.eventPlaceholders.guests')}
              </span>
            </div>
          )}
          {record.address && (
            <div className="flex content-center items-center">
              <LocationOnOutlinedIcon color="disabled" />
              <span className="ml-1">{record.address}</span>
            </div>
          )}
          <div className="flex content-center items-center">
            <EventOutlinedIcon color="disabled" />
            <span className="ml-1">
              {record.startDate.toLocaleDateString()}{' '}
              {record.endDate && `- ${record.endDate.toLocaleDateString()}`}
            </span>
          </div>
        </FlexBox>
        {record.budget && (
          <FlexBox
            flex={1}
            className="text-2xl font-semibold"
            justifyContent="end"
          >
            <span>
              {record.budget}
              {translate('country.currency')}
            </span>
          </FlexBox>
        )}
      </FlexBox>
      <div className="mt-2 flex content-center items-center">
        <EmailOutlinedIcon color="disabled" />
        <ReferenceField reference="customers" source="customerId">
          <TextField className="ml-2" source="email" />
        </ReferenceField>
      </div>
      {record.notes && (
        <div className="mt-2 flex flex-row content-start items-start">
          <FormatAlignLeftOutlinedIcon color="disabled" />
          <RichTextField className="ml-2" source="notes" />
        </div>
      )}
      {!!record.events.length && (
        <div className="mt-4">
          <div className="text-truncate  text-xl font-semibold">
            {translate('org.eventPlaceholders.linkedEvents')}
          </div>
          <ArrayField source="events">
            <Datagrid className="mt-3" bulkActionButtons={false}>
              <ListItemWithLabel
                label={translate('org.eventPlaceholders.eventId')}
              >
                <EventPlaceholderLinkedEntityIdField />
              </ListItemWithLabel>
              <TextField
                source="name"
                label="org.eventPlaceholders.eventName"
              />
              <KDateField
                source="startDate"
                label="org.eventPlaceholders.startDate"
              />
              <EventStatusChipField
                source="eventStatus"
                label="org.eventPlaceholders.status"
              />
            </Datagrid>
          </ArrayField>
        </div>
      )}
      {!!record.concierge && (
        <div className="mt-2 flex content-center items-center">
          <RocketLaunchOutlinedIcon color={'disabled'} />
          <div className="ml-2">
            {translate('org.eventPlaceholders.linkedConcierge')}:
            <span className="ml-1">
              <EventPlaceholderLinkedEntityIdField />
            </span>
          </div>
        </div>
      )}
    </div>
  )
}

export default EventPlaceholderDetailsCard
