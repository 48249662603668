import React from 'react'
import { NumberField, SimpleShowLayout } from 'react-admin'
import KDateField from 'components/Fields/KDateField'
import SummaryEventButton from 'components/Buttons/SummaryEventButton'
import KTextField from 'components/Fields/KTextField'
import { ROUND_TWO_DECIMALS } from 'components/Fields/LabeledFields/LabeledNumberField'
import FlexBox from 'components/UI/layout/FlexBox'
import KHeader from 'components/UI/KHeader'
import { MappedEnrichedEvent } from 'services/api/entities/events/types/internal/event-models'
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined'
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined'
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import CallOutlinedIcon from '@mui/icons-material/CallOutlined'
import { greyedStyle } from 'resources/operations/events/EventEditReadOnlyFields'
import { useKRecordContext } from 'hooks/useKRecordContext'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'
import { useGetSource } from 'hooks/useForm/useForm'
import EventListServicesTable from 'resources/operations/events/list-components/EventListServicesTable'
import { Grid, Paper } from '@mui/material'

const EventListDetailsCard: React.FC = () => {
  const getSource = useGetSource<MappedEnrichedEvent>(undefined)
  const record = useKRecordContext<MappedEnrichedEvent>()

  return (
    <SimpleShowLayout>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={11}>
          <Paper
            variant="outlined"
            sx={{ padding: '24px', marginInline: '48px' }}
          >
            <FlexBox justifyContent="space-between" gap={'1em'}>
              <KHeader title={record?.name ?? ''} style={{ margin: '0' }} />
              <SummaryEventButton />
            </FlexBox>

            <FlexBox justifyContent="space-between">
              {/*=========== LEFT CONTAINER ===========*/}
              <FlexBox
                flexDirection="column"
                justifyContent="space-between"
                gap={'.5em'}
              >
                {/*======= row 1 =======*/}
                <FlexBox gap={'1em'}>
                  <div>
                    <KTextField
                      source={getSource('guestsNumber')}
                      startIcon={<GroupsOutlinedIcon style={greyedStyle} />}
                      endLabel={'form.fields.guests'}
                    />
                  </div>{' '}
                  {record?.fullAddress && (
                    <KTextField
                      source={getSource('fullAddress')}
                      startIcon={<FmdGoodOutlinedIcon style={greyedStyle} />}
                    />
                  )}
                </FlexBox>

                <FlexBox alignItems="center" gap={'1em'}>
                  <KDateField
                    source={getSource('startDate')}
                    startIcon={<TodayOutlinedIcon style={greyedStyle} />}
                  />
                  <AccessTimeOutlinedIcon style={greyedStyle} />
                  <KDateField source={getSource('startDate')} showTime />
                  -
                  <KDateField source={getSource('endDate')} showTime />
                </FlexBox>

                {/*======= row 2 =======*/}
                <FlexBox gap={'1em'}>
                  <KTextField
                    source={getSource('customerInfo.email')}
                    startIcon={<EmailOutlinedIcon style={greyedStyle} />}
                  />
                  <KTextField
                    source={getSource('customerInfo.phone')}
                    startIcon={<CallOutlinedIcon style={greyedStyle} />}
                  />
                </FlexBox>
              </FlexBox>

              {/*=========== RIGHT CONTAINER ===========*/}
              <FlexBox
                flexDirection="column"
                alignItems="end"
                justifyContent={'space-between'}
                gap={'.5em'}
              >
                {/*======= pricing ======= */}
                <FlexBox flexDirection="column" alignItems="end">
                  <div>
                    <NumberField
                      source={getSource('pricing.price')}
                      options={ROUND_TWO_DECIMALS}
                      sx={{
                        fontSize: '1.5rem',
                        fontWeight: '700',
                        marginRight: '.2em'
                      }}
                    />
                    &euro;
                  </div>

                  <div style={greyedStyle}>
                    (
                    <NumberField
                      options={ROUND_TWO_DECIMALS}
                      source={getSource('pricing.costNoVat')}
                      sx={{
                        marginRight: '.2em'
                      }}
                    />
                    &euro;)
                  </div>
                </FlexBox>
              </FlexBox>
            </FlexBox>

            <EventListServicesTable />
          </Paper>
        </Grid>
      </Grid>
    </SimpleShowLayout>
  )
}

export default EventListDetailsCard
