import { SupplierType } from '@kampaay/common'
import {
  UseValidationSchema,
  yupIgnore,
  yupObject,
  yupString
} from 'services/yup'

const useSupplierTypeVS: UseValidationSchema<SupplierType> = () =>
  yupObject().shape({
    id: yupIgnore(),
    name: yupString().required().max(200)
  })

export default useSupplierTypeVS
