import { useKRecordContext } from 'hooks/useKRecordContext'
import React, { CSSProperties } from 'react'
import { TextField, TextFieldProps, useTranslate } from 'react-admin'
import get from 'lodash/get'

const kTextFieldContainerStyle: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  gap: '.4em'
}

type Props = Pick<
  TextFieldProps,
  'label' | 'source' | 'record' | 'sortable' | 'sx' | 'className'
> & {
  startIcon?: React.ReactNode
  endLabel?: string
  labelStyle?: CSSProperties
}

/**
 * TextField with a label above
 * @param startIcon - MUI icon to show before the field
 * @param endLabel - label to show after the field
 * @param labelStyle - style of the label
 * @param textFieldProps - props to pass to the TextField
 */
const KTextField: React.FC<Props> = ({
  source,
  // The record, if not passed as prop, would be taken from the context regardless
  // inside the textField component, so we get it here to use it
  record = useKRecordContext(),
  startIcon,
  endLabel,
  labelStyle,
  className,
  ...textFieldProps
}) => {
  const translate = useTranslate()
  const hasValue = !!source && !!get(record, source)

  if (!hasValue) return null
  return (
    <div style={kTextFieldContainerStyle}>
      {startIcon}
      <TextField
        source={source}
        record={record}
        {...textFieldProps}
        className={className}
      />
      {endLabel ? <span style={labelStyle}>{translate(endLabel)}</span> : null}
    </div>
  )
}

export default KTextField
