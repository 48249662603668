import type { CountryInfo, I18nBinding } from './countries-cfg'

/**
 * Selects the supported languages for a given country.
 *
 * @param {CountryInfo} country - Information about the country for which to select supported languages.
 * @returns {I18nBinding[]} An array of objects containing information about supported languages for the country.
 */
export const getCountryLanguages = (country: CountryInfo): I18nBinding[] => [
  {
    localeCode: country.localeCode,
    langLabel: country.langLabel,
    i18nLocale: country.i18nLocale,
    languageCode: country.languageCode
  },
  ...country.secondaryLangs
]
