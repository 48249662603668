import { LocationFeature } from 'services/api/entities/catalog/locationFeatures/types/internal/locationFeatures-models'
import { UseValidationSchema, yupIgnore, yupString } from 'services/yup'
import { LocationFeatureCategory } from 'types/form'
import { catalogItemBaseVS } from 'services/validations/catalog/catalogItemBase'

export const useLocationFeatureVS: UseValidationSchema<LocationFeature> = () =>
  catalogItemBaseVS.shape({
    image: yupIgnore(),
    category: yupString<LocationFeatureCategory>().required()
  })
