import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react'
import { LoggedUserContext } from 'contexts/loggedUserContext'
import { KConsole } from 'services/console'
import { getToken, Kampaayer } from '@kampaay/common'
import { callApi, getApiCfg, getPath } from 'services/api/dataProvider/common'
import { auth0 } from 'auth0Provider'

export const LoggedUserContextProvider: React.FC<PropsWithChildren> = ({
  children
}) => {
  const [loggedUserState, setLoggedUserState] = useState<Kampaayer | undefined>(
    undefined
  )

  useEffect(() => {
    ;(async () => {
      try {
        // get kampaayer id from jwt token (as we do for BOv2)
        const jwtToken = await getToken(auth0)

        if (!jwtToken?.kampaayUserId) {
          return KConsole.error(
            'Error fetching kampaayer: kampaayerId not found in local storage'
          )
        }
        const kampaayerApiCfg = getApiCfg('kampaayers')
        try {
          const res = await callApi(
            kampaayerApiCfg,
            getPath(kampaayerApiCfg, {
              id: jwtToken?.kampaayUserId
            }),
            'kampaayers'
          )
          setLoggedUserState(res?.data)
        } catch (err) {
          KConsole.error(`Error fetching kampaayer: ${err}`)
        }
      } catch (error) {
        KConsole.error(`Error getting token: ${error}`)
      }
    })()
  }, [])

  const context = useMemo(
    () => ({
      loggedUser: loggedUserState
    }),
    [loggedUserState]
  )

  return (
    <LoggedUserContext.Provider value={context}>
      {children}
    </LoggedUserContext.Provider>
  )
}
