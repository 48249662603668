import type { OrganizationMembershipToAPI } from 'services/api/entities/crm/organizations/membership/types/api/request-models'
import type { APICfg } from 'services/api/api'
import {
  OrganizationMembership,
  organizationMembershipParse
} from '@kampaay/common'
import { cleanUpApprovers } from 'services/api/entities/crm/organizations/index'

export const organizationMembershipWrite = async (
  organizationMembership: OrganizationMembership
): Promise<OrganizationMembershipToAPI> => {
  return {
    ...organizationMembership,
    approvalSteps: cleanUpApprovers(organizationMembership.approvalSteps)
  }
}

const apiCfg: APICfg = {
  endpoint: 'organizationmembership',
  parse: organizationMembershipParse,
  write: organizationMembershipWrite
}

export default apiCfg
