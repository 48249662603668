import { standardPricingArrayVS } from 'services/validations/catalog/standard-pricing/standardPricingArray'
import {
  UseValidationSchema,
  yupArray,
  yupIgnore,
  yupString
} from 'services/yup'
import { catalogItemBaseVS } from 'services/validations/catalog/catalogItemBase'
import { Logistic } from '@kampaay/common'

export const useLogisticVS: UseValidationSchema<Logistic> = () => {
  return catalogItemBaseVS.shape({
    description: yupString().required(),
    facets: yupArray(),
    facetCodes: yupArray(),
    pricing: standardPricingArrayVS({ allowFree: true }).min(1).max(1),
    image: yupIgnore(),
    geoAreaPresets: yupArray().min(1)
  })
}
