import React from 'react'
import { EditButton, ReferenceArrayField } from 'react-admin'
import { useParams } from 'react-router-dom'
import { useKRecordContext } from 'hooks/useKRecordContext'
import { OrganizationBudget } from '@kampaay/common'
import KHeader from 'components/UI/KHeader'
import { parseMultilangOrFallbackToAnyAvailable } from '@kampaay/common'
import OrganizationSubGroupDatagrid from 'components/FormComponents/Organization/SubGroups/OrganizationSubGroupDatagrid'

const OrganizationSubGroupExpandable: React.FC = () => {
  const record = useKRecordContext<OrganizationBudget>()
  const { id } = useParams()

  if (!record) {
    return null
  }

  return (
    <div className="px-10 pb-16 pt-5">
      <div className="flex w-full items-center justify-between gap-2">
        <KHeader title={parseMultilangOrFallbackToAnyAvailable(record.name)} />
        <EditButton to={`/organization/${id}/sub-groups/${record.id}`} />
      </div>
      <div className="w-full">
        <ReferenceArrayField
          reference="organizationmembership"
          source="membershipsIds"
          record={record}
        >
          <OrganizationSubGroupDatagrid />
        </ReferenceArrayField>
      </div>
    </div>
  )
}

export default OrganizationSubGroupExpandable
