import React from 'react'

export type OKRCountryView = 'ita' | 'swe' | 'all'

type Props = {
  value: OKRCountryView
  updateValue: (value: OKRCountryView) => void
  title: string
}

const OkrCountryViewSwitch: React.FC<Props> = ({
  value,
  updateValue,
  title
}) => {
  return (
    <div className="m-3 rounded-2xl bg-neutral-300 p-4 px-6">
      <div>{title}</div>
      <div className="mt-2 flex justify-between rounded-2xl bg-neutral-100 p-2">
        <span
          className={`cursor-pointer rounded-2xl px-3 py-1 ${
            value === 'all' ? 'bg-neutral-300' : 'bg-neutral-200'
          }`}
          onClick={() => updateValue('all')}
        >
          All
        </span>
        <span
          className={`cursor-pointer rounded-2xl px-3 py-1 ${
            value === 'ita' ? 'bg-neutral-300' : 'bg-neutral-200'
          }`}
          onClick={() => updateValue('ita')}
        >
          Ita
        </span>
        <span
          className={`cursor-pointer rounded-2xl px-3 py-1 ${
            value === 'swe' ? 'bg-neutral-300' : 'bg-neutral-200'
          }`}
          onClick={() => updateValue('swe')}
        >
          Swe
        </span>
      </div>
    </div>
  )
}

export default OkrCountryViewSwitch
