import React from 'react'
import SectionWrapper from 'components/UI/SectionWrapper'
import KList from 'components/Lists/KLists/KList'
import KPagination from 'components/Lists/KPagination'
import {
  BooleanField,
  ChipField,
  CreateButton,
  Datagrid,
  EditButton,
  FunctionField,
  NumberField
} from 'react-admin'
import { OrganizationBudget } from '@kampaay/common'
//import { organizationBudgetParse } from 'react-admin/src/@kampaay/common'
import KDateField from 'components/Fields/KDateField'
import { useParams } from 'react-router-dom'
import OrganizationMenuWrapper from 'components/FormComponents/Organization/OrganizationMenuWrapper'
import OrganizationBudgetExpandable from 'components/FormComponents/Organization/Budget/OrganizationBudgetExpandable'
import {
  FILTER_SOURCES,
  parseMultilangOrFallbackToAnyAvailable
} from '@kampaay/common'

const OrganizationBudgetList: React.FC = () => {
  const { id } = useParams()

  return (
    <SectionWrapper>
      <KList
        resource="organizationbudget"
        exporter={false}
        pagination={<KPagination />}
        perPage={40}
        sort={{ field: 'id', order: 'ASC' }}
        actions={
          <CreateButton
            label="form.fields.addSharedBudget"
            to={`/organization/${id}/budgets/create`}
          />
        }
        filter={{ [FILTER_SOURCES.organizationId.eq]: id }}
        filterDefaultValues={{ [FILTER_SOURCES.isActive.eq]: 'true' }}
        empty={false}
      >
        <OrganizationMenuWrapper hasPadding>
          <Datagrid rowClick="expand" expand={<OrganizationBudgetExpandable />}>
            <FunctionField
              label="form.fields.name"
              sortable={false}
              render={(record: OrganizationBudget) => (
                <EditButton
                  label={parseMultilangOrFallbackToAnyAvailable(record.name)}
                  to={`/organization/${id}/budgets/${record.id}`}
                  icon={<></>}
                  className="normal-case"
                />
              )}
            />
            <NumberField source="totalAmount" label="form.fields.total" />
            <FunctionField
              label="form.fields.available"
              sortable={false}
              render={(record: OrganizationBudget) => (
                <NumberField
                  record={{
                    available: record.totalAmount - record.amountSpent
                  }}
                  source="available"
                />
              )}
            />
            <KDateField
              source="startDate"
              label="form.fields.startDate"
              sortable={false}
            />
            <KDateField
              source="endDate"
              label="form.fields.endDate"
              sortable={false}
            />
            <FunctionField
              label="Groups"
              sortable={false}
              render={(record: OrganizationBudget) => (
                <ChipField
                  record={{ groups: record.organizationSubGroups.length }}
                  source="groups"
                />
              )}
            />
            <FunctionField
              label="form.fields.members"
              sortable={false}
              render={(record: OrganizationBudget) => (
                <ChipField
                  record={{ members: record.membershipsIds.length }}
                  source="members"
                />
              )}
            />
            <BooleanField
              source="isActive"
              label="form.fields.isActive"
              sortable={false}
            />
            <BooleanField
              source="automaticRenewal"
              label="form.fields.automaticRenewal"
              sortable={false}
            />
          </Datagrid>
        </OrganizationMenuWrapper>
      </KList>
    </SectionWrapper>
  )
}

export default OrganizationBudgetList
