import {
  BillingInfo,
  CountryCode,
  getBillingInfo,
  getMargin,
  getMarginPerc,
  getProposalTotalPricing,
  type Proposal
} from '@kampaay/common'
import { TransactionService } from 'services/api/entities/accounting/transaction/types/internal/transaction-models'
import { Customization } from 'services/api/entities/events/types/internal/event-models'
import { getAggregateValue } from 'services/utils'

export type WithServiceName = {
  serviceName: string
}

/**
 * These are the billing info for a single service
 */
export type TransactionServiceBillingInfo = Omit<BillingInfo, 'vatRate'> & {
  serviceName: string

  // Customizations
  extraCost?: number
  extraPrice?: number
}

/**
 * Transaction service billing info for aggregates used to
 * calculate the TransactionRecapBillingInfo
 */
export type TransactionServiceBillingInfoForAggregates = Omit<
  TransactionServiceBillingInfo,
  'costVatPerc'
>

/**
 * Type representing the billing info for a transaction
 * This information are used to display info at the top of the transaction form
 */
export type TransactionRecapBillingInfo = Omit<
  TransactionServiceBillingInfo,
  'costVatPerc' | 'serviceName' | 'extraPrice' | 'extraCost'
> & {
  discountedTotalMargin: number
  discountedTotalMarginPerc: number
  discount: number
  discountedPrice: number

  totalCommission?: number
  contractDiscount?: number
  dedicatedDiscount?: number
}

/**
 * Data necessary to compute the billing info
 */
type ServiceDataForBillingInfo = Pick<
  TransactionService,
  | 'costNoVat'
  | 'deltaCostNoVat'
  | 'costVatPerc'
  | 'price'
  | 'serviceName'
  | 'deliveryCost'
  | 'customizations'
>

/**
 * Computes the billing information for a single service.
 *
 * This function takes into account the base cost of the service, any additional costs due to customizations,
 * and the VAT rate. It then calculates the total price, the cost without VAT, and the margin.
 *
 * @param {ServiceDataForBillingInfo} serviceData - An object containing the data for the service.
 * @param {number} serviceData.costNoVat - The base cost of the service without VAT.
 * @param {number} serviceData.deltaCostNoVat - The additional cost without VAT.
 * @param {number} serviceData.costVatPerc - The VAT rate for the service.
 * @param {Customization[]} serviceData.customizations - The customizations for the service.
 * @param {number} serviceData.price - The base price of the service.
 * @param {string} serviceData.serviceName - The name of the service.
 * @param {CountryCode} [customerBusinessCountry] - The business country of the customer. Optional.
 * @returns {TransactionServiceBillingInfo} An object containing the billing information for the service.
 */
export const getServiceBillingInfo = (
  {
    costNoVat: serviceCostNoVat,
    deltaCostNoVat,
    costVatPerc,
    customizations = [],
    price: servicePrice,
    serviceName
  }: ServiceDataForBillingInfo,
  customerBusinessCountry?: CountryCode
): TransactionServiceBillingInfo => {
  const extraPrice = customizations?.sum((c) => (c.price ? c.price : 0)) ?? 0
  const extraCost = customizations?.sum((c) => (c.cost ? c.cost : 0)) ?? 0

  const price = servicePrice + extraPrice
  const costNoVat = serviceCostNoVat + extraCost + deltaCostNoVat

  const { margin, marginPerc } = getBillingInfo({
    price,
    costNoVat,
    costVatPerc,
    customerBusinessCountry
  })

  return {
    serviceName,
    costVatPerc,
    price,
    costNoVat,
    margin,
    marginPerc,
    extraPrice,
    extraCost
  }
}

export const getTransactionRecapBillingInfo = (
  aggregateRecord: TransactionServiceBillingInfoForAggregates[],
  professionalsCustomizations: Customization[] = [],
  customizations: Customization[] = [],
  discount = 0,
  proposal?: Proposal
): TransactionRecapBillingInfo => {
  const [customizationsCost, customizationsPrice] = [
    ...customizations,
    ...professionalsCustomizations
  ].reduce(
    ([prevCost, prevPrice], { cost, price }) => [
      prevCost + (cost ?? 0),
      prevPrice + (price ?? 0)
    ],
    [0, 0]
  )

  const contractDiscount = proposal?.contractDiscount ?? 0
  const dedicatedDiscount = proposal?.dedicatedDiscount ?? 0

  // For events created from proposals BE stores the proposal's final price as the price of a customization
  // this means that to get the total non-discounted price for the entire event we need to add back the discounts
  // thus the addition of contractDiscount and dedicatedDiscount
  const price =
    getAggregateValue(aggregateRecord, (i) => i.price) +
    customizationsPrice +
    contractDiscount +
    dedicatedDiscount

  const discountedPrice =
    price - contractDiscount - dedicatedDiscount - discount
  const finalDiscount = contractDiscount + dedicatedDiscount + discount

  const costNoVat =
    getAggregateValue(aggregateRecord, (itm) => itm.costNoVat) +
    customizationsCost

  const margin = getMargin(price, costNoVat)
  const discountedTotalMargin = getMargin(discountedPrice, costNoVat)
  const marginPerc = getMarginPerc(margin, price)
  const discountedTotalMarginPerc = getMarginPerc(
    discountedTotalMargin,
    discountedPrice
  )
  const totalCommission = !!proposal
    ? getProposalTotalPricing(proposal).totalCommission
    : 0

  return {
    price,
    discountedPrice,
    discount: finalDiscount,
    contractDiscount,
    dedicatedDiscount,
    costNoVat,
    margin,
    discountedTotalMargin,
    marginPerc,
    discountedTotalMarginPerc,
    totalCommission
  }
}

export const getTransactionBillingInfoFromProposal = (
  proposal: Proposal,
  eventDiscount = 0
): TransactionRecapBillingInfo => {
  const {
    totalPrice,
    finalPrice,
    totalCost: proposalTotalCost,
    totalCommission,
    postDiscountMarginality,
    postDiscountMarginalityPercentage
  } = getProposalTotalPricing(proposal)
  const { price, costNoVat, margin, marginPerc } = getBillingInfo({
    price: totalPrice,
    costNoVat: proposalTotalCost
  })

  const contractDiscount = proposal.contractDiscount ?? 0
  const dedicatedDiscount = proposal.dedicatedDiscount ?? 0
  const discount = contractDiscount + dedicatedDiscount + eventDiscount

  return {
    price,
    discountedPrice: finalPrice - eventDiscount,
    discount,
    contractDiscount,
    dedicatedDiscount,
    costNoVat,
    margin,
    discountedTotalMargin: postDiscountMarginality,
    marginPerc,
    discountedTotalMarginPerc: postDiscountMarginalityPercentage,
    totalCommission
  }
}
