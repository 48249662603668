import { EmojiPeople } from '@mui/icons-material'
import ProfessionalFiguresList from 'resources/catalog/professionals/p-figures/ProfessionalFiguresList'
import ProfessionalFiguresCreate from 'resources/catalog/professionals/p-figures/ProfessionalFiguresCreate'
import ProfessionalFiguresEdit from 'resources/catalog/professionals/p-figures/ProfessionalFiguresEdit'

const drinkSectionIndex = {
  icon: EmojiPeople,
  list: ProfessionalFiguresList,
  edit: ProfessionalFiguresEdit,
  create: ProfessionalFiguresCreate
}

export default drinkSectionIndex
