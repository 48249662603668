import React from 'react'
import VariantTailoringsInput from 'resources/crm/clients/organization/tailoring/components/variant/VariantTailoringsInput'
import ServiceTailoringsEdit from 'resources/crm/clients/organization/tailoring/ServiceTailoringsEdit'

const DrinksTailoringsEdit: React.FC = () => {
  return (
    <ServiceTailoringsEdit
      service="drinks"
      productEntity="drinks"
      tailoringsInput={VariantTailoringsInput}
    />
  )
}

export default DrinksTailoringsEdit
