import { KTestConfig, KTestFunction, UseValidationSchema } from 'services/yup'
import { useUpsellingVS } from 'services/validations/catalog/upselling'
import { standardPricingArrayVS } from 'services/validations/catalog/standard-pricing/standardPricingArray'
import { DEFAULT_PRICE_RANGE_MAX_QUANTITY } from 'services/cfg/form'
import { APIPriceRange, Upselling } from '@kampaay/common'

/**
 * Requires at least one price range to have maxQuantity set to the default max quantity
 * @param values price ranges
 */
const validateMaxQuantityPricingRange: KTestFunction<APIPriceRange[]> = (
  values
) =>
  !values ||
  values.some((p) => p?.maxQuantity === DEFAULT_PRICE_RANGE_MAX_QUANTITY)

const hasMaxQuantityPriceRangeCFG: KTestConfig<APIPriceRange[]> = {
  test: validateMaxQuantityPricingRange,
  message: `At least one pricing must have Max Quantity set to ${DEFAULT_PRICE_RANGE_MAX_QUANTITY}`
}

export const useVirtualUpsellingVS: UseValidationSchema<Upselling> = () =>
  useUpsellingVS().shape({
    pricing: standardPricingArrayVS().min(1).test(hasMaxQuantityPriceRangeCFG)
  })
