import React from 'react'
import { FormDataConsumer, SimpleFormIterator } from 'react-admin'
import ProfessionalsSelectionDetailsFormContent from 'resources/operations/events/eventsFormTabs/ProfessionaTab/ProfessionalsSelectionDetailsFormContent'
import { isProfessionalServiceObsolete } from 'resources/operations/events/utils'
import { ProfessionalSection } from 'services/api/entities/catalog/sections/professionalSections'
import { EnrichedProfessionalService } from 'services/api/entities/events/types/internal/services'
import { FormDataConsumerProps } from 'types/common'
import LocalizedSelectInput from 'components/Inputs/Localized/LocalizedSelectInput'
import PricingSummary from 'components/FormComponents/PricingSummary/PricingSummary'
import KArrayInput from 'components/Inputs/KInputs/KArrayInput'
import { getById } from '@kampaay/common'

type Props = {
  choices?: ProfessionalSection[]
}

const ProfessionalServiceSelectionInput: React.FC<Props> = ({
  choices = [],
  ...rest
}) => (
  <KArrayInput source={'professionalService.services'}>
    <SimpleFormIterator disableReordering>
      <FormDataConsumer {...rest}>
        {({
          scopedFormData,
          getSource
        }: FormDataConsumerProps<unknown, EnrichedProfessionalService>) => {
          const isObsolete = isProfessionalServiceObsolete(
            choices,
            scopedFormData
          )

          return (
            <>
              <LocalizedSelectInput
                {...rest}
                choices={choices}
                label="form.fields.professionalSections"
                source={getSource!('id')}
                forceDisabled={!!scopedFormData?.id || isObsolete}
              />

              <PricingSummary
                source={getSource!('pricing')}
                title={'form.headers.servicePricing'}
                labelPrice={'form.fields.revenueNoVat'}
              />

              {!!scopedFormData && (
                <ProfessionalsSelectionDetailsFormContent
                  scopedFormData={scopedFormData}
                  getSource={getSource}
                  selectedProduct={getById(choices, scopedFormData.id)}
                  isObsolete={isObsolete}
                />
              )}
            </>
          )
        }}
      </FormDataConsumer>
    </SimpleFormIterator>
  </KArrayInput>
)

export default ProfessionalServiceSelectionInput
