import React from 'react'
import SectionWrapper from 'components/UI/SectionWrapper'
import FoodFormContent from 'resources/catalog/food/product/FoodFormContent'
import { KSimpleForm } from 'components/Forms/KForms/KSimpleForm'
import KCreate from 'components/Forms/KCreate/KCreate'

const FoodCreate: React.FC = () => {
  return (
    <SectionWrapper>
      <KCreate>
        <KSimpleForm>
          <FoodFormContent />
        </KSimpleForm>
      </KCreate>
    </SectionWrapper>
  )
}

export default FoodCreate
