export type FeatureFlag = 'i18n' | 'timelog' | 'supplierOrderAccess'

export type FeatureFlagConfig = {
  icon: string
  title: string
  description: string
}

export const availableFeatureFlags: Record<FeatureFlag, FeatureFlagConfig> = {
  i18n: {
    icon: 'fa-regular fa-language',
    title: 'i18n Manager',
    description: 'Allows managing i18n labels.'
  },
  timelog: {
    icon: 'fa-regular fa-table-list',
    title: 'Timelog',
    description: 'Gives access to timelog data also to non EE kampaayers.'
  },
  supplierOrderAccess: {
    icon: 'fa-regular fa-boxes-packing',
    title: 'Supplier Order access',
    description: 'Allows access to order management for suppliers.'
  }
}

const storagePrefix = 'kFeat_'
const enabledValue = 'true'

/**
 * Enable or disable a specific feature flag for the current browser.
 *
 * @param {FeatureFlag} flag The feature flag to toggle.
 */
export const toggleFeatureFlag = (flag: FeatureFlag) => {
  const storageKey = `${storagePrefix}${flag}`

  if (localStorage.getItem(storageKey)) {
    localStorage.removeItem(storageKey)
  } else {
    localStorage.setItem(storageKey, enabledValue)
  }
}

/**
 * Check if a specific feature flag is enabled.
 *
 * @param {FeatureFlag} flag The feature flag to check.
 * @returns {boolean} Whether the feature flag is enabled.
 */
export const isFeatureFlagEnabled = (flag: FeatureFlag): boolean => {
  return localStorage.getItem(`${storagePrefix}${flag}`) === enabledValue
}
