import React from 'react'
import SupplierAssignationOkrsTabContent from 'okrs/supplier-assignation-okrs/SupplierAssignationOkrsTabContent'
import { TabBanelProps } from 'components/Dialogs/TabBanel'
import { OKREnrichedProposal } from 'okrs/OkrsPage'

type Props = TabBanelProps & {
  rangeStart: string
  rangeEnd: string
  conciergeProposals: OKREnrichedProposal[]
}

const SupplierAssignationOkrsTab: React.FC<Props> = ({
  tabIndex,
  tabValue,
  rangeEnd,
  rangeStart,
  conciergeProposals,
  ...rest
}) => {
  return (
    <div
      role="tabpanel"
      hidden={tabValue !== tabIndex}
      id={`tabpanel-${tabIndex}`}
      {...rest}
    >
      <SupplierAssignationOkrsTabContent
        rangeStart={rangeStart}
        rangeEnd={rangeEnd}
        conciergeProposals={conciergeProposals}
      />
    </div>
  )
}

export default SupplierAssignationOkrsTab
