export class KConsole {
  static log(...params: any[]) {
    // eslint-disable-next-line no-console
    console.log(...params)
  }

  static warn(...params: any[]) {
    // eslint-disable-next-line no-console
    console.warn(...params)
  }

  static error(...params: any[]) {
    // eslint-disable-next-line no-console
    console.error(...params)
  }
}
