import {
  LinkedCustomer,
  LinkedOrganization,
  TailoredProduct
} from '@kampaay/common'
import {
  yupArray,
  yupIgnore,
  yupNumber,
  yupObject,
  YupSchema,
  yupString
} from 'services/yup'

const linkedCustomersVS: YupSchema<LinkedCustomer> = yupObject().shape({
  id: yupIgnore(),
  name: yupString(),
  email: yupString<string, LinkedCustomer>()
})

const linkedOrganizationsVS: YupSchema<LinkedOrganization> = yupObject().shape({
  id: yupNumber(),
  name: yupString(),
  responsibleFullName: yupString()
})

export const tailoredProductVS: YupSchema<TailoredProduct> = yupObject().shape({
  customersFull: yupArray(linkedCustomersVS),
  organizationsFull: yupArray(linkedOrganizationsVS),
  customers: yupArray(),
  organizations: yupArray()
})
