import React from 'react'
import KSelectInput, {
  KSelectInputProps
} from 'components/Inputs/KInputs/KSelectInput'

export type KNullableBooleanFilterProps = Omit<KSelectInputProps, 'choices'> & {
  allowFalseValues?: boolean
}

const KNullableBooleanFilter: React.FC<KNullableBooleanFilterProps> = ({
  allowFalseValues = true,
  ...rest
}) => {
  // choices can only be an array of objects, so we can't just use 'undefined'
  // to replace the false value when allowFalseValues is false
  const choices = allowFalseValues
    ? [
        { id: 'true', name: 'ra.boolean.true' },
        { id: 'false', name: 'ra.boolean.false' }
      ]
    : [{ id: 'true', name: 'ra.boolean.true' }]
  return <KSelectInput choices={choices} {...rest} />
}

export default KNullableBooleanFilter
