import React from 'react'
import { EditButton, ReferenceArrayField } from 'react-admin'
import { useParams } from 'react-router-dom'
import { useKRecordContext } from 'hooks/useKRecordContext'
import KHeader from 'components/UI/KHeader'
import OrganizationMembershipBudgetsDatagrid from 'components/FormComponents/Organization/Membership/Budgets/OrganizationMembershipBudgetsDatagrid'
import { OrganizationMembership } from '@kampaay/common'

const OrganizationMembershipExpandable: React.FC = () => {
  const record = useKRecordContext<OrganizationMembership>()
  const { id } = useParams()

  if (!record) {
    return null
  }

  return (
    <div className="px-10 pb-16 pt-5">
      <div className="flex w-full items-center justify-between gap-2">
        <KHeader title={record.userName} />
        <EditButton to={`/organization/${id}/members/${record.id}`} />
      </div>
      <div className="w-full">
        <ReferenceArrayField
          reference="organizationbudget"
          source="activeBudgetIds"
          record={record}
        >
          <OrganizationMembershipBudgetsDatagrid />
        </ReferenceArrayField>
      </div>
    </div>
  )
}

export default OrganizationMembershipExpandable
