import React from 'react'
import { useTranslate } from 'react-admin'
import { Grid } from '@mui/material'
import CustomReferenceInput from 'components/Inputs/CustomReferenceInput'
import KAutocompleteInput from 'components/Inputs/KInputs/KAutocompleteInput'
import KSelectInput from 'components/Inputs/KInputs/KSelectInput'
import { organizationMembershipRoles } from 'services/cfg/form'
import { FILTER_SOURCES, OrganizationMembership } from '@kampaay/common'
import { useKRecordContext } from 'hooks/useKRecordContext'

const OrganizationMembershipInfo: React.FC = () => {
  const translate = useTranslate()
  const record = useKRecordContext<OrganizationMembership>()

  return (
    <div className="w-full">
      <h2>{translate('tab.label.info')}</h2>
      <Grid container spacing={5} alignItems="center" wrap="nowrap">
        <Grid item xs={3}>
          <CustomReferenceInput
            entity="customers"
            queryFilter={
              !!record?.id
                ? undefined
                : { [FILTER_SOURCES.hasNoMembership.eq]: 'true' }
            }
          >
            <KAutocompleteInput
              source="userId"
              label="form.fields.customer"
              className="w-full"
              optionText="email"
              helperText={false}
            />
          </CustomReferenceInput>
        </Grid>
        <Grid item xs={2}>
          <KSelectInput source="role" choices={organizationMembershipRoles} />
        </Grid>
      </Grid>
    </div>
  )
}

export default OrganizationMembershipInfo
