import React from 'react'
import { LocationFeature } from 'services/api/entities/catalog/locationFeatures/types/internal/locationFeatures-models'
import { useKGetList } from 'hooks/useKGetList'
import { LocationFeatureCategory } from 'types/form'
import LocalizedSelectArrayInput from 'components/Inputs/Localized/LocalizedSelectArrayInput'

type Props = {
  type: LocationFeatureCategory
  source: string
}

const LocationFeatureInput: React.FC<Props> = ({ type, source }) => {
  const choices = useKGetList<LocationFeature>('locationfeatures').list.filter(
    (f) => f.category === type
  )

  return (
    <LocalizedSelectArrayInput
      source={source}
      choices={choices}
      label={`form.fields.${source}`}
      fullWidth
    />
  )
}

export default LocationFeatureInput
