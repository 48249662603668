import { styled } from '@mui/system'
import React from 'react'
import { FormDataConsumer, useTranslate } from 'react-admin'
import {
  getSelectionChoices,
  isBaseProductObsolete
} from 'resources/operations/events/utils'
import { EventItem } from 'services/api/entities/events/types/internal/event-models'
import { MainProductSelection } from 'services/api/entities/events/types/internal/selections'
import {
  FormContentProp,
  FormDataConsumerProps,
  GetSourceFn
} from 'types/common'
import LocalizedAutocompleteInput from 'components/Inputs/Localized/LocalizedAutocompleteInput'
import EventProductQuantityInput from 'components/FormComponents/EventProductQuantityInput'
import PricingSummary from 'components/FormComponents/PricingSummary/PricingSummary'
import KDivider from 'components/UI/KDivider'
import { useForm } from 'hooks/useForm'
import { getById, MainProduct, WithIdAndName } from '@kampaay/common'

const PREFIX = 'MainProductSelectionInputContent'

const classes = {
  dFlex: `${PREFIX}-dFlex`,
  justifyLeft: `${PREFIX}-justifyLeft`,
  mR: `${PREFIX}-mR`,
  banner: `${PREFIX}-banner`,
  selectionInput: `${PREFIX}-mR`
}

const Root = styled('div')({
  [`& .${classes.dFlex}`]: {
    display: 'flex',
    width: '100%'
  },
  [`& .${classes.justifyLeft}`]: {
    justifyContent: 'left'
  },
  [`& .${classes.mR}`]: {
    marginRight: '8px'
  },
  [`& .${classes.banner}`]: {
    color: '#FF0000',
    border: '3px solid #FF0000',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 8px',
    marginTop: '8px',
    maxWidth: '500px'
  },
  [`& .${classes.selectionInput}`]: {
    width: '256px',
    marginRight: '8px'
  }
})

export type MainProductSelectionInputContentProps<T extends MainProduct> = {
  label: string
  disableInputs?: boolean
  choices?: T[]
  hideQuantity?: boolean
  children?: JSX.Element
  getIsProductObsolete?: (choices: T[], scopedFormData: any) => boolean
  disableProductChoiceAfterSelections?: boolean
}

export type SelectionDetailsProps<T, K> = {
  selectedProduct?: T
  isObsolete?: boolean
  getSource?: GetSourceFn
  scopedFormData?: K
  formData?: EventItem
}

const MainProductSelectionInputContent = <T extends MainProduct>({
  source,
  label,
  disableInputs = false,
  choices = [],
  disableProductChoiceAfterSelections = false,
  hideQuantity,
  getIsProductObsolete = isBaseProductObsolete,
  children,
  ...rest
}: FormContentProp & MainProductSelectionInputContentProps<T>) => {
  const translate = useTranslate()
  const { useSetValue } = useForm<WithIdAndName>(source)
  const setName = useSetValue('name')
  return (
    <FormDataConsumer {...rest} source={source}>
      {({
        formData,
        scopedFormData,
        getSource
      }: FormDataConsumerProps<unknown, MainProductSelection>) => {
        if (!getSource) return null
        const isObsolete = getIsProductObsolete(choices, scopedFormData)

        // to prevent inconsistent data after user changes the product we can disable the input if necessary
        const disableAfterSelect =
          disableProductChoiceAfterSelections && !!scopedFormData?.id

        const disableInner = disableInputs || isObsolete
        const updatedChoices = getSelectionChoices(scopedFormData, choices)
        const selectedProduct = getById(choices, scopedFormData?.id)
        const isTailoredProduct =
          scopedFormData?.isPrivate ??
          [
            ...(selectedProduct?.customers ?? []),
            ...(selectedProduct?.organizations ?? [])
          ].length

        const pricing = scopedFormData?.pricing

        return (
          <Root>
            <div className={[classes.dFlex, classes.justifyLeft].join(' ')}>
              <div className={classes.mR}>
                <LocalizedAutocompleteInput
                  source={getSource!('id')}
                  label={label}
                  choices={updatedChoices}
                  disabled={disableInner || disableAfterSelect}
                  parse={(v: number) => {
                    const product = updatedChoices.find((l) => l.id === v)
                    setName(product?.name ?? 'N/A')
                    return v
                  }}
                />
                {/*============= SELECTION PRICING SUMMARY =============*/}
                {pricing ? (
                  <PricingSummary
                    source={getSource!('pricing')}
                    title={'form.headers.selectionPricing'}
                    labelPrice={'form.fields.revenueNoVat'}
                  />
                ) : null}
              </div>

              {!!isTailoredProduct && (
                <div className={classes.banner}>
                  {translate('form.fields.tailoredEventProductBanner')}
                </div>
              )}
            </div>
            {!hideQuantity && (
              <EventProductQuantityInput
                disabled={disableInner}
                source={source}
              />
            )}
            {!!children &&
              !!scopedFormData?.id &&
              React.cloneElement(children, {
                selectedProduct,
                isObsolete,
                getSource,
                scopedFormData,
                formData
              })}

            <KDivider
              style={{
                width: '20%'
              }}
            />
          </Root>
        )
      }}
    </FormDataConsumer>
  )
}

export default MainProductSelectionInputContent
