import { HistoryEdu } from '@mui/icons-material'
import DraftEventList from 'resources/operations/eventsDraft/DrafEventtList'
import EventEdit from 'resources/operations/events/EventEdit'
import EventCreate from 'resources/operations/events/EventCreate'

const draftEventsIndex = {
  icon: HistoryEdu,
  list: DraftEventList,
  edit: EventEdit,
  create: EventCreate
}

export default draftEventsIndex
