import { SupervisedUserCircle } from '@mui/icons-material'
import KampaayerEdit from 'resources/crm/kampaayers/KampaayerEdit'
import KampaayerCreate from 'resources/crm/kampaayers/KampaayerCreate'
import KampaayerList from 'resources/crm/kampaayers/KampaayerList'

const kampaayersIndex = {
  icon: SupervisedUserCircle,
  edit: KampaayerEdit,
  create: KampaayerCreate,
  list: KampaayerList
}

export default kampaayersIndex
