import { APICfg } from 'services/api/api'
import { convertEmptyStringToUndefined } from 'services/utils'
import { getMonthAndYearFromDate } from '@kampaay/common'
import { ProductServiceName } from 'types/common'
import {
  TransactionProfessionalServiceToAPI,
  TransactionProfessionalServiceGroupToAPI,
  TransactionServiceToAPI,
  TransactionToAPI
} from 'services/api/entities/accounting/transaction/types/api/request-models'
import {
  APITransaction,
  APITransactionProfessionalService,
  APITransactionProfessionalServiceGroup,
  APITransactionServiceBase,
  APITransactionServices
} from 'services/api/entities/accounting/transaction/types/api/response-models'
import {
  Transaction,
  TransactionProfessionalService,
  TransactionProfessionalServiceGroup,
  TransactionService
} from 'services/api/entities/accounting/transaction/types/internal/transaction-models'
import { PRODUCT_SERVICES_NAMES } from 'services/cfg'
import { customizationWrite } from 'services/api/entities/events/write'
import { customizationParse } from 'services/api/entities/events/parse'
import omit from 'lodash/omit'

// ============
// PARSE
// ============

const serviceBaseParse = <T extends APITransactionServiceBase>(
  s: T,
  serviceName: string
) => ({
  ...s,
  supplier: s?.supplier?.name,
  serviceName
})

const namedServiceParse =
  (
    transactionServicesMap: Omit<APITransactionServices, 'professionalService'>
  ) =>
  (serviceName: ProductServiceName): TransactionService => {
    const currentService = transactionServicesMap[serviceName]

    return {
      ...serviceBaseParse(currentService, serviceName),
      customizations:
        currentService?.customizations.map(customizationParse) ?? []
    }
  }

const professionalServiceParse = ({
  sectionId,
  sectionName,
  specialType: _specialType,
  supplier,
  ...rest
}: APITransactionProfessionalService): TransactionProfessionalService => ({
  ...serviceBaseParse(rest, sectionName),
  section: sectionId,
  supplier: supplier?.name
})

const parseProfessionalWrapper = (
  w?: APITransactionProfessionalServiceGroup
): TransactionProfessionalServiceGroup => ({
  customizations: w?.customizations.map(customizationParse) ?? [],
  services: w?.services.map(professionalServiceParse) ?? []
})

const transactionParse = ({
  eventDate,
  professionalServiceGroup,
  eventCustomizations,
  geoAreaPreset,
  ...rest
}: APITransaction): Transaction => {
  return {
    ...omit(rest, PRODUCT_SERVICES_NAMES),
    eventDate,
    services: PRODUCT_SERVICES_NAMES.map(namedServiceParse(rest)),
    professionalService: parseProfessionalWrapper(professionalServiceGroup),
    monthYearDate: getMonthAndYearFromDate(eventDate),
    customizations: eventCustomizations.map(customizationParse),
    geoAreaPreset: geoAreaPreset?.id,
    geoAreaPresetFull: geoAreaPreset
  }
}

// ============
// WRITE
// ============

const namedServiceWrite = async ({
  deltaCostNoVat,
  costVatPerc,
  customizations,
  serviceName
}: TransactionService): Promise<TransactionServiceToAPI> => ({
  deltaCostNoVat,
  costVatPerc,
  serviceName,
  customizations: await Promise.all(customizations.map(customizationWrite))
})

const bundleApiServices = async (
  services: TransactionService[]
): Promise<Record<ProductServiceName, TransactionServiceToAPI>> => {
  const mappedServices = await Promise.all(services.map(namedServiceWrite))

  return PRODUCT_SERVICES_NAMES.reduce(
    (acc, service) => ({
      ...acc,
      [service]: mappedServices.find((s) => s.serviceName === service)
    }),
    Object.create(null)
  )
}

const professionalServiceWrite = ({
  deltaCostNoVat,
  costVatPerc,
  serviceName,
  section
}: TransactionProfessionalService): TransactionProfessionalServiceToAPI => ({
  deltaCostNoVat,
  costVatPerc,
  serviceName,
  section: section!
})

const professionalWrapperWrite = async (
  w: TransactionProfessionalServiceGroup
): Promise<TransactionProfessionalServiceGroupToAPI> => ({
  customizations: await Promise.all(w.customizations.map(customizationWrite)),
  services: w.services.map(professionalServiceWrite)
})

const transactionWrite = async ({
  services,
  invoiceStatus,
  customizations,
  paymentStatus,
  accountingStatus,
  invoiceNumber,
  invoiceExpiryDate,
  checkoutQuestionResponses,
  professionalService,
  discount
}: Transaction): Promise<TransactionToAPI> => {
  const servicesToApi = await bundleApiServices(services)

  return {
    paymentStatus,
    invoiceStatus,
    eventCustomizations: await Promise.all(
      customizations.map(customizationWrite)
    ),
    accountingStatus,
    invoiceExpiryDate,
    ...servicesToApi,
    professionalServiceGroup: await professionalWrapperWrite(
      professionalService
    ),
    invoiceNumber: convertEmptyStringToUndefined(invoiceNumber),
    checkoutQuestionResponses,
    discount
  }
}

// ============
// CFG
// ============

const apiCfg: APICfg = {
  endpoint: 'event-account',
  UPDATE_METHOD: 'PATCH' as const,
  parse: transactionParse,
  write: transactionWrite
}

export default apiCfg
