import type { ISODate } from '../../../../types'
import { createMockBuilder } from '../../../../utils'
import type { APIProposal } from '../types'

const buildBaseMock = (): APIProposal => ({
  id: 0,
  isPublished: false,
  name: '...at least write something, DUH',
  status: 'pending',
  validUntil: '2023-05-18T20:44:06.940Z' as ISODate, // TODO: implement branding
  itemGroups: [],
  documents: [],
  guid: '123abc',
  proposalHistory: 0,
  isFinal: false,
  hasSupplierAssigned: false
})

export const buildApiProposalMock = createMockBuilder(buildBaseMock)
