import { createMockBuilder } from '../../../../utils'
import type { SupplierToAPI } from '../types'

const buildBaseMock = (): SupplierToAPI => ({
  phone: '',
  phoneVerified: false,
  email: '',
  emailVerified: false,
  organizationNumber: '',
  status: 'active',
  name: '',
  country: 'IT',
  headQuarters: '',
  responsibleFullName: '',
  id: 1,
  drinks: [],
  foods: [],
  locations: [],
  musics: [],
  rents: [],
  documents: [],
  images: [],
  description: '',
  internalDescription: '',
  websiteUrl: '',
  hasAccount: true,
  virtualExperiences: [],
  physicalExperiences: [],
  professionalFigures: [],
  services: [],
  businessName: 'Joe Vannee Kotzow',
  proposalItems: [],
  privacyPolicyAccepted: false,
  usageOfImagesAndTextsAccepted: false,
  agreementInForce: 'none',
  rebateAndCommission: 0,
  tags: []
})

export const buildSupplierToAPIMock = createMockBuilder(buildBaseMock)
