import { dateToDateOnly, dateToISODate } from '../../../utils'
import type { MultipleFileUploader } from '../../types'
import type { ConciergeRequest } from '../concierge-request'
import type { ConciergeRequestToAPI } from '../concierge-request/types/api/request-models'
import type { Concierge, ConciergeToAPI } from './types'

export const conciergeRequestWrite = (
  request: ConciergeRequest
): ConciergeRequestToAPI => ({
  ...request,
  formPayload: !!request.formPayload
    ? {
        ...request.formPayload,
        when: {
          ...request.formPayload.when,
          hasDate: {
            ...request.formPayload.when?.hasDate,
            startDate: request.formPayload?.when?.hasDate?.startDate
              ? dateToDateOnly(request.formPayload.when.hasDate.startDate)
              : undefined,
            endDate: request.formPayload?.when?.hasDate?.endDate
              ? dateToDateOnly(request.formPayload.when.hasDate.endDate)
              : undefined
          }
        },
        proposalDeadline: request.formPayload?.proposalDeadline
          ? dateToDateOnly(request.formPayload.proposalDeadline)
          : undefined
      }
    : undefined
})

export const conciergeWrite =
  (uploadDocuments: MultipleFileUploader) =>
  async (data: Concierge): Promise<ConciergeToAPI> => {
    const kampaayerDocs = await uploadDocuments(data.kampaayerDocs ?? [])
    const {
      expirationStatus: _expirationStatus,
      hasSupplierAssigned: _hasSupplierAssigned,
      originalRequest: _originalRequest,
      request: _request,
      ...rest
    } = data

    return {
      ...rest,
      startDate: data.startDate ? dateToISODate(data.startDate) : undefined,
      endDate: data.endDate ? dateToISODate(data.endDate) : undefined,
      proposalHistories: data.proposalHistories,
      eventCategory: data.eventCategory!,
      kampaayerDocs,
      currentRequest: data.request
        ? conciergeRequestWrite(data.request)
        : undefined
    }
  }
