import React from 'react'
import OptionedSectionFormContent from 'components/Forms/OptionedSectionFormContent'
import SectionWrapper from 'components/UI/SectionWrapper'
import { KSimpleForm } from 'components/Forms/KForms/KSimpleForm'
import KEdit from 'components/Forms/KEdit/KEdit'

const FoodSectionEdit: React.FC = () => {
  return (
    <SectionWrapper title={'resources.virtualexperiencessections.editSection'}>
      <KEdit>
        <KSimpleForm>
          <OptionedSectionFormContent />
        </KSimpleForm>
      </KEdit>
    </SectionWrapper>
  )
}

export default FoodSectionEdit
