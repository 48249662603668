import { Help } from '@mui/icons-material'
import RuleCreate from 'resources/catalog/rule/RuleCreate'
import RuleEdit from 'resources/catalog/rule/RuleEdit'
import RuleList from 'resources/catalog/rule/RuleList'

const ruleCatalogIndex = {
  icon: Help,
  list: RuleList,
  edit: RuleEdit,
  create: RuleCreate
}

export default ruleCatalogIndex
