import { QueryFilterObject } from 'services/queryEncoding/types'
import { entries } from 'services/utils'
import { removeMillisecondsFromIso } from '@kampaay/common'
import { addDays } from 'date-fns'
import { FILTER_SOURCES, FilterOperator, FilterSource } from '@kampaay/common'

/**
 * Joins params with a comma
 * @param param
 */
const encodeStringArray = (param: (string | number)[]) => param.join(',')

type DateFilterSource = FilterSource<
  'startDate' | 'endDate' | 'eventDate',
  FilterOperator
>

/**
 * Array of filters containing not string values
 */
export const NON_STRING_FILTER_SOURCES: DateFilterSource[] = [
  FILTER_SOURCES.startDate.lt,
  FILTER_SOURCES.startDate.gte,
  FILTER_SOURCES.eventDate.gte,
  FILTER_SOURCES.eventDate.lt
]

/**
 * Array containing filters representing end dates
 */
export const DATE_RANGE_END_FILTER_SOURCES: DateFilterSource[] = [
  FILTER_SOURCES.startDate.lt,
  FILTER_SOURCES.endDate.lt
]

/**
 * Add one day to the filter end date and remove milliseconds
 * @param date
 * @param source
 */
const addOneToRangeEndDate = (date: string, source: DateFilterSource) =>
  DATE_RANGE_END_FILTER_SOURCES.includes(source)
    ? removeMillisecondsFromIso(addDays(new Date(date), 1).toISOString())
    : date

/**
 * If the filter type is a string converts the string from a to 'a'
 * @param value
 * @param source
 */
export const turnStringsIntoStrings = (
  value: string,
  source: DateFilterSource
) => (NON_STRING_FILTER_SOURCES.includes(source) ? value : `'${value}'`)

/**
 * @param v: object of filters to map
 * @param s: filter source
 * @returns the processed filter value
 */
const processFilterValue = (
  v: string | (string | number)[],
  s: DateFilterSource
): string => {
  const stringifiedValue = Array.isArray(v)
    ? encodeStringArray(v)
    : turnStringsIntoStrings(v, s)
  return addOneToRangeEndDate(stringifiedValue, s)
}

/**
 * @param  q object of query filters
 * @returns the query string for the filters
 */
export const encodeFilters = (q: QueryFilterObject): string => {
  return entries(q as Record<DateFilterSource, string>)
    .map(([k, v]) => k + processFilterValue(v, k))
    .join('~and~')
}
