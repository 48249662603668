import { APICfg } from 'services/api/api'
import { FILTER_SOURCES } from '@kampaay/common'
import { eventWrite } from 'services/api/entities/events/write'
import { eventParse } from 'services/api/entities/events/parse'
import { eventGetOne } from 'services/api/entities/events/getOne'
import { eventUpdate } from 'services/api/entities/events/update'

const apiCfg = {
  endpoint: 'event',
  query: { [FILTER_SOURCES.status.eq]: 'draft' },
  parse: eventParse,
  write: eventWrite,
  getOne: eventGetOne,
  update: eventUpdate
} as APICfg

export default apiCfg
