/// <reference types="google.maps" />
import { countrySwitchStrict } from '../geo-management'

export const DEFAULT_MAP_CENTER: google.maps.LatLngLiteral =
  countrySwitchStrict({
    IT: {
      lat: 45.462787,
      lng: 9.188551
    },
    SE: {
      lat: 59.326191,
      lng: 18.067396
    }
  })

export const DEFAULT_MAP_OPTIONS: google.maps.MapOptions = {
  zoom: 13,
  disableDefaultUI: true,
  zoomControl: true,
  maxZoom: 20,
  minZoom: 6,
  center: DEFAULT_MAP_CENTER
}

/**
 * Returns the north-east and south-west coordinates of the given google maps bounds
 * to build the API filter querystring
 *
 * @param {google.maps.LatLngBounds | undefined} bounds - The bounds of the map.
 * @returns {number[] | undefined} An array containing the longitude and latitude of the north-east and south-west corners of the bounds, or undefined if the bounds are not defined or the corners could not be determined.
 */
export const convertBoundsToMapFilter = (
  bounds: google.maps.LatLngBounds | undefined
): number[] | undefined => {
  const ne = bounds?.getNorthEast()
  const sw = bounds?.getSouthWest()

  if (!ne || !sw) return
  return [ne.lng(), ne.lat(), sw.lng(), sw.lat()]
}
