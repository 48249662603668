import { Tooltip } from '@mui/material'
import { useListContext } from 'ra-core'
import React from 'react'
import { ReferenceArrayField } from 'react-admin'
import { APIEntity } from 'services/api/entities'
import get from 'lodash/get'
import { useKRecordContext } from 'hooks/useKRecordContext'

const Renderer = ({
  format,
  source
}: Pick<Props<any>, 'format' | 'source'>) => {
  const list = useListContext<any>()

  const formatItem = format ?? ((record) => record)

  if (!list.data?.length) {
    return <></>
  }

  if (list.data.length === 1) {
    return <div>{formatItem(list.data[0])}</div>
  }

  const record = useKRecordContext()
  const ids = get(record, source) as (string | number)[]
  const sortedItems = ids.map((id) =>
    list.data.find((item: any) => item.id === id)
  )

  const firstItem = formatItem(sortedItems[0])
  const tooltipContent = (
    <>
      {sortedItems.slice(1).map((item) => (
        <div>{formatItem(item)}</div>
      ))}
    </>
  )

  return (
    <Tooltip title={tooltipContent} arrow>
      <div className="overflow-hidden text-ellipsis whitespace-nowrap">
        {firstItem}, ...
      </div>
    </Tooltip>
  )
}

type Props<T> = {
  label: string
  source: string
  reference: APIEntity
  format?: (record: T) => string
}

const TooltipReferenceArrayField = <T,>(props: Props<T>) => {
  return (
    <ReferenceArrayField {...props}>
      <Renderer format={props.format} source={props.source} />
    </ReferenceArrayField>
  )
}

export default TooltipReferenceArrayField
