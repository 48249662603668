import { Card } from '@mui/material'
import { styled } from '@mui/material/styles'
import React, { useState } from 'react'
import { Button, useDataProvider, useGetOne, useTranslate } from 'react-admin'
import { KConsole } from 'services/console'
import { SERVICE_CFG_MOCK } from 'services/tsMocks'

const PREFIX = 'ConfigurationPanel'

const classes = {
  dFlex: `${PREFIX}-dFlex`,
  text: `${PREFIX}-text`,
  buttonContainer: `${PREFIX}-buttonContainer`,
  button: `${PREFIX}-button`
}

const StyledCard = styled(Card)({
  [`&.${classes.dFlex}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '24px',
    marginTop: '24px'
  },
  [`& .${classes.text}`]: {
    flexGrow: 1
  },
  [`& .${classes.buttonContainer}`]: {
    minWidth: '160px',
    display: 'flex'
  },
  [`& .${classes.button}`]: {
    margin: 'auto',
    padding: '8px 12px'
  }
})

const ConfigurationPanel: React.FC = () => {
  const translate = useTranslate()
  const [isConfigMissing, setIsConfigMissing] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const dp = useDataProvider()

  const createServiceConfig = async () => {
    try {
      setIsLoading(true)
      await dp.create('servicesconfig', {
        data: SERVICE_CFG_MOCK
      })
    } catch (err) {
      KConsole.error(err)
    } finally {
      setIsLoading(false)
    }
  }
  const { isError } = useGetOne('servicesconfig', {
    id: 'servicesconfig'
  })
  if (isError) {
    setIsConfigMissing(true)
  }

  return (
    <StyledCard className={classes.dFlex}>
      {isConfigMissing ? (
        <React.Fragment>
          <div className={classes.text}>
            {translate('dashboard.message.appConfigMissing')}
          </div>
          <div className={classes.buttonContainer}>
            <Button
              label="Push me"
              variant="contained"
              className={classes.button}
              disabled={isLoading}
              onClick={createServiceConfig}
            />
          </div>
        </React.Fragment>
      ) : (
        <div className={classes.text}>
          {translate('dashboard.message.appConfigOk')}
        </div>
      )}
    </StyledCard>
  )
}

export default ConfigurationPanel
