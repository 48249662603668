import { getId } from '../../../../utils'
import { upsellingParse } from '../common'
import { logisticParse } from '../logistics'
import type { APIFoodUpselling, FoodUpselling } from './types'

export const parseFoodUpselling = (res: APIFoodUpselling): FoodUpselling => {
  const logistics = res.logistics?.map(getId) ?? []
  const logisticsFull = res.logistics?.map(logisticParse) ?? []

  return {
    ...upsellingParse(res),
    logistics,
    logisticsFull
  }
}
