import React from 'react'
import ProductCommonTailoringInput from 'resources/crm/clients/organization/tailoring/components/productCommon/ProductCommonTailoringInput'
import ServiceTailoringsEdit from 'resources/crm/clients/organization/tailoring/ServiceTailoringsEdit'

const RentTailoringsEdit: React.FC = () => {
  return (
    <ServiceTailoringsEdit
      service="rents"
      productEntity="rents"
      tailoringsInput={ProductCommonTailoringInput}
    />
  )
}

export default RentTailoringsEdit
