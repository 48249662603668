import React from 'react'
import { useFormContext } from 'react-hook-form'
import { FILTER_SOURCES } from '@kampaay/common'
import { CheckboxGroupInput } from 'react-admin'
import { KFilterFieldProps } from 'services/filters/types'

export const VISIBLE_TO_ALL_CHOICES = [{ name: 'Visible to all', id: 'true' }]

export const TailoredVisibleToAllFilter: React.FC<KFilterFieldProps> = ({
  source,
  label
}) => {
  const { setValue } = useFormContext()

  /**
   * When the toggle visibleToAll changes we reset the tailored client and
   * organization filter picklists
   */
  const resetClientAndOrganizationFilter = () => {
    setValue(FILTER_SOURCES.customerId.eq, '')
    setValue(FILTER_SOURCES.organizationId.eq, '')
  }

  return (
    <CheckboxGroupInput
      source={source}
      label={label}
      choices={VISIBLE_TO_ALL_CHOICES}
      onChange={resetClientAndOrganizationFilter}
    />
  )
}
