import { createMockBuilder } from '../../../../utils'
import type { APIDoc } from '../types'

const buildBaseMock = (): APIDoc => ({
  id: '1',
  url: 'https://www.something.com',
  fileName: 'Image'
})

export const buildApiDocMock = createMockBuilder(buildBaseMock)
