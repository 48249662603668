import React from 'react'
import SectionWrapper from 'components/UI/SectionWrapper'
import LocationFormContent from 'resources/catalog/location/product/LocationFormContent'
import { KSimpleForm } from 'components/Forms/KForms/KSimpleForm'
import KEdit from 'components/Forms/KEdit/KEdit'

const LocationEdit: React.FC = () => {
  return (
    <SectionWrapper>
      <KEdit>
        <KSimpleForm>
          <LocationFormContent />
        </KSimpleForm>
      </KEdit>
    </SectionWrapper>
  )
}

export default LocationEdit
