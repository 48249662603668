import { ListAlt } from '@mui/icons-material'
import RentSectionCreate from 'resources/catalog/rent/sections/RentSectionCreate'
import RentSectionEdit from 'resources/catalog/rent/sections/RentSectionEdit'
import RentSectionList from 'resources/catalog/rent/sections/RentSectionList'

const musicSectionIndex = {
  icon: ListAlt,
  list: RentSectionList,
  edit: RentSectionEdit,
  create: RentSectionCreate
}

export default musicSectionIndex
