import React from 'react'
import { RadioButtonGroupInput } from 'react-admin'
import useFacets from 'hooks/useFacets'
import { SingleSelectableFacetCategory } from '@kampaay/common'

type ToggleFacetCategory = Extract<SingleSelectableFacetCategory, 'pricing'>

type Props = {
  facetCategory: ToggleFacetCategory
  source: string
  initialValue?: string
}

const FacetToggle: React.FC<Props> = ({
  facetCategory,
  source,
  initialValue
}) => {
  const facets = useFacets(facetCategory)

  const defaultValue = initialValue
    ? facets.find((f) => f.code.includes(initialValue))?.id
    : undefined

  if (!facets.length) return null

  return (
    <RadioButtonGroupInput
      label={`form.fields.${source}`}
      source={source}
      optionText="description"
      choices={facets}
      parse={(v: string) => Number(v)}
      format={(v?: number) => v?.toString()} //TODO: remove once every upselling has a pricing type
      defaultValue={defaultValue}
    />
  )
}

export default FacetToggle
