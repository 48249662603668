import { styled } from '@mui/material/styles'
import { useForm } from 'hooks/useForm'
import { useTranslate } from 'ra-core'
import React from 'react'
import { RAFormFC } from 'types/common'
import KAccordion from 'components/UI/Accordions/KAccordion/KAccordion'
import { WithSectionAndStatus } from 'components/Inputs/CustomReferenceInput'
import ClientAutocompleteArrayInput from 'components/Inputs/Tailored/ClientAutocompleteArrayInput'
import OrganizationAutocompleteArrayInput from 'components/Inputs/Tailored/OrganizationAutocompleteArrayInput'

const PREFIX = 'TailoredInput'

const classes = {
  redText: `${PREFIX}-redText`
}

const StyledKAccordion = styled(KAccordion)({
  [`& .${classes.redText}`]: {
    color: '#FF0000'
  }
})

// this type is necessary because the professionals have only one section in our model
type WithCustomersAndOrganizations = {
  customers: number[]
  organizations: number[]
}
type Props = {
  validationMessage?: string
  disableValidation?: boolean
  hideClient?: boolean
}

const TailoredInput: RAFormFC<Props> = ({
  source,
  validationMessage = '',
  disableValidation = false,
  hideClient = false
}) => {
  const translate = useTranslate()
  const { useGetValue } = useForm<
    WithCustomersAndOrganizations & WithSectionAndStatus
  >(source)
  const sections = useGetValue('sections')
  const isInSection = !!sections?.length

  return (
    <StyledKAccordion accordionTitle="Only For">
      {isInSection && !disableValidation ? (
        <u className={classes.redText}>{translate(validationMessage)}</u>
      ) : (
        <>
          <OrganizationAutocompleteArrayInput />
          {!hideClient && <ClientAutocompleteArrayInput />}
        </>
      )}
    </StyledKAccordion>
  )
}

export default TailoredInput
