import KAccordion from 'components/UI/Accordions/KAccordion/KAccordion'
import KToolTip from 'components/UI/KTooltip'
import { useForm } from 'hooks/useForm'
import React from 'react'
import { SimpleFormIterator } from 'react-admin'
import { OrganizationBase } from 'services/api/entities/crm/organizations/types/internal/organization-model'
import { RAFormFC } from 'types/common'
import KTextInput from 'components/Inputs/KInputs/KTextInput/KTextInput'
import KArrayInput from 'components/Inputs/KInputs/KArrayInput'

const OrganizationEmailInput: RAFormFC = ({ source }) => {
  return (
    <KToolTip title="tooltip.hint.organizationMailingList" placement="top">
      <KTextInput source={source!} label="Email" />
    </KToolTip>
  )
}

const MailingListSection: RAFormFC = ({ source }) => {
  const { getSource } = useForm<OrganizationBase>(source)

  return (
    <KAccordion accordionTitle="form.headers.confirmationEmails">
      <KArrayInput source={getSource('mailingList')}>
        <SimpleFormIterator disableReordering>
          <OrganizationEmailInput />
        </SimpleFormIterator>
      </KArrayInput>
    </KAccordion>
  )
}

export default MailingListSection
