import { CheckCircle, PlaylistAddCheck } from '@mui/icons-material'
import { useDataProvider, useListContext, useNotify } from 'ra-core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button } from 'react-admin'
import { getId, WithIdAndName } from '@kampaay/common'
import DraggableList from 'components/Lists/DraggableList/DraggableList'

type Props = {
  children?: React.ReactElement
}

const ReorderableListContent: React.FC<Props> = ({ children }) => {
  const dp = useDataProvider()
  const { data, resource, refetch } = useListContext<WithIdAndName>()
  const notify = useNotify()

  const [showDraggable, setShowDraggable] = useState(false)
  const [orderedItems, setOrderedItems] = useState<WithIdAndName[]>(data)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    setOrderedItems(data)
  }, [setOrderedItems, data])

  const handleToggle = useCallback(async () => {
    if (showDraggable) {
      try {
        setIsLoading(true)
        await dp.reorder(resource, { data: orderedItems.map(getId) })
        await refetch()

        setShowDraggable(!showDraggable)
      } catch {
        notify('notification.generic.error', {
          type: 'error'
        })
      } finally {
        setIsLoading(false)
      }
    } else {
      setShowDraggable(!showDraggable)
    }
  }, [showDraggable, setShowDraggable, dp, orderedItems, resource, refetch])

  const buttonLabel = useMemo(
    () => (showDraggable ? 'Save' : 'Reorder'),
    [showDraggable]
  )

  return (
    <>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'end'
        }}
      >
        <Button
          label={buttonLabel}
          style={{ margin: '8px' }}
          disabled={isLoading}
          onClick={handleToggle}
        >
          {showDraggable ? <CheckCircle /> : <PlaylistAddCheck />}
        </Button>
      </div>
      {showDraggable ? (
        <DraggableList records={orderedItems} onReorder={setOrderedItems} />
      ) : (
        children
      )}
    </>
  )
}
export default ReorderableListContent
