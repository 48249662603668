import React from 'react'
import { RAFormFC } from 'types/common'
import CustomReferenceInput from 'components/Inputs/CustomReferenceInput'
import LocalizedAutoCompleteArrayInput from 'components/Inputs/Localized/LocalizedAutoCompleteArrayInput'

const GeoAreaPresetInput: RAFormFC = ({ source }) => (
  <CustomReferenceInput entity={'geoareapresets'}>
    <LocalizedAutoCompleteArrayInput
      source={source}
      optionText="name"
      label="form.fields.geoareaPresets"
      style={{ width: '300px' }}
    />
  </CustomReferenceInput>
)

export default GeoAreaPresetInput
