import { upsellingParse, upsellingWrite } from '@kampaay/common'
import { APICfg } from 'services/api/api'
import { uploadOrKeepMultipleImgs } from 'services/utils/images'

const apiCfg: APICfg = {
  endpoint: 'physicalexperiencesupselling',
  parse: upsellingParse,
  write: upsellingWrite(uploadOrKeepMultipleImgs)
}

export default apiCfg
