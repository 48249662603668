import type {
  Facet,
  FacetFilterGroup,
  MultiSelectableFacetsCategory,
  SingleSelectableFacetCategory
} from '../../api'
import { isTruthy } from '../misc'
import { getId } from '../object'

/**
 * Finds a single facet from specified `filterGroup` and `category`.
 * In case of `filterGroup` being present, the `category` is ignored.
 * @param facets parsed facets
 * @param category category to search for
 * @param filterGroup filterGroup to search for
 *
 * @returns the facet id or null if not found
 */
export const findSingleFacet = (
  facets: Facet[],
  category: SingleSelectableFacetCategory,
  filterGroup?: FacetFilterGroup
) =>
  facets.find((f) =>
    filterGroup ? f.filterGroup === filterGroup : f.category === category
  )?.id ?? null

/**
 * Finds multiple facets from specified `filterGroup` and `category`.
 * In case of `filterGroup` being present, the `category` is ignored.
 * @param facets parsed facets
 * @param category category to search for
 * @param filterGroup filterGroup to search for
 *
 * @returns the facet ids or [] if not found
 */
export const findMultipleFacets = (
  facets: Facet[],
  category: MultiSelectableFacetsCategory,
  filterGroup?: FacetFilterGroup
) =>
  facets
    .filter((f) =>
      filterGroup ? f.filterGroup === filterGroup : f.category === category
    )
    .map(getId)
/**
 * Bundle facets ids into an array filtering out null values.
 * @param facetIds facet ids to bundle
 */
export const bundleFacetIds = (...facetIds: (number | null)[]) => {
  return facetIds.filter(isTruthy) as number[]
}
