import React from 'react'
import { styled } from '@mui/material/styles'
import {
  ExportButton,
  Labeled,
  SimpleFormIterator,
  useGetOne,
  useTranslate
} from 'react-admin'
import { exportRecord } from 'services/csv/exporter'
import KHeader from 'components/UI/KHeader'
import { Divider } from '@mui/material'
import LabeledNumberField from 'components/Fields/LabeledFields/LabeledNumberField'
import LabeledDateField from 'components/Fields/LabeledFields/LabeledDateField'
import LabeledTextField from 'components/Fields/LabeledFields/LabeledTextField'
import { Transaction } from 'services/api/entities/accounting/transaction/types/internal/transaction-models'
import { useKResourceContext } from 'hooks/useKResourceContext'
import QuestionRepliesAccordion from 'resources/operations/events/EventsComponents/CheckoutQuestionReply/QuestionRepliesAccordion'
import { someRepliesIsFilled } from 'resources/operations/events/utils'
import ConfirmedTransactionFields from 'components/FormComponents/Transaction/ConfirmedTransactionFields'
import TransactionRecapBillingInfoFields from 'components/FormComponents/Transaction/TransactionRecapBillingInfoFields'
import KDivider from 'components/UI/KDivider'
import { useForm } from 'hooks/useForm'
import { useKRecordContext } from 'hooks/useKRecordContext'
import { RAFormFC } from 'types/common'
import { useTransactionRecapInfo } from 'hooks/useTransactionRecapInfo'
import TransactionServiceAccordion from 'components/FormComponents/Transaction/TransactionServiceAccordion'
import TransactionCustomizationArrayInput from 'components/FormComponents/Transaction/TransactionCustomizationArrayInput'
import KAccordion from 'components/UI/Accordions/KAccordion/KAccordion'
import GeoareaEventAndTransactionField from 'components/FormComponents/Event/GeoareaEventAndTransactionField'
import KArrayInput from 'components/Inputs/KInputs/KArrayInput'
import { Proposal } from '@kampaay/common'

const Root = styled('div')(() => ({
  width: '100%',
  '& .exportPadding': {
    padding: '10px'
  },
  '& .d-flex-column': {
    display: 'flex',
    flexDirection: 'column'
  },
  '& .w-100': {
    width: '100%'
  }
}))

const TransactionFormContent: RAFormFC = ({ source }) => {
  const translate = useTranslate()
  const resource = useKResourceContext<'transaction'>()

  const record = useKRecordContext<Transaction>()
  const isEventConfirmed = record?.eventStatus === 'confirmed'
  const { useGetValue } = useForm<Transaction>(source)
  const customizations = useGetValue('customizations')
  const discount = useGetValue('discount')
  const formId = useGetValue('id')
  const customerBusinessCountry = useGetValue('customerBusinessCountry')
  const professionalService = useGetValue('professionalService')
  const hasProfessionalServices = !!professionalService.services.length
  const hasProfessionalsCustomizations =
    !!professionalService.customizations?.length

  const customerOrganizationId = record && record.customerOrganizationId
  const checkoutQuestionResponses = record && record.checkoutQuestionResponses
  const showReplies =
    customerOrganizationId && checkoutQuestionResponses?.length
  const expandRepliesAccordion =
    checkoutQuestionResponses && someRepliesIsFilled(checkoutQuestionResponses)

  const { data: proposal } = useGetOne<Proposal>(
    'proposal',
    {
      id: record?.proposalId!
    },
    {
      enabled: !!record?.proposalId
    }
  )

  const { updateServiceBillingInfo, transactionRecapInfo } =
    useTransactionRecapInfo(
      professionalService.customizations,
      customizations,
      customerBusinessCountry,
      proposal,
      discount
    )

  return (
    <Root>
      <div className="exportPadding">
        <ExportButton onClick={() => exportRecord(resource, record!)} />
      </div>
      <div className="d-flex-column w-100">
        <KHeader title="General" headingLevel="h1" />
        <Divider />
        <LabeledNumberField source="id" label="form.fields.eventId" />

        <LabeledTextField
          source="eventStatus"
          label="form.fields.eventStatus"
        />
        {isEventConfirmed && <ConfirmedTransactionFields />}

        <LabeledDateField
          source="confirmedAt"
          label="form.fields.confirmedAt"
        />
        <LabeledDateField source="eventDate" label="form.fields.eventDate" />
        <LabeledTextField
          source="monthYearDate"
          label="form.fields.monthYearDate"
        />
        <LabeledTextField source="customerName" label="form.fields.company" />
        <LabeledTextField
          source="customerEmail"
          label="form.fields.clientEmail"
        />

        <Labeled label="form.fields.geoareaPreset">
          <GeoareaEventAndTransactionField />
        </Labeled>

        <LabeledTextField
          source="eventAddress"
          label="form.fields.eventAddress"
        />
        <LabeledTextField
          source="customerAddress"
          label="form.fields.clientAddress"
        />
        <LabeledTextField
          label="form.fields.businessCountry"
          source="customerBusinessCountry"
        />
        <LabeledTextField
          label="form.fields.paymentMethod"
          source="paymentMethod"
        />
        <LabeledTextField source="customerVat" label="form.fields.clientVat" />
        <LabeledTextField
          source="customerFiscalCode"
          label="form.fields.clientFiscalCode"
        />
        <LabeledTextField source="customerSdi" label="form.fields.clientSdi" />
        <LabeledTextField
          source="customerPhone"
          label="form.fields.clientPhone"
        />
        <LabeledTextField source="customerPec" label="form.fields.clientPec" />

        {showReplies ? (
          <>
            <KDivider />
            <QuestionRepliesAccordion
              defaultExpanded={expandRepliesAccordion}
            />
          </>
        ) : null}

        <KHeader title="Event Totals" headingLevel="h1" />
        <Divider />

        <TransactionRecapBillingInfoFields
          transactionRecapInfo={transactionRecapInfo}
          formId={formId}
        />

        <LabeledNumberField
          source="discount"
          label="Event only discount (old coupon)"
          roundTwoDecimals
        />

        {!!customizations?.length && (
          <KAccordion
            accordionTitle={translate('form.headers.transactionCustomizations')}
          >
            <TransactionCustomizationArrayInput />
          </KAccordion>
        )}

        <KDivider title="Services" headingLevel="h1" />

        <KArrayInput source="services" fullWidth>
          <SimpleFormIterator
            disableRemove
            disableAdd
            disableReordering
            fullWidth
          >
            <TransactionServiceAccordion
              formId={formId}
              updateServiceBillingInfo={updateServiceBillingInfo}
            />
          </SimpleFormIterator>
        </KArrayInput>

        {/* PROFESSIONAL SERVICES */}
        {hasProfessionalServices && (
          <KAccordion
            accordionTitle="form.headers.transactionProfessionalService"
            defaultExpanded
          >
            {hasProfessionalsCustomizations && (
              <TransactionCustomizationArrayInput source="professionalService" />
            )}

            <KArrayInput source="professionalService.services" fullWidth>
              <SimpleFormIterator
                disableRemove
                disableAdd
                disableReordering
                fullWidth
              >
                <TransactionServiceAccordion
                  formId={formId}
                  updateServiceBillingInfo={updateServiceBillingInfo}
                />
              </SimpleFormIterator>
            </KArrayInput>
          </KAccordion>
        )}
      </div>
    </Root>
  )
}

export default TransactionFormContent
