import { CSVEntityDescriptor } from 'services/csv/types'
import { formatMainProduct } from 'services/csv/entities/catalog/common/mainProduct'
import { parseCsvFields, ProfessionalFigure } from '@kampaay/common'
import { formatQuantityHint } from 'services/csv/entities/catalog/common/quantityHint'
import { formatTimeBeforeAfterHint } from 'services/csv/entities/catalog/common/timeBeforeAfterHint'

const formatProfessional = ({
  advanceReservationMin,
  pricing,
  section,
  sectionName,
  ...rest
}: ProfessionalFigure) => ({
  ...formatMainProduct(rest),
  'Booking time-limit': advanceReservationMin,
  Pricing: pricing,
  ...formatQuantityHint(rest),
  ...formatTimeBeforeAfterHint(rest),
  ...parseCsvFields({ ['Section Name']: sectionName ?? '' }),
  section
})

export const professionals: CSVEntityDescriptor<ProfessionalFigure> = {
  format: formatProfessional
}
