import React from 'react'
import HowItWorksAccordion from 'components/UI/Accordions/HowItWorksAccordion'
import ContentToggler from 'components/UI/ContentToggler'
import KDivider from 'components/UI/KDivider'
import BeforeHintArrayInput from 'components/FormComponents/Hints/TImeBeforeHint/BeforeHintArrayInput'
import DefaultTimeBeforeHint from 'components/FormComponents/Hints/TImeBeforeHint/DefaultTimeBeforeHint'

type Props = {
  accordionText: string[]
  arrayInputSource?: string
  defaultHintSource?: string
  togglerInputSource?: string
}

const BeforeHintFormContent: React.FC<Props> = ({
  arrayInputSource = 'timeBeforeHint',
  defaultHintSource = 'defaultTimeBeforeHint',
  togglerInputSource = 'showTimeBeforeHint',
  accordionText,
  ...props
}) => {
  return (
    <React.Fragment>
      <KDivider title="Time" />
      <ContentToggler
        {...props}
        source={togglerInputSource}
        label="form.fields.showTimeHint"
      >
        <BeforeHintArrayInput source={arrayInputSource} />
        <DefaultTimeBeforeHint source={defaultHintSource} />
      </ContentToggler>
      <HowItWorksAccordion accordionText={accordionText} />
    </React.Fragment>
  )
}

export default BeforeHintFormContent
