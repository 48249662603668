import React from 'react'
import { styled } from '@mui/material/styles'
import { ruleWhatChoices, serviceDiscriminatorChoices } from 'services/cfg/form'
import ProductsByServiceSelector from 'components/FormComponents/Rules/ProductsByServiceSelector'
import KToolTip from 'components/UI/KTooltip'
import { RAFormFC } from 'types/common'
import KSelectInput from 'components/Inputs/KInputs/KSelectInput'
import { useForm } from 'hooks/useForm'
import { IfClause } from '@kampaay/common'

const PREFIX = 'IfClauseForm'

const classes = {
  dFlexRow: `${PREFIX}-dFlexRow`,
  inputW250px: `${PREFIX}-inputW250px`
}

const Root = styled('div')({
  [`& .${classes.dFlexRow}`]: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    width: '100%'
  },
  [`& .${classes.inputW250px}`]: {
    width: '250px'
  }
})

const IfClauseInput: RAFormFC = ({ source }) => {
  const { getSource } = useForm<IfClause>(source)

  return (
    <Root>
      <div className={classes.dFlexRow}>
        <KToolTip title="form.fields.ruleWhatTooltip" placement="top">
          <KSelectInput
            label="form.fields.ruleWhat"
            className={classes.inputW250px}
            source={getSource('what')}
            choices={ruleWhatChoices}
          />
        </KToolTip>
        <ProductsByServiceSelector
          serviceTooltipText="form.fields.ifClauseServiceTooltip"
          productsTooltipText="form.fields.ifClauseProductsTooltip"
          source={source}
          serviceChoices={serviceDiscriminatorChoices}
        />
      </div>
    </Root>
  )
}

export default IfClauseInput
