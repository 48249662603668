import React from 'react'
import { Button } from 'react-admin'
import { Transaction } from 'services/api/entities/accounting/transaction/types/internal/transaction-models'
import { useKRecordContext } from 'hooks/useKRecordContext'

const AsideShowButton: React.FC<{
  cb: (p?: Transaction) => void
}> = ({ cb }) => {
  const record = useKRecordContext<Transaction>()
  return (
    <Button
      onClick={(e) => {
        e.stopPropagation()
        cb(record)
      }}
      variant="contained"
      disableElevation
      label="Show"
    />
  )
}

export default AsideShowButton
