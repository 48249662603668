import {
  bundleFacetIds,
  experienceParse,
  experienceWrite,
  findSingleFacet,
  parseFacet
} from '@kampaay/common'
import { APICfg } from 'services/api/api'
import { VirtualExperienceToAPI } from 'services/api/entities/catalog/virtual/types/api/request-models'
import { APIVirtualExperience } from 'services/api/entities/catalog/virtual/types/api/response-models'
import { VirtualExperience } from 'services/api/entities/catalog/virtual/types/internal/virtual-models'
import {
  uploadOrKeepImg,
  uploadOrKeepMultipleImgs
} from 'services/utils/images'

// ============
// PARSE
// ============

const virtualParse = (res: APIVirtualExperience): VirtualExperience => {
  const facets = res.facets.map(parseFacet)
  const virtualCategoryFacet = findSingleFacet(
    facets,
    'productFilter',
    'VirtualCategory'
  )

  return {
    ...experienceParse(res),
    virtualCategoryFacet
  }
}

// ============
// WRITE
// ============

const virtualWrite = async (
  req: VirtualExperience
): Promise<VirtualExperienceToAPI> => {
  const { virtualCategoryFacet, ...reqRest } = req
  const { facets, ...mappedRest } = await experienceWrite(
    uploadOrKeepImg,
    uploadOrKeepMultipleImgs
  )(reqRest)

  return {
    ...mappedRest,
    facets: bundleFacetIds(
      ...facets,
      virtualCategoryFacet === '' ? null : virtualCategoryFacet
    )
  }
}

// ============
// CFG
// ============

const apiCfg: APICfg = {
  endpoint: 'virtualexperiences',
  parse: virtualParse,
  write: virtualWrite
}

export default apiCfg
