import { useTranslate } from 'ra-core'
import { useMemo } from 'react'
import { MappedEnrichedEvent } from 'services/api/entities/events/types/internal/event-models'
import {
  EnrichedProductService,
  EnrichedProductServiceBase
} from 'services/api/entities/events/types/internal/services'
import { getServiceNameFromCollectionName } from 'services/cfg/name-maps'
import { ServiceCollectionName } from 'types/common'
import { SelectionType } from 'hooks/events/useEventService'
import get from 'lodash/get'

export type EventReadonlyServiceSummary<
  TService extends EnrichedProductServiceBase = EnrichedProductServiceBase,
  TServiceSource extends string = string
> = {
  id: string
  service: TService
  serviceName: string
  serviceTabPath: ServiceCollectionName
  selections: SelectionType[]
  hasUpselling: boolean
  hasSelections: boolean
  hasCustomizations: boolean
  serviceSource: TServiceSource
  numberOfCustomizations: number
}

export const useReadonlyEventService = (
  record: MappedEnrichedEvent,
  collectionName: ServiceCollectionName
): EventReadonlyServiceSummary<EnrichedProductService> => {
  const translate = useTranslate()

  const serviceName = getServiceNameFromCollectionName(collectionName)
  const serviceSource = `productServices.${serviceName}` as const

  const selections: SelectionType[] = get(record, collectionName) ?? []
  const service: EnrichedProductService = get(record, serviceSource)!

  const hasSelections = useMemo(() => !!selections?.length, [selections])
  const hasCustomizations = useMemo(
    () => !!service?.customizations?.length,
    [service]
  )
  const hasUpselling = useMemo(
    () => selections.some((s) => !!s.upsellingSelection?.length),
    [selections]
  )
  const numberOfCustomizations = useMemo(
    () => service?.customizations?.length ?? 0,
    [service]
  )

  return {
    id: serviceName,
    selections,
    service,
    serviceSource,
    serviceName: translate(`form.tabs.${serviceName}`),
    serviceTabPath: collectionName,
    hasSelections,
    hasUpselling,
    hasCustomizations,
    numberOfCustomizations
  }
}
