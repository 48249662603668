import { entries } from 'services/utils'

export type APIError = {
  body: {
    status: number
    title: string
    errors?: {
      [key: string]: string[]
    }
  }
}

export class KError extends Error {
  status?: number
  title: string
  errors: string[]

  constructor({ body: { errors, title, status } }: APIError) {
    super(title ?? '')

    this.status = status
    this.errors = errors
      ? entries(errors).map(([k, v]) => `${k}: ${v.join(' ,')}`)
      : []
    this.title = title ?? ''
  }
}
