import React, { useCallback } from 'react'
import {
  Button,
  ChipField,
  ListContextProvider,
  useGetMany,
  useList,
  useTranslate
} from 'react-admin'
import CustomReferenceInput from 'components/Inputs/CustomReferenceInput'
import KAutocompleteInput from 'components/Inputs/KInputs/KAutocompleteInput'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { FILTER_SOURCES, parseMultilangToCurrentLang } from '@kampaay/common'
import { useParams } from 'react-router-dom'
import OrganizationMembershipBudgetsDatagrid from 'components/FormComponents/Organization/Membership/Budgets/OrganizationMembershipBudgetsDatagrid'
import { OrganizationBudget } from '@kampaay/common'

const OrganizationBudgetMemberships = () => {
  const { id: organizationId } = useParams()
  const translate = useTranslate()
  const { setValue, watch } = useFormContext()

  const { append, remove } = useFieldArray({ name: 'activeBudgetIds' })
  const activeBudgetIds = watch('activeBudgetIds')
  const { data } = useGetMany<OrganizationBudget>('organizationbudget', {
    ids: activeBudgetIds
  })

  const listContext = useList({ data })
  const budget = watch('newBudget')

  const addBudget = useCallback(() => {
    if (budget) {
      append(budget)
      setValue('newBudget', undefined)
    }
  }, [budget])

  const removeBudgets = useCallback(() => {
    remove(listContext.selectedIds.map((id) => activeBudgetIds.indexOf(id)))
  }, [listContext, activeBudgetIds])

  return (
    <div className="w-full">
      <div className="flex w-full items-center justify-between">
        <div className="flex w-fit items-center gap-2">
          <h2>{translate('form.fields.budgets')}</h2>
          {activeBudgetIds && (
            <ChipField
              record={{ budgets: activeBudgetIds.length }}
              source="budgets"
            />
          )}
        </div>
        <div className="flex w-fit items-center gap-2">
          <CustomReferenceInput
            entity="organizationbudget"
            queryFilter={{
              [FILTER_SOURCES.organizationId.eq]: organizationId!,
              [FILTER_SOURCES.isActive.eq]: true
            }}
            filterFns={[(b) => !activeBudgetIds?.includes(b.id)]}
          >
            <KAutocompleteInput
              source="newBudget"
              label="form.fields.searchBudget"
              optionText={(record) => parseMultilangToCurrentLang(record.name)}
              className="w-[250px]"
            />
          </CustomReferenceInput>
          <Button
            label="form.fields.addBudget"
            onClick={addBudget}
            disabled={!budget}
          />
        </div>
      </div>
      <div className="mt-4">
        <ListContextProvider
          value={{ ...listContext, resource: 'organizationbudget' }}
        >
          <OrganizationMembershipBudgetsDatagrid
            data={data}
            removeMembers={removeBudgets}
            bulkActions
          />
        </ListContextProvider>
      </div>
    </div>
  )
}

export default OrganizationBudgetMemberships
