import {
  APIMailingList,
  MailingList,
  MailingListToAPI
} from 'services/api/entities/mailing-list/types'
import { APICfg } from 'services/api/api'

const parseMailingList = (mailingList: APIMailingList): MailingList =>
  mailingList

const writeMailingList = async (
  mailingList: MailingList
): Promise<MailingListToAPI> => mailingList

const apiCfg = {
  endpoint: 'mailingList',
  parse: parseMailingList,
  write: writeMailingList
} as APICfg

export default apiCfg
