import React from 'react'
import { Labeled } from 'react-admin'
import KDateField, { KDateFieldProps } from 'components/Fields/KDateField'

type LabeledDateFieldProps = Omit<KDateFieldProps, 'label'> & {
  label: string
}

/**
 * DateField with a label above
 * @param label - label of the field
 * @param source - source of the field
 * @param showTime - if true shows time - default false
 * @param showDate - if true shows date - default true
 *
 * N.B. showTime and showDate cannot be both false
 */
const LabeledDateField: React.FC<LabeledDateFieldProps> = ({
  label,
  source,
  showTime,
  showDate,
  record,
  emptyText
}) => {
  return (
    <Labeled label={label} sx={{ marginBottom: '16px' }}>
      <KDateField
        source={source}
        showTime={showTime}
        showDate={showDate}
        record={record}
        emptyText={emptyText}
      />
    </Labeled>
  )
}

export default LabeledDateField
