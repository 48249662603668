import React from 'react'
import { EmptyFormExt } from 'services/cfg/form'
import { type SupportedCountryCode, countrySwitch } from '@kampaay/common'

/**
 *
 * @param extensions Record where key is country and value is a component we want render for that country
 * @returns if our country is contained in record return the component otherwise EmptyFormExt
 */
export const countrySwitchFC = <P extends object>(
  extensions: Partial<Record<SupportedCountryCode, React.FC<P>>>
) => countrySwitch<React.FC<P>>(EmptyFormExt, extensions)
