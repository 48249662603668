import React, { createContext, useContext, useMemo, useState } from 'react'
import { getCurrentLang, LocaleCode, setCurrentLang } from '@kampaay/common'
import {
  LangContextProviderProps,
  LangContextType
} from 'contexts/langContext/types'

export const LangContext = createContext<LangContextType>({
  currentLang: getCurrentLang(),
  updateCurrentLang: () => {}
})

export const LangContextProvider: React.FC<LangContextProviderProps> = ({
  children
}) => {
  const [currentLangState, setCurrentLangState] = useState(getCurrentLang())

  const context = useMemo(
    () => ({
      currentLang: currentLangState,
      updateCurrentLang: (lang: LocaleCode) => {
        setCurrentLangState(lang)
        setCurrentLang(lang)
      }
    }),
    [currentLangState]
  )

  return <LangContext.Provider value={context}>{children}</LangContext.Provider>
}

export const useLangContext = () => {
  const context = useContext(LangContext)
  if (context === undefined) {
    throw new Error(
      'hook useLangContext must be used within a LangContextProvider'
    )
  }
  return context
}
