import React from 'react'
import FacetFilterType from 'components/Inputs/Facets/Wrappers/FacetFilterType'
import FacetTag from 'components/Inputs/Facets/Wrappers/FacetTag'

const FoodFacetSection = () => {
  return (
    <>
      <FacetTag />
      <FacetFilterType />
    </>
  )
}

export default FoodFacetSection
