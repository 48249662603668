import { paymentMethodChoices } from '@kampaay/common'
import KSelectInput from 'components/Inputs/KInputs/KSelectInput'
import { useForm } from 'hooks/useForm/useForm'
import React from 'react'
import { useTranslate } from 'react-admin'
import { MappedEnrichedEvent } from 'services/api/entities/events/types/internal/event-models'
import { RAFormFC } from 'types/common'

const PaymentMethodGeneralInput: RAFormFC = ({ source }) => {
  const { getSource } = useForm<MappedEnrichedEvent>(source)

  const t = useTranslate()

  return (
    <KSelectInput
      source={getSource('paymentMethod')}
      label="form.fields.paymentMethod"
      defaultValue={paymentMethodChoices[0].id}
      choices={paymentMethodChoices.map((choice) => ({
        id: choice.id,
        name: t(choice.name)
      }))}
    />
  )
}

export default PaymentMethodGeneralInput
