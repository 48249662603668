import React from 'react'
import {
  required,
  minLength,
  FormDataConsumer,
  ArrayField,
  Datagrid,
  TextField,
  Link,
  useCreatePath
} from 'react-admin'
import { facetDescriptionOptions } from 'services/cfg/form'
import FACET_FORM_MAP, {
  FacetFormExtension
} from 'resources/facets/FacetsCategoryFields'
import { ENTITY_SERVICE_MAP } from 'services/cfg/name-maps'
import LocalizedTextField from 'components/Fields/Localized/LocalizedTextField'
import { useKRecordContext } from 'hooks/useKRecordContext'
import DescriptionInput from 'components/Inputs/DescriptionInput'
import KSelectInput from 'components/Inputs/KInputs/KSelectInput'
import { APILinkedProduct, FacetCategory } from '@kampaay/common'

const LinkField: React.FC = () => {
  const record = useKRecordContext<APILinkedProduct>()

  const product = record ? ENTITY_SERVICE_MAP[record.type] : undefined
  const createPath = useCreatePath()

  const link = createPath({
    resource: `/${product}`,
    id: record?.id,
    type: 'edit'
  })

  return <Link to={link}>Go To Product Page</Link>
}

type Props = {
  disable?: boolean
}

const FacetFormContent: React.FC<Props> = ({ disable = false }) => (
  <>
    <DescriptionInput />
    <KSelectInput
      disabled={disable}
      source="category"
      defaultValue="tag"
      label="form.fields.category"
      validate={[required(), minLength(1)]}
      choices={facetDescriptionOptions}
      fullWidth
    />
    <FormDataConsumer>
      {({ formData: { category } }) => {
        const FacetCategoryFields: FacetFormExtension =
          FACET_FORM_MAP[category as FacetCategory]
        return category ? <FacetCategoryFields disable={disable} /> : null
      }}
    </FormDataConsumer>

    <ArrayField source="products">
      <Datagrid style={{ width: '600px' }}>
        <LocalizedTextField sortable={false} source="name" />
        <TextField sortable={false} source="type" />
        <LinkField />
      </Datagrid>
    </ArrayField>
  </>
)

export default FacetFormContent
