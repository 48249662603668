import React from 'react'
import { FilterSelectInput } from 'components/Inputs/FilterSelectInput'
import { useFormContext } from 'react-hook-form'
import {
  LinkedOrganization,
  FILTER_SOURCES,
  TailoredProduct
} from '@kampaay/common'
import { KFilterFieldProps } from 'services/filters/types'
import { useKResourceContext } from 'hooks/useKResourceContext'

const TailoredOrganizationFilter: React.FC<KFilterFieldProps> = ({
  source,
  label,
  ...props
}) => {
  const { getValues, setValue } = useFormContext()
  const resource = useKResourceContext()

  const handleChange = () => {
    if (getValues(FILTER_SOURCES.isPublic.eq)) {
      setValue(FILTER_SOURCES.isPublic.eq, undefined)
    }
  }

  return (
    <FilterSelectInput<LinkedOrganization>
      {...props}
      entity={resource}
      label={label}
      searchFn={(e: TailoredProduct) => e.organizationsFull}
      optionText="name"
      source={source}
      onChange={handleChange}
    />
  )
}

export default TailoredOrganizationFilter
