import React from 'react'
import { KInputProps, useKInput } from 'hooks/useKInput/useKInput'
import { DateInput, DateInputProps } from 'react-admin'

export type KDateInputProps = KInputProps & DateInputProps

const KDateInput: React.FC<KDateInputProps> = (props) => {
  const { isRequired } = useKInput(props)

  return <DateInput {...props} isRequired={isRequired} />
}

export default KDateInput
