import React from 'react'
import DescriptionInput from 'components/Inputs/DescriptionInput'
import CatalogItemBaseFormContent, {
  CatalogItemBaseFormContentProps
} from 'components/Forms/CatalogItemBaseFormContent'
import LocalizedAutoCompleteArrayInput from 'components/Inputs/Localized/LocalizedAutoCompleteArrayInput'
import { useKGetList } from 'hooks/useKGetList'

export type ProductCommonFormContentProps = CatalogItemBaseFormContentProps & {
  showGeoAreaPresets?: boolean
}

const ProductCommonFormContent: React.FC<ProductCommonFormContentProps> = ({
  children,
  slotBeforeFacets,
  facetFields,
  showGeoAreaPresets = true
}) => {
  const choices = useKGetList('geoareapresets').list

  return (
    <CatalogItemBaseFormContent
      facetFields={facetFields}
      slotBeforeFacets={
        <>
          {showGeoAreaPresets ? (
            <LocalizedAutoCompleteArrayInput
              source="geoAreaPresets"
              label="form.fields.geoAreaPresets"
              choices={choices}
              fullWidth
              optionText="name"
            />
          ) : null}
          <DescriptionInput />
          {slotBeforeFacets}
        </>
      }
    >
      {children}
    </CatalogItemBaseFormContent>
  )
}

export default ProductCommonFormContent
