import { APICfg } from 'services/api/api'
import { baseProductParse, baseProductWrite } from '.'

const apiCfg: APICfg = {
  endpoint: 'music',
  parse: baseProductParse,
  write: baseProductWrite
}

export default apiCfg
