import React from 'react'
import { useFormContext } from 'react-hook-form'
import useFacets from 'hooks/useFacets'
import QuantityHintFormContent from 'components/FormComponents/Hints/QuantityHint/QuantityHintFormContent'
import { FoodUpselling, getById, PricingFacet } from '@kampaay/common'

const UpsellingHintInput: React.FC = () => {
  const { watch, setValue } = useFormContext<FoodUpselling>()
  const pricingFacets = useFacets<PricingFacet>('pricing')
  const pricingFacet = watch('pricingFacet')

  const selectedPricingFacet = getById(pricingFacets, pricingFacet!)

  // Quantity Hint is available only on "perItem" upsellings
  const isPerItem = selectedPricingFacet?.pricingType === 'perItem'
  setValue('isPerItem', isPerItem ?? false)

  return isPerItem ? (
    <QuantityHintFormContent
      accordionText={[
        'quantityUpsellingHintAccordionText1',
        'quantityUpsellingHintAccordionText2',
        'quantityUpsellingHintAccordionText3',
        'quantityUpsellingHintAccordionText4'
      ]}
    />
  ) : null
}

export default UpsellingHintInput
