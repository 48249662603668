import { type Kampaayer, getCurrentCountry } from '@kampaay/common'
import React from 'react'
import type { RAFormFC } from 'types/common'
import KampaayerSelectInput, {
  type KampaayerSelectInputProps
} from 'components/Inputs/KampaayerSelectInput'
import type { FilterFn } from 'components/Inputs/CustomReferenceInput'

const filterCurrentCountryEE = (kampaayer: Kampaayer) =>
  kampaayer.managedCountries === getCurrentCountry().id
const filterKampaayerFns: FilterFn<Kampaayer>[] = [filterCurrentCountryEE]

const EEAssigneeSelectInput: RAFormFC<KampaayerSelectInputProps> = ({
  source,
  sx,
  fullWidth
}) => (
  <KampaayerSelectInput
    source={source}
    sx={sx}
    fullWidth={fullWidth}
    filterFns={filterKampaayerFns}
  />
)

export default EEAssigneeSelectInput
