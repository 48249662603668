import { APIEntity } from 'services/api/entities'
import { KConsole } from 'services/console'
import { queryClient } from 'services/queryClient'

type DependencyMap = Partial<Record<APIEntity, APIEntity[]>>

/**
 * Many of our resources are linked to some other resources, or some of them
 * are separate on the client side but are the same resource on the BE side.
 * In these cases, we might need to invalidate the cache of another resource
 * when updating one.
 *
 * Example:
 * Event and transaction are the same resource on the BE side. This means that
 * we can update an event both through the event form and the transaction form.
 * When updating some data that is visible both in event and in transaction
 * if we save the event, then we will always see the old cached transaction
 * since there has been no mutation/update triggered for the transaction and
 * the client doesn't know that it should invalidate the cache for that resource
 * as well as for event.
 */
const DEPENDENCY_MAP: DependencyMap = {
  events: ['transaction'],
  foodsupselling: ['foods'],
  logistics: ['foods', 'foodsupselling'],
  virtualupselling: ['virtuals'],
  locationupselling: ['locations'],
  physicalupselling: ['physicals'],
  concierge: ['proposal'],
  proposal: ['concierge', 'proposal'], // invalidates its own queries since modifying a proposal can trigger a status change on others as well
  setConciergeStatus: ['concierge', 'proposal'],
  organizationbudget: ['organizationmembership', 'organizationsubgroup'],
  organizationmembership: ['organizationbudget', 'organizationsubgroup'],
  organizationsubgroup: ['organizationbudget', 'organizationmembership']
}

export default async function invalidateDependentQueries(resource: APIEntity) {
  const dependencies = DEPENDENCY_MAP[resource]

  if (dependencies) {
    KConsole.log('Invalidating queries for', dependencies)

    dependencies.map((dependency) => queryClient.removeQueries([dependency]))
  }
}
