import {
  EventGeneralBriefQuestion,
  EventGeneralService
} from 'services/api/entities/events/types/internal/services'
import { yupArray, yupIgnore, yupObject, YupSchema } from 'services/yup'
import { eventBriefQuestionVS } from 'services/validations/operations/events/services/productService'

const generalBriefQuestionVS: YupSchema<EventGeneralBriefQuestion> =
  eventBriefQuestionVS.shape({
    activeWhenServices: yupArray()
  })

export const eventGeneralServiceVS: YupSchema<EventGeneralService> =
  yupObject().shape({
    discriminator: yupIgnore(),
    briefQuestions: yupArray(generalBriefQuestionVS)
  })
