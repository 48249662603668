import React from 'react'
import { useTranslate } from 'react-admin'
import OrganizationBudgetEventsDatagrid from 'components/FormComponents/Organization/Budget/Events/OrganizationBudgetEventsDatagrid'

const OrganizationBudgetEvents: React.FC = () => {
  const translate = useTranslate()

  return (
    <div className="w-full">
      <h2>{translate('resources.organization.budgetEvents')}</h2>
      <div className="mt-4">
        <OrganizationBudgetEventsDatagrid />
      </div>
    </div>
  )
}

export default OrganizationBudgetEvents
