import {
  CheckoutQuestionType,
  countrySwitch,
  EmailApprovalType,
  OrganizationApproverConfig
} from '@kampaay/common'
import { yupArray } from 'services/yup/custom-schemas/array'
import { yupIgnore } from 'services/yup/helpers'
import { yupString } from 'services/yup/custom-schemas/string'
import { UseValidationSchema, YupSchema } from 'services/yup/types'
import {
  PaymentConfig,
  PaymentConfiguration,
  PaymentConfigurationBase
} from 'types/common'
import { customerBaseVS } from 'services/validations/crm/customerBase'
import { yupBoolean, yupNumber, yupObject } from 'services/yup'
import {
  HiddenServices,
  OrganizationBase,
  OrganizationITA,
  OrganizationSE
} from 'services/api/entities/crm/organizations/types/internal/organization-model'
import { CheckoutQuestion } from 'services/api/entities/services-config/types/internal/question-models'
import { questionVS } from 'services/validations/servicesConfig/common'

const checkoutQuestionVS: YupSchema<CheckoutQuestion> = questionVS.shape({
  type: yupString<CheckoutQuestionType>(),
  description: yupString().optional(),
  options: yupArray(yupString()).when('type', {
    is: 'freeText',
    then: (schema) => schema.optional(),
    otherwise: (schema) =>
      schema.required().when('mandatory', {
        is: true,
        then: (schema) => schema.min(2),
        otherwise: (schema) => schema.min(1)
      })
  })
})

const paymentConfigurationBase: YupSchema<PaymentConfigurationBase> =
  yupObject().shape({
    name: yupString().optional(),
    title: yupString().optional(),
    description: yupString().optional()
  })

const paymentConfiguration: YupSchema<PaymentConfiguration> =
  paymentConfigurationBase.shape({
    isDisabled: yupBoolean()
  })

const paymentConfig: YupSchema<PaymentConfig> = yupObject().shape({
  bankTransfer: paymentConfiguration.optional(),
  card: paymentConfiguration.optional(),
  organizationBudget: paymentConfigurationBase.optional()
})

export const approvalConfigVs: YupSchema<OrganizationApproverConfig> =
  yupObject().shape({
    approvalType: yupString<EmailApprovalType>().required(),
    approverOptions: yupArray(
      yupObject().shape({
        email: yupString().email().required(),
        name: yupString().required()
      })
    ).when('approvalType', {
      is: 'pickList',
      then: (schema) => schema.required().min(1)
    }),
    fallbackApprovers: yupArray()
      .of(yupString().email().required())
      .when('approvalType', {
        is: 'groupAdmin',
        then: (schema) => schema.required().min(1)
      }),
    fallbackCCs: yupArray()
      .of(yupString().email().required())
      .when('approvalType', {
        is: 'groupAdmin',
        then: (schema) => schema.required().min(1)
      })
  })

export const useBaseOrganizationVS: UseValidationSchema<
  OrganizationBase
> = () =>
  customerBaseVS.shape({
    image: yupIgnore(),
    checkoutQuestions: yupArray(checkoutQuestionVS),
    // .nullable().required() is concatenated because we want to allow the field to be nullable and not trigger type errors,
    // but we want to make sure that it is always defined if an entry is added to the array
    mailingList: yupArray(yupString().nullable().required().email()),
    paymentConfiguration: paymentConfig.optional(),
    hiddenServices: useHiddenServicesValidationSchema(),
    isTrial: yupBoolean(),
    approvalEnabled: yupBoolean(),
    approvalThreshold: yupNumber().optional(),
    visiblePublicGeoAreaPresets: yupArray(yupNumber()).optional(),
    salesOwnerId: yupNumber().optional(),
    customApprovalFlow: yupBoolean(),
    approvalSteps: yupArray(approvalConfigVs).when('customApprovalFlow', {
      is: true,
      then: (schema) => schema.required().min(1)
    })
  })

export const useHiddenServicesValidationSchema: UseValidationSchema<
  HiddenServices
> = () =>
  yupObject().shape({
    physicals: yupBoolean().required(),
    foods: yupBoolean().required(),
    virtuals: yupBoolean().required(),
    locations: yupBoolean().required(),
    professionals: yupBoolean().required(),
    musics: yupBoolean().required(),
    rents: yupBoolean().required()
  })

const useBaseOrganizationSEVS: UseValidationSchema<OrganizationSE> = () =>
  useBaseOrganizationVS()

const useOrganizationITAVS: UseValidationSchema<OrganizationITA> = () =>
  useBaseOrganizationVS().shape({
    pec: yupString().required(),
    sdi: yupString().required(),
    vat: yupString().required()
  })

export const useOrganizationVS = countrySwitch(useBaseOrganizationVS, {
  IT: useOrganizationITAVS,
  SE: useBaseOrganizationSEVS
})
