import React from 'react'
import { SimpleFormIterator } from 'react-admin'
import KAccordion from 'components/UI/Accordions/KAccordion/KAccordion'
import OptionGroupInput from 'components/FormComponents/OptionGroupInput'
import KArrayInput from 'components/Inputs/KInputs/KArrayInput'
import { KBooleanInput } from 'components/Inputs/KInputs/KBooleanInput'
import KToolTip from 'components/UI/KTooltip'

const CatalogOptionsInput: React.FC = () => {
  return (
    <KAccordion accordionTitle="Options" errorsDependencies={['options']}>
      <KToolTip
        title="form.fields.flexibleOptionsTooltip"
        placement="top"
        style={{ width: 'fit-content' }}
      >
        <KBooleanInput
          source="hasFlexibleOptions"
          label="form.fields.flexibleOptions"
        />
      </KToolTip>
      <KArrayInput source="options">
        <SimpleFormIterator>
          <OptionGroupInput />
        </SimpleFormIterator>
      </KArrayInput>
    </KAccordion>
  )
}
export default CatalogOptionsInput
