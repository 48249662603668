import React, { useMemo } from 'react'
import { useKGetList } from 'hooks/useKGetList'
import { getCollectionNameFromServiceName } from 'services/cfg/name-maps'
import { ProductServiceName } from 'types/common'
import BriefQuestionArrayInput from 'components/FormComponents/Brief/BriefQuestionArrayInput'
import KDivider from 'components/UI/KDivider'
import FileInputAccordion from 'components/Inputs/FileInputAccordion'
import KFormTab from 'components/Forms/KForms/KFormTab'
import KNumberInput from 'components/Inputs/KInputs/KNumberInput/KNumberInput'
import { MainProduct } from '@kampaay/common'

type Props = {
  servName: ProductServiceName
  children?: React.ReactNode
}

const BaseCfgTab: React.FC<Props> = ({ servName, children, ...rest }) => {
  const name = useMemo(
    () => getCollectionNameFromServiceName(servName),
    [servName]
  )
  const { list: productChoices } = useKGetList<MainProduct>(name)
  return (
    <KFormTab {...rest} label={`form.tabs.${servName}`}>
      {children}
      <FileInputAccordion
        multiple
        label="Kampaayer Documents"
        accept="application/pdf,.xls,.xlsx,.docx"
        source={`${servName}.eventKampaayerDocs`}
      />
      <KDivider title="Service Time Limit" />
      <KNumberInput
        source={`${servName}.advanceReservationMin`}
        label="form.fields.advanceReservationMin"
      />
      <KDivider title="Brief Questions" />
      <BriefQuestionArrayInput
        source={`${servName}.brief`}
        productChoices={productChoices}
      />
    </KFormTab>
  )
}

export default BaseCfgTab
