import { styled } from '@mui/material/styles'
import React from 'react'
import { ArrayInput, SimpleFormIterator } from 'react-admin'
import HalfStepNumberInput from 'components/Inputs/HalfStepNumberInput/HalfStepNumberInput'
import KNumberInput from 'components/Inputs/KInputs/KNumberInput/KNumberInput'

const PREFIX = 'BeforeHintArrayInput'

const classes = {
  fieldsBox: `${PREFIX}-fieldsBox`,
  fieldWidth: `${PREFIX}-fieldWidth`,
  ml55: `${PREFIX}-ml55`
}

const StyledArrayInput = styled(ArrayInput)(() => ({
  [`& .${classes.fieldsBox}`]: {
    display: 'flex',
    flexDirection: 'row',
    gap: '100px',
    width: '100%'
  },

  [`& .${classes.fieldWidth}`]: {
    width: '300px'
  },

  [`& .${classes.ml55}`]: {
    marginLeft: '50px'
  }
}))

type Props = {
  source: string
}

const BeforeHintArrayInput: React.FC<Props> = ({ source }) => {
  return (
    <StyledArrayInput source={source}>
      <SimpleFormIterator inline>
        <KNumberInput
          source="maxParticipants"
          label="form.fields.maxParticipants"
          className={classes.fieldWidth}
          defaultValue={0}
        />
        <HalfStepNumberInput
          source="hoursBefore"
          label="form.fields.hoursBefore"
          className={classes.fieldWidth}
        />
      </SimpleFormIterator>
    </StyledArrayInput>
  )
}

export default BeforeHintArrayInput
