import { UseValidationSchema, yupArray, yupString } from 'services/yup'
import { useProductSectionVS } from 'services/validations/catalog/sections/productSection'
import { ProductSectionWithDescription } from 'services/api/entities/catalog/types'

export const useProductSectionWithDescriptionVS: UseValidationSchema<
  ProductSectionWithDescription<any>
> = () =>
  useProductSectionVS().shape({
    description: yupString().required(),
    productsFull: yupArray()
  })
