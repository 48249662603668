import { buildMultilangString, createMockBuilder } from '../../../../utils'
import type { Proposal } from '../types'

const buildBaseMock = (): Proposal => ({
  id: 1,
  name: buildMultilangString({ en: 'proposal name', it: 'nome proposta' }),
  status: 'pending',
  validUntil: new Date('2023-05-18T20:44:06.940Z'),
  isPublished: false,
  itemGroups: [],
  documents: [],
  guid: '123abc',
  proposalHistory: 0,
  isFinal: false,
  hasSupplierAssigned: false
})

export const buildProposalMock = createMockBuilder(buildBaseMock)
