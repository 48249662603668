import { useKRecordContext } from 'hooks/useKRecordContext'
import { TextField, useCreatePath } from 'react-admin'
import React from 'react'
import { openInternalLinkInNewWindow } from 'services/utils/navigationUtils'
import { APIEntity } from 'services/api/entities'

const EventPlaceholderLinkedEntityIdField = () => {
  const record = useKRecordContext()
  const createPath = useCreatePath()

  if (!record) {
    return null
  }

  const onClick = () => {
    const id = !!record.concierge ? record.concierge.id : record.id
    const resource: APIEntity = !!record.concierge
      ? 'concierge'
      : // If we don't have a concierge property, then the record is LinkedEvent
      record.eventStatus === 'draft'
      ? 'eventsdraft'
      : 'events'

    const pathname = createPath({
      resource,
      id,
      type: 'edit'
    })

    openInternalLinkInNewWindow(pathname)
  }

  return (
    <TextField
      className="cursor-pointer"
      color="primary"
      source={!!record.concierge ? 'concierge.id' : 'id'}
      label="Id"
      onClick={onClick}
    />
  )
}

export default EventPlaceholderLinkedEntityIdField
