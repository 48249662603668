import { ActivityStatus, QuestionReply } from '@kampaay/common'
import { APICfg } from 'services/api/api'

export type BriefQuestionReply = QuestionReply & {
  status: ActivityStatus
}

// TODO to type supplieredService as ProductServiceDiscriminator
export type ServiceBrief = {
  briefConfirmed?: boolean
  supplieredService: string
  questionResponses: BriefQuestionReply[]
}

type APIEventBrief = ServiceBrief[]

export type EventBrief = APIEventBrief

const apiCfg: APICfg = {
  endpoint: '',
  getOneEndpoint: 'event/:id/brief',
  parse: (res: APIEventBrief): EventBrief => res,
  write: async (req: EventBrief): Promise<APIEventBrief> => req
}

export default apiCfg
