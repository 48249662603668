import React from 'react'
import SectionWrapper from 'components/UI/SectionWrapper'
import ProductSectionFormContent from 'components/Forms/ProductSectionFormContent'
import { KSimpleForm } from 'components/Forms/KForms/KSimpleForm'
import KCreate from 'components/Forms/KCreate/KCreate'

const RentSectionCreate: React.FC = () => {
  return (
    <SectionWrapper title={'resources.rentsections.createSection'}>
      <KCreate>
        <KSimpleForm>
          <ProductSectionFormContent />
        </KSimpleForm>
      </KCreate>
    </SectionWrapper>
  )
}

export default RentSectionCreate
