import type {
  AgreementInForce,
  AgreementType,
  ConciergeFormAccommodationPlace,
  ConciergeFormGeographicalArea,
  ConciergeFormLocationNeed,
  ConciergeFormLocationType,
  ConciergeFormLogisticNeed,
  ConciergeFormPayloadGuestType,
  ConciergeFormType,
  ConciergeRequestEventPurpose,
  ConciergeRequestType,
  ConciergeStatus,
  EmailThreadAssigneeType,
  EventSize,
  FoodServiceMealAccommodationType,
  FoodServiceMealType,
  FoodServiceSupplierType,
  GuestManagementEventType,
  GuestManagementFormFieldType,
  GuestManagementPackage,
  LocationArrangementType,
  PaymentMethod,
  RoomsTypes,
  SupplierService,
  VirtualServiceCourses,
  VirtualServiceTypes
} from '../api'
import type { ActivityStatus } from '../types'

export type TypedChoices<T> = { id: T; name: string }[]

export type DashboardActivityStatus =
  | 'toBeContacted'
  | 'quoteRequested'
  | 'quoteReceived'
  | 'toBeConfirmed'
  | 'confirmed'
  | 'actionRequired'
  | 'discarded'

export const proposalIsPublishedChoices: TypedChoices<boolean> = [
  { id: true, name: 'Published' },
  { id: false, name: 'Draft' }
]

export const eventSizeChoices: TypedChoices<EventSize> = [
  { id: 'large', name: 'form.fields.eventSize.large' },
  { id: 'medium', name: 'form.fields.eventSize.medium' },
  { id: 'small', name: 'form.fields.eventSize.small' }
]

export const activityStatusChoices: TypedChoices<ActivityStatus> = [
  { id: 'inactive', name: 'form.choices.inactive' },
  { id: 'active', name: 'form.choices.active' }
]

export const agreementInForceChoices: TypedChoices<AgreementInForce> = [
  { id: 'none', name: 'agreementInForce.options.none' },
  {
    id: 'standardTermsAndConditions',
    name: 'agreementInForce.options.standardTermsAndConditions'
  },
  { id: 'contract', name: 'agreementInForce.options.contract' }
]

export const getAgreementInForceLabel = (input: AgreementInForce) =>
  agreementInForceChoices.find((choice) => choice.id === input)!.name

export const financeLegalCustomerAgreementInForceChoices: TypedChoices<AgreementType> =
  [
    {
      id: 'frameworkContract',
      name: 'form.fields.agreementType.frameworkContract'
    },
    {
      id: 'eventSpecificContract',
      name: 'form.fields.agreementType.eventSpecificContract'
    }
  ]

export const financeLegalSupplierAgreementInForceChoices: TypedChoices<AgreementType> =
  [
    {
      id: 'standardTermsAndCondition',
      name: 'form.fields.agreementType.standardTermsAndCondition'
    },
    ...financeLegalCustomerAgreementInForceChoices
  ]

export const emailThreadAssigneeChoices: TypedChoices<EmailThreadAssigneeType> =
  [
    { id: 'supplier', name: 'form.fields.emailThreadAssignee.supplier' },
    { id: 'otherActor', name: 'form.fields.emailThreadAssignee.otherActor' }
  ]

export const guestManagementPackageChoices: TypedChoices<GuestManagementPackage> =
  [
    {
      id: 'standard',
      name: 'guestManagementRequest.form.selectedPackage.standard'
    },
    {
      id: 'professional',
      name: 'guestManagementRequest.form.selectedPackage.professional'
    },
    { id: 'custom', name: 'guestManagementRequest.form.selectedPackage.custom' }
  ]

export const guestManagementEventTypeChoices: TypedChoices<GuestManagementEventType> =
  [
    {
      id: 'onInvitation',
      name: 'guestManagementRequest.form.eventInformation.onInvitation'
    },
    {
      id: 'open',
      name: 'guestManagementRequest.form.eventInformation.open'
    }
  ]

export const guestManagementFieldTypeChoices: TypedChoices<GuestManagementFormFieldType> =
  [
    {
      id: 'text',
      name: 'guestManagementRequest.form.registrationFields.text'
    },
    {
      id: 'number',
      name: 'guestManagementRequest.form.registrationFields.number'
    },
    {
      id: 'date',
      name: 'guestManagementRequest.form.registrationFields.date'
    },
    {
      id: 'time',
      name: 'guestManagementRequest.form.registrationFields.time'
    },
    {
      id: 'select',
      name: 'guestManagementRequest.form.registrationFields.select'
    },
    {
      id: 'divider',
      name: 'guestManagementRequest.form.registrationFields.divider'
    }
  ]

export const activityStatuses: TypedChoices<DashboardActivityStatus> = [
  { name: 'form.fields.activityStatus.toBeContacted', id: 'toBeContacted' },
  {
    name: 'form.fields.activityStatus.quoteRequested',
    id: 'quoteRequested'
  },
  { name: 'form.fields.activityStatus.quoteReceived', id: 'quoteReceived' },
  { name: 'form.fields.activityStatus.toBeConfirmed', id: 'toBeConfirmed' },
  { name: 'form.fields.activityStatus.confirmed', id: 'confirmed' },
  {
    name: 'form.fields.activityStatus.actionRequired',
    id: 'actionRequired'
  },
  { name: 'form.fields.activityStatus.discarded', id: 'discarded' }
]

export const conciergeStatusChoices: TypedChoices<ConciergeStatus> = [
  { id: 'received', name: 'form.fields.conciergeStatus.received' },
  {
    id: 'takenInCharge',
    name: 'form.fields.conciergeStatus.takenInCharge'
  },
  { id: 'proposalSent', name: 'form.fields.conciergeStatus.proposalSent' },
  { id: 'confirmed', name: 'form.fields.conciergeStatus.confirmed' },
  { id: 'lost', name: 'form.fields.conciergeStatus.lost' },
  { id: 'closed', name: 'form.fields.conciergeStatus.closed' }
]

export const arrangementTypeChoices: TypedChoices<LocationArrangementType> = [
  { id: 'classroom', name: 'form.fields.classroomArrangement' },
  { id: 'theater', name: 'form.fields.theaterArrangement' },
  { id: 'cabaret', name: 'form.fields.cabaretArrangement' },
  { id: 'boardroom', name: 'form.fields.boardroomArrangement' },
  { id: 'ushape', name: 'form.fields.ushapeArrangement' },
  { id: 'gala', name: 'form.fields.galaArrangement' },
  { id: 'standing', name: 'form.fields.standingArrangement' }
]

export const conciergeFormTypeChoices: TypedChoices<ConciergeFormType> = [
  { id: 'teamBuilding', name: 'form.fields.conciergeFormType.teamBuilding' },
  {
    id: 'lunchAndDinner',
    name: 'form.fields.conciergeFormType.lunchAndDinner'
  },
  {
    id: 'meetingAndConvention',
    name: 'form.fields.conciergeFormType.meetingAndConvention'
  },
  { id: 'companyParty', name: 'form.fields.conciergeFormType.companyParty' },
  {
    id: 'companyRetreat',
    name: 'form.fields.conciergeFormType.companyRetreat'
  },
  {
    id: 'virtualExperiences',
    name: 'form.fields.conciergeFormType.virtualExperiences'
  },
  { id: 'other', name: 'form.fields.conciergeFormType.other' }
]

export const conciergeRequestTypeChoices: TypedChoices<ConciergeRequestType> = [
  { id: 'form', name: 'form.fields.conciergeRequestType.form' },
  { id: 'freeText', name: 'form.fields.conciergeRequestType.freeText' }
]

export const conciergeEventPurposeChoices: TypedChoices<ConciergeRequestEventPurpose> =
  [
    {
      id: 'internalEngagement',
      name: 'form.fields.conciergeEventPurpose.internalEngagement'
    },
    {
      id: 'internalCommunication',
      name: 'form.fields.conciergeEventPurpose.internalCommunication'
    },
    { id: 'onboarding', name: 'form.fields.conciergeEventPurpose.onboarding' },
    { id: 'netWorking', name: 'form.fields.conciergeEventPurpose.netWorking' },
    { id: 'other', name: 'form.fields.conciergeEventPurpose.other' }
  ]

export const conciergeNeedChoices: TypedChoices<ConciergeFormLocationNeed> = [
  {
    id: 'hasOwnLocation',
    name: 'form.fields.conciergeNeed.hasOwnLocation'
  },
  { id: 'inOwnOffice', name: 'form.fields.conciergeNeed.inOwnOffice' },
  {
    id: 'needsLocation',
    name: 'form.fields.conciergeNeed.needsLocation'
  }
]

export const conciergeGeographicalAreasChoices: TypedChoices<ConciergeFormGeographicalArea> =
  [
    { id: 'city', name: 'form.fields.conciergeGeographicalArea.city' },
    {
      id: 'countrySide',
      name: 'form.fields.conciergeGeographicalArea.countrySide'
    },
    { id: 'lake', name: 'form.fields.conciergeGeographicalArea.lake' },
    { id: 'mountain', name: 'form.fields.conciergeGeographicalArea.mountain' },
    { id: 'seaside', name: 'form.fields.conciergeGeographicalArea.seaside' }
  ]

export const conciergeLogisticNeedsChoices: TypedChoices<ConciergeFormLogisticNeed> =
  [
    {
      id: 'nearStation',
      name: 'form.fields.conciergeLogisticNeed.nearStation'
    },
    { id: 'nearOffice', name: 'form.fields.conciergeLogisticNeed.nearOffice' },
    { id: 'cityCenter', name: 'form.fields.conciergeLogisticNeed.cityCenter' },
    { id: 'withParking', name: 'form.fields.conciergeLogisticNeed.withParking' }
  ]

export const conciergeLocationTypesChoices: TypedChoices<ConciergeFormLocationType> =
  [
    {
      id: 'historicVenue',
      name: 'form.fields.conciergeLocationType.historicVenue'
    },
    {
      id: 'conferenceCenter',
      name: 'form.fields.conciergeLocationType.conferenceCenter'
    },
    { id: 'hotel', name: 'form.fields.conciergeLocationType.hotel' },
    { id: 'resort', name: 'form.fields.conciergeLocationType.resort' },
    { id: 'rooftop', name: 'form.fields.conciergeLocationType.rooftop' },
    { id: 'farmhouse', name: 'form.fields.conciergeLocationType.farmhouse' },
    { id: 'museum', name: 'form.fields.conciergeLocationType.museum' },
    {
      id: 'coworkingSpace',
      name: 'form.fields.conciergeLocationType.coworkingSpace'
    },
    { id: 'disco', name: 'form.fields.conciergeLocationType.disco' },
    { id: 'restaurant', name: 'form.fields.conciergeLocationType.restaurant' },
    {
      id: 'sportCenter',
      name: 'form.fields.conciergeLocationType.sportCenter'
    },
    { id: 'winery', name: 'form.fields.conciergeLocationType.winery' }
  ]

export const conciergeGuestTypesChoices: TypedChoices<ConciergeFormPayloadGuestType> =
  [
    { id: 'internal', name: 'form.fields.conciergeGuestType.internal' },
    { id: 'management', name: 'form.fields.conciergeGuestType.management' },
    { id: 'customer', name: 'form.fields.conciergeGuestType.customer' },
    { id: 'other', name: 'form.fields.conciergeGuestType.other' }
  ]

export const conciergeTopicsChoices: TypedChoices<string> = [
  { id: 'Sport Challenge', name: 'form.fields.conciergeTopic.sportChallenge' },
  { id: 'Problem Solving', name: 'form.fields.conciergeTopic.problemSolving' },
  { id: 'Team Games', name: 'form.fields.conciergeTopic.teamGames' },
  { id: 'Creativity', name: 'form.fields.conciergeTopic.creativity' },
  { id: 'Wellbeing', name: 'form.fields.conciergeTopic.wellbeing' },
  {
    id: 'Tasting experience',
    name: 'form.fields.conciergeTopic.tastingExperience'
  },
  {
    id: 'Sustainability and social impact',
    name: 'form.fields.conciergeTopic.sustainabilityAndSocialImpact'
  },
  {
    id: 'Cultural experiences',
    name: 'form.fields.conciergeTopic.culturalExperiences'
  }
]

export const conciergeMealAccommodationChoices: TypedChoices<FoodServiceMealAccommodationType> =
  [
    {
      id: 'standingBuffet',
      name: 'form.fields.conciergeMealAccommodation.standingBuffet'
    },
    {
      id: 'seatedBuffet',
      name: 'form.fields.conciergeMealAccommodation.seatedBuffet'
    },
    {
      id: 'dinnerPlace',
      name: 'form.fields.conciergeMealAccommodation.dinnerPlace'
    }
  ]

export const conciergeSupplierTypeChoices: TypedChoices<FoodServiceSupplierType> =
  [
    { id: 'catering', name: 'form.fields.conciergeSupplierType.catering' },
    { id: 'restaurant', name: 'form.fields.conciergeSupplierType.restaurant' },
    { id: 'other', name: 'form.fields.conciergeSupplierType.other' }
  ]

export const conciergeMealTypeChoices: TypedChoices<FoodServiceMealType> = [
  { id: 'welcomeCoffee', name: 'form.fields.conciergeMealType.welcomeCoffee' },
  { id: 'coffeeBreak', name: 'form.fields.conciergeMealType.coffeeBreak' },
  { id: 'lunch', name: 'form.fields.conciergeMealType.lunch' },
  { id: 'aperitif', name: 'form.fields.conciergeMealType.aperitif' },
  { id: 'dinner', name: 'form.fields.conciergeMealType.dinner' },
  { id: 'openBar', name: 'form.fields.conciergeMealType.openBar' }
]

export const conciergeVirtualServiceTypeChoices: TypedChoices<VirtualServiceTypes> =
  [
    {
      id: 'virtualExperiences',
      name: 'form.fields.conciergeVirtualServiceType.virtualExperiences'
    },
    { id: 'giftBox', name: 'form.fields.conciergeVirtualServiceType.giftBox' }
  ]

export const conciergeVirtualServiceCoursesChoices: TypedChoices<VirtualServiceCourses> =
  [
    {
      id: 'starters',
      name: 'form.fields.conciergeVirtualServiceCourses.starters'
    },
    {
      id: 'firstCourse',
      name: 'form.fields.conciergeVirtualServiceCourses.firstCourse'
    },
    {
      id: 'mainCourse',
      name: 'form.fields.conciergeVirtualServiceCourses.mainCourse'
    },
    {
      id: 'dessert',
      name: 'form.fields.conciergeVirtualServiceCourses.dessert'
    }
  ]

export const conciergeRoomsTypesChoices: TypedChoices<RoomsTypes> = [
  { id: 'indifferent', name: 'form.fields.conciergeRoomsTypes.indifferent' },
  { id: 'single', name: 'form.fields.conciergeRoomsTypes.single' },
  { id: 'double', name: 'form.fields.conciergeRoomsTypes.double' },
  { id: 'twin', name: 'form.fields.conciergeRoomsTypes.twin' },
  { id: 'triple', name: 'form.fields.conciergeRoomsTypes.triple' }
]

export const conciergeAccommodationPlaceChoices: TypedChoices<ConciergeFormAccommodationPlace> =
  [
    {
      id: 'eventLocation',
      name: 'form.fields.conciergeAccommodationPlace.eventLocation'
    },
    {
      id: 'elsewhere',
      name: 'form.fields.conciergeAccommodationPlace.elsewhere'
    }
  ]

export const calendarPeriodChoices: TypedChoices<'week' | 'month'> = [
  { id: 'week', name: 'resources.organization.calendar.toolbar.week' },
  { id: 'month', name: 'resources.organization.calendar.toolbar.month' }
]

export const eventColorChoices: TypedChoices<string> = [
  { id: '#C40F45', name: 'form.fields.eventColor.cardinal' },
  { id: '#B55644', name: 'form.fields.eventColor.redwood' },
  { id: '#F2BD02', name: 'form.fields.eventColor.saffron' },
  { id: '#4DB35E', name: 'form.fields.eventColor.pigmentGreen' },
  { id: '#006F04', name: 'form.fields.eventColor.officeGreen' },
  { id: '#1FC8D3', name: 'form.fields.eventColor.robinEggBlue' },
  { id: '#007AF3', name: 'form.fields.eventColor.azure' },
  { id: '#1527C2', name: 'form.fields.eventColor.persianBlue' },
  { id: '#E66ADA', name: 'form.fields.eventColor.orchid' },
  { id: '#AA76FF', name: 'form.fields.eventColor.tropicalIndigo' },
  { id: '#FF6320', name: 'form.fields.eventColor.kampaay' }
]

export const getSupplierServiceName = (servidce: SupplierService) =>
  supplierServiceChoices.find((choice) => choice.id === servidce)?.name

export const supplierServiceChoices: TypedChoices<SupplierService> = [
  {
    id: 'activitiesExperiences',
    name: 'form.choices.supplierType.activitiesExperiences'
  },
  {
    id: 'decorationAndSetUp',
    name: 'form.choices.supplierType.decorationAndSetUp'
  },
  { id: 'entertainment', name: 'form.choices.supplierType.entertainment' },
  { id: 'foodBeverage', name: 'form.choices.supplierType.foodBeverage' },
  { id: 'gadgetAndGifts', name: 'form.choices.supplierType.gadgetAndGifts' },
  {
    id: 'overnightAccommodation',
    name: 'form.choices.supplierType.overnightAccommodation'
  },
  { id: 'staff', name: 'form.choices.supplierType.staff' },
  {
    id: 'technicalEquipmentAndServices',
    name: 'form.choices.supplierType.technicalEquipmentAndServices'
  },
  { id: 'transportation', name: 'form.choices.supplierType.transportation' },
  { id: 'venue', name: 'form.choices.supplierType.venue' }
]

export const monthChoices: TypedChoices<number> = Array(12)
  .fill(0)
  .map((_, index) => ({
    id: index + 1,
    name: new Date(0, index).toLocaleString('en-US', { month: 'long' })
  }))

export const paymentMethodChoices: TypedChoices<PaymentMethod> = [
  { id: 'none', name: 'form.fields.none' },
  { id: 'card', name: 'form.fields.card' },
  { id: 'bankTransfer', name: 'form.fields.bankTransfer' },
  { id: 'organizationBudget', name: 'form.fields.companyBudget' }
]
