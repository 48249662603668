import React, { CSSProperties, PropsWithChildren } from 'react'
import {
  Collapse,
  CollapseProps,
  IconButton,
  IconButtonProps,
  styled
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import KHeader, { HeadingLevel } from 'components/UI/KHeader'

type KCollapseProps = PropsWithChildren &
  CollapseProps & {
    collapseTitle: string
    shouldDisplayAlertIcon?: boolean
    prependContent?: React.ReactElement
    collapseHeadingLevel?: HeadingLevel
    style?: CSSProperties
    defaultExpanded?: boolean
  }

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand: _expand, ...other } = props
  return <IconButton {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}))

/**
 * This component is a wrapper of MUI Collapse
 * @see https://mui.com/material-ui/api/collapse/
 *
 * @param collapseTitle The title of the collapse
 * @param defaultExpanded If true, the collapse will be expanded by default
 * @param prependContent Content to be displayed on the left side of the header
 * @param collapseHeadingLevel The dimension of the header (h1, h2, h3, h4, h5, h6)
 * @param children The content of the collapse
 */
const KCollapse: React.FC<KCollapseProps> = ({
  children,
  collapseTitle,
  collapseHeadingLevel,
  prependContent,
  defaultExpanded = false
}) => {
  const [expanded, setExpanded] = React.useState(defaultExpanded)
  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        {prependContent && (
          <div style={{ marginLeft: 'auto', marginRight: '1em' }}>
            {prependContent}
          </div>
        )}

        <KHeader
          style={{ marginRight: '.5em', margin: '0' }}
          headingLevel={collapseHeadingLevel}
          title={collapseTitle}
        />

        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </div>
      <Collapse in={expanded} timeout="auto">
        {children}
      </Collapse>
    </div>
  )
}

export default KCollapse
