import { APILocationFeature } from 'services/api/entities/catalog/locationFeatures/types/api/response-models'
import {
  buildApiProposalMock,
  buildProposalItemMock,
  GeoAreaPreset,
  ISODate
} from '@kampaay/common'
import { buildAPIAreaMock } from 'test/mocks/area/api-area-mocks'
import { buildOrganizationBudgetMock } from 'test/mocks/crm/organization-budget/budget-mocks'
import { buildOrganizationMembershipMock } from 'test/mocks/crm/organization-membership/membership-mocks'
import { KConsole } from 'services/console'
import { buildAPIMailingListMock } from 'test/mocks/mailing-list/api-mailing-list-mocks'
import { buildOrganizationSubGroupMock } from 'test/mocks/crm/organization-sub-group/sub-group-mocks'

const getFilter = (url: any, filterName: any) => {
  const path = new URL(url)
  return path.searchParams.get(filterName)
}

const getMockedResponse = (
  resource: string,
  type: 'list' | 'detail',
  url: string
) => {
  KConsole.log('[MOCK INDEX TYPE]', type)
  KConsole.log('[MOCK INDEX UTL]', url)
  KConsole.log('[MOCK INDEX RESOURCE]', resource)
  switch (resource) {
    case 'organization/:id/tailoring/foods':
      if (type === 'list') return [{ id: 55, tailorings: [] }]
      if (type === 'detail') return { id: 55, tailorings: [] }
      break
    case 'organization/:id/tailoring/locations':
      if (type === 'list') return [{ id: 55, tailorings: [] }]
      if (type === 'detail') return { id: 55, tailorings: [] }
      break
    case 'organization/:id/tailoring/drinks':
      if (type === 'list') return [{ id: 55, tailorings: [] }]
      if (type === 'detail') return { id: 55, tailorings: [] }
      break
    case 'organization/:id/tailoring/virtuals':
      if (type === 'list') return [{ id: 55, tailorings: [] }]
      if (type === 'detail') return { id: 55, tailorings: [] }
      break
    case 'organization/:id/tailoring/physicals':
      if (type === 'list') return [{ id: 55, tailorings: [] }]
      if (type === 'detail') return { id: 55, tailorings: [] }
      break
    case 'organization/:id/tailoring/musics':
      if (type === 'list') return [{ id: 55, tailorings: [] }]
      if (type === 'detail') return { id: 55, tailorings: [] }
      break
    case 'organization/:id/tailoring/rents':
      if (type === 'list') return [{ id: 55, tailorings: [] }]
      if (type === 'detail') return { id: 55, tailorings: [] }
      break
    case 'organization/:id/tailoring/professionals':
      if (type === 'list') return [{ id: 55, tailorings: [] }]
      if (type === 'detail') return { id: 55, tailorings: [] }
      break
    case 'organization/:id/tailoring/foodsupselling':
      if (type === 'list') return [{ id: 55, tailorings: [] }]
      if (type === 'detail') return { id: 55, tailorings: [] }
      break
    case 'organization/:id/tailoring/virtualupselling':
      if (type === 'list') return [{ id: 55, tailorings: [] }]
      if (type === 'detail') return { id: 55, tailorings: [] }
      break
    case 'organization/:id/tailoring/physicalupselling':
      if (type === 'list') return [{ id: 55, tailorings: [] }]
      if (type === 'detail') return { id: 55, tailorings: [] }
      break
    case 'organization/:id/tailoring/locationupselling':
      if (type === 'list') return [{ id: 55, tailorings: [] }]
      if (type === 'detail') return { id: 55, tailorings: [] }
      break
    case 'organization/:id/tailoring/logistics':
      if (type === 'list') return [{ id: 55, tailorings: [] }]
      if (type === 'detail') return { id: 55, tailorings: [] }
      break
    case 'drinkscomponent':
      if (type === 'list') return PRODUCTS
      if (type === 'detail') return {}
      break
    case 'musics':
      if (type === 'list') return MUSIC_LIST
      if (type === 'detail') return MUSIC_ITEM
      break
    case 'locations':
      if (type === 'list') return LOCATION_LIST
      if (type === 'detail') return LOCATION_ITEM
      break
    case 'locationfeatures':
      if (type === 'list') return LOCATION_FEATURE_LIST
      if (type === 'detail') return LOCATION_EQUIPMENT
      break
    case 'locationsections':
      if (type === 'list') return LOCATION_SECTION
      if (type === 'detail') return LOCATION_SECTION_ITEM
      break
    case 'musicsections':
      if (type === 'list') return MUSIC_SECTIONS
      if (type === 'detail') return MUSIC_SECTION_ITEM
      break
    case 'logistics':
      if (type === 'list') return LOGISTICS_LIST
      if (type === 'detail') return LOGISTICS_ITEM
      break
    case 'rents':
      if (type === 'list') return RENT_LIST
      if (type === 'detail') return RENT_ITEM
      break
    case 'rentsections':
      if (type === 'list') return RENT_SECTIONS
      if (type === 'detail') return RENT_SECTION_ITEM
      break
    case 'foods':
      if (type === 'list') return FOOD_LIST
      if (type === 'detail') return FOOD_ITEM
      break
    case 'foodsections':
      if (type === 'list') return FOOD_SECTIONS
      if (type === 'detail') return FOOD_SECTION_ITEM
      break
    case 'foodsupselling':
      if (type === 'list') return FOOD_UPSELLING_LIST
      if (type === 'detail') return FOOD_UPSELLING_ITEM
      break
    case 'virtualsections':
      if (type === 'list') return VIRTUAL_SECTIONS
      if (type === 'detail') return VIRTUAL_SECTION_ITEM
      break
    case 'professionalsections':
      if (type === 'list') return PROFESSIONAL_SECTIONS
      if (type === 'detail') return PROFESSIONAL_SECTIONS_ITEM
      break
    // physical experiences
    //mocked with virtual experience products
    case 'physical':
      if (type === 'list') return PHYSICAL_LIST
      if (type === 'detail') return PHYSICAL_LIST_ITEM
      break
    //mocked with virtual experience products
    case 'physicalsections':
      if (type === 'list') return VIRTUAL_SECTIONS
      if (type === 'detail') return VIRTUAL_SECTION_ITEM
      break
    case 'physicalupselling':
      if (type === 'list') return PHYSICAL_UPSELLING_LIST
      if (type === 'detail') return PHYSICAL_UPSELLING_LIST_ITEM
      break
    // --------------------
    case 'drinkssection':
      if (type === 'list') return DRINK
      if (type === 'detail') return DRINKS_SECTION_ITEM
      break
    case 'drinks':
      if (type === 'list')
        return getFilter(url, 'filterSection') === '0'
          ? DRINKS_LIST_VODKA
          : DRINKS_LIST_GIN
      if (type === 'detail') return DRINK_ITEM_VODKA
      break
    case 'variants':
      if (type === 'detail') return VARIANT_ITEM
      break
    case 'business':
      if (type === 'list') return [business, { ...business, id: 1 }]
      if (type === 'detail') {
        return business
      }
      break
    case 'kampaayers':
      if (type === 'list') return [kampaayer, { ...kampaayer, id: 1 }]
      if (type === 'detail') return kampaayer
      break
    case 'suppliers':
      if (type === 'list') return [supplier, { ...supplier, id: 1 }]
      if (type === 'detail') return supplier
      break
    case 'events':
      if (type === 'list') return [operation, { ...operation, id: 1 }]
      if (type === 'detail') return operation
      break
    case 'geoareapresets':
      if (type === 'list') return GEOAREA_LIST
      if (type === 'detail') return GEOAREA_ITEM
      break
    case 'servicesconfig':
      if (type === 'list') return SERVICE_CFG
      if (type === 'detail') return SERVICE_CFG
      break
    case 'professionaltypes':
      if (type === 'list') return PROFESSIONAL_TYPES_LIST
      if (type === 'detail') return PROFESSIONAL_TYPES_DETAIL
      break
    case 'professionalfigures':
      if (type === 'list')
        return getFilter(url, 'filterSection') === '0'
          ? P_FIGURE_LIST_BARRISTI
          : P_FIGURE_LIST_ALFIERI
      if (type === 'detail') return P_FIGURE_BALFIO
      break
    case 'facets':
      if (type === 'list')
        return [
          {
            id: 0,
            code: 'coupon:discount',
            description: 'super coupon'
          }
        ]
      if (type === 'detail')
        return {
          id: 0,
          code: 'coupon:discount',
          description: 'super coupon'
        }
      break
    case 'proposalItem':
      if (type === 'detail') {
        return buildProposalItemMock()
      }
      break
    // case 'proposalHistory':
    //   if (type === 'detail') {
    //     return buildProposalHistoryMock()
    //   }
    //   break
    case 'area':
      return [buildAPIAreaMock()]
    case 'mailingList':
      if (type === 'detail') {
        return buildAPIMailingListMock()
      } else if (type === 'list') {
        return [buildAPIMailingListMock()]
      }
      break
    case 'proposal':
      if (type === 'detail') {
        return buildApiProposalMock({
          validUntil: new Date('2050-12-31T23:59:59.000Z').toISOString(),
          isPublished: true,
          proposalHistory: 1
        })
      } else if (type === 'list') {
        return Array(5).fill(
          buildApiProposalMock({
            validUntil: new Date('2050-12-31T23:59:59.000Z').toISOString(),
            isPublished: true,
            proposalHistory: 1,
            dedicatedDiscount: 20,
            contractDiscount: 10
          })
        )
      }
      break
    case 'organizationbudget':
      if (type === 'detail') {
        return buildOrganizationBudgetMock()
      } else if (type === 'list') {
        return [...Array(5)].map((_, idx) =>
          buildOrganizationBudgetMock({
            id: idx + 1,
            name: `Budget ${idx + 1}`
          })
        )
      }
      break
    case 'organizationmembership':
      if (type === 'detail') {
        return buildOrganizationMembershipMock()
      } else if (type === 'list') {
        return [...Array(5)].map((_, idx) =>
          buildOrganizationMembershipMock({
            id: idx + 1,
            role: 'collaborator'
          })
        )
      }
      break

    case 'organizationsubgroup':
      if (type === 'detail') {
        return buildOrganizationSubGroupMock()
      } else if (type === 'list') {
        return [...Array(5)].map((_, idx) =>
          buildOrganizationSubGroupMock({
            id: idx + 1
          })
        )
      }
      break

    default:
      return null
  }
}

export default getMockedResponse

const LOCATION_LIST = [
  {
    id: 1,
    images: [
      {
        id: '1',
        url: 'http://ilvenetoimbruttito.com/uploads/images/posts/2016/col12_Schermata_2018-10-12_alle_11.47.43-3b5337144f1efb4e0a5e7d8887e26119.png'
      }
    ],
    sections: [],
    name: 'Testardi Location',
    description: '<strong>Scampa location</strong>',
    supplier: 'Mr Scampa',
    nickname: 'Scampagnata',
    status: 'inactive',
    fullAddress: 'via della scampa, 2',
    facets: [
      {
        id: 99,
        code: 'productFilter:location:Duomo',
        description: 'che voglia di scampa'
      }
    ],
    peopleCapacity: 150,
    roomCapacity: 120,
    planimetry: [],
    availableFrom: '2021-11-29T16:00:00Z',
    availableTo: '2021-11-29T19:00:00Z',
    component: {
      defaultCost: 12,
      price: {
        agencyA: 67,
        venue: 345,
        business: 45
      }
    }
  },
  {
    id: 2,
    images: [
      {
        id: '2',
        url: 'http://ilvenetoimbruttito.com/uploads/images/posts/2016/col12_Schermata_2018-10-12_alle_11.47.43-3b5337144f1efb4e0a5e7d8887e26119.png'
      }
    ],
    sections: [],
    name: 'asdasdsad 2',
    description: '<strong>Scampa location</strong>',
    supplier: 'Mr Scampa',
    nickname: 'Voglia dasdi',
    status: 'active',
    fullAddress: 'via della scampa, 2',
    facets: [
      {
        id: 10,
        code: 'productFilter:location:Quarto_Oggiaro',
        description: 'che voglia di scampa'
      }
    ],
    peopleCapacity: 120,
    roomCapacity: 120,
    planimetry: [],
    availableFrom: '2021-11-29T16:00:00Z',
    availableTo: '2021-11-29T19:00:00Z',
    component: {
      defaultCost: 12,
      price: {
        agencyA: 67,
        venue: 345,
        business: 45
      }
    }
  },
  {
    id: 3,
    images: [
      {
        id: '2',
        url: 'http://ilvenetoimbruttito.com/uploads/images/posts/2016/col12_Schermata_2018-10-12_alle_11.47.43-3b5337144f1efb4e0a5e7d8887e26119.png'
      }
    ],
    sections: [],
    name: 'Testardi Location 2',
    description: '<strong>Scampa location</strong>',
    supplier: 'Mr Scampa',
    nickname: 'Voglia di',
    status: 'inactive',
    fullAddress: 'via della scampa, 2',
    facets: [],
    peopleCapacity: 200,
    roomCapacity: 120,
    planimetry: [],
    availableFrom: '2021-11-29T16:00:00Z',
    availableTo: '2021-11-29T19:00:00Z',
    component: {
      defaultCost: 12,
      price: {
        agencyA: 67,
        venue: 345,
        business: 45
      }
    }
  }
]

const LOCATION_ITEM = {
  id: 1,
  images: [
    {
      id: '1',
      url: 'http://ilvenetoimbruttito.com/uploads/images/posts/2016/col12_Schermata_2018-10-12_alle_11.47.43-3b5337144f1efb4e0a5e7d8887e26119.png'
    }
  ],
  sections: [],
  name: 'Testardi Location',
  description: '<strong>Scampa location</strong>',
  supplier: 'Mr Scampa',
  nickname: 'Scampagnata',
  status: 'inactive',
  fullAddress: 'via della scampa, 2',
  facets: [
    {
      id: 99,
      code: 'LocationZone:Location:Duomo',
      description: 'che voglia di scampa'
    }
  ],
  peopleCapacity: 150,
  roomCapacity: 120,
  planimetry: [],
  availableFrom: '2021-11-29T16:00:00Z',
  availableTo: '2021-11-29T19:00:00Z',
  component: {
    defaultCost: 12,
    price: {
      agencyA: 67,
      venue: 345,
      business: 45
    }
  }
}

// GEOAREA

const GEOAREA_LIST: GeoAreaPreset[] = [
  {
    id: 1,
    name: 'Milan center',
    nickname: 'Milan nickname',
    description: 'Milan description',
    geoArea: {
      lat: 45.464664,
      lng: 9.18854,
      radius: 25
    },
    organizations: [],
    organizationsFull: []
  },
  {
    id: 2,
    name: 'Stockholm center',
    nickname: 'Stockholm nickname',
    description: 'Stockholm description',
    geoArea: {
      lat: 45.464664,
      lng: 9.18854,
      radius: 25
    },
    organizations: [],
    organizationsFull: []
  }
]

const GEOAREA_ITEM: GeoAreaPreset = {
  id: 1,
  name: 'Milan center',
  nickname: 'Milan nickname',
  description: 'Milan description',
  geoArea: {
    lat: 59.382442499999996,
    lng: 18.036758,
    radius: 40
  },
  organizations: [],
  organizationsFull: []
}

const SERVICE_CFG = {
  id: 'serviceconfig',
  rentService: {
    eventKampaayerDocs: []
  },
  foodService: {
    eventKampaayerDocs: []
  },
  virtualService: {
    eventKampaayerDocs: []
  },
  musicService: {
    eventKampaayerDocs: []
  },
  drinkService: {
    eventKampaayerDocs: []
  }
}

const business = {
  id: '0',
  customerType: { id: 0, name: 'Business' },
  name: ' test name',
  customerAddress: 'test address',
  businessHeadQuarters: { id: 0, name: 'headquarter adrress test 1' },
  phone: '',
  email: '',
  pec: '',
  fax: '',
  sdi: '',
  fiscalCode: '',
  vat: '',
  referral: '',
  eventAddresses: [],
  usageOfImagesAndTextsAccepted: true,
  privacyPolicyAccepted: false,
  profilingConsensus: true
}

const kampaayer = {
  id: '0',
  firstName: 'name test',
  lastName: 'lastname test',
  phone: '34712345666',
  email: 'uhuhu@wow.org',
  birthDate: '2011-10-05T14:48:00.000Z',
  birthPlace: "L'hondon",
  residenceAddress: 'address test',
  domicileAddress: 'domicle addree test',
  fiscalCode: 'GRNDG87HJB888543CN',
  kContracts: {
    id: 0,
    url: 'http://www.pdf995.com/samples/pdf.pdf'
  }
}

const supplier = {
  id: '0',
  name: 'business name test',
  category: { id: 0, name: 'Category test' },
  legalAddress: { id: 0, name: 'Indirizzo 1' },
  operativeAddress: { id: 0, name: 'Indirizzo 1' },
  phone: '34712345666',
  email: 'uhuhu@wow.org',
  fax: '34712345666',
  fiscalCode: 'bavbhwhbruvbuwbuvh',
  vat: '4652385823659823',
  responsible: 'responsible test'
}

// products
const PRODUCTS = [
  { id: 0, name: 'Lime', component: { defaultCost: 2 } },
  { id: 1, name: 'Ghiaccio', component: { defaultCost: 3 } },
  { id: 2, name: 'Fragole', component: { defaultCost: 4 } }
]

const PRODUCT_OPTION_ITEMS = [
  {
    name: 'Item 1',
    description: 'Lorem ipsum description',
    image:
      'https://static.pourfemme.it/r/845X0/ricette.pourfemme.it/img/pasta-fredda-light-ricetta.jpg'
  },
  {
    name: 'Item 2',
    description: 'Lorem ipsum description',
    image:
      'https://static.pourfemme.it/r/845X0/ricette.pourfemme.it/img/pasta-fredda-light-ricetta.jpg'
  }
]

const PRODUCT_OPTIONS = [
  {
    name: 'Option 1',
    items: PRODUCT_OPTION_ITEMS
  }
]

// drinks

export const VARIANT_PRICE_ITEM = {
  agencyA: 6,
  venue: 8,
  business: 9
}

const VARIANT_CONTENT_ITEM = [
  {
    id: 2,
    number: 4
  },
  {
    id: 4,
    number: 7
  }
]
const DRINK = [
  {
    id: '0',
    name: 'Drink a base di Vodka'
  },
  {
    id: '1',
    name: 'Drink a base di Gin'
  }
]

const DRINKS_SECTION_ITEM = {
  id: '0',
  name: 'Drink a base di Vodka'
}

const DRINKS_LIST_VODKA = [
  {
    id: '0',
    name: 'Drink Vodka 1',
    description: 'Drink a base di Vodka, lime, tonica e menta',
    image: '',
    variants: [],
    isBundle: true
  },
  {
    id: '1',
    name: 'Drink Vodka 2',
    description: 'Drink a base di Vodka, lime, tonica e lamponi',
    image: '',
    variants: [],
    isBundle: true
  },
  {
    id: '2',
    name: 'Drink No bundle',
    description: 'Drink a base di Vodka, lime, tonica e menta',
    image: '',
    defaultCost: 6,
    defaultSupplier: 1,
    price: VARIANT_PRICE_ITEM,
    isBundle: false
  }
]

const DRINK_ITEM_VODKA = {
  id: '0',
  name: 'Drink Vodka 2',
  description: 'Drink a base di Vodka, lime, tonica e menta',
  image: '',
  variants: [],
  isBundle: true
}

const DRINKS_LIST_GIN = [
  {
    id: '0',
    name: 'Drink Gin 1',
    description: 'Drink a base di Gin, lime, tonica e menta',
    image: 'https://i.ebayimg.com/images/i/141374754207-0-1/s-l1000.jpg',
    variants: [],
    isBundle: true
  },
  {
    id: '1',
    name: 'Drink Gin 2',
    description: 'Drink a base di Gin, lime, tonica e rosmarino',
    image: 'https://i.ebayimg.com/images/i/141374754207-0-1/s-l1000.jpg',
    variants: [],
    isBundle: true
  }
]

const VARIANT_ITEM = {
  id: '0',
  name: 'Smirnoff',
  image:
    'https://cdn2.bigcommerce.com/server5500/tpbc2s65/products/1201/images/6105/0008200010570_A__53852.1471964368.380.500.jpg?c=2',
  price: VARIANT_PRICE_ITEM,
  drinksPerBottle: 4,
  content: VARIANT_CONTENT_ITEM
}
// drinks

// music
const MUSIC_LIST = [
  {
    id: '0',
    defaultCost: 6,
    defaultSupplier: 1,
    price: VARIANT_PRICE_ITEM,
    name: 'Music Name',
    image:
      'https://img.discogs.com/_zLgMkRnoWrEbYQqPFfOs4kRsgw=/fit-in/300x300/filters:strip_icc():format(jpeg):mode_rgb():quality(40)/discogs-images/R-5339445-1501959227-8948.jpeg.jpg',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque dolor libero, semper quis posuere at, luctus eget lectus.'
  },
  {
    id: '1',
    defaultCost: 6,
    defaultSupplier: 1,
    price: 10.99,
    name: 'Music Name',
    image:
      'https://img.discogs.com/_zLgMkRnoWrEbYQqPFfOs4kRsgw=/fit-in/300x300/filters:strip_icc():format(jpeg):mode_rgb():quality(40)/discogs-images/R-5339445-1501959227-8948.jpeg.jpg',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque dolor libero, semper quis posuere at, luctus eget lectus.'
  },
  {
    id: '2',
    defaultCost: 6,
    defaultSupplier: 1,
    price: VARIANT_PRICE_ITEM,
    name: 'Music Name',
    image:
      'https://img.discogs.com/_zLgMkRnoWrEbYQqPFfOs4kRsgw=/fit-in/300x300/filters:strip_icc():format(jpeg):mode_rgb():quality(40)/discogs-images/R-5339445-1501959227-8948.jpeg.jpg',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque dolor libero, semper quis posuere at, luctus eget lectus.'
  },
  {
    id: '3',
    defaultCost: 6,
    defaultSupplier: 1,
    price: VARIANT_PRICE_ITEM,
    name: 'Music Name',
    image:
      'https://img.discogs.com/_zLgMkRnoWrEbYQqPFfOs4kRsgw=/fit-in/300x300/filters:strip_icc():format(jpeg):mode_rgb():quality(40)/discogs-images/R-5339445-1501959227-8948.jpeg.jpg',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque dolor libero, semper quis posuere at, luctus eget lectus.'
  },
  {
    id: '4',
    defaultCost: 6,
    defaultSupplier: 1,
    price: VARIANT_PRICE_ITEM,
    name: 'Music Name',
    image:
      'https://img.discogs.com/_zLgMkRnoWrEbYQqPFfOs4kRsgw=/fit-in/300x300/filters:strip_icc():format(jpeg):mode_rgb():quality(40)/discogs-images/R-5339445-1501959227-8948.jpeg.jpg',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque dolor libero, semper quis posuere at, luctus eget lectus.'
  }
]

const MUSIC_ITEM = {
  id: '0',
  defaultCost: 6,
  defaultSupplier: 1,
  price: VARIANT_PRICE_ITEM,
  name: 'Music Name',
  image:
    'https://img.discogs.com/_zLgMkRnoWrEbYQqPFfOs4kRsgw=/fit-in/300x300/filters:strip_icc():format(jpeg):mode_rgb():quality(40)/discogs-images/R-5339445-1501959227-8948.jpeg.jpg',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque dolor libero, semper quis posuere at, luctus eget lectus.'
}
// music

// logistics
const LOGISTICS_LIST = [
  {
    id: '0',
    defaultCost: 6,
    defaultSupplier: 1,
    price: VARIANT_PRICE_ITEM,
    name: 'Logistics Name',
    image:
      'https://img.discogs.com/_zLgMkRnoWrEbYQqPFfOs4kRsgw=/fit-in/300x300/filters:strip_icc():format(jpeg):mode_rgb():quality(40)/discogs-images/R-5339445-1501959227-8948.jpeg.jpg',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque dolor libero, semper quis posuere at, luctus eget lectus.'
  },
  {
    id: '1',
    defaultCost: 6,
    defaultSupplier: 1,
    price: 10.99,
    name: 'Logistics Name',
    image:
      'https://img.discogs.com/_zLgMkRnoWrEbYQqPFfOs4kRsgw=/fit-in/300x300/filters:strip_icc():format(jpeg):mode_rgb():quality(40)/discogs-images/R-5339445-1501959227-8948.jpeg.jpg',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque dolor libero, semper quis posuere at, luctus eget lectus.'
  },
  {
    id: '2',
    defaultCost: 6,
    defaultSupplier: 1,
    price: VARIANT_PRICE_ITEM,
    name: 'Logistics Name',
    image:
      'https://img.discogs.com/_zLgMkRnoWrEbYQqPFfOs4kRsgw=/fit-in/300x300/filters:strip_icc():format(jpeg):mode_rgb():quality(40)/discogs-images/R-5339445-1501959227-8948.jpeg.jpg',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque dolor libero, semper quis posuere at, luctus eget lectus.'
  },
  {
    id: '3',
    defaultCost: 6,
    defaultSupplier: 1,
    price: VARIANT_PRICE_ITEM,
    name: 'Logistics Name',
    image:
      'https://img.discogs.com/_zLgMkRnoWrEbYQqPFfOs4kRsgw=/fit-in/300x300/filters:strip_icc():format(jpeg):mode_rgb():quality(40)/discogs-images/R-5339445-1501959227-8948.jpeg.jpg',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque dolor libero, semper quis posuere at, luctus eget lectus.'
  },
  {
    id: '4',
    defaultCost: 6,
    defaultSupplier: 1,
    price: VARIANT_PRICE_ITEM,
    name: 'Logistics Name',
    image:
      'https://img.discogs.com/_zLgMkRnoWrEbYQqPFfOs4kRsgw=/fit-in/300x300/filters:strip_icc():format(jpeg):mode_rgb():quality(40)/discogs-images/R-5339445-1501959227-8948.jpeg.jpg',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque dolor libero, semper quis posuere at, luctus eget lectus.'
  }
]

const LOGISTICS_ITEM = {
  id: '0',
  defaultCost: 6,
  defaultSupplier: 1,
  price: VARIANT_PRICE_ITEM,
  name: 'Logistics Name',
  image:
    'https://img.discogs.com/_zLgMkRnoWrEbYQqPFfOs4kRsgw=/fit-in/300x300/filters:strip_icc():format(jpeg):mode_rgb():quality(40)/discogs-images/R-5339445-1501959227-8948.jpeg.jpg',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque dolor libero, semper quis posuere at, luctus eget lectus.'
}
// logistics

// rent
const RENT_LIST = [
  {
    id: '0',
    defaultCost: 6,
    defaultSupplier: 1,
    price: VARIANT_PRICE_ITEM,
    name: 'Rent Name',
    image:
      'https://i.ebayimg.com/images/a/(KGrHqZ,!gwE17RNlW5PBNlYb9rTsg~~/s-l600.jpg',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque dolor libero, semper quis posuere at, luctus eget lectus.'
  },
  {
    id: '1',
    defaultCost: 6,
    defaultSupplier: 1,
    price: VARIANT_PRICE_ITEM,
    name: 'Rent Name',
    image:
      'https://i.ebayimg.com/images/a/(KGrHqZ,!gwE17RNlW5PBNlYb9rTsg~~/s-l600.jpg',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque dolor libero, semper quis posuere at, luctus eget lectus.'
  },
  {
    id: '2',
    defaultCost: 6,
    defaultSupplier: 1,
    price: VARIANT_PRICE_ITEM,
    name: 'Rent Name',
    image:
      'https://i.ebayimg.com/images/a/(KGrHqZ,!gwE17RNlW5PBNlYb9rTsg~~/s-l600.jpg',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque dolor libero, semper quis posuere at, luctus eget lectus.'
  },
  {
    id: '3',
    defaultCost: 6,
    defaultSupplier: 1,
    price: VARIANT_PRICE_ITEM,
    name: 'Rent Name',
    image:
      'https://i.ebayimg.com/images/a/(KGrHqZ,!gwE17RNlW5PBNlYb9rTsg~~/s-l600.jpg',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque dolor libero, semper quis posuere at, luctus eget lectus.'
  },
  {
    id: '4',
    defaultCost: 6,
    defaultSupplier: 1,
    price: VARIANT_PRICE_ITEM,
    name: 'Rent Name',
    image:
      'https://i.ebayimg.com/images/a/(KGrHqZ,!gwE17RNlW5PBNlYb9rTsg~~/s-l600.jpg',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque dolor libero, semper quis posuere at, luctus eget lectus.'
  }
]

const RENT_ITEM = {
  id: '0',
  defaultCost: 6,
  defaultSupplier: 1,
  price: VARIANT_PRICE_ITEM,
  name: 'Rent Name',
  image:
    'https://i.ebayimg.com/images/a/(KGrHqZ,!gwE17RNlW5PBNlYb9rTsg~~/s-l600.jpg',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque dolor libero, semper quis posuere at, luctus eget lectus.'
}

// rent

// food
const FOOD_LIST = [
  {
    id: '0',
    defaultCost: 6,
    defaultSupplier: 1,
    price: VARIANT_PRICE_ITEM,
    name: 'Food Name',
    options: PRODUCT_OPTIONS,
    image:
      'https://www.bbcgoodfood.com/sites/default/files/recipe/recipe-image/2017/03/easy-turkey-paella.jpg',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque dolor libero, semper quis posuere at, luctus eget lectus.'
  },
  {
    id: '1',
    defaultCost: 6,
    defaultSupplier: 1,
    price: VARIANT_PRICE_ITEM,
    name: 'Food Name',
    options: PRODUCT_OPTIONS,
    image:
      'https://www.bbcgoodfood.com/sites/default/files/recipe/recipe-image/2017/03/easy-turkey-paella.jpg',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque dolor libero, semper quis posuere at, luctus eget lectus.'
  },
  {
    id: '2',
    defaultCost: 6,
    defaultSupplier: 1,
    price: VARIANT_PRICE_ITEM,
    name: 'Food Name',
    options: PRODUCT_OPTIONS,
    image:
      'https://www.bbcgoodfood.com/sites/default/files/recipe/recipe-image/2017/03/easy-turkey-paella.jpg',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque dolor libero, semper quis posuere at, luctus eget lectus.'
  },
  {
    id: '3',
    defaultCost: 6,
    defaultSupplier: 1,
    price: VARIANT_PRICE_ITEM,
    name: 'Food Name',
    options: PRODUCT_OPTIONS,
    image:
      'https://www.bbcgoodfood.com/sites/default/files/recipe/recipe-image/2017/03/easy-turkey-paella.jpg',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque dolor libero, semper quis posuere at, luctus eget lectus.'
  },
  {
    id: '4',
    defaultCost: 6,
    defaultSupplier: 1,
    price: VARIANT_PRICE_ITEM,
    name: 'Food Name',
    options: PRODUCT_OPTIONS,
    image:
      'https://www.bbcgoodfood.com/sites/default/files/recipe/recipe-image/2017/03/easy-turkey-paella.jpg',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque dolor libero, semper quis posuere at, luctus eget lectus.'
  }
]

const FOOD_ITEM = {
  id: '0',
  defaultCost: 6,
  defaultSupplier: 1,
  price: VARIANT_PRICE_ITEM,
  name: 'Food Name',
  options: PRODUCT_OPTIONS,
  image:
    'https://www.bbcgoodfood.com/sites/default/files/recipe/recipe-image/2017/03/easy-turkey-paella.jpg',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque dolor libero, semper quis posuere at, luctus eget lectus.'
}

// food upselling
const FOOD_UPSELLING_LIST = [
  {
    id: '0',
    defaultCost: 6,
    defaultSupplier: 1,
    price: VARIANT_PRICE_ITEM,
    name: 'Food Upselling Name',
    image:
      'https://images-eu.ssl-images-amazon.com/images/I/318LA3A0IjL._AC_US200_.jpg',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque dolor libero, semper quis posuere at, luctus eget lectus.'
  },
  {
    id: '1',
    defaultCost: 6,
    defaultSupplier: 1,
    price: VARIANT_PRICE_ITEM,
    name: 'Food Upselling Name',
    image:
      'https://images-eu.ssl-images-amazon.com/images/I/318LA3A0IjL._AC_US200_.jpg',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque dolor libero, semper quis posuere at, luctus eget lectus.'
  },
  {
    id: '2',
    defaultCost: 6,
    defaultSupplier: 1,
    price: VARIANT_PRICE_ITEM,
    name: 'Food Upselling Name',
    image:
      'https://images-eu.ssl-images-amazon.com/images/I/318LA3A0IjL._AC_US200_.jpg',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque dolor libero, semper quis posuere at, luctus eget lectus.'
  },
  {
    id: '3',
    defaultCost: 6,
    defaultSupplier: 1,
    price: VARIANT_PRICE_ITEM,
    name: 'Food Upselling Name',
    image:
      'https://images-eu.ssl-images-amazon.com/images/I/318LA3A0IjL._AC_US200_.jpg',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque dolor libero, semper quis posuere at, luctus eget lectus.'
  },
  {
    id: '4',
    defaultCost: 6,
    defaultSupplier: 1,
    price: VARIANT_PRICE_ITEM,
    name: 'Food Upselling Name',
    image:
      'https://images-eu.ssl-images-amazon.com/images/I/318LA3A0IjL._AC_US200_.jpg',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque dolor libero, semper quis posuere at, luctus eget lectus.'
  }
]

const FOOD_UPSELLING_ITEM = {
  id: '0',
  defaultCost: 6,
  defaultSupplier: 1,
  price: VARIANT_PRICE_ITEM,
  name: 'Food Upselling Name',
  image:
    'https://images-eu.ssl-images-amazon.com/images/I/318LA3A0IjL._AC_US200_.jpg',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque dolor libero, semper quis posuere at, luctus eget lectus.'
}

// food upselling

const operation = {
  id: 0,
  name: 'Nome evento',
  startDate: '2011-10-05T14:48:00.000Z',
  customer: 'Client Name', // TODO: AnagraficaPrivato o AnagraficaBusiness
  contactPhone: '3472971971374',
  number: 34,
  address: 'Address test', // TODO: address object
  bartenderNotes: 'Bartender notes',
  bartenderOutfit: { id: 0, name: 'CASUAL' },
  bartenders: [
    {
      startTime: '2011-10-05T14:48:00.000Z',
      endTime: '2011-10-05T14:48:00.000Z',
      sitter: 'Mario Sitter'
    },
    {
      startTime: '2011-10-05T14:48:00.000Z',
      endTime: '2011-10-05T14:48:00.000Z',
      sitter: 'Carlo Sitter'
    }
  ],
  assistantNotes: 'Assistant notes test',
  assistantOutfit: 'KAAMPAY',
  assistants: [
    {
      startTime: '2011-10-05T14:48:00.000Z',
      endTime: '2011-10-05T14:48:00.000Z',
      sitter: 'Mario Sitter'
    },
    {
      startTime: '2011-10-05T14:48:00.000Z',
      endTime: '2011-10-05T14:48:00.000Z',
      sitter: 'Carlo Sitter'
    }
  ],
  cleanerNotes: 'Cleaner notes test',
  cleaners: [
    {
      startTime: '2011-10-05T14:48:00.000Z',
      endTime: '2011-10-05T14:48:00.000Z',
      sitter: 'Mario Cleaner'
    },
    {
      startTime: '2011-10-05T14:48:00.000Z',
      endTime: '2011-10-05T14:48:00.000Z',
      sitter: 'Carlo Cleaner'
    }
  ],
  cleanerServiceDate: '2011-10-05T14:48:00.000Z',
  drinkStatus: { id: 0, name: 'CREATED' },
  drinkCourier: 'Ferdinando Courier',
  drinks: [
    {
      id: 0,
      name: 'Drink Name'
    }
  ],
  drinkBags: 4,
  drinkWeight: 4,
  drinkBoxes: 5,
  drinkNotes: 'Drink notes test',
  foodDeliveryTime: '2011-10-05T14:48:00.000Z',
  foodStatus: { id: 0, name: 'CREATED' },
  foods: [
    {
      id: 0,
      name: 'Food Name'
    }
  ],
  foodGuests: 90,
  foodNotes: 'Foods notes test',
  musicStatus: { id: 0, name: 'CREATED' },
  musics: [
    { id: 0, name: 'Music 1' },
    { id: 1, name: 'Music 2' }
  ],
  musicPickupDate: '2011-10-05T14:48:00.000Z',
  musicNotes: 'Music notes test',
  musicDeliveryTime: '2011-10-05T14:48:00.000Z',
  rentStatus: { id: 0, name: 'CREATED' },
  rents: [
    { id: 0, name: 'Rent 1' },
    { id: 1, name: 'Rent 2' }
  ],
  rentPickupDate: '2011-10-05T14:48:00.000Z',
  rentNotes: 'Rent notes test',
  rentDeliveryTime: '2011-10-05T14:48:00.000Z'
}

// PROFESSIONAL TYPES

const PROFESSIONAL_TYPES_LIST = [
  {
    id: 0,
    name: 'Barristi'
  },
  {
    id: 1,
    name: 'Alfieri'
  },
  {
    id: 2,
    name: 'Balfieri'
  }
]

const PROFESSIONAL_TYPES_DETAIL = {
  id: '0',
  name: 'Barristi'
}

const P_FIGURE_LIST_BARRISTI = [
  {
    id: 0,
    name: 'Barrista 1',
    description: 'Uno dei tanti',
    image: {
      url: 'https://cdn.vox-cdn.com/uploads/chorus_asset/file/2664638/Goat.1351089485.jpg'
    },
    professionalType: {
      id: 0,
      name: 'Barristi'
    }
  },
  {
    id: 1,
    name: 'Barrista 12',
    description: 'Uno dei tanti',
    image: {
      url: 'https://cdn.vox-cdn.com/uploads/chorus_asset/file/2664638/Goat.1351089485.jpg'
    },
    professionalType: {
      id: 0,
      name: 'Barristi'
    }
  },
  {
    id: 2,
    name: 'Super Barrista',
    description: 'Non un semplice Barrista',
    image: {
      url: 'https://cdn.vox-cdn.com/uploads/chorus_asset/file/2664638/Goat.1351089485.jpg'
    },
    professionalType: {
      id: 0,
      name: 'Barristi'
    }
  }
]

const P_FIGURE_LIST_ALFIERI = [
  {
    id: 0,
    name: "Alfio l'alfiere",
    description: 'Un alfio che più alfio non si può',
    image: {
      url: 'https://cdn.vox-cdn.com/uploads/chorus_asset/file/2664638/Goat.1351089485.jpg'
    },
    professionalType: {
      id: 1,
      name: 'Alfieri'
    }
  },
  {
    id: 1,
    name: 'Balfio Balfiere',
    description: 'Un Balfio a me ed un Balfio a te',
    image: {
      url: 'https://cdn.vox-cdn.com/uploads/chorus_asset/file/2664638/Goat.1351089485.jpg'
    },
    professionalType: {
      id: 1,
      name: 'Alfieri'
    }
  }
]

const P_FIGURE_BALFIO = {
  id: 1,
  name: 'Balfio Balfiere',
  description: 'Un Balfio a me ed un Balfio a te',
  longDescription: [
    {
      title: 'Come Balfiere',
      items: ['Non lo batte nessuno']
    },
    {
      title: 'Un Balfio',
      items: ['Super Balfio', 'Super Balfio']
    }
  ],
  image: {
    url: 'https://cdn.vox-cdn.com/uploads/chorus_asset/file/2664638/Goat.1351089485.jpg'
  },
  professionalType: {
    id: 1,
    name: 'Alfieri'
  },
  minServiceHours: 4,
  component: {
    defaultCost: 10,
    price: VARIANT_PRICE_ITEM
  }
}

//food & virtual sections mocks
const FOOD_SECTION_ITEM = {
  component: {
    defaultCost: 10,
    price: {
      agencyA: 13.3,
      business: 13.97
    }
  },
  upsellingItems: [
    {
      logistics: [
        {
          component: {
            defaultCost: 95,
            price: {
              agencyA: 104.5,
              agencyB: 0,
              venue: 0,
              business: 104.5
            }
          },
          id: 1,
          name: 'Delivery con allestimento e ritiro',
          description:
            " Comprende: Consegna fino a 20 km da Milano |  Mis en place del buffet  | Noleggio e ritiro di tutti gli strumenti non a perdere (macchina del caffè, tovagliato, scaldavivande, microonde, ...)  | smaltimento dei rifiuti (No sbarazzo o pulizia) - Facchinaggio. L'allestimento sarà effettuato con tovaglie in cotone, vassoi e pirofile in alluminio o ceramica (Piatti in ceramica e posato in alluminio non sono inclusi)",
          facets: []
        }
      ],
      component: {
        defaultCost: 2.53,
        price: {
          agencyA: 4.15,
          business: 4.15
        }
      },
      id: 29,
      name: 'Tavolo per Buffet',
      description:
        'Tavolo per Buffet Tovagliato 180 x 100 (1 ogni 10 persone circa)',
      facets: []
    },
    {
      logistics: [
        {
          component: {
            defaultCost: 95,
            price: {
              agencyA: 104.5,
              agencyB: 0,
              venue: 0,
              business: 104.5
            }
          },
          id: 1,
          name: 'Delivery con allestimento e ritiro',
          description:
            " Comprende: Consegna fino a 20 km da Milano |  Mis en place del buffet  | Noleggio e ritiro di tutti gli strumenti non a perdere (macchina del caffè, tovagliato, scaldavivande, microonde, ...)  | smaltimento dei rifiuti (No sbarazzo o pulizia) - Facchinaggio. L'allestimento sarà effettuato con tovaglie in cotone, vassoi e pirofile in alluminio o ceramica (Piatti in ceramica e posato in alluminio non sono inclusi)",
          facets: []
        }
      ],
      component: {
        defaultCost: 3.16,
        price: {
          agencyA: 5.12,
          business: 5.12
        }
      },
      id: 30,
      name: 'Tavolo mangia in piedi',
      description: 'Tavolo mangia in piedi tovagliato (1 ogni 8 persone circa)',
      facets: []
    },
    {
      logistics: [
        {
          component: {
            defaultCost: 95,
            price: {
              agencyA: 104.5,
              agencyB: 0,
              venue: 0,
              business: 104.5
            }
          },
          id: 1,
          name: 'Delivery con allestimento e ritiro',
          description:
            " Comprende: Consegna fino a 20 km da Milano |  Mis en place del buffet  | Noleggio e ritiro di tutti gli strumenti non a perdere (macchina del caffè, tovagliato, scaldavivande, microonde, ...)  | smaltimento dei rifiuti (No sbarazzo o pulizia) - Facchinaggio. L'allestimento sarà effettuato con tovaglie in cotone, vassoi e pirofile in alluminio o ceramica (Piatti in ceramica e posato in alluminio non sono inclusi)",
          facets: []
        },
        {
          component: {
            defaultCost: 45,
            price: {
              agencyA: 49.5,
              business: 49.5
            }
          },
          id: 2,
          name: 'Delivery con solo allestimento',
          description:
            "Comprende: Consegna fino a 20 km da Milano | Mis en place del buffet | Tovagliato monouso | Facchinaggio. L'allestimento è effettuato con vassoi e pirofile materiali monouso.",
          facets: []
        },
        {
          component: {
            defaultCost: 35,
            price: {
              agencyA: 38.5,
              business: 38.5
            }
          },
          id: 3,
          name: 'Solo Delivery',
          description: 'Comprende:  Consegna fino a 20 km da Milano',
          facets: []
        }
      ],
      component: {
        defaultCost: 3.65,
        price: {
          agencyA: 4.88,
          business: 4.88
        }
      },
      id: 31,
      name: 'Mini pasticceria assortita',
      description: 'Mini pasticceria assortita 4 pezzi',
      facets: []
    },
    {
      logistics: [
        {
          component: {
            defaultCost: 95,
            price: {
              agencyA: 104.5,
              agencyB: 0,
              venue: 0,
              business: 104.5
            }
          },
          id: 1,
          name: 'Delivery con allestimento e ritiro',
          description:
            " Comprende: Consegna fino a 20 km da Milano |  Mis en place del buffet  | Noleggio e ritiro di tutti gli strumenti non a perdere (macchina del caffè, tovagliato, scaldavivande, microonde, ...)  | smaltimento dei rifiuti (No sbarazzo o pulizia) - Facchinaggio. L'allestimento sarà effettuato con tovaglie in cotone, vassoi e pirofile in alluminio o ceramica (Piatti in ceramica e posato in alluminio non sono inclusi)",
          facets: []
        },
        {
          component: {
            defaultCost: 45,
            price: {
              agencyA: 49.5,
              business: 49.5
            }
          },
          id: 2,
          name: 'Delivery con solo allestimento',
          description:
            "Comprende: Consegna fino a 20 km da Milano | Mis en place del buffet | Tovagliato monouso | Facchinaggio. L'allestimento è effettuato con vassoi e pirofile materiali monouso.",
          facets: []
        },
        {
          component: {
            defaultCost: 35,
            price: {
              agencyA: 38.5,
              business: 38.5
            }
          },
          id: 3,
          name: 'Solo Delivery',
          description: 'Comprende:  Consegna fino a 20 km da Milano',
          facets: []
        }
      ],
      component: {
        defaultCost: 3,
        price: {
          agencyA: 4.51,
          business: 4.51
        }
      },
      id: 32,
      name: 'Macedonia di frutta',
      description: 'Macedonia di frutta fresca di stagione',
      facets: []
    }
  ],
  logistics: [
    {
      component: {
        defaultCost: 95,
        price: {
          agencyA: 104.5,
          agencyB: 0,
          venue: 0,
          business: 104.5
        }
      },
      id: 1,
      name: 'Delivery con allestimento e ritiro',
      description:
        " Comprende: Consegna fino a 20 km da Milano |  Mis en place del buffet  | Noleggio e ritiro di tutti gli strumenti non a perdere (macchina del caffè, tovagliato, scaldavivande, microonde, ...)  | smaltimento dei rifiuti (No sbarazzo o pulizia) - Facchinaggio. L'allestimento sarà effettuato con tovaglie in cotone, vassoi e pirofile in alluminio o ceramica (Piatti in ceramica e posato in alluminio non sono inclusi)",
      facets: [],
      image: {
        id: '5939a655019e4bb9a2f6a134c88ec05c',
        url: 'https://images.staging.kampaay.com/5939a655019e4bb9a2f6a134c88ec05c'
      }
    },
    {
      component: {
        defaultCost: 45,
        price: {
          agencyA: 49.5,
          business: 49.5
        }
      },
      id: 2,
      name: 'Delivery con solo allestimento',
      description:
        "Comprende: Consegna fino a 20 km da Milano | Mis en place del buffet | Tovagliato monouso | Facchinaggio. L'allestimento è effettuato con vassoi e pirofile materiali monouso.",
      facets: [],
      image: {
        id: 'd41529f6ab38476eab7fff4df40bbe1b',
        url: 'https://images.staging.kampaay.com/d41529f6ab38476eab7fff4df40bbe1b'
      }
    },
    {
      component: {
        defaultCost: 35,
        price: {
          agencyA: 38.5,
          business: 38.5
        }
      },
      id: 3,
      name: 'Solo Delivery',
      description: 'Comprende:  Consegna fino a 20 km da Milano',
      facets: [],
      image: {
        id: '5758ec5f58924b29a1c5de98512918d2',
        url: 'https://images.staging.kampaay.com/5758ec5f58924b29a1c5de98512918d2'
      }
    }
  ],
  id: 95,
  facets: [],
  name: 'Food Box Basic',
  description: 'Pacchetto ideale per un pranzo o una cena di qualsiasi tipo',
  longDescription: [
    {
      title:
        'Il Food Box è la soluzione adatta a pranzi e cene sostanziose e veloci. Il servizio prevede la consegna in location di singoli box per ogni piatto scelto.\nLe proposte di menù possono essere personalizzate per incontrare specifiche esigenze o preferenze alimentari (vegetariano, vegano, senza glutine, altre intolleranze/allergie). \n\nIl pacchetto prevede: ',
      items: [
        'Primo piatto a scelta',
        'Contorno a scelta',
        'Acqua naturale/frizzante (0,5 litri a persona)',
        'Pane'
      ]
    }
  ],
  options: [
    {
      id: 661,
      name: 'Primo Piatto',
      maxSelection: 1,
      items: [
        {
          id: 4025,
          name: 'Mezze maniche alla Norma ',
          description:
            'Ingredienti: mezze maniche integrali, pomodoro, melanzane, basilico, ricotta salata (servito freddo) || Allergeni: Cereali e derivati, Latte',
          image: {
            id: '611f3841902b401ebc3f9f50635c919a',
            url: 'https://images.staging.kampaay.com/611f3841902b401ebc3f9f50635c919a'
          }
        },
        {
          id: 4026,
          name: 'Penne integrali ai datterini e burrata',
          description:
            'Ingredienti: penne integrali, datterini gialli e rossi, basilico, stracciatella di burrata, olive taggiasche (servito freddo) || Allergeni: Cereali e derivati, Latte',
          image: {
            id: '5eedc441c05f40baaf4b0a2af7bbf718',
            url: 'https://images.staging.kampaay.com/5eedc441c05f40baaf4b0a2af7bbf718'
          }
        },
        {
          id: 4027,
          name: 'Pennette al pomodoro e burrata',
          description:
            'Ingredienti: pennette, salsa pomodoro, basilico, stracciatella di burrata (servito freddo) || Allergeni: Cereali e derivati, Latte',
          image: {
            id: 'ab4157a18b3d4b36ad8e693e5dfc418a',
            url: 'https://images.staging.kampaay.com/ab4157a18b3d4b36ad8e693e5dfc418a'
          }
        },
        {
          id: 4028,
          name: 'Pennette speck, zafferano e zucchine',
          description:
            'Ingredienti: pennette integrali, crema di zafferano con formaggio fresco, speck, zucchine trifolate (servito freddo) || Allergeni: Cereali e derivati, Latte',
          image: {
            id: '608334e7b63944a882a99a13635cab1a',
            url: 'https://images.staging.kampaay.com/608334e7b63944a882a99a13635cab1a'
          }
        },
        {
          id: 4029,
          name: 'Lasagnette alla bolognese con ragù bianco',
          description:
            'Ingredienti: pasta fresca, manzo macinato, sedano, carote, cipolla, vino bianco, formaggio grattugiato || Allergeni: Cereali e derivati, Latte, Sedano, Uovo',
          image: {
            id: '28adb42cb3e4487bbd14a4b3ae396b27',
            url: 'https://images.staging.kampaay.com/28adb42cb3e4487bbd14a4b3ae396b27'
          }
        },
        {
          id: 4030,
          name: 'Lasagnette vegetariane ',
          description:
            'Ingredienti: pasta fresca, ortaggi di stagione (carote, zucchine, peperoni, fagiolini, melanzane), besciamella, formaggio grattugiato || Allergeni: Cereali e derivati, Latte, Uovo ',
          image: {
            id: '1bd48eebcfbe4c2d89d5ac8af3aeb9ee',
            url: 'https://images.staging.kampaay.com/1bd48eebcfbe4c2d89d5ac8af3aeb9ee'
          }
        },
        {
          id: 4031,
          name: 'Cannelloni ricotta e spinaci',
          description:
            'Ingredienti: pasta fresca, ricotta fresca, spinaci, noce moscata, formaggio grattugiato, uova || Allergeni: Cereali e derivati, Latte, Uova, Frutta guscio',
          image: {
            id: '9046bc5b2f44463c9e5a0d3915012e14',
            url: 'https://images.staging.kampaay.com/9046bc5b2f44463c9e5a0d3915012e14'
          }
        }
      ]
    },
    {
      id: 662,
      name: 'Contorno',
      maxSelection: 1,
      items: [
        {
          id: 4032,
          name: 'Melanzane al funghetto',
          description: 'Ingredienti: melanzane, pomodorini, basilico, menta',
          image: {
            id: '484685087f5d48e8b24783561e30fb98',
            url: 'https://images.staging.kampaay.com/484685087f5d48e8b24783561e30fb98'
          }
        },
        {
          id: 4033,
          name: 'Caponata/Ratatouille di verdure',
          description: 'Ingredienti: melanzane, pomodorini, basilico, menta',
          image: {
            id: '8dad6918af954ebca7900186f818df63',
            url: 'https://images.staging.kampaay.com/8dad6918af954ebca7900186f818df63'
          }
        },
        {
          id: 4034,
          name: 'Patate al forno',
          description: 'Ingredienti: patate, rosmarino',
          image: {
            id: 'e26e3cebffe2499094dfc1d85fb0ee84',
            url: 'https://images.staging.kampaay.com/e26e3cebffe2499094dfc1d85fb0ee84'
          }
        },
        {
          id: 4035,
          name: ' Mix di verdure saltate in padella',
          description: 'Ingredienti: zucchine, carote, peperoni, cipolla',
          image: {
            id: 'bd24aeda84e0482b8bdb782f729e540b',
            url: 'https://images.staging.kampaay.com/bd24aeda84e0482b8bdb782f729e540b'
          }
        },
        {
          id: 4036,
          name: 'Mix di verdure al vapore',
          description: 'Ingredienti: broccoli, cavolfiori, fagiolini'
        },
        {
          id: 4037,
          name: 'Cruditè di verdure',
          description: 'Ingredienti: broccoli, cavolfiori, fagiolini'
        }
      ]
    }
  ],
  image: {
    id: '0591aecdd78d44d3bfeaf6b7999dccc8',
    url: 'https://images.staging.kampaay.com/0591aecdd78d44d3bfeaf6b7999dccc8'
  },
  minQuantity: 15
}
const FOOD_SECTIONS = [
  {
    id: 1,
    name: 'test',
    description: '💩',
    products: [
      {
        component: {
          defaultCost: 10,
          price: {
            agencyA: 13.3,
            business: 13.97
          }
        },
        upsellingItems: [
          {
            logistics: [
              {
                component: {
                  defaultCost: 95,
                  price: {
                    agencyA: 104.5,
                    agencyB: 0,
                    venue: 0,
                    business: 104.5
                  }
                },
                id: 1,
                name: 'Delivery con allestimento e ritiro',
                description:
                  " Comprende: Consegna fino a 20 km da Milano |  Mis en place del buffet  | Noleggio e ritiro di tutti gli strumenti non a perdere (macchina del caffè, tovagliato, scaldavivande, microonde, ...)  | smaltimento dei rifiuti (No sbarazzo o pulizia) - Facchinaggio. L'allestimento sarà effettuato con tovaglie in cotone, vassoi e pirofile in alluminio o ceramica (Piatti in ceramica e posato in alluminio non sono inclusi)",
                facets: []
              }
            ],
            component: {
              defaultCost: 2.53,
              price: {
                agencyA: 4.15,
                business: 4.15
              }
            },
            id: 29,
            name: 'Tavolo per Buffet',
            description:
              'Tavolo per Buffet Tovagliato 180 x 100 (1 ogni 10 persone circa)',
            facets: []
          },
          {
            logistics: [
              {
                component: {
                  defaultCost: 95,
                  price: {
                    agencyA: 104.5,
                    agencyB: 0,
                    venue: 0,
                    business: 104.5
                  }
                },
                id: 1,
                name: 'Delivery con allestimento e ritiro',
                description:
                  " Comprende: Consegna fino a 20 km da Milano |  Mis en place del buffet  | Noleggio e ritiro di tutti gli strumenti non a perdere (macchina del caffè, tovagliato, scaldavivande, microonde, ...)  | smaltimento dei rifiuti (No sbarazzo o pulizia) - Facchinaggio. L'allestimento sarà effettuato con tovaglie in cotone, vassoi e pirofile in alluminio o ceramica (Piatti in ceramica e posato in alluminio non sono inclusi)",
                facets: []
              }
            ],
            component: {
              defaultCost: 3.16,
              price: {
                agencyA: 5.12,
                business: 5.12
              }
            },
            id: 30,
            name: 'Tavolo mangia in piedi',
            description:
              'Tavolo mangia in piedi tovagliato (1 ogni 8 persone circa)',
            facets: []
          },
          {
            logistics: [
              {
                component: {
                  defaultCost: 95,
                  price: {
                    agencyA: 104.5,
                    agencyB: 0,
                    venue: 0,
                    business: 104.5
                  }
                },
                id: 1,
                name: 'Delivery con allestimento e ritiro',
                description:
                  " Comprende: Consegna fino a 20 km da Milano |  Mis en place del buffet  | Noleggio e ritiro di tutti gli strumenti non a perdere (macchina del caffè, tovagliato, scaldavivande, microonde, ...)  | smaltimento dei rifiuti (No sbarazzo o pulizia) - Facchinaggio. L'allestimento sarà effettuato con tovaglie in cotone, vassoi e pirofile in alluminio o ceramica (Piatti in ceramica e posato in alluminio non sono inclusi)",
                facets: []
              },
              {
                component: {
                  defaultCost: 45,
                  price: {
                    agencyA: 49.5,
                    business: 49.5
                  }
                },
                id: 2,
                name: 'Delivery con solo allestimento',
                description:
                  "Comprende: Consegna fino a 20 km da Milano | Mis en place del buffet | Tovagliato monouso | Facchinaggio. L'allestimento è effettuato con vassoi e pirofile materiali monouso.",
                facets: []
              },
              {
                component: {
                  defaultCost: 35,
                  price: {
                    agencyA: 38.5,
                    business: 38.5
                  }
                },
                id: 3,
                name: 'Solo Delivery',
                description: 'Comprende:  Consegna fino a 20 km da Milano',
                facets: []
              }
            ],
            component: {
              defaultCost: 3.65,
              price: {
                agencyA: 4.88,
                business: 4.88
              }
            },
            id: 31,
            name: 'Mini pasticceria assortita',
            description: 'Mini pasticceria assortita 4 pezzi',
            facets: []
          },
          {
            logistics: [
              {
                component: {
                  defaultCost: 95,
                  price: {
                    agencyA: 104.5,
                    agencyB: 0,
                    venue: 0,
                    business: 104.5
                  }
                },
                id: 1,
                name: 'Delivery con allestimento e ritiro',
                description:
                  " Comprende: Consegna fino a 20 km da Milano |  Mis en place del buffet  | Noleggio e ritiro di tutti gli strumenti non a perdere (macchina del caffè, tovagliato, scaldavivande, microonde, ...)  | smaltimento dei rifiuti (No sbarazzo o pulizia) - Facchinaggio. L'allestimento sarà effettuato con tovaglie in cotone, vassoi e pirofile in alluminio o ceramica (Piatti in ceramica e posato in alluminio non sono inclusi)",
                facets: []
              },
              {
                component: {
                  defaultCost: 45,
                  price: {
                    agencyA: 49.5,
                    business: 49.5
                  }
                },
                id: 2,
                name: 'Delivery con solo allestimento',
                description:
                  "Comprende: Consegna fino a 20 km da Milano | Mis en place del buffet | Tovagliato monouso | Facchinaggio. L'allestimento è effettuato con vassoi e pirofile materiali monouso.",
                facets: []
              },
              {
                component: {
                  defaultCost: 35,
                  price: {
                    agencyA: 38.5,
                    business: 38.5
                  }
                },
                id: 3,
                name: 'Solo Delivery',
                description: 'Comprende:  Consegna fino a 20 km da Milano',
                facets: []
              }
            ],
            component: {
              defaultCost: 3,
              price: {
                agencyA: 4.51,
                business: 4.51
              }
            },
            id: 32,
            name: 'Macedonia di frutta',
            description: 'Macedonia di frutta fresca di stagione',
            facets: []
          }
        ],
        logistics: [
          {
            component: {
              defaultCost: 95,
              price: {
                agencyA: 104.5,
                agencyB: 0,
                venue: 0,
                business: 104.5
              }
            },
            id: 1,
            name: 'Delivery con allestimento e ritiro',
            description:
              " Comprende: Consegna fino a 20 km da Milano |  Mis en place del buffet  | Noleggio e ritiro di tutti gli strumenti non a perdere (macchina del caffè, tovagliato, scaldavivande, microonde, ...)  | smaltimento dei rifiuti (No sbarazzo o pulizia) - Facchinaggio. L'allestimento sarà effettuato con tovaglie in cotone, vassoi e pirofile in alluminio o ceramica (Piatti in ceramica e posato in alluminio non sono inclusi)",
            facets: [],
            image: {
              id: '5939a655019e4bb9a2f6a134c88ec05c',
              url: 'https://images.staging.kampaay.com/5939a655019e4bb9a2f6a134c88ec05c'
            }
          },
          {
            component: {
              defaultCost: 45,
              price: {
                agencyA: 49.5,
                business: 49.5
              }
            },
            id: 2,
            name: 'Delivery con solo allestimento',
            description:
              "Comprende: Consegna fino a 20 km da Milano | Mis en place del buffet | Tovagliato monouso | Facchinaggio. L'allestimento è effettuato con vassoi e pirofile materiali monouso.",
            facets: [],
            image: {
              id: 'd41529f6ab38476eab7fff4df40bbe1b',
              url: 'https://images.staging.kampaay.com/d41529f6ab38476eab7fff4df40bbe1b'
            }
          },
          {
            component: {
              defaultCost: 35,
              price: {
                agencyA: 38.5,
                business: 38.5
              }
            },
            id: 3,
            name: 'Solo Delivery',
            description: 'Comprende:  Consegna fino a 20 km da Milano',
            facets: [],
            image: {
              id: '5758ec5f58924b29a1c5de98512918d2',
              url: 'https://images.staging.kampaay.com/5758ec5f58924b29a1c5de98512918d2'
            }
          }
        ],
        id: 95,
        facets: [],
        name: 'Food Box Basic',
        description:
          'Pacchetto ideale per un pranzo o una cena di qualsiasi tipo',
        longDescription: [
          {
            title:
              'Il Food Box è la soluzione adatta a pranzi e cene sostanziose e veloci. Il servizio prevede la consegna in location di singoli box per ogni piatto scelto.\nLe proposte di menù possono essere personalizzate per incontrare specifiche esigenze o preferenze alimentari (vegetariano, vegano, senza glutine, altre intolleranze/allergie). \n\nIl pacchetto prevede: ',
            items: [
              'Primo piatto a scelta',
              'Contorno a scelta',
              'Acqua naturale/frizzante (0,5 litri a persona)',
              'Pane'
            ]
          }
        ],
        options: [
          {
            id: 661,
            name: 'Primo Piatto',
            maxSelection: 1,
            items: [
              {
                id: 4025,
                name: 'Mezze maniche alla Norma ',
                description:
                  'Ingredienti: mezze maniche integrali, pomodoro, melanzane, basilico, ricotta salata (servito freddo) || Allergeni: Cereali e derivati, Latte',
                image: {
                  id: '611f3841902b401ebc3f9f50635c919a',
                  url: 'https://images.staging.kampaay.com/611f3841902b401ebc3f9f50635c919a'
                }
              },
              {
                id: 4026,
                name: 'Penne integrali ai datterini e burrata',
                description:
                  'Ingredienti: penne integrali, datterini gialli e rossi, basilico, stracciatella di burrata, olive taggiasche (servito freddo) || Allergeni: Cereali e derivati, Latte',
                image: {
                  id: '5eedc441c05f40baaf4b0a2af7bbf718',
                  url: 'https://images.staging.kampaay.com/5eedc441c05f40baaf4b0a2af7bbf718'
                }
              },
              {
                id: 4027,
                name: 'Pennette al pomodoro e burrata',
                description:
                  'Ingredienti: pennette, salsa pomodoro, basilico, stracciatella di burrata (servito freddo) || Allergeni: Cereali e derivati, Latte',
                image: {
                  id: 'ab4157a18b3d4b36ad8e693e5dfc418a',
                  url: 'https://images.staging.kampaay.com/ab4157a18b3d4b36ad8e693e5dfc418a'
                }
              },
              {
                id: 4028,
                name: 'Pennette speck, zafferano e zucchine',
                description:
                  'Ingredienti: pennette integrali, crema di zafferano con formaggio fresco, speck, zucchine trifolate (servito freddo) || Allergeni: Cereali e derivati, Latte',
                image: {
                  id: '608334e7b63944a882a99a13635cab1a',
                  url: 'https://images.staging.kampaay.com/608334e7b63944a882a99a13635cab1a'
                }
              },
              {
                id: 4029,
                name: 'Lasagnette alla bolognese con ragù bianco',
                description:
                  'Ingredienti: pasta fresca, manzo macinato, sedano, carote, cipolla, vino bianco, formaggio grattugiato || Allergeni: Cereali e derivati, Latte, Sedano, Uovo',
                image: {
                  id: '28adb42cb3e4487bbd14a4b3ae396b27',
                  url: 'https://images.staging.kampaay.com/28adb42cb3e4487bbd14a4b3ae396b27'
                }
              },
              {
                id: 4030,
                name: 'Lasagnette vegetariane ',
                description:
                  'Ingredienti: pasta fresca, ortaggi di stagione (carote, zucchine, peperoni, fagiolini, melanzane), besciamella, formaggio grattugiato || Allergeni: Cereali e derivati, Latte, Uovo ',
                image: {
                  id: '1bd48eebcfbe4c2d89d5ac8af3aeb9ee',
                  url: 'https://images.staging.kampaay.com/1bd48eebcfbe4c2d89d5ac8af3aeb9ee'
                }
              },
              {
                id: 4031,
                name: 'Cannelloni ricotta e spinaci',
                description:
                  'Ingredienti: pasta fresca, ricotta fresca, spinaci, noce moscata, formaggio grattugiato, uova || Allergeni: Cereali e derivati, Latte, Uova, Frutta guscio',
                image: {
                  id: '9046bc5b2f44463c9e5a0d3915012e14',
                  url: 'https://images.staging.kampaay.com/9046bc5b2f44463c9e5a0d3915012e14'
                }
              }
            ]
          },
          {
            id: 662,
            name: 'Contorno',
            maxSelection: 1,
            items: [
              {
                id: 4032,
                name: 'Melanzane al funghetto',
                description:
                  'Ingredienti: melanzane, pomodorini, basilico, menta',
                image: {
                  id: '484685087f5d48e8b24783561e30fb98',
                  url: 'https://images.staging.kampaay.com/484685087f5d48e8b24783561e30fb98'
                }
              },
              {
                id: 4033,
                name: 'Caponata/Ratatouille di verdure',
                description:
                  'Ingredienti: melanzane, pomodorini, basilico, menta',
                image: {
                  id: '8dad6918af954ebca7900186f818df63',
                  url: 'https://images.staging.kampaay.com/8dad6918af954ebca7900186f818df63'
                }
              },
              {
                id: 4034,
                name: 'Patate al forno',
                description: 'Ingredienti: patate, rosmarino',
                image: {
                  id: 'e26e3cebffe2499094dfc1d85fb0ee84',
                  url: 'https://images.staging.kampaay.com/e26e3cebffe2499094dfc1d85fb0ee84'
                }
              },
              {
                id: 4035,
                name: ' Mix di verdure saltate in padella',
                description: 'Ingredienti: zucchine, carote, peperoni, cipolla',
                image: {
                  id: 'bd24aeda84e0482b8bdb782f729e540b',
                  url: 'https://images.staging.kampaay.com/bd24aeda84e0482b8bdb782f729e540b'
                }
              },
              {
                id: 4036,
                name: 'Mix di verdure al vapore',
                description: 'Ingredienti: broccoli, cavolfiori, fagiolini'
              },
              {
                id: 4037,
                name: 'Cruditè di verdure',
                description: 'Ingredienti: broccoli, cavolfiori, fagiolini'
              }
            ]
          }
        ],
        image: {
          id: '0591aecdd78d44d3bfeaf6b7999dccc8',
          url: 'https://images.staging.kampaay.com/0591aecdd78d44d3bfeaf6b7999dccc8'
        },
        minQuantity: 15
      },
      {
        component: {
          defaultCost: 6,
          price: {
            agencyA: 8.03,
            business: 8.03
          }
        },
        upsellingItems: [
          {
            logistics: [
              {
                component: {
                  defaultCost: 95,
                  price: {
                    agencyA: 104.5,
                    agencyB: 0,
                    venue: 0,
                    business: 104.5
                  }
                },
                id: 1,
                name: 'Delivery con allestimento e ritiro',
                description:
                  " Comprende: Consegna fino a 20 km da Milano |  Mis en place del buffet  | Noleggio e ritiro di tutti gli strumenti non a perdere (macchina del caffè, tovagliato, scaldavivande, microonde, ...)  | smaltimento dei rifiuti (No sbarazzo o pulizia) - Facchinaggio. L'allestimento sarà effettuato con tovaglie in cotone, vassoi e pirofile in alluminio o ceramica (Piatti in ceramica e posato in alluminio non sono inclusi)",
                facets: []
              }
            ],
            component: {
              defaultCost: 2.53,
              price: {
                agencyA: 4.15,
                business: 4.15
              }
            },
            id: 29,
            name: 'Tavolo per Buffet',
            description:
              'Tavolo per Buffet Tovagliato 180 x 100 (1 ogni 10 persone circa)',
            facets: []
          },
          {
            logistics: [
              {
                component: {
                  defaultCost: 95,
                  price: {
                    agencyA: 104.5,
                    agencyB: 0,
                    venue: 0,
                    business: 104.5
                  }
                },
                id: 1,
                name: 'Delivery con allestimento e ritiro',
                description:
                  " Comprende: Consegna fino a 20 km da Milano |  Mis en place del buffet  | Noleggio e ritiro di tutti gli strumenti non a perdere (macchina del caffè, tovagliato, scaldavivande, microonde, ...)  | smaltimento dei rifiuti (No sbarazzo o pulizia) - Facchinaggio. L'allestimento sarà effettuato con tovaglie in cotone, vassoi e pirofile in alluminio o ceramica (Piatti in ceramica e posato in alluminio non sono inclusi)",
                facets: []
              }
            ],
            component: {
              defaultCost: 3.16,
              price: {
                agencyA: 5.12,
                business: 5.12
              }
            },
            id: 30,
            name: 'Tavolo mangia in piedi',
            description:
              'Tavolo mangia in piedi tovagliato (1 ogni 8 persone circa)',
            facets: []
          },
          {
            logistics: [
              {
                component: {
                  defaultCost: 95,
                  price: {
                    agencyA: 104.5,
                    agencyB: 0,
                    venue: 0,
                    business: 104.5
                  }
                },
                id: 1,
                name: 'Delivery con allestimento e ritiro',
                description:
                  " Comprende: Consegna fino a 20 km da Milano |  Mis en place del buffet  | Noleggio e ritiro di tutti gli strumenti non a perdere (macchina del caffè, tovagliato, scaldavivande, microonde, ...)  | smaltimento dei rifiuti (No sbarazzo o pulizia) - Facchinaggio. L'allestimento sarà effettuato con tovaglie in cotone, vassoi e pirofile in alluminio o ceramica (Piatti in ceramica e posato in alluminio non sono inclusi)",
                facets: []
              },
              {
                component: {
                  defaultCost: 45,
                  price: {
                    agencyA: 49.5,
                    business: 49.5
                  }
                },
                id: 2,
                name: 'Delivery con solo allestimento',
                description:
                  "Comprende: Consegna fino a 20 km da Milano | Mis en place del buffet | Tovagliato monouso | Facchinaggio. L'allestimento è effettuato con vassoi e pirofile materiali monouso.",
                facets: []
              },
              {
                component: {
                  defaultCost: 35,
                  price: {
                    agencyA: 38.5,
                    business: 38.5
                  }
                },
                id: 3,
                name: 'Solo Delivery',
                description: 'Comprende:  Consegna fino a 20 km da Milano',
                facets: []
              }
            ],
            component: {
              defaultCost: 3.65,
              price: {
                agencyA: 4.88,
                business: 4.88
              }
            },
            id: 31,
            name: 'Mini pasticceria assortita',
            description: 'Mini pasticceria assortita 4 pezzi',
            facets: []
          },
          {
            logistics: [
              {
                component: {
                  defaultCost: 95,
                  price: {
                    agencyA: 104.5,
                    agencyB: 0,
                    venue: 0,
                    business: 104.5
                  }
                },
                id: 1,
                name: 'Delivery con allestimento e ritiro',
                description:
                  " Comprende: Consegna fino a 20 km da Milano |  Mis en place del buffet  | Noleggio e ritiro di tutti gli strumenti non a perdere (macchina del caffè, tovagliato, scaldavivande, microonde, ...)  | smaltimento dei rifiuti (No sbarazzo o pulizia) - Facchinaggio. L'allestimento sarà effettuato con tovaglie in cotone, vassoi e pirofile in alluminio o ceramica (Piatti in ceramica e posato in alluminio non sono inclusi)",
                facets: []
              },
              {
                component: {
                  defaultCost: 45,
                  price: {
                    agencyA: 49.5,
                    business: 49.5
                  }
                },
                id: 2,
                name: 'Delivery con solo allestimento',
                description:
                  "Comprende: Consegna fino a 20 km da Milano | Mis en place del buffet | Tovagliato monouso | Facchinaggio. L'allestimento è effettuato con vassoi e pirofile materiali monouso.",
                facets: []
              },
              {
                component: {
                  defaultCost: 35,
                  price: {
                    agencyA: 38.5,
                    business: 38.5
                  }
                },
                id: 3,
                name: 'Solo Delivery',
                description: 'Comprende:  Consegna fino a 20 km da Milano',
                facets: []
              }
            ],
            component: {
              defaultCost: 3,
              price: {
                agencyA: 4.51,
                business: 4.51
              }
            },
            id: 32,
            name: 'Macedonia di frutta',
            description: 'Macedonia di frutta fresca di stagione',
            facets: []
          }
        ],
        logistics: [
          {
            component: {
              defaultCost: 95,
              price: {
                agencyA: 104.5,
                agencyB: 0,
                venue: 0,
                business: 104.5
              }
            },
            id: 1,
            name: 'Delivery con allestimento e ritiro',
            description:
              " Comprende: Consegna fino a 20 km da Milano |  Mis en place del buffet  | Noleggio e ritiro di tutti gli strumenti non a perdere (macchina del caffè, tovagliato, scaldavivande, microonde, ...)  | smaltimento dei rifiuti (No sbarazzo o pulizia) - Facchinaggio. L'allestimento sarà effettuato con tovaglie in cotone, vassoi e pirofile in alluminio o ceramica (Piatti in ceramica e posato in alluminio non sono inclusi)",
            facets: [],
            image: {
              id: '5939a655019e4bb9a2f6a134c88ec05c',
              url: 'https://images.staging.kampaay.com/5939a655019e4bb9a2f6a134c88ec05c'
            }
          }
        ],
        id: 84,
        facets: [],
        name: 'Welcome Coffe Basic (Dolce)',
        description:
          'Welcome Coffe Dolce ideale per i tuoi business meetings, convention, show room, ecc.',
        longDescription: [
          {
            title: 'Welcome Coffee Dolce a base di:',
            items: [
              'Caffè in cialde (2 cialde)',
              'Thè in busta o thermos',
              'Macchina del caffè',
              'Mix di Succhi Freschi (confezione singola in vetro)',
              'Biscotteria secca assortita (3 pezzi a persona)',
              'Mini croissant/plumcake/pasticceria assortita (3 pezzi assortiti a persona a seconda della disponibilità)'
            ]
          },
          {
            title:
              'In ottemperanza alle norme vigenti, per gli standing catering è necessario avere almeno un cameriere',
            items: []
          }
        ],
        options: [],
        image: {
          id: '4b892cbe6d7c4ef89d2b598e75017bc1',
          url: 'https://images.staging.kampaay.com/4b892cbe6d7c4ef89d2b598e75017bc1'
        },
        minQuantity: 15
      }
    ]
  }
]
//physical mocks are made with virtual mocks
const PHYSICAL_LIST = [
  {
    component: {
      perGuestDefaultCost: 25.49,
      perEventDefaultCost: 0,
      margin: {
        agencyA: 25.03,
        business: 25.03
      }
    },
    upsellingItems: [
      {
        component: {
          perGuestDefaultCost: 4.2,
          perEventDefaultCost: 0,
          margin: {
            agencyA: 25,
            business: 25
          }
        },
        id: 24,
        name: 'Tumbler personalizzato',
        description:
          'Bicchiere da cocktail in vetro personalizzato per il tuo evento (minimo 10 pz)',
        facets: [],
        images: []
      },
      {
        component: {
          perGuestDefaultCost: 3.5,
          perEventDefaultCost: 0,
          margin: {
            agencyA: 25.53,
            business: 25.53
          }
        },
        id: 25,
        name: 'Tumbler non personalizzato',
        description:
          'Bicchiere da cocktail in vetro non personalizzato per il tuo evento (minimo 10 pz)',
        facets: [],
        images: []
      },
      {
        component: {
          perGuestDefaultCost: 2.5,
          perEventDefaultCost: 0,
          margin: {
            agencyA: 24.24,
            business: 24.24
          }
        },
        id: 26,
        name: 'Apribottiglie personalizzato',
        description: "Apribottiglie con logo dell'azienda (minimo 100 pz)",
        facets: [],
        images: []
      },
      {
        component: {
          perGuestDefaultCost: 10,
          perEventDefaultCost: 0,
          margin: {
            agencyA: 16.67,
            business: 16.67
          }
        },
        id: 33,
        name: 'Packaging su stampa digitale',
        description:
          'Packaging con stampa digitale con la personalizzazione da te preferita',
        facets: [],
        images: []
      },
      {
        component: {
          perGuestDefaultCost: 0.2,
          perEventDefaultCost: 0,
          margin: {
            agencyA: 86.67,
            business: 86.67
          }
        },
        id: 34,
        name: 'Flyer A5',
        description: 'Flyer A5 grammatura 160 g',
        facets: [],
        images: []
      },
      {
        component: {
          perGuestDefaultCost: 0.2,
          perEventDefaultCost: 0,
          margin: {
            agencyA: 86.67,
            business: 86.67
          }
        },
        id: 35,
        name: 'Sticker personalizzato',
        description: 'Sticker personalizzato con il logo della tua azienda',
        facets: [],
        images: []
      },
      {
        component: {
          perGuestDefaultCost: 1.43,
          perEventDefaultCost: 0,
          margin: {
            agencyA: 20.56,
            business: 20.56
          }
        },
        id: 38,
        name: 'Taralli artigianali',
        description: 'Taralli artigianali pugliesi classici (100g)',
        facets: [],
        images: []
      },
      {
        component: {
          perGuestDefaultCost: 1.3,
          perEventDefaultCost: 0,
          margin: {
            agencyA: 23.53,
            business: 23.53
          }
        },
        id: 48,
        name: 'Cola BIO',
        description:
          'Soft Drink in Vetro Bevande Cortese Originale 1959 BIO (27cl)',
        facets: [],
        images: []
      },
      {
        component: {
          perGuestDefaultCost: 1.3,
          perEventDefaultCost: 0,
          margin: {
            agencyA: 23.52,
            business: 23.52
          }
        },
        id: 49,
        name: 'Aranciata BIO',
        description:
          'Soft Drink in Vetro Bevande Cortese Originale 1959 BIO (27cl)',
        facets: [],
        images: []
      }
    ],
    maxQuantity: 400,
    options: [
      {
        id: 523,
        name: 'Food',
        items: [
          {
            id: 2323,
            name: 'L’imprevedibile',
            description:
              'Alici Sott’olio (130g) con Crema di Parmigiano Reggiano e tartufo (30g)',
            quantity: 0,
            image: {
              id: 'ed094cd1e0564e119880a1ea6c898d7e',
              url: 'https://images.staging.kampaay.com/ed094cd1e0564e119880a1ea6c898d7e'
            }
          },
          {
            id: 2324,
            name: 'Il vivace',
            description:
              'Giardiniera (250g) con Crema di carciofi con tartufo (30g)',
            quantity: 0,
            image: {
              id: '29eceeb9e9a2483b9111b2e3af22970a',
              url: 'https://images.staging.kampaay.com/29eceeb9e9a2483b9111b2e3af22970a'
            }
          },
          {
            id: 2325,
            name: 'L’eccentrico',
            description:
              'Salame sottovuoto di cinghiale (170g) con Crema di Parmigiano Reggiano e tartufo (30g)',
            quantity: 0,
            image: {
              id: '517ec9ac4a7542d7b79ffb15a48a08d0',
              url: 'https://images.staging.kampaay.com/517ec9ac4a7542d7b79ffb15a48a08d0'
            }
          }
        ]
      },
      {
        id: 524,
        name: 'Acqua',
        items: [
          {
            id: 2326,
            name: 'Acqua naturale',
            description: 'Acqua naturale Surgiva vetro (50cl)',
            quantity: 0,
            image: {
              id: '662023ff85164499bfdf7f5cfff689bf',
              url: 'https://images.staging.kampaay.com/662023ff85164499bfdf7f5cfff689bf'
            }
          },
          {
            id: 2327,
            name: 'Acqua frizzante',
            description: 'Acqua frizzante Surgiva (50cl)',
            quantity: 0,
            image: {
              id: '2bf237ff7ed54cb09f9b17b393762c36',
              url: 'https://images.staging.kampaay.com/2bf237ff7ed54cb09f9b17b393762c36'
            }
          }
        ]
      },
      {
        id: 525,
        name: 'Drink',
        items: [
          {
            id: 2328,
            name: 'Succo fresco di stagione con Mirtillo',
            description: 'Succo fresco di stagione con Mirtillo (125cl)',
            quantity: 0,
            image: {
              id: '93244530260047cf8733bdfaeed3c029',
              url: 'https://images.staging.kampaay.com/93244530260047cf8733bdfaeed3c029'
            }
          },
          {
            id: 2329,
            name: 'Succo fresco di stagione con Mela, Lemon e Ginger',
            description:
              'Succo fresco di stagione con Mela, Lemon e Ginger (125cl)',
            quantity: 0,
            image: {
              id: 'f47b9fb647384de1b6694d4be34304b4',
              url: 'https://images.staging.kampaay.com/f47b9fb647384de1b6694d4be34304b4'
            }
          },
          {
            id: 2330,
            name: 'Succo fresco di stagione con Melagrana',
            description: 'Succo fresco di stagione con Melagrana (125cl)',
            quantity: 0,
            image: {
              id: 'dfdb446adf424223a8575a3b3ac9c3e2',
              url: 'https://images.staging.kampaay.com/dfdb446adf424223a8575a3b3ac9c3e2'
            }
          },
          {
            id: 2331,
            name: 'Cola',
            description:
              'Soft drink in vetro Cortese Originale 1959 BIO (27cl)',
            quantity: 0,
            image: {
              id: '9a5eaf4ef92a4d25a55a30becbf5e6af',
              url: 'https://images.staging.kampaay.com/9a5eaf4ef92a4d25a55a30becbf5e6af'
            }
          },
          {
            id: 2332,
            name: 'Limonata',
            description:
              'Soft drink in vetro Cortese Originale 1959 BIO (27cl)',
            quantity: 0,
            image: {
              id: '615ebc3377474366b0d9e9126feb4d7f',
              url: 'https://images.staging.kampaay.com/615ebc3377474366b0d9e9126feb4d7f'
            }
          },
          {
            id: 2333,
            name: 'Aranciata',
            description:
              'Soft drink in vetro Cortese Originale 1959 BIO (27cl)',
            quantity: 0,
            image: {
              id: '3b0b13e4380a4fbab692da6f0fa232a7',
              url: 'https://images.staging.kampaay.com/3b0b13e4380a4fbab692da6f0fa232a7'
            }
          }
        ]
      }
    ],
    minQuantity: 0,
    sections: [
      {
        description:
          'Il Virtual Catering è studiato per rendere il tuo meeting o la tua convention un momento memorabile e unico.',
        id: 96,
        name: 'Virtual Catering'
      }
    ],
    highlights: [],
    id: 37,
    name: 'Lunch Base',
    description:
      'Il Virtual Catering è il servizio pensato da Kampaay per portare a tutti i partecipanti l’esperienza del catering all’interno del tuo evento virtuale.',
    facets: [],
    images: [
      {
        id: '2ddd6af4aa7342e4bee5dc67abed157d',
        url: 'https://images.staging.kampaay.com/2ddd6af4aa7342e4bee5dc67abed157d'
      }
    ]
  }
]

const PHYSICAL_LIST_ITEM = {
  component: {
    perGuestDefaultCost: 25.49,
    perEventDefaultCost: 0,
    margin: {
      agencyA: 25.03,
      business: 25.03
    }
  },
  upsellingItems: [
    {
      component: {
        perGuestDefaultCost: 4.2,
        perEventDefaultCost: 0,
        margin: {
          agencyA: 25,
          business: 25
        }
      },
      id: 24,
      name: 'Tumbler personalizzato',
      description:
        'Bicchiere da cocktail in vetro personalizzato per il tuo evento (minimo 10 pz)',
      facets: [],
      images: []
    },
    {
      component: {
        perGuestDefaultCost: 3.5,
        perEventDefaultCost: 0,
        margin: {
          agencyA: 25.53,
          business: 25.53
        }
      },
      id: 25,
      name: 'Tumbler non personalizzato',
      description:
        'Bicchiere da cocktail in vetro non personalizzato per il tuo evento (minimo 10 pz)',
      facets: [],
      images: []
    },
    {
      component: {
        perGuestDefaultCost: 2.5,
        perEventDefaultCost: 0,
        margin: {
          agencyA: 24.24,
          business: 24.24
        }
      },
      id: 26,
      name: 'Apribottiglie personalizzato',
      description: "Apribottiglie con logo dell'azienda (minimo 100 pz)",
      facets: [],
      images: []
    },
    {
      component: {
        perGuestDefaultCost: 10,
        perEventDefaultCost: 0,
        margin: {
          agencyA: 16.67,
          business: 16.67
        }
      },
      id: 33,
      name: 'Packaging su stampa digitale',
      description:
        'Packaging con stampa digitale con la personalizzazione da te preferita',
      facets: [],
      images: []
    },
    {
      component: {
        perGuestDefaultCost: 0.2,
        perEventDefaultCost: 0,
        margin: {
          agencyA: 86.67,
          business: 86.67
        }
      },
      id: 34,
      name: 'Flyer A5',
      description: 'Flyer A5 grammatura 160 g',
      facets: [],
      images: []
    },
    {
      component: {
        perGuestDefaultCost: 0.2,
        perEventDefaultCost: 0,
        margin: {
          agencyA: 86.67,
          business: 86.67
        }
      },
      id: 35,
      name: 'Sticker personalizzato',
      description: 'Sticker personalizzato con il logo della tua azienda',
      facets: [],
      images: []
    },
    {
      component: {
        perGuestDefaultCost: 1.43,
        perEventDefaultCost: 0,
        margin: {
          agencyA: 20.56,
          business: 20.56
        }
      },
      id: 38,
      name: 'Taralli artigianali',
      description: 'Taralli artigianali pugliesi classici (100g)',
      facets: [],
      images: []
    },
    {
      component: {
        perGuestDefaultCost: 1.3,
        perEventDefaultCost: 0,
        margin: {
          agencyA: 23.53,
          business: 23.53
        }
      },
      id: 48,
      name: 'Cola BIO',
      description:
        'Soft Drink in Vetro Bevande Cortese Originale 1959 BIO (27cl)',
      facets: [],
      images: []
    },
    {
      component: {
        perGuestDefaultCost: 1.3,
        perEventDefaultCost: 0,
        margin: {
          agencyA: 23.52,
          business: 23.52
        }
      },
      id: 49,
      name: 'Aranciata BIO',
      description:
        'Soft Drink in Vetro Bevande Cortese Originale 1959 BIO (27cl)',
      facets: [],
      images: []
    }
  ],
  maxQuantity: 400,
  options: [
    {
      id: 523,
      name: 'Food',
      items: [
        {
          id: 2323,
          name: 'L’imprevedibile',
          description:
            'Alici Sott’olio (130g) con Crema di Parmigiano Reggiano e tartufo (30g)',
          quantity: 0,
          image: {
            id: 'ed094cd1e0564e119880a1ea6c898d7e',
            url: 'https://images.staging.kampaay.com/ed094cd1e0564e119880a1ea6c898d7e'
          }
        },
        {
          id: 2324,
          name: 'Il vivace',
          description:
            'Giardiniera (250g) con Crema di carciofi con tartufo (30g)',
          quantity: 0,
          image: {
            id: '29eceeb9e9a2483b9111b2e3af22970a',
            url: 'https://images.staging.kampaay.com/29eceeb9e9a2483b9111b2e3af22970a'
          }
        },
        {
          id: 2325,
          name: 'L’eccentrico',
          description:
            'Salame sottovuoto di cinghiale (170g) con Crema di Parmigiano Reggiano e tartufo (30g)',
          quantity: 0,
          image: {
            id: '517ec9ac4a7542d7b79ffb15a48a08d0',
            url: 'https://images.staging.kampaay.com/517ec9ac4a7542d7b79ffb15a48a08d0'
          }
        }
      ]
    },
    {
      id: 524,
      name: 'Acqua',
      items: [
        {
          id: 2326,
          name: 'Acqua naturale',
          description: 'Acqua naturale Surgiva vetro (50cl)',
          quantity: 0,
          image: {
            id: '662023ff85164499bfdf7f5cfff689bf',
            url: 'https://images.staging.kampaay.com/662023ff85164499bfdf7f5cfff689bf'
          }
        },
        {
          id: 2327,
          name: 'Acqua frizzante',
          description: 'Acqua frizzante Surgiva (50cl)',
          quantity: 0,
          image: {
            id: '2bf237ff7ed54cb09f9b17b393762c36',
            url: 'https://images.staging.kampaay.com/2bf237ff7ed54cb09f9b17b393762c36'
          }
        }
      ]
    },
    {
      id: 525,
      name: 'Drink',
      items: [
        {
          id: 2328,
          name: 'Succo fresco di stagione con Mirtillo',
          description: 'Succo fresco di stagione con Mirtillo (125cl)',
          quantity: 0,
          image: {
            id: '93244530260047cf8733bdfaeed3c029',
            url: 'https://images.staging.kampaay.com/93244530260047cf8733bdfaeed3c029'
          }
        },
        {
          id: 2329,
          name: 'Succo fresco di stagione con Mela, Lemon e Ginger',
          description:
            'Succo fresco di stagione con Mela, Lemon e Ginger (125cl)',
          quantity: 0,
          image: {
            id: 'f47b9fb647384de1b6694d4be34304b4',
            url: 'https://images.staging.kampaay.com/f47b9fb647384de1b6694d4be34304b4'
          }
        },
        {
          id: 2330,
          name: 'Succo fresco di stagione con Melagrana',
          description: 'Succo fresco di stagione con Melagrana (125cl)',
          quantity: 0,
          image: {
            id: 'dfdb446adf424223a8575a3b3ac9c3e2',
            url: 'https://images.staging.kampaay.com/dfdb446adf424223a8575a3b3ac9c3e2'
          }
        },
        {
          id: 2331,
          name: 'Cola',
          description: 'Soft drink in vetro Cortese Originale 1959 BIO (27cl)',
          quantity: 0,
          image: {
            id: '9a5eaf4ef92a4d25a55a30becbf5e6af',
            url: 'https://images.staging.kampaay.com/9a5eaf4ef92a4d25a55a30becbf5e6af'
          }
        },
        {
          id: 2332,
          name: 'Limonata',
          description: 'Soft drink in vetro Cortese Originale 1959 BIO (27cl)',
          quantity: 0,
          image: {
            id: '615ebc3377474366b0d9e9126feb4d7f',
            url: 'https://images.staging.kampaay.com/615ebc3377474366b0d9e9126feb4d7f'
          }
        },
        {
          id: 2333,
          name: 'Aranciata',
          description: 'Soft drink in vetro Cortese Originale 1959 BIO (27cl)',
          quantity: 0,
          image: {
            id: '3b0b13e4380a4fbab692da6f0fa232a7',
            url: 'https://images.staging.kampaay.com/3b0b13e4380a4fbab692da6f0fa232a7'
          }
        }
      ]
    }
  ],
  minQuantity: 0,
  sections: [
    {
      description:
        'Il Virtual Catering è studiato per rendere il tuo meeting o la tua convention un momento memorabile e unico.',
      id: 96,
      name: 'Virtual Catering'
    }
  ],
  highlights: [],
  id: 37,
  name: 'Lunch Base',
  description:
    'Il Virtual Catering è il servizio pensato da Kampaay per portare a tutti i partecipanti l’esperienza del catering all’interno del tuo evento virtuale.',
  facets: [],
  images: [
    {
      id: '2ddd6af4aa7342e4bee5dc67abed157d',
      url: 'https://images.staging.kampaay.com/2ddd6af4aa7342e4bee5dc67abed157d'
    }
  ]
}

const PHYSICAL_UPSELLING_LIST = [
  {
    component: {
      perGuestDefaultCost: 4.2,
      perEventDefaultCost: 0,
      margin: {
        agencyA: 25,
        business: 25
      }
    },
    id: 24,
    name: 'Tumbler personalizzato',
    description:
      'Bicchiere da cocktail in vetro personalizzato per il tuo evento (minimo 10 pz)',
    facets: [],
    images: []
  },
  {
    component: {
      perGuestDefaultCost: 3.5,
      perEventDefaultCost: 0,
      margin: {
        agencyA: 25.53,
        business: 25.53
      }
    },
    id: 25,
    name: 'Tumbler non personalizzato',
    description:
      'Bicchiere da cocktail in vetro non personalizzato per il tuo evento (minimo 10 pz)',
    facets: [],
    images: []
  },
  {
    component: {
      perGuestDefaultCost: 2.5,
      perEventDefaultCost: 0,
      margin: {
        agencyA: 24.24,
        business: 24.24
      }
    },
    id: 26,
    name: 'Apribottiglie personalizzato',
    description: "Apribottiglie con logo dell'azienda (minimo 100 pz)",
    facets: [],
    images: []
  },
  {
    component: {
      perGuestDefaultCost: 10,
      perEventDefaultCost: 0,
      margin: {
        agencyA: 16.67,
        business: 16.67
      }
    },
    id: 33,
    name: 'Packaging su stampa digitale',
    description:
      'Packaging con stampa digitale con la personalizzazione da te preferita',
    facets: [],
    images: []
  },
  {
    component: {
      perGuestDefaultCost: 0.2,
      perEventDefaultCost: 0,
      margin: {
        agencyA: 86.67,
        business: 86.67
      }
    },
    id: 34,
    name: 'Flyer A5',
    description: 'Flyer A5 grammatura 160 g',
    facets: [],
    images: []
  },
  {
    component: {
      perGuestDefaultCost: 0.2,
      perEventDefaultCost: 0,
      margin: {
        agencyA: 86.67,
        business: 86.67
      }
    },
    id: 35,
    name: 'Sticker personalizzato',
    description: 'Sticker personalizzato con il logo della tua azienda',
    facets: [],
    images: []
  },
  {
    component: {
      perGuestDefaultCost: 1.43,
      perEventDefaultCost: 0,
      margin: {
        agencyA: 20.56,
        business: 20.56
      }
    },
    id: 38,
    name: 'Taralli artigianali',
    description: 'Taralli artigianali pugliesi classici (100g)',
    facets: [],
    images: []
  },
  {
    component: {
      perGuestDefaultCost: 1.3,
      perEventDefaultCost: 0,
      margin: {
        agencyA: 23.53,
        business: 23.53
      }
    },
    id: 48,
    name: 'Cola BIO',
    description:
      'Soft Drink in Vetro Bevande Cortese Originale 1959 BIO (27cl)',
    facets: [],
    images: []
  },
  {
    component: {
      perGuestDefaultCost: 1.3,
      perEventDefaultCost: 0,
      margin: {
        agencyA: 23.52,
        business: 23.52
      }
    },
    id: 49,
    name: 'Aranciata BIO',
    description:
      'Soft Drink in Vetro Bevande Cortese Originale 1959 BIO (27cl)',
    facets: [],
    images: []
  }
]

const PHYSICAL_UPSELLING_LIST_ITEM = {
  component: {
    perGuestDefaultCost: 4.2,
    perEventDefaultCost: 0,
    margin: {
      agencyA: 25,
      business: 25
    }
  },
  id: 24,
  name: 'Tumbler personalizzato',
  description:
    'Bicchiere da cocktail in vetro personalizzato per il tuo evento (minimo 10 pz)',
  facets: [],
  images: []
}

const VIRTUAL_SECTIONS = [
  {
    id: 1,
    name: 'test0',
    description: 'description0',
    products: [
      {
        component: {
          perGuestDefaultCost: 25.49,
          perEventDefaultCost: 0,
          margin: {
            agencyA: 25.03,
            business: 25.03
          }
        },
        upsellingItems: [
          {
            component: {
              perGuestDefaultCost: 4.2,
              perEventDefaultCost: 0,
              margin: {
                agencyA: 25,
                business: 25
              }
            },
            id: 24,
            name: 'Tumbler personalizzato',
            description:
              'Bicchiere da cocktail in vetro personalizzato per il tuo evento (minimo 10 pz)',
            facets: []
          },
          {
            component: {
              perGuestDefaultCost: 3.5,
              perEventDefaultCost: 0,
              margin: {
                agencyA: 25.53,
                business: 25.53
              }
            },
            id: 25,
            name: 'Tumbler non personalizzato',
            description:
              'Bicchiere da cocktail in vetro non personalizzato per il tuo evento (minimo 10 pz)',
            facets: []
          },
          {
            component: {
              perGuestDefaultCost: 2.5,
              perEventDefaultCost: 0,
              margin: {
                agencyA: 24.24,
                business: 24.24
              }
            },
            id: 26,
            name: 'Apribottiglie personalizzato',
            description: "Apribottiglie con logo dell'azienda (minimo 100 pz)",
            facets: []
          },
          {
            component: {
              perGuestDefaultCost: 10,
              perEventDefaultCost: 0,
              margin: {
                agencyA: 16.67,
                business: 16.67
              }
            },
            id: 33,
            name: 'Packaging su stampa digitale',
            description:
              'Packaging con stampa digitale con la personalizzazione da te preferita',
            facets: []
          },
          {
            component: {
              perGuestDefaultCost: 0.2,
              perEventDefaultCost: 0,
              margin: {
                agencyA: 86.67,
                business: 86.67
              }
            },
            id: 34,
            name: 'Flyer A5',
            description: 'Flyer A5 grammatura 160 g',
            facets: []
          },
          {
            component: {
              perGuestDefaultCost: 0.2,
              perEventDefaultCost: 0,
              margin: {
                agencyA: 86.67,
                business: 86.67
              }
            },
            id: 35,
            name: 'Sticker personalizzato',
            description: 'Sticker personalizzato con il logo della tua azienda',
            facets: []
          },
          {
            component: {
              perGuestDefaultCost: 1.43,
              perEventDefaultCost: 0,
              margin: {
                agencyA: 20.56,
                business: 20.56
              }
            },
            id: 38,
            name: 'Taralli artigianali',
            description: 'Taralli artigianali pugliesi classici (100g)',
            facets: []
          },
          {
            component: {
              perGuestDefaultCost: 1.3,
              perEventDefaultCost: 0,
              margin: {
                agencyA: 23.53,
                business: 23.53
              }
            },
            id: 48,
            name: 'Cola BIO',
            description:
              'Soft Drink in Vetro Bevande Cortese Originale 1959 BIO (27cl)',
            facets: []
          },
          {
            component: {
              perGuestDefaultCost: 1.3,
              perEventDefaultCost: 0,
              margin: {
                agencyA: 23.52,
                business: 23.52
              }
            },
            id: 49,
            name: 'Aranciata BIO',
            description:
              'Soft Drink in Vetro Bevande Cortese Originale 1959 BIO (27cl)',
            facets: []
          }
        ],
        id: 37,
        facets: [],
        name: 'Lunch Base',
        description:
          'Il Virtual Catering è il servizio pensato da Kampaay per portare a tutti i partecipanti l’esperienza del catering all’interno del tuo evento virtuale.',
        maxQuantity: 400,
        options: [
          {
            id: 523,
            name: 'Food',
            maxSelection: 1,
            items: [
              {
                id: 2323,
                name: 'L’imprevedibile',
                description:
                  'Alici Sott’olio (130g) con Crema di Parmigiano Reggiano e tartufo (30g)',
                image: {
                  id: 'ed094cd1e0564e119880a1ea6c898d7e',
                  url: 'https://images.staging.kampaay.com/ed094cd1e0564e119880a1ea6c898d7e'
                }
              },
              {
                id: 2324,
                name: 'Il vivace',
                description:
                  'Giardiniera (250g) con Crema di carciofi con tartufo (30g)',
                image: {
                  id: '29eceeb9e9a2483b9111b2e3af22970a',
                  url: 'https://images.staging.kampaay.com/29eceeb9e9a2483b9111b2e3af22970a'
                }
              },
              {
                id: 2325,
                name: 'L’eccentrico',
                description:
                  'Salame sottovuoto di cinghiale (170g) con Crema di Parmigiano Reggiano e tartufo (30g)',
                image: {
                  id: '517ec9ac4a7542d7b79ffb15a48a08d0',
                  url: 'https://images.staging.kampaay.com/517ec9ac4a7542d7b79ffb15a48a08d0'
                }
              }
            ]
          },
          {
            id: 524,
            name: 'Acqua',
            maxSelection: 1,
            items: [
              {
                id: 2326,
                name: 'Acqua naturale',
                description: 'Acqua naturale Surgiva vetro (50cl)',
                image: {
                  id: '662023ff85164499bfdf7f5cfff689bf',
                  url: 'https://images.staging.kampaay.com/662023ff85164499bfdf7f5cfff689bf'
                }
              },
              {
                id: 2327,
                name: 'Acqua frizzante',
                description: 'Acqua frizzante Surgiva (50cl)',
                image: {
                  id: '2bf237ff7ed54cb09f9b17b393762c36',
                  url: 'https://images.staging.kampaay.com/2bf237ff7ed54cb09f9b17b393762c36'
                }
              }
            ]
          },
          {
            id: 525,
            name: 'Drink',
            maxSelection: 1,
            items: [
              {
                id: 2328,
                name: 'Succo fresco di stagione con Mirtillo',
                description: 'Succo fresco di stagione con Mirtillo (125cl)',
                image: {
                  id: '93244530260047cf8733bdfaeed3c029',
                  url: 'https://images.staging.kampaay.com/93244530260047cf8733bdfaeed3c029'
                }
              },
              {
                id: 2329,
                name: 'Succo fresco di stagione con Mela, Lemon e Ginger',
                description:
                  'Succo fresco di stagione con Mela, Lemon e Ginger (125cl)',
                image: {
                  id: 'f47b9fb647384de1b6694d4be34304b4',
                  url: 'https://images.staging.kampaay.com/f47b9fb647384de1b6694d4be34304b4'
                }
              },
              {
                id: 2330,
                name: 'Succo fresco di stagione con Melagrana',
                description: 'Succo fresco di stagione con Melagrana (125cl)',
                image: {
                  id: 'dfdb446adf424223a8575a3b3ac9c3e2',
                  url: 'https://images.staging.kampaay.com/dfdb446adf424223a8575a3b3ac9c3e2'
                }
              },
              {
                id: 2331,
                name: 'Cola',
                description:
                  'Soft drink in vetro Cortese Originale 1959 BIO (27cl)',
                image: {
                  id: '9a5eaf4ef92a4d25a55a30becbf5e6af',
                  url: 'https://images.staging.kampaay.com/9a5eaf4ef92a4d25a55a30becbf5e6af'
                }
              },
              {
                id: 2332,
                name: 'Limonata',
                description:
                  'Soft drink in vetro Cortese Originale 1959 BIO (27cl)',
                image: {
                  id: '615ebc3377474366b0d9e9126feb4d7f',
                  url: 'https://images.staging.kampaay.com/615ebc3377474366b0d9e9126feb4d7f'
                }
              },
              {
                id: 2333,
                name: 'Aranciata',
                description:
                  'Soft drink in vetro Cortese Originale 1959 BIO (27cl)',
                image: {
                  id: '3b0b13e4380a4fbab692da6f0fa232a7',
                  url: 'https://images.staging.kampaay.com/3b0b13e4380a4fbab692da6f0fa232a7'
                }
              }
            ]
          }
        ],
        image: {
          id: '2ddd6af4aa7342e4bee5dc67abed157d',
          url: 'https://images.staging.kampaay.com/2ddd6af4aa7342e4bee5dc67abed157d'
        },
        minQuantity: 0
      },
      {
        component: {
          perGuestDefaultCost: 23.89,
          perEventDefaultCost: 0,
          margin: {
            agencyA: 25.34,
            business: 25.34
          }
        },
        upsellingItems: [
          {
            component: {
              perGuestDefaultCost: 6.65,
              perEventDefaultCost: 0,
              margin: {
                agencyA: 26.1,
                business: 26.1
              }
            },
            id: 21,
            name: 'Calice personalizzato',
            description:
              'Calice di vetro personalizzato per il tuo evento (minimo 30 pz)',
            facets: []
          },
          {
            component: {
              perGuestDefaultCost: 3,
              perEventDefaultCost: 0,
              margin: {
                agencyA: 25,
                business: 25
              }
            },
            id: 22,
            name: 'Calice non personalizzato',
            description:
              'Calice di vetro non personalizzato per il tuo evento (minimo 30 pz)',
            facets: []
          },
          {
            component: {
              perGuestDefaultCost: 4.2,
              perEventDefaultCost: 0,
              margin: {
                agencyA: 25,
                business: 25
              }
            },
            id: 24,
            name: 'Tumbler personalizzato',
            description:
              'Bicchiere da cocktail in vetro personalizzato per il tuo evento (minimo 10 pz)',
            facets: []
          },
          {
            component: {
              perGuestDefaultCost: 3.5,
              perEventDefaultCost: 0,
              margin: {
                agencyA: 25.53,
                business: 25.53
              }
            },
            id: 25,
            name: 'Tumbler non personalizzato',
            description:
              'Bicchiere da cocktail in vetro non personalizzato per il tuo evento (minimo 10 pz)',
            facets: []
          },
          {
            component: {
              perGuestDefaultCost: 2.5,
              perEventDefaultCost: 0,
              margin: {
                agencyA: 24.24,
                business: 24.24
              }
            },
            id: 26,
            name: 'Apribottiglie personalizzato',
            description: "Apribottiglie con logo dell'azienda (minimo 100 pz)",
            facets: []
          },
          {
            component: {
              perGuestDefaultCost: 10,
              perEventDefaultCost: 0,
              margin: {
                agencyA: 16.67,
                business: 16.67
              }
            },
            id: 33,
            name: 'Packaging su stampa digitale',
            description:
              'Packaging con stampa digitale con la personalizzazione da te preferita',
            facets: []
          },
          {
            component: {
              perGuestDefaultCost: 0.2,
              perEventDefaultCost: 0,
              margin: {
                agencyA: 86.67,
                business: 86.67
              }
            },
            id: 34,
            name: 'Flyer A5',
            description: 'Flyer A5 grammatura 160 g',
            facets: []
          },
          {
            component: {
              perGuestDefaultCost: 0.2,
              perEventDefaultCost: 0,
              margin: {
                agencyA: 86.67,
                business: 86.67
              }
            },
            id: 35,
            name: 'Sticker personalizzato',
            description: 'Sticker personalizzato con il logo della tua azienda',
            facets: []
          },
          {
            component: {
              perGuestDefaultCost: 1.43,
              perEventDefaultCost: 0,
              margin: {
                agencyA: 20.56,
                business: 20.56
              }
            },
            id: 38,
            name: 'Taralli artigianali',
            description: 'Taralli artigianali pugliesi classici (100g)',
            facets: []
          },
          {
            component: {
              perGuestDefaultCost: 1.3,
              perEventDefaultCost: 0,
              margin: {
                agencyA: 23.53,
                business: 23.53
              }
            },
            id: 48,
            name: 'Cola BIO',
            description:
              'Soft Drink in Vetro Bevande Cortese Originale 1959 BIO (27cl)',
            facets: []
          },
          {
            component: {
              perGuestDefaultCost: 1.3,
              perEventDefaultCost: 0,
              margin: {
                agencyA: 23.52,
                business: 23.52
              }
            },
            id: 49,
            name: 'Aranciata BIO',
            description:
              'Soft Drink in Vetro Bevande Cortese Originale 1959 BIO (27cl)',
            facets: []
          }
        ],
        id: 40,
        facets: [],
        name: 'Aperitivo base Vino4',
        description:
          'Il Virtual Catering è il servizio pensato da Kampaay per portare a tutti i partecipanti l’esperienza del catering all’interno del tuo evento virtuale.',
        maxQuantity: 400,
        options: [
          {
            id: 521,
            name: 'Food',
            maxSelection: 2,
            items: [
              {
                id: 2313,
                name: 'Taralli artigianali',
                description: 'Taralli artigianali (100g)',
                image: {
                  id: '8f0c287fd6f64567939d4edc28cc32bb',
                  url: 'https://images.staging.kampaay.com/8f0c287fd6f64567939d4edc28cc32bb'
                }
              },
              {
                id: 2314,
                name: 'Taralli gourmet al pomodoro',
                description: 'Taralli gourmet al pomodoro (100g) ',
                image: {
                  id: '61aecd127a41412b85a130835098b1fb',
                  url: 'https://images.staging.kampaay.com/61aecd127a41412b85a130835098b1fb'
                }
              },
              {
                id: 2315,
                name: 'Taralli gourmet curcuma e pepe',
                description: 'Taralli gourmet curcuma e pepe (100g)',
                image: {
                  id: '5867af126bf54f669b15e1656ad65fef',
                  url: 'https://images.staging.kampaay.com/5867af126bf54f669b15e1656ad65fef'
                }
              },
              {
                id: 2316,
                name: 'Taralli gourmet pistacchio',
                description: 'Taralli gourmet pistacchio (100g)',
                image: {
                  id: 'f2c602589c5e4d9b9a19cd36f4ee2999',
                  url: 'https://images.staging.kampaay.com/f2c602589c5e4d9b9a19cd36f4ee2999'
                }
              },
              {
                id: 2317,
                name: 'Chips gourmet',
                description: 'Patatas Nanas - Chips gourmet (50g)',
                image: {
                  id: '133fec159b4c44a7b2106d2c7ecbea50',
                  url: 'https://images.staging.kampaay.com/133fec159b4c44a7b2106d2c7ecbea50'
                }
              },
              {
                id: 2318,
                name: 'Mix di frutta secca',
                description:
                  'Misto aperitivo (25g) e Energy Mix (30g) con: Arachidi/mandorle/anacardi/noci di macadamia/Anacardi/uva sultanina/nocciole/noci brasile',
                image: {
                  id: '1776d4ee04df4c3a832f7f32789d8996',
                  url: 'https://images.staging.kampaay.com/1776d4ee04df4c3a832f7f32789d8996'
                }
              },
              {
                id: 2319,
                name: 'Veggie snack ',
                description: 'Triangoli di mais con legumi BIO (70g)',
                image: {
                  id: '70c85592a1574d2f90ecd110c9e272f9',
                  url: 'https://images.staging.kampaay.com/70c85592a1574d2f90ecd110c9e272f9'
                }
              }
            ]
          },
          {
            id: 522,
            name: 'Vino',
            maxSelection: 1,
            items: [
              {
                id: 2320,
                name: 'Langhe Chardonnay DOC',
                description: 'Langhe Chardonnay DOC Villa Lanata - Vino bianco',
                image: {
                  id: '223c59213ff745d1bc50633f6451a17f',
                  url: 'https://images.staging.kampaay.com/223c59213ff745d1bc50633f6451a17f'
                }
              },
              {
                id: 2321,
                name: 'Prosecco Treviso Extra Dry',
                description: 'Prosecco Treviso Extra Dry Borgo Molino',
                image: {
                  id: '752b012cc1d94304be8a6e1835954540',
                  url: 'https://images.staging.kampaay.com/752b012cc1d94304be8a6e1835954540'
                }
              },
              {
                id: 2322,
                name: 'Piemonte Barbera DOC',
                description: 'Piemonte Barbera DOC Clavesana - Vino rosso',
                image: {
                  id: '0e2812248ea14f008c971432d45ce931',
                  url: 'https://images.staging.kampaay.com/0e2812248ea14f008c971432d45ce931'
                }
              }
            ]
          }
        ],
        image: {
          id: '760ccd1f3a744d688cb8c0d194fdf4dc',
          url: 'https://images.staging.kampaay.com/760ccd1f3a744d688cb8c0d194fdf4dc'
        },
        minQuantity: 0
      }
    ]
  }
]

const VIRTUAL_SECTION_ITEM = {
  component: {
    perGuestDefaultCost: 25.49,
    perEventDefaultCost: 0,
    margin: {
      agencyA: 25.03,
      business: 25.03
    }
  },
  upsellingItems: [
    {
      component: {
        perGuestDefaultCost: 4.2,
        perEventDefaultCost: 0,
        margin: {
          agencyA: 25,
          business: 25
        }
      },
      id: 24,
      name: 'Tumbler personalizzato',
      description:
        'Bicchiere da cocktail in vetro personalizzato per il tuo evento (minimo 10 pz)',
      facets: []
    },
    {
      component: {
        perGuestDefaultCost: 3.5,
        perEventDefaultCost: 0,
        margin: {
          agencyA: 25.53,
          business: 25.53
        }
      },
      id: 25,
      name: 'Tumbler non personalizzato',
      description:
        'Bicchiere da cocktail in vetro non personalizzato per il tuo evento (minimo 10 pz)',
      facets: []
    },
    {
      component: {
        perGuestDefaultCost: 2.5,
        perEventDefaultCost: 0,
        margin: {
          agencyA: 24.24,
          business: 24.24
        }
      },
      id: 26,
      name: 'Apribottiglie personalizzato',
      description: "Apribottiglie con logo dell'azienda (minimo 100 pz)",
      facets: []
    },
    {
      component: {
        perGuestDefaultCost: 10,
        perEventDefaultCost: 0,
        margin: {
          agencyA: 16.67,
          business: 16.67
        }
      },
      id: 33,
      name: 'Packaging su stampa digitale',
      description:
        'Packaging con stampa digitale con la personalizzazione da te preferita',
      facets: []
    },
    {
      component: {
        perGuestDefaultCost: 0.2,
        perEventDefaultCost: 0,
        margin: {
          agencyA: 86.67,
          business: 86.67
        }
      },
      id: 34,
      name: 'Flyer A5',
      description: 'Flyer A5 grammatura 160 g',
      facets: []
    },
    {
      component: {
        perGuestDefaultCost: 0.2,
        perEventDefaultCost: 0,
        margin: {
          agencyA: 86.67,
          business: 86.67
        }
      },
      id: 35,
      name: 'Sticker personalizzato',
      description: 'Sticker personalizzato con il logo della tua azienda',
      facets: []
    },
    {
      component: {
        perGuestDefaultCost: 1.43,
        perEventDefaultCost: 0,
        margin: {
          agencyA: 20.56,
          business: 20.56
        }
      },
      id: 38,
      name: 'Taralli artigianali',
      description: 'Taralli artigianali pugliesi classici (100g)',
      facets: []
    },
    {
      component: {
        perGuestDefaultCost: 1.3,
        perEventDefaultCost: 0,
        margin: {
          agencyA: 23.53,
          business: 23.53
        }
      },
      id: 48,
      name: 'Cola BIO',
      description:
        'Soft Drink in Vetro Bevande Cortese Originale 1959 BIO (27cl)',
      facets: []
    },
    {
      component: {
        perGuestDefaultCost: 1.3,
        perEventDefaultCost: 0,
        margin: {
          agencyA: 23.52,
          business: 23.52
        }
      },
      id: 49,
      name: 'Aranciata BIO',
      description:
        'Soft Drink in Vetro Bevande Cortese Originale 1959 BIO (27cl)',
      facets: []
    }
  ],
  id: 37,
  facets: [],
  name: 'Lunch Base',
  description:
    'Il Virtual Catering è il servizio pensato da Kampaay per portare a tutti i partecipanti l’esperienza del catering all’interno del tuo evento virtuale.',
  maxQuantity: 400,
  options: [
    {
      id: 523,
      name: 'Food',
      maxSelection: 1,
      items: [
        {
          id: 2323,
          name: 'L’imprevedibile',
          description:
            'Alici Sott’olio (130g) con Crema di Parmigiano Reggiano e tartufo (30g)',
          image: {
            id: 'ed094cd1e0564e119880a1ea6c898d7e',
            url: 'https://images.staging.kampaay.com/ed094cd1e0564e119880a1ea6c898d7e'
          }
        },
        {
          id: 2324,
          name: 'Il vivace',
          description:
            'Giardiniera (250g) con Crema di carciofi con tartufo (30g)',
          image: {
            id: '29eceeb9e9a2483b9111b2e3af22970a',
            url: 'https://images.staging.kampaay.com/29eceeb9e9a2483b9111b2e3af22970a'
          }
        },
        {
          id: 2325,
          name: 'L’eccentrico',
          description:
            'Salame sottovuoto di cinghiale (170g) con Crema di Parmigiano Reggiano e tartufo (30g)',
          image: {
            id: '517ec9ac4a7542d7b79ffb15a48a08d0',
            url: 'https://images.staging.kampaay.com/517ec9ac4a7542d7b79ffb15a48a08d0'
          }
        }
      ]
    },
    {
      id: 524,
      name: 'Acqua',
      maxSelection: 1,
      items: [
        {
          id: 2326,
          name: 'Acqua naturale',
          description: 'Acqua naturale Surgiva vetro (50cl)',
          image: {
            id: '662023ff85164499bfdf7f5cfff689bf',
            url: 'https://images.staging.kampaay.com/662023ff85164499bfdf7f5cfff689bf'
          }
        },
        {
          id: 2327,
          name: 'Acqua frizzante',
          description: 'Acqua frizzante Surgiva (50cl)',
          image: {
            id: '2bf237ff7ed54cb09f9b17b393762c36',
            url: 'https://images.staging.kampaay.com/2bf237ff7ed54cb09f9b17b393762c36'
          }
        }
      ]
    },
    {
      id: 525,
      name: 'Drink',
      maxSelection: 1,
      items: [
        {
          id: 2328,
          name: 'Succo fresco di stagione con Mirtillo',
          description: 'Succo fresco di stagione con Mirtillo (125cl)',
          image: {
            id: '93244530260047cf8733bdfaeed3c029',
            url: 'https://images.staging.kampaay.com/93244530260047cf8733bdfaeed3c029'
          }
        },
        {
          id: 2329,
          name: 'Succo fresco di stagione con Mela, Lemon e Ginger',
          description:
            'Succo fresco di stagione con Mela, Lemon e Ginger (125cl)',
          image: {
            id: 'f47b9fb647384de1b6694d4be34304b4',
            url: 'https://images.staging.kampaay.com/f47b9fb647384de1b6694d4be34304b4'
          }
        },
        {
          id: 2330,
          name: 'Succo fresco di stagione con Melagrana',
          description: 'Succo fresco di stagione con Melagrana (125cl)',
          image: {
            id: 'dfdb446adf424223a8575a3b3ac9c3e2',
            url: 'https://images.staging.kampaay.com/dfdb446adf424223a8575a3b3ac9c3e2'
          }
        },
        {
          id: 2331,
          name: 'Cola',
          description: 'Soft drink in vetro Cortese Originale 1959 BIO (27cl)',
          image: {
            id: '9a5eaf4ef92a4d25a55a30becbf5e6af',
            url: 'https://images.staging.kampaay.com/9a5eaf4ef92a4d25a55a30becbf5e6af'
          }
        },
        {
          id: 2332,
          name: 'Limonata',
          description: 'Soft drink in vetro Cortese Originale 1959 BIO (27cl)',
          image: {
            id: '615ebc3377474366b0d9e9126feb4d7f',
            url: 'https://images.staging.kampaay.com/615ebc3377474366b0d9e9126feb4d7f'
          }
        },
        {
          id: 2333,
          name: 'Aranciata',
          description: 'Soft drink in vetro Cortese Originale 1959 BIO (27cl)',
          image: {
            id: '3b0b13e4380a4fbab692da6f0fa232a7',
            url: 'https://images.staging.kampaay.com/3b0b13e4380a4fbab692da6f0fa232a7'
          }
        }
      ]
    }
  ],
  image: {
    id: '2ddd6af4aa7342e4bee5dc67abed157d',
    url: 'https://images.staging.kampaay.com/2ddd6af4aa7342e4bee5dc67abed157d'
  },
  minQuantity: 0
}

const PROFESSIONAL_SECTIONS = [
  {
    id: 1,
    name: 'first',
    products: [
      {
        professionalType: {
          id: 1,
          name: 'Bartender',
          specialType: 'waitingStaff'
        },
        longDescription: [
          {
            title:
              'Il bartender è una figura orientata a soddisfare e comprendere i gusti e i bisogni di tutti i partecipanti. \nUn professionista esperto per la preparazione di cocktail e long drink alcolici e analcolici: conosce e miscela i diversi tipi di cocktail, dai più comuni a quelli più ricercati, ne cura il gusto e la presentazione, crea e sperimenta lui stesso nuove combinazioni.',
            items: []
          },
          {
            title:
              'Un aspetto importante della figura del bartender è la presenza scenica accompagnata da doti comunicative e una spiccata socialità. ',
            items: []
          },
          {
            title:
              'Il bartender arriverà con tutti gli strumenti per miscelare i vostri drink!',
            items: []
          }
        ],
        minServiceHours: 4,
        component: {
          shifts: [
            {
              name: 'Night Shift',
              startTime: '2021-09-29T19:00:00Z',
              endTime: '2021-09-28T23:00:00Z',
              defaultCost: 30,
              price: {
                agencyA: 48.8,
                business: 48.8
              }
            },
            {
              name: 'Late Night Shift',
              startTime: '2021-09-28T23:00:00Z',
              endTime: '2021-09-29T04:00:00Z',
              defaultCost: 45,
              price: {
                agencyA: 73.2,
                business: 73.2
              }
            }
          ],
          defaultCost: 22,
          price: {
            agencyA: 33.55,
            business: 33.55
          }
        },
        id: 5,
        name: 'Bartender',
        description:
          'Il bartender è una figura orientata a soddisfare e comprendere i gusti e i bisogni di tutti i partecipanti.  Un professionista esperto per la preparazione di cocktail e long drink alcolici e analcolici: conosce e miscela i diversi tipi di cocktail, dai più comuni a quelli più ricercati, ne cura il gusto e la presentazione, crea e sperimenta lui stesso nuove combinazioni. Un aspetto importante della figura del bartender è la presenza scenica accompagnata da doti comunicative e una spiccata socialità. ',
        facets: [],
        image: {
          id: '1ff4a325b89f45ad97762a91e237b633',
          url: 'https://images.staging.kampaay.com/1ff4a325b89f45ad97762a91e237b633'
        }
      },
      {
        professionalType: {
          id: 1,
          name: 'Bartender',
          specialType: 'waitingStaff'
        },
        longDescription: [
          {
            title:
              'Il Bartender Mixologist rispecchia le caratteristiche del Bartender quali orientamento alla comprensione dei gusti e dei bisogni di tutti i partecipanti, preparazione di cocktail e long drink alcolici e analcolici con spiccata attenzione ai sapori e alla presentazione del drink. ',
            items: []
          },
          {
            title:
              'Inoltre questa figura ha presenza scenica accompagnata da doti comunicative e una elevata socialità. Ha la capacità realizzare i cocktail con gesti tecnici estremamente rapidi e coreografici. In particolare, la spettacolarizzazione della tecnica di lavoro del barman, utilizzando bicchieri e bottiglie con movimenti acrobatici simili a quelle dei giocolieri realizzando cocktail più evoluti e ricercati.',
            items: []
          },
          {
            title:
              'Il bartender arriverà con tutti gli strumenti per miscelare i vostri drink!',
            items: []
          }
        ],
        minServiceHours: 4,
        component: {
          shifts: [],
          defaultCost: 25,
          price: {
            agencyA: 48.8,
            business: 48.8
          }
        },
        id: 6,
        name: 'Bartender Mixologist',
        description:
          'Il Bartender Mixologist rispecchia le caratteristiche del Bartender quali orientamento alla comprensione dei gusti e dei bisogni di tutti i partecipanti, preparazione di cocktail e long drink alcolici e analcolici con spiccata attenzione ai sapori e alla presentazione del drink. ',
        facets: [],
        image: {
          id: 'ec4fdac9bf7e418c9a659c91833328e8',
          url: 'https://images.staging.kampaay.com/ec4fdac9bf7e418c9a659c91833328e8'
        }
      }
    ]
  }
]

const PROFESSIONAL_SECTIONS_ITEM = {
  professionalType: {
    id: 1,
    name: 'Bartender',
    specialType: 'waitingStaff'
  },
  longDescription: [
    {
      title:
        'Il bartender è una figura orientata a soddisfare e comprendere i gusti e i bisogni di tutti i partecipanti. \nUn professionista esperto per la preparazione di cocktail e long drink alcolici e analcolici: conosce e miscela i diversi tipi di cocktail, dai più comuni a quelli più ricercati, ne cura il gusto e la presentazione, crea e sperimenta lui stesso nuove combinazioni.',
      items: []
    },
    {
      title:
        'Un aspetto importante della figura del bartender è la presenza scenica accompagnata da doti comunicative e una spiccata socialità. ',
      items: []
    },
    {
      title:
        'Il bartender arriverà con tutti gli strumenti per miscelare i vostri drink!',
      items: []
    }
  ],
  minServiceHours: 4,
  component: {
    shifts: [
      {
        name: 'Night Shift',
        startTime: '2021-09-29T19:00:00Z',
        endTime: '2021-09-28T23:00:00Z',
        defaultCost: 30,
        price: {
          agencyA: 48.8,
          business: 48.8
        }
      },
      {
        name: 'Late Night Shift',
        startTime: '2021-09-28T23:00:00Z',
        endTime: '2021-09-29T04:00:00Z',
        defaultCost: 45,
        price: {
          agencyA: 73.2,
          business: 73.2
        }
      }
    ],
    defaultCost: 22,
    price: {
      agencyA: 33.55,
      business: 33.55
    }
  },
  id: 5,
  name: 'Bartender',
  description:
    'Il bartender è una figura orientata a soddisfare e comprendere i gusti e i bisogni di tutti i partecipanti.  Un professionista esperto per la preparazione di cocktail e long drink alcolici e analcolici: conosce e miscela i diversi tipi di cocktail, dai più comuni a quelli più ricercati, ne cura il gusto e la presentazione, crea e sperimenta lui stesso nuove combinazioni. Un aspetto importante della figura del bartender è la presenza scenica accompagnata da doti comunicative e una spiccata socialità. ',
  facets: [],
  image: {
    id: '1ff4a325b89f45ad97762a91e237b633',
    url: 'https://images.staging.kampaay.com/1ff4a325b89f45ad97762a91e237b633'
  }
}

const MUSIC_SECTIONS = [
  {
    id: 1,
    name: 'music section test',
    products: [
      {
        component: {
          defaultCost: 130,
          price: {
            agencyA: 195.2,
            agencyB: 206.2,
            business: 219.6
          }
        },
        id: 34,
        name: 'Dj Set (4 ore)',
        description: '4 ore di Dj Set con stile musicale a scelta',
        facets: [],
        image: {
          id: '99d1ff57405d47c5aa34c6c1664fc599',
          url: 'https://images.staging.kampaay.com/99d1ff57405d47c5aa34c6c1664fc599'
        }
      },
      {
        component: {
          defaultCost: 2590,
          price: {
            agencyA: 3782,
            agencyB: 228.8,
            business: 3904
          }
        },
        id: 32,
        name: 'Impianto musicale fino a 100 persone wow',
        description:
          'Impianto completo per evento musicale con: Consolle dj con 2dj Nexus 2000 e un Djm 90018 faro a batteria smart bat || 4 teste mobili Wash/spot su base in americana con mixer luci || 2 diffusori acustici Paolo sub || Trasporto, montaggio e smontaggio in giornata (il DJ non è incluso)',
        facets: [],
        image: {
          id: '80ad8658e24a49d6a9beac16c3759698',
          url: 'https://images.staging.kampaay.com/80ad8658e24a49d6a9beac16c3759698'
        }
      }
    ]
  }
]

const MUSIC_SECTION_ITEM = {
  component: {
    defaultCost: 130,
    price: {
      agencyA: 195.2,
      agencyB: 206.2,
      business: 219.6
    }
  },
  id: 34,
  name: 'Dj Set (4 ore)',
  description: '4 ore di Dj Set con stile musicale a scelta',
  facets: [],
  image: {
    id: '99d1ff57405d47c5aa34c6c1664fc599',
    url: 'https://images.staging.kampaay.com/99d1ff57405d47c5aa34c6c1664fc599'
  }
}

const RENT_SECTIONS = [
  {
    id: 1,
    name: 'rentSectionTest',
    products: [
      {
        component: {
          defaultCost: 750,
          price: {
            agencyA: 1098,
            business: 1195.6
          }
        },
        id: 55,
        name: 'Relax - allestimento per 50 persone',
        description:
          '50% di sedute comode con: N°06 Standing table di 3 tipologie di design (bianchi o neri) | N°26 sedute comode mix pouf di diverse dimensioni (bianchi o neri) | N°06 tavolini di appoggio (bianchi o neri) || Incluso: Trasporto su Milano, montaggio e smontaggio',
        facets: [],
        image: {
          id: '2c827f07ac4241d8be226df3dde2afd1',
          url: 'https://images.staging.kampaay.com/2c827f07ac4241d8be226df3dde2afd1'
        }
      },
      {
        component: {
          defaultCost: 750,
          price: {
            agencyA: 1098,
            business: 1195.6
          }
        },
        id: 56,
        name: 'Seated - allestimento per 50 persone',
        description:
          '50% di sedute comode con: N°06 Standing table di 3 tipologie design (bianchi o neri) | N°26 Sedie Design (bianche, nere o trasparenti) | N°06 Tavoli bistrot di Design (bianchi o neri) || ​Incluso: Trasporto su Milano, montaggio e smontaggio',
        facets: [],
        image: {
          id: '748e157a659f41eb908b70da4fefceba',
          url: 'https://images.staging.kampaay.com/748e157a659f41eb908b70da4fefceba'
        }
      }
    ]
  }
]

const RENT_SECTION_ITEM = {
  component: {
    defaultCost: 750,
    price: {
      agencyA: 1098,
      business: 1195.6
    }
  },
  id: 55,
  name: 'Relax - allestimento per 50 persone',
  description:
    '50% di sedute comode con: N°06 Standing table di 3 tipologie di design (bianchi o neri) | N°26 sedute comode mix pouf di diverse dimensioni (bianchi o neri) | N°06 tavolini di appoggio (bianchi o neri) || Incluso: Trasporto su Milano, montaggio e smontaggio',
  facets: [],
  image: {
    id: '2c827f07ac4241d8be226df3dde2afd1',
    url: 'https://images.staging.kampaay.com/2c827f07ac4241d8be226df3dde2afd1'
  }
}

const LOCATION_SECTION = [
  {
    id: 1,
    name: 'Section Testardi',
    products: [
      {
        id: 1,
        images: [
          {
            id: '1',
            url: 'http://ilvenetoimbruttito.com/uploads/images/posts/2016/col12_Schermata_2018-10-12_alle_11.47.43-3b5337144f1efb4e0a5e7d8887e26119.png'
          }
        ],
        sections: [],
        name: 'Testardi Location',
        description: '<strong>Scampa location</strong>',
        supplier: 'Mr Scampa',
        nickname: 'Scampagnata',
        status: 'active',
        fullAddress: 'via della scampa, 2',
        facets: [],
        peopleCapacity: 200,
        roomCapacity: 120,
        planimetry: [],
        availableFrom: '2021-11-29T16:00:00Z',
        availableTo: '2021-11-29T19:00:00Z',
        component: {
          defaultCost: 12,
          price: {
            agencyA: 67,
            venue: 345,
            business: 45
          }
        }
      },
      {
        id: 2,
        images: [
          {
            id: '2',
            url: 'http://ilvenetoimbruttito.com/uploads/images/posts/2016/col12_Schermata_2018-10-12_alle_11.47.43-3b5337144f1efb4e0a5e7d8887e26119.png'
          }
        ],
        sections: [],
        name: 'Testardi Location 2',
        description: '<strong>Scampa location</strong>',
        supplier: 'Mr Scampa',
        nickname: 'Scampagnata Di Ca',
        status: 'active',
        fullAddress: 'via della scampa, 2',
        facets: [],
        peopleCapacity: 200,
        roomCapacity: 120,
        planimetry: [],
        availableFrom: '2021-11-29T16:00:00Z',
        availableTo: '2021-11-29T19:00:00Z',
        component: {
          defaultCost: 12,
          price: {
            agencyA: 67,
            venue: 345,
            business: 45
          }
        }
      }
    ]
  },
  {
    id: 2,
    name: 'Section Testardi 2',
    products: [
      {
        id: 1,
        images: [
          {
            id: '1',
            url: 'http://ilvenetoimbruttito.com/uploads/images/posts/2016/col12_Schermata_2018-10-12_alle_11.47.43-3b5337144f1efb4e0a5e7d8887e26119.png'
          }
        ],
        sections: [],
        name: 'Testardi Location',
        description: '<strong>Scampa location</strong>',
        supplier: 'Mr Scampa',
        nickname: 'Scampagnata',
        status: 'active',
        fullAddress: 'via della scampa, 2',
        facets: [],
        peopleCapacity: 200,
        roomCapacity: 120,
        planimetry: [],
        availableFrom: '2021-11-29T16:00:00Z',
        availableTo: '2021-11-29T19:00:00Z',
        component: {
          defaultCost: 12,
          price: {
            agencyA: 67,
            venue: 345,
            business: 45
          }
        }
      },
      {
        id: 2,
        images: [
          {
            id: '2',
            url: 'http://ilvenetoimbruttito.com/uploads/images/posts/2016/col12_Schermata_2018-10-12_alle_11.47.43-3b5337144f1efb4e0a5e7d8887e26119.png'
          }
        ],
        sections: [],
        name: 'Testardi Location 2',
        description: '<strong>Scampa location</strong>',
        supplier: 'Mr Scampa',
        nickname: 'Scampagnata Di Ca',
        status: 'active',
        fullAddress: 'via della scampa, 2',
        facets: [],
        peopleCapacity: 200,
        roomCapacity: 120,
        planimetry: [],
        availableFrom: '2021-11-29T16:00:00Z',
        availableTo: '2021-11-29T19:00:00Z',
        component: {
          defaultCost: 12,
          price: {
            agencyA: 67,
            venue: 345,
            business: 45
          }
        }
      }
    ]
  }
]

const LOCATION_SECTION_ITEM = {
  id: 1,
  name: 'Section Testardi',
  products: [
    {
      id: 1,
      images: [
        {
          id: '1',
          url: 'http://ilvenetoimbruttito.com/uploads/images/posts/2016/col12_Schermata_2018-10-12_alle_11.47.43-3b5337144f1efb4e0a5e7d8887e26119.png'
        }
      ],
      sections: [],
      name: 'Testardi Location',
      description: '<strong>Scampa location</strong>',
      supplier: 'Mr Scampa',
      nickname: 'Scampagnata',
      status: 'active',
      fullAddress: 'via della scampa, 2',
      facets: [],
      peopleCapacity: 200,
      roomCapacity: 120,
      planimetry: [],
      availableFrom: '2021-11-29T16:00:00Z',
      availableTo: '2021-11-29T19:00:00Z',
      component: {
        defaultCost: 12,
        price: {
          agencyA: 67,
          venue: 345,
          business: 45
        }
      }
    },
    {
      id: 2,
      images: [
        {
          id: '2',
          url: 'http://ilvenetoimbruttito.com/uploads/images/posts/2016/col12_Schermata_2018-10-12_alle_11.47.43-3b5337144f1efb4e0a5e7d8887e26119.png'
        }
      ],
      sections: [],
      name: 'Testardi Location 2',
      description: '<strong>Scampa location</strong>',
      supplier: 'Mr Scampa',
      nickname: 'Scampagnata Di Ca',
      status: 'active',
      fullAddress: 'via della scampa, 2',
      facets: [],
      peopleCapacity: 200,
      roomCapacity: 120,
      planimetry: [],
      availableFrom: '2021-11-29T16:00:00Z',
      availableTo: '2021-11-29T19:00:00Z',
      component: {
        defaultCost: 12,
        price: {
          agencyA: 67,
          venue: 345,
          business: 45
        }
      }
    }
  ]
}

const LOCATION_EQUIPMENT: APILocationFeature = {
  id: 1,
  name: 'Test Equipment',
  category: 'characteristic',
  updatedAt: '' as ISODate,
  updatedBy: 'ALFIOOOOOOO',
  images: []
}

const LOCATION_CHARACTERISTIC: APILocationFeature = {
  id: 2,
  name: 'Test Characteristic',
  category: 'equipment',
  updatedAt: '' as ISODate,
  updatedBy: 'ALFIOOOOOOO',
  images: []
}

const LOCATION_FEATURE_LIST = [LOCATION_EQUIPMENT, LOCATION_CHARACTERISTIC]
