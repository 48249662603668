import { OrganizationBudget } from '@kampaay/common'
import {
  UseValidationSchema,
  yupArray,
  yupBoolean,
  yupDate,
  yupIgnore,
  yupNumber,
  yupObject,
  yupString
} from 'services/yup'
import * as yup from 'yup'

const useOrganizationBudgetVS: UseValidationSchema<OrganizationBudget> = () =>
  yupObject().shape({
    id: yupIgnore(),
    name: yupString(),
    organizationId: yupIgnore(),
    totalAmount: yupNumber().when('canBeExceeded', {
      is: true,
      then: (schema) => schema.min(0),
      otherwise: (schema) =>
        schema.when('amountSpent', {
          is: (value: number) => !!value && value > 0,
          then: yupNumber().min(yup.ref('amountSpent')),
          otherwise: yupNumber().min(1)
        })
    }),
    amountSpent: yupNumber(),
    startDate: yupDate().required(),
    endDate: yupDate()
      .required()
      .when('startDate', (startDate, schema) =>
        startDate
          ? schema.min(startDate, 'End date must be greater than start date')
          : schema
      ),
    isActive: yupIgnore(),
    canBeExceeded: yupBoolean(),
    automaticRenewal: yupBoolean(),
    membershipsIds: yupArray(),
    organizationSubGroups: yupArray()
  })

export default useOrganizationBudgetVS
