import { DrinkSection } from 'services/api/entities/catalog/sections/drinkSections'
import { parseCsvFields } from '@kampaay/common'
import { CSVEntityDescriptor } from 'services/csv/types'

export const drinksections: CSVEntityDescriptor<DrinkSection> = {
  headers: ['ID', 'Name'],
  format: (record) => ({
    ID: record.id,
    ...parseCsvFields({ Name: record.name })
  })
}
