import { createMockBuilder } from '../../../../utils/test'
import type { APIImage } from '../types'

const buildBaseMock = (): APIImage => ({
  id: 'fake id',
  url: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/aa/Philips_PM5544.svg/2880px-Philips_PM5544.svg.png',
  fileName: 'fake file name'
})

export const buildApiImageMock = createMockBuilder(buildBaseMock)
