import type { FileUploader } from '../../../types'
import type { Logistic, LogisticToApi } from './types'

export const logisticWrite =
  (imageUploader: FileUploader) =>
  async (req: Logistic): Promise<LogisticToApi> => {
    const imgId = await imageUploader(req.image)

    return {
      ...req,
      images: imgId ? [imgId] : [],
      // unmock
      geoAreaPresets: req.geoAreaPresets ?? []
    }
  }
