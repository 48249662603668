import React, { useCallback, useMemo } from 'react'
import type { NavigateAction, ToolbarProps, View } from 'react-big-calendar'
import {
  enabledViews,
  type WithCalendarOriginalItem
} from 'components/UI/Organization/Calendar/OrganizationCalendar'
import { useTranslate } from 'react-admin'
import {
  getCalendarUpdatedPeriod,
  type CalendarSurroundingPeriod,
  calendarEventDefaultColor
} from 'services/utils/organization/calendar'
import { CalendarMonth, ChevronLeft, ChevronRight } from '@mui/icons-material'
import { CircularProgress } from '@mui/material'
import { useKGetList } from 'hooks/useKGetList'
import type { EventPlaceholderCategory } from 'services/api/entities/event-placeholder-category/types'
import { useParams } from 'react-router-dom'
import { FILTER_SOURCES } from '@kampaay/common'
import { isSameMonth } from 'date-fns'
import OrganizationCalendarItemBase, {
  type CalendarEventVariant,
  type OrganizationCalendarItemBaseProps
} from 'components/UI/Organization/Calendar/Item/OrganizationCalendarItemBase'

const buttonClass =
  'w-fit cursor-pointer rounded border border-solid border-neutral-300 bg-transparent px-5 py-2 font-semibold hover:bg-neutral-100'
const chevronClass =
  'flex h-[30px] w-[30px] cursor-pointer items-center justify-center rounded-full hover:bg-neutral-100 bg-transparent border-0'

type AvailableView = {
  text: string
  value: View
}

type LegendCategory = OrganizationCalendarItemBaseProps & {
  text: string
}

type Props = {
  isLoading: boolean
  toolbar: ToolbarProps<WithCalendarOriginalItem>
  currentPeriod: CalendarSurroundingPeriod
  setCurrentPeriod: (period: CalendarSurroundingPeriod) => void
}

const OrganizationCalendarToolbar: React.FC<Props> = ({
  isLoading,
  toolbar,
  currentPeriod,
  setCurrentPeriod
}) => {
  const translate = useTranslate()
  const { id: organizationId } = useParams()
  const { list: categories } = useKGetList<EventPlaceholderCategory>(
    'eventplaceholdercategory',
    {
      filter: {
        [FILTER_SOURCES.organizationId.eq]: organizationId
      }
    }
  )

  // Here we are adding a few default categories that are not included in the database. They are not really
  // a category as we think of them, but it's just a way to show what the default colors stand for.
  // IDs are defaulted to 0 as we don't really care about them and want to avoid having conflicts anyway.
  const fullCategories = useMemo(
    (): LegendCategory[] => [
      {
        text: translate('resources.organization.calendar.legend.phCreated'),
        variant: 'noEvents',
        isPlaceholder: true
      },
      {
        text: translate('resources.organization.calendar.legend.phEvents'),
        variant: 'pending',
        isPlaceholder: true
      },
      {
        text: translate('resources.organization.calendar.legend.phEvent'),
        variant: 'confirmed',
        isPlaceholder: true
      },
      {
        text: translate('resources.organization.calendar.legend.closed'),
        color: calendarEventDefaultColor,
        variant: 'noEvents'
      },
      {
        text: translate('resources.organization.calendar.legend.draft'),
        color: calendarEventDefaultColor,
        variant: 'pending'
      },
      {
        text: translate('resources.organization.calendar.legend.confirmed'),
        color: calendarEventDefaultColor,
        variant: 'confirmed'
      },
      ...categories.map((category) => ({
        text: category.name,
        color: category.color,
        variant: 'confirmed' as CalendarEventVariant,
        isPlaceholder: true
      }))
    ],
    [categories]
  )

  const updatePeriod = useCallback(
    (action: NavigateAction) => {
      const updatedPeriod = getCalendarUpdatedPeriod(
        toolbar.date,
        toolbar.view,
        action
      )

      if (!isSameMonth(currentPeriod.date, updatedPeriod.date)) {
        setCurrentPeriod(
          getCalendarUpdatedPeriod(currentPeriod.date, 'month', action)
        )
      }

      toolbar.onNavigate(action)
    },
    [currentPeriod, toolbar]
  )

  const availableViews: AvailableView[] = useMemo(
    () =>
      enabledViews.map((view) => ({
        text: `resources.organization.calendar.toolbar.${view}`,
        value: view
      })),
    []
  )

  const formattedPeriod = useMemo(
    () =>
      Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'long',
        day: toolbar.view === 'day' ? 'numeric' : undefined
      }).format(toolbar.date),
    [toolbar]
  )

  return (
    <div className="mb-3 flex w-full flex-col gap-3">
      <div className="flex w-full items-center justify-between gap-2">
        <div className="flex w-fit items-center gap-6">
          <h3 className="my-0 flex items-center gap-2">
            <CalendarMonth />
            <span>{translate('resources.organization.menu.calendar')}</span>
          </h3>
          <button className={buttonClass} onClick={() => updatePeriod('TODAY')}>
            {translate('resources.organization.calendar.toolbar.today')}
          </button>
          <div className="flex w-fit items-center gap-1">
            <button
              className={chevronClass}
              onClick={() => updatePeriod('PREV')}
            >
              <ChevronLeft />
            </button>
            <button
              className={chevronClass}
              onClick={() => updatePeriod('NEXT')}
            >
              <ChevronRight />
            </button>
            <span className="font-medium">{formattedPeriod}</span>
          </div>
          {isLoading && <CircularProgress size={25} />}
        </div>
        <select
          className={buttonClass}
          value={toolbar.view}
          onChange={(event) => toolbar.onView(event.target.value as View)}
        >
          {availableViews.map((view) => (
            <option key={view.value} value={view.value}>
              {translate(view.text)}
            </option>
          ))}
        </select>
      </div>
      <div className="flex w-full flex-wrap items-center gap-x-4 gap-y-2">
        {fullCategories.map((category, index) => (
          <div key={index} className="flex w-fit items-center gap-1.5">
            <span className="text-sm italic">{category.text}</span>
            <OrganizationCalendarItemBase
              variant={category.variant}
              className="h-[15px] w-[30px] rounded"
              color={category.color}
              isPlaceholder={category.isPlaceholder}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default OrganizationCalendarToolbar
