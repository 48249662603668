import React from 'react'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { useNotify } from 'ra-core'
import { Button, IconButtonWithTooltip } from 'react-admin'
import { WithDataTestId } from 'test/utils'

type CopyToClipboardButtonVariant = 'icon' | 'text' | 'outlined' | 'contained'

type Props = WithDataTestId & {
  textToCopy: string
  label?: string
  notificationLabel?: string
  variant?: CopyToClipboardButtonVariant
  endIcon?: React.ReactNode
  startIcon?: React.ReactNode
  className?: string
}

/**
 * Button that copies the text to the clipboard.
 * @param text the text to copy to clipboard on click
 * @param label the label of the button
 * @param notificationLabel the label of the notification
 * @param variant
 * @param endIcon
 * @param className
 */
const CopyToClipboardButton: React.FC<Props> = ({
  textToCopy,
  label = 'common.label.share',
  notificationLabel = 'notification.info.linkCopiedToClipboard',
  variant = 'icon',
  endIcon,
  startIcon,
  className,
  'data-testid': testId
}) => {
  const notify = useNotify()

  //=============================
  // FUNCTIONS
  //=============================
  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation()

    navigator.clipboard.writeText(textToCopy)
    notify(notificationLabel, { type: 'info' })
  }

  //=============================
  // JSX
  //=============================

  // THIS VARIANT IS NEVER USED. WE CAN DISPOSE OF IT
  // WHEN MIGRATINGs
  if (variant === 'icon') {
    return (
      <IconButtonWithTooltip
        onClick={handleClick}
        color="primary"
        label={label}
        className={className}
        data-testid={testId}
      >
        <ContentCopyIcon />
      </IconButtonWithTooltip>
    )
  }

  return (
    <Button
      variant={variant}
      label={label}
      onClick={handleClick}
      endIcon={endIcon}
      startIcon={startIcon}
      size="large"
      className={`font-bold ${className}`}
      data-testid={testId}
    />
  )
}

export default CopyToClipboardButton
