import { setCountry } from '../geo-management'
import jwtDecode from 'jwt-decode'

type AuthClient = {
  getTokenSilently: () => Promise<string>
}

export const USER_TYPE_KEY = 'https://app.kampaay.com/userType'
export const KAMPAAY_USER_ID_KEY = 'https://app.kampaay.com/kampaayUserId'
export const COUNTRIES_KEY = 'https://app.kampaay.com/countries'
export const ENVIRONMENT = 'https://app.kampaay.com/env'

type TokenUserType = 'pmi' | 'supplier'
type Auth0Environment = 'production' | 'staging' | 'demo'

type JwtToken = {
  [USER_TYPE_KEY]: TokenUserType
  [KAMPAAY_USER_ID_KEY]: number
  [COUNTRIES_KEY]: number[]
  [ENVIRONMENT]: Auth0Environment
}

type DecodedJwt = {
  userType: TokenUserType
  kampaayUserId: number
  countries: number[]
  env: Auth0Environment
}

export type JwtData = DecodedJwt & {
  authToken: string
}

const decodeJwt = (token: string): DecodedJwt => {
  const decoded = jwtDecode(token) as JwtToken
  const userType = decoded[USER_TYPE_KEY]
  const kampaayUserId = decoded[KAMPAAY_USER_ID_KEY]
  const env = decoded[ENVIRONMENT]

  let countries: number[] = []

  try {
    countries = decoded[COUNTRIES_KEY]
  } catch (error) {
    console.warn(
      `countries not set in current user JWT: ${decoded[COUNTRIES_KEY]}`
    )
  }
  // any unknown token userType is mapped as undefined
  return { userType, kampaayUserId, countries, env }
}

export const getToken = async (authClient: AuthClient) => {
  const authToken = await authClient.getTokenSilently()
  const { countries, ...decoded } = decodeJwt(authToken)
  const jwt = { ...decoded, countries, authToken }

  if (countries && !!countries.length) {
    setCountry(countries[0]!)

    return jwt
  }
}
