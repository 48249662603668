import { KSimpleForm } from 'components/Forms/KForms/KSimpleForm'
import React from 'react'
import KEdit from 'components/Forms/KEdit/KEdit'
import KTextInput from 'components/Inputs/KInputs/KTextInput/KTextInput'
import { useFormContext } from 'react-hook-form'

const Wrapper: React.FC = () => {
  const { setValue, getValues } = useFormContext()

  return (
    <>
      {/* This does not work, investigate further in the future */}
      <button onClick={() => setValue('title', undefined)}>Empty</button>
      <button
        onClick={() => setValue('title', (getValues('title') ?? '') + '!')}
      >
        Add !
      </button>
    </>
  )
}

const PocEdit: React.FC = () => {
  return (
    <KEdit>
      <KSimpleForm>
        <KTextInput source={'title'} />
        <Wrapper />
      </KSimpleForm>
    </KEdit>
  )
}

export default PocEdit
