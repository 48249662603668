import React from 'react'
import NoDeleteButtonToolbar from 'components/Toolbars/NoDeleteButtonToolbar'
import SectionWrapper from 'components/UI/SectionWrapper'
import ServicesConfigForm from 'resources/services-config/ServicesConfigForm'
import KEdit from 'components/Forms/KEdit/KEdit'

const ServicesConfigEdit: React.FC = () => {
  return (
    <SectionWrapper>
      <KEdit id="servicesconfig">
        <ServicesConfigForm toolbar={<NoDeleteButtonToolbar />} />
      </KEdit>
    </SectionWrapper>
  )
}

export default ServicesConfigEdit
