import { TableRow, TableCell } from '@mui/material'
import KChip from 'components/UI/KChip'
import { EventReadonlyProfessionalServiceSummary } from 'hooks/events/getReadonlyEventProfessionalService'
import { EventReadonlyServiceSummary } from 'hooks/events/useReadonlyEventService'
import { useCreatePath, useTranslate } from 'ra-core'
import React from 'react'
import {
  ArrayField,
  SingleFieldList,
  ChipField,
  BooleanField,
  NumberField,
  Link
} from 'react-admin'
import { LogisticSelection } from 'services/api/entities/events/types/internal/selections'
import EventListServicesTableFoodAdditionalInfo from 'resources/operations/events/list-components/EventListServicesTableFoodAdditionalInfo'
import ChipFieldWithQuantity from 'components/Fields/ChipFieldWithQuantity'

type Props = {
  eventId: number
  summary: EventReadonlyServiceSummary | EventReadonlyProfessionalServiceSummary
  hasIntolerances: boolean
  logistics: LogisticSelection[]
}

const EventListServicesTableRow: React.FC<Props> = ({
  eventId,
  summary,
  hasIntolerances,
  logistics
}) => {
  const createPath = useCreatePath()
  const translate = useTranslate()

  // creates a link to the tab of the service, concatenates the 'events/:id' path with the tab path.
  const linkToTab = createPath({
    resource: `events/${eventId}`,
    id: summary.serviceTabPath,
    type: 'edit'
  })

  return (
    <TableRow>
      <TableCell>
        <Link to={linkToTab}>{summary.serviceName}</Link>
      </TableCell>

      <TableCell>
        <ArrayField record={summary} source="selections">
          <SingleFieldList linkType={false}>
            <ChipFieldWithQuantity />
          </SingleFieldList>
        </ArrayField>

        {summary.serviceTabPath === 'foods' ? (
          <EventListServicesTableFoodAdditionalInfo
            hasIntolerances={hasIntolerances}
            logistics={logistics}
          />
        ) : null}
      </TableCell>

      <TableCell>
        {summary.service.supplierName ? (
          <ChipField record={summary} source="service.supplierName" />
        ) : (
          <KChip customBackgroundColor="error" label="N/A" />
        )}
      </TableCell>
      <TableCell>
        <BooleanField record={summary} source="hasCustomizations" />
      </TableCell>
      <TableCell>
        <BooleanField record={summary} source="hasUpselling" />
      </TableCell>
      <TableCell>
        <NumberField record={summary} source="service.pricing.price" />{' '}
        <span>{translate('country.currency')}</span>
      </TableCell>
    </TableRow>
  )
}

export default EventListServicesTableRow
