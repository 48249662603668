import React, { useEffect, useState } from 'react'
import { Validator } from 'ra-core'
import { monthChoices, monthDayChoices } from 'services/cfg/form'
import { getDayAndMonthFromLabel, getDayMonthLabel } from '@kampaay/common'
import { useTranslate } from 'react-admin'
import { MenuItem, styled, TextField } from '@mui/material'
import { useKInput } from 'hooks/useKInput/useKInput'
import { NullableWithDayAndMonth, DayOrMonth } from '@kampaay/common'
const PREFIX = 'DayMonthInput'

const classes = {
  inline: `${PREFIX}-inline`,
  hide: `${PREFIX}-hide`,
  sectionTitle: `${PREFIX}-sectionTitle`,
  divider: `${PREFIX}-divider`,
  productFieldsContainer: `${PREFIX}-productFieldsContainer`,
  fullWidth: `${PREFIX}-fullWidth`,
  flexColumn: `${PREFIX}-flexColumn`,
  defaultWidth: `${PREFIX}-defaultWidth`,
  width160: `${PREFIX}-width160`,
  arrayInputPadding: `${PREFIX}-arrayInputPadding`,
  mr16: `${PREFIX}-mr16`,
  flexCenter: `${PREFIX}-flexCenter`
}

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }: any) => ({
  [`& .${classes.inline}`]: { display: 'inline-flex' },

  [`& .${classes.hide}`]: {
    height: '0px',
    width: '0px',
    visibility: 'hidden'
  },

  [`& .${classes.sectionTitle}`]: {
    fontWeight: 'bold',
    fontSize: '20px',
    marginTop: '4px'
  },

  [`& .${classes.divider}`]: {
    width: '100%',
    height: '3px',
    backgroundColor: theme.palette.primary.light,
    marginTop: '20px'
  },

  [`& .${classes.productFieldsContainer}`]: {
    display: 'flex',
    flexDirection: 'column'
  },

  [`& .${classes.fullWidth}`]: {
    width: '100%'
  },

  [`& .${classes.flexColumn}`]: {
    display: 'flex',
    flexDirection: 'column'
  },

  [`& .${classes.defaultWidth}`]: {
    width: '256px'
  },

  [`& .${classes.width160}`]: {
    width: '160px'
  },

  [`& .${classes.arrayInputPadding}`]: {
    padding: '16px 0 0 0'
  },

  [`& .${classes.mr16}`]: {
    marginRight: '16px'
  },

  [`& .${classes.flexCenter}`]: {
    display: 'flex',
    justifyContent: 'center'
  }
}))

const MONTH_DAYS_MAP = {
  '01': monthDayChoices,
  '02': monthDayChoices.slice(0, 28),
  '03': monthDayChoices,
  '04': monthDayChoices.slice(0, -1),
  '05': monthDayChoices,
  '06': monthDayChoices.slice(0, -1),
  '07': monthDayChoices,
  '08': monthDayChoices,
  '09': monthDayChoices.slice(0, -1),
  '10': monthDayChoices,
  '11': monthDayChoices.slice(0, -1),
  '12': monthDayChoices
}

type MonthDayMapKey = keyof typeof MONTH_DAYS_MAP

type Props = {
  source: string
  label: string
  validate?: Validator | Validator[]
  style?: React.CSSProperties
  disabled?: boolean
}

const DayMonthInput: React.FC<Props> = ({
  source,
  validate,
  label,
  disabled = false,
  style = {}
}) => {
  const translate = useTranslate()

  const {
    field: { value, onChange },
    fieldState: { isTouched, error },
    formState: { isSubmitted }
  } = useKInput({ source, validate })

  const hasError = (isTouched || isSubmitted) && !!error

  const [dayMonthState, setDayMonthState] = useState<NullableWithDayAndMonth>({
    day: '',
    month: ''
  })

  useEffect(() => {
    setDayMonthState(getDayAndMonthFromLabel(value))
  }, [value])

  const { day, month } = dayMonthState

  const changeHandler =
    (field: DayOrMonth) =>
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      // this variable is meant to store the new day to set in the case we change the month
      // to a a new one that does not include the already selected day. which in that case gets snapped to the last available day for the new month
      const updatePayload = {
        ...dayMonthState
      }

      if (field === 'month') {
        const newMonthDayChoices = MONTH_DAYS_MAP[value as MonthDayMapKey]

        // if we're changing month and the selected day is not inside the month we snap to the latest day available for the new month
        if (!!updatePayload.day && !newMonthDayChoices.some((d) => d === day)) {
          updatePayload.day = newMonthDayChoices[newMonthDayChoices.length - 1]
        }
      }

      if (
        (field === 'month' && !!updatePayload.day) ||
        (field === 'day' && !!updatePayload.month)
      ) {
        const updatedFormValue = getDayMonthLabel({
          ...updatePayload,
          [field]: value
        })

        onChange(updatedFormValue)
      } else {
        setDayMonthState({ ...dayMonthState, [field]: value })
      }
    }

  const dayChoices = !!month ? MONTH_DAYS_MAP[month as MonthDayMapKey] : ['']

  return (
    <Root>
      <p className={classes.width160} style={{ marginBottom: '8px' }}>
        {translate(label)}
      </p>
      <div style={{ display: 'flex', alignItems: 'center', ...style }}>
        {/* here we're using TextField as Select fields instead of the specific MUI Select brecause the latter has a bug with showing the label of the field */}
        <TextField
          className={`${classes.width160} ${classes.mr16}`}
          label="Month"
          variant="filled"
          value={month}
          disabled={disabled}
          select
          onChange={changeHandler('month')}
          error={hasError}
          helperText={hasError ?? error?.message ?? ''}
        >
          {monthChoices.map((month, monthIdx) => (
            <MenuItem value={month.id} key={`${month.id}-${monthIdx}`}>
              {monthChoices[monthIdx].name}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          className={classes.width160}
          label="Day"
          variant="filled"
          value={day}
          disabled={disabled}
          select
          onChange={changeHandler('day')}
          error={hasError}
          helperText={hasError ?? error?.message ?? ''}
        >
          {dayChoices.map((day, dayIdx) => (
            <MenuItem value={day} key={`${day}-${dayIdx}`}>
              {dayChoices[dayIdx]}
            </MenuItem>
          ))}
        </TextField>
      </div>
    </Root>
  )
}

export default DayMonthInput
