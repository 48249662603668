import React, { PropsWithChildren } from 'react'
import { SimpleFormIterator } from 'react-admin'
import KDivider from 'components/UI/KDivider'
import HalfStepNumberInput from 'components/Inputs/HalfStepNumberInput/HalfStepNumberInput'
import WeekDaysRangeInput from 'components/FormComponents/pricing/byRules/WeekDaysRangeInput'
import { RAFormFC } from 'types/common'
import { Location } from 'services/api/entities/catalog/location/types/internal/location-models'
import { useForm } from 'hooks/useForm'
import DatePeriodInput from 'components/FormComponents/pricing/byRules/DayPeriodInput'
import KArrayInput from 'components/Inputs/KInputs/KArrayInput'
import { PricingRuleSet } from '@kampaay/common'

export type PricingByRulesInputProps = PropsWithChildren & {
  defaultValue?: Partial<PricingRuleSet>[]
}

const PricingByRulesInput: RAFormFC<PricingByRulesInputProps> = ({
  children,
  defaultValue = [],
  source
}) => {
  const { getSource } = useForm<Location>(source)

  return (
    <KArrayInput
      source={getSource('pricing')}
      label=""
      fullWidth
      defaultValue={defaultValue}
    >
      <SimpleFormIterator>
        <DatePeriodInput />
        <WeekDaysRangeInput />
        <HalfStepNumberInput source="minHours" label="form.fields.minHours" />
        <KDivider />
        {children}
      </SimpleFormIterator>
    </KArrayInput>
  )
}

export default PricingByRulesInput
