import React from 'react'
import MainProductList, {
  MainProductListProps
} from 'components/Lists/MainProductList'
import DrinkVariantsListPart from 'resources/catalog/drink/product/DrinkVariantsListPart'

const DrinkList: React.FC<MainProductListProps> = (props) => (
  <MainProductList {...props}>
    <DrinkVariantsListPart />
  </MainProductList>
)

export default DrinkList
