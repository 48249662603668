import { createMockBuilder } from '../../../../utils/test'
import type { Image } from '../types'

const buildBaseMock = (): Image => ({
  id: 'fake id',
  src: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/aa/Philips_PM5544.svg/2880px-Philips_PM5544.svg.png',
  title: 'fake file name'
})

export const buildImageMock = createMockBuilder(buildBaseMock)
