import React from 'react'
import OptionedProductFormContent, {
  OptionedProductFormContentProps
} from 'components/Forms/OptionedProductFormContent'
import ExperienceFacetSection from 'components/Inputs/Facets/FacetFormSections/ExperienceFacetSection'

export type ExperienceFormContentProps = OptionedProductFormContentProps

const ExperienceFormContent: React.FC<ExperienceFormContentProps> = (props) => {
  return (
    <OptionedProductFormContent
      facetFields={<ExperienceFacetSection />}
      {...props}
    >
      {props.children}
    </OptionedProductFormContent>
  )
}

export default ExperienceFormContent
