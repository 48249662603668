import { EVENT_FILTERS_DRAFTS } from 'services/filters/entities/draftEvent'
import { FILTER_SOURCES } from '@kampaay/common'
import { buildFilterConfig } from 'services/utils/filters'
import { eventStatusChoices } from 'services/cfg/form'

export const EVENT_FILTERS = [
  ...EVENT_FILTERS_DRAFTS,
  buildFilterConfig('boolean', {
    source: FILTER_SOURCES.isFromConcierge.eq,
    label: 'form.fields.isFromConcierge',
    alwaysOn: false
  }),
  buildFilterConfig('eeAssigneeSelect', {
    source: FILTER_SOURCES.kampaayerCoordinators.in,
    alwaysOn: true
  }),
  buildFilterConfig('selectArray', {
    source: FILTER_SOURCES.status.in,
    label: 'form.fields.status',
    alwaysOn: true,
    choices: eventStatusChoices.filter((c) =>
      ['confirmed', 'pendingApproval', 'closed'].includes(c.id)
    )
  })
]
