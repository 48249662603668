import { IfClauseToApi, isTruthy, Rule, ThenClauseToAPI } from '@kampaay/common'
import { KampaayDataProvider } from 'services/api/dataProvider'
import { initializeListParams } from 'services/utils'

const hasRuleIncompatibilityAction = (rule: Rule) =>
  rule.then.every((then) => then.action === 'incompatibility')

const needsInverseLogic = (rule: Rule) => !!rule.applyInverseLogic

export const reverseIfStatement = (
  ifStatement: IfClauseToApi
): ThenClauseToAPI => {
  return {
    ...ifStatement,
    action: 'incompatibility',
    targetType: !!ifStatement.products.length ? 'product' : 'service'
  }
}

export const reverseThenStatement = (
  thenStatement: ThenClauseToAPI
): IfClauseToApi | undefined => {
  return {
    what: 'cart',
    products:
      thenStatement.targetType === 'product' ? thenStatement.products : [],
    service: thenStatement.service
  }
}

export const invertRule = (rule: Rule): Rule => ({
  ...rule,
  name: `${rule.name} INVERSE`,
  operator: 'or',
  then: rule.if.map(reverseIfStatement),
  if: rule.then.map(reverseThenStatement).filter(isTruthy)
})

export const getIncompatibilityRules = async (dp: KampaayDataProvider) => {
  const { data } = await dp.getList<Rule>('rules', initializeListParams())
  const incompatibilityRules = data.filter(hasRuleIncompatibilityAction)
  const incompatibilityRulesWithInverseLogic = incompatibilityRules
    .filter(needsInverseLogic)
    .map(invertRule)

  const parsedRules = [
    ...incompatibilityRules,
    ...incompatibilityRulesWithInverseLogic
  ]

  return parsedRules
}
