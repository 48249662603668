import React from 'react'
import SectionWrapper from 'components/UI/SectionWrapper'
import { KSimpleForm } from 'components/Forms/KForms/KSimpleForm'
import KEdit from 'components/Forms/KEdit/KEdit'
import OrganizationBudgetFormContent from 'components/Forms/OrganizationBudgetFormContent'
import { useParams } from 'react-router-dom'

const OrganizationBudgetEdit: React.FC = () => {
  const { budgetId, id: organizationId } = useParams()

  return (
    <SectionWrapper title="resources.organization.editBudget">
      <KEdit resource="organizationbudget" id={budgetId}>
        <KSimpleForm
          redirectAfterDelete={() => `organization/${organizationId}/budgets`}
        >
          <OrganizationBudgetFormContent />
        </KSimpleForm>
      </KEdit>
    </SectionWrapper>
  )
}

export default OrganizationBudgetEdit
