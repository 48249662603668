import KAccordion from 'components/UI/Accordions/KAccordion/KAccordion'
import React, { useMemo } from 'react'
import OptionedProductTailoringPricesInput from 'resources/crm/clients/organization/tailoring/components/optioned/OptionedProductTailoringPricesInput'
import { KBooleanInput } from 'components/Inputs/KInputs/KBooleanInput'
import { RAFormFC } from 'types/common'
import { useForm } from 'hooks/useForm'
import CustomReferenceInput from 'components/Inputs/CustomReferenceInput'
import KAutocompleteInput from 'components/Inputs/KInputs/KAutocompleteInput'
import { Organization } from 'services/api/entities/crm/organizations/types/internal/organization-model'
import { useKRecordContext } from 'hooks/useKRecordContext'
import { PhysicalExperience } from 'services/api/entities/catalog/physical/types/internal/physical-models'
import KNumberInput from 'components/Inputs/KInputs/KNumberInput/KNumberInput'
import { getById, PhysicalOptionedTailoring } from '@kampaay/common'

type Props = {
  selectedOrganizationId?: number
  organizations: Organization[]
}

const PhysicalTailoringInput: RAFormFC<Props> = ({
  selectedOrganizationId,
  source,
  organizations
}) => {
  const record = useKRecordContext<PhysicalExperience>()
  const { getSource, useGetValue } = useForm<PhysicalOptionedTailoring>(source)
  const currentOrganizationId = useGetValue('organizationId')

  const currentOrganization = useMemo(
    () => getById(organizations, currentOrganizationId),
    [organizations, currentOrganizationId]
  )

  // If the organization is filtered out
  if (
    !!selectedOrganizationId &&
    selectedOrganizationId !== currentOrganizationId
  ) {
    return null
  }

  return currentOrganizationId ? (
    <KAccordion accordionTitle={currentOrganization?.name!}>
      <KNumberInput
        className={'hidden'}
        disabled
        source={getSource('id')}
        defaultValue={record?.id}
      />
      <KBooleanInput
        source={getSource('isHidden')}
        label={'form.fields.isHidden'}
        defaultValue={false}
      />
      <KBooleanInput
        source={getSource('hasCustomPrice')}
        label={'form.fields.addCustomPrice'}
      />
      <OptionedProductTailoringPricesInput
        productEntity="physicals"
        source={source}
      />
    </KAccordion>
  ) : (
    <CustomReferenceInput entity={'organization'}>
      <KAutocompleteInput
        className="w-72"
        label="form.fields.physicals.addOrganization"
        source={getSource('organizationId')}
      />
    </CustomReferenceInput>
  )
}

export default PhysicalTailoringInput
