import {
  RuleAction,
  RuleTargetType,
  ProductServiceDiscriminator
} from '@kampaay/common'

export const TARGET_TYPE_FOR_RULE_ACTION: Record<RuleAction, RuleTargetType[]> =
  {
    suggest: ['product'],
    incompatibility: ['product', 'service']
  }

export const THEN_CLAUSE_TARGETTABLE_SERVICES: Record<
  RuleTargetType,
  ProductServiceDiscriminator[]
> = {
  product: [
    'DrinkConfigurator',
    'ProfessionalConfigurator',
    'RentConfigurator',
    'FoodConfigurator'
  ],
  service: [
    'DrinkConfigurator',
    'ProfessionalConfigurator',
    'RentConfigurator',
    'FoodConfigurator'
  ]
}

export const SERVICE_WHERE_AVAILABLE: ProductServiceDiscriminator[] = [
  'FoodConfigurator'
]
