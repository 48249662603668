import React from 'react'
import { ChipField, ChipFieldProps } from 'react-admin'
import LocalizedField, {
  LocalizedFieldProps
} from 'components/Fields/Localized/LocalizedField'

type LocalizedChipFieldProps = Pick<LocalizedFieldProps, 'source' | 'record'> &
  ChipFieldProps

/**
 * This is a ChipField that translates the text from multilang
 * @param source - the source of the text to translate
 * @param record - the record to translate - if not passed it will be retrieved from the context
 * @param style - the style of the chip
 */
const LocalizedChipField: React.FC<LocalizedChipFieldProps> = ({
  source,
  record,
  ...chipFieldProps
}) => (
  <LocalizedField source={source} record={record}>
    <ChipField {...chipFieldProps} />
  </LocalizedField>
)

export default LocalizedChipField
