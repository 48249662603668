import { TimeLabel } from '@kampaay/common'

/**
 * From a start number and an end number get all the ".5" steps inbetween.
 *
 * When we reach the 24 hours, we start back from 0
 *
 * @example
 * getHalfStepHoursArray(0, 4) // [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4]
 * getHalfStepHoursArray(19, 1) // [19, 19.5, ... ,23, 23.5, 0, 0.5]
 */
export const getHalfStepHoursArray = (start: number, end: number) => {
  const iterations = end - start > 0 ? end - start : end - start + 24
  const arr: number[] = []

  for (let i = 0; i < iterations; i = i + 0.5) {
    arr.push((start + i) % 24)
  }
  return arr
}

const getHoursFromTimeLabel = (timeLabel: TimeLabel) => {
  const [hours, minutes] = timeLabel.split(':')
  return Number(hours) + Number(minutes) / 60
}

/**
 * Given an array of object with a start and end time
 * returns the "Half Hour Steps" between the start and end time of all the element of the array
 *
 * @example
 * getSetFromHalfSteps([{ start: '10:00', end: '11:00' }, { start: '12:00', end: '13:00' }]) // [10, 10.5, 11, 12, 12.5, 13]
 *
 * @param timeRange
 */
export const getSetFromHalfSteps = (
  ...timeRange: { start: TimeLabel; end: TimeLabel }[]
): Set<number> => {
  const steps = timeRange.flatMap((tr) => {
    const start = getHoursFromTimeLabel(tr.start)
    const end = getHoursFromTimeLabel(tr.end)
    return getHalfStepHoursArray(start, end)
  })

  return new Set(steps)
}

export type PricingRuleDatesNumbers = {
  periodFrom: number
  periodTo: number
  dayFrom: number
  dayTo: number
}

// Checks that periodFrom and periodTo (converted to their corresponding day number 1-366) are not overlapping
export const areDatesOverlapping = (
  first: PricingRuleDatesNumbers,
  second: PricingRuleDatesNumbers
) =>
  second.periodFrom.isWithin(first.periodFrom, first.periodTo) ||
  second.periodTo.isWithin(first.periodFrom, first.periodTo)

// Check that the week days (converted to integers 0-6) are not overlapping
export const areWeekDaysOverlapping = (
  first: PricingRuleDatesNumbers,
  second: PricingRuleDatesNumbers
) =>
  second.dayFrom.isWithin(first.dayFrom, first.dayTo) ||
  second.dayTo.isWithin(first.dayFrom, first.dayTo)
