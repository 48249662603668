import { createMockBuilder, OrganizationBudget } from '@kampaay/common'

const buildBaseMock = (): OrganizationBudget => ({
  id: 1,
  name: 'Budget 1',
  organizationId: 2,
  totalAmount: 1000,
  amountSpent: 50,
  startDate: new Date('2024-01-01T10:00:00Z'),
  endDate: new Date('2024-12-31T10:00:00Z'),
  isActive: true,
  canBeExceeded: true,
  membershipsIds: [1, 2, 3],
  automaticRenewal: false,
  organizationSubGroups: []
})

export const buildOrganizationBudgetMock = createMockBuilder(buildBaseMock)
