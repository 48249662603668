import React, { useMemo, useState } from 'react'
import {
  Datagrid,
  FunctionField,
  NumberField,
  ReferenceField,
  TextField
} from 'react-admin'
import OkrCountryViewSwitch, { OKRCountryView } from 'okrs/OkrCountryViewSwitch'
import { TableCell, TableHead, TableRow } from '@mui/material'
import { OKREnrichedProposal } from 'okrs/OkrsPage'
import { addDays } from 'date-fns'
import KampaayerReferenceField from 'resources/crm/kampaayers/KampaayerReferenceField'
import { Concierge, isTruthy, Proposal } from '@kampaay/common'

type Props = {
  rangeStart: string
  rangeEnd: string
  conciergeProposals: OKREnrichedProposal[]
}

const DatagridHeader: React.FC = () => (
  <TableHead>
    <TableRow>
      <TableCell>Id</TableCell>
      <TableCell>Country</TableCell>
      <TableCell>Name</TableCell>
      <TableCell>Concierge Name</TableCell>
      <TableCell>EE Assignee</TableCell>
      <TableCell>Supplier Association</TableCell>
    </TableRow>
  </TableHead>
)

const SupplierAssignationOkrsTabContent: React.FC<Props> = ({
  rangeEnd,
  rangeStart,
  conciergeProposals
}) => {
  const [currentView, setCurrentView] = useState<OKRCountryView>('all')

  const relevantProposals: OKREnrichedProposal[] = useMemo(() => {
    // grouping proposals with the other ones in the same history,
    // and concurrently sorting the proposals ascending based on publish date
    const groupedByHistory = conciergeProposals.reduce((acc, p) => {
      const historyId = p.proposalHistory

      if (!historyId) return acc

      if (acc[historyId]) {
        const updatedList = [...acc[historyId], p]
        updatedList.sort((a, b) => (a.publishedAt! > b.publishedAt! ? 1 : -1))

        acc[historyId] = updatedList
      } else {
        acc[historyId] = [p]
      }

      return acc
    }, {} as Record<number, OKREnrichedProposal[]>)

    // Filtering the proposals to view in the list by the ones whose first version has been published
    // in the selected range, then returning the last version of each history
    return Object.values(groupedByHistory)
      .filter(
        (arr) =>
          new Date(arr[0].publishedAt!) >= new Date(rangeStart) &&
          new Date(arr[0].publishedAt!) <= addDays(new Date(rangeEnd), 1)
      )
      .flatMap((arr) => arr.at(-1))
      .filter(isTruthy)
  }, [conciergeProposals])

  const filteredProposals = useMemo(() => {
    if (currentView === 'all') return relevantProposals
    if (currentView === 'swe')
      return relevantProposals.filter((p) => p.country === 'Sweden')
    else return relevantProposals.filter((p) => p.country === 'Italy')
  }, [currentView, relevantProposals])

  const overallResult = useMemo(() => {
    const items = filteredProposals.flatMap((p) =>
      p.itemGroups.flatMap((ig) => ig.items)
    )
    const itemsWithSupplier = items.filter((i) => !!i.supplier)

    const percentage = `${(!items.length
      ? 0
      : (itemsWithSupplier.length / items.length) * 100
    ).toFixed(2)} %`

    return {
      totalItems: items,
      totalItemsWithSupplier: itemsWithSupplier,
      percentage
    }
  }, [filteredProposals])

  return (
    <div className="rounded-lg bg-white shadow">
      <div className="flex justify-around">
        <OkrCountryViewSwitch
          value={currentView}
          updateValue={setCurrentView}
          title={`Currently viewed proposals: ${filteredProposals.length}`}
        />

        <div className={`m-3 rounded-2xl bg-neutral-300 p-4`}>
          <div>
            Total Items in the viewed proposals:{' '}
            {overallResult.totalItems.length}
          </div>
          <div>
            Total items with a supplier assigned:{' '}
            {overallResult.totalItemsWithSupplier.length}
          </div>
          <div>
            Percentage of items association with suppliers:{' '}
            {overallResult.percentage}
          </div>
        </div>
      </div>

      <Datagrid header={DatagridHeader} data={filteredProposals}>
        <NumberField label="Id" source={'id'} sortable={false} />
        <TextField label="Country" source="country" sortable={false} />
        <TextField label="Name" source="name" sortable={false} />
        <ReferenceField
          reference={'concierge'}
          source={'conciergeId'}
          label={'Concierge name'}
          link={false}
          sortable={false}
        >
          <FunctionField render={(c: Concierge) => `${c.id} - ${c.name}`} />
        </ReferenceField>
        <KampaayerReferenceField label={'EE Assignee'} />
        <FunctionField
          label={'Supplier association'}
          sortable={false}
          render={(p: Proposal) => {
            const items = p.itemGroups.flatMap((ig) => ig.items)
            const itemsWithSupplier = items.filter((i) => !!i.supplier)

            return `${(!items.length
              ? 0
              : (itemsWithSupplier.length / items.length) * 100
            ).toFixed(2)} %`
          }}
        />
      </Datagrid>
    </div>
  )
}

export default SupplierAssignationOkrsTabContent
