import React from 'react'
import SectionWrapper from 'components/UI/SectionWrapper'
import FoodUpsellingFormContent from 'resources/catalog/food/upselling/FoodUpsellingFormContent'
import { KSimpleForm } from 'components/Forms/KForms/KSimpleForm'
import KCreate from 'components/Forms/KCreate/KCreate'

const FoodUpsellingCreate: React.FC = () => {
  return (
    <SectionWrapper>
      <KCreate>
        <KSimpleForm>
          <FoodUpsellingFormContent />
        </KSimpleForm>
      </KCreate>
    </SectionWrapper>
  )
}

export default FoodUpsellingCreate
