import { EditWithComparator } from 'poc/EditWithComparator'
import { renderCount } from 'poc/renderCount'
import React from 'react'
import { required, SimpleFormIterator } from 'react-admin'
import { KSimpleForm } from 'components/Forms/KForms/KSimpleForm'
import KTextInput, {
  KTextInputProps
} from 'components/Inputs/KInputs/KTextInput/KTextInput'
import KArrayInput from 'components/Inputs/KInputs/KArrayInput'

const CustomTextInput: React.FC<KTextInputProps> = (props) => {
  renderCount('CustomTextInput')
  return <KTextInput {...props} />
}

const PocEdit: React.FC = () => (
  <EditWithComparator>
    {(comp) => (
      <KSimpleForm>
        <KTextInput disabled label="Id" source="id" />
        <KTextInput source="title" validate={required()} />
        <KArrayInput source="highlights">
          <SimpleFormIterator>
            {comp({
              choice1: <KTextInput label="choice1" source="item" />,
              choice2: <CustomTextInput label="choice3" source="item" />
            })}
          </SimpleFormIterator>
        </KArrayInput>
      </KSimpleForm>
    )}
  </EditWithComparator>
)

export default PocEdit
