import React from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton
} from '@mui/material'
import { Toolbar, useCreateSuggestionContext, useTranslate } from 'react-admin'
import FlexBox from 'components/UI/layout/FlexBox'
import { Close } from '@mui/icons-material'
import KCreate from 'components/Forms/KCreate/KCreate'
import { KSimpleForm } from 'components/Forms/KForms/KSimpleForm'
import { useParams } from 'react-router-dom'
import KNumberInput from 'components/Inputs/KInputs/KNumberInput/KNumberInput'
import { useCustomReferenceContext } from 'components/Inputs/CustomReferenceContext'
import KSaveButton from 'components/Buttons/KSaveButton'
import OrganizationSubGroupFormContent from 'components/Forms/OrganizationSubGroupFormContent'

const BottomToolbar: React.FC = () => {
  const { refetch } = useCustomReferenceContext()
  const { onCreate } = useCreateSuggestionContext()

  return (
    <Toolbar>
      <KSaveButton
        mutationOptions={{
          onSuccess: (data) => {
            onCreate(data)
            refetch()
          }
        }}
      />
    </Toolbar>
  )
}

const OrganizationSubGroupCreateModal: React.FC = () => {
  const translate = useTranslate()
  const { onCancel } = useCreateSuggestionContext()
  const { id: organizationId } = useParams()

  return (
    <Dialog open maxWidth="xl" fullWidth>
      <FlexBox justifyContent="space-between">
        <DialogTitle>
          {translate('resources.organization.createSubGroup')}
        </DialogTitle>
        <DialogActions>
          <IconButton onClick={onCancel}>
            <Close />
          </IconButton>
        </DialogActions>
      </FlexBox>
      <DialogContent style={{ paddingTop: 0 }}>
        <KCreate resource="organizationsubgroup">
          <KSimpleForm redirectAfterCreate={false} toolbar={<BottomToolbar />}>
            <KNumberInput
              source="organizationId"
              defaultValue={parseInt(organizationId!)}
              style={{ display: 'none' }}
            />
            <OrganizationSubGroupFormContent isMembersSectionShown={false} />
          </KSimpleForm>
        </KCreate>
      </DialogContent>
    </Dialog>
  )
}

export default OrganizationSubGroupCreateModal
