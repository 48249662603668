import { FILTER_SOURCES } from '@kampaay/common'
import { GEO_AREA_FILTER_CFG } from 'services/filters/entities/geoareaFilter'
import { buildFilterConfig } from 'services/utils/filters'

export const EVENT_FILTERS_DRAFTS = [
  buildFilterConfig('text', {
    source: FILTER_SOURCES.name.contains,
    label: 'form.fields.eventName',
    alwaysOn: true
  }),
  buildFilterConfig('text', {
    source: FILTER_SOURCES.customerName.contains,
    label: 'form.fields.customerFullName',
    alwaysOn: true
  }),
  buildFilterConfig('text', {
    source: FILTER_SOURCES.customerEmail.contains,
    label: 'form.fields.email',
    alwaysOn: true
  }),
  GEO_AREA_FILTER_CFG,
  buildFilterConfig('date', {
    source: FILTER_SOURCES.startDate.gte,
    label: 'form.fields.dateFrom',
    alwaysOn: false
  }),
  buildFilterConfig('date', {
    source: FILTER_SOURCES.startDate.lt,
    label: 'form.fields.dateTo',
    alwaysOn: false
  })
]
