import React from 'react'
import {
  Calendar,
  dateFnsLocalizer,
  type ToolbarProps,
  type View
} from 'react-big-calendar'
import OrganizationCalendarToolbar from 'components/UI/Organization/Calendar/OrganizationCalendarToolbar'
import OrganizationCalendarItem from 'components/UI/Organization/Calendar/Item/OrganizationCalendarItem'
import type { EventPlaceholder } from 'services/api/entities/event-placeholder/types/internal/event-placeholder-models'
import {
  type CalendarSurroundingPeriod,
  formatCalendarItems,
  getToday
} from 'services/utils/organization/calendar'
import { enUS } from 'date-fns/locale'
import { format, parse, startOfWeek, getDay } from 'date-fns'
import type { EventItem } from 'services/api/entities/events/types/internal/event-models'
import type { CalendarConcierge } from 'resources/crm/clients/organization/calendar/OrganizationCalendarList'

import 'components/UI/Organization/Calendar/style.css'

export type CalendarItem = EventPlaceholder | EventItem | CalendarConcierge

export type WithCalendarOriginalItem = {
  originalItem: CalendarItem
}

type Props = {
  isLoading: boolean
  items: CalendarItem[]
  currentPeriod: CalendarSurroundingPeriod
  setCurrentPeriod: (period: CalendarSurroundingPeriod) => void
}

// See https://jquense.github.io/react-big-calendar/examples/index.html?path=/docs/props--localizer#localizer
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek: () => startOfWeek(getToday(), { weekStartsOn: 1 }),
  getDay,
  locales: {
    'en-US': enUS
  }
})

export const enabledViews = ['month', 'week', 'day'] satisfies View[]

const OrganizationCalendar: React.FC<Props> = ({
  isLoading,
  items,
  currentPeriod,
  setCurrentPeriod
}) => {
  return (
    <Calendar
      localizer={localizer}
      components={{
        event: OrganizationCalendarItem,
        toolbar: (toolbar: ToolbarProps<WithCalendarOriginalItem>) => (
          <OrganizationCalendarToolbar
            isLoading={isLoading}
            toolbar={toolbar}
            currentPeriod={currentPeriod}
            setCurrentPeriod={setCurrentPeriod}
          />
        )
      }}
      events={formatCalendarItems(items)}
      startAccessor="startDate"
      endAccessor="endDate"
      views={enabledViews}
    />
  )
}

export default OrganizationCalendar
