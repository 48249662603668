import { APIDoc, APIImage, Image, UploadedDoc, isTruthy } from '@kampaay/common'

/**
 * @deprecated
 *
 * @param img
 * @returns
 */
export const parseRaImage = (img?: APIImage): Image | undefined => {
  if (!img) return undefined

  return {
    id: img.id,
    src: img.url,
    title: img.fileName
  }
}

/**
 * @deprecated
 *
 * @param img
 * @returns
 */
export const parseRaDoc = (doc?: APIDoc): UploadedDoc | undefined => {
  if (!doc) return undefined

  return {
    id: doc.id,
    src: doc.url,
    title: doc.fileName
  } as UploadedDoc
}

/**
 * @deprecated
 *
 * @param img
 * @returns
 */
export const parseRaMultipleDocs = (docs: APIDoc[]): UploadedDoc[] => {
  return docs.map(parseRaDoc).filter(isTruthy)
}
