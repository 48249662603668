import { useCatalogProductsMap } from 'hooks/eventPackage/useCatalogProductsMap'
import { MainProductAPIEntity } from 'services/api/entities'
import { arrayIncludesEvery } from 'services/utils'

type Payload = {
  SCN: MainProductAPIEntity
  selectionIds: (number | undefined)[]
}

//At least 1 product within the service selection is not available in the catalog anymore
export const useCheckIfSelectionsNotInCatalog = ({
  SCN,
  selectionIds
}: Payload) => {
  const productsMap = useCatalogProductsMap()
  const selectedServiceArray = productsMap[SCN]
  const selectedServiceArrayIds = selectedServiceArray.map(
    (product: { id: number }) => product.id
  )
  return arrayIncludesEvery(selectedServiceArrayIds, selectionIds)
    ? false
    : true
}
