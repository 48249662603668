import { Warning } from '@mui/icons-material'
import KBanner from 'components/UI/KBanner'
import { useGetOne } from 'ra-core'
import React from 'react'
import { useParams } from 'react-router-dom'
import { ServiceCollectionName } from 'types/common'

type HiddenServiceTailoringBannerProps = {
  service: ServiceCollectionName
}

const HiddenServiceTailoringBanner: React.FC<
  HiddenServiceTailoringBannerProps
> = ({ service }) => {
  const { id: organizationId } = useParams()

  const useIsServiceHidden = (
    service: ServiceCollectionName,
    organizationId: number
  ): boolean =>
    useGetOne(`organization`, {
      id: organizationId
    }).data?.hiddenServices[service]

  return (
    <>
      {useIsServiceHidden(service, Number(organizationId)!) && (
        <KBanner
          text="resources.customers.hiddenServices.bannerWarning"
          icon={<Warning />}
        />
      )}
    </>
  )
}

export default HiddenServiceTailoringBanner
