import {
  CSVEntityDescriptor,
  CSVEntityDescriptorMap,
  CSVToAPIEntityMap,
  EntityWithCSV,
  MulticonfigCSVEntityDescriptor
} from 'services/csv/types'
import { isMulticonfigCSVEntityDescriptor } from 'services/csv/utils/isMulticonfigCSVEntityDescriptor'

/**
 * Returns a CSVEntityDescriptor from a CSVEntityDescriptorMap.
 * If the map value is not a multiconfig descriptor and the passed descriptorKey is undefined, return the normal keyless descriptor.
 * If the map value is not a multiconfig descriptor and the passed descriptorKey is defined, return the normal keyless descriptor.
 * If the map value is a multiconfig descriptor and the passed descriptorKey is undefined, return the descriptor under the primary key.
 * If the map value is a multiconfig descriptor and the passed descriptorKey is defined, return the descriptor under the passed key.
 * @param csvConfig
 * @param csvEntityDescriptorKey
 * @returns The CSVEntityDescriptor of an entity
 */
export const getDescriptor = <T extends EntityWithCSV>(
  csvConfig: CSVEntityDescriptorMap[T],
  csvEntityDescriptorKey: keyof MulticonfigCSVEntityDescriptor<T> | undefined
): CSVEntityDescriptor<CSVToAPIEntityMap[T]> => {
  if (isMulticonfigCSVEntityDescriptor(csvConfig)) {
    if (csvEntityDescriptorKey) {
      if (!csvConfig[csvEntityDescriptorKey]) {
        throw new Error(
          `The Descriptor with key \"${csvEntityDescriptorKey}\" doesn't exist in the CSVEntityDescriptorMap`
        )
      }
      return csvConfig[csvEntityDescriptorKey]
    }
    return csvConfig.primary
  }
  return csvConfig as CSVEntityDescriptor<CSVToAPIEntityMap[T]>
}
