import { parseSupplierType, writeSupplierType } from '@kampaay/common'
import { APICfg } from 'services/api/api'

//=========================
// API CFG
//=========================
const apiCfg: APICfg = {
  endpoint: 'suppliertype',
  parse: parseSupplierType,
  write: writeSupplierType
}

export default apiCfg
