import React, { useMemo, useState } from 'react'
import KAccordion from 'components/UI/Accordions/KAccordion/KAccordion'
import { useKGetList } from 'hooks/useKGetList'
import { Organization } from 'services/api/entities/crm/organizations/types/internal/organization-model'
import { Autocomplete, TextField } from '@mui/material'
import { SimpleFormIterator } from 'react-admin'
import KArrayInput from 'components/Inputs/KInputs/KArrayInput'
import PhysicalTailoringInput from 'resources/catalog/physicalexperiences/product/PhysicalTailoringInput'
import { useTranslate } from 'ra-core'

const PhysicalTailoringSection = () => {
  const translate = useTranslate()

  const [selectedOrganization, setSelectedOrganization] = useState<{
    id: number
    label: string
  } | null>(null)

  const { list: organizations } = useKGetList<Organization>('organization')

  const organizationOptions = useMemo(
    () => organizations.map((org) => ({ id: org.id, label: org.name })),
    [organizations]
  )

  return (
    <KAccordion accordionTitle="form.fields.physicals.tailorings">
      <Autocomplete
        placeholder={'form.fields.physicals.organizationSearch.placeholder'}
        className={'w-72'}
        options={organizationOptions}
        renderInput={(params) => (
          <TextField
            {...params}
            label={translate('form.fields.physicals.organizationSearch.label')}
          />
        )}
        value={selectedOrganization}
        onChange={(_e, value) => {
          setSelectedOrganization(value)
        }}
      />
      <KArrayInput source="tailorings">
        <SimpleFormIterator
          fullWidth
          className={'w-full'}
          disableRemove
          disableReordering
        >
          <PhysicalTailoringInput
            selectedOrganizationId={selectedOrganization?.id}
            organizations={organizations}
          />
        </SimpleFormIterator>
      </KArrayInput>
    </KAccordion>
  )
}

export default PhysicalTailoringSection
