import type { Customer } from '../types'
import { createMockBuilder } from '../../../../utils'
import { buildCustomerBaseMock } from './customerBase-mocks'

const buildBaseMock = (): Customer => ({
  ...buildCustomerBaseMock(),
  address: undefined,
  promotionalActivitiesConsensus: true,
  privacyPolicyAccepted: true,
  termsAcceptance: true,
  dataProcessingConsensus: true,
  phoneVerified: true,
  email: 'test email',
  emailVerified: true,
  organizationNumber: 'test organizationNumber',
  status: 'active',
  phone: 'test phone'
})

export const buildCustomerMock = createMockBuilder(buildBaseMock)
