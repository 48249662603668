import React from 'react'
import { RAFormFC } from 'types/common'
import { KBooleanInput } from 'components/Inputs/KInputs/KBooleanInput'
import { useForm } from 'hooks/useForm'
import { CheckoutQuestion } from 'services/api/entities/services-config/types/internal/question-models'
import LocalizedTextInput from 'components/Inputs/Localized/LocalizedTextInput'
import KSelectInput from 'components/Inputs/KInputs/KSelectInput'
import { checkoutQuestionChoices } from 'services/cfg/form'
import { useFormContext } from 'react-hook-form'
import KArrayInput from 'components/Inputs/KInputs/KArrayInput'
import { SimpleFormIterator } from 'react-admin'
import KTextInput from 'components/Inputs/KInputs/KTextInput/KTextInput'

const OrganizationCheckoutQuestion: RAFormFC = ({ source }) => {
  const { getSource } = useForm<CheckoutQuestion>(source)
  const { watch } = useFormContext()

  const questionType = watch(getSource('type'))

  return (
    <div className="my-4 rounded border border-solid border-neutral-300 p-6">
      <div className="flex items-center gap-6">
        <LocalizedTextInput
          source={getSource('text')}
          label="form.fields.checkoutQuestionName"
        />
        <KSelectInput
          source={getSource('type')}
          label="form.fields.type"
          choices={checkoutQuestionChoices}
          defaultValue={checkoutQuestionChoices[0].id}
        />
        <KBooleanInput
          source={getSource('mandatory')}
          label="form.fields.mandatory"
        />
      </div>
      <LocalizedTextInput
        source={getSource('description')}
        label="form.fields.description"
        fullWidth
      />
      {questionType?.toLowerCase().includes('select') && (
        <KArrayInput source={getSource('options')}>
          <SimpleFormIterator>
            <KTextInput source="" />
          </SimpleFormIterator>
        </KArrayInput>
      )}
    </div>
  )
}

export default OrganizationCheckoutQuestion
