import { useTranslate } from 'ra-core'
import React, { useEffect, useMemo } from 'react'
import { TransactionService } from 'services/api/entities/accounting/transaction/types/internal/transaction-models'
import { RAFormFC } from 'types/common'
import LabeledNumberField from 'components/Fields/LabeledFields/LabeledNumberField'
import LabeledTextField from 'components/Fields/LabeledFields/LabeledTextField'
import KNumberInput from 'components/Inputs/KInputs/KNumberInput/KNumberInput'
import {
  getServiceBillingInfo,
  TransactionServiceBillingInfo
} from 'resources/accounting/transaction/computedGetters'
import LabeledBooleanField from 'components/Fields/LabeledFields/LabeledBooleanField'
import { useForm } from 'hooks/useForm'
import TransactionCustomizationArrayInput from 'components/FormComponents/Transaction/TransactionCustomizationArrayInput'
import { buildStyledComponent } from 'services/utils/style/buildStyledComponent'
import { type CountryCode } from '@kampaay/common'

const [Root, classes] = buildStyledComponent(
  'FormService',
  'div',
  () => ({}),
  (theme) => ({
    inline: {
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between'
    },
    divider: {
      width: '100%',
      height: '3px',
      backgroundColor: theme.palette.primary.light,
      marginTop: '20px'
    },
    column: {
      display: 'flex',
      margin: '0px 24px',
      flexDirection: 'column'
    },
    withoutVATLabel: {
      width: '100%',
      marginTop: '10px'
    },
    exportPadding: {
      padding: '10px'
    },
    dNone: {
      display: 'none'
    }
  })
)

export type ServiceComponentProps = {
  formId: number
  updateServiceBillingInfo: (
    partialRecord: TransactionServiceBillingInfo
  ) => void
  customerBusinessCountry?: CountryCode
}

const TransactionServiceInput: RAFormFC<ServiceComponentProps> = ({
  formId,
  updateServiceBillingInfo,
  customerBusinessCountry,
  source
}) => {
  const { useGetValue, getSource } = useForm<TransactionService>(source)

  const costNoVat = useGetValue('costNoVat')
  let deltaCostNoVat = useGetValue('deltaCostNoVat')
  deltaCostNoVat = deltaCostNoVat != undefined ? deltaCostNoVat : 0
  const costVatPerc = useGetValue('costVatPerc')
  const customizations = useGetValue('customizations')
  const price = useGetValue('price')
  const supplier = useGetValue('supplier')
  const experienceNames = useGetValue('experienceNames')
  const serviceName = useGetValue('serviceName')
  const deliveryCost = useGetValue('deliveryCost')
  const translate = useTranslate()
  const computedRecord = useMemo(
    () =>
      getServiceBillingInfo(
        {
          costNoVat,
          deltaCostNoVat,
          costVatPerc,
          customizations,
          price,
          serviceName,
          deliveryCost
        },
        customerBusinessCountry
      ),
    [
      costNoVat,
      deltaCostNoVat,
      costVatPerc,
      customizations?.sum((c) => (c.price ? c.price : 0)) ?? 0,
      customizations?.sum((c) => (c.cost ? c.cost : 0)) ?? 0,
      price,
      serviceName,
      deliveryCost,
      customerBusinessCountry
    ]
  )

  // this fix multiple re-rendering due to updateServiceBillingInfo
  // used as dependency in useEffect
  const memoizedUpdateServiceBillingInfo = useMemo(
    () => updateServiceBillingInfo,
    []
  )
  useEffect(() => {
    memoizedUpdateServiceBillingInfo(computedRecord)
  }, [memoizedUpdateServiceBillingInfo, computedRecord])

  const scopedRecord = { id: formId, ...computedRecord }

  return (
    <Root className={classes.inline}>
      <div className={classes.column}>
        <h3>{translate('form.headers.revenues')}</h3>
        <LabeledNumberField
          label="form.fields.grossPrice"
          record={scopedRecord}
          source="grossPrice"
          roundTwoDecimals
        />

        <LabeledNumberField
          label="form.fields.inboundVat"
          record={scopedRecord}
          source="inboundVat"
          roundTwoDecimals
        />

        <LabeledNumberField
          label="form.fields.price"
          record={scopedRecord}
          source="price"
          roundTwoDecimals
        />
      </div>

      <div className={classes.column}>
        <h3>{translate('form.headers.costs')}</h3>
        <LabeledNumberField
          label="form.fields.cost"
          record={scopedRecord}
          source="totalCost"
          roundTwoDecimals
        />

        <LabeledNumberField
          label="form.fields.costNoVat"
          record={scopedRecord}
          source={'costNoVat'}
        />

        <KNumberInput
          label="form.fields.deltaCost"
          source={getSource('deltaCostNoVat')}
          placeholder={'0'}
          defaultValue={0}
        />

        <KNumberInput
          source={getSource('costVatPerc')}
          label="form.fields.costVatPerc"
        />

        <LabeledNumberField
          label="form.fields.costVat"
          record={scopedRecord}
          source="costVat"
          roundTwoDecimals
        />

        {!!supplier && (
          <>
            <LabeledTextField
              label="Supplier"
              record={scopedRecord}
              source="supplier"
            />

            <LabeledBooleanField
              source={getSource('supplyConfirmed')}
              label="Supply Confirmation"
            />
          </>
        )}
      </div>

      <div className={classes.column}>
        <h3>{translate('form.headers.margins')}</h3>
        <LabeledNumberField
          label="form.fields.margin"
          record={scopedRecord}
          source="margin"
        />
        <LabeledNumberField
          label="form.fields.vatEffect"
          record={scopedRecord}
          source="vatEffect"
          roundTwoDecimals
        />
        <LabeledNumberField
          label="form.fields.marginPerc"
          record={scopedRecord}
          source="marginPerc"
          roundTwoDecimals
        />

        {!!experienceNames?.length && (
          <>
            <h3>Purchased Formats</h3>

            {experienceNames.map((name, idx) => (
              <LabeledTextField
                key={idx}
                label="Experience"
                record={{ id: idx, name: name }}
                source="name"
              />
            ))}
          </>
        )}
      </div>

      {!!customizations?.length && (
        <div>
          <h3>{translate('form.headers.customization')}</h3>
          <TransactionCustomizationArrayInput source={source} />
        </div>
      )}
    </Root>
  )
}

export default TransactionServiceInput
