import { TableBody, TableRow, TableCell, TextField } from '@mui/material'
import React from 'react'
import { Button } from 'react-admin'
import { entries } from 'services/utils'
import CopyToClipboardButton from 'components/Buttons/CopyToClipboardButton'
import type { I18nMap, PlatformCode } from 'i18n/components/I18nFileProvider'
import type {
  DeleteLabelFn,
  DuplicateLabelFn,
  UpdateTranslationFn
} from 'i18n/components/I18nFormHandler'

type Props = {
  labelsToRender: string[]
  i18nMap: I18nMap
  updateTranslation: UpdateTranslationFn
  deleteLabel: DeleteLabelFn
  duplicateLabel: DuplicateLabelFn
  isDeleteEnabled: boolean
  isDuplicateEnabled: boolean
  duplicatedLabels: string[]
  platformCode: PlatformCode
}

const I18nLabelsForm: React.FC<Props> = ({
  i18nMap,
  labelsToRender,
  updateTranslation,
  deleteLabel,
  duplicateLabel,
  isDeleteEnabled,
  isDuplicateEnabled,
  duplicatedLabels,
  platformCode
}) => {
  return (
    <TableBody>
      {labelsToRender.map((key, idx) => (
        <TableRow key={idx}>
          <TableCell style={{ width: '20%' }}>
            <div
              style={{
                display: 'flex',
                flexWrap: 'nowrap',
                alignItems: 'center'
              }}
            >
              <CopyToClipboardButton
                textToCopy={key}
                label="tooltip.i18n.copyLabelToClipboard"
                notificationLabel="notification.info.labelCopiedToClipboard"
              />
              <span>{key}</span>
            </div>
          </TableCell>
          {entries(i18nMap).map(([code, json], index) => (
            <TableCell key={index + 100}>
              <TextField
                value={json[key]}
                onChange={updateTranslation(key, code)}
                variant="outlined"
                size="small"
                fullWidth
              />
            </TableCell>
          ))}
          {isDeleteEnabled && (
            <TableCell style={{ width: '1%' }}>
              <Button label="Delete" onClick={() => deleteLabel(key)} />
            </TableCell>
          )}
          {isDuplicateEnabled && (
            <TableCell style={{ width: '1%' }}>
              <Button
                label={
                  duplicatedLabels.includes(key)
                    ? 'Undo'
                    : `Duplicate to ${platformCode === 'bo' ? 'SF' : 'BO'}`
                }
                onClick={() => duplicateLabel(key)}
              />
            </TableCell>
          )}
        </TableRow>
      ))}
    </TableBody>
  )
}

export default I18nLabelsForm
