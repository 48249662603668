import React from 'react'
import { Drink } from 'services/api/entities/catalog/drink/types/internal/drink-models'
import { DrinkSelection } from 'services/api/entities/events/types/internal/selections'
import { WithIdAndName } from '@kampaay/common'
import EventBaseSelectionArrayInput from 'components/FormComponents/EventBaseSelectionArrayInput'
import { SelectionDetailsProps } from 'components/FormComponents/MainProductSelectionInputContent'

const DrinkVariantsSelectionInput: React.FC<
  SelectionDetailsProps<Drink, DrinkSelection>
> = ({ selectedProduct, isObsolete, getSource, scopedFormData }) => (
  <EventBaseSelectionArrayInput
    source={getSource!('variants')}
    label="form.fields.variants"
    arrayInputLabel="form.fields.variants"
    choices={
      isObsolete
        ? (scopedFormData?.variants as WithIdAndName[])
        : selectedProduct?.variants
    }
    disableInputs={isObsolete}
  />
)

export default DrinkVariantsSelectionInput
