import { uploadOrKeepFile } from 'services/utils/files'
import {
  locationArrangementsParse,
  locationArrangementsWrite
} from 'services/api/entities/catalog/location/arrangement'
import {
  byRulesPricingParse,
  byRulesPricingWrite
} from 'services/api/entities/catalog/location/locationPricing'
import { LocationToApi } from 'services/api/entities/catalog/location/types/api/request-models'
import { APILocation } from 'services/api/entities/catalog/location/types/api/response-models'
import { Location } from 'services/api/entities/catalog/location/types/internal/location-models'
import {
  bundleFacetIds,
  findMultipleFacets,
  findSingleFacet,
  getId,
  parseFacet,
  parseFileInfo,
  parseGeocodedAddress,
  productWithUpsellingParse,
  productWithUpsellingWrite,
  writeGeocodedAddress
} from '@kampaay/common'
import { APIFacet } from '@kampaay/common/src/api/resources/catalog/facets/types/response-models'
import { uploadOrKeepMultipleImgs } from 'services/utils/images'

// ============
// UTILS
// ============

const LOCATION_FACET_REGEX = /^productFilter:Location:(.*)/

const getLocationZone = (facets: APIFacet[]): string | undefined =>
  facets.map((x) => x.code.match(LOCATION_FACET_REGEX)).find((x) => !!x)?.[1]

// ============
// PARSE
// ============

const locationParse = ({
  features,
  planimetry,
  ...res
}: APILocation): Location => {
  const equipments = (features ?? [])
    .filter((f) => f.category === 'equipment')
    .map(getId)
  const characteristics = (features ?? [])
    .filter((f) => f.category === 'characteristic')
    .map(getId)

  const facets = res.facets.map(parseFacet)
  const filterLocationFacet = findSingleFacet(
    facets,
    'productFilter',
    'Location'
  )

  const tagFacets = findMultipleFacets(facets, 'tag')
  const eventTypeFacet = findMultipleFacets(
    facets,
    'productFilter',
    'EventType'
  )

  return {
    ...res,
    ...productWithUpsellingParse(res),
    planimetry: planimetry[0] && parseFileInfo(planimetry[0]),
    locationZone: getLocationZone(res.facets),
    equipments,
    characteristics,
    filterLocationFacet,
    tagFacets,
    arrangements: locationArrangementsParse(res.arrangements),
    eventTypeFacet,
    pricing: res.pricing.map(byRulesPricingParse),
    geoCodedAddress: parseGeocodedAddress(res.geoCodedAddress)
  }
}

// ============
// WRITE
// ============

const locationWrite = async (req: Location): Promise<LocationToApi> => {
  const {
    planimetry,
    equipments = [],
    characteristics = [],
    filterLocationFacet,
    tagFacets = [],
    arrangements,
    eventTypeFacet = [],
    defaultHoursBefore,
    defaultHoursAfter,
    availableFrom,
    availableTo,
    ...rest
  } = req

  const transformedArrangements = locationArrangementsWrite(arrangements)

  const facets = bundleFacetIds(
    filterLocationFacet,
    ...tagFacets,
    ...eventTypeFacet
  )

  const planimetryUpl = await uploadOrKeepFile(planimetry)
  const features = [...equipments, ...characteristics]

  return {
    ...(await productWithUpsellingWrite(uploadOrKeepMultipleImgs)(rest)),
    planimetry: planimetryUpl ? [planimetryUpl] : [],
    features,
    facets,
    defaultHoursBefore,
    defaultHoursAfter,
    availableFrom,
    availableTo,
    arrangements: transformedArrangements,
    pricing: req.pricing.map(byRulesPricingWrite),
    geoCodedAddress: writeGeocodedAddress(req.geoCodedAddress)!
  }
}

// ============
// CFG
// ============

const apiCfg = {
  endpoint: 'location',
  parse: locationParse,
  write: locationWrite
}

export default apiCfg
