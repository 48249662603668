import React from 'react'
import { BooleanField, Datagrid, TextField } from 'react-admin'
import SectionWrapper from 'components/UI/SectionWrapper'
import DeleteAndExportBulk from 'components/Buttons/DeleteAndExportBulk'
import KPagination from 'components/Lists/KPagination'
import { BaseListProps } from 'components/Lists/CatalogItemBaseList'
import KList from 'components/Lists/KLists/KList'
import { FILTER_SOURCES } from '@kampaay/common'
import KampaayerReferenceField from 'resources/crm/kampaayers/KampaayerReferenceField'

const OrganizationList: React.FC<BaseListProps> = (props) => {
  return (
    <SectionWrapper>
      <KList
        {...props}
        exporter={false}
        pagination={<KPagination />}
        perPage={40}
        filterDefaultValues={{ [FILTER_SOURCES.isTrial.eq]: 'false' }}
      >
        <Datagrid rowClick="edit" bulkActionButtons={<DeleteAndExportBulk />}>
          <TextField
            source="name"
            label="form.fields.organizationName"
            sortable={false}
          />
          <TextField
            source="responsibleFullName"
            label="form.fields.responsibleFullName"
            sortable={false}
          />
          <KampaayerReferenceField
            source="salesOwnerId"
            label="form.fields.salesOwner"
          />
          <BooleanField
            source="isTrial"
            label="form.fields.isTrial"
            sortable={false}
          />
          <BooleanField
            source="approvalEnabled"
            label="form.fields.approvalFlow"
            sortable={false}
          />
        </Datagrid>
      </KList>
    </SectionWrapper>
  )
}

export default OrganizationList
