import { entries } from 'services/utils'
import {
  APIEntity,
  ListedProductEntity,
  SectionEntity
} from 'services/api/entities'
import { FullProductServiceName, ServiceCollectionName } from 'types/common'
import { LinkedProductType, MainProductName } from '@kampaay/common'

export const DISCRIMINATOR_COLLECTION_NAMES_MAP = {
  VirtualConfigurator: 'virtuals',
  FoodConfigurator: 'foods',
  RentConfigurator: 'rents',
  MusicConfigurator: 'musics',
  DrinkConfigurator: 'drinks',
  PhysicalConfigurator: 'physicals',
  LocationConfigurator: 'locations',
  ProfessionalConfigurator: 'professionals'
} as const

export const getCollectionNameFromDiscriminator = <
  T extends keyof typeof DISCRIMINATOR_COLLECTION_NAMES_MAP
>(
  discriminator: T
) => DISCRIMINATOR_COLLECTION_NAMES_MAP[discriminator]

// Record<MainProductName, ServiceCollectionName>
export const PRODUCT_NAME_COLLECTION_NAMES_MAP = {
  location: 'locations',
  drink: 'drinks',
  food: 'foods',
  music: 'musics',
  virtual: 'virtuals',
  professional: 'professionals',
  rent: 'rents',
  physical: 'physicals'
} as const

export const getCollectionNameFromProductName = <
  T extends keyof typeof PRODUCT_NAME_COLLECTION_NAMES_MAP
>(
  productName: T
) => PRODUCT_NAME_COLLECTION_NAMES_MAP[productName]

export const ENTITY_SERVICE_MAP: Record<LinkedProductType, APIEntity> = {
  ...PRODUCT_NAME_COLLECTION_NAMES_MAP,
  foodUpselling: 'foodsupselling',
  locationUpselling: 'locationupselling',
  logistic: 'logistics',
  physicalUpselling: 'physicalupselling',
  virtualUpselling: 'virtualupselling'
}

export const PRODUCT_SERVICE_ENTITY_MAP: Partial<
  Record<APIEntity, LinkedProductType>
> = {
  physicals: 'physical',
  virtuals: 'virtual'
}

//Record<ServiceCollectionName, FullProductServiceName>
export const COLLECTION_NAME_SERVICE_MAP = {
  foods: 'foodService',
  locations: 'locationService',
  physicals: 'physicalService',
  virtuals: 'virtualService',
  professionals: 'professionalService',
  drinks: 'drinkService',
  musics: 'musicService',
  rents: 'rentService'
} as const

export const getServiceNameFromCollectionName = <
  T extends keyof typeof COLLECTION_NAME_SERVICE_MAP
>(
  collectionName: T
) => COLLECTION_NAME_SERVICE_MAP[collectionName]

/**
 * Map that indicates to which products belongs a section
 */
export const SECTION_PRODUCT_ENTITY_MAP: Record<
  SectionEntity,
  ListedProductEntity
> = {
  drinksections: 'drinks',
  foodsections: 'foods',
  locationsections: 'locations',
  musicsections: 'musics',
  physicalsections: 'physicals',
  professionalsections: 'professionals',
  rentsections: 'rents',
  virtualexperiencessections: 'virtuals'
}

/**
 *  util to get name of api entity passing a services name
 * @param servName services name
 * @returns name api entity
 */
export const getCollectionNameFromServiceName = (
  servName: FullProductServiceName
): ServiceCollectionName =>
  entries(COLLECTION_NAME_SERVICE_MAP).find(([, v]) => v === servName)![0]

export const getDiscriminatorFromProductName = <T extends MainProductName>(
  n: T
) => {
  const collectionName = getCollectionNameFromProductName(n)

  return entries(DISCRIMINATOR_COLLECTION_NAMES_MAP).find(
    ([, v]) => v === collectionName
  )![0]
}
