import { APICfg } from 'services/api/api'
import { parseProductTailorings } from 'services/api/entities/crm/organizations/emsTailoring/common/parsers'
import { writeProductTailorings } from 'services/api/entities/crm/organizations/emsTailoring/common/writers'

const apiCfg: APICfg = {
  endpoint: '',
  getOneEndpoint: 'organization/:id/tailoring/musics',
  parse: parseProductTailorings,
  write: writeProductTailorings
}

export default apiCfg
