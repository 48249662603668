import React from 'react'
import SingleArrangementInput from 'resources/catalog/location/product/SingleArrangementInput'
import { useForm } from 'hooks/useForm'
import { RAFormFC } from 'types/common'
import { LocationArrangementMap } from 'services/api/entities/catalog/location/types/internal/location-models'
import { LOCATION_ARRANGEMENTS_STARTUP } from 'services/api/entities/catalog/location/arrangement'
import { entries } from 'services/utils'

const LocationArrangementsInput: RAFormFC = ({ source }) => {
  const { getSource } = useForm<LocationArrangementMap>(source)
  return (
    <>
      {entries(LOCATION_ARRANGEMENTS_STARTUP).map(([key, value]) => (
        <SingleArrangementInput
          defaultValue={value}
          source={getSource(key)}
          name={key}
          key={key}
        />
      ))}
    </>
  )
}

export default LocationArrangementsInput
