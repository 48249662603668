import React from 'react'
import MainProductFormContent, {
  MainProductFormContentProps
} from 'components/Forms/MainProductFormContent'
import KToolTip from 'components/UI/KTooltip'
import KNumberInput from 'components/Inputs/KInputs/KNumberInput/KNumberInput'
import VariantsFormPartial from 'resources/catalog/drink/product/VariantsFormPartial'
import { KBooleanInput } from 'components/Inputs/KInputs/KBooleanInput'
import SuppliersAutocompleteArrayInput from 'components/Inputs/SuppliersAutocompleteArrayInput'

type DrinkFormProps = MainProductFormContentProps

const DrinkFormContent: React.FC<DrinkFormProps> = (props) => (
  <MainProductFormContent {...props}>
    <KNumberInput label="form.fields.minQuantity" source="minQuantity" />
    <KToolTip title="form.fields.drinkPerUnitTooltip">
      <KNumberInput
        label="form.fields.drinksPerUnit"
        source="drinksPerUnit"
        defaultValue={1}
      />
    </KToolTip>
    <KBooleanInput
      source="hasMultipleVariants"
      label="form.fields.hasMultipleVariants"
    />
    <SuppliersAutocompleteArrayInput />
    <VariantsFormPartial />
  </MainProductFormContent>
)

export default DrinkFormContent
