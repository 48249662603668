import React from 'react'
import { locationFeatureCategoryChoices } from 'services/cfg/form'
import CatalogItemBaseFormContent from 'components/Forms/CatalogItemBaseFormContent'
import KToolTip from 'components/UI/KTooltip'
import KSelectInput from 'components/Inputs/KInputs/KSelectInput'
import KImageInput from 'components/Inputs/KImageInput'
import { useForm } from 'hooks/useForm'
import { LocationFeature } from 'services/api/entities/catalog/locationFeatures/types/internal/locationFeatures-models'
import { RAFormFC } from 'types/common'

const LocationFeatureFormContent: RAFormFC = ({ source }) => {
  const { getSource } = useForm<LocationFeature>(source)

  return (
    <CatalogItemBaseFormContent>
      <KToolTip title="tooltip.hint.locationFeatureImage">
        <KImageInput accept=".png" />
      </KToolTip>
      <KSelectInput
        source={getSource('category')}
        choices={locationFeatureCategoryChoices}
      />
    </CatalogItemBaseFormContent>
  )
}

export default LocationFeatureFormContent
