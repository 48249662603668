import { APICfg } from 'services/api/api'
import { APIBaseProduct } from 'services/api/entities/catalog/baseProduct/types/api/response-models'
import {
  ProductSection,
  ProductSectionToAPI
} from 'services/api/entities/catalog/types'
import { productSectionParse } from 'services/api/entities/catalog/utils'

type RentSection = ProductSection<APIBaseProduct>

type RentSectionToApi = ProductSectionToAPI

const apiCfg: APICfg = {
  endpoint: 'rentsections',
  parse: productSectionParse,
  write: async (req: RentSection): Promise<RentSectionToApi> => req
}
export default apiCfg
