import { useKRecordContext } from 'hooks/useKRecordContext'
import React from 'react'
import { ChipField } from 'react-admin'
import { EventItem } from 'services/api/entities/events/types/internal/event-models'
import { RAFormFC } from 'types/common'

type EventStatusChipFieldProps = {
  label: string
}
const EventStatusChipField: RAFormFC<EventStatusChipFieldProps> = ({
  label,
  source
}) => {
  const record = useKRecordContext<EventItem>()
  const sourceValue =
    record && source ? (record as unknown as string)[source as any] : ''

  const color =
    sourceValue === 'missing' || sourceValue === 'unassigned'
      ? 'error'
      : sourceValue === 'accepted' || sourceValue === 'confirmed'
      ? 'success'
      : 'default'
  return <ChipField source={source} label={label} color={color} />
}

export default EventStatusChipField
