import React from 'react'
import FileInputAccordion from 'components/Inputs/FileInputAccordion'
import { kampaayerRolesChoices } from 'services/cfg/form'
import KSelectInput from 'components/Inputs/KInputs/KSelectInput'
import KTextInput from 'components/Inputs/KInputs/KTextInput/KTextInput'
import { activityStatusChoices, SUPPORTED_COUNTRIES } from '@kampaay/common'
import KDateInput from 'components/Inputs/KInputs/KDateInput'

const KampaayerFormContent: React.FC = () => {
  return (
    <>
      <KSelectInput
        source="role"
        label="form.fields.role"
        choices={kampaayerRolesChoices}
        defaultValue="admin"
      />

      <KTextInput source="firstName" label="form.fields.firstName" />
      <KTextInput source="lastName" label="form.fields.lastName" />
      <KTextInput source="phone" label="form.fields.phone" />
      <KTextInput source="email" label="form.fields.email" />
      <KSelectInput
        source="status"
        choices={activityStatusChoices}
        defaultValue={activityStatusChoices[0].id} // 'active'
      />
      <KDateInput source="birthDate" label="form.fields.birthDate" />
      <KTextInput source="birthPlace" label="form.fields.birthPlace" />
      <KTextInput
        source="residenceAddress"
        label="form.fields.residenceAddress"
      />
      <KTextInput
        source="domicileAddress"
        label="form.fields.domicileAddress"
      />
      <KTextInput source="personalNumber" label="form.fields.personalNumber" />
      {/* This will become a SelectArray input when we support multiple countries for a kampaayer  */}
      <KSelectInput
        source="managedCountries"
        choices={SUPPORTED_COUNTRIES}
        defaultValue={SUPPORTED_COUNTRIES[0].id}
      />

      <FileInputAccordion source="kContract" />
    </>
  )
}

export default KampaayerFormContent
