import React from 'react'
import { TextField } from 'react-admin'
import EditableList from 'components/Lists/EditableList/EditableList'
import KTextInput from 'components/Inputs/KInputs/KTextInput/KTextInput'

const PocList = () => (
  <EditableList formContent={<EditComponent />}>
    <>
      <TextField source="id" />
      <TextField source="title" />
    </>
  </EditableList>
)

const EditComponent = () => {
  return <KTextInput source="title" />
}

export default PocList
