import React from 'react'
import { TextField } from 'react-admin'
import MainProductList, {
  MainProductListProps
} from 'components/Lists/MainProductList'

const LocationList: React.FC<MainProductListProps> = (props) => {
  return (
    <MainProductList {...props}>
      <TextField
        label="form.fields.address"
        source="geoCodedAddress.geoPoint.formattedAddress"
      />
      <TextField source="supplierName" label="Location Supplier" />
      <TextField source="peopleCapacity" />
      <TextField source="locationZone" />
      <TextField source="status" label="Location Status" />
    </MainProductList>
  )
}

export default LocationList
