import { yupArray, UseValidationSchema } from 'services/yup'
import { mainProductVS } from 'services/validations/catalog/mainProduct'
import {
  ProductWithUpsellingEntity,
  UpsellingEntity
} from 'services/api/entities'
import { useKResourceContext } from 'hooks/useKResourceContext'
import { useKGetList } from 'hooks/useKGetList'
import { buildGeoareaPresetValidation } from 'services/validations/common'
import { ProductWithUpselling } from '@kampaay/common'

const VS_PRODUCT_UPSELLINGS_MAP: Record<
  ProductWithUpsellingEntity,
  UpsellingEntity
> = {
  foods: 'foodsupselling',
  locations: 'locationupselling',
  physicals: 'physicalupselling',
  virtuals: 'virtualupselling'
}

export const useProductWithUpsellingVS: UseValidationSchema<
  ProductWithUpselling
> = () => {
  const resource = useKResourceContext<ProductWithUpsellingEntity>()
  const { list } = useKGetList<ProductWithUpselling>(
    VS_PRODUCT_UPSELLINGS_MAP[resource]
  )

  const geoAreaPresetsTest = buildGeoareaPresetValidation<ProductWithUpselling>(
    {
      key: 'upsellingItems',
      list
    }
  )

  return mainProductVS.shape({
    upsellingItems: yupArray(),
    upsellingItemsFull: yupArray(),
    geoAreaPresets: yupArray().min(0).test(geoAreaPresetsTest)
  })
}
