import { buildFilterConfig } from 'services/utils/filters'
import { FILTER_SOURCES } from '@kampaay/common'

export const ORG_FILTERS = [
  buildFilterConfig('select', {
    source: FILTER_SOURCES.isTrial.eq,
    alwaysOn: true,
    label: 'form.fields.isTrial',
    emptyText: 'form.fields.all',
    choices: [
      { id: 'true', name: 'form.fields.onlyTrialOrgs' },
      { id: 'false', name: 'form.fields.onlyCustomerOrgs' }
    ]
  }),
  buildFilterConfig('nullableBoolean', {
    source: FILTER_SOURCES.hasApprovalEnabled.eq,
    alwaysOn: true,
    label: 'form.fields.approvalFlow',
    emptyText: 'form.fields.all'
  }),
  buildFilterConfig('text', {
    source: FILTER_SOURCES.name.contains,
    alwaysOn: true,
    label: 'form.fields.name'
  }),
  buildFilterConfig('salesOwnerSelect', {
    source: FILTER_SOURCES.salesOwnerId.in,
    alwaysOn: true
  }),
  buildFilterConfig('nullableBoolean', {
    source: FILTER_SOURCES.hasEvents.eq,
    alwaysOn: false,
    label: 'filters.label.hasEvents'
  })
]
