import React, { PropsWithChildren } from 'react'
import LastEditDetailsField from 'components/FormComponents/LastEditDetailsFields'
import LocalizedTextInput from 'components/Inputs/Localized/LocalizedTextInput'

export type CatalogItemBaseFormContentProps = PropsWithChildren & {
  facetFields?: React.ReactElement
  slotBeforeFacets?: React.ReactElement
}

const CatalogItemBaseFormContent: React.FC<CatalogItemBaseFormContentProps> = ({
  facetFields,
  slotBeforeFacets,
  children
}) => {
  return (
    <>
      <LastEditDetailsField />
      <LocalizedTextInput source="name" label="form.fields.name" fullWidth />
      {slotBeforeFacets}
      {facetFields}
      {children}
    </>
  )
}

export default CatalogItemBaseFormContent
