import React from 'react'
import { rgbToHex } from '@mui/material/styles'
import { Chip, ChipProps } from '@mui/material'
import { SupportedColor, kampaayTheme } from 'layout/config/themes'

export const CUSTOM_CHIP_COLORS_MAP: Record<SupportedColor, string> = {
  error: rgbToHex('rgba(255, 0, 0, 0.15)'),
  ...kampaayTheme.palette.primary,
  ...kampaayTheme.palette.secondary
}

export type KChipProps = Omit<ChipProps, 'color'> & {
  customBackgroundColor?: SupportedColor
  textColor?: SupportedColor
}

const KChip: React.FC<KChipProps> = ({
  customBackgroundColor,
  textColor,
  ...rest
}) => {
  const styles = {
    backgroundColor: customBackgroundColor
      ? CUSTOM_CHIP_COLORS_MAP[customBackgroundColor]
      : undefined,
    color: textColor ? CUSTOM_CHIP_COLORS_MAP[textColor] : undefined
  }

  return <Chip style={styles} {...rest} />
}

export default KChip
