import React from 'react'
import { useWatch } from 'react-hook-form'
import { allowedServicesChoices } from 'services/cfg/form'
import LocalizedSelectArrayInput from 'components/Inputs/Localized/LocalizedSelectArrayInput'
import {
  EncodedProfessionalDiscriminator,
  ServicesConfig
} from 'services/api/entities/services-config/types/internal/services-config-models'

type Props = { source: string; label: string }

export const encodeProfessionalDiscriminator = (
  sectionId: number
): EncodedProfessionalDiscriminator => `ProfessionalConfigurator-${sectionId}`

const BriefServiceInput: React.FC<Props> = ({ source, label }) => {
  const professionalServices = useWatch<
    ServicesConfig,
    'professionalService.services'
  >({ name: 'professionalService.services' })

  const professionalSectionChoices = professionalServices.map(
    ({ section: { id, name } }) => ({
      id: encodeProfessionalDiscriminator(id),
      name
    })
  )

  const serviceChoices = [
    ...allowedServicesChoices,
    ...professionalSectionChoices
  ]

  return (
    <LocalizedSelectArrayInput
      source={source}
      label={label}
      choices={serviceChoices}
    />
  )
}

export default BriefServiceInput
