import groupBy from 'lodash/groupBy'
import sumBy from 'lodash/sumBy'
import type { Proposal } from '../../../api'
import type { ProposalTotalPricing } from '.'
import {
  type ProposalItemPricingProps,
  getProposalItemPricing
} from '../proposal-item'
import { roundTwoDecimals } from '../../prices'

export type ProposalCostPerSupplier = {
  supplierId: number | 'unknown'
  costAndCommission: Pick<ProposalTotalPricing, 'totalCost' | 'totalCommission'>
}

/**
 * Given a proposal, this function returns an array of
 * objects each containing the supplierId and the
 * total pricings calculated for that supplier.
 * @param proposal
 *
 */
export const getProposalCostPerSupplier = (
  proposal: Proposal
): ProposalCostPerSupplier[] => {
  const itemGroups = proposal?.itemGroups ?? []

  return (
    Object.entries(
      // Flatten the items out from the item groups and filter out the ones that
      // are not included in the price, then groups them by supplier
      // If the supplier is not defined, the item will be grouped under 'unknown'
      groupBy(
        itemGroups
          .flatMap((group) => group.items)
          .filter((i) => i.isPriceIncluded),
        (i) => i.supplier ?? 'unknown'
      )
    )
      // For each grouped item, calculate the pricing
      .map(
        ([supplierId, items]) =>
          [
            supplierId === 'unknown' ? supplierId : Number(supplierId),
            items.map(getProposalItemPricing)
          ] satisfies [number | 'unknown', ProposalItemPricingProps[]]
      )
      // Extract the total cost and commission for each supplier and reshape the data
      .map(([supplierId, pricingRecord]) => ({
        supplierId,
        costAndCommission: {
          totalCost: roundTwoDecimals(
            sumBy(pricingRecord, (p) => p.totalCost ?? 0)
          ),
          totalCommission: roundTwoDecimals(
            sumBy(pricingRecord, (p) => p.commissionAmount ?? 0)
          )
        }
      }))
  )
}
