import { createMockBuilder } from '../../../../utils'
import type { Supplier } from '../types'

const buildBaseMock = (): Supplier => ({
  phone: '',
  phoneVerified: false,
  email: '',
  additionalEmails: [],
  emailVerified: false,
  organizationNumber: '',
  status: 'active',
  name: '',
  country: 'IT',
  headQuarters: '',
  responsibleFullName: '',
  id: 1,
  suppliedServicesProducts: [],
  hasAccount: true,
  documents: [],
  images: [],
  description: '',
  internalDescription: '',
  websiteUrl: '',
  services: ['venue'],
  businessName: 'Joe Vannee Kotzow',
  proposalItems: [],
  privacyPolicyAccepted: false,
  usageOfImagesAndTextsAccepted: false,
  agreementInForce: 'none',
  rebateAndCommission: 0,
  tags: [],
  documentItems: []
})

export const buildSupplierMock = createMockBuilder(buildBaseMock)
