import React, { Children, useCallback, useMemo, useRef } from 'react'
import {
  AddItemButton as DefaultAddItemButton,
  RemoveItemButton as DefaultRemoveItemButton,
  ReOrderButtons as DefaultReOrderButtons,
  SimpleFormIteratorClasses,
  SimpleFormIteratorContext,
  SimpleFormIteratorItem,
  SimpleFormIteratorPrefix,
  SimpleFormIteratorProps,
  useArrayInput
} from 'react-admin'
import clsx from 'clsx'
import { styled } from '@mui/material'
import { useKResourceContext } from 'hooks/useKResourceContext'
import get from 'lodash/get'
import { useKRecordContext } from 'hooks/useKRecordContext'
import { useWatch } from 'react-hook-form'

type KSimpleFormIteratorProps<TValue extends object> =
  SimpleFormIteratorProps & {
    showFn: (obj: TValue) => boolean
  }

/**
 * This is a copy of the SimpleFormIterator from react-admin, with the addition of a showFn prop.
 * @param props
 */
const KSimpleFormIterator = <TValue extends object>(
  props: KSimpleFormIteratorProps<TValue>
) => {
  const {
    source,
    children,
    inline,
    disabled,
    disableReordering,
    disableRemove,
    disableAdd,
    fullWidth,
    showFn
  } = props
  const resource = useKResourceContext()
  const record = useKRecordContext(props)
  const records = get(record, source!)

  // the controls of the array input
  const { append, fields, move, remove } = useArrayInput(props)

  // current records
  const inputValue = useWatch({ name: source! })

  // the default value of each element
  const initialDefaultValue = useRef<Record<string, any>>({})

  // this block of code is the one that takes the previous record if there is one
  // and as default initializes each element of the previous record to an empty string
  // as default, this is done to calculate the default value if the inputs are not direct children
  // of the iterator
  if (fields.length > 0) {
    const { id: _id, ...rest } = fields[0]
    initialDefaultValue.current = rest
    for (const k in initialDefaultValue.current)
      initialDefaultValue.current[k] = ''
  }

  // This handles the add of a new field to the list
  // Note that the whole complexity of this fn derives from
  // the calculation of the default value for the new field
  const addField = useCallback(
    (item: any = undefined) => {
      let defaultValue = item
      if (item == null) {
        defaultValue = initialDefaultValue.current
        if (
          Children.count(children) === 1 &&
          React.isValidElement(Children.only(children)) &&
          // @ts-ignore
          !Children.only(children).props.source
        ) {
          defaultValue = ''
        } else {
          defaultValue = defaultValue || ({} as Record<string, unknown>)
          Children.forEach(children, (input) => {
            if (React.isValidElement(input) && input.props.source) {
              defaultValue[input.props.source] = input.props.defaultValue ?? ''
            }
          })
        }
      }
      append(defaultValue)
    },
    [append, children]
  )

  const context = useMemo(
    () => ({
      total: fields.length,
      add: addField,
      remove,
      reOrder: move,
      source: source!
    }),
    [addField, fields.length, source]
  )

  return (
    <SimpleFormIteratorContext.Provider value={context}>
      <Root className={clsx(fullWidth && 'fullwidth', disabled && 'disabled')}>
        {/* LIST OF ELEMENTS */}
        <ul className={SimpleFormIteratorClasses.list}>
          {fields.map((member, index) =>
            !inputValue[index] || showFn(inputValue[index]) ? (
              <SimpleFormIteratorItem
                key={member.id}
                disabled={disabled}
                disableRemove={disableRemove}
                disableReordering={disableReordering}
                fields={fields}
                index={index}
                member={`${source}.${index}`}
                onRemoveField={remove}
                onReorder={move}
                record={(records && records[index]) || {}}
                removeButton={<DefaultRemoveItemButton />}
                reOrderButtons={<DefaultReOrderButtons />}
                resource={resource}
                source={source!}
                inline={inline}
              >
                {children}
              </SimpleFormIteratorItem>
            ) : (
              <></>
            )
          )}
        </ul>

        {/* ADD BUTTON */}
        {!disabled && !disableAdd && (
          <div className={SimpleFormIteratorClasses.add}>
            <DefaultAddItemButton
              className={clsx('button-add', `button-add-${source}`)}
              onClick={() => addField()}
            />
          </div>
        )}
      </Root>
    </SimpleFormIteratorContext.Provider>
  )
}

export default KSimpleFormIterator

const Root = styled('div', {
  name: SimpleFormIteratorPrefix,
  overridesResolver: (props, styles) => styles.root
})(({ theme }) => ({
  '& > ul': {
    padding: 0,
    marginTop: 0,
    marginBottom: 0
  },
  '& > ul > li:last-child': {
    // hide the last separator
    borderBottom: 'none'
  },
  [`& .${SimpleFormIteratorClasses.line}`]: {
    display: 'flex',
    listStyleType: 'none',
    borderBottom: `solid 1px ${theme.palette.divider}`,
    [theme.breakpoints.down('sm')]: { display: 'block' }
  },
  [`& .${SimpleFormIteratorClasses.index}`]: {
    display: 'flex',
    alignItems: 'top',
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('md')]: { display: 'none' }
  },
  [`& .${SimpleFormIteratorClasses.form}`]: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column'
  },
  [`&.fullwidth > ul > li > .${SimpleFormIteratorClasses.form}`]: {
    flex: 2
  },
  [`& .${SimpleFormIteratorClasses.inline}`]: {
    flexDirection: 'row',
    columnGap: '1em',
    flexWrap: 'wrap'
  },
  [`& .${SimpleFormIteratorClasses.action}`]: {
    marginTop: theme.spacing(0.5),
    visibility: 'hidden',
    '@media(hover:none)': {
      visibility: 'visible'
    }
  },
  [`& .${SimpleFormIteratorClasses.add}`]: {
    borderBottom: 'none'
  },
  [`& .${SimpleFormIteratorClasses.line}:hover > .${SimpleFormIteratorClasses.action}`]:
    {
      visibility: 'visible'
    }
}))
