import React from 'react'
import { translate } from 'services/i18n'
import { stripHTMLTags } from '@kampaay/common'

type TranslatedParagraphProps = {
  i18nKey: string
  value: string | number | undefined
  keyClass?: string
  keyStyle?: Object
  valueClass?: string
  valueStyle?: Object
  'data-testid'?: string
}

const stringifyValue = (value: string | number | undefined) => {
  if (typeof value === 'string') {
    return value
  }
  if (typeof value === 'number') {
    return value.toString()
  }
  return ''
}

const TranslatedParagraph: React.FC<TranslatedParagraphProps> = ({
  i18nKey,
  value,
  keyStyle,
  valueStyle,
  keyClass,
  valueClass,
  'data-testid': dataTestId
}) => (
  <p data-testid={dataTestId}>
    <span
      data-testid="translated-paragraph--key"
      className={keyClass ?? ''}
      style={keyStyle}
    >
      {translate(i18nKey)}:{' '}
    </span>
    <span
      data-testid="translated-paragraph--value"
      className={valueClass ?? ''}
      style={valueStyle}
    >
      {stripHTMLTags(stringifyValue(value))}
    </span>
  </p>
)

export default TranslatedParagraph
