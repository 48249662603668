import { GetListParams } from 'react-admin'
import CFG from 'services/cfg'
import { Memoizer } from 'types/common'
import { ActivityStatus, Customer } from '@kampaay/common'

export * from './common/index'
export * from './object'
export * from './common/arrays'
export * from './sorting'
export * from './facets'

/**
 * If no params are passed, it returns the default GetListParams object
 * If some params are passed, it returns a complete GetListParams object by spreading
 * correctly the passed params over the default GetListParams object
 *
 * @param params partial GetListParams object
 * @returns A complete GetListParams object
 */
export const initializeListParams = (
  params: Partial<GetListParams> = {}
): GetListParams => ({
  filter: params.filter ?? '',
  pagination: params.pagination ?? {
    page: 1,
    perPage: CFG.perPageDefault
  },
  sort: params.sort ?? {
    field: '',
    order: ''
  },
  meta: params.meta ?? {}
})

export const convertEmptyStringToUndefined = <T extends unknown>(
  p: T | undefined | ''
): T | undefined => (p === '' ? undefined : p)

export const getName = <T extends { name: string }>(item: T) => item.name

export const getAggregateValue = <T>(
  collection: T[],
  getAddend: (arg: T) => number
) => collection.map(getAddend).reduce((acc, item) => acc + item || 0, 0)

export const nonMemoizer: Memoizer = (fn, _deps) => fn()

export const hasSections = (i: { sections: unknown[] }) => !!i.sections.length

export const isActive = <T extends { status: ActivityStatus }>(e: T) =>
  e.status === 'active'

/**
 * Compare 2 Sets and return true if they're equal
 */
export const areSetsEqual = <T>(a: Set<T>, b: Set<T>) =>
  a.size === b.size && Array.from(a).every((value) => b.has(value))

/**
 * Checks the uniqueness of the specified field inside the elements of an array
 * @param objects the objects to check
 * @param key the key to check
 */
export const isEveryNameOfKeyUnique = <
  TObject extends object,
  SKey extends keyof TObject
>(
  objects: TObject[] | undefined,
  key: SKey
) => {
  const unique = new Set(objects?.map((e) => e[key]))
  return unique.size === objects?.length
}

export const getEmailName = (customer: Customer) =>
  `${customer.email} - ${customer.name}`

export const noop = (): any => {}
