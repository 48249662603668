import { UpdateParams, UpdateResult } from 'ra-core'
import { APICfg } from 'services/api/api'
import { callUpdate } from 'services/api/dataProvider/common'
import invalidateDependentQueries from 'services/api/dataProvider/dependencies'

export type ConciergeStatusAction = 'close' | 'setLost'

type SetConciergeStatusParams = {
  conciergeId: number
  action: ConciergeStatusAction
}

const setConciergeStatus = async (
  _resource: string,
  { data }: UpdateParams<SetConciergeStatusParams>
): Promise<UpdateResult<any>> => {
  const endPoint = `concierge/${data.conciergeId}/${data.action}`
  await callUpdate(apiCfg, endPoint, 'PATCH', {})

  await invalidateDependentQueries('setConciergeStatus')
  return {
    data: {
      id: ''
    }
  }
}

/**
 * This entity will not have a related resource, it is needed to handle the state of a concierge event.
 * It will only require custom handling, so the `endpoint`, `parse` and  `write`
 */
const apiCfg: APICfg = {
  endpoint: '',
  parse: (res: unknown) => res,
  write: async (req: unknown): Promise<unknown> => req,
  update: setConciergeStatus
}

export default apiCfg
