import { TextField } from '@mui/material'
import { KInputProps } from 'hooks/useKInput/useKInput'
import React from 'react'
import { NumberInputProps } from 'react-admin'
import { useKNumberInput } from 'components/Inputs/KInputs/KNumberInput/useKNumberInput'

export type KNumberInputProps = NumberInputProps &
  KInputProps & { step?: number }

export type NumberInputChangeEvent = React.ChangeEventHandler<
  HTMLTextAreaElement | HTMLInputElement
>

const KNumberInput: React.FC<KNumberInputProps> = ({
  disabled,
  className,
  style,
  fullWidth,
  placeholder,
  'data-testid': dataTestId,
  ...numberInputProps
}) => {
  const {
    field: { onChange, value },
    isRequired,
    k: { hasError, label, helperText, sx, variant }
  } = useKNumberInput(numberInputProps)

  return (
    <TextField
      type="number"
      value={value}
      className={className}
      style={{ ...style, width: fullWidth ? '100%' : undefined }}
      error={hasError}
      helperText={helperText}
      sx={sx}
      variant={variant}
      required={isRequired}
      label={label}
      disabled={disabled}
      onChange={onChange}
      placeholder={placeholder}
      inputProps={{
        ...numberInputProps.inputProps,
        'data-testid': dataTestId
      }}
    />
  )
}

export default KNumberInput
