import { uploadImg } from 'services/api/dataProvider/common'
import { Image } from '@kampaay/common'

export const uploadOrKeepMultipleImgs = (imgArray: Image[] = []) => {
  return Promise.all(
    imgArray.map((img) => ('rawFile' in img ? uploadImg(img.rawFile) : img.id))
  )
}

export const uploadOrKeepImg = (img: Image | undefined) => {
  return !img || !('rawFile' in img)
    ? Promise.resolve(img?.id)
    : uploadImg(img.rawFile).then((id: string) => id)
}
