import React from 'react'
import SectionWrapper from 'components/UI/SectionWrapper'
import { navigate } from 'services/utils/navigationUtils'

const Dashboard: React.FC = () => {
  navigate('', 'vue-app')

  return <SectionWrapper title="common.label.dashboard"></SectionWrapper>
}

export default Dashboard
