import React from 'react'
import { RAFormFC } from 'types/common'
import { useForm } from 'hooks/useForm'
import { OrganizationBase } from 'services/api/entities/crm/organizations/types/internal/organization-model'
import KHeader from 'components/UI/KHeader'
import { SimpleFormIterator, useTranslate } from 'react-admin'
import OrganizationCheckoutQuestion from 'resources/crm/clients/organization/checkout-questions/OrganizationCheckoutQuestion'
import KArrayInput from 'components/Inputs/KInputs/KArrayInput'

const OrganizationCheckoutQuestions: RAFormFC = ({ source }) => {
  const { getSource } = useForm<OrganizationBase>(source)
  const translate = useTranslate()

  return (
    <>
      <KHeader
        title="form.headers.checkoutQuestionAccordionTitleCheckout"
        headingLevel="h2"
        className="my-0"
      />
      <p className="mb-0 mt-2">
        {translate('form.fields.checkoutQuestionDescription')}
      </p>
      <div className="mt-4">
        <KArrayInput source={getSource('checkoutQuestions')}>
          <SimpleFormIterator fullWidth>
            <OrganizationCheckoutQuestion />
          </SimpleFormIterator>
        </KArrayInput>
      </div>
    </>
  )
}

export default OrganizationCheckoutQuestions
