import React from 'react'
import { FormTabProps, useTranslate } from 'react-admin'
import { productStatusChoices } from 'services/cfg/form'
import { isSectionValid } from 'resources/operations/events/utils'
import CustomReferenceInput from 'components/Inputs/CustomReferenceInput'
import ProfessionalServiceSelectionInput from 'components/FormComponents/ProfessionalServiceSelectionInput'
import KFormTab from 'components/Forms/KForms/KFormTab'
import { Badge } from '@mui/material'
import { ProfessionalSection } from 'services/api/entities/catalog/sections/professionalSections'
import KSelectInput from 'components/Inputs/KInputs/KSelectInput'
import { useForm } from 'hooks/useForm'
import { RAFormFC } from 'types/common'
import { MappedEnrichedEvent } from 'services/api/entities/events/types/internal/event-models'
import KDivider from 'components/UI/KDivider'
import FlexBox from 'components/UI/layout/FlexBox'
import EventCustomizationArrayInputAccordion from 'components/FormComponents/Customizations/EventCustomizationArrayInputAccordion'
import { useEventProfessionalServiceGroup } from 'hooks/events/useEventProfessionalServiceGroup'

type Props = Omit<FormTabProps, 'label'>

const ProfessionalsTab: RAFormFC<Props> = ({ source, ...props }) => {
  const { hasSelections } = useEventProfessionalServiceGroup()
  const translate = useTranslate()
  const { getSource, useGetValue } = useForm<MappedEnrichedEvent>(source)

  const isGeoareaSelected = !!useGetValue('geoAreaPreset')
  return (
    <KFormTab
      {...props}
      disabled={!isGeoareaSelected}
      label={
        <Badge
          badgeContent={hasSelections ? 1 : 0}
          color="primary"
          variant="dot"
        >
          {translate('form.tabs.professionals')}
        </Badge>
      }
    >
      <KSelectInput
        source="professionalStatus"
        label="form.fields.status"
        choices={productStatusChoices}
        defaultValue={productStatusChoices[0].id}
      />

      {/* Professionals input */}
      <CustomReferenceInput<ProfessionalSection>
        entity="professionalsections"
        filterFns={[isSectionValid]}
      >
        <ProfessionalServiceSelectionInput />
      </CustomReferenceInput>

      <FlexBox>
        <KDivider />
        <EventCustomizationArrayInputAccordion
          source={getSource('professionalService')}
          maxCustomizations={1}
        />
      </FlexBox>
    </KFormTab>
  )
}

export default ProfessionalsTab
