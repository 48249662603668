import { styled } from '@mui/material/styles'
import React from 'react'
import { useTranslate } from 'react-admin'
import KTextInput from 'components/Inputs/KInputs/KTextInput/KTextInput'

const PREFIX = 'QuantityHintGuestMockField'

const classes = {
  fieldsBox: `${PREFIX}-fieldsBox`,
  fieldWidth: `${PREFIX}-fieldWidth`,
  ml55: `${PREFIX}-ml55`
}

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.fieldsBox}`]: {
    display: 'flex',
    flexDirection: 'row',
    gap: '100px',
    width: '100%'
  },

  [`& .${classes.fieldWidth}`]: {
    width: '300px'
  },

  [`& .${classes.ml55}`]: {
    marginLeft: '50px'
  }
}))

const QuantityHintGuestMockField: React.FC = (props) => {
  const translate = useTranslate()
  return (
    <Root>
      <div>{translate('form.fields.simulateParticipants')}</div>
      <KTextInput
        {...props}
        source="guestsNumberMock"
        label="form.fields.guestsNumber"
        type="number"
        className={classes.fieldWidth}
      />
    </Root>
  )
}

export default QuantityHintGuestMockField
