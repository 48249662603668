import { ProductWithUpselling } from '@kampaay/common'
import {
  formatMainProduct,
  MainProductCSV
} from 'services/csv/entities/catalog/common/mainProduct'
import {
  formatWithIdAndName,
  WithIdAndNameCSV
} from 'services/csv/entities/catalog/common/withIdAndName'

export type ProductWithUpsellingCSV = MainProductCSV & {
  Upselling: WithIdAndNameCSV[]
}

export const formatProductWithUpselling = ({
  upsellingItemsFull,
  ...rest
}: ProductWithUpselling): ProductWithUpsellingCSV => ({
  ...formatMainProduct(rest),
  Upselling: upsellingItemsFull.map(formatWithIdAndName)
})
