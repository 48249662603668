import type { EventProps } from 'react-big-calendar'
import React, { useCallback } from 'react'
import { Link, useCreatePath } from 'react-admin'
import type { WithCalendarOriginalItem } from 'components/UI/Organization/Calendar/OrganizationCalendar'
import type {
  LinkedConcierge,
  LinkedEvent
} from 'services/api/entities/event-placeholder/types/internal/event-placeholder-models'
import {
  getCalendarItemResourceName,
  isEventPlaceholder
} from 'services/utils/organization/calendar'
import OrganizationCalendarItemContent from 'components/UI/Organization/Calendar/Item/OrganizationCalendarItemContent'

const OrganizationCalendarItem = (
  item: EventProps<WithCalendarOriginalItem>
) => {
  const createPath = useCreatePath()
  const { originalItem } = item.event

  const createItemPath = useCallback((item: LinkedEvent | LinkedConcierge) => {
    return createPath({
      resource: `/${getCalendarItemResourceName(item)}`,
      id: item.id,
      type: 'edit'
    })
  }, [])

  if (isEventPlaceholder(originalItem)) {
    return <OrganizationCalendarItemContent originalItem={originalItem} />
  }

  return (
    <Link to={createItemPath(originalItem)} target="_blank">
      <OrganizationCalendarItemContent originalItem={originalItem} />
    </Link>
  )
}

export default OrganizationCalendarItem
