import type { FileUploader } from '../../types'
import type { ImageWithDescription, ImageWithDescriptionToAPI } from './types'

export const writeImageWithDescription =
  (fileUploader: FileUploader) =>
  async ({
    image,
    description
  }: ImageWithDescription): Promise<ImageWithDescriptionToAPI> => {
    const uploadedImage = await fileUploader(image)

    return {
      description,
      image: uploadedImage!
    }
  }
