import { CatalogItemBase, parseCsvFields } from '@kampaay/common'

export type CatalogItemBaseCSV = {
  ID: number
  Name: string
}

export const formatCatalogItemBase = ({
  id,
  name
}: CatalogItemBase): CatalogItemBaseCSV => ({
  ID: id,
  ...parseCsvFields({ Name: name })
})
