import { APICfg } from 'services/api/api'
import { productSectionParse } from 'services/api/entities/catalog/utils'
import {
  ExperienceSection,
  ExperienceSectionToAPI
} from 'services/api/entities/catalog/sections/experienceSection'

const apiCfg: APICfg = {
  endpoint: 'physicalexperiencessections',
  parse: productSectionParse,
  write: async (req: ExperienceSection): Promise<ExperienceSectionToAPI> => req
}

export default apiCfg
