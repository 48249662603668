import { KBooleanInput } from 'components/Inputs/KInputs/KBooleanInput'
import React from 'react'
import { PaymentConfiguration, RAFormFC } from 'types/common'
import { styled } from '@mui/material'
import LocalizedTextInput from 'components/Inputs/Localized/LocalizedTextInput'
import { useForm } from 'hooks/useForm'
import KToolTip from 'components/UI/KTooltip'

export type Props = {
  title: string
  showDisabledToggle?: boolean
}

const PaymentConfigurationBaseInput: RAFormFC<Props> = ({
  source,
  title,
  showDisabledToggle = false
}) => {
  const { getSource } = useForm<PaymentConfiguration>(source)

  const BooleanInputWrapper = styled('div')(() => ({
    display: 'flex',
    flexGrow: 1,
    '& b': {
      lineHeight: 2.5,
      marginRight: '60px'
    }
  }))

  return (
    <>
      <BooleanInputWrapper>
        <b>{title}</b>
        {showDisabledToggle ? (
          <KToolTip title="form.fields.activatePaymentToggle">
            <KBooleanInput
              source={getSource('isDisabled')}
              label="form.fields.isDisabled"
              defaultValue={false}
            />
          </KToolTip>
        ) : null}
      </BooleanInputWrapper>
      <div>
        <LocalizedTextInput
          source={getSource('name')}
          label="form.fields.name"
        />
      </div>
      <div>
        <LocalizedTextInput
          source={getSource('title')}
          label="form.fields.title"
        />
      </div>
      <div>
        <LocalizedTextInput
          source={getSource('description')}
          label="form.fields.description"
        />
      </div>
    </>
  )
}

export default PaymentConfigurationBaseInput
