import { Button } from '@mui/material'
import React from 'react'
import { useTranslate } from 'react-admin'
import EventSummaryRenderer from 'resources/operations/events/EventsComponents/PdfRenderers/EventSummaryRenderer'
import { printReactComponent } from 'services/pdf-print/pdf-print'
import DownloadIcon from '@mui/icons-material/DownloadOutlined'

const PrintEventSummaryButton: React.FC = () => {
  const translate = useTranslate()
  return (
    <Button
      startIcon={<DownloadIcon />}
      variant="text"
      sx={{
        fontWeight: 'bold'
      }}
      onClick={printReactComponent(EventSummaryRenderer({}) as JSX.Element)}
    >
      {translate('common.label.downloadEventSummary')}
    </Button>
  )
}
export default PrintEventSummaryButton
