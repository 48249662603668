import { UseValidationSchema, YupSchema } from 'services/yup/types'
import { yupArray, yupIgnore, yupNumber } from 'services/yup'
import { useExperienceVS } from 'services/validations/catalog/experience'
import { PhysicalExperience } from 'services/api/entities/catalog/physical/types/internal/physical-models'
import { organizationTailoringsArrayOptionedVS } from 'services/validations/crm/oraganizationTailorings/organizationTailoringsOptioned'
import { getGeoCodedAddressVS } from 'services/validations/common'
import { APIPriceRange, PhysicalOptionedTailoring } from '@kampaay/common'

const physicalOptionedTailoringVS: YupSchema<PhysicalOptionedTailoring> =
  organizationTailoringsArrayOptionedVS.shape({
    organizationId: yupNumber()
  })

export const usePhysicalVS: UseValidationSchema<
  PhysicalExperience,
  APIPriceRange[] | undefined
> = () =>
  useExperienceVS().shape({
    minDuration: yupNumber().required(),
    maxDuration: yupNumber().required(),
    settingFacets: yupArray(),
    teambuildingCategoryFacet: yupIgnore(),
    languageFacets: yupArray(),
    tailorings: yupArray(physicalOptionedTailoringVS),
    geoCodedAddress: getGeoCodedAddressVS().optional()
  })
