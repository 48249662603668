import React from 'react'
import { ReferenceField, FunctionField, ReferenceFieldProps } from 'react-admin'
import { Kampaayer } from '@kampaay/common'
import { WithDataTestId } from 'test/utils'
import { WithSource } from 'types/common'
import { WithClassName } from 'types/ui'

type Props = WithClassName &
  WithDataTestId &
  Partial<WithSource> & {
    label?: string
    link?: ReferenceFieldProps['link']
    sortable?: ReferenceFieldProps['sortable']
    sortBy?: ReferenceFieldProps['sortBy']
  }

const KampaayerReferenceField: React.FC<Props> = ({
  className,
  label = 'form.fields.kampaayer',
  link = false,
  sortable = false,
  sortBy,
  'data-testid': testId,
  source = 'kampaayerId'
}) => {
  return (
    <ReferenceField
      label={label}
      source={source}
      reference="kampaayers"
      sortable={sortable}
      data-testid={testId}
      sortBy={sortBy}
      link={link}
    >
      <FunctionField
        className={className}
        render={(record: Kampaayer) => `${record.firstName} ${record.lastName}`}
      />
    </ReferenceField>
  )
}

export default KampaayerReferenceField
