import React from 'react'
import { useKGetList } from 'hooks/useKGetList'
import KToolTip from 'components/UI/KTooltip'
import { Customer } from '@kampaay/common'
import KAutocompleteArrayInput, {
  KAutocompleteArrayInputProps
} from 'components/Inputs/KInputs/KAutocompleteArrayInput'

const ClientAutocompleteArrayInput: React.FC<KAutocompleteArrayInputProps> = (
  props
) => {
  const customers = useKGetList<Customer>('customers').list

  return (
    <KToolTip title="form.fields.tailoredClientTooltip" placement="top-start">
      <KAutocompleteArrayInput
        {...props}
        source="customers"
        choices={customers.map((e) => ({
          id: e.id,
          name: `${e.email} - ${e.name}`
        }))}
        defaultValue={[]}
      />
    </KToolTip>
  )
}

export default ClientAutocompleteArrayInput
