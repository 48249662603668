import React from 'react'
import { OptionGroupSelection } from 'services/api/entities/events/types/internal/selections'
import { translate } from 'services/i18n'
import { parseMultilangOrFallbackEng } from '@kampaay/common'

type OptionsSectionProps = {
  options: OptionGroupSelection[]
}

const OptionsSection: React.FC<OptionsSectionProps> = ({ options }) => {
  return (
    <>
      <p>{translate('form.fields.options')}: </p>
      <ul style={{ paddingLeft: '60px' }}>
        {options.map((option, idx) => (
          <div key={`${option.id}-${option.name}-${idx}`}>
            <li>
              <p data-testid="options-section--name">
                {parseMultilangOrFallbackEng(option.name)}
              </p>
            </li>
            <ul style={{ paddingLeft: '60px' }}>
              <>
                {option.items?.map((item, idx) => (
                  <li
                    key={`${item.id}-${item.name}-${idx}`}
                    data-testid="options-section--items"
                  >
                    <p>{`${parseMultilangOrFallbackEng(item.name)}: ${
                      item.quantity
                    }`}</p>
                  </li>
                ))}
              </>
            </ul>
          </div>
        ))}
      </ul>
    </>
  )
}

export default OptionsSection
