import React, { ReactElement, ReactNode } from 'react'
import { styled } from '@mui/material/styles'
import { ExpandMore } from '@mui/icons-material'
import { useTranslate } from 'react-admin'
import {
  Collapse,
  Divider,
  List,
  MenuItem,
  Tooltip,
  Typography
} from '@mui/material'

const PREFIX = 'SubMenu'

const classes = {
  icon: `${PREFIX}-icon`,
  sidebarIsOpen: `${PREFIX}-sidebarIsOpen`,
  sidebarIsClosed: `${PREFIX}-sidebarIsClosed`
}

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }: any) => ({
  [`& .${classes.icon}`]: { minWidth: theme.spacing(5), color: '#00000084' },

  [`& .${classes.sidebarIsOpen}`]: {
    paddingLeft: 25,
    transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms'
  },

  [`& .${classes.sidebarIsClosed}`]: {
    paddingLeft: 0,
    transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms'
  }
}))

interface Props {
  sidebarIsOpen: boolean
  dense?: boolean
  handleToggle: () => void
  icon: ReactElement
  isOpen: boolean
  name: string
  children: ReactNode
}

const SubMenu = ({
  handleToggle,
  sidebarIsOpen,
  isOpen,
  name,
  icon,
  children,
  dense
}: Props) => {
  const translate = useTranslate()

  const header = (
    <MenuItem dense={dense} onClick={handleToggle}>
      <div className={classes.icon}>{isOpen ? <ExpandMore /> : icon}</div>
      <Typography variant="inherit" color="textSecondary">
        {translate(name)}
      </Typography>
    </MenuItem>
  )

  return (
    <Root>
      {sidebarIsOpen || isOpen ? (
        header
      ) : (
        <Tooltip title={translate(name)} placement="right">
          {header}
        </Tooltip>
      )}
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List
          className={
            sidebarIsOpen ? classes.sidebarIsOpen : classes.sidebarIsClosed
          }
        >
          {children}
        </List>
        <Divider />
      </Collapse>
    </Root>
  )
}

export default SubMenu
