import React from 'react'
import LabeledNumberField from 'components/Fields/LabeledFields/LabeledNumberField'

type Props = {
  available: number
  filled?: boolean
}

const OrganizationAvailableBudgetField: React.FC<Props> = ({
  available,
  filled
}) => {
  return (
    <LabeledNumberField
      record={{ available }}
      source="available"
      label="form.fields.available"
      filled={filled}
      className="w-full"
    />
  )
}

export default OrganizationAvailableBudgetField
