import { buildFilterConfig } from 'services/utils/filters'
import { FILTER_SOURCES, TypedChoices } from '@kampaay/common'
import { EventPlaceholderStatus } from 'services/api/entities/event-placeholder/types/internal/event-placeholder-models'

const eventPlaceholderStatusChoices: TypedChoices<EventPlaceholderStatus> = [
  {
    id: 'pending',
    name: 'org.eventPlaceholders.filterLabels.pending'
  },
  {
    id: 'noEvents',
    name: 'org.eventPlaceholders.filterLabels.noEvents'
  },
  {
    id: 'confirmed',
    name: 'org.eventPlaceholders.filterLabels.confirmed'
  }
]

export const ORG_EVENT_PLACEHOLDERS_FILTERS = [
  buildFilterConfig('text', {
    source: FILTER_SOURCES.name.contains,
    alwaysOn: true,
    label: 'org.eventPlaceholders.name'
  }),
  buildFilterConfig('text', {
    source: FILTER_SOURCES.customerName.contains,
    alwaysOn: true,
    label: 'org.eventPlaceholders.customerName'
  }),
  buildFilterConfig('text', {
    source: FILTER_SOURCES.customerEmail.contains,
    alwaysOn: true,
    label: 'org.eventPlaceholders.customerEmail'
  }),
  buildFilterConfig('select', {
    source: FILTER_SOURCES.status.eq,
    alwaysOn: true,
    label: 'org.eventPlaceholders.status',
    choices: eventPlaceholderStatusChoices
  }),
  buildFilterConfig('date', {
    source: FILTER_SOURCES.startDate.gte,
    alwaysOn: true,
    label: 'org.eventPlaceholders.startDate'
  }),
  buildFilterConfig('date', {
    source: FILTER_SOURCES.endDate.lt,
    alwaysOn: true,
    label: 'org.eventPlaceholders.endDate'
  })
]
