import { LOCATION_FILTERS } from 'services/filters/entities/location'
import { TRANSACTION_FILTERS } from 'services/filters/entities/transaction'
import { UPSELLING_FILTERS } from 'services/filters/entities/upselling'
import { CUSTOMER_FILTERS } from 'services/filters/entities/customer'
import { USER_FILTERS } from 'services/filters/entities/user'
import { CONCIERGE_FILTERS } from 'services/filters/entities/concierge'
import { EVENT_FILTERS_DRAFTS } from 'services/filters/entities/draftEvent'
import { EVENT_FILTERS } from 'services/filters/entities/event'
import { FilterCfgMap } from 'services/filters/types'
import { AREA_FILTERS } from 'services/filters/entities/area'
import { ORG_FILTERS } from 'services/filters/entities/organization'
import { ORG_BUDGET_FILTERS } from 'services/filters/entities/organization/budget'
import { ORG_MEMBERSHIP_FILTERS } from 'services/filters/entities/organization/membership'
import { EMAIL_MESSAGE_FILTERS } from 'services/filters/entities/emailmessage'
import { ORG_EVENT_PLACEHOLDERS_FILTERS } from 'services/filters/entities/event-placeholders'
import { LOCATION_FEATURES_FILTERS } from 'services/filters/entities/locationFeatures'
import { CATALOG_FILTERS } from 'services/filters/entities/catalog'
import { PHYSICAL_FILTERS } from 'services/filters/entities/physical'

export const filterConfig: FilterCfgMap = {
  // catalog
  drinks: CATALOG_FILTERS,
  foods: CATALOG_FILTERS,
  musics: CATALOG_FILTERS,
  rents: CATALOG_FILTERS,
  virtuals: CATALOG_FILTERS,
  physicals: PHYSICAL_FILTERS,
  professionals: CATALOG_FILTERS,
  locations: LOCATION_FILTERS,

  // upselling
  foodsupselling: UPSELLING_FILTERS,
  virtualupselling: UPSELLING_FILTERS,
  physicalupselling: UPSELLING_FILTERS,
  locationupselling: UPSELLING_FILTERS,

  // misc
  events: EVENT_FILTERS,
  area: AREA_FILTERS,
  concierge: CONCIERGE_FILTERS,
  eventsdraft: EVENT_FILTERS_DRAFTS,
  kampaayers: USER_FILTERS,
  customers: CUSTOMER_FILTERS,
  transaction: TRANSACTION_FILTERS,
  logistics: UPSELLING_FILTERS,
  organization: ORG_FILTERS,
  organizationbudget: ORG_BUDGET_FILTERS,
  organizationmembership: ORG_MEMBERSHIP_FILTERS,
  eventPlaceholder: ORG_EVENT_PLACEHOLDERS_FILTERS,
  emailmessage: EMAIL_MESSAGE_FILTERS,
  locationfeatures: LOCATION_FEATURES_FILTERS
}
