export const pdfCss = `
  @font-face {
    font-family: 'HKGrotesk';
    src: url('public/assets/HKGrotesk-Regular.otf') format('opentype');
  }
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'HKGrotesk', Helvetica, Arial, Verdana, Tahoma, sans-serif;
}

/* 
  PAGE LAYOUT
*/

.quotation-body {
  padding: 0 50px;
  width: 100%;
}

.page-header,
.header-space {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-footer,
.footer-space {
  height: 50px;
}

.page-header {
  position: fixed;
  top: 0;
}

.page-footer {
  position: fixed;
  bottom: 0;
  border-top: 1px solid black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.header-logo {
  height: 100%;
  width: auto;
}

.page-content {
  line-height: 1.5;
}

.section {
  border-top: 1px solid black;
  padding-top: 15px;
  margin-top: 25px;
}

/* 
  TYPOGRAPHY
*/

.text-capitalize {
  text-transform: capitalize;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.bold {
  font-weight: 900;
}

.underlined {
  text-decoration: underline;
}

.f-size-12 {
  font-size: 12px;
}

.footer-text {
  color: grey;
  font-style: italic;
}

/* 
  SPACING
*/

.mt-15 {
  margin-top: 15px;
}

.mb-15 {
  margin-bottom: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.mr-20 {
  margin-right: 20px;
}
`
