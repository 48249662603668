import { useForm } from 'hooks/useForm'
import { APIEntity } from 'services/api/entities'
import { useCatalogRepository } from 'resources/crm/clients/organization/tailoring/hooks/repositories/useCatalogRepository'
import { include } from 'resources/operations/events/utils'
import {
  getId,
  ProductTailoringBase,
  Tailorings,
  WithIdAndName
} from '@kampaay/common'

export const useTailoringsRepository = (
  catalogProductEntity: APIEntity,
  productTailoringEntity?: string
) => {
  // ===================================
  // SETUP
  // ===================================
  const { products: catalogProducts } =
    useCatalogRepository(catalogProductEntity)
  const { useGetValue } = useForm<Tailorings<ProductTailoringBase>>(
    productTailoringEntity
  )

  // ===================================
  // DATA
  // ===================================
  const currentFormTailorings = useGetValue('tailorings')

  /**
   * extract the related products from the current form tailorings
   *
   * e.g.
   * (foodTailoring <--> food)
   */
  const getRelatedCatalogProducts = (fullCatalogProducts: WithIdAndName[]) => {
    return include(currentFormTailorings.map(getId), fullCatalogProducts)
  }

  // ===================================
  return {
    getRelatedCatalogProducts,
    currentFormTailorings,
    catalogProducts
  }
}
