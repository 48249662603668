import * as yup from 'yup'

// extending the yup class in order to be able to instantiate it for the purpose of the yuoIgnore helper
class YupBaseSchema<T> extends yup.BaseSchema<T> {}

/**
 * Meant to be used to define the schemas of fields that do not need specific validation
 *
 * @returns a yup.BAseSchema generically typed
 */
export const yupIgnore = <T>() => new YupBaseSchema<T>()
