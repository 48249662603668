import React from 'react'
import { Customization } from 'services/api/entities/events/types/internal/event-models'
import { translate } from 'services/i18n'
import RendererTranslatedParagraph from 'resources/operations/events/EventsComponents/PdfRenderers/commonRenderers/TranslatedParagraph'
import { parseMultilangOrFallbackEng, stripHTMLTags } from '@kampaay/common'

type CustomizationsSectionProps = {
  customizations: Customization[]
}

const CustomizationsSection: React.FC<CustomizationsSectionProps> = ({
  customizations
}) => {
  return (
    <>
      <p>{translate('form.fields.customizations')}: </p>
      <ul style={{ paddingLeft: '60px' }}>
        {customizations.map((customization) => (
          <li key={customization.name}>
            <p data-testid="customization-section--name">
              {parseMultilangOrFallbackEng(customization.name)}
            </p>
            <p data-testid="customization-section--description">
              {stripHTMLTags(
                parseMultilangOrFallbackEng(customization.description) ?? ''
              )}
            </p>
            <RendererTranslatedParagraph
              data-testid="customization-section--price"
              i18nKey="form.fields.price"
              keyClass="font-bold"
              value={customization.price}
            />
          </li>
        ))}
      </ul>
    </>
  )
}

export default CustomizationsSection
