import { buildMultilangString, createMockBuilder } from '../../../../utils'
import type { APIProposalItem } from '../types'

const buildBaseMock = (): APIProposalItem => ({
  id: 1,
  name: buildMultilangString({
    en: 'proposal item name',
    it: 'nome item proposta'
  }),
  isPriceIncluded: false,
  images: [],
  showDescription: false,
  showImages: false,
  showSupplier: false,
  createdAt: '2021-01-01T00:00:00.000Z',
  isSelectable: false,
  layout: 'standard',
  guid: 'guid',
  isOutOfScope: false
})

export const buildAPIProposalItemMock = createMockBuilder(buildBaseMock)
