import React, { PropsWithChildren } from 'react'
import { FormDataConsumer } from 'react-admin'
import { accessPropertyByPath } from 'resources/operations/events/utils'
import { FormDataConsumerProps } from 'types/common'
import { KBooleanInput } from 'components/Inputs/KInputs/KBooleanInput'

type ContentTogglerProps = PropsWithChildren & {
  source: string
  label: string
}

/**
 * If the switch is on, the content is shown
 * Else the content is hidden
 * @param children - content to be shown
 * @param source - source of the switch
 * @param label - label of the switch
 */
const ContentToggler: React.FC<ContentTogglerProps> = ({
  children,
  source,
  label
}) => (
  <>
    <KBooleanInput source={source} label={label} />
    <FormDataConsumer>
      {({ formData }: FormDataConsumerProps<any>) =>
        accessPropertyByPath(formData, source) && children
      }
    </FormDataConsumer>
  </>
)

export default ContentToggler
