import React from 'react'
import { SelectArrayInputProps } from 'types/common'
import KSelectArrayInput from 'components/Inputs/KInputs/KSelectArrayInput'
import LocalizedWithChoicesInput from 'components/Inputs/Localized/LocalizedWithChoicesInput'

const LocalizedSelectArrayInput: React.FC<SelectArrayInputProps> = ({
  defaultValue = [],
  ...rest
}) => (
  <LocalizedWithChoicesInput {...rest}>
    <KSelectArrayInput source={rest.source} defaultValue={defaultValue} />
  </LocalizedWithChoicesInput>
)

export default LocalizedSelectArrayInput
