import React from 'react'
import SectionWrapper from 'components/UI/SectionWrapper'
import DrinkSectionFormContent from 'resources/catalog/drink/sections/DrinkSectionFormContent'
import { KSimpleForm } from 'components/Forms/KForms/KSimpleForm'
import KCreate from 'components/Forms/KCreate/KCreate'

const DrinkCreate: React.FC = () => (
  <SectionWrapper title={'resources.drinksections.createSection'}>
    <KCreate>
      <KSimpleForm>
        <DrinkSectionFormContent />
      </KSimpleForm>
    </KCreate>
  </SectionWrapper>
)

export default DrinkCreate
