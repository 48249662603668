import { drinks } from 'services/csv/entities/catalog/drinks'
import { drinksections } from 'services/csv/entities/catalog/section/drinkssection'
import { events } from 'services/csv/entities/operations/events'
import { foods } from 'services/csv/entities/catalog/foods'
import { foodsupselling } from 'services/csv/entities/catalog/upselling/foodsupselling'
import { kampaayers } from 'services/csv/entities/crm/kampaayers'
import { musics } from 'services/csv/entities/catalog/musics'
import { customers } from 'services/csv/entities/crm/customers'
import { rents } from 'services/csv/entities/catalog/rents'
import { transaction } from 'services/csv/entities/accounting/transaction'
import { virtuals } from 'services/csv/entities/catalog/virtual'
import { physicals } from 'services/csv/entities/catalog/physical'
import { virtualupselling } from 'services/csv/entities/catalog/upselling/virtualupselling'
import { logistics } from 'services/csv/entities/catalog/logistics'
import { professionals } from 'services/csv/entities/catalog/professionalfigures'
import { locations } from 'services/csv/entities/catalog/locations'
import { locationupselling } from 'services/csv/entities/catalog/upselling/locationupselling'
import { physicalupselling } from 'services/csv/entities/catalog/upselling/physicalupselling'
import { locationfeatures } from 'services/csv/entities/catalog/locationfeatures'
import { organization } from 'services/csv/entities/crm/organizations'
import { transactionForPurchaseOrder } from 'services/csv/entities/accounting/transactionForPurchaseOrder'
import { CSVEntityDescriptorMap } from 'services/csv/types'

export const CSV_CFG: CSVEntityDescriptorMap = {
  customers,
  drinks,
  drinksections,
  events,
  eventsdraft: events,
  foods,
  foodsupselling,
  kampaayers,
  locationfeatures,
  locations,
  locationupselling,
  logistics,
  musics,
  organization,
  physicals,
  physicalupselling,
  professionals,
  rents,
  transaction: {
    primary: transaction,
    purchaseOrder: transactionForPurchaseOrder
  },
  virtuals,
  virtualupselling
} as const
