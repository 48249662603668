import React from 'react'
import { styled } from '@mui/material/styles'
import { Labeled, useTranslate } from 'react-admin'
import KNumberInput from 'components/Inputs/KInputs/KNumberInput/KNumberInput'
import { computeQuantityByMaxParticipants } from 'components/FormComponents/Hints/common'
import { useWatch } from 'react-hook-form'
import { QuantityHint, WithQuantityHint } from '@kampaay/common'

const PREFIX = 'DefaultQuantityHint'

const classes = {
  fieldsBox: `${PREFIX}-fieldsBox`,
  fieldWidth: `${PREFIX}-fieldWidth`,
  ml55: `${PREFIX}-ml55`
}

const StyledDiv = styled('div')(() => ({
  [`& .${classes.fieldsBox}`]: {
    display: 'flex',
    flexDirection: 'row',
    gap: '100px',
    width: '100%'
  },

  [`& .${classes.fieldWidth}`]: {
    width: '300px'
  },

  [`& .${classes.ml55}`]: {
    marginLeft: '50px'
  }
}))

export type QuantityHintProps = {
  guestsNumberMock: number
  quantityHint: QuantityHint[]
}

const DefaultQuantityHint: React.FC<QuantityHintProps> = ({
  guestsNumberMock,
  quantityHint
}) => {
  const translate = useTranslate()
  const defaultQuantityHint = useWatch<WithQuantityHint, 'defaultQuantityHint'>(
    {
      name: 'defaultQuantityHint'
    }
  )

  return (
    <StyledDiv>
      <div className={[classes.fieldsBox, classes.ml55].join(' ')}>
        <div className={classes.fieldWidth}>
          {translate('form.fields.default')}
        </div>
        <KNumberInput
          label={'form.fields.maxParticipants'}
          source="defaultQuantityHint.maxParticipants"
          style={{ display: 'hidden', width: '0px' }}
          defaultValue={10000}
        />
        <KNumberInput
          source="defaultQuantityHint.quantityMultiplier"
          label="form.fields.quantityMultiplier"
          className={classes.fieldWidth}
          defaultValue={100}
        />
        <Labeled label="Simulated quantity" className={classes.fieldWidth}>
          <div>
            {computeQuantityByMaxParticipants(
              quantityHint,
              defaultQuantityHint?.maxParticipants,
              defaultQuantityHint?.quantityMultiplier,
              guestsNumberMock
            )}
          </div>
        </Labeled>
      </div>
    </StyledDiv>
  )
}

export default DefaultQuantityHint
