import { EventBriefQuestion } from 'services/api/entities/events/types/internal/services'

const checkIfResponseIsFilled = (briefQuestion: EventBriefQuestion): boolean =>
  !!briefQuestion?.response?.trim()

/**
 * Checks if the brief is filled out.
 * @param briefQuestions - The array of brief questions.
 * @returns Returns `true` if the brief is filled out, `false` otherwise.
 */
export const checkBriefQuestionsResponses = (
  briefQuestions: EventBriefQuestion[] = []
): boolean => {
  // Filter the briefQuestions array to get only the mandatory questions
  const mandatoryQuestions: EventBriefQuestion[] = briefQuestions.filter(
    (briefQuestion: EventBriefQuestion) => briefQuestion.mandatory
  )

  // If there are mandatory questions, check all of them
  if (mandatoryQuestions.length > 0) {
    return mandatoryQuestions.every(checkIfResponseIsFilled)
  }

  // Otherwise check if there is at least one question with a non-empty response
  return briefQuestions.some(checkIfResponseIsFilled)
}
