import type { MultipleFileUploader } from '../../../../types'
import type {
  MainProduct,
  MainProductToApi,
  ProductCommon,
  ProductCommonToAPI
} from './types'

export const productCommonWrite =
  (imageUploader: MultipleFileUploader) =>
  async (req: ProductCommon): Promise<ProductCommonToAPI> => ({
    ...req,
    images: await imageUploader(req.images ?? [])
  })

export const mainProductWrite =
  (imageUploader: MultipleFileUploader) =>
  async ({
    advanceReservationMin,
    ...rest
  }: MainProduct): Promise<MainProductToApi> => ({
    ...rest,
    advanceReservationMin,
    ...(await productCommonWrite(imageUploader)(rest))
  })
