// !Tets Fns
import { yupArrayWithContext, YupSchema } from 'services/yup'
import { Location } from 'services/api/entities/catalog/location/types/internal/location-models'
import {
  areRulesNotOverlappingCFG,
  byRulePricingCommonVS
} from 'services/validations/catalog/by-rule-pricing/byRulePricing'
import {
  areAllTPRCoveringTheAvailableTimePeriodCFG,
  isAtLeastOneNHRGreaterThanMinHoursCFG
} from 'services/validations/catalog/location/byRulePricing.validations'
import { pricingRulesVS } from 'services/validations/catalog/location/pricingRules'
import { LocationPricingRuleSet } from '@kampaay/common'

export const locationByRulePricingArrayVS: YupSchema<LocationPricingRuleSet[]> =
  yupArrayWithContext<Location>()(
    byRulePricingCommonVS.shape({
      pricingRules: pricingRulesVS
    })
  )
    .min(1)
    .test(isAtLeastOneNHRGreaterThanMinHoursCFG)
    .test(areRulesNotOverlappingCFG)
    .test(areAllTPRCoveringTheAvailableTimePeriodCFG)
