import { eventColorChoices } from '@kampaay/common'
import KSelectInput from 'components/Inputs/KInputs/KSelectInput'
import { useTranslate } from 'ra-core'
import React from 'react'
import { RAFormFC } from 'types/common'

export const EventColorSelectInput: RAFormFC = ({ source }) => {
  const t = useTranslate()

  return (
    <KSelectInput
      source={source}
      label="form.fields.color"
      choices={eventColorChoices}
      optionText={(choice) => (
        <div style={{ display: 'flex', gap: '0.5rem' }}>
          <div
            style={{
              width: '20px',
              height: '20px',
              borderRadius: '5px',
              backgroundColor: choice.id
            }}
          ></div>
          {t(choice.name)}
        </div>
      )}
    />
  )
}
