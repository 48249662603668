import { APICfg } from 'services/api/api'
import { conciergeParse } from '@kampaay/common'
import { uploadOrKeepMultipleFiles } from 'services/utils/files'
import { conciergeWrite } from '@kampaay/common'

const apiCfg: APICfg = {
  endpoint: 'concierge',
  parse: conciergeParse,
  write: conciergeWrite(uploadOrKeepMultipleFiles)
}

export default apiCfg
