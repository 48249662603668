import { parseImage } from '../images/parse'
import type { APIImageWithDescription, ImageWithDescription } from './types'

export const parseImageWithDescription = (
  image: APIImageWithDescription
): ImageWithDescription => {
  return {
    description: image.description,
    image: parseImage(image.image)!
  }
}

export const parseMultipleImagesWithDescription = (
  images: APIImageWithDescription[]
): ImageWithDescription[] => {
  return images.map(parseImageWithDescription)
}
