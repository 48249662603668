import React, { useState } from 'react'
import { Menu, Resource } from 'react-admin'
import { isDev } from 'services/env'
import SubMenu from 'layout/SubMenu'
import { Science } from '@mui/icons-material'
import { pocMap } from 'poc/factory'

type Props = { onClick: any; sidebarIsOpen: boolean; dense?: boolean }

export const PocMenu: React.FC<Props> = !isDev
  ? (_props) => null
  : ({ onClick, sidebarIsOpen, dense }) => {
      const [isOpen, setIsOpen] = useState<boolean>(false)
      return (
        <SubMenu
          handleToggle={() => setIsOpen(!isOpen)}
          isOpen={isOpen}
          name="poc.resources.mainMenuLabel"
          icon={<Science />}
          sidebarIsOpen={sidebarIsOpen}
          dense={dense}
        >
          {Object.entries(pocMap).map(([resource]) => {
            return (
              <Menu.Item
                key={resource}
                to={`/${resource}`}
                primaryText={resource}
                leftIcon={<Science />}
                onClick={onClick}
                dense={dense}
              />
            )
          })}
        </SubMenu>
      )
    }

export const PocResources = () =>
  isDev ? (
    <>
      {Object.entries(pocMap).map(([resource, cfg]) => (
        <Resource
          key={resource}
          icon={Science}
          name={resource}
          {...cfg.forms}
        />
      ))}
    </>
  ) : null
