import { QuantityHint } from '@kampaay/common'
import { sortAscByMaxParticipants } from '@kampaay/common/src/utils/sorting'

export const computeQuantityByMaxParticipants = (
  quantityHint: QuantityHint[],
  maxParticipants?: number,
  quantityMultiplier?: number,
  guestsNumber?: number
) => {
  // if there is a value not filled we return '/'
  if (!maxParticipants || !guestsNumber || !quantityMultiplier) return '/'
  const clonedQuantityHint = [...quantityHint]

  // if in the hints array there is no element with the corresponding maxParticipants that
  // means that this is the default hint  that need to be pushed like the last element
  if (!clonedQuantityHint.find((e) => e.maxParticipants === maxParticipants))
    clonedQuantityHint.push({ maxParticipants, quantityMultiplier })

  // we need to sort the hints because we need to find the lower limit to know if we are in
  // the correct range
  clonedQuantityHint.sort(sortAscByMaxParticipants)

  // we search in the sorted array of hints to find the index
  const index = clonedQuantityHint.findIndex(
    (e) => e.maxParticipants === maxParticipants
  )

  // then we can find the min participants searching if present the previous element in the sorted array
  const minParticipants =
    index > 0 ? clonedQuantityHint[index - 1].maxParticipants : 0
  // if the range is correct we return the computed quantity
  return guestsNumber <= maxParticipants && guestsNumber > minParticipants
    ? Math.ceil((guestsNumber * quantityMultiplier) / 100)
    : '/'
}
