import React from 'react'
import KAccordion from 'components/UI/Accordions/KAccordion/KAccordion'
import KNumberInput from 'components/Inputs/KInputs/KNumberInput/KNumberInput'
import KTextInput from 'components/Inputs/KInputs/KTextInput/KTextInput'
import { INTOLERANCES } from '@kampaay/common'

type Props = {
  source: string
  disabled?: boolean
}

const IntolerancesInput: React.FC<Props> = ({ source, disabled = false }) => {
  return (
    <KAccordion accordionTitle="Intolerances">
      {INTOLERANCES.map((intolerance, i) => (
        <KNumberInput
          key={i}
          source={`${source}.${intolerance}`}
          label={`form.fields.intolerances.${intolerance}`}
          disabled={disabled}
          style={{ width: '24%', marginRight: '8px' }}
          min={0}
        />
      ))}
      <KTextInput
        source={`${source}.other`}
        label="form.fields.intolerances.other"
        disabled={disabled}
        style={{ width: '100%', marginTop: '8px' }}
      />
    </KAccordion>
  )
}

export default IntolerancesInput
