import { GetOneParams } from 'react-admin'
import { APIEntity } from 'services/api/entities/index'
import { multiPlexGetOneHandler } from 'services/api/dataProvider/common'
import { UnMappedEnrichedEvent } from 'services/api/entities/events/types/internal/event-models'
import { parseEventMultiplex } from 'services/api/entities/events/eventsMultiplexMap'

export const eventGetOne = async (resource: string, params: GetOneParams) => {
  const entities: APIEntity[] = [
    resource as APIEntity,
    'servicesconfig',
    'eventbrief'
  ]

  const [eventResponse, serviceConfigResp, eventBriefResp] =
    await multiPlexGetOneHandler(params, entities)

  const multiResponse: { data: UnMappedEnrichedEvent } = {
    data: {
      ...eventResponse!.data,
      serviceConfig: serviceConfigResp!.data,
      surveyResponses: eventBriefResp!.data
    }
  }

  return { data: parseEventMultiplex(multiResponse.data) }
}
