import React from 'react'
import ExclusiveBoolean from 'components/Inputs/ExclusiveBoolean'
import { KBooleanInput } from 'components/Inputs/KInputs/KBooleanInput'
import KNumberInput from 'components/Inputs/KInputs/KNumberInput/KNumberInput'
import { useForm } from 'hooks/useForm'
import { RAFormFC } from 'types/common'
import { OptionGroup } from '@kampaay/common'

type Props = {
  acceptsQuantityDependentSources: string[]
}

const OptionGroupSettings: RAFormFC<Props> = ({
  acceptsQuantityDependentSources,
  source
}) => {
  const { useGetValue, useSetValue, getSource } = useForm<OptionGroup>(source)

  const acceptsQuantity = useGetValue('acceptsQuantity')
  const infoBox = useGetValue('infoBox')
  const setMaxSelection = useSetValue('maxSelection')
  const setAcceptsQuantity = useSetValue('acceptsQuantity')

  // #870 if infoBox is true we disable the acceptsQuantity field and set it to false
  const onIsInfoBoxChange = (_event: any, e: boolean): void => {
    if (!e) {
      setMaxSelection(1)
      setAcceptsQuantity(false)
    }
  }

  const onAcceptsQuantityChange = (_event: any, e: boolean): void => {
    if (!e) {
      setMaxSelection(1)
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <KNumberInput
        source={getSource('maxSelection')}
        label="form.fields.maxOptions"
        disabled={!acceptsQuantity}
        style={{ margin: '0 30px  0 0' }}
      />
      <KBooleanInput
        source={getSource('infoBox')}
        label="form.fields.infoBox"
        defaultValue={false}
        onChange={onIsInfoBoxChange}
      />
      <ExclusiveBoolean
        source={getSource('acceptsQuantity')}
        label="form.fields.acceptsQuantity"
        defaultValue={true}
        disabled={infoBox}
        dependentFieldsSources={acceptsQuantityDependentSources}
        onChange={onAcceptsQuantityChange}
      />
    </div>
  )
}

export default OptionGroupSettings
