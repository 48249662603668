import ApproverOptionInput from 'components/FormComponents/Organization/ApprovalFlows/ApproverOptionInput'
import OrgApprovalFlowsReadonlyAccordion from 'components/FormComponents/Organization/ApprovalFlows/OrgApprovalFlowsReadonlyAccordion'
import KArrayInput from 'components/Inputs/KInputs/KArrayInput'
import { KBooleanInput } from 'components/Inputs/KInputs/KBooleanInput'
import KToolTip from 'components/UI/KTooltip'
import { useGetOne, useTranslate } from 'ra-core'
import React, { useMemo } from 'react'
import {
  BooleanField,
  Labeled,
  NumberField,
  SimpleFormIterator
} from 'react-admin'
import { useWatch } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { APIOrganization } from 'services/api/entities/crm/organizations/types/api/response-models'
import { emailApprovalTypeChoicesMember } from 'services/cfg/form'

const MembershipApprovalFlowSection: React.FC = () => {
  const organizationId = parseInt(useParams().id!)
  const translate = useTranslate()
  const hasApprovalDisabled = useWatch({ name: 'disableApprovalFlow' })

  const { data: organization, isLoading } = useGetOne<APIOrganization>(
    'organization',
    {
      id: organizationId
    }
  )

  const orgHasApprovalEnabled = useMemo(
    () => !isLoading && !!organization?.approvalEnabled,
    [organization, isLoading]
  )
  const orgHasCustomApprovalFlow = useMemo(
    () => !isLoading && !!organization?.customApprovalFlow,
    [organization, isLoading]
  )

  return (
    <div>
      <h2>{translate('form.headers.approvalFlow')}</h2>
      <div className="flex gap-x-6">
        <Labeled label="form.fields.approvalEnabled">
          <BooleanField record={organization} source="approvalEnabled" />
        </Labeled>

        <Labeled label="form.fields.customApprovalFlow">
          <BooleanField record={organization} source="customApprovalFlow" />
        </Labeled>

        <Labeled label="form.fields.approvalThreshold">
          <NumberField record={organization} source="approvalThreshold" />
        </Labeled>

        {orgHasCustomApprovalFlow && (
          <KToolTip title="form.tooltips.disableApprovalFlow" placement="top">
            <KBooleanInput
              source="disableApprovalFlow"
              label="form.fields.disableApprovalFlow"
            />
          </KToolTip>
        )}
      </div>

      {orgHasCustomApprovalFlow && (
        <OrgApprovalFlowsReadonlyAccordion organization={organization} />
      )}

      {orgHasApprovalEnabled && !hasApprovalDisabled && (
        <KArrayInput
          source="approvalSteps"
          label={translate('form.fields.approvalSteps')}
        >
          <SimpleFormIterator>
            <ApproverOptionInput
              approvalChoices={emailApprovalTypeChoicesMember}
            />
          </SimpleFormIterator>
        </KArrayInput>
      )}
    </div>
  )
}

export default MembershipApprovalFlowSection
