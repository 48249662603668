import React from 'react'
import { required } from 'react-admin'
import { facetFilterGroupChoices, pricingFacetChoices } from 'services/cfg/form'
import LocalizedTextInput from 'components/Inputs/Localized/LocalizedTextInput'
import KSelectInput from 'components/Inputs/KInputs/KSelectInput'
import { KBooleanInput } from 'components/Inputs/KInputs/KBooleanInput'
import { FacetCategory } from '@kampaay/common'

export type FacetFormExtension = React.FC<{ disable: boolean }>

const productFilterFields: FacetFormExtension = () => (
  <React.Fragment>
    <KSelectInput
      choices={facetFilterGroupChoices}
      source="filterGroup"
      label="form.fields.filterGroup"
      fullWidth
      validate={required()}
    />
    <LocalizedTextInput
      source="filterName"
      label="form.fields.filterName"
      validate={required()}
    />
    <KBooleanInput source="isTag" label="form.fields.isTag" />
  </React.Fragment>
)

const tagFields: FacetFormExtension = () => (
  <LocalizedTextInput
    source="tagName"
    label="form.fields.tagName"
    validate={required()}
  />
)

const pricingFields: FacetFormExtension = () => (
  <KSelectInput
    source="pricingType"
    choices={pricingFacetChoices}
    validate={required()}
  />
)

const groupFields: FacetFormExtension = () => (
  <LocalizedTextInput
    source="productClusterCode"
    label="form.fields.productClusterCode"
    validate={required()}
  />
)
const FACET_FORM_MAP: Record<FacetCategory, FacetFormExtension> = {
  productFilter: productFilterFields,
  filterTag: productFilterFields,
  tag: tagFields,
  group: groupFields,
  pricing: pricingFields
}

export default FACET_FORM_MAP
