import React, { useCallback } from 'react'
import { useTranslate } from 'react-admin'
import { useKRecordContext } from 'hooks/useKRecordContext'
import { MappedEnrichedEvent } from 'services/api/entities/events/types/internal/event-models'
import { RAFormFC } from 'types/common'
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material'
import FlexBox from 'components/UI/layout/FlexBox'
import SettingsIcon from '@mui/icons-material/Settings'
import { useNavigate } from 'react-router-dom'
import { useEventForm } from 'hooks/events/useEventForm'
import EventServiceSummaryTableRow from 'resources/operations/events/EventsComponents/EventServicesSummary/EventServiceSummaryTableRow'

type Props = {
  isGeoareaSelected: boolean
}

const tableCellStyles = {
  fontWeight: 'bold'
}

const EventServicesSummary: RAFormFC<Props> = ({ isGeoareaSelected }) => {
  const record = useKRecordContext<MappedEnrichedEvent>()

  const navigate = useNavigate()
  const translate = useTranslate()

  const { summaries, hasIntolerances, logistics, hasSelections } =
    useEventForm()

  const handleManageButton = useCallback(() => {
    summaries.length > 0
      ? navigate(summaries[0].serviceTabPath)
      : navigate('physicals')
  }, [summaries])

  return (
    <Box sx={{ width: '100%' }}>
      <FlexBox justifyContent="space-between">
        <h3>{translate('common.label.services')}</h3>
        <Button
          endIcon={<SettingsIcon />}
          onClick={handleManageButton}
          disabled={!isGeoareaSelected}
        >
          {translate('common.label.manage')}
        </Button>
      </FlexBox>

      {/* Not showing the table if the event does not have any service customization or selection */}
      {hasSelections && (
        <>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={tableCellStyles}>
                  {translate('form.fields.service')}
                </TableCell>
                <TableCell sx={tableCellStyles}>
                  {translate('form.fields.selections')}
                </TableCell>
                <TableCell sx={tableCellStyles}>
                  {translate('form.fields.supplier')}
                </TableCell>
                <TableCell sx={tableCellStyles}>
                  {translate('form.fields.triggerSupplierEmail')}
                </TableCell>
                <TableCell sx={tableCellStyles}>
                  {translate('form.fields.customizations')}
                </TableCell>
                <TableCell sx={tableCellStyles}>
                  {translate('form.fields.upsellingItems')}
                </TableCell>
                <TableCell sx={tableCellStyles}>
                  {translate('form.fields.total')}
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {summaries.map((summary) => (
                <EventServiceSummaryTableRow
                  summary={summary}
                  eventId={record?.id}
                  hasIntolerances={hasIntolerances}
                  logistics={logistics}
                  key={summary.id}
                />
              ))}
            </TableBody>
          </Table>
        </>
      )}
    </Box>
  )
}
export default EventServicesSummary
