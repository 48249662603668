import React from 'react'
import KDivider from 'components/UI/KDivider'
import CustomReferenceInput from 'components/Inputs/CustomReferenceInput'
import LocalizedSelectInput from 'components/Inputs/Localized/LocalizedSelectInput'
import MainProductFormContent, {
  MainProductFormContentProps
} from 'components/Forms/MainProductFormContent'
import HighlightsInput from 'components/Inputs/HighlightsInput'
import BookingTimeLimitInput from 'components/Inputs/BookingTimeLimitInput'
import BeforeAfterHintFormContent from 'components/FormComponents/Hints/TimeBeforeAfterHint/BeforeAfterHintFormContent'
import QuantityHintFormContent from 'components/FormComponents/Hints/QuantityHint/QuantityHintFormContent'
import LinkedRulesField from 'components/Fields/LinkedRulesField'
import ProfessionalByRulePricingInput from 'components/FormComponents/pricing/byRules/ProfessionalByRulePricingInput'
import SuppliersAutocompleteArrayInput from 'components/Inputs/SuppliersAutocompleteArrayInput'

type ProfessionalFigureFormContentProps = MainProductFormContentProps

const ProfessionalFiguresFormContent: React.FC<
  ProfessionalFigureFormContentProps
> = (props) => (
  <MainProductFormContent {...props} disableTailoredValidation>
    <CustomReferenceInput entity="professionalsections">
      <LocalizedSelectInput
        source="section"
        label="Professional Section"
        fullWidth
      />
    </CustomReferenceInput>
    <BookingTimeLimitInput />
    <SuppliersAutocompleteArrayInput />
    <HighlightsInput />
    <ProfessionalByRulePricingInput />
    <KDivider title="Suggestions" headingLevel="h1" />
    <QuantityHintFormContent
      accordionText={[
        'quantityProfessionalsHintAccordionText1',
        'quantityProfessionalsHintAccordionText2',
        'quantityProfessionalsHintAccordionText3',
        'quantityProfessionalsHintAccordionText4'
      ]}
    />
    <BeforeAfterHintFormContent
      accordionText={[
        'beforeAfterHintAccordionText1',
        'beforeAfterHintAccordionText2',
        'beforeAfterHintAccordionText3'
      ]}
    />
    <LinkedRulesField />
  </MainProductFormContent>
)

export default ProfessionalFiguresFormContent
