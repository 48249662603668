import React from 'react'
import { RAFormFC } from 'types/common'
import { useForm } from 'hooks/useForm'
import DayMonthInput from 'components/Inputs/DayMonthInput'
import { PricingRuleSet } from '@kampaay/common'

const DatePeriodInput: RAFormFC = ({ source }) => {
  const { getSource } = useForm<PricingRuleSet>(source)

  return (
    <>
      <DayMonthInput
        source={getSource('periodFrom')}
        label="form.fields.periodFrom"
        style={{ marginBottom: '16px' }}
      />
      <DayMonthInput
        source={getSource('periodTo')}
        label="form.fields.periodTo"
        style={{ marginBottom: '32px' }}
      />
    </>
  )
}

export default DatePeriodInput
