import {
  UseValidationSchema,
  yupIgnore,
  yupNumber,
  yupObject,
  yupString
} from 'services/yup'
import { EventPlaceholderCategory } from 'services/api/entities/event-placeholder-category/types'

const useEventPlaceholderCategoryVS: UseValidationSchema<
  EventPlaceholderCategory
> = () =>
  yupObject().shape({
    id: yupIgnore(),
    name: yupString(),
    color: yupString(),
    organizationId: yupNumber()
  })

export default useEventPlaceholderCategoryVS
