import React from 'react'
import SectionWrapper from 'components/UI/SectionWrapper'
import { KSimpleForm } from 'components/Forms/KForms/KSimpleForm'
import CustomerFormContent from 'components/FormComponents/customerAndSupplierForms/CustomerFormContent'
import KEdit from 'components/Forms/KEdit/KEdit'

const CustomersEdit: React.FC = () => {
  return (
    <SectionWrapper>
      <KEdit>
        <KSimpleForm>
          <CustomerFormContent />
        </KSimpleForm>
      </KEdit>
    </SectionWrapper>
  )
}

export default CustomersEdit
