import React from 'react'
import { KSimpleForm } from 'components/Forms/KForms/KSimpleForm'
import OrganizationSubGroupFormContent from 'components/Forms/OrganizationSubGroupFormContent'
import SectionWrapper from 'components/UI/SectionWrapper'
import { useParams } from 'react-router-dom'
import KEdit from 'components/Forms/KEdit/KEdit'
import BackButton from 'components/Buttons/BackButton'
import OrganizationMenuWrapper from 'components/FormComponents/Organization/OrganizationMenuWrapper'

const OrganizationSubGroupEdit: React.FC = () => {
  const { id: organizationId, subGroupId } = useParams()

  return (
    <SectionWrapper title="resources.organization.editSubGroup">
      <KEdit resource="organizationsubgroup" id={subGroupId}>
        <KSimpleForm
          redirectAfterDelete={() =>
            `organization/${organizationId}/sub-groups`
          }
        >
          <OrganizationMenuWrapper>
            <BackButton
              redirectPath={`/organization/${organizationId}/sub-groups`}
            />
            <OrganizationSubGroupFormContent />
          </OrganizationMenuWrapper>
        </KSimpleForm>
      </KEdit>
    </SectionWrapper>
  )
}

export default OrganizationSubGroupEdit
