import React from 'react'
import LocalizedSelectArrayInput from 'components/Inputs/Localized/LocalizedSelectArrayInput'
import useFacets from 'hooks/useFacets'
import { FacetSelectComponentProps } from 'services/utils'
import {
  FacetFilterGroup,
  MultiSelectableFacetsCategory
} from '@kampaay/common'

type MultiPickListFacetCategory = MultiSelectableFacetsCategory
type MultiPickListFilterGroup = Extract<
  FacetFilterGroup,
  'Setting' | 'EventType' | 'Language'
>

type Props = FacetSelectComponentProps<
  MultiPickListFacetCategory,
  MultiPickListFilterGroup
>

const FacetMultiSelectInput: React.FC<Props> = ({
  facetCategory,
  source,
  filterGroup
}) => {
  const facets = useFacets(facetCategory, filterGroup)
  if (!facets.length) return null

  return (
    <LocalizedSelectArrayInput
      label={`form.fields.${source}`}
      optionText="description"
      fullWidth
      source={source}
      choices={facets}
    />
  )
}

export default FacetMultiSelectInput
