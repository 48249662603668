import type { APIOrganizationBudget } from './types/api/response-models'
import type { OrganizationBudget } from './types/internal/budget-models'

export const organizationBudgetParse = ({
  startDate,
  endDate,
  ...rest
}: APIOrganizationBudget): OrganizationBudget => {
  return {
    ...rest,
    startDate: new Date(startDate),
    endDate: new Date(endDate)
  }
}
