import { KSimpleForm } from 'components/Forms/KForms/KSimpleForm'
import OrganizationMenuWrapper from 'components/FormComponents/Organization/OrganizationMenuWrapper'
import React, { PropsWithChildren } from 'react'
import SectionWrapper from 'components/UI/SectionWrapper'
import NoDeleteButtonToolbar from 'components/Toolbars/NoDeleteButtonToolbar'
import KEdit from 'components/Forms/KEdit/KEdit'

const BaseTailoringsEdit: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <SectionWrapper>
      <KEdit>
        <KSimpleForm toolbar={<NoDeleteButtonToolbar />}>
          <OrganizationMenuWrapper>{children}</OrganizationMenuWrapper>
        </KSimpleForm>
      </KEdit>
    </SectionWrapper>
  )
}

export default BaseTailoringsEdit
