import type { APICfg } from 'services/api/api'
import { APIEntity } from '..'
import { GetListParams } from 'react-admin'
import { callApi, getPath } from 'services/api/dataProvider/common'
import { KConsole } from 'services/console'
import { FILTER_SOURCES, emailMessageParse } from '@kampaay/common'

const getList = async (resource: string, params: GetListParams) => {
  KConsole.log('EMAILMESSAGE - GETLIST', resource, params)
  const supplierId = params.meta?.supplierId

  const response = await callApi(
    apiCfg,
    // Email messages use the special `user` endppoint
    // There's no distinction between suppliers and customers
    getPath(
      { ...apiCfg, endpoint: `emailmessage` },
      {
        ...params,
        filter: {
          ...params.filter,
          [FILTER_SOURCES.userId.eq]: supplierId
        }
      }
    ),
    // Casting needed. If we set resource as APIEntity, TS
    // will complain because this getList's type does not overlap
    // with the one in node_modules/ra-core/dist/cjs/types.d.ts
    resource as APIEntity,
    true,
    'GET',
    undefined,
    params.meta?.headers
  )

  return {
    ...response,
    data: response?.data ?? []
  }
}

const apiCfg: APICfg = {
  endpoint: '',
  parse: emailMessageParse,
  // This entity is read-only, so we don't need to implement
  // the following method nor the APIType for it
  write: async () => undefined,
  getList
}

export default apiCfg
