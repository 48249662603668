import { styled, Typography } from '@mui/material'
import React, { ReactElement } from 'react'
import { useKTranslate } from 'hooks/useKTranslate'

export type KBannerProps = {
  text: string
  icon?: ReactElement
  style?: React.CSSProperties
  backgroundColor?: string
  textColor?: string
  className?: string
}

/**
 * A banner component that displays a text and an icon
 * @param text the text of the banner
 * @param icon the icon of the banner
 * @param backgroundColor the background color of the banner
 * @param textColor the text color of the banner
 * @param style
 */
const KBanner: React.FC<KBannerProps> = ({
  text,
  icon,
  backgroundColor,
  textColor,
  className
}) => {
  const Root = styled('div')(({ theme }: any) => ({
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    padding: '16px',
    gap: '8px',
    backgroundColor: backgroundColor ?? theme.palette.primary.errorBackground,
    color: textColor ?? theme.palette.primary.errorText,
    className
  }))
  const translatedText = useKTranslate(text)

  return (
    <Root>
      <>
        {icon}
        <Typography variant={'subtitle1'}>{translatedText}</Typography>
      </>
    </Root>
  )
}

export default KBanner
