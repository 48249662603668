import React from 'react'
import {
  EventProductStatus,
  EventStatus,
  KampaayerRole,
  LocationArrangementName,
  LocationArrangementStatus,
  LocationFeatureCategory,
  PaymentStatus,
  ProfessionalOutfit,
  ProfessionalSpecialType,
  QuestionType,
  TransactionStatus,
  VirtualSpecialType
} from 'types/form'
import { DrinkSpecialType } from 'services/api/entities/catalog/sections/drinkSections'
import { MainProductAPIEntity } from 'services/api/entities'
import {
  ActivityStatus,
  APIPriceRange,
  CheckoutQuestionType,
  DayLabel,
  EmailApprovalType,
  FacetCategory,
  FacetFilterGroup,
  MonthLabel,
  OrganizationMembershipRole,
  PricingFacetType,
  PricingRuleMode,
  ProductServiceDiscriminator,
  RuleAction,
  RuleOperator,
  RulePricingType,
  RuleTargetType,
  RuleWhat,
  ServiceDiscriminator,
  TypedChoices,
  WeekDay
} from '@kampaay/common'

export const DEFAULT_PRICE_RANGE_MAX_QUANTITY = 10000

/**
 * A: Why not just creat a constant?
 *
 * Q:
 * Some "defaultValue"s are passed by reference,
 * this cause multiple components to share the same value,
 * and mess up the form state.
 * This will prevent that.
 */
export const getStandardPricingStartup = (): APIPriceRange[] => {
  return [
    {
      variableCost: 0,
      fixedCost: 0,
      fixedPrice: 0,
      variablePrice: 0,
      maxQuantity: 10000
    }
  ]
}

export const serviceDiscriminatorArray: ServiceDiscriminator[] = [
  'DrinkConfigurator',
  'FoodConfigurator',
  'GeneralConfigurator',
  'MusicConfigurator',
  'RentConfigurator',
  'VirtualConfigurator'
]

export const ARRANGEMENT_NAMES_ARRAY: LocationArrangementName[] = [
  'classroom',
  'theater',
  'cabaret',
  'boardroom',
  'ushape',
  'standing'
]

export const weekDaysList: TypedChoices<WeekDay> = [
  { id: 'sunday', name: 'Sunday' },
  { id: 'monday', name: 'Monday' },
  { id: 'tuesday', name: 'Tuesday' },
  { id: 'wednesday', name: 'Wednesday' },
  { id: 'thursday', name: 'Thursday' },
  { id: 'friday', name: 'Friday' },
  { id: 'saturday', name: 'Saturday' }
]

export const activityStatusChoices: TypedChoices<ActivityStatus> = [
  { id: 'active', name: 'Active' },
  { id: 'inactive', name: 'Inactive' }
]

export const invoiceStatusList = [
  { id: 'missingData', name: 'Missing Data' },
  { id: 'ready', name: 'Ready' },
  { id: 'invoiced', name: 'Invoiced' },
  { id: 'notToInvoice', name: 'Not to Invoice' }
]

export const accountingStatusList = [
  { id: 'accounted', name: 'Accounted' },
  { id: 'deleted', name: 'Deleted' }
]

export const paymentStatusList: TypedChoices<PaymentStatus> = [
  { id: 'none', name: 'None' },
  { id: 'payed', name: 'Payed' },
  { id: 'expired', name: 'Expired' }
]

export const productStatusChoices: TypedChoices<EventProductStatus> = [
  { id: 'created', name: 'Created' },
  { id: 'delivered', name: 'Delivered' },
  { id: 'cancelled', name: 'Cancelled' }
]

export const eventStatusChoices: TypedChoices<EventStatus> = [
  { id: 'confirmed', name: 'Confirmed' },
  { id: 'closed', name: 'Closed' },
  { id: 'invoiced', name: 'Invoiced' },
  { id: 'draft', name: 'Draft' },
  { id: 'pendingApproval', name: 'Pending Approval' }
]

export const transactionStatusChoices: TypedChoices<TransactionStatus> = [
  { id: 'confirmed', name: 'Confirmed' },
  { id: 'draft', name: 'Draft' },
  { id: 'closed', name: 'Closed' }
]

export const professionalOutfitChoices: TypedChoices<ProfessionalOutfit> = [
  { id: 'blackShirt', name: 'Black Shirt' },
  { id: 'blackShirtApron', name: 'Black Shirt with apron' },
  { id: 'whiteShirt', name: 'White Shirt' },
  { id: 'whiteShirtApron', name: 'White Shirt with apron' }
]

export const briefQuestionChoices: TypedChoices<QuestionType> = [
  { id: 'boolean', name: 'Boolean' },
  { id: 'freeText', name: 'Text' },
  { id: 'multiple', name: 'Multiple' },
  { id: 'date', name: 'Date' },
  { id: 'time', name: 'Time' }
]

export const checkoutQuestionChoices: TypedChoices<CheckoutQuestionType> = [
  { id: 'freeText', name: 'Text' },
  { id: 'singleSelect', name: 'Single Select' },
  { id: 'multiSelect', name: 'Multi Select' }
]

export const allowedServicesChoices: TypedChoices<ProductServiceDiscriminator> =
  [
    { id: 'FoodConfigurator', name: 'Foods' },
    { id: 'RentConfigurator', name: 'Rents' },
    { id: 'MusicConfigurator', name: 'Musics' },
    { id: 'DrinkConfigurator', name: 'Drinks' },
    { id: 'VirtualConfigurator', name: 'Virtuals' },
    { id: 'PhysicalConfigurator', name: 'Physicals' },
    { id: 'LocationConfigurator', name: 'Locations' }
  ]

export const professionalSpecialTypeChoices: TypedChoices<ProfessionalSpecialType> =
  [
    { id: 'waitingStaff', name: 'Waiting Staff' },
    { id: 'cleaner', name: 'Cleaner' }
  ]

export const facetDescriptionOptions: TypedChoices<
  Exclude<FacetCategory, 'filterTag'>
> = [
  { id: 'tag', name: 'tag' },
  { id: 'productFilter', name: 'productFilter' },
  { id: 'group', name: 'group' },
  { id: 'pricing', name: 'pricing' }
]

export const pricingFacetChoices: TypedChoices<PricingFacetType> = [
  { id: 'perItem', name: 'perItem' },
  { id: 'perPerson', name: 'perPerson' }
]

export const organizationMembershipRoles: TypedChoices<OrganizationMembershipRole> =
  [
    { id: 'admin', name: 'Admin' },
    { id: 'collaborator', name: 'Collaborator' },
    { id: 'subGroupAdmin', name: 'Group Admin' }
  ]

/**
 * Get the formatted name of an organization member's role
 *
 * @param {OrganizationMembershipRole} role - The role of the organization member
 * @returns {string | undefined} - The formatted name of the role
 */
export const getOrganizationMembershipRoleName = (
  role: OrganizationMembershipRole
): string | undefined =>
  organizationMembershipRoles.find((r) => r.id === role)?.name

export const virtualExpChoices: TypedChoices<VirtualSpecialType> = [
  { id: 'virtualCatering', name: 'Virtual Catering' }
]

export const kampaayerRolesChoices: TypedChoices<KampaayerRole> = [
  { id: 'admin', name: 'Admin' },
  { id: 'marketing', name: 'Marketing' },
  { id: 'customerSuccess', name: 'Customer Success' }, // before was under "sales"
  { id: 'accountExecutive', name: 'Account Executive' }, // before was under "sales"
  { id: 'eventExcellence', name: 'Event Excellence' }, // before was "ops"
  { id: 'procurement', name: 'Procurement' },
  { id: 'finance', name: 'Finance' },
  { id: 'legal', name: 'Legal' }
]

export const locationArrangementStatusChoices: TypedChoices<LocationArrangementStatus> =
  [
    { id: 'active', name: 'Active' },
    { id: 'inactive', name: 'Inactive' },
    { id: 'hidden', name: 'Hidden' }
  ]

export const locationFeatureCategoryChoices: TypedChoices<LocationFeatureCategory> =
  [
    { id: 'characteristic', name: 'Characteristic' },
    { id: 'equipment', name: 'Equipment' }
  ]

export const facetFilterGroupChoices: TypedChoices<FacetFilterGroup> = [
  { id: 'Type', name: 'Type' },
  { id: 'Location', name: 'Location' },
  { id: 'EventType', name: 'Event type' },
  { id: 'Setting', name: 'Setting' },
  { id: 'Skill', name: 'Skill' },
  { id: 'TeamBuildingCategory', name: 'Team building category' },
  { id: 'VirtualCategory', name: 'Virtual Category' },
  { id: 'Language', name: 'Language' }
]

export const EmptyFormExt: React.FC = () => null

// RULES
export const ruleWhatChoices: TypedChoices<RuleWhat> = [
  { id: 'cart', name: 'Cart contains' },
  { id: 'selecting', name: 'User is selecting' }
]

export const ruleActionChoices: TypedChoices<RuleAction> = [
  { id: 'suggest', name: 'Suggest' },
  { id: 'incompatibility', name: 'Block selection of' }
]

export const ruleOperatorChoices: TypedChoices<RuleOperator> = [
  { id: 'and', name: 'And' },
  { id: 'or', name: 'Or' }
]

export const ruleTargetTypeChoices: TypedChoices<RuleTargetType> = [
  { id: 'product', name: 'Product' },
  { id: 'service', name: 'Service' }
]

export const serviceDiscriminatorChoices: TypedChoices<ProductServiceDiscriminator> =
  [
    { id: 'MusicConfigurator', name: 'Music' },
    { id: 'RentConfigurator', name: 'Rent' },
    { id: 'FoodConfigurator', name: 'Food' },
    { id: 'DrinkConfigurator', name: 'Drink' },
    { id: 'VirtualConfigurator', name: 'Virtual' },
    { id: 'PhysicalConfigurator', name: 'Physical' },
    { id: 'LocationConfigurator', name: 'Location' },
    { id: 'ProfessionalConfigurator', name: 'Professional' }
  ]

export const drinkSpecialTypeChoices: TypedChoices<DrinkSpecialType> = [
  { id: 'alcoholicCocktail', name: 'Alcoholic Cocktail' },
  { id: 'analcoholicCocktail', name: 'Analcoholic Cocktail' },
  { id: 'beer', name: 'Beer' },
  { id: 'wine', name: 'Wine' },
  { id: 'softDrink', name: 'Soft Drink' }
]

export const monthChoices: TypedChoices<MonthLabel> = [
  { id: '01', name: 'Jan' },
  { id: '02', name: 'Feb' },
  { id: '03', name: 'Mar' },
  { id: '04', name: 'Apr' },
  { id: '05', name: 'May' },
  { id: '06', name: 'Jun' },
  { id: '07', name: 'Jul' },
  { id: '08', name: 'Aug' },
  { id: '09', name: 'Sep' },
  { id: '10', name: 'Oct' },
  { id: '11', name: 'Nov' },
  { id: '12', name: 'Dec' }
]

export const monthDayChoices: DayLabel[] = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31'
]

export const pricingRulesModeChoices: TypedChoices<PricingRuleMode> = [
  { id: 'tpr', name: 'Time Period' },
  { id: 'nhr', name: 'Min Hours' }
]

export const pricingRulesTypeChoices: TypedChoices<RulePricingType> = [
  { id: 'fixed', name: 'Fixed' },
  { id: 'proportional', name: 'Proportional (by hour)' }
]

export const locationPricingNameChoices: TypedChoices<string> = [
  { id: 'perHour', name: 'Per hour' },
  { id: 'halfDay', name: 'Half day' },
  { id: 'fullDay', name: 'Full day' }
]

export const serviceChoices: TypedChoices<MainProductAPIEntity> = [
  { id: 'musics', name: 'Music' },
  { id: 'rents', name: 'Rent' },
  { id: 'foods', name: 'Food' },
  { id: 'drinks', name: 'Drink' },
  { id: 'virtuals', name: 'Virtual' },
  { id: 'physicals', name: 'Physical' },
  { id: 'locations', name: 'Location' },
  { id: 'professionals', name: 'Professional' }
]

export const emailApprovalTypeChoicesMember: TypedChoices<EmailApprovalType> = [
  { id: 'freeText', name: 'Free text' },
  { id: 'pickList', name: 'Pick list' }
]

export const emailApprovalTypeChoicesOrganization: TypedChoices<EmailApprovalType> =
  [...emailApprovalTypeChoicesMember, { id: 'groupAdmin', name: 'Group Admin' }]
