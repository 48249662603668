import React from 'react'
import CatalogItemBaseList, {
  CatalogItemBaseListProps
} from 'components/Lists/CatalogItemBaseList'

const FoodSectionList: React.FC<CatalogItemBaseListProps> = (props) => (
  <CatalogItemBaseList {...props} />
)

export default FoodSectionList
