import React from 'react'
import { RAFormFC } from 'types/common'
import StandardPricingInput from 'components/FormComponents/pricing/standard/StandardPricingInput'
import { useForm } from 'hooks/useForm'
import { useCatalogRepository } from 'resources/crm/clients/organization/tailoring/hooks/repositories/useCatalogRepository'
import { SimpleFormIterator } from 'react-admin'
import OptionTailoringInput from 'resources/crm/clients/organization/tailoring/components/optioned/OptionTailoringInput'
import KAccordion from 'components/UI/Accordions/KAccordion/KAccordion'
import KDivider from 'components/UI/KDivider'
import { APIEntity } from 'services/api/entities'
import KArrayInput from 'components/Inputs/KInputs/KArrayInput'
import {
  APIPriceRange,
  getById,
  OptionedProduct,
  OptionedTailoring,
  OptionItem,
  ProductTailoring,
  WithIdAndName
} from '@kampaay/common'

type OptionedProductTailoringInputProps = {
  relatedTailoredProducts?: WithIdAndName[]
  productEntity: APIEntity
}

const OptionedProductTailoringPricesInput: RAFormFC<
  OptionedProductTailoringInputProps
> = ({ source, productEntity }) => {
  const { getSource, useGetValue } = useForm<OptionedTailoring>(source)
  const hasCustomPrice = useGetValue('hasCustomPrice')

  // optioned
  const productId = useGetValue('id')
  const { products: catalogProducts } =
    useCatalogRepository<OptionedProduct>(productEntity)
  const currentFormOptions = useGetValue('options')

  const allOptions = getOptions(productId, catalogProducts)
  const filteredOptions = filterOptions(allOptions, currentFormOptions)

  //===============================================
  // FUNCTIONS
  //===============================================
  function getOptions(productId: number, products: OptionedProduct[]) {
    const fullProduct = getById(products, productId)

    const options =
      fullProduct?.options.flatMap((opt) => {
        return opt.items
      }) || []

    return options
  }

  // filter out options that are already in the form
  function filterOptions(
    options: OptionItem[],
    currentFormOptions: ProductTailoring<APIPriceRange>[]
  ) {
    const filtered = options.filter((opt) => {
      const isOptionInForm = currentFormOptions?.find((formOpt) => {
        return formOpt.id === opt.id
      })
      return !isOptionInForm
    })
    return filtered
  }

  //===============================================
  // JSX
  //===============================================
  return (
    <>
      {hasCustomPrice ? (
        <>
          <KDivider />
          <StandardPricingInput source={getSource('pricing')} />
        </>
      ) : null}

      <KDivider />
      <KAccordion accordionTitle={'form.fields.options'}>
        <KArrayInput source={getSource('options')}>
          <SimpleFormIterator>
            <OptionTailoringInput
              allOptions={allOptions}
              filteredOptions={filteredOptions}
            />
          </SimpleFormIterator>
        </KArrayInput>
      </KAccordion>
    </>
  )
}

export default OptionedProductTailoringPricesInput
