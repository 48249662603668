import { APICfg } from 'services/api/api'
import { proposalParse, proposalWrite } from '@kampaay/common'
import {
  uploadOrKeepImg,
  uploadOrKeepMultipleImgs
} from 'services/utils/images'
import { uploadOrKeepMultipleFiles } from 'services/utils/files'

const apiCfg = {
  endpoint: 'proposal',
  parse: proposalParse,
  write: proposalWrite(
    uploadOrKeepImg,
    uploadOrKeepMultipleImgs,
    uploadOrKeepMultipleFiles
  )
} as APICfg

export default apiCfg
