import React from 'react'
import { MultilangParseFunction, MultilangWriteFunction } from '@kampaay/common'
import { WithForceDisabled } from 'types/common'
import LocalizedInput from 'components/Inputs/Localized/LocalizedInput'

import KTextInput, {
  KTextInputProps
} from 'components/Inputs/KInputs/KTextInput/KTextInput'

type LocalizedTextInputProps = WithForceDisabled &
  Omit<KTextInputProps, 'disabled'> & {
    writeFunction?: MultilangWriteFunction
    parseFunction?: MultilangParseFunction
  }

const LocalizedTextInput: React.FC<LocalizedTextInputProps> = ({
  forceDisabled,
  ...rest
}) => (
  <LocalizedInput {...rest}>
    <KTextInput {...rest} disabled={forceDisabled} />
  </LocalizedInput>
)

export default LocalizedTextInput
