import React from 'react'
import { Button, DeleteButton, SaveButton, useExpanded } from 'react-admin'
import { useKResourceContext } from 'hooks/useKResourceContext'
import { Close } from '@mui/icons-material'
import { Toolbar } from 'ra-ui-materialui'

const EditableDatagridToolbar: React.FC<{
  id?: number
  setCreateVisibility: (value: boolean) => void
  allowDelete: boolean
}> = ({ id, setCreateVisibility, allowDelete, ...props }) => {
  const resource = useKResourceContext()

  // This handles the expansion panel collapse when the form is submitted or closed
  const [_, setExpanded] = useExpanded(resource, id ?? 'row0')

  return (
    <Toolbar {...props} className="w-full ">
      <SaveButton />

      {allowDelete && <DeleteButton className="ml-3" />}

      {/* Close expansion panel button */}
      <Button
        onClick={() => {
          setExpanded()

          if (!id) {
            setCreateVisibility(false)
          }
        }}
        label="button.label.close"
        startIcon={<Close />}
        className="ml-3"
      />
    </Toolbar>
  )
}

export default EditableDatagridToolbar
