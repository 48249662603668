import { SimpleFormIterator } from 'ra-ui-materialui'
import React from 'react'
import LocalizedRichTextInput from 'components/Inputs/Localized/LocalizedRichTextInput'
import KAccordion from 'components/UI/Accordions/KAccordion/KAccordion'
import KArrayInput from 'components/Inputs/KInputs/KArrayInput'

const HighlightsInput: React.FC = () => (
  <KAccordion accordionTitle="Highlights">
    <KArrayInput source="highlights" label="">
      <SimpleFormIterator>
        <LocalizedRichTextInput source="preview" label="form.fields.preview" />
        <LocalizedRichTextInput source="content" label="form.fields.content" />
      </SimpleFormIterator>
    </KArrayInput>
  </KAccordion>
)

export default HighlightsInput
