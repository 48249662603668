import { styled } from '@mui/material/styles'
import React from 'react'
import KTextInput from 'components/Inputs/KInputs/KTextInput/KTextInput'

const StyledKTextInput = styled(KTextInput)({
  width: '256px'
})

type VatCodeInputProps = {
  disabled?: boolean
}

const VatCodeInput = ({ disabled }: VatCodeInputProps): JSX.Element => {
  return (
    <StyledKTextInput
      source="vat"
      label="form.fields.vat"
      disabled={disabled}
    />
  )
}

export default VatCodeInput
