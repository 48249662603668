import React from 'react'
import CatalogOptionsInput from 'components/FormComponents/CatalogOptionsInput'
import StandardPricingInput from 'components/FormComponents/pricing/standard/StandardPricingInput'
import UpsellingSelector, {
  UpsellingSelectorProps
} from 'components/FormComponents/UpsellingSelector'
import BookingTimeLimitInput from 'components/Inputs/BookingTimeLimitInput'
import HighlightsInput from 'components/Inputs/HighlightsInput'
import MainProductFormContent, {
  MainProductFormContentProps
} from 'components/Forms/MainProductFormContent'
import KNumberInput from 'components/Inputs/KInputs/KNumberInput/KNumberInput'
import SuppliersAutocompleteArrayInput from 'components/Inputs/SuppliersAutocompleteArrayInput'

export type OptionedProductFormContentProps = MainProductFormContentProps &
  UpsellingSelectorProps & {
    slotBeforeHighlights?: React.ReactElement
  }

const OptionedProductFormContent: React.FC<OptionedProductFormContentProps> = ({
  upsellingEntity,
  children,
  slotBeforeHighlights,
  ...props
}) => (
  <MainProductFormContent {...props}>
    <UpsellingSelector upsellingEntity={upsellingEntity} />
    <KNumberInput
      source="minQuantity"
      label="form.fields.minQuantity"
      fullWidth
    />
    <BookingTimeLimitInput />
    {slotBeforeHighlights}
    <SuppliersAutocompleteArrayInput />
    <HighlightsInput />
    <CatalogOptionsInput />
    <StandardPricingInput />
    {children}
  </MainProductFormContent>
)

export default OptionedProductFormContent
