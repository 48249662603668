import LabeledBooleanField from 'components/Fields/LabeledFields/LabeledBooleanField'
import EventServiceBriefSection from 'components/FormComponents/EventServiceBriefSection'
import KDivider from 'components/UI/KDivider'
import { useForm } from 'hooks/useForm'
import React from 'react'
import { APIEntity } from 'services/api/entities'
import { MappedEnrichedEvent } from 'services/api/entities/events/types/internal/event-models'
import { NamedService, RAFormFC } from 'types/common'
import EventCustomizationArrayInputAccordion from 'components/FormComponents/Customizations/EventCustomizationArrayInputAccordion'
import { useEventService } from 'hooks/events/useEventService'

type Props = {
  experience: Extract<APIEntity, 'virtuals' | 'physicals'>
  experienceService: Extract<NamedService, 'physicalService' | 'virtualService'>
  children?: React.ReactNode
}

const ExperienceFormTabInputs: RAFormFC<Props> = ({
  experience,
  experienceService,
  source
}) => {
  const { notifyServiceSupplier, isNotifySupplierDisabled } =
    useEventService(experience)
  const { useGetValue } = useForm<MappedEnrichedEvent>(source)

  const productServices = useGetValue('productServices')

  const service = productServices?.[experienceService]

  return (
    <>
      <div
        style={{
          width: '100%'
        }}
      >
        <KDivider />
        <EventCustomizationArrayInputAccordion
          source={`productServices.${experienceService}`}
          maxCustomizations={1}
        />
      </div>
      {!!service?.briefQuestions && !!service?.briefQuestions.length && (
        <>
          <KDivider title="Event Brief" headingLevel="h3" />
          <LabeledBooleanField
            source={`productServices.${experienceService}.briefConfirmed`}
            label="form.fields.briefConfirmation"
          />
          <EventServiceBriefSection
            serviceSource={`productServices.${experienceService}`}
            notifyServiceSupplier={notifyServiceSupplier}
            isNotifySupplierDisabled={isNotifySupplierDisabled}
          />
        </>
      )}
    </>
  )
}

export default ExperienceFormTabInputs
