import React, { useMemo, useState } from 'react'
import { Tab, Tabs, TextField } from '@mui/material'
import ConciergeOkrsTab from 'okrs/concierge-okrs/ConciergeOkrsTab'
import SupplierAssignationOkrsTab from 'okrs/supplier-assignation-okrs/SupplierAssignationOkrsTab'
import { useKGetList } from 'hooks/useKGetList'
import { Proposal, getCountry } from '@kampaay/common'

export type OKREnrichedProposal = Proposal & {
  country: string
}

const OkrsPage: React.FC = () => {
  const [value, setValue] = React.useState(0)
  const [rangeStart, setRangeStart] = useState<string>('2023-10-01')
  const [rangeEnd, setRangeEnd] = useState<string>('2023-12-31')

  const handleChange = (_e: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const { list: italianProposals } = useKGetList<Proposal>('proposal', {
    filter: { 'isPublished~eq~': 'true' },
    sort: { field: 'id', order: 'ASC' },
    meta: { headers: { 'x-kampaay-country': getCountry() === 1 ? '1' : '2' } }
  })

  const { list: swedishProposals } = useKGetList<Proposal>('proposal', {
    filter: { 'isPublished~eq~': 'true' },
    sort: { field: 'id', order: 'DESC' },
    meta: { headers: { 'x-kampaay-country': getCountry() === 1 ? '2' : '1' } }
  })

  const conciergeProposals = useMemo(
    () =>
      [
        ...(italianProposals ?? []).map((p) => ({ ...p, country: 'Italy' })),
        ...swedishProposals.map((p) => ({ ...p, country: 'Sweden' }))
      ].filter((p) => !!p.conciergeId),
    [italianProposals, swedishProposals]
  )

  return (
    <div>
      <TextField
        className="mr-4"
        type="date"
        value={rangeStart}
        label="Date from"
        onChange={(e) => setRangeStart(e.target.value)}
      />
      <TextField
        type="date"
        value={rangeEnd}
        label="Date to"
        onChange={(e) => setRangeEnd(e.target.value)}
      />
      <Tabs value={value} onChange={handleChange}>
        <Tab label="Okr 1.2 & 1.5" />
        <Tab label="Okr 1.10" />
        <Tab label="Okr 1.11" />
      </Tabs>
      <ConciergeOkrsTab
        tabIndex={0}
        tabValue={value}
        rangeEnd={rangeEnd}
        rangeStart={rangeStart}
      />

      <SupplierAssignationOkrsTab
        tabIndex={2}
        tabValue={value}
        rangeEnd={rangeEnd}
        rangeStart={rangeStart}
        conciergeProposals={conciergeProposals}
      />
    </div>
  )
}

export default OkrsPage
