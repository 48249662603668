import KToolTip from 'components/UI/KTooltip'
import { useEventApprovalStatus } from 'hooks/useEventApprovalStatus'
import React from 'react'
import { TextField } from 'react-admin'

type Props = {
  label: string
  source: string
}

const EventListStatusField: React.FC<Props> = (props) => {
  const approvalStatus = useEventApprovalStatus()

  if (!approvalStatus) return null

  const { showTooltip, tooltipLabel } = approvalStatus

  return (
    <KToolTip title={tooltipLabel} disabled={!showTooltip} placement="top">
      <TextField {...props} />
    </KToolTip>
  )
}

export default EventListStatusField
