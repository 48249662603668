import { createMockBuilder } from '../../../../utils'
import type { ProposalItemPresentationSettings } from '../types'

const buildBaseMock = (): ProposalItemPresentationSettings => ({
  images: [],
  layout: 'standard',
  showDescription: false,
  showImages: false,
  showSupplier: false,
  supplierImages: []
})

export const buildProposalItemPresentationSettingsMock =
  createMockBuilder(buildBaseMock)
