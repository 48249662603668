import React from 'react'
import { RAFormFC } from 'types/common'
import { useForm } from 'hooks/useForm'
import { useCatalogRepository } from 'resources/crm/clients/organization/tailoring/hooks/repositories/useCatalogRepository'
import { SimpleFormIterator } from 'react-admin'
import KDivider from 'components/UI/KDivider'
import { APIEntity } from 'services/api/entities'
import {
  Drink,
  DrinkVariant
} from 'services/api/entities/catalog/drink/types/internal/drink-models'
import VariantTailoringInput from 'resources/crm/clients/organization/tailoring/components/variant/VariantTailoringInput'
import KArrayInput from 'components/Inputs/KInputs/KArrayInput'
import {
  APIPriceRange,
  DrinkTailoring,
  getById,
  ProductTailoring,
  WithIdAndName
} from '@kampaay/common'

type VariantTailoringInputProps = {
  relatedTailoredProducts?: WithIdAndName[]
  productEntity: APIEntity
}

const VariantTailoringsInput: RAFormFC<VariantTailoringInputProps> = ({
  source,
  productEntity
}) => {
  const { getSource, useGetValue } = useForm<DrinkTailoring>(source)
  const productId = useGetValue('id')
  const currentFormVariants = useGetValue('variants')

  // variants
  const { products: catalogProducts } =
    useCatalogRepository<Drink>(productEntity)

  const allVariants = getVariants(productId, catalogProducts)
  const filteredVariants = filterVariants(allVariants, currentFormVariants)

  //===============================================
  // FUNCTIONS
  //===============================================
  function getVariants(productId: number, products: Drink[]) {
    const fullProduct = getById(products, productId)
    const variants = fullProduct?.variants || []

    return variants
  }

  // filter out variants that are already in the form
  function filterVariants(
    variants: DrinkVariant[],
    currentFormVariants: ProductTailoring<APIPriceRange>[]
  ) {
    const filtered = variants.filter((opt) => {
      const isOptionInForm = currentFormVariants?.find((formVar) => {
        return formVar.id === opt.id
      })
      return !isOptionInForm
    })

    return filtered
  }

  //===============================================
  // JSX
  //===============================================
  return (
    <>
      <KDivider />
      <KArrayInput source={getSource('variants')}>
        <SimpleFormIterator>
          <VariantTailoringInput
            allVariants={allVariants}
            filteredVariants={filteredVariants}
          />
        </SimpleFormIterator>
      </KArrayInput>
    </>
  )
}

export default VariantTailoringsInput
