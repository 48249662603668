import React from 'react'
import { translate } from 'services/i18n'
import { UpsellingSelection } from 'services/api/entities/events/types/internal/selections'
import { parseMultilangOrFallbackEng } from '@kampaay/common'

type UpsellingSectionProps = {
  upsellings: UpsellingSelection[]
}

const UpsellingSection: React.FC<UpsellingSectionProps> = ({ upsellings }) => {
  return (
    <>
      <p>{translate('form.fields.selectedExtras')}: </p>
      <ul style={{ paddingLeft: '60px' }}>
        {upsellings.map((upselling, idx) => (
          <li
            data-testid="upselling-section--name"
            key={`${upselling.id}-${upselling.name}-${idx}`}
          >
            <p>{`${parseMultilangOrFallbackEng(upselling.name)}: ${
              upselling.quantity
            }`}</p>
          </li>
        ))}
      </ul>
    </>
  )
}

export default UpsellingSection
