import { findSingleFacet, getId } from '../../../../../utils'
import { parseFacet, type PricingFacet } from '../../facets'
import { parseQuantityHint } from '../hints'
import { productCommonParse } from '../products'
import type { APIUpselling, Upselling } from './types'

export const upsellingParse = (res: APIUpselling): Upselling => {
  const facets = res.facets.map(parseFacet)
  const pricingFacet = findSingleFacet(facets, 'pricing')
  const groupFacet = findSingleFacet(facets, 'group')

  return {
    ...res,
    ...productCommonParse(res),
    ...parseQuantityHint(res),
    pricingFacet,
    groupFacet,
    isPerItem: facets.some(
      (f) => (f as PricingFacet).pricingType === 'perItem'
    ),
    products: res.products?.map(getId) ?? []
  }
}
