import { GroupWork } from '@mui/icons-material'
import PhysicalExperienceUpsellingList from 'resources/catalog/physicalexperiences/upselling/PhysicalExperienceUpsellingList'
import PhysicalExperienceUpsellingEdit from 'resources/catalog/physicalexperiences/upselling/PhysicalExperienceUpsellingEdit'
import PhysicalExperienceUpsellingCreate from 'resources/catalog/physicalexperiences/upselling/PhysicalExperienceUpsellingCreate'

const physicalUpsellingIndex = {
  icon: GroupWork,
  list: PhysicalExperienceUpsellingList,
  edit: PhysicalExperienceUpsellingEdit,
  create: PhysicalExperienceUpsellingCreate
}

export default physicalUpsellingIndex
