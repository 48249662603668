import { UseValidationSchema, yupArray, yupString } from 'services/yup'
import { ProfessionalSection } from 'services/api/entities/catalog/sections/professionalSections'
import { useProductSectionVS } from 'services/validations/catalog/sections/productSection'
import { ProfessionalSpecialType } from 'types/form'

export const useProfessionalSectionVS: UseValidationSchema<
  ProfessionalSection
> = () =>
  useProductSectionVS().shape({
    productsFull: yupArray(),
    specialType: yupString<ProfessionalSpecialType>()
  })
