import React from 'react'
import SectionWrapper from 'components/UI/SectionWrapper'
import KList from 'components/Lists/KLists/KList'
import KPagination from 'components/Lists/KPagination'
import {
  BooleanField,
  ChipField,
  CreateButton,
  Datagrid,
  FunctionField,
  ReferenceField,
  TextField
} from 'react-admin'
import { useParams } from 'react-router-dom'
import { FILTER_SOURCES, OrganizationMembership } from '@kampaay/common'
import OrganizationMenuWrapper from 'components/FormComponents/Organization/OrganizationMenuWrapper'
import { getOrganizationMembershipRoleName } from 'services/cfg/form'
import OrganizationMembershipExpandable from 'components/FormComponents/Organization/Membership/OrganizationMembershipExpandable'

const OrganizationMembershipList: React.FC = () => {
  const { id } = useParams()

  return (
    <SectionWrapper>
      <KList
        resource="organizationmembership"
        exporter={false}
        pagination={<KPagination />}
        perPage={40}
        sort={{ field: 'id', order: 'ASC' }}
        actions={
          <CreateButton
            label="form.fields.addMember"
            to={`/organization/${id}/members/create`}
          />
        }
        filter={{ [FILTER_SOURCES.organizationId.eq]: id }}
        empty={false}
      >
        <OrganizationMenuWrapper hasPadding>
          <Datagrid
            rowClick="expand"
            expand={<OrganizationMembershipExpandable />}
          >
            <TextField source="userEmail" label="form.fields.email" />
            <FunctionField
              label="form.fields.role"
              sortable={false}
              render={(record?: OrganizationMembership) => (
                <TextField
                  record={{
                    role: record
                      ? getOrganizationMembershipRoleName(record.role)
                      : undefined
                  }}
                  source="role"
                />
              )}
            />
            <ReferenceField
              reference="organizationsubgroup"
              source="subGroupId"
              label="form.fields.subGroup"
              sortable={false}
            >
              <TextField source="name" />
            </ReferenceField>
            <FunctionField
              label="form.fields.budgets"
              render={(record?: OrganizationMembership) => (
                <ChipField
                  record={{
                    activeBudgets: record?.activeBudgetIds?.length ?? 0
                  }}
                  source="activeBudgets"
                />
              )}
            />
            <BooleanField
              source="approvalRequired"
              label="form.fields.approvalRequired"
              sortable={false}
            />
          </Datagrid>
        </OrganizationMenuWrapper>
      </KList>
    </SectionWrapper>
  )
}

export default OrganizationMembershipList
