import React from 'react'
import TimePicker from 'components/Inputs/TimePicker'
import EventMainProductSelectionInput from 'components/FormComponents/EventMainProductSelectionInput'
import { SelectionDetailsProps } from 'components/FormComponents/MainProductSelectionInputContent'
import {
  filterByTailored,
  useCustomerIdAndOrganizationFromEvent
} from 'components/FormComponents/TailoredEventSelectionInput'
import { EventServiceBase } from 'services/api/entities/events/types/internal/services'
import { ProfessionalSection } from 'services/api/entities/catalog/sections/professionalSections'
import { RAFormFC } from 'types/common'
import {
  APIProfessionalFigure,
  ProfessionalFigure,
  professionalFigureParse
} from '@kampaay/common'

type Props = SelectionDetailsProps<ProfessionalSection, EventServiceBase> & {
  professionals: APIProfessionalFigure[]
}

const useTailoredProfessionalFilter = (
  p: APIProfessionalFigure[]
): ProfessionalFigure[] => {
  const customerIdAndOrg = useCustomerIdAndOrganizationFromEvent()

  const filterProfessionalsFn = filterByTailored({
    organizationId: customerIdAndOrg.organization?.id,
    customerId: customerIdAndOrg.customerId
  })

  return p.map(professionalFigureParse).filter(filterProfessionalsFn)
}

const ProfessionalFigureArrayInput: RAFormFC<Props> = ({
  getSource,
  isObsolete,
  professionals,
  ...rest
}) => {
  const selectableProfessionals = useTailoredProfessionalFilter(professionals)

  return (
    <EventMainProductSelectionInput
      {...rest}
      source={getSource!('selections')}
      label="Professional Figures"
      choices={selectableProfessionals}
      disableInputs={isObsolete}
    >
      <ProfessionalFigureTimeRangePicker />
    </EventMainProductSelectionInput>
  )
}

export default ProfessionalFigureArrayInput

const ProfessionalFigureTimeRangePicker: React.FC<
  SelectionDetailsProps<APIProfessionalFigure, EventServiceBase>
> = ({ getSource, isObsolete, ...rest }) => (
  <>
    <TimePicker
      {...rest}
      disabled={isObsolete}
      source={getSource!('startTime')}
      label="form.fields.startTime"
    />
    <TimePicker
      {...rest}
      disabled={isObsolete}
      source={getSource!('endTime')}
      label="form.fields.endTime"
    />
  </>
)
