import React from 'react'
import SectionWrapper from 'components/UI/SectionWrapper'
import KCreate from 'components/Forms/KCreate/KCreate'
import { KSimpleForm } from 'components/Forms/KForms/KSimpleForm'
import { useParams } from 'react-router-dom'
import OrganizationMembershipFormContent from 'components/Forms/OrganizationMembershipFormContent'

const OrganizationMembershipCreate: React.FC = () => {
  const { id: organizationId } = useParams()

  return (
    <SectionWrapper title="resources.organization.createMember">
      <KCreate resource="organizationmembership">
        <KSimpleForm
          redirectAfterCreate={(_, id) =>
            `organization/${organizationId}/members/${id}`
          }
        >
          <OrganizationMembershipFormContent />
        </KSimpleForm>
      </KCreate>
    </SectionWrapper>
  )
}

export default OrganizationMembershipCreate
