import React from 'react'
import SectionWrapper from 'components/UI/SectionWrapper'
import EditableList from 'components/Lists/EditableList/EditableList'
import MailingListFormContent from 'resources/mailing-list/MailingListFormContent'
import KTextField from 'components/Fields/KTextField'
import KPagination from 'components/Lists/KPagination'
import { FunctionField } from 'react-admin'
import { MailingList } from 'services/api/entities/mailing-list/types'
import KChip from 'components/UI/KChip'

const MailingListList: React.FC = () => {
  return (
    <SectionWrapper title="resources.mailingList.title">
      <EditableList
        actions={<></>}
        allowDelete={false}
        bulkActionButtons={false}
        formContent={<MailingListFormContent />}
        hasBulkActions={false}
        pagination={<KPagination />}
        perPage={40}
      >
        <KTextField source="name" label="form.fields.name" />
        <KTextField source="description" label="form.fields.description" />
        <FunctionField
          label="form.fields.addresses"
          render={(record: MailingList) => (
            <KChip label={record.addresses.length} />
          )}
        />
      </EditableList>
    </SectionWrapper>
  )
}

export default MailingListList
