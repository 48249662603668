import { styled } from '@mui/material'
import { RaRecord } from 'ra-core'
import React from 'react'
import { SimpleFormIterator } from 'react-admin'
import { briefQuestionChoices } from 'services/cfg/form'
import KToolTip from 'components/UI/KTooltip'
import LocalizedTextInput from 'components/Inputs/Localized/LocalizedTextInput'
import KSelectInput from 'components/Inputs/KInputs/KSelectInput'
import BriefServiceInput from 'components/FormComponents/Brief/BriefServiceInput'
import { KBooleanInput } from 'components/Inputs/KInputs/KBooleanInput'
import {
  BriefQuestion,
  GeneralBriefQuestion
} from 'services/api/entities/services-config/types/internal/question-models'
import LocalizedSelectArrayInput from 'components/Inputs/Localized/LocalizedSelectArrayInput'
import { RAFormFC } from 'types/common'
import { useForm } from 'hooks/useForm'
import KArrayInput from 'components/Inputs/KInputs/KArrayInput'

const PREFIX = 'BriefQuestionInput'

const classes = {
  column: `${PREFIX}-column`
}

const StyledDiv = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%'
})

type Props = {
  isGeneralQuestion?: boolean
  productChoices?: RaRecord[]
}

const BriefQuestionInput: RAFormFC<Props> = ({
  source,
  isGeneralQuestion = false,
  productChoices = []
}) => {
  const { getSource, useGetValue } = useForm<
    GeneralBriefQuestion | BriefQuestion
  >(source)

  const questionType = useGetValue('type')

  return (
    <StyledDiv className={classes.column}>
      <KToolTip title="form.fields.briefNameTooltip" placement="top-start">
        <LocalizedTextInput
          fullWidth
          source={getSource('text')}
          label="form.fields.question"
        />
      </KToolTip>
      <KSelectInput
        style={{ width: '10%' }}
        source={getSource('type')}
        label="Type"
        choices={briefQuestionChoices}
        defaultValue={briefQuestionChoices[0].id}
      />
      <KBooleanInput source={getSource('mandatory')} label="Is mandatory" />
      {questionType === 'multiple' && (
        <KArrayInput
          source={getSource('options')}
          style={{ padding: '16px 0' }}
          label="Options"
        >
          <SimpleFormIterator>
            <LocalizedTextInput source="" label="form.fields.option" />
          </SimpleFormIterator>
        </KArrayInput>
      )}
      <KToolTip
        title="form.fields.activeWhenNotServiceTooltip"
        placement="right-end"
      >
        <BriefServiceInput
          source={getSource('notActiveWhenServices')}
          label="form.fields.activeWhenNotService"
        />
      </KToolTip>
      {isGeneralQuestion ? (
        <BriefServiceInput
          source={getSource('activeWhenServices')}
          label="form.fields.activeWhenService"
        />
      ) : (
        <KToolTip title="form.fields.activeOnlyFor" placement="right-end">
          <LocalizedSelectArrayInput
            source={getSource('products')}
            label="Active only For"
            choices={productChoices}
          />
        </KToolTip>
      )}
    </StyledDiv>
  )
}

export default BriefQuestionInput
