import React from 'react'
import { RAFormFC } from 'types/common'
import { useForm } from 'hooks/useForm'
import { CheckoutQuestionReply } from '@kampaay/common'
import LocalizedHeader from 'components/Fields/Localized/LocalizedHeader'
import KTextInput from 'components/Inputs/KInputs/KTextInput/KTextInput'
import KSelectInput from 'components/Inputs/KInputs/KSelectInput'
import KSelectArrayInput from 'components/Inputs/KInputs/KSelectArrayInput'

const CheckoutQuestionReplyItem: RAFormFC = ({ source }) => {
  const { getSource, useGetValue } = useForm<CheckoutQuestionReply>(source)

  const question = useGetValue('question')
  const type = useGetValue('type')
  const options = useGetValue('options')

  const choices = options?.map((option) => ({
    id: option,
    name: option
  }))

  return (
    <>
      <LocalizedHeader title={question} headingLevel="h4" />
      {type === 'freeText' && (
        <KTextInput
          source={getSource('responses.0')}
          label="form.fields.response"
          fullWidth
        />
      )}
      {type === 'singleSelect' && (
        <KSelectInput
          source={getSource('responses.0')}
          label="form.fields.response"
          parse={(v) => v ?? ''}
          choices={choices}
          fullWidth
        />
      )}
      {type === 'multiSelect' && (
        <KSelectArrayInput
          source={getSource('responses')}
          label="form.fields.response"
          choices={choices}
          fullWidth
        />
      )}
    </>
  )
}

export default CheckoutQuestionReplyItem
