import React from 'react'
import { SelectColumnsButton, FilterButton, CreateButton } from 'react-admin'
import { filterConfig } from 'services/filters'
import { getFilterComponent } from 'services/filters/KFilters'

type KListToolbarProps = {
  resource: keyof typeof filterConfig
}

const KListToolbar: React.FC<KListToolbarProps> = ({ resource }) => {
  // Since we are adding a custom toolbar the default behavior of filters has to be built back up by us
  // Here we are retrieving the filters that are not always shown from the resource's config and we are creating their components.
  const postFilters =
    filterConfig[resource]
      ?.filter((f) => !f.props.alwaysOn)
      .map(getFilterComponent) ?? []

  return (
    <div>
      <SelectColumnsButton />
      <FilterButton filters={postFilters} />
      <CreateButton />
    </div>
  )
}

export default KListToolbar
