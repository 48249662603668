import { LocationCity } from '@mui/icons-material'
import LocationUpsellingList from 'resources/catalog/location/upselling/LocationUpsellingList'
import LocationUpsellingEdit from 'resources/catalog/location/upselling/LocationUpsellingEdit'
import LocationUpsellingCreate from 'resources/catalog/location/upselling/LocationUpsellingCreate'

const locationUpselling = {
  icon: LocationCity,
  list: LocationUpsellingList,
  edit: LocationUpsellingEdit,
  create: LocationUpsellingCreate
}

export default locationUpselling
