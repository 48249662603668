import {
  type SupportedCountryCode,
  SUPPORTED_COUNTRY_CODES
} from './countries-cfg'
import { getCurrentCountry } from './country-accessors'

/**
 * This should be used when a switch is supposed to be updated when a country is added to our platform:
 * it will force every usage of this function to be updated and the new country handling specified.
 *
 * @param expression exhaustive key-value expression covering all the supported country codes
 * @returns
 */
export const countrySwitchStrict = <TValue>(
  expression: Record<SupportedCountryCode, TValue>
) => expression[getCurrentCountry().code]

/**
 * This should be used when a switch is NOT supposed to be updated when adding a country (or if we still don't know if it will)
 *
 * @param defaultValue default value will be returned if a value corresponding to the current country is not specified in the expression
 * @param expression non-exhaustive key-value expression covering only some of the supported country codes: others will be assigned with defaultValue
 * @returns
 */
export const countrySwitch = <TValue>(
  defaultValue: TValue,
  expression: Partial<Record<SupportedCountryCode, TValue>>
) => {
  const exp = SUPPORTED_COUNTRY_CODES.reduce(
    (acc, code) => ({
      ...acc,
      [code]: expression[code] || defaultValue
    }),
    {} as Record<SupportedCountryCode, TValue>
  )
  return countrySwitchStrict(exp)
}
