import { useKResourceContext } from 'hooks/useKResourceContext'
import { EntitiesWithForm, VS_MAP } from 'services/validations'

/**
 * Used to retrieve the schema from the context with the correct entity
 */
export const useFormVS = () => {
  const entity = useKResourceContext() as EntitiesWithForm
  const useValidationSchema = VS_MAP[entity]

  return useValidationSchema?.()
}
