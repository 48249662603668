import type { FileUploader } from '../../types'
import { writeImageWithDescription } from '../images-with-description'
import {
  writeFoodSelection,
  writeOptionedSelection,
  writeProfessionalSelection
} from './selections'
import type { ProposalItem, ProposalItemToAPI } from './types'

const extractSelectionToWrite = (
  selection: ProposalItem['selection']
): Pick<
  ProposalItemToAPI,
  'foodSelection' | 'physicalExperienceSelection' | 'professionalSelection'
> => {
  switch (selection?.type) {
    case 'foodBeverage':
      return {
        foodSelection: writeFoodSelection(selection)
      }
    case 'staff':
      return {
        professionalSelection: writeProfessionalSelection(selection)
      }
    case 'activitiesExperiences':
      return {
        physicalExperienceSelection: writeOptionedSelection(selection)
      }
    default:
      return {}
  }
}

export const proposalItemWrite =
  (fileUploader: FileUploader) =>
  async ({
    presentation,
    createdAt: _createdAt,
    unitaryPrice: _unitaryPrice,
    unitaryCost: _unitaryCost,
    totalPrice: price,
    totalCost: cost,
    isOutOfScope,
    selection,
    ...rest
  }: ProposalItem): Promise<ProposalItemToAPI> => {
    const writtenImages = await Promise.all(
      (presentation.images ?? []).map(writeImageWithDescription(fileUploader))
    )

    return {
      ...rest,
      narrativeDescription: presentation.narrativeDescription,
      showDescription: presentation.showDescription,
      showImages: presentation.showImages,
      showSupplier: presentation.showSupplier,
      images: writtenImages,
      layout: presentation.layout,
      supplierImages: presentation.supplierImages ?? [],
      price,
      cost,
      isOutOfScope: !!isOutOfScope,
      ...extractSelectionToWrite(selection)
    }
  }
