import { LocationCity } from '@mui/icons-material'
import LocationList from 'resources/catalog/location/product/LocationList'
import LocationEdit from 'resources/catalog/location/product/LocationEdit'
import LocationCreate from 'resources/catalog/location/product/LocationCreate'

const locationIndex = {
  icon: LocationCity,
  list: LocationList,
  edit: LocationEdit,
  create: LocationCreate
}

export default locationIndex
