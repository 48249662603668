import { ProposalCostPerSupplier } from '@kampaay/common'
import { format } from 'date-fns'
import { KampaayDataProvider } from 'services/api/dataProvider'
import { ExportDataToAPI } from 'services/api/entities/accounting/transactionExportForPurchaseOrder/types/api/request-models'
import {
  ExportData,
  EventExportData,
  ConciergeExportData
} from 'services/api/entities/accounting/transactionExportForPurchaseOrder/types/internal'
import { translate } from 'services/i18n'

type ProposalCostPerUnknownSupplier = {
  [K in keyof ProposalCostPerSupplier]: Exclude<
    ProposalCostPerSupplier[K],
    number
  >
}
type ProposalCostPerKnownSupplier = {
  [K in keyof ProposalCostPerSupplier]: Exclude<
    ProposalCostPerSupplier[K],
    'unknown'
  >
}

/**
 * Sends to backend the data of the current export and fetches the data of the previous export
 * @param dataProvider
 * @param currentExportData
 * @returns the export data of the latest export (the one previous to the current one)
 * @link reference https://gitlab.com/kampaay/general/-/issues/2337, tech details, strategies secion
 */
export const patchLatestExportData = async (
  dataProvider: KampaayDataProvider,
  currentExportData: ExportDataToAPI
): Promise<ExportData> => {
  return (
    await dataProvider.updateMany('transactionexportforpurchaseorder', {
      data: currentExportData,
      ids: []
    })
  )?.data?.[0]
}

// Handles the known data and maps them to the current export data
export const mapCurrentExportData = (
  costsPerSupplier: ProposalCostPerSupplier[],
  transactionId: number
): ExportDataToAPI => {
  const costsWithoutSupplier =
    costsPerSupplier.filter<ProposalCostPerUnknownSupplier>(
      (cost): cost is ProposalCostPerUnknownSupplier =>
        cost.supplierId === 'unknown'
    )[0]?.costAndCommission

  return {
    eventExportData: [
      {
        eventId: transactionId,
        commissionWithoutSupplier: costsWithoutSupplier?.totalCommission,
        costWithoutSupplier: costsWithoutSupplier?.totalCost,
        conciergeExportData: costsPerSupplier
          .filter<ProposalCostPerKnownSupplier>(
            (
              costPerSupplier
            ): costPerSupplier is ProposalCostPerKnownSupplier =>
              costPerSupplier.supplierId !== 'unknown'
          )
          .map((costPerSupplier) => ({
            commission: costPerSupplier.costAndCommission.totalCommission,
            cost: costPerSupplier.costAndCommission.totalCost,
            supplierId: costPerSupplier.supplierId
          }))
      }
    ]
  }
}

/**
 * Since we wamt to keep track of the suppliers that were there in the previous export but not in the current one,
 * we need to merge the current costs with the previous ones
 * @param currentCostsPerSupplier the current costs per supplier
 * @param previousExportData the previous export data
 * @link reference https://gitlab.com/kampaay/general/-/issues/2337, last acceptance criterion
 */
export const mergeCurrentlyExistingWithPreviouslyExistingCosts = (
  currentCostsPerSupplier: ProposalCostPerSupplier[],
  previousEventExportData: EventExportData
): ProposalCostPerSupplier[] => {
  const costsPerSupplier = currentCostsPerSupplier.concat(
    // Find items in conciergeExportData that do not exist anymore in currentCostsPerSupplier based on the 'supplierId' property
    previousEventExportData.conciergeExportData
      .filter(
        (previous) =>
          !currentCostsPerSupplier.some(
            (current) => current.supplierId === previous.supplierId
          )
      )
      // map those items to the ProposalCostPerSupplier type. Their current cost and commission will be 0
      .map<ProposalCostPerSupplier>((conciergeExportDatum) => ({
        supplierId: conciergeExportDatum.supplierId,
        costAndCommission: {
          totalCommission: 0,
          totalCost: 0
        }
      }))
  )

  // If the previous export had a cost or commission without supplier and the current export does not, we need to add it to the current export
  if (
    (previousEventExportData.commissionWithoutSupplier > 0 ||
      previousEventExportData.costWithoutSupplier > 0) &&
    !currentCostsPerSupplier.some((current) => current.supplierId === 'unknown')
  ) {
    costsPerSupplier.push({
      supplierId: 'unknown',
      costAndCommission: {
        totalCommission: 0,
        totalCost: 0
      }
    })
  }
  return costsPerSupplier
}

// Handles the known data and maps them to the latest export data
export const mapConciergePricingDataFromLatestExport = (
  supplierId: number | 'unknown',
  eventExportData: EventExportData
):
  | Omit<ConciergeExportData, 'supplierId'> & {
      supplierId: number | string
    } => {
  if (supplierId === 'unknown') {
    return {
      commission: eventExportData.commissionWithoutSupplier,
      cost: eventExportData.costWithoutSupplier,
      supplierId: translate(
        `csv.transactionForPurchaseOrder.supplierNotSpecified`
      ),
      supplierBusinessName: translate(
        `csv.transactionForPurchaseOrder.supplierNotSpecified`
      ),
      supplierEmail: translate(
        `csv.transactionForPurchaseOrder.supplierNotSpecified`
      ),
      supplierName: translate(
        `csv.transactionForPurchaseOrder.supplierNotSpecified`
      ),
      supplierVat: translate(
        `csv.transactionForPurchaseOrder.supplierNotSpecified`
      )
    }
  }
  let previousEventExportData = eventExportData.conciergeExportData.find(
    (conciergeExportData) => conciergeExportData.supplierId === supplierId
  )

  // Fallback values in some weird case the supplierId is not found
  if (!previousEventExportData) {
    previousEventExportData = {
      cost: 0,
      commission: 0,
      supplierId: 0,
      supplierBusinessName: '-',
      supplierEmail: '-',
      supplierName: '-',
      supplierVat: '-'
    }
  }
  return previousEventExportData
}

export const formatDateForPurchaseOrderCSV = (
  date: Date | undefined,
  options?: { includeTime: boolean }
) => {
  if (!date) {
    return '---'
  }
  if (options?.includeTime) {
    return format(date, 'dd/MM/yyyy HH:mm')
  }
  return format(date, 'dd/MM/yyyy')
}
