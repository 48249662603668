import {
  createReadableErrorPath,
  isTruthy,
  makeErrorReadable,
  UTranslate,
  WithId
} from '@kampaay/common'
import { MainProductAPIEntity } from 'services/api/entities'
import { COLLECTION_NAME_SERVICE_MAP } from 'services/cfg/name-maps'
import { ServicesConfig } from 'services/api/entities/services-config/types/internal/services-config-models'

import { Translate } from 'react-admin'
import { FieldError, FieldErrorsImpl } from 'react-hook-form'

export const validateUniqueById = (e?: (WithId | undefined)[]) => {
  if (!e) return undefined
  return e.length > new Set(e.filter(isTruthy).map((i) => i?.id)).size
    ? 'You are trying to add the same item twice'
    : undefined
}

export const validateBookingTimeLimit =
  (resource: MainProductAPIEntity, servicesConfig: ServicesConfig) =>
  (value: number) => {
    const { advanceReservationMin } =
      servicesConfig[COLLECTION_NAME_SERVICE_MAP[resource]]
    if (value && value < advanceReservationMin) {
      return `Please indicate a timelimit that is greater or equal than: ${advanceReservationMin}, the one set for the service`
    }
    return undefined
  }

type KFieldError = FieldError & { path: string[] }

export const isFieldError = (
  err:
    | FieldError
    | FieldErrorsImpl<{
        [x: string]: any
      }>
): err is FieldError => 'ref' in err

/**
   * errors retrace the structure of the form data and for each error detected in it
   * (i.e: corresponding to `formData.prop1.array[0].prop2` for example), we can expect an errors object as follows:
  {
    prop1: {
      array: {
        0: { prop2: {
          message: 'form.validations.everyPriceRangeHasPrice'
          ref: htmlElement | namedReference
        } },
      },
    },
  }
   *
   * @param errors
   * @returns
   */
export const getFirstError = (
  errors:
    | FieldError
    | FieldErrorsImpl<{
        [x: string]: any
      }>,
  path: string[] = []
): KFieldError | undefined => {
  if (isFieldError(errors)) {
    return { ...errors, path: path }
  }
  const firstKey = Object.keys(errors)[0]
  const err = errors[firstKey]
  if (!err) return undefined
  return getFirstError(err, [...path, firstKey])
}

const camelToSentence = (message: string) => {
  const firstLetterRegex = /^[a-z]/
  const firstWordLetterRegex = /([a-z])([A-Z])/g

  return message
    .replace(firstLetterRegex, (value) => value.toLocaleUpperCase())
    .replaceAll(firstWordLetterRegex, (_, last, first) => `${last} ${first}`)
}

const translateOrTransformToSentence =
  (t: Translate) => (prefix: string) => (key: string) => {
    const tKey = `${prefix}.${key}`
    const result = t(tKey)

    if (result === tKey) {
      return camelToSentence(key)
    }

    return result
  }

/**
 * Transform the path to a single string which easily parsable through Regexp,
 * the same format used in the yup error message
 *
 * @param path
 * @returns
 */
const transformPathComponentsToString = (path: string[]) =>
  path
    .map((value) =>
      parseInt(value).toString() === value ? `[${value}]` : value
    )
    .join('.')
    .replaceAll('.[', '[')

/**
 * Translate a path (as string array) using the same rules as string path
 * @param path
 */
const translatePath = (path: string[], t: UTranslate) => {
  const stringPath = transformPathComponentsToString(path)

  return createReadableErrorPath(stringPath, t)
}

export const buildErrorMessage = (
  err: Pick<KFieldError, 'message' | 'path'>,
  trans: Translate
) =>
  err.message
    ? makeErrorReadable(err.message, translateOrTransformToSentence(trans))
    : `Generic error at ${translatePath(
        err.path,
        translateOrTransformToSentence(trans)
      )}`
