import React from 'react'
import LocationTailoringsInput from 'resources/crm/clients/organization/tailoring/components/byRule/LocationTailoringsInput'
import ServiceTailoringsEdit from 'resources/crm/clients/organization/tailoring/ServiceTailoringsEdit'

const LocationTailoringsEdit: React.FC = () => {
  return (
    <ServiceTailoringsEdit
      service="locations"
      productEntity="locations"
      tailoringsInput={LocationTailoringsInput}
    />
  )
}

export default LocationTailoringsEdit
