import React from 'react'
import { getTimeLabels } from '@kampaay/common'
import KSelectInput, {
  KSelectInputProps
} from 'components/Inputs/KInputs/KSelectInput'

const TimePicker: React.FC<KSelectInputProps> = (props) => {
  return <KSelectInput {...props} choices={getTimeLabels()} fullWidth />
}

export default TimePicker
