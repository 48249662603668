import React from 'react'
import ProductSectionFormContent from 'components/Forms/ProductSectionFormContent'
import SectionWrapper from 'components/UI/SectionWrapper'
import { KSimpleForm } from 'components/Forms/KForms/KSimpleForm'
import KEdit from 'components/Forms/KEdit/KEdit'

const LocationSectionEdit: React.FC = () => (
  <SectionWrapper title={'resources.locationsections.editSection'}>
    <KEdit>
      <KSimpleForm>
        <ProductSectionFormContent />
      </KSimpleForm>
    </KEdit>
  </SectionWrapper>
)

export default LocationSectionEdit
