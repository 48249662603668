import { styled } from '@mui/material/styles'
import React from 'react'
import {
  ruleActionChoices,
  ruleTargetTypeChoices,
  serviceDiscriminatorChoices
} from 'services/cfg/form'
import {
  TARGET_TYPE_FOR_RULE_ACTION,
  THEN_CLAUSE_TARGETTABLE_SERVICES
} from 'services/cfg/rules'
import { RAFormFC } from 'types/common'
import KToolTip from 'components/UI/KTooltip'
import KSelectInput from 'components/Inputs/KInputs/KSelectInput'
import ProductsByServiceSelector from 'components/FormComponents/Rules/ProductsByServiceSelector'
import { useForm } from 'hooks/useForm'
import { ThenClause } from '@kampaay/common'

const PREFIX = 'ThenClauseForm'

const classes = {
  dFlexRow: `${PREFIX}-dFlexRow`,
  inputW250px: `${PREFIX}-inputW250px`,
  inputW800px: `${PREFIX}-inputW800px`,
  w100: `${PREFIX}-w100`
}

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')({
  [`& .${classes.dFlexRow}`]: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    width: '100%'
  },
  [`& .${classes.inputW250px}`]: {
    width: '250px'
  },
  [`& .${classes.inputW800px}`]: {
    width: '800px'
  },
  [`& .${classes.w100}`]: {
    width: '100%'
  }
})

const ThenClauseInput: RAFormFC = ({ source }) => {
  const { getSource, useGetValue, useSetValue } = useForm<ThenClause>(source)
  const targetType = useGetValue('targetType')
  const setProducts = useSetValue('products')
  const action = useGetValue('action')

  // the array of selectable choices
  const selectableServices = THEN_CLAUSE_TARGETTABLE_SERVICES[targetType]

  const selectableTargets = TARGET_TYPE_FOR_RULE_ACTION[action]

  // filter the choices of services with the selectable services
  const serviceAvailableChoices = serviceDiscriminatorChoices.filter((e) =>
    selectableServices?.includes(e.id)
  )

  // filter the choices of targets with the selectable targets
  const targetAvailableChoices = ruleTargetTypeChoices.filter((e) =>
    selectableTargets?.includes(e.id)
  )

  return (
    <Root>
      <div className={classes.dFlexRow}>
        <KToolTip title="form.fields.ruleActionTooltip" placement="top">
          <KSelectInput
            label="form.fields.ruleAction"
            className={classes.inputW250px}
            source={getSource('action')}
            choices={ruleActionChoices}
            defaultValue="suggest"
          />
        </KToolTip>

        <KToolTip title="form.fields.ruleTargetTypeTooltip" placement="top">
          <KSelectInput
            label="form.fields.targetType"
            source={getSource('targetType')}
            onChange={() => setProducts([])}
            choices={targetAvailableChoices}
            className={classes.inputW250px}
          />
        </KToolTip>

        <ProductsByServiceSelector
          serviceChoices={serviceAvailableChoices}
          source={source}
          serviceTooltipText="form.fields.thenClauseServiceTooltip"
          productsTooltipText="form.fields.thenClauseProductsTooltip"
          targetType={targetType}
          action={action}
        />
      </div>
    </Root>
  )
}

export default ThenClauseInput
