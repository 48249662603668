import { yupArray } from 'services/yup/custom-schemas/array'
import { yupNumber } from 'services/yup/custom-schemas/number'
import { yupIgnore } from 'services/yup/helpers'
import { UseValidationSchema } from 'services/yup/types'
import { standardPricingArrayVS } from 'services/validations/catalog/standard-pricing/standardPricingArray'
import { productCommonVS } from 'services/validations/catalog/productCommon'
import { withQuantityHintVS } from 'services/validations/catalog/hints'
import { Upselling } from '@kampaay/common'

export const useUpsellingVS: UseValidationSchema<Upselling> = () =>
  productCommonVS.concat(withQuantityHintVS).shape({
    pricing: standardPricingArrayVS().min(1),
    minQuantity: yupNumber().required().min(1),
    pricingFacet: yupIgnore(),
    groupFacet: yupIgnore(),
    products: yupArray(),
    isPerItem: yupIgnore(),
    geoAreaPresets: yupArray().min(1)
  })
