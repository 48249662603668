import React from 'react'
import { RaRecord, SimpleFormIterator } from 'react-admin'
import BriefQuestionInput from 'components/FormComponents/Brief/BriefQuestionInput'
import KArrayInput from 'components/Inputs/KInputs/KArrayInput'

type Props = {
  source: string
  isGeneralQuestion?: boolean
  productChoices?: RaRecord[]
}

const BriefQuestionArrayInput: React.FC<Props> = ({
  source,
  isGeneralQuestion = false,
  productChoices
}) => {
  return (
    <KArrayInput
      source={source}
      label="Survey Structure"
      style={{ padding: '16px 0' }}
    >
      <SimpleFormIterator fullWidth>
        <BriefQuestionInput
          isGeneralQuestion={isGeneralQuestion}
          productChoices={productChoices}
        />
      </SimpleFormIterator>
    </KArrayInput>
  )
}

export default BriefQuestionArrayInput
