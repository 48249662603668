import { APICfg } from 'services/api/api'
import { foodUpsellingWrite, parseFoodUpselling } from '@kampaay/common'
import { uploadOrKeepMultipleImgs } from 'services/utils/images'

const apiCfg: APICfg = {
  endpoint: 'foodupselling',
  parse: parseFoodUpselling,
  write: foodUpsellingWrite(uploadOrKeepMultipleImgs)
}

export default apiCfg
