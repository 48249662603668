import {
  type UseValidationSchema,
  yupBoolean,
  yupIgnore,
  yupObject,
  yupString,
  yupArray,
  yupNumber
} from 'services/yup'
import { approvalConfigVs } from '.'
import {
  OrganizationMembership,
  OrganizationMembershipRole
} from '@kampaay/common'

const useOrganizationMembershipVS: UseValidationSchema<
  OrganizationMembership
> = () =>
  yupObject().shape({
    id: yupIgnore(),
    userId: yupIgnore(),
    organizationId: yupIgnore(),
    role: yupString<OrganizationMembershipRole>(),
    activeBudgetIds: yupArray(yupNumber()).test({
      test: (ids) => Array.from(new Set(ids)).length === ids?.length,
      message: 'form.validations.uniqueBudgetsArray'
    }),
    userName: yupIgnore(),
    userEmail: yupIgnore(),
    subGroupId: yupIgnore(),
    disableApprovalFlow: yupBoolean(),
    approvalSteps: yupArray(approvalConfigVs)
  })

export default useOrganizationMembershipVS
