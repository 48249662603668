import {
  FoodSelection,
  LogisticSelection
} from 'services/api/entities/events/types/internal/selections'
import { TimeLabel } from '@kampaay/common'
import {
  yupIgnore,
  yupNumber,
  yupObject,
  YupSchema,
  yupString
} from 'services/yup'
import { productSelectionBaseVS } from 'services/validations/operations/events/selections/common/base'
import { optionedProductSelectionVS } from 'services/validations/operations/events/selections/common/optionedProduct'

const logisticSelectionVS: YupSchema<LogisticSelection> =
  productSelectionBaseVS.shape({
    pricing: yupObject().nullable(),
    quantity: yupIgnore()
  })

const intoleranceSchema = (label: string) =>
  yupNumber()
    .transform((value: number) => (isNaN(value) ? undefined : value))
    .integer()
    .min(0)
    .optional()
    .label(label)

const intolerancesSelectionVS: YupSchema<FoodSelection['intolerances']> =
  yupObject().shape({
    gluten: intoleranceSchema('The number of gluten intolerant guests'),
    lactose: intoleranceSchema('The number of lactose intolerant guests'),
    vegan: intoleranceSchema('The number of vegan guests'),
    vegetarian: intoleranceSchema('The number of vegetarian guests'),
    other: yupString().optional()
  })

export const foodSelectionVS: YupSchema<FoodSelection> =
  optionedProductSelectionVS.shape({
    deliveryTime: yupString<TimeLabel>().required(),
    intolerances: intolerancesSelectionVS,
    logisticSelection: logisticSelectionVS.required()
  })
