import { Snackbar, Alert, AlertTitle, IconButton } from '@mui/material'
import FlexBox from 'components/UI/layout/FlexBox'
import React from 'react'
import { Button } from 'react-admin'
import { translate } from 'services/i18n'
import { openExternalLinkInNewWindow } from 'services/utils/navigationUtils'
import { FILE_ERRORS_MAP } from 'components/Inputs/KInputs/KFileInput'
import CloseIcon from '@mui/icons-material/Close'

type ImageTooLargeNotificationProps = {
  isNotificationOpen: boolean
  setIsNotificationOpen: (isOpen: boolean) => void
}

const ImageTooLargeNotification: React.FC<ImageTooLargeNotificationProps> = ({
  isNotificationOpen,
  setIsNotificationOpen
}) => {
  return (
    <Snackbar
      open={isNotificationOpen}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert severity="error" sx={{ width: '100%' }} variant={'filled'}>
        <FlexBox gap="3em" alignItems="center">
          <div>
            <AlertTitle>Error</AlertTitle>
            {FILE_ERRORS_MAP['file-too-large']}
            <div>{'use the RESIZE TOOL to reduce the size of the image'}</div>
          </div>

          <Button
            label="label.imageInput.resizeMessage.text3"
            variant="outlined"
            style={{ color: 'white', borderColor: 'white' }}
            onClick={() => {
              openExternalLinkInNewWindow(
                translate('label.imageInput.resizeMessage.link')
              )

              setIsNotificationOpen(false)
            }}
          />

          <IconButton
            aria-label="Hide"
            onClick={() => setIsNotificationOpen(false)}
            size={'large'}
          >
            <CloseIcon style={{ color: 'white' }} />
          </IconButton>
        </FlexBox>
      </Alert>
    </Snackbar>
  )
}

export default ImageTooLargeNotification
