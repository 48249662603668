import React from 'react'
import { SimpleFormIterator } from 'react-admin'
import LocalizedTextInput from 'components/Inputs/Localized/LocalizedTextInput'
import LocalizedRichTextInput from 'components/Inputs/Localized/LocalizedRichTextInput'
import OptionGroupSettings from 'components/FormComponents/OptionGroupSettings'
import CatalogOptionsInput from 'components/FormComponents/pricing/CatalogOptionsInput'
import KDivider from 'components/UI/KDivider'
import { useForm } from 'hooks/useForm'
import { RAFormFC } from 'types/common'
import KArrayInput from 'components/Inputs/KInputs/KArrayInput'
import { OptionGroup } from '@kampaay/common'

const OptionGroupInput: RAFormFC = ({ source }) => {
  const { useGetValue, getSource } = useForm<OptionGroup>(source!)

  const items = useGetValue('items')

  // this is used by the exclusive boolean to reset itself when one of the dependencies changes
  const dependentFieldsSources: string[] = (items ?? []).map((_, index) =>
    getSource(`items.${index}.isDefault`)
  )

  dependentFieldsSources.push(getSource('acceptsQuantity'))

  return (
    <>
      <KDivider title="Option Group" />
      <LocalizedTextInput
        source={getSource('name')}
        label="form.fields.name"
        fullWidth
      />
      <LocalizedRichTextInput
        source={getSource('description')}
        label="form.fields.description"
        fullWidth
      />

      <OptionGroupSettings
        source={source}
        acceptsQuantityDependentSources={dependentFieldsSources}
      />

      <KArrayInput source={getSource('items')}>
        <SimpleFormIterator>
          <CatalogOptionsInput
            isDefaultDependentSources={dependentFieldsSources}
          />
        </SimpleFormIterator>
      </KArrayInput>
    </>
  )
}

export default OptionGroupInput
