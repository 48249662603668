import {
  UseValidationSchema,
  yupIgnore,
  yupNumber,
  yupObject,
  yupString
} from 'services/yup'
import { ActivityStatus, Kampaayer } from '@kampaay/common'
import { KampaayerRole } from 'types/form'

export const useKampaayerVS: UseValidationSchema<Kampaayer> = () =>
  yupObject().shape({
    id: yupIgnore(),
    firstName: yupString().required(),
    lastName: yupString().required(),
    phone: yupString().required(),
    email: yupString().required(),
    domicileAddress: yupString().required(),
    birthDate: yupString().required(),
    birthPlace: yupString().required(),
    residenceAddress: yupString().required(),
    personalNumber: yupString().required(),
    role: yupString<KampaayerRole>().required(),
    kContract: yupIgnore(),
    managedCountries: yupNumber().required(),
    fullName: yupIgnore(),
    status: yupString<ActivityStatus>().required()
  })
