import React, { useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { EventBriefQuestion } from 'services/api/entities/events/types/internal/services'
import { isActive, keys } from 'services/utils'
import { Button } from '@mui/material'
import { useTranslate } from 'react-admin'
import { WithDataTestId } from 'test/utils'
import { ProductServiceName } from 'types/common'
import { MappedEnrichedEvent } from 'services/api/entities/events/types/internal/event-models'

type Props = WithDataTestId & {
  serviceSource?: string // an undefined serviceSource means that we are in the global scope
}

type EventServices =
  | {
      generalService: MappedEnrichedEvent['generalService']
      productServices: MappedEnrichedEvent['productServices']
      professionalService: MappedEnrichedEvent['professionalService']
    }
  | undefined

const EventBriefBulkToggle: React.FC<Props> = ({
  serviceSource,
  'data-testid': dataTestId = 'event-brief-bulk-toggle'
}) => {
  const translate = useTranslate()
  const inGeneralTab = serviceSource === 'generalService'
  const inGlobalScope = serviceSource === undefined
  const [someQuestionsAreActive, setSomeQuestionsAreActive] = useState(false)
  const { setValue, watch } = useFormContext()

  // get needed services
  const eventServices = getEventServices()

  // get questions
  const questions: EventBriefQuestion[] = inGlobalScope
    ? getAllQuestions()
    : watch(`${serviceSource}.briefQuestions`)

  // ==============================
  // EFFECTS
  // ==============================
  useEffect(() => {
    // since every returns true for an empty array,
    // we need to check for that
    if (questions.length === 0) return setSomeQuestionsAreActive(false)

    setSomeQuestionsAreActive(questions.some((q) => isActive(q)))
  }, [JSON.stringify(questions), setSomeQuestionsAreActive])

  // ==============================
  // HANDLERS
  // ==============================
  const handleButtonClick = () => {
    inGlobalScope
      ? updateStatusForAllQuestions()
      : updateStatusForCurrentServiceQuestions()
  }

  // ==============================
  // FUNCTIONS
  // ==============================
  const buttonLabel = useMemo(() => {
    let labelKey = ''

    if (inGlobalScope) {
      labelKey = someQuestionsAreActive
        ? 'button.label.questions.deactivateBriefQuestions'
        : 'button.label.questions.activateBriefQuestions'
    } else {
      const tabScope = inGeneralTab ? 'General' : 'Service'
      labelKey = someQuestionsAreActive
        ? `button.label.questions.deactivate${tabScope}Questions`
        : `button.label.questions.activate${tabScope}Questions`
    }

    return translate(labelKey)
  }, [inGlobalScope, someQuestionsAreActive, inGeneralTab])

  function getAllQuestions(): EventBriefQuestion[] {
    if (!eventServices) return []

    const generalServiceQuestions: EventBriefQuestion[] =
      eventServices?.generalService?.briefQuestions ?? []

    const professionalServiceQuestions: EventBriefQuestion[] =
      eventServices.professionalService.services.flatMap(
        (s) => s.briefQuestions ?? []
      ) ?? []

    const productServiceQuestions: EventBriefQuestion[] = Object.keys(
      eventServices.productServices
    ).flatMap((key) => {
      return (
        eventServices?.productServices[key as ProductServiceName]
          .briefQuestions ?? []
      )
    })

    return [
      ...generalServiceQuestions,
      ...professionalServiceQuestions,
      ...productServiceQuestions
    ]
  }

  function updateStatusForAllQuestions() {
    if (!eventServices) return []

    eventServices.generalService?.briefQuestions.forEach((_q, i) => {
      setStatusFor(`generalService.briefQuestions.${i}.status`)
    })

    eventServices.professionalService.services.forEach((s, i) => {
      s.briefQuestions.forEach((_q, j) => {
        setStatusFor(
          `professionalService.services.${i}.briefQuestions.${j}.status`
        )
      })
    })

    const servicesKeys = keys(eventServices.productServices)
    servicesKeys.forEach((key) => {
      const briefQuestions =
        eventServices.productServices[key].briefQuestions ?? []

      briefQuestions.forEach((_q, j) => {
        setStatusFor(`productServices.${key}.briefQuestions.${j}.status`)
      })
    })
  }

  function updateStatusForCurrentServiceQuestions() {
    questions.forEach((_q, i) => {
      setStatusFor(`${serviceSource}.briefQuestions.${i}.status`)
    })
  }

  function setStatusFor(name: string) {
    setValue(name, someQuestionsAreActive ? 'inactive' : 'active', {
      shouldDirty: true
    })
  }

  function getEventServices(): EventServices {
    return inGlobalScope
      ? {
          generalService: watch('generalService'),
          professionalService: watch('professionalService'),
          productServices: watch('productServices')
        }
      : undefined
  }

  // ==============================
  // JSX
  // ==============================
  return (
    <Button
      onClick={handleButtonClick}
      variant="contained"
      size="large"
      data-testid={dataTestId}
    >
      {buttonLabel}
    </Button>
  )
}

export default EventBriefBulkToggle
