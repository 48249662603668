import { EnrichedProfessionalService } from 'services/api/entities/events/types/internal/services'
import { parseMultilangOrFallbackToAnyAvailable } from '@kampaay/common'
import { SelectionType } from 'hooks/events/useEventService'
import { EventReadonlyServiceSummary } from 'hooks/events/useReadonlyEventService'

export type EventReadonlyProfessionalServiceSummary = Omit<
  EventReadonlyServiceSummary<
    EnrichedProfessionalService,
    `professionalService.services.${number}`
  >,
  'numberOfCustomizations'
>

/**
 *
 * @param proService the single professional service from the record
 * @param hasCustomizations if there is at least one customization in the professional service group
 * @param idx the index of the given service in the list of services under the group
 * @returns the summary of a professional service retrieved from the record of a singe professional service
 */
export const getReadonlyEventProfessionalService = (
  proService: EnrichedProfessionalService,
  hasCustomizations: boolean,
  idx: number
): EventReadonlyProfessionalServiceSummary => {
  const selections: SelectionType[] = proService.selections ?? []
  const hasSelections = !!selections.length
  const hasUpselling = !!selections.flatMap(
    (s) => !!s.upsellingSelection?.length
  )

  const serviceSource = `professionalService.services.${idx}` as const

  return {
    id: proService.name,
    selections,
    service: proService,
    serviceSource,
    serviceName: parseMultilangOrFallbackToAnyAvailable(proService.name),
    serviceTabPath: 'professionals',
    hasSelections,
    hasUpselling,
    hasCustomizations
  }
}
