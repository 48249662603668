import React from 'react'
import ProductCommonTailoringInput from 'resources/crm/clients/organization/tailoring/components/productCommon/ProductCommonTailoringInput'
import ProductCommonTailoringsEdit from 'resources/crm/clients/organization/tailoring/ProductCommonTailoringsEdit'

const PhysicalUpsellingTailoringsEdit: React.FC = () => {
  return (
    <ProductCommonTailoringsEdit
      productEntity="physicalupselling"
      tailoringsInput={ProductCommonTailoringInput}
    />
  )
}

export default PhysicalUpsellingTailoringsEdit
