import React from 'react'
import { styled } from '@mui/system'
import KNumberInput from 'components/Inputs/KInputs/KNumberInput/KNumberInput'
import MarginPercAndVatFields from 'components/FormComponents/pricing/MarginPercAndVatFields'
import { useForm } from 'hooks/useForm'
import { RAFormFC } from 'types/common'
import { WithDataTestId } from 'test/utils'
import { WithStyle } from 'types/ui'
import { APIPriceRange } from '@kampaay/common'

const PREFIX = 'VariablePricesInput'

const classes = {
  container: `${PREFIX}-container`,
  numberInput: `${PREFIX}-numberInput`
}

const StyledDiv = styled('div')({
  [`& .${classes.container}`]: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    width: '100%'
  },
  [`& .${classes.numberInput}`]: {
    width: '300px',
    marginRight: '24px'
  }
})

type Props = WithDataTestId & WithStyle

const FixedPricesInput: RAFormFC<Props> = ({
  source,
  style,
  'data-testid': dataTestId = 'fixed-prices-input'
}) => {
  const { getSource } = useForm<APIPriceRange>(source)

  return (
    <StyledDiv style={style} data-testid={dataTestId}>
      <div className={classes.container}>
        <KNumberInput
          source={getSource('fixedCost')}
          label="form.fields.fixedCost"
          className={classes.numberInput}
          step={0.1}
        />
        <KNumberInput
          source={getSource('fixedPrice')}
          label="form.fields.fixedPrice"
          className={classes.numberInput}
          step={0.1}
        />

        <MarginPercAndVatFields
          costSource={getSource('fixedCost')}
          priceSource={getSource('fixedPrice')}
        />
      </div>
    </StyledDiv>
  )
}

export default FixedPricesInput
