import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { useEventRecord } from 'hooks/events/useEventRecord'
import { useKRecordContext } from 'hooks/useKRecordContext'
import { useTranslate } from 'ra-core'
import React from 'react'

import { MappedEnrichedEvent } from 'services/api/entities/events/types/internal/event-models'
import EventListServicesTableRow from 'resources/operations/events/list-components/EventListServicesTableRow'

const tableCellStyles = {
  fontWeight: 'bold'
}

const EventListServicesTable: React.FC = () => {
  const { summaries, hasIntolerances, logistics } = useEventRecord()
  const record = useKRecordContext<MappedEnrichedEvent>()
  const translate = useTranslate()

  if (!record || !summaries.length) return null

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell sx={tableCellStyles}>
            {translate('form.fields.service')}
          </TableCell>
          <TableCell sx={tableCellStyles}>
            {translate('form.fields.selections')}
          </TableCell>
          <TableCell sx={tableCellStyles}>
            {translate('form.fields.supplier')}
          </TableCell>
          <TableCell sx={tableCellStyles}>
            {translate('form.fields.customizations')}
          </TableCell>
          <TableCell sx={tableCellStyles}>
            {translate('form.fields.upsellingItems')}
          </TableCell>
          <TableCell sx={tableCellStyles}>
            {translate('form.fields.total')}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {summaries.map((summary) => (
          <EventListServicesTableRow
            summary={summary}
            eventId={record.id}
            hasIntolerances={hasIntolerances}
            logistics={logistics}
            key={summary.id}
          />
        ))}
      </TableBody>
    </Table>
  )
}

export default EventListServicesTable
