import React from 'react'
import BeforeHintFormContent from 'components/FormComponents/Hints/TImeBeforeHint/BeforeHintFormContent'
import KDivider from 'components/UI/KDivider'
import BaseCfgTab from 'resources/services-config/FormTabs/BaseCfgTab'

const FoodServiceCfgTab: React.FC = (props) => {
  return (
    <BaseCfgTab {...props} servName="foodService">
      <KDivider title="Suggestions" headingLevel="h1" />
      <BeforeHintFormContent
        arrayInputSource="foodService.timeBeforeHint"
        defaultHintSource="foodService.defaultTimeBeforeHint"
        togglerInputSource="foodService.showTimeBeforeHint"
        accordionText={[
          'beforeCateringHintAccordionText1',
          'beforeCateringHintAccordionText2',
          'beforeCateringHintAccordionText3'
        ]}
      />
    </BaseCfgTab>
  )
}

export default FoodServiceCfgTab
