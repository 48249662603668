import { createMockBuilder } from '../../../../utils'
import type { APISupplier } from '../types'

const buildBaseMock = (): APISupplier => ({
  phone: '',
  phoneVerified: false,
  email: '',
  emailVerified: false,
  organizationNumber: undefined,
  status: 'active',
  name: '',
  country: 'IT',
  headQuarters: undefined,
  responsibleFullName: '',
  id: 1,
  createdAt: undefined,
  lastLogin: undefined,
  drinks: [],
  foods: [],
  locations: [],
  musics: [],
  rents: [],
  documents: [],
  images: [],
  description: '',
  internalDescription: '',
  websiteUrl: '',
  hasAccount: true,
  physicalExperiences: [],
  professionalFigures: [],
  virtualExperiences: [],
  services: [],
  businessName: 'Joe Vannee Kotzow',
  proposalItems: [],
  privacyPolicyAccepted: false,
  usageOfImagesAndTextsAccepted: false,
  agreementInForce: 'none',
  rebateAndCommission: 0,
  tags: [],
  documentItems: []
})

export const buildAPISupplierMock = createMockBuilder(buildBaseMock)
