import { Badge } from '@mui/material'
import LabeledBooleanField from 'components/Fields/LabeledFields/LabeledBooleanField'
import EventServiceBriefSection from 'components/FormComponents/EventServiceBriefSection'
import { TailoredEventSelectionInput } from 'components/FormComponents/TailoredEventSelectionInput'
import KFormTab from 'components/Forms/KForms/KFormTab'
import KRichTextInput from 'components/Inputs/KInputs/KRichTextInput'
import KSelectInput from 'components/Inputs/KInputs/KSelectInput'
import KDivider from 'components/UI/KDivider'
import { useForm } from 'hooks/useForm'
import { useTranslate } from 'ra-core'
import React from 'react'
import { productStatusChoices } from 'services/cfg/form'
import { RAFormFC } from 'types/common'
import EventServiceSection from 'resources/operations/events/EventsComponents/EventServiceSection'
import { isLocationObsolete } from 'resources/operations/events/utils'
import LocationSelectionDetailsFormContent from 'resources/operations/events/eventsFormTabs/LocationTab/LocationSelectionDetailsFormContent'
import { MappedEnrichedEvent } from 'services/api/entities/events/types/internal/event-models'
import PricingSummary from 'components/FormComponents/PricingSummary/PricingSummary'
import { FormTabProps } from 'react-admin'
import FlexBox from 'components/UI/layout/FlexBox'
import EventCustomizationArrayInputAccordion from 'components/FormComponents/Customizations/EventCustomizationArrayInputAccordion'
import { useEventService } from 'hooks/events/useEventService'

type Props = Omit<FormTabProps, 'label'>

const LocationFormTab: RAFormFC<Props> = (props) => {
  const translate = useTranslate()
  const { useGetValue, getSource } = useForm<MappedEnrichedEvent>(undefined)
  const {
    hasSelections,
    hasCustomizations,
    isNotifySupplierDisabled,
    notifyServiceSupplier
  } = useEventService('locations')
  const briefQuestions = useGetValue(
    'productServices.locationService.briefQuestions'
  )

  const isGeoareaSelected = !!useGetValue('geoAreaPreset')

  return (
    <KFormTab
      {...props}
      disabled={!isGeoareaSelected}
      label={
        <Badge
          badgeContent={hasSelections || hasCustomizations ? 1 : 0}
          color="primary"
          variant="dot"
        >
          {translate('form.tabs.location')}
        </Badge>
      }
    >
      <FlexBox justifyContent="space-between">
        <KSelectInput
          source="locationStatus"
          label="form.fields.status"
          choices={productStatusChoices}
          defaultValue={productStatusChoices[0].id}
        />
        <PricingSummary
          source={getSource('productServices.locationService.pricing')}
          title={'form.headers.servicePricing'}
        />
      </FlexBox>

      <EventServiceSection
        source={getSource('productServices.locationService')}
        serviceName={'locations'}
      />

      <KRichTextInput source="locationNotes" label="form.fields.notes" />

      {/* Locations input */}
      <TailoredEventSelectionInput
        entity="locations"
        label="form.fields.locations"
        source="locations"
        getIsProductObsolete={isLocationObsolete}
        hideQuantity
        disableAdd
      >
        <LocationSelectionDetailsFormContent />
      </TailoredEventSelectionInput>

      {
        <>
          <FlexBox>
            <KDivider />
            <EventCustomizationArrayInputAccordion
              source={`productServices.locationService`}
              maxCustomizations={1}
            />
          </FlexBox>
          {!!briefQuestions && !!briefQuestions.length ? (
            <>
              <KDivider title="Event Brief" headingLevel="h3" />
              <LabeledBooleanField
                source="productServices.locationService.briefConfirmed"
                label="form.fields.briefConfirmation"
              />
              <EventServiceBriefSection
                serviceSource={`productServices.locationService`}
                isNotifySupplierDisabled={isNotifySupplierDisabled}
                notifyServiceSupplier={notifyServiceSupplier}
              />
            </>
          ) : null}
        </>
      }
    </KFormTab>
  )
}

export default LocationFormTab
