import { useKRecordContext } from 'hooks/useKRecordContext'
import React, { CSSProperties } from 'react'
import { DateField, DateFieldProps } from 'react-admin'
import { RaRecord } from 'ra-core'
import { getCurrentLang } from '@kampaay/common'

const kDateFieldContainerStyle: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  gap: '.4em'
}

export type KDateFieldProps = Pick<
  DateFieldProps,
  'label' | 'source' | 'showTime' | 'showDate' | 'sortable' | 'emptyText'
> & {
  startIcon?: React.ReactNode
  className?: string
  record?: RaRecord
}

const TIME_OPTIONS = { hour: '2-digit', minute: '2-digit' }
const DATE_OPTIONS = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric'
}
const DATE_TIME_OPTIONS = { ...TIME_OPTIONS, ...DATE_OPTIONS }

/**
 * A simple wrapper of react-admin DateField
 * Prefer always to use this component instead of DateField
 * because it is formatted and localized in the proper way
 * @param source
 * @param label
 * @param showTime
 * @param showDate
 * @param sortable
 * @param startIcon
 */
const KDateField: React.FC<KDateFieldProps> = ({
  source,
  label,
  showTime,
  showDate,
  sortable,
  startIcon,
  className,
  record,
  emptyText
}) => {
  const contextRecord = useKRecordContext()

  /**
   * Here we choose the correct options configuration
   */
  const options =
    showTime && showDate
      ? DATE_TIME_OPTIONS
      : showTime
      ? TIME_OPTIONS
      : DATE_OPTIONS

  const dateField = (
    <DateField
      record={record ?? contextRecord}
      source={source}
      label={label}
      showTime={showTime}
      showDate={showDate}
      options={options}
      locales={getCurrentLang()}
      sortable={sortable}
      className={className}
      emptyText={emptyText}
    />
  )

  return (
    <>
      {startIcon ? (
        <div style={kDateFieldContainerStyle}>
          {startIcon}
          {dateField}
        </div>
      ) : (
        dateField
      )}
    </>
  )
}

export default KDateField
