import React from 'react'
import LocalizedAutoCompleteArrayInput from 'components/Inputs/Localized/LocalizedAutoCompleteArrayInput'
import { useForm } from 'hooks/useForm'
import { RAFormFC } from 'types/common'
import CustomReferenceInput from 'components/Inputs/CustomReferenceInput'
import { hasAccountQueryFilter } from 'services/utils/concierge'
import { MainProduct } from '@kampaay/common'

type Props = {
  fetchWithAccountOnly?: boolean
  queryFilter?: Record<string, string>
}

const SuppliersAutocompleteArrayInput: RAFormFC<Props> = ({
  source,
  queryFilter,
  fetchWithAccountOnly = true
}) => {
  const { getSource } = useForm<MainProduct>(source)
  const finalQueryFilter = {
    ...queryFilter,
    ...(fetchWithAccountOnly ? hasAccountQueryFilter : {})
  }

  return (
    <CustomReferenceInput entity="suppliers" queryFilter={finalQueryFilter}>
      <LocalizedAutoCompleteArrayInput
        label={'resources.suppliers.name'}
        source={getSource('suppliers')}
        fullWidth
        optionText={(choice) => choice.email + ' - ' + choice.name}
      />
    </CustomReferenceInput>
  )
}

export default SuppliersAutocompleteArrayInput
