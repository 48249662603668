import React from 'react'
import ProductSectionFormContent from 'components/Forms/ProductSectionFormContent'
import SectionWrapper from 'components/UI/SectionWrapper'
import { KSimpleForm } from 'components/Forms/KForms/KSimpleForm'
import KEdit from 'components/Forms/KEdit/KEdit'

const MusicSectionEdit: React.FC = () => {
  return (
    <SectionWrapper title={'resources.musicsections.editSection'}>
      <KEdit>
        <KSimpleForm>
          <ProductSectionFormContent />
        </KSimpleForm>
      </KEdit>
    </SectionWrapper>
  )
}

export default MusicSectionEdit
