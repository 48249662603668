import { APICfg } from 'services/api/api'
import { professionalFigureParse, professionalWrite } from '@kampaay/common'
import { uploadOrKeepMultipleImgs } from 'services/utils/images'

const apiCfg: APICfg = {
  endpoint: 'professionalfigure',
  parse: professionalFigureParse,
  write: professionalWrite(uploadOrKeepMultipleImgs)
}

export default apiCfg
