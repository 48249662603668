import {
  MultiLanguageString,
  MultilangParseFunction,
  MultilangWriteFunction,
  getCurrentLang,
  isJSONString,
  parseMultilangJSON,
  writeMultilangJSON
} from '@kampaay/common'
import { useLangContext } from 'contexts/langContext/LangContext'
import { useForm } from 'hooks/useForm'
import React, { useCallback } from 'react'

export type LocalizedInputProps = {
  writeFunction?: MultilangWriteFunction
  parseFunction?: MultilangParseFunction
  source: string
  children: JSX.Element
}

const LocalizedInput: React.FC<LocalizedInputProps> = ({
  writeFunction = writeMultilangJSON,
  parseFunction = parseMultilangJSON,
  source,
  children
}) => {
  const { currentLang } = useLangContext()

  const { useGetValue } = useForm<any>(undefined)
  const value = useGetValue(source)

  const formatHandler = useCallback(
    (v: string) => {
      const parsed = parseFunction(v)

      return parsed[currentLang]
    },
    [currentLang]
  )

  const parseHandler = useCallback(
    (v: string) => {
      const lang =
        currentLang === getCurrentLang() ? currentLang : getCurrentLang()
      const w = writeFunction(
        lang,
        v,
        (isJSONString(value) || parseFunction(value)) as MultiLanguageString
      )

      return w
    },
    [currentLang]
  )

  return React.cloneElement(children, {
    parse: parseHandler,
    format: formatHandler
  })
}

export default LocalizedInput
