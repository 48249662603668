import { yupIgnore } from 'services/yup/helpers'
import { yupString } from 'services/yup/custom-schemas/string'
import { YupSchema } from 'services/yup/types'
import { yupObject } from 'services/yup'
import { CatalogItemBase } from '@kampaay/common'

export const catalogItemBaseVS: YupSchema<CatalogItemBase> = yupObject().shape({
  id: yupIgnore(),
  name: yupString().required().max(200),
  updatedBy: yupIgnore(),
  updatedAt: yupIgnore()
})
