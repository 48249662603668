import { CSVEntityDescriptor } from 'services/csv/types'
import { formatUpselling } from 'services/csv/entities/catalog/common/upselling'
import { FoodUpselling } from '@kampaay/common'

const formatFoodUpselling = ({ logistics, ...rest }: FoodUpselling) => ({
  ...formatUpselling(rest),
  Logistics: logistics.map((id) => ({ ID: id }))
})

export const foodsupselling: CSVEntityDescriptor<FoodUpselling> = {
  format: formatFoodUpselling
}
