import React from 'react'
import OrganizationMenuWrapper from 'components/FormComponents/Organization/OrganizationMenuWrapper'
import OrganizationMembershipInfo from 'components/FormComponents/Organization/Membership/OrganizationMembershipInfo'
import OrganizationMembershipBudgets from 'components/FormComponents/Organization/Membership/Budgets/OrganizationMembershipBudgets'
import KNumberInput from 'components/Inputs/KInputs/KNumberInput/KNumberInput'
import { useParams } from 'react-router-dom'
import OrganizationMembershipSubGroup from 'components/FormComponents/Organization/SubGroups/OrganizationMembershipSubGroup'
import MembershipApprovalFlowSection from 'components/FormComponents/Organization/Membership/MembershipApprovalFlowSection'

const OrganizationMembershipFormContent: React.FC = () => {
  const organizationId = parseInt(useParams().id!)

  return (
    <OrganizationMenuWrapper>
      <KNumberInput
        source="organizationId"
        defaultValue={organizationId}
        className="hidden"
      />
      <OrganizationMembershipInfo />

      <div className="mt-8">
        <MembershipApprovalFlowSection />
      </div>

      <div>
        <OrganizationMembershipSubGroup />
      </div>
      <div className="mt-8">
        <OrganizationMembershipBudgets />
      </div>
    </OrganizationMenuWrapper>
  )
}

export default OrganizationMembershipFormContent
