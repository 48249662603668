import { Location } from 'services/api/entities/catalog/location/types/internal/location-models'
import { CSVEntityDescriptor } from 'services/csv/types'
import {
  formatProductWithUpselling,
  ProductWithUpsellingCSV
} from 'services/csv/entities/catalog/common/productWithUpselling'

type LocationCSV = ProductWithUpsellingCSV & {
  'Booking time-limit': number | undefined
  Nickname: string
  Status: string
  'Full address'?: string
  'People capacity': number | undefined
  'Room capacity': number | undefined
  'Available from': string
  'Available to': string
}

const formatLocation = ({
  advanceReservationMin,
  nickname,
  geoCodedAddress,
  peopleCapacity,
  roomCapacity,
  availableFrom,
  availableTo,
  status,
  ...rest
}: Location): LocationCSV => ({
  ...formatProductWithUpselling(rest),
  'Booking time-limit': advanceReservationMin,
  Nickname: nickname,
  Status: status,
  'Full address': geoCodedAddress?.geoPoint?.formattedAddress,
  'People capacity': peopleCapacity,
  'Room capacity': roomCapacity,
  'Available from': availableFrom,
  'Available to': availableTo
})

export const locations: CSVEntityDescriptor<Location> = {
  format: formatLocation
}
