import React from 'react'
import { AutocompleteInputProps } from 'react-admin'
import KAutocompleteInput from 'components/Inputs/KInputs/KAutocompleteInput'
import CustomReferenceInput from 'components/Inputs/CustomReferenceInput'
import { getEmailName } from 'services/utils'

type Props = Omit<AutocompleteInputProps, 'choices' | 'source' | 'label'>

const KampaayCustomersInput: React.FC<Props> = (props) => {
  return (
    <CustomReferenceInput entity="customers">
      <KAutocompleteInput
        style={{ width: '256px' }}
        source="customer"
        label="form.fields.email"
        optionText={getEmailName}
        {...props}
      />
    </CustomReferenceInput>
  )
}

export default KampaayCustomersInput
