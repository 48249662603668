import { CatalogItemBase, getId } from '@kampaay/common'
import {
  APIProductSection,
  ProductSection
} from 'services/api/entities/catalog/types'

export const productSectionParse = <T extends CatalogItemBase>(
  res: APIProductSection<T>
): ProductSection<T> => ({
  ...res,
  products: res.products.map(getId),
  // in the case of section forms that do not allow adding to a section a product that already has a section assigned
  // we need to pass to the internal model the list of products bound to it to enrich the assignable product choices with its own products
  productsFull: res.products
})
