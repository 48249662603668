import React from 'react'
import { Filter } from 'react-admin'
import { filterConfig } from 'services/filters/index'
import TailoredOrganizationFilter from 'components/Inputs/FilterInputs/TailoredOrganizationFilter'
import TailoredClientFilter from 'components/Inputs/FilterInputs/TailoredClientFilter'
import { TailoredVisibleToAllFilter } from 'components/Inputs/FilterInputs/TailoredVisibleToAllFilter'
import { useKResourceContext } from 'hooks/useKResourceContext'
import { FilterCfg, FilterInputType } from 'services/filters/types'
import GeoAreaPresetInput from 'components/Inputs/Localized/GeoAreaPresetInput'
import KAutocompleteReferenceInput from 'components/Inputs/KInputs/KAutocompleteReferenceInput'
import KAutocompleteReferenceArrayInput from 'components/Inputs/KInputs/KAutocompleteReferenceArrayInput'
import KTextInput from 'components/Inputs/KInputs/KTextInput/KTextInput'
import { KBooleanInput } from 'components/Inputs/KInputs/KBooleanInput'
import KDateInput from 'components/Inputs/KInputs/KDateInput'
import KNumberInput from 'components/Inputs/KInputs/KNumberInput/KNumberInput'
import KSelectInput from 'components/Inputs/KInputs/KSelectInput'
import KNullableBooleanFilter from 'components/Inputs/KInputs/KNullableBooleanFilter'
import KSelectArrayInput from 'components/Inputs/KInputs/KSelectArrayInput'
import EEAssigneeSelectInput from 'resources/operations/events/EEAssigneeSelectInput'
import SuppliersAutocompleteArrayFilterInput from 'components/Inputs/Supplier/SuppliersAutocompleteArrayFilterInput'
import SalesOwnerSelectInput from 'components/Forms/SalesOwnerSelectInput'

export const FILTER_FIELDS_MAP = {
  date: KDateInput,
  number: KNumberInput,
  text: KTextInput,
  organizationFilter: TailoredOrganizationFilter,
  select: KSelectInput,
  selectArray: KSelectArrayInput,
  clientFilter: TailoredClientFilter,
  visibleToAll: TailoredVisibleToAllFilter,
  boolean: KBooleanInput,
  nullableBoolean: KNullableBooleanFilter,
  geoAreaPreset: GeoAreaPresetInput,
  autocomplete: KAutocompleteReferenceInput,
  autocompleteArray: KAutocompleteReferenceArrayInput,
  eeAssigneeSelect: EEAssigneeSelectInput,
  salesOwnerSelect: SalesOwnerSelectInput,
  supplierAutocompleteArray: SuppliersAutocompleteArrayFilterInput
} as const

/**
 * Given a CFG retrieves the correct filter with configuration
 *
 * Note: mappedProps should not have any type, but fixing it requires
 * more effort and the goal of type safety for filters configuration
 * is already achieved
 */
export const getFilterComponent = (
  filter: FilterCfg<FilterInputType>,
  idx: number
) => {
  const inputField = filter.inputField
  const Field = FILTER_FIELDS_MAP[inputField]

  return (
    <Field {...(filter.props as any)} key={`${idx}-${filter.props.source}`} />
  )
}

const KFilters: React.FC = (props) => {
  const resource = useKResourceContext()

  if (!resource) return null

  const filters = filterConfig[resource] ?? []
  const mappedFilters = filters.map(getFilterComponent)

  return <Filter {...props}>{mappedFilters}</Filter>
}

export default KFilters
