import { PaginationPayload } from 'react-admin'
import CFG from 'services/cfg'

/**
 * @param  p pagination params with page and per page
 * @returns the array of pagination params strings
 */
export const encodePaginationParams = (
  p?: PaginationPayload
): { page?: number; perPage?: number } =>
  !p || p?.perPage === CFG.perPageDefault ? {} : p
