import React from 'react'
import KNumberInput, {
  KNumberInputProps
} from 'components/Inputs/KInputs/KNumberInput/KNumberInput'

type HalfStepNumberInputProps = Pick<
  KNumberInputProps,
  'source' | 'validate' | 'label' | 'disabled' | 'defaultValue' | 'className'
>

const HalfStepNumberInput: React.FC<HalfStepNumberInputProps> = ({
  validate = [],
  source,
  label,
  disabled,
  defaultValue,
  className
}) => (
  <KNumberInput
    source={source}
    label={label}
    disabled={disabled}
    defaultValue={defaultValue}
    step={0.5}
    className={className}
    validate={validate}
  />
)

export default HalfStepNumberInput
