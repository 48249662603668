import React, { useEffect } from 'react'
import { BooleanInput, BooleanInputProps } from 'react-admin'
import { useWatch } from 'react-hook-form'
import { KInputProps, useKInput } from 'hooks/useKInput/useKInput'

export type KBooleanInputProps = KInputProps &
  Pick<
    BooleanInputProps,
    | 'source'
    | 'defaultValue'
    | 'disabled'
    | 'onChange'
    | 'fullWidth'
    | 'style'
    | 'parse'
    | 'format'
  >

/**
 * A boolean input that don't allow empty string like value
 * @param source - The source of the field
 * @param label - The label of the field
 * @param defaultValue - The default value of the field
 * @param disabled - If the field is disabled
 * @param onChange - The onChange function
 * @param fullWidth - If the field is full width
 * @param style - The style of the field
 */
export const KBooleanInput: React.FC<KBooleanInputProps> = ({
  source,
  label,
  defaultValue = false,
  disabled,
  onChange: oc,
  fullWidth,
  style,
  parse,
  format,
  className,
  'data-testid': dataTestId
}) => {
  const {
    field: { onChange, value },
    k: { helperText }
  } = useKInput({ source, defaultValue })

  const formValue = useWatch({ name: source })
  useEffect(() => {
    if (
      value === '' ||
      (formValue === undefined && defaultValue !== undefined)
    ) {
      onChange(defaultValue)
    }
  }, [value])

  return (
    <BooleanInput
      label={label}
      source={source}
      disabled={disabled}
      onChange={oc}
      fullWidth={fullWidth}
      style={style}
      className={className}
      parse={parse}
      format={format}
      inputProps={{
        'data-testid': dataTestId
      }}
      helperText={helperText}
    />
  )
}

// extending type of InputHTMLAttributes<HTMLInputElement>
declare module 'react' {
  // eslint-disable-next-line unused-imports/no-unused-vars
  interface InputHTMLAttributes<T> {
    'data-testid'?: string
  }
}
