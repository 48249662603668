import { useKGetList } from 'hooks/useKGetList'
import { Location } from 'services/api/entities/catalog/location/types/internal/location-models'
import { BaseProduct } from 'services/api/entities/catalog/baseProduct/types/internal/baseProduct-models'
import { Drink } from 'services/api/entities/catalog/drink/types/internal/drink-models'
import { Experience, Food, ProfessionalFigure } from '@kampaay/common'

export type CatalogProductsMap = ReturnType<typeof useCatalogProductsMap>

/**
 * Returns a map of all the catalog products
 */
export const useCatalogProductsMap = () => {
  const { list: locations } = useKGetList<Location>('locations')
  const { list: physicals } = useKGetList<Experience>('physicals')
  const { list: foods } = useKGetList<Food>('foods')
  const { list: professionals } =
    useKGetList<ProfessionalFigure>('professionals')
  const { list: rents } = useKGetList<BaseProduct>('rents')
  const { list: musics } = useKGetList<BaseProduct>('musics')
  const { list: drinks } = useKGetList<Drink>('drinks')
  const { list: virtuals } = useKGetList<BaseProduct>('virtuals')

  return {
    locations,
    physicals,
    foods,
    professionals,
    rents,
    musics,
    drinks,
    virtuals
  }
}
