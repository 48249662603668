import { bundleFacetIds } from '../../../../utils'
import type { FileUploader, MultipleFileUploader } from '../../../types'
import { writeGeocodedAddress } from '../../geo-coded-addresses'
import {
  optionedProductWrite,
  type PhysicalOptionedTailoring,
  type PhysicalOptionedTailoringToAPI
} from '../common'
import type {
  Experience,
  ExperienceToAPI,
  PhysicalExperience,
  PhysicalExperienceToAPI
} from './types'

export const experienceWrite =
  (imageUploader: FileUploader, imagesUploader: MultipleFileUploader) =>
  async ({
    tagFacets = [],
    skillFacet,
    ...rest
  }: Experience): Promise<ExperienceToAPI> => {
    const facets = bundleFacetIds(...tagFacets, skillFacet)

    return {
      ...(await optionedProductWrite(imageUploader, imagesUploader)(rest)),
      facets
    }
  }

const writePhysicalOptionedTailoringItem = (
  data: PhysicalOptionedTailoring
): PhysicalOptionedTailoringToAPI => {
  return {
    id: data.id,
    organizationId: data.organizationId,
    isHidden: data.isHidden,
    options: data.options.map((option) => ({
      id: option.id,
      isHidden: false, // options cannot be hidden as for now
      pricing: option.pricing
    })),
    pricing: data.hasCustomPrice ? data.pricing : []
  }
}

export const physicalWrite =
  (imageUploader: FileUploader, imagesUploader: MultipleFileUploader) =>
  async (req: PhysicalExperience): Promise<PhysicalExperienceToAPI> => {
    const {
      teambuildingCategoryFacet,
      settingFacets = [],
      languageFacets = [],
      minDuration,
      maxDuration,
      tailorings,
      ...reqRest
    } = req
    const { facets, ...mappedRest } = await experienceWrite(
      imageUploader,
      imagesUploader
    )(reqRest)
    const tailoringsToApi = tailorings.map((t) =>
      writePhysicalOptionedTailoringItem(t)
    )

    return {
      ...mappedRest,
      facets: bundleFacetIds(
        ...facets,
        teambuildingCategoryFacet === '' ? null : teambuildingCategoryFacet,
        ...settingFacets,
        ...languageFacets
      ),
      minDuration,
      maxDuration,
      tailorings: tailoringsToApi,
      geoCodedAddress: !!req.geoCodedAddress
        ? writeGeocodedAddress(req.geoCodedAddress)!
        : undefined
    }
  }
