import React from 'react'
import { FacetSelectInput } from 'components/Inputs/Facets/BaseComponents/FacetSelectInput'

const FacetFilterLocation = () => (
  <FacetSelectInput
    facetCategory="productFilter"
    filterGroup="Location"
    source="filterLocationFacet"
  />
)

export default FacetFilterLocation
