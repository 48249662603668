import {
  FilterCfg,
  FilterCfgProps,
  FilterInputType
} from 'services/filters/types'

export const buildFilterConfig = <T extends FilterInputType>(
  inputType: T,
  props: FilterCfgProps<T>
): FilterCfg<T> => {
  return {
    inputField: inputType,
    props
  }
}
