import React, { useMemo } from 'react'
import { useTranslate } from 'react-admin'
import { Grid } from '@mui/material'
import { OrganizationBudget } from '@kampaay/common'
import KNumberInput from 'components/Inputs/KInputs/KNumberInput/KNumberInput'
import KDateInput from 'components/Inputs/KInputs/KDateInput'
import { KBooleanInput } from 'components/Inputs/KInputs/KBooleanInput'
import { useFormContext } from 'react-hook-form'
import { useKRecordContext } from 'hooks/useKRecordContext'
import LocalizedTextInput from 'components/Inputs/Localized/LocalizedTextInput'
import OrganizationAvailableBudgetField from 'components/Fields/OrganizationAvailableBudgetField'

const OrganizationBudgetInfo: React.FC = () => {
  const translate = useTranslate()
  const amountSpent = useKRecordContext<OrganizationBudget>()?.amountSpent
  const { watch } = useFormContext()
  const totalAmount = watch('totalAmount')

  const available = useMemo(
    () => (amountSpent ? totalAmount - amountSpent : 0),
    [totalAmount]
  )

  return (
    <div className="w-full" style={{ width: '100%' }}>
      <h2>{translate('tab.label.info')}</h2>
      <Grid
        container
        spacing={2}
        alignItems="center"
        wrap="nowrap"
        width="100%"
      >
        <Grid item xs={6}>
          <LocalizedTextInput
            source="name"
            label="form.fields.name"
            className="w-full"
            helperText={false}
          />
        </Grid>
        <Grid item xs={3}>
          <KNumberInput
            source="totalAmount"
            label="form.fields.total"
            className="w-full"
            helperText={false}
            min={0}
          />
        </Grid>
        <Grid item xs={3}>
          <OrganizationAvailableBudgetField available={available} filled />
        </Grid>
      </Grid>
      <Grid container spacing={2} wrap="nowrap" width="100%">
        <Grid item xs={3}>
          <KDateInput
            source="startDate"
            label="form.fields.startDate"
            className="w-full"
            helperText={false}
          />
        </Grid>
        <Grid item xs={3}>
          <KDateInput
            source="endDate"
            label="form.fields.endDate"
            className="w-full"
            helperText={false}
          />
        </Grid>
        <Grid item xs={3}>
          <KBooleanInput
            source="canBeExceeded"
            label="form.fields.canBeExceeded"
            helperText={false}
            defaultValue={false}
            className="mt-3"
          />
        </Grid>
        <Grid item xs={3}>
          <KBooleanInput
            source="automaticRenewal"
            label="form.fields.automaticRenewal"
            helperText={false}
            defaultValue={false}
            className="mt-3"
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default OrganizationBudgetInfo
