import React from 'react'
import { BaseListProps } from 'components/Lists/CatalogItemBaseList'
import SectionWrapper from 'components/UI/SectionWrapper'
import KList from 'components/Lists/KLists/KList'
import KPagination from 'components/Lists/KPagination'
import { Datagrid, ReferenceField, TextField } from 'react-admin'
import OrganizationMenuWrapper from 'components/FormComponents/Organization/OrganizationMenuWrapper'
import KDateField from 'components/Fields/KDateField'
import EventPlaceholderStatusChip from 'resources/crm/clients/organization/placeholders/EventPlaceholderStatusChip'
import ListItemWithLabel from 'components/UI/ListItemWithLabel'
import KFilters from 'services/filters/KFilters'
import EventPlaceholderDetailsCard from 'resources/crm/clients/organization/placeholders/EventPlaceholderDetailsCard'
import { useParams } from 'react-router-dom'
import { FILTER_SOURCES } from '@kampaay/common'
import { format } from 'date-fns'

const OrganizationEventPlaceholdersList: React.FC<BaseListProps> = () => {
  const { id: organizationId } = useParams()

  return (
    <SectionWrapper>
      <KList
        resource="eventPlaceholder"
        exporter={false}
        pagination={<KPagination />}
        filters={<KFilters />}
        filter={{ [FILTER_SOURCES.organizationId.eq]: organizationId }}
        filterDefaultValues={{
          [FILTER_SOURCES.startDate.gte]: format(new Date(), 'yyyy-MM-dd')
        }}
        perPage={40}
        empty={false}
        sort={{ field: 'startDate', order: 'ASC' }}
      >
        <OrganizationMenuWrapper hasPadding>
          <Datagrid rowClick="expand" expand={<EventPlaceholderDetailsCard />}>
            <TextField source="name" label="org.eventPlaceholders.name" />
            <TextField
              source="customerName"
              label="org.eventPlaceholders.customerName"
            />
            <ReferenceField
              reference="customers"
              source="customerId"
              label="org.eventPlaceholders.customerEmail"
            >
              <TextField source="email" />
            </ReferenceField>
            <KDateField
              source="startDate"
              label="org.eventPlaceholders.startDate"
            />
            <KDateField
              source="endDate"
              label="org.eventPlaceholders.endDate"
            />
            <ListItemWithLabel label="org.eventPlaceholders.status">
              <EventPlaceholderStatusChip />
            </ListItemWithLabel>
          </Datagrid>
        </OrganizationMenuWrapper>
      </KList>
    </SectionWrapper>
  )
}

export default OrganizationEventPlaceholdersList
