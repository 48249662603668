import { GeoAreaPreset } from '@kampaay/common'
import { useKGetList } from 'hooks/useKGetList'

export const useGeoareaPreset = (geoAreaPresetId: number) => {
  const geoAreaPresetList = useKGetList<GeoAreaPreset>('geoareapresets').list
  const geoAreaPresetFull = geoAreaPresetList.find(
    (geoareapresetLocal) => geoareapresetLocal.id === geoAreaPresetId
  )
  const isGeoAreaPresetPublic = !geoAreaPresetFull?.organizations.length
  return { geoAreaPresetFull, isGeoAreaPresetPublic }
}
