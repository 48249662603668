import { styled } from '@mui/material'
import React from 'react'
import { weekDaysList } from 'services/cfg/form'
import KSelectInput from 'components/Inputs/KInputs/KSelectInput'
import { useForm } from 'hooks/useForm'
import { RAFormFC } from 'types/common'
import { APIPricingRuleSet } from '@kampaay/common'

const StyledDiv = styled('div')(() => ({
  display: 'flex'
}))

const WeekDaysRangeInput: RAFormFC = ({ source }) => {
  const { getSource } = useForm<APIPricingRuleSet>(source)

  return (
    <StyledDiv>
      <KSelectInput
        source={getSource('dayFrom')}
        choices={weekDaysList}
        label="form.fields.dayFrom"
        style={{ marginRight: '16px' }}
      />
      <KSelectInput
        source={getSource('dayTo')}
        choices={weekDaysList}
        label="form.fields.dayTo"
      />
    </StyledDiv>
  )
}

export default WeekDaysRangeInput
