import { buildMultilangString, createMockBuilder } from '../../../../utils/test'
import type { ProposalItemToAPI } from '../types'

const buildBaseMock = (): ProposalItemToAPI => ({
  id: 1,
  name: buildMultilangString({
    en: 'proposal item name',
    it: 'nome item proposta'
  }),
  isPriceIncluded: false,
  images: [],
  showDescription: false,
  showImages: false,
  showSupplier: false,
  isSelectable: false,
  layout: 'standard',
  isOutOfScope: false,
  supplierImages: []
})

export const buildProposalItemToAPIMock = createMockBuilder(buildBaseMock)
