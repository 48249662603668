import React from 'react'
import SectionWrapper from 'components/UI/SectionWrapper'
import OptionedSectionFormContent from 'components/Forms/OptionedSectionFormContent'
import { KSimpleForm } from 'components/Forms/KForms/KSimpleForm'
import KCreate from 'components/Forms/KCreate/KCreate'

const FoodSectionCreate: React.FC = () => {
  return (
    <SectionWrapper
      title={'resources.virtualexperiencessections.createSection'}
    >
      <KCreate>
        <KSimpleForm>
          <OptionedSectionFormContent />
        </KSimpleForm>
      </KCreate>
    </SectionWrapper>
  )
}

export default FoodSectionCreate
