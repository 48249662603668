import { Chair } from '@mui/icons-material'
import RentEdit from 'resources/catalog/rent/product/RentEdit'
import RentCreate from 'resources/catalog/rent/product/RentCreate'
import RentList from 'resources/catalog/rent/product/RentList'

const rentIndex = {
  icon: Chair,
  list: RentList,
  edit: RentEdit,
  create: RentCreate
}

export default rentIndex
