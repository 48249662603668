import type { ProposalItem, ProposalItemCopySource } from '../../../../api'
import type { StorageProposalItem } from '../../../../services'
import type { WithOptionalId } from '../../../../types'

export type DuplicateProposalItemConfig = {
  keepGuid: boolean
  keepCreatedAt: boolean
  keepReusedSource: boolean
  resetSelectability: boolean
}

/**
 * Duplicates a proposal item.
 * Can be configured to omit/keep parameters.
 *
 * @param {WithOptionalId<ProposalItem>} proposalItem - The proposal item to be copied.
 * @param {ProposalItemCopySource} reusedSource - The source of the item.
 * @param {number | undefined} proposalId - The ID of the proposal the item comes from.
 * @param {DuplicateProposalItemConfig} config - The configuration for copying the proposal item.
 * @param {boolean} config.keepGuid - Keeps the 'guid' property if true, removes it if false.
 * @param {boolean} config.keepCreatedAt - Keeps the 'createdAt' property if true, removes it if false.
 * @param {boolean} config.keepReusedSource - Keeps the 'reusedSource' and 'reusedProposalItemId' properties if true, overrides the original values if false.
 * @param {boolean} config.resetSelectability - Resets the 'isSelectable' property if true, keeps the original value if false.
 *
 * @returns {StorageProposalItem} - The copied proposal item, in a format suitable for storage.
 *
 * The function is generally used when copypasting or creating new versions of proposal items.
 * While creating a copy of the proposal item, the function is supposed to remove the 'guid', since the guid
 * is used to identify a new version of the same item. A copied item is not a new version but rather a new item.
 *
 * **Why do we need to change the default behavior?**
 * - **keepGuid**: set to true when cutting items instead of copying. Since we are not creating a new item, we should keep the guid.
 * - **keepCreatedAt**: set to true when cutting items instead of copying. Since we are not creating a new item, we should keep the createdAt.
 * - **keepReusedSource**: set to true when creating a new version of an item or cutting instead of copying. By default, we should override the reusedSource and reusedProposalItemId, but not in those cases
 * - **resetSelectability**: set to true when creating a new version of an item. If the selectability is not reset, the final user could select the new version of the item, results in a double booking.
 *
 * @link [cut/paste logic](https://gitlab.com/kampaay/general/-/issues/2459#note_1702739980)
 * @link [isSelectable logic](https://gitlab.com/kampaay/general/-/issues/2669)
 */
export const duplicateProposalItem = (
  { id, ...proposalItem }: WithOptionalId<ProposalItem>,
  reusedSource: ProposalItemCopySource,
  proposalId: number | undefined = undefined,
  config: DuplicateProposalItemConfig = {
    keepGuid: false,
    keepCreatedAt: false,
    keepReusedSource: false,
    resetSelectability: false
  }
): StorageProposalItem => {
  const copiedProposalItem: StorageProposalItem = {
    ...proposalItem,
    proposalId
  }

  if (!config.keepGuid) {
    delete copiedProposalItem.guid
  }

  if (!config.keepCreatedAt) {
    delete copiedProposalItem.createdAt
  }

  if (!config.keepReusedSource) {
    // Proposals coming from the standard catalog never have an id, so they should always have a reusedSource.
    // If the proposalItem does not have an id, it means it's a new item,
    // so, it should not have a reusedSource, since it has not been created yet
    copiedProposalItem.reusedSource =
      reusedSource === 'standardCatalog' || id ? reusedSource : undefined

    // If the proposalItem does not have an id it means it's a new item,
    // so this purposefully becomes undefined.
    // If the proposalItem comes from the catalog, it should not have a reusedProposalItemId
    copiedProposalItem.reusedProposalItemId =
      reusedSource === 'standardCatalog' ? undefined : id
  }

  if (config.resetSelectability) {
    copiedProposalItem.isSelectable = false
  }

  return copiedProposalItem
}
