import React from 'react'
import { FacetSelectInput } from 'components/Inputs/Facets/BaseComponents/FacetSelectInput'

const SkillFacetInput: React.FC = () => (
  <FacetSelectInput
    source="skillFacet"
    facetCategory="productFilter"
    filterGroup="Skill"
  />
)

export default SkillFacetInput
