import { FILTER_SOURCES, conciergeStatusChoices } from '@kampaay/common'
import { buildFilterConfig } from 'services/utils/filters'

export const CONCIERGE_FILTERS = [
  buildFilterConfig('selectArray', {
    source: FILTER_SOURCES.requestStatus.in,
    label: 'form.fields.status',
    alwaysOn: true,
    choices: conciergeStatusChoices
  }),
  buildFilterConfig('text', {
    source: FILTER_SOURCES.name.contains,
    label: 'form.fields.eventName',
    alwaysOn: true
  }),
  buildFilterConfig('eeAssigneeSelect', {
    source: FILTER_SOURCES.kampaayerCoordinators.in,
    alwaysOn: true
  }),
  buildFilterConfig('text', {
    source: FILTER_SOURCES.customerName.contains,
    label: 'form.fields.customerFullName',
    alwaysOn: true
  }),
  buildFilterConfig('text', {
    source: FILTER_SOURCES.customerEmail.contains,
    label: 'form.fields.email',
    alwaysOn: true
  })
]
