import { Menu, MenuItemLinkProps } from 'react-admin'
import events from 'resources/operations/events'
import concierge from 'resources/operations/concierge'
import React, { PropsWithChildren } from 'react'
import { navigate } from 'services/utils/navigationUtils'
import { LocalMall, TipsAndUpdates } from '@mui/icons-material'

type KampaaySharedMenuItemProps = Omit<
  MenuItemLinkProps,
  'to' | 'primaryText' | 'leftIcon'
>

const ConciergeMenuItem = ({
  dense,
  ...restProps
}: KampaaySharedMenuItemProps) => (
  <Menu.Item
    {...restProps}
    to={`/concierge`}
    primaryText="resources.events.concierge"
    leftIcon={<concierge.icon />}
    onClick={(e) => {
      e.preventDefault()
      navigate('concierge', 'vue-app')
    }}
    dense={dense}
  />
)

const ProposalMenuItem = ({
  dense,
  ...restProps
}: KampaaySharedMenuItemProps) => (
  <Menu.Item
    {...restProps}
    to={`/proposal`}
    primaryText="resources.events.standalone"
    leftIcon={<TipsAndUpdates />}
    onClick={(e) => {
      e.preventDefault()
      navigate('inspirations', 'vue-app')
    }}
    dense={dense}
  />
)

const EventsMenuItem = ({
  onClick,
  dense,
  ...restProps
}: KampaaySharedMenuItemProps) => (
  <Menu.Item
    {...restProps}
    to={`/events`}
    primaryText="resources.events.standard"
    leftIcon={<events.icon />}
    onClick={onClick}
    dense={dense}
  />
)

const SuppliersMenuItem = ({
  dense,
  ...restProps
}: KampaaySharedMenuItemProps) => (
  <Menu.Item
    {...restProps}
    to={`/suppliers`}
    primaryText="resources.suppliers.name"
    leftIcon={<LocalMall />}
    onClick={(e) => {
      e.preventDefault()
      navigate('suppliers', 'vue-app')
    }}
    dense={dense}
  />
)

/****************************************************
 * FINAL MENU
 ****************************************************/
type KampaaySharedMenuItems = {
  ConciergeMenuItem: React.FC<KampaaySharedMenuItemProps>
  ProposalMenuItem: React.FC<KampaaySharedMenuItemProps>
  EventsMenuItem: React.FC<KampaaySharedMenuItemProps>
  SuppliersMenuItem: React.FC<KampaaySharedMenuItemProps>
}

const KampaaySharedMenu: React.FC<PropsWithChildren> &
  KampaaySharedMenuItems = ({ children }) => <>{children}</>

KampaaySharedMenu.ConciergeMenuItem = ConciergeMenuItem
KampaaySharedMenu.EventsMenuItem = EventsMenuItem
KampaaySharedMenu.ProposalMenuItem = ProposalMenuItem
KampaaySharedMenu.SuppliersMenuItem = SuppliersMenuItem

export default KampaaySharedMenu
