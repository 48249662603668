import GoogleIcon from '@mui/icons-material/Google'
import { Button } from '@mui/material'
import clsx from 'clsx'
import KToolTip from 'components/UI/KTooltip'
import { useLoggedUser } from 'contexts/loggedUserContext/useLoggedUser'
import { useNotify, useTranslate } from 'ra-core'
import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import { httpClient } from 'services/api/dataProvider/common'
import { BASE_URL } from 'services/utils/urlUtils'
import { WithClassName } from 'types/ui'

type Props = WithClassName

const scope =
  'https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/gmail.labels https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/gmail.modify'

const useConnectGmail = () => {
  const { loggedUserRole } = useLoggedUser()
  const shouldSeeButton =
    loggedUserRole === 'admin' || loggedUserRole === 'eventExcellence'

  const { data: isConnected, isLoading } = useQuery(['connectGmail', 'check'], {
    queryFn: async ({ signal }) => {
      const url = new URL(`${BASE_URL}/google/auth/check`)

      url.searchParams.append('scope', scope)

      try {
        await httpClient(url.toString(), { signal })
        return true
      } catch (error) {
        return false
      }
    },
    enabled: shouldSeeButton
  })

  const connect = async () => {
    const url = new URL(`${BASE_URL}/google/auth/consent`)

    url.searchParams.append('scope', scope)
    url.searchParams.append('redirectUri', window.location.toString())

    const { body: googleLoginUrl } = await httpClient(url.toString(), {})

    window.open(googleLoginUrl, '_self')
  }

  return {
    isButtonVisible: !(isConnected || isLoading || !shouldSeeButton),
    connect
  }
}

export function ConnectGmailButton({ className }: Props) {
  const t = useTranslate()
  const { loggedUserRole } = useLoggedUser()
  const { isButtonVisible, connect } = useConnectGmail()
  const notify = useNotify()

  useEffect(() => {
    if (isButtonVisible && loggedUserRole === 'eventExcellence') {
      notify(t('gmail.notification.enableGmailIntegration.react'), {
        type: 'error',
        multiLine: true
      })
    }
  }, [isButtonVisible])

  if (!isButtonVisible) {
    return null
  }

  return (
    <KToolTip title="button.tooltip.connectGmail">
      <Button
        onClick={connect}
        variant="contained"
        disableElevation
        className={clsx('bg-white normal-case text-black', className)}
        startIcon={<GoogleIcon />}
      >
        {t('button.label.connectGmail')}
      </Button>
    </KToolTip>
  )
}
