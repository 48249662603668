import React from 'react'
import { useKResourceContext } from 'hooks/useKResourceContext'
import LabeledTextField from 'components/Fields/LabeledFields/LabeledTextField'
import KampaayCustomersInput from 'components/Inputs/KampaayCustomersInput'
import { useEventForm } from 'hooks/events/useEventForm'

type Props = {
  customerEditable?: boolean
}
const EditCustomerField: React.FC<Props> = ({ customerEditable = false }) => {
  const { hasTailoredProducts } = useEventForm()
  const resource = useKResourceContext()

  const isDraft = resource === 'eventsdraft'

  const isCustomerEditable = isDraft && !hasTailoredProducts && customerEditable
  return isCustomerEditable ? (
    <KampaayCustomersInput />
  ) : (
    <LabeledTextField source="customerInfo.email" label="form.fields.email" />
  )
}

export default EditCustomerField
