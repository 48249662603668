import { getUnitaryProposalItemPriceOrCost } from '../../../utils'
import { parseFileInfo } from '../fileinfo'
import { parseMultipleImagesWithDescription } from '../images-with-description'
import type {
  APIFoodSelection,
  APIPhysicalSelection,
  APIProfessionalSelection,
  FoodSelection,
  PhysicalSelection,
  ProfessionalSelection
} from './selections'
import type { APIProposalItem, ProposalItem } from './types'

const parseGenericSelection = <
  T extends
    | APIProfessionalSelection
    | APIPhysicalSelection
    | APIFoodSelection
    | undefined
>(
  item: Pick<APIProposalItem, 'name' | 'quantity'>,
  selection: T
) => ({
  ...selection,
  name: item.name,
  quantity: item.quantity
})

export const extractProposalItemSelection = (
  item: Pick<
    APIProposalItem,
    | 'name'
    | 'quantity'
    | 'professionalSelection'
    | 'physicalExperienceSelection'
    | 'foodSelection'
  >
): ProfessionalSelection | PhysicalSelection | FoodSelection | undefined => {
  if (item.professionalSelection) {
    return {
      ...parseGenericSelection(item, item.professionalSelection),
      type: 'staff'
    }
  } else if (item.physicalExperienceSelection) {
    return {
      ...parseGenericSelection(item, item.physicalExperienceSelection),
      type: 'activitiesExperiences'
    }
  } else if (item.foodSelection) {
    return {
      ...parseGenericSelection(item, item.foodSelection),
      type: 'foodBeverage'
    }
  }
}

export const proposalItemParse = (response: APIProposalItem): ProposalItem => {
  const {
    images,
    narrativeDescription,
    showDescription,
    showImages,
    showSupplier,
    layout,
    supplierImages,
    price,
    cost,
    quantity,
    quantity2,
    ...rest
  } = response

  return {
    ...rest,
    unitaryPrice: getUnitaryProposalItemPriceOrCost(price, quantity, quantity2),
    totalPrice: price,
    unitaryCost: getUnitaryProposalItemPriceOrCost(cost, quantity, quantity2),
    totalCost: cost,
    quantity,
    quantity2,
    presentation: {
      images: parseMultipleImagesWithDescription(images ?? []),
      narrativeDescription,
      showDescription: !!showDescription,
      showImages: !!showImages,
      showSupplier: !!showSupplier,
      layout,
      supplierImages: (supplierImages ?? []).map((i) => parseFileInfo(i)!.id!)
    },
    selection: extractProposalItemSelection(response)
  }
}
