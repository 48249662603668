import React from 'react'
import { EditButton, ReferenceArrayField } from 'react-admin'
import { useParams } from 'react-router-dom'
import { useKRecordContext } from 'hooks/useKRecordContext'
import { OrganizationBudget } from '@kampaay/common'
import KHeader from 'components/UI/KHeader'
import OrganizationBudgetMembershipsDatagrid from 'components/FormComponents/Organization/Budget/Memberships/OrganizationBudgetMembershipsDatagrid'
import { parseMultilangOrFallbackToAnyAvailable } from '@kampaay/common'
import OrganizationBudgetSubGroupsDatagrid from 'components/FormComponents/Organization/SubGroups/OrganizationBudgetSubGroupsDatagrid'

const OrganizationBudgetExpandable: React.FC = () => {
  const record = useKRecordContext<OrganizationBudget>()
  const { id } = useParams()

  if (!record) {
    return null
  }

  return (
    <>
      <div className="px-10 pb-16 pt-5">
        <div className="flex w-full items-center justify-between gap-2">
          <KHeader
            className="mb-0"
            title={parseMultilangOrFallbackToAnyAvailable(record.name)}
          />
          <EditButton to={`/organization/${id}/budgets/${record.id}`} />
        </div>
        <div className="w-full">
          <KHeader
            headingLevel="h4"
            title={'form.fields.budget.individualAssignment'}
          />
          <ReferenceArrayField
            reference="organizationmembership"
            source="membershipsIds"
            record={record}
          >
            <OrganizationBudgetMembershipsDatagrid />
          </ReferenceArrayField>
        </div>

        <div className="flex w-full items-center justify-between gap-2 pt-5">
          <KHeader
            headingLevel="h4"
            title={'form.fields.budget.groupAssignment'}
          />
        </div>
        <div className="w-full">
          <ReferenceArrayField
            reference="organizationsubgroup"
            source="organizationSubGroups"
            record={record}
          >
            <OrganizationBudgetSubGroupsDatagrid />
          </ReferenceArrayField>
        </div>
      </div>
    </>
  )
}

export default OrganizationBudgetExpandable
