import React from 'react'
import { List } from '@mui/material'
import { availableFeatureFlags, FeatureFlagConfig } from '@kampaay/common'
import FeatureFlag from 'components/EngineeringPanel/FeatureFlag'

const FeatureFlags = () => {
  return (
    <List>
      {Object.entries(availableFeatureFlags).map(
        ([featureFlagKey, config]: [any, FeatureFlagConfig]) => (
          <FeatureFlag
            key={featureFlagKey}
            featureFlagKey={featureFlagKey}
            config={config}
          />
        )
      )}
    </List>
  )
}

export default FeatureFlags
