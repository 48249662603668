import * as yup from 'yup'
import { AnyObject } from 'yup/lib/types'

/**
 * Enhances yup.boolean() with the possibility to pass the context as a generic parameter:
 *
 * @returns a yup.boolean constructor
 */
export const yupBoolean = <TContext extends AnyObject = AnyObject>() =>
  new yup.BooleanSchema<boolean, TContext>()
