import {
  type APIPricingRuleSet,
  mainProductParse,
  parseQuantityHint,
  parseTimeBeforeAfterHint
} from '../common'
import type {
  APIProfessionalFigure,
  ProfessionalFigure,
  ProfessionalPricingRuleSet
} from './types'

export const parseProfessionalPricing = ({
  timePeriodRules,
  minHoursRules,
  ...rest
}: APIPricingRuleSet): ProfessionalPricingRuleSet => {
  return {
    ...rest,
    // even if API contract supports an array, professional pricing just supports one minHoursRule
    cost: minHoursRules[0]?.cost ?? 0,
    price: minHoursRules[0]?.price ?? 0,
    minHoursRules,
    shiftPricingRules: timePeriodRules.map((e) => ({ ...e, mode: 'tpr' }))
  }
}

export const professionalFigureParse = ({
  pricing,
  ...rest
}: APIProfessionalFigure): ProfessionalFigure => {
  return {
    ...mainProductParse(rest),
    section: rest.sections[0]?.id,
    sectionName: rest.sections[0]?.name,
    ...parseTimeBeforeAfterHint(rest),
    ...parseQuantityHint(rest),
    pricing: pricing.map(parseProfessionalPricing)
  }
}
