import React from 'react'
import KCreate from 'components/Forms/KCreate/KCreate'
import { KSimpleForm } from 'components/Forms/KForms/KSimpleForm'
import SectionWrapper from 'components/UI/SectionWrapper'
import { useParams } from 'react-router-dom'
import OrganizationSubGroupFormContent from 'components/Forms/OrganizationSubGroupFormContent'
import BackButton from 'components/Buttons/BackButton'
import OrganizationMenuWrapper from 'components/FormComponents/Organization/OrganizationMenuWrapper'

const OrganizationSubGroupCreate = () => {
  const { id: organizationId } = useParams()

  return (
    <SectionWrapper title="resources.organization.createSubGroup">
      <KCreate resource="organizationsubgroup">
        <KSimpleForm
          redirectAfterCreate={(_, id) =>
            `organization/${organizationId}/sub-groups/${id}`
          }
        >
          <OrganizationMenuWrapper>
            <BackButton
              redirectPath={`/organization/${organizationId}/sub-groups/`}
            />
            <OrganizationSubGroupFormContent />
          </OrganizationMenuWrapper>
        </KSimpleForm>
      </KCreate>
    </SectionWrapper>
  )
}

export default OrganizationSubGroupCreate
