import { APICfg } from 'services/api/api'
import { LocationFeatureToAPI } from 'services/api/entities/catalog/locationFeatures/types/api/request-models'
import { APILocationFeature } from 'services/api/entities/catalog/locationFeatures/types/api/response-models'
import { LocationFeature } from 'services/api/entities/catalog/locationFeatures/types/internal/locationFeatures-models'
import { parseRaImage } from 'services/api/raParsers'
import { uploadOrKeepImg } from 'services/utils/images'

// ============
// PARSE
// ============

const locationFeatureParse = ({
  images,
  ...res
}: APILocationFeature): LocationFeature => ({
  ...res,
  image: parseRaImage(images[0])
})

// ============
// WRITE
// ============

const locationFeatureWrite = async ({
  image: formImage,
  ...req
}: LocationFeature): Promise<LocationFeatureToAPI> => {
  const image = await uploadOrKeepImg(formImage)
  return {
    ...req,
    images: image ? [image] : []
  }
}

// ============
// CFG
// ============

const apiCfg: APICfg = {
  endpoint: 'locationfeature',
  parse: locationFeatureParse,
  write: locationFeatureWrite
}

export default apiCfg
