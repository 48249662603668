import React from 'react'
import PricingByRulesInput from 'components/FormComponents/pricing/byRules/PricingByRulesInput'
import PricingRulesArrayInput from 'components/FormComponents/pricing/byRules/PricingRulesArrayInput'
import { RAFormFC } from 'types/common'

const LocationByRulePricingInput: RAFormFC = ({ source }) => {
  return (
    <PricingByRulesInput source={source}>
      <PricingRulesArrayInput />
    </PricingByRulesInput>
  )
}

export default LocationByRulePricingInput
