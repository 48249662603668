import { useKRecordContext } from 'hooks/useKRecordContext'
import React from 'react'
import { ChipField } from 'react-admin'
import { EventItem } from 'services/api/entities/events/types/internal/event-models'
import { RAFormFC } from 'types/common'

type EventCountServicesChipFieldProps = {
  label: string
}

const EventCountServicesChipField: RAFormFC<
  EventCountServicesChipFieldProps
> = ({ label }) => {
  const record = useKRecordContext<EventItem>()

  if (!record) {
    return null
  }
  return (
    <ChipField
      source={'count'}
      record={{ count: record?.purchasedServicesArray.length }}
      label={label}
    />
  )
}
export default EventCountServicesChipField
