import React from 'react'
import { Datagrid, TextField } from 'react-admin'
import { BaseListProps } from 'components/Lists/CatalogItemBaseList'
import SectionWrapper from 'components/UI/SectionWrapper'
import KList from 'components/Lists/KLists/KList'

const RuleList: React.FC<BaseListProps> = (props) => {
  return (
    <SectionWrapper>
      <KList {...props} exporter={false} pagination={false}>
        <Datagrid rowClick="edit">
          <TextField source="name" sortable={false} />
        </Datagrid>
      </KList>
    </SectionWrapper>
  )
}

export default RuleList
