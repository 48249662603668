import { ListAlt } from '@mui/icons-material'
import MusicSectionCreate from 'resources/catalog/music/sections/MusicSectionCreate'
import MusicSectionEdit from 'resources/catalog/music/sections/MusicSectionEdit'
import MusicSectionList from 'resources/catalog/music/sections/MusicSectionList'

const musicSectionIndex = {
  icon: ListAlt,
  list: MusicSectionList,
  edit: MusicSectionEdit,
  create: MusicSectionCreate
}

export default musicSectionIndex
