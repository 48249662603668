import { proposalItemParse } from '../proposal-item'
import type { APIProposalItemGroup, ProposalItemGroup } from './types'

export const parseProposalItemGroup = (
  proposalItemGroup: APIProposalItemGroup
): ProposalItemGroup => {
  return {
    ...proposalItemGroup,
    items: proposalItemGroup.items.map(proposalItemParse)
  }
}
