/**
 * @abstract: this is a vanillajs implementation of a render count banner we can
 * port to react or update with new features if needed
 */

let div: HTMLDivElement
let count = 0

const getContainer = (): HTMLDivElement => {
  if (!div) {
    div = document.createElement('div')
    div.style.position = 'fixed'
    div.style.zIndex = '100'
    div.style.bottom = '0'
    div.style.padding = '10px'
    div.style.background = 'green'
    document.body.append(div)
  }
  return div
}

/**
 * TODO: support multiple component observers
 *
 * @param title
 */
export const renderCount = (title?: string) => {
  const div = getContainer()
  count++
  div.innerText = `${title ?? 'Render count'}: ${count}`
}
