import React from 'react'
import { NumberField } from 'react-admin'
import { CatalogItemBaseListProps } from 'components/Lists/CatalogItemBaseList'
import KCloneButton from 'components/Buttons/KCloneButton'
import ProductCommonList from 'components/Lists/ProductCommonList'
import { Upselling } from '@kampaay/common'

const FoodUpsellingList: React.FC<CatalogItemBaseListProps> = (props) => (
  <ProductCommonList {...props}>
    <NumberField
      source="component.defaultCost"
      label="form.fields.defaultCost"
      sortable={false}
    />
    <KCloneButton<Upselling> omittedProperties={['products']} />
  </ProductCommonList>
)

export default FoodUpsellingList
