import { useKGetList } from 'hooks/useKGetList'
import { createContext, useContext } from 'react'

export type CustomReferenceContextType = {
  refetch: ReturnType<typeof useKGetList>['refetch'] | (() => void)
}

const CustomReferenceContext = createContext<CustomReferenceContextType>({
  refetch: () => {}
})

export const CustomReferenceContextProvider = CustomReferenceContext.Provider

export const useCustomReferenceContext = () => {
  const context = useContext(CustomReferenceContext)

  if (context === undefined) {
    throw new Error(
      'hook useCustomReferenceContext must be used within a CustomReferenceContext'
    )
  }

  return context
}
