import { Kampaayer } from '@kampaay/common'
import DeleteAndExportBulk from 'components/Buttons/DeleteAndExportBulk'
import KDateField from 'components/Fields/KDateField'
import TooltipReferenceArrayField from 'components/Fields/TooltipReferenceArrayField'
import GeoareaEventAndTransactionField from 'components/FormComponents/Event/GeoareaEventAndTransactionField'
import EventCountServicesChipField from 'components/Inputs/Events/EventCountServicesChipField'
import KList from 'components/Lists/KLists/KList'
import KPagination from 'components/Lists/KPagination'
import KListToolbar from 'components/Toolbars/KListToolbar'
import SectionWrapper from 'components/UI/SectionWrapper'
import { useKResourceContext } from 'hooks/useKResourceContext'
import React, { PropsWithChildren } from 'react'
import {
  DatagridConfigurable,
  FilterPayload,
  FunctionField,
  TextField,
  useTranslate
} from 'react-admin'
import EventIdEditButton from 'resources/operations/events/EventIdEditButton'
import EventListDetailsCard from 'resources/operations/events/list-components/EventListDetailsCard'
import { EventEntity } from 'services/api/entities'
import { EventItem } from 'services/api/entities/events/types/internal/event-models'
import EventListStatusField from 'resources/operations/events/list-components/EventListStatusField'

type EventListCommonProps = {
  filterDefaultValues?: object
  filter?: FilterPayload
}

const EventListCommon: React.FC<PropsWithChildren<EventListCommonProps>> = ({
  children,
  filterDefaultValues,
  filter
}) => {
  const resource = useKResourceContext<EventEntity>()

  const translate = useTranslate()

  return (
    <SectionWrapper>
      <KList
        exporter={false}
        pagination={<KPagination />}
        perPage={40}
        actions={<KListToolbar resource={resource} />}
        filterDefaultValues={filterDefaultValues}
        filter={filter}
      >
        <DatagridConfigurable
          omit={[
            'name',
            'createdAt',
            'supplierMessagesStatus',
            'kampaayerCoordinatorName',
            'size'
          ]}
          rowClick="expand"
          bulkActionButtons={<DeleteAndExportBulk />}
          expand={<EventListDetailsCard />}
        >
          <EventIdEditButton source="id" />
          <TooltipReferenceArrayField<Kampaayer>
            label="form.fields.salesCoordinatorName"
            source="kampaayerCoordinators"
            reference="kampaayers"
            format={(record) => `${record.firstName} ${record.lastName}`}
          />
          <TextField label="form.fields.name" source="name" sortable={false} />
          <TextField
            label="form.fields.company"
            source="customerInfo.name"
            sortBy="customerName"
          />
          <TextField
            label="form.fields.email"
            source="customerInfo.email"
            sortBy="customerEmail"
          />
          <EventCountServicesChipField label="Services" />
          <KDateField label="form.fields.startDate" source="startDate" />
          <KDateField label="Creation Date" source="createdAt" />
          <GeoareaEventAndTransactionField label="form.fields.geoareaPreset" />

          <EventListStatusField
            label="form.fields.status"
            source="eventStatus"
          />

          <FunctionField
            label="form.fields.size"
            source="size"
            sortable={false}
            render={(record: EventItem) => {
              if (!record.size) return
              return translate(`form.fields.eventSize.${record.size}`)
            }}
          />
          {children}
        </DatagridConfigurable>
      </KList>
    </SectionWrapper>
  )
}
export default EventListCommon
