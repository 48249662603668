import React from 'react'
import SectionWrapper from 'components/UI/SectionWrapper'
import KCreate from 'components/Forms/KCreate/KCreate'
import OrganizationConfigurationTab from 'components/FormComponents/Organization/General/OrganizationConfigurationTab'
import OrganizationCheckoutTab from 'components/FormComponents/Organization/General/OrganizationCheckoutTab'
import KTabbedForm from 'components/Forms/KForms/KTabbedForm'

const OrganizationCreate: React.FC = () => {
  return (
    <SectionWrapper>
      <KCreate>
        <KTabbedForm>
          <OrganizationConfigurationTab />
          <OrganizationCheckoutTab />
        </KTabbedForm>
      </KCreate>
    </SectionWrapper>
  )
}

export default OrganizationCreate
