import React from 'react'
import DescriptionInput from 'components/Inputs/DescriptionInput'
import ProductSectionFormContent from 'components/Forms/ProductSectionFormContent'
import VirtualExpSpecialTypeInput from 'components/Inputs/VirtualExpSpecialTypeInput'
import { SectionEntity } from 'services/api/entities'
import { useKResourceContext } from 'hooks/useKResourceContext'

const OptionedSectionFormContent: React.FC = () => {
  const entity = useKResourceContext<SectionEntity>()
  const isVirtual = entity === 'virtualexperiencessections'

  return (
    <ProductSectionFormContent>
      <DescriptionInput />
      {isVirtual && <VirtualExpSpecialTypeInput />}
    </ProductSectionFormContent>
  )
}

export default OptionedSectionFormContent
