import React, { PropsWithChildren } from 'react'
import { DatagridBody, DatagridBodyProps } from 'react-admin'

export type EditableDatagridBodyProps = PropsWithChildren &
  Pick<DatagridBodyProps, 'data'> & {
    isCreateVisible: boolean
  }

const EditableDatagridBody: React.FC<EditableDatagridBodyProps> = ({
  children,
  data,
  isCreateVisible,
  ...rest
}) => {
  // here we are adding an empty element at the start of the data if
  // the creation element flag is set to true in order to see a new row
  // at the start of the list
  const dynamicData = isCreateVisible ? [{}, ...(data ?? [])] : data

  return (
    <DatagridBody {...rest} data={dynamicData}>
      {children}
    </DatagridBody>
  )
}

export default EditableDatagridBody
