import { APICfg } from 'services/api/api'
import {
  VirtualExperienceSection,
  VirtualExperienceSectionToAPI
} from 'services/api/entities/catalog/sections/experienceSection'
import { convertEmptyStringToUndefined } from 'services/utils'
import { productSectionParse } from 'services/api/entities/catalog/utils'

const apiCfg: APICfg = {
  endpoint: 'virtualexperiencessections',
  parse: productSectionParse,
  write: async ({
    specialType,
    ...rest
  }: VirtualExperienceSection): Promise<VirtualExperienceSectionToAPI> => ({
    ...rest,
    specialType: convertEmptyStringToUndefined(specialType)
  })
}

export default apiCfg
