import {
  KTestConfig,
  KTestFunction,
  UseValidationSchema,
  yupArray,
  yupBoolean,
  yupIgnore,
  yupObject,
  YupSchema
} from 'services/yup'
import { standardPricingArrayVS } from 'services/validations/catalog/standard-pricing/standardPricingArray'
import * as yup from 'yup'
import { OptionedTailoring, Tailorings } from '@kampaay/common'

// ============================================================
// is hidden toggle
// ============================================================
export const atLeastOneToggleIsEnabledIsHidden: KTestFunction<
  boolean,
  OptionedTailoring
> = (isHidden, { parent: { hasCustomPrice, options } }) => {
  // skip test whenever some option is added
  if (options?.length > 0) return true

  return isHidden || hasCustomPrice
}

export const hasAtLeastOneToggleIsEnabledIsHidden: KTestConfig<
  boolean,
  OptionedTailoring
> = {
  name: 'hasAtLeastOneToggleIsEnabledIsHidden',
  test: atLeastOneToggleIsEnabledIsHidden,
  message: 'form.validations.atLeastOneToggleIsEnabled'
}

// ============================================================
// has custom price toggle
// ============================================================
export const atLeastOneToggleIsEnabledCustomPrice: KTestFunction<
  boolean,
  OptionedTailoring
> = (hasCustomPrice, { parent: { isHidden, options } }) => {
  // skip test whenever some option is added
  if (options?.length > 0) return true

  return hasCustomPrice || isHidden
}

export const hasAtLeastOneToggleIsEnabledCustomPrice: KTestConfig<
  boolean,
  OptionedTailoring
> = {
  name: 'hasAtLeastOneToggleIsEnabledCustomPrice',
  test: atLeastOneToggleIsEnabledCustomPrice,
  message: 'form.validations.atLeastOneToggleIsEnabled'
}

// ============================================================
// has at least one pricing when has custom price
// ============================================================
export const atLeastOnePricing: KTestFunction<boolean, OptionedTailoring> = (
  hasCustomPrice,
  { parent: { pricing, isHidden, options } }
) => {
  if (isHidden) return true
  if (options?.length > 0) return true

  return !!hasCustomPrice && !!pricing.length
}

export const hasAtLeastOnePricing: KTestConfig<boolean, OptionedTailoring> = {
  name: 'hasAtLeastOnePricing',
  test: atLeastOnePricing,
  message: 'form.validations.atLeastOnePricing'
}

// ============================================================
// VS
// ============================================================
export const optionVS = yupObject().shape({
  id: yupIgnore(),
  isHidden: yupBoolean().required(),
  hasCustomPrice: yupBoolean().required(),
  pricing: standardPricingArrayVS().min(1)
})

export const organizationTailoringsArrayOptionedVS: YupSchema<OptionedTailoring> =
  yupObject().shape({
    id: yupIgnore(),
    isHidden: yupBoolean<OptionedTailoring>()
      .required()
      .test(hasAtLeastOneToggleIsEnabledIsHidden),
    hasCustomPrice: yupBoolean<OptionedTailoring>()
      .required()
      .test(hasAtLeastOneToggleIsEnabledCustomPrice)
      .test(hasAtLeastOnePricing),
    options: yupArray(optionVS),
    // fix any cast
    pricing: yup.lazy((_, { parent }) =>
      parent.hasCustomPrice ? standardPricingArrayVS() : yupArray()
    ) as any
  })

export const useOrganizationTailoringsOptionedVS: UseValidationSchema<
  Tailorings<OptionedTailoring>
> = () =>
  yupObject().shape({
    id: yupIgnore(),
    tailorings: yupArray(organizationTailoringsArrayOptionedVS)
  })
