import { getDateAsString } from '@kampaay/common'
import { Kampaayer } from '@kampaay/common'
import { CSVEntityDescriptor } from 'services/csv/types'

export const kampaayers: CSVEntityDescriptor<Kampaayer> = {
  format: (record) => ({
    ID: record.id,
    Firstname: record.firstName,
    Lastname: record.lastName,
    Phone: record.phone,
    Email: record.email,
    'Birth Date': getDateAsString(record.birthDate),
    'Birth Place': record.birthPlace,
    'Personal Number': record.personalNumber,
    'Residence Address': record.residenceAddress,
    'Domicile Address': record.domicileAddress
  })
}
