import React from 'react'
import { useFormContext } from 'react-hook-form'
import {
  KBooleanInput,
  KBooleanInputProps
} from 'components/Inputs/KInputs/KBooleanInput'

type Props = Pick<
  KBooleanInputProps,
  'source' | 'label' | 'defaultValue' | 'disabled' | 'onChange'
> & {
  dependentFieldsSources: string[]
}

/**
 * Boolean Input which allows only one field to be true at a time, setting to false the other specified dependent fields.
 *
 * @param dependentFieldsSources: array of sources that are dependent on the value of the input,
 * if this gets set to true, then every other dependent field will be set to false
 * @param source
 * @param onChange
 * @param label
 * @param defaultValue
 * @param disabled
 */
const ExclusiveBoolean: React.FC<Props> = ({
  source,
  dependentFieldsSources,
  onChange,
  label,
  defaultValue,
  disabled
}) => {
  const { setValue } = useFormContext()

  const internalOnChange = (_event: any, e: boolean) => {
    if (onChange) onChange(_event, e)
    // Turning off the dependent fields
    dependentFieldsSources.forEach((source) => {
      setValue(source, false)
    })
    setValue(source, e)
  }
  return (
    <KBooleanInput
      label={label}
      defaultValue={defaultValue}
      disabled={disabled}
      source={source}
      onChange={internalOnChange}
    />
  )
}

export default ExclusiveBoolean
