import { UpdateParams } from 'react-admin'
import { MappedEnrichedEvent } from 'services/api/entities/events/types/internal/event-models'
import { mapEventToAPI } from 'services/api/entities/events/eventsMultiplexMap'
import { multiPlexUpdateHandler } from 'services/api/dataProvider/common'
import { APIEntity } from 'services/api/entities/index'

export const eventUpdate = (
  resource: string,
  { data }: UpdateParams<MappedEnrichedEvent>
) => {
  const { surveyResponses, ...event } = mapEventToAPI(
    data as MappedEnrichedEvent
  )
  const eventId = event.id

  return multiPlexUpdateHandler(
    {
      resource: resource as APIEntity,
      params: { id: eventId, data: event }
    },
    {
      resource: 'eventbrief',
      params: {
        data: surveyResponses,
        id: eventId
      }
    }
  )
}
