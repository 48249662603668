import React, { PropsWithChildren } from 'react'
import { Edit, EditProps } from 'react-admin'
import { UseMutationOptions } from 'react-query'
import { UseUpdateMutateParams } from 'ra-core'

type NativeMutationOptions = UseMutationOptions<
  any,
  unknown,
  UseUpdateMutateParams
>

// onSuccess and onError from the KSaveButton wrapper overwrite the ones from the Edit component,
// therefore we remove them from the interface in order to avoid missing this
// redirect should always be disabled for edit forms as defined in this issue: https://gitlab.com/kampaay/general/-/issues/2004
type Props = PropsWithChildren<
  Omit<EditProps, 'mutationOptions' | 'redirect'> & {
    mutationOptions?: Omit<NativeMutationOptions, 'onSuccess' | 'onError'>
  }
>

const KEdit: React.FC<Props> = ({
  children,
  mutationMode = 'pessimistic',
  ...rest
}) => {
  return (
    <Edit mutationMode={mutationMode} redirect={false} {...rest}>
      {children}
    </Edit>
  )
}

export default KEdit
