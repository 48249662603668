import React from 'react'
import { BaseListProps } from 'components/Lists/CatalogItemBaseList'
import EventListCommon from 'resources/operations/events/EventListCommon'
import { RAFormFC } from 'types/common'
import StatusChipField from 'components/Inputs/Events/EventStatusChipField'

const DraftEventList: RAFormFC<BaseListProps> = () => {
  return (
    <EventListCommon>
      <StatusChipField
        label="form.fields.supplierMessage"
        source="supplierMessagesStatus"
      />
    </EventListCommon>
  )
}
export default DraftEventList
