import React from 'react'
import { SelectInput, SelectInputProps } from 'react-admin'
import { KInputProps, useKInput } from 'hooks/useKInput/useKInput'

// no need to use the resettable prop since the input always renders the empty item as the first one when it's not required
export type KSelectInputProps = KInputProps &
  Omit<SelectInputProps, 'resettable'>

/**
 * This allows us to keep an undefined value in the form state, and have the SelectInput not force "" as a value
 * The SelectInput will always have a "" value when empty, while the form will have undefined
 *
 * @param v the current value of the input
 * @param choices the choices of the input
 * @param optionValue the key of the choice whose value is used as the input's value
 */
export const formatKSelectInputValue = <
  TValue extends string | number,
  TObject extends object,
  TKey extends keyof TObject
>(
  v: TValue,
  choices: TObject[] | undefined,
  optionValue: TKey
) => {
  if (!v || !choices || choices.every((o) => o[optionValue] !== v)) {
    return ''
  } else return v
}

const KSelectInput: React.FC<KSelectInputProps> = (props) => {
  const {
    source,
    parse = (a: string) => a,
    format = (a: string) => a,
    choices,
    optionValue = 'id',
    ...restProps
  } = props

  const {
    isRequired,
    k: { helperText }
  } = useKInput(props)

  return (
    <SelectInput
      source={source}
      choices={choices}
      format={(v) => formatKSelectInputValue(format(v), choices, optionValue)}
      parse={(v) => parse(v ? v : undefined)}
      optionValue={optionValue}
      {...restProps}
      isRequired={isRequired}
      required={isRequired}
      helperText={helperText}
    />
  )
}

export default KSelectInput
