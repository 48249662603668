import { APIEntity } from 'services/api/entities'
import { useFoodVS } from 'services/validations/catalog/food/food'
import { useVirtualVS } from 'services/validations/catalog/virtual'
import { useLocationVS } from 'services/validations/catalog/location'
import { usePhysicalVS } from 'services/validations/catalog/physical'
import { useProfessionalVS } from 'services/validations/catalog/professional'
import { useBaseProductVS } from 'services/validations/catalog/baseProduct'
import { useDrinkVS } from 'services/validations/catalog/drink'
import { useCustomerVS } from 'services/validations/crm/customer'
import { useOrganizationVS } from 'services/validations/crm/organization'
import { useEventVS } from 'services/validations/operations/events'
import { useFoodUpsellingVS } from 'services/validations/catalog/food-upselling/foodUpselling'
import { useVirtualUpsellingVS } from 'services/validations/catalog/virtualUpselling'
import { useLocationFeatureVS } from 'services/validations/catalog/locationFeature'
import { useLogisticVS } from 'services/validations/catalog/logistic'
import { useDrinkSectionVS } from 'services/validations/catalog/sections/drinkSection'
import { useProductSectionWithDescriptionVS } from 'services/validations/catalog/sections/productSectionWithDescriptionVS'
import { useVirtualSectionVS } from 'services/validations/catalog/sections/virtualSection'
import { useProductSectionVS } from 'services/validations/catalog/sections/productSection'
import { useProfessionalSectionVS } from 'services/validations/catalog/sections/professionalSection'
import { useKampaayerVS } from 'services/validations/crm/kampaayer'
import { useRulesVS } from 'services/validations/catalog/rules'
import { ObjectSchema } from 'yup'
import { useOrganizationTailoringsOptionedVS } from 'services/validations/crm/oraganizationTailorings/organizationTailoringsOptioned'
import { useOrganizationTailoringsLocationVS } from 'services/validations/crm/oraganizationTailorings/organizationTailoringsLocation'
import { useOrganizationTailoringsProfessionalVS } from 'services/validations/crm/oraganizationTailorings/organizationTailoringsProfessional'
import { useOrganizationTailoringsProductVS } from 'services/validations/crm/oraganizationTailorings/organizationTailoringsProduct'
import { useOrganizationTailoringsDrinkVS } from 'services/validations/crm/oraganizationTailorings/organizationTailoringsDrink'
import { servicesConfigVS } from 'services/validations/servicesConfig'
import { useUpsellingVS } from 'services/validations/catalog/upselling'
import useSupplierTypeVS from 'services/validations/supplierType'
import useAreaVS from 'services/validations/area'
import useOrganizationBudgetVS from 'services/validations/crm/organization/budget'
import useOrganizationMembershipVS from 'services/validations/crm/organization/membership'
import useMailingListVS from 'services/validations/mailing-list'
import useEventPlaceholderCategoryVS from 'services/validations/event-placeholder-category'
import useOrganizationSubGroupVS from 'services/validations/crm/organization/sub-group'

export type EntitiesWithForm = Exclude<
  APIEntity,
  | 'setConciergeStatus'
  | 'eventbrief'
  | 'emailmessage'
  | 'transactionexportforpurchaseorder'
  | 'mergeSuppliers'
  | 'eventPlaceholder'
  | 'guestmanagement'
>

export const VS_MAP: Record<
  EntitiesWithForm,
  // FIXME: this should be UseValidationSchema<any> but it's not working
  (() => ObjectSchema<any>) | undefined
> = {
  eventsdraft: useEventVS,
  events: useEventVS,
  concierge: undefined,
  facets: undefined,
  transaction: undefined,
  geoareapresets: undefined,
  logistics: useLogisticVS,
  servicesconfig: servicesConfigVS,
  locationfeatures: useLocationFeatureVS,
  rules: useRulesVS,
  // CRM
  customers: useCustomerVS,
  suppliers: undefined,
  organization: useOrganizationVS,
  kampaayers: useKampaayerVS,
  // SECTIONS VS
  drinksections: useDrinkSectionVS,
  foodsections: useProductSectionWithDescriptionVS,
  musicsections: useProductSectionVS,
  rentsections: useProductSectionVS,
  virtualexperiencessections: useVirtualSectionVS,
  professionalsections: useProfessionalSectionVS,
  locationsections: useProductSectionVS,
  physicalsections: useProductSectionWithDescriptionVS,
  // UPSELLING VS
  foodsupselling: useFoodUpsellingVS,
  locationupselling: useUpsellingVS,
  virtualupselling: useVirtualUpsellingVS,
  physicalupselling: useUpsellingVS,
  // PRODUCTS VS
  foods: useFoodVS,
  virtuals: useVirtualVS,
  locations: useLocationVS,
  physicals: usePhysicalVS,
  professionals: useProfessionalVS,
  musics: useBaseProductVS,
  drinks: useDrinkVS,
  rents: useBaseProductVS,
  // ORGANIZATION EMS TAILORINGS
  ['organization/:id/tailoring/foods']: useOrganizationTailoringsOptionedVS,
  ['organization/:id/tailoring/virtuals']: useOrganizationTailoringsOptionedVS,
  ['organization/:id/tailoring/physicals']: useOrganizationTailoringsOptionedVS,
  ['organization/:id/tailoring/locations']: useOrganizationTailoringsLocationVS,
  ['organization/:id/tailoring/professionals']:
    useOrganizationTailoringsProfessionalVS,
  ['organization/:id/tailoring/musics']: useOrganizationTailoringsProductVS,
  ['organization/:id/tailoring/rents']: useOrganizationTailoringsProductVS,
  ['organization/:id/tailoring/foodsupselling']:
    useOrganizationTailoringsProductVS,
  ['organization/:id/tailoring/locationupselling']:
    useOrganizationTailoringsProductVS,
  ['organization/:id/tailoring/physicalupselling']:
    useOrganizationTailoringsProductVS,
  ['organization/:id/tailoring/virtualupselling']:
    useOrganizationTailoringsProductVS,
  ['organization/:id/tailoring/logistics']: useOrganizationTailoringsProductVS,
  ['organization/:id/tailoring/drinks']: useOrganizationTailoringsDrinkVS,
  proposal: undefined,
  proposalHistory: undefined,
  area: useAreaVS,
  suppliertype: useSupplierTypeVS,
  organizationbudget: useOrganizationBudgetVS,
  organizationsubgroup: useOrganizationSubGroupVS,
  organizationmembership: useOrganizationMembershipVS,
  mailingList: useMailingListVS,
  eventplaceholdercategory: useEventPlaceholderCategoryVS
}
