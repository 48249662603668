import { styled } from '@mui/system'
import React from 'react'
import { ArrayInput, SimpleFormIterator } from 'react-admin'
import { parseMultilangOrFallbackToAnyAvailable } from '@kampaay/common'

const PREFIX = 'EventBaseSelectionInputWrapper'

const classes = {
  arrayInputPadding: `${PREFIX}-arrayInputPadding`
}

const StyledArrayInput = styled(ArrayInput)({
  [`& .${classes.arrayInputPadding}`]: {
    padding: '16px 0 0 0'
  }
})

export type EventBaseSelectionInputWrapperProps = {
  source: string
  disableInputs?: boolean
  arrayInputLabel?: string
  children?: JSX.Element
  disableAdd?: boolean
}

const EventBaseSelectionInputWrapper: React.FC<
  EventBaseSelectionInputWrapperProps
> = ({
  source,
  disableInputs = false,
  arrayInputLabel = '',
  children,
  disableAdd = disableInputs
}) => {
  const translatedArrayInputLabel =
    parseMultilangOrFallbackToAnyAvailable(arrayInputLabel)

  return (
    <StyledArrayInput
      source={source}
      label={translatedArrayInputLabel}
      className={classes.arrayInputPadding}
      fullWidth
      disabled={disableInputs}
    >
      <SimpleFormIterator
        disableAdd={disableAdd}
        disableRemove={disableInputs}
        disableReordering
      >
        {children}
      </SimpleFormIterator>
    </StyledArrayInput>
  )
}

export default EventBaseSelectionInputWrapper
