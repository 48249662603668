import { parseGuestManagement } from '@kampaay/common'
import { APICfg } from 'services/api/api'

const apiCfg: APICfg = {
  endpoint: 'guestmanagement',
  parse: parseGuestManagement,
  write: (data) => data
}

export default apiCfg
