import { APICfg } from 'services/api/api'
import { APILocation } from 'services/api/entities/catalog/location/types/api/response-models'
import {
  ProductSection,
  ProductSectionToAPI
} from 'services/api/entities/catalog/types'
import { productSectionParse } from 'services/api/entities/catalog/utils'

export type LocationSection = ProductSection<APILocation>

type LocationSectionToApi = ProductSectionToAPI

const apiCfg: APICfg = {
  endpoint: 'locationsections',
  parse: productSectionParse,
  write: async (req: LocationSection): Promise<LocationSectionToApi> => req
}
export default apiCfg
