import { MusicNote } from '@mui/icons-material'
import MusicEdit from 'resources/catalog/music/product/MusicEdit'
import MusicCreate from 'resources/catalog/music/product/MusicCreate'
import MusicList from 'resources/catalog/music/product/MusicList'

const musicIndex = {
  icon: MusicNote,
  list: MusicList,
  edit: MusicEdit,
  create: MusicCreate
}

export default musicIndex
