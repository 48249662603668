import { proposalHistoryParse, proposalHistoryWrite } from '@kampaay/common'
import { APICfg } from 'services/api/api'

//====================================================
// CFG
//====================================================
const apiCfg = {
  endpoint: 'proposalHistory',
  parse: proposalHistoryParse,
  write: proposalHistoryWrite
} as APICfg

export default apiCfg
