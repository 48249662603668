import React from 'react'
import { SimpleFormIterator } from 'react-admin'
import EventBriefQuestionInput from 'components/FormComponents/EventBriefQuestionInput'
import EventBriefBulkToggle from 'components/Toggles/EventBriefBulkToggle/EventBriefBulkToggle'
import { NotifySupplierErrors } from 'hooks/events/useEventService'
import { useEventForm } from 'hooks/events/useEventForm'
import NotifySupplierSection from 'components/FormComponents/NotifySupplierSection'
import KArrayInput from 'components/Inputs/KInputs/KArrayInput'

type Props = {
  serviceSource: string
  notifyServiceSupplier?: () => void
  isNotifySupplierDisabled?: NotifySupplierErrors
}

const EventServiceBriefSection: React.FC<Props> = ({
  serviceSource,
  notifyServiceSupplier,
  isNotifySupplierDisabled
}) => {
  const isInGeneralTab = serviceSource === 'generalService'
  const { notifyAllSuppliers, ableToNotifyAllSuppliers } = useEventForm()

  return (
    <React.Fragment>
      <div
        style={{
          width: '100%',
          display: 'flex',
          gap: '2em',
          flexDirection: 'column',
          alignItems: 'end'
        }}
      >
        {isInGeneralTab && <EventBriefBulkToggle />}
        <EventBriefBulkToggle serviceSource={serviceSource} />
        <NotifySupplierSection
          ableToNotifyAllSuppliers={ableToNotifyAllSuppliers}
          notifyAllSuppliers={notifyAllSuppliers}
          isInGeneralTab={isInGeneralTab}
          isNotifySupplierDisabled={isNotifySupplierDisabled}
          notifyServiceSupplier={notifyServiceSupplier}
          serviceSource={serviceSource}
        />
      </div>
      <KArrayInput
        source={`${serviceSource}.briefQuestions`}
        label="form.fields.briefQuestions"
      >
        <SimpleFormIterator disableAdd disableRemove disableReordering>
          <EventBriefQuestionInput />
        </SimpleFormIterator>
      </KArrayInput>
    </React.Fragment>
  )
}

export default EventServiceBriefSection
