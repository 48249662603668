import { geoareaPresetParse, geoareaPresetWrite } from '@kampaay/common'
import { APICfg } from 'services/api/api'

////////////////////////////////
// CFG
////////////////////////////////

const geoAreaPresetCfg: APICfg = {
  endpoint: 'geoareapreset',
  parse: geoareaPresetParse,
  write: geoareaPresetWrite
}

export default geoAreaPresetCfg
