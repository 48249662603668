import { createMockBuilder, OrganizationMembership } from '@kampaay/common'

const buildBaseMock = (): OrganizationMembership => ({
  id: 1,
  userId: 1,
  organizationId: 2,
  role: 'admin',
  activeBudgetIds: [1],
  userName: 'Mr. Kampaay',
  userEmail: 'mr@kampaay.com',
  approvalSteps: [],
  disableApprovalFlow: false
})

export const buildOrganizationMembershipMock = createMockBuilder(buildBaseMock)
