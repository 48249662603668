import React from 'react'
import CatalogItemBaseList, {
  CatalogItemBaseListProps
} from 'components/Lists/CatalogItemBaseList'

const PhysicalExperienceSectionList: React.FC<CatalogItemBaseListProps> = (
  props
) => <CatalogItemBaseList {...props} />

export default PhysicalExperienceSectionList
