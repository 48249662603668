import { getId, WithId } from '@kampaay/common'
import { KConsole } from 'services/console'

/**
 * Takes an array as parameter and returns true if the array is empty
 * else returns false
 * @param collection - Array to check
 */
export const isEmpty = <T>(collection: T[]) => collection.length === 0

/**
 * Takes an array as parameter and returns true if the array is not empty
 * else returns false
 * @param collection - Array to check
 */
export const isNotEmpty = <T>(collection: T[]) => !isEmpty(collection)

/**
 * Takes an array of arrays of primitives
 * and returns the intersection of all the elements in common
 * between the arrays
 * @param collections - Array of collections of primitives
 */
export const intersect = <T extends number | string>(...collections: T[][]) => {
  if (collections.length === 0) return []
  const [first, ...rest] = collections
  return first.filter((value) =>
    rest.every((collection) => collection.includes(value))
  )
}

/**
 * Intersects the array of arrays filtering out the empty arrays before
 * @param collections - Array of collections of primitives
 */
export const intersectNonEmptyArrays = <T extends number | string>(
  collections: T[][]
): T[] => intersect(...collections.filter(isNotEmpty))

/**
 * This function extends the functionality of intersect also to objects
 * to do so it takes a function to retrieve the primitive key of each object
 * (like for example the id of an object) and the array of objects with those
 * keys in common between the collections
 * @param collections - Array of collections of objects
 * @param getKeyFn - Function to retrieve the primitive key of each object
 */
export const intersectObjects = <T extends object>(
  collections: T[][],
  getKeyFn: (c: T) => string | number
): T[] => {
  const primitiveCollection = collections.map((c) => c.map(getKeyFn))

  const intersection = intersect(...primitiveCollection)

  return (collections[0] ?? []).filter((c) =>
    intersection.includes(getKeyFn(c))
  )
}

/**
 * Given an array of collections of objects with ids returns the intersection of
 * the objects with ids in common between all the collections
 * @param collections - Array of collections of objects with ids
 */
export const intersectObjectById = <T extends WithId>(
  collections: T[][]
): T[] => intersectObjects(collections, getId)

/**
 * check whether some array item
 * is included in ALL array of arrays
 *
 * e.g.
 *
 * collectionArray:
 * [
 *   [1,5],
 *   [3,1,5],
 *   [6,1]
 * ]
 *
 * collection:
 * [1,2,3] -> true, because 1 is included in all arrays of collectionArray
 * [5,6] -> false, because no items are included in all arrays of collectionArray
 *
 *
 * @param collections
 */
export const isIntersecting = <T extends number | string>(
  ...collections: T[][]
): boolean => {
  return intersect(...collections)?.length > 0
}

/**
 * This util takes an array, a get key function and a get value function
 * Foreach element creates a property in the object with the key returned by the
 * get key function and the value returned by the get value function. It is
 * a way to do in a nicer way the ugly reduce of the objects
 * @param array - Array to map to object
 * @param getKeyFn - Function to get the key of the object
 * @param getValueFn - Function to get the value of the object
 *
 * @example
 * const array = [
 *  { id: 1, name: 'John' },
 *  { id: 2, name: 'Jane' },
 * ]
 *
 * // returns { 1: 'John', 2: 'Jane' }
 * const result = mapToObject(array, (item) => item.id, (item) => item.name)
 */
export const mapToObject = <
  TArrayValue extends any,
  TKey extends string | number,
  TValue
>(
  array: TArrayValue[] | readonly TArrayValue[],
  getKeyFn: (v: TArrayValue) => TKey,
  getValueFn: (v: TArrayValue) => TValue
) => {
  return [...array].reduce<Record<TKey, TValue>>((acc, item) => {
    const key = getKeyFn(item)

    if (key in acc) {
      KConsole.error(
        `mapToObject: the get key fn returned 2 times the same key: ${key}, please ensure that`
      )
    }

    acc[key] = getValueFn(item)
    return acc
  }, Object.create(null))
}
