/**
 * Maps the internal service collection names to the names of the services in the Supplier DTOs
 */
export const SUPPLIER_SERVICE_NAMES_MAP = {
  foods: 'foods',
  drinks: 'drinks',
  locations: 'locations',
  musics: 'musics',
  rents: 'rents',
  physicals: 'physicalExperiences',
  virtuals: 'virtualExperiences',
  professionals: 'professionalFigures'
} as const
