import React from 'react'
import { RAFormFC } from 'types/common'
import { useForm } from 'hooks/useForm'
import ProfessionalByRulePricingInput from 'components/FormComponents/pricing/byRules/ProfessionalByRulePricingInput'
import { ProductTailoring, ProfessionalPricingRuleSet } from '@kampaay/common'

const ProfessionalTailoringsInput: RAFormFC = ({ source }) => {
  const { useGetValue } =
    useForm<ProductTailoring<ProfessionalPricingRuleSet>>(source)
  const hasCustomPrice = useGetValue('hasCustomPrice')

  // =======================
  // JSX
  // =======================
  return (
    <>
      {hasCustomPrice ? (
        <ProfessionalByRulePricingInput source={source} />
      ) : null}
    </>
  )
}

export default ProfessionalTailoringsInput
