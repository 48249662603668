import EditableList from 'components/Lists/EditableList/EditableList'
import SectionWrapper from 'components/UI/SectionWrapper'
import React from 'react'
import AreaFormContent from 'resources/area/AreaFormContent'
import LocalizedTextField from 'components/Fields/Localized/LocalizedTextField'
import KPagination from 'components/Lists/KPagination'

type AreaListProps = {}

const AreaList: React.FC<AreaListProps> = () => {
  return (
    <SectionWrapper title="resources.area.name">
      <EditableList
        formContent={<AreaFormContent />}
        perPage={40}
        pagination={<KPagination />}
      >
        <LocalizedTextField source="name" label="form.fields.name" />
      </EditableList>
    </SectionWrapper>
  )
}

export default AreaList
