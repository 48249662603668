import React from 'react'
import KPagination from 'components/Lists/KPagination'
import {
  ChipField,
  CreateButton,
  Datagrid,
  EditButton,
  FunctionField
} from 'react-admin'
import {
  FILTER_SOURCES,
  parseMultilangOrFallbackToAnyAvailable
} from '@kampaay/common'
import SectionWrapper from 'components/UI/SectionWrapper'
import KList from 'components/Lists/KLists/KList'
import { useParams } from 'react-router-dom'
import OrganizationMenuWrapper from 'components/FormComponents/Organization/OrganizationMenuWrapper'
import { OrganizationBudget } from '@kampaay/common'
import OrganizationSubGroupExpandable from 'components/FormComponents/Organization/SubGroups/OrganizationSubGroupExpandable'

const OrganizationSubGroupList = () => {
  const { id } = useParams()

  return (
    <SectionWrapper>
      <KList
        resource="organizationsubgroup"
        exporter={false}
        pagination={<KPagination />}
        perPage={40}
        sort={{ field: 'id', order: 'ASC' }}
        actions={
          <CreateButton
            label="resources.organization.createSubGroupLower"
            to={`/organization/${id}/sub-groups/create`}
          />
        }
        filter={{ [FILTER_SOURCES.organizationId.eq]: id }}
        empty={false}
      >
        <OrganizationMenuWrapper hasPadding>
          <Datagrid
            rowClick="expand"
            expand={<OrganizationSubGroupExpandable />}
          >
            <FunctionField
              label="form.fields.name"
              sortable={false}
              render={(record: OrganizationBudget) => (
                <EditButton
                  label={parseMultilangOrFallbackToAnyAvailable(record.name)}
                  to={`/organization/${id}/sub-groups/${record.id}`}
                  icon={<></>}
                  className="normal-case"
                />
              )}
            />
            <FunctionField
              label="form.fields.members"
              sortable={false}
              render={(record: OrganizationBudget) => (
                <ChipField
                  record={{ members: record.membershipsIds.length }}
                  source="members"
                />
              )}
            />
          </Datagrid>
        </OrganizationMenuWrapper>
      </KList>
    </SectionWrapper>
  )
}

export default OrganizationSubGroupList
