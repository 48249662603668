import { DisplaySettings, Save } from '@mui/icons-material'
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  IconButton,
  Tab,
  Tabs,
  Tooltip
} from '@mui/material'
import { useTranslate } from 'ra-core'
import React, { useState } from 'react'
import FeatureFlags from 'components/EngineeringPanel/FeatureFlags'

const EngineeringPanel = () => {
  const [open, setOpen] = useState<boolean>(false)
  const [tab, setTab] = useState<number>(0)
  const translate = useTranslate()

  return (
    <>
      <Tooltip title={translate('engineeringPanel.title')}>
        <IconButton className="text-neutral-50" onClick={() => setOpen(true)}>
          <DisplaySettings />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <div style={{ padding: '10px 30px', width: 800 }}>
          <h3>{translate('engineeringPanel.title')}</h3>
        </div>
        <Tabs value={tab} onChange={(_, newTab) => setTab(newTab)}>
          <Tab label={translate('engineeringPanel.featureFlags.title')} />
        </Tabs>
        <div style={{ padding: 30 }}>
          {tab === 0 && (
            <div
              style={{ display: 'flex', flexDirection: 'column', rowGap: 10 }}
            >
              <Alert severity="warning">
                {translate('engineeringPanel.featureFlags.alert')}
              </Alert>
              <FeatureFlags />
            </div>
          )}
        </div>
        <DialogActions>
          <Button onClick={() => window.location.reload()} startIcon={<Save />}>
            {translate('engineeringPanel.saveAndReload')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default EngineeringPanel
