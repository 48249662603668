import { CSSProperties } from 'react'
import { KampaayTheme, kampaayTheme } from 'layout/config/themes'
import { CreateStyledComponent } from '@mui/styled-engine'
import { styled } from '@mui/material/styles'
import { entries } from 'services/utils/object'

/**
 * This is the type of map of class names and their scoped version
 *
 * @example
 * // {
 * //   displayCenter: 'kampaay-text-field-displayCenter'
 * // }
 * type AClassesMap = ScopedClassNamesMap<{
 *  displayCenter: {
 *    display: 'flex',
 *    justifyContent: 'center',
 *  }}, 'kampaay-text-field'>
 */
type ScopedClassNamesMap<TObject extends object, TPrefix extends string> = {
  [K in keyof TObject & string]: `${TPrefix}-${K}`
}

/**
 * This function is used to create a map of scoped class names
 * @param prefix - the prefix of the specific component [should be something specific to the component]
 * @param object - the object that contains the class names
 */
const getScopedClassNamesMap = <
  TObject extends Record<string, CSSProperties>,
  TPrefix extends string
>(
  prefix: TPrefix,
  object: TObject
): ScopedClassNamesMap<TObject, TPrefix> =>
  entries(object).reduce<ScopedClassNamesMap<TObject, TPrefix>>(
    (acc, [key]) => ({
      ...acc,
      [key]: `${prefix}-${key as string}`
    }),
    Object.create(null)
  )

/**
 * This is a wrapper of the styled components that exposes a cleaner interface
 * CAN ONLY STYLE HTML-NATIVE COMPONENTS
 * @param prefix - the prefix of the specific component [should be something specific to the component]
 * @param component - the component to be styled
 * @param getRootClassObject - the properties of the root component
 * @param getClassObject - the properties and class names that will be injected in the given component
 *
 * @example
 * // here below you can see that we are going to create a Text field with a red background
 * // and that has as scoped css class displayCenter
 * const [StyledTextField, classes] = buildStyledComponent(
 * 	  'kampaay-text-field',
 * 	  TextField,
 * 	  () => ({
 * 	    backgroundColor: 'red',
 * 	  }),
 * 		(theme) => ({
 * 			displayCenter: {
 * 			  display: 'flex',
 * 			  justifyContent: 'center',
 * 			}
 * 	  })
 *
 * 	  // { displayCenter: 'kampaay-text-field-displayCenter'}
 * 	  KConsole.log(classes)
 *
 * 	  return <StyledTextField className={classes.displayCenter} />
 */
export const buildStyledComponent = <
  KObject extends Record<string, CSSProperties>,
  TKey extends keyof KObject & string,
  WPrefix extends string,
  TComponent extends keyof JSX.IntrinsicElements
>(
  prefix: WPrefix,
  component: TComponent,
  getRootClassObject: ((theme: KampaayTheme) => CSSProperties) | undefined,
  getClassObject: (theme: KampaayTheme) => KObject = () => ({} as KObject)
): [
  ReturnType<CreateStyledComponent<{}, JSX.IntrinsicElements[TComponent]>>,
  ScopedClassNamesMap<KObject, WPrefix>
] => {
  const classMap = getScopedClassNamesMap(prefix, getClassObject(kampaayTheme))

  const injectedClasses = Object.fromEntries(
    entries(getClassObject(kampaayTheme)).map(([key, value]) => [
      `& .${prefix}-${key as TKey}`,
      value
    ])
  )

  const styledComponentClasses = {
    ...(getRootClassObject ? getRootClassObject(kampaayTheme) : {}),
    ...injectedClasses
  }

  const styledComponent = styled(component)(() => styledComponentClasses)

  return [styledComponent, classMap]
}
