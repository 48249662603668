import { APICfg } from 'services/api/api'
import { OrganizationToAPI } from 'services/api/entities/crm/organizations/types/api/request-models'
import {
  APIOrganization,
  APIOrganizationITA
} from 'services/api/entities/crm/organizations/types/api/response-models'
import { Organization } from 'services/api/entities/crm/organizations/types/internal/organization-model'
import { writePaymentConfiguration } from 'services/api/entities/crm/organizations/paymentConfigWrite'
import {
  hiddenServicesParse,
  hiddenServicesWrite
} from 'services/api/entities/crm/organizations/hiddenServiceParseAndWrite'
import { baseCustomerWrite } from 'services/api/entities/crm/customers'
import { uploadOrKeepImg } from 'services/utils/images'
import { CheckoutQuestion } from 'services/api/entities/services-config/types/internal/question-models'
import { parseRaImage } from 'services/api/raParsers'
import { OrganizationApproverConfig } from '@kampaay/common'

const organizationParse = ({
  image,
  hiddenServices,
  customApprovalFlow,
  approvalSteps,
  ...res
}: APIOrganization | APIOrganizationITA): Organization => ({
  ...res,
  image: parseRaImage(image),
  hiddenServices: hiddenServicesParse(hiddenServices),
  customApprovalFlow: !!customApprovalFlow,
  approvalSteps: approvalSteps ?? []
})

const writeCheckoutQuestions = (
  questions: CheckoutQuestion[]
): CheckoutQuestion[] => {
  return questions.map((question) => {
    if (question.type === 'freeText') {
      return {
        id: question.id,
        type: question.type,
        text: question.text,
        description: question.description,
        mandatory: question.mandatory
      }
    }

    return question
  })
}

export const cleanUpApprovers = (
  approvalSteps: OrganizationApproverConfig[]
) => {
  return approvalSteps?.map((step) => {
    return {
      ...step,
      fallbackApprovers: step.fallbackApprovers?.length
        ? step.fallbackApprovers
        : undefined,
      fallbackCCs: step.fallbackCCs?.length ? step.fallbackCCs : undefined
    }
  })
}

const organizationWrite = async ({
  image,
  paymentConfiguration,
  hiddenServices,
  checkoutQuestions,
  approvalSteps,
  ...res
}: Organization): Promise<OrganizationToAPI> => {
  return {
    ...res,
    ...baseCustomerWrite(res),
    paymentConfiguration: writePaymentConfiguration(paymentConfiguration),
    image: await uploadOrKeepImg(image),
    hiddenServices: hiddenServicesWrite(hiddenServices),
    checkoutQuestions: writeCheckoutQuestions(checkoutQuestions),
    approvalSteps: cleanUpApprovers(approvalSteps)
  }
}

const apiCfg: APICfg = {
  endpoint: 'organization',
  parse: organizationParse,
  write: organizationWrite
}

export default apiCfg
