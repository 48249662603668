import { buildMultilangString, createMockBuilder } from '../../../../utils'
import type { ProposalItem } from '../types'
import { buildProposalItemPresentationSettingsMock } from './proposal-item-presentation-settings-mocks'

const buildBaseMock = (): ProposalItem => ({
  id: 1,
  name: buildMultilangString({
    en: 'proposal item name',
    it: 'nome item proposta'
  }),
  isPriceIncluded: false,
  presentation: buildProposalItemPresentationSettingsMock(),
  createdAt: '2021-01-01T00:00:00.000Z',
  isSelectable: false,
  isOutOfScope: false
})

export const buildProposalItemMock = createMockBuilder(buildBaseMock)
