import { MainProduct } from '@kampaay/common'
import {
  formatProductCommon,
  ProductCommonCSV
} from 'services/csv/entities/catalog/common/productCommon'
import {
  formatWithIdAndName,
  WithIdAndNameCSV
} from 'services/csv/entities/catalog/common/withIdAndName'

export type MainProductCSV = ProductCommonCSV & {
  'If clause linked rules': string
  'Then clause linked rules': string
  'Tailored customers': WithIdAndNameCSV[]
  'Tailored organizations': WithIdAndNameCSV[]
  'Sections ids': string
  'Advance reservation min': number | undefined
}

export const formatMainProduct = ({
  ifClauseLinkedRules,
  thenClauseLinkedRules,
  customersFull,
  organizationsFull,
  sections,
  highlights: _highlights,
  advanceReservationMin,
  ...rest
}: MainProduct): MainProductCSV => {
  return {
    ...formatProductCommon(rest),
    // TODO remove optional chaining when the https://gitlab.com/kampaay/general/-/issues/1676 is released
    ['If clause linked rules']: ifClauseLinkedRules
      ?.map((r) => r.ruleName)
      .join(', '),
    // TODO remove optional chaining when the https://gitlab.com/kampaay/general/-/issues/1676 is released
    ['Then clause linked rules']: thenClauseLinkedRules
      ?.map((r) => r.ruleName)
      .join(', '),
    ['Tailored customers']: customersFull.map(formatWithIdAndName),
    ['Tailored organizations']: organizationsFull.map(formatWithIdAndName),
    ['Sections ids']: sections.join(', '),
    ['Advance reservation min']: advanceReservationMin
  }
}
