import { createMockBuilder, OrganizationSubGroup } from '@kampaay/common'

const buildBaseMock = (): OrganizationSubGroup => ({
  id: 1,
  name: 'A cool sub group',
  organizationId: 1,
  membershipsIds: []
})

export const buildOrganizationSubGroupMock = createMockBuilder(buildBaseMock)
