import React from 'react'
import { SimpleFormIterator } from 'react-admin'
import { getStandardPricingStartup } from 'services/cfg/form'
import KAccordion from 'components/UI/Accordions/KAccordion/KAccordion'
import StandardPricingInput from 'components/FormComponents/pricing/standard/StandardPricingInput'
import LocalizedTextInput from 'components/Inputs/Localized/LocalizedTextInput'
import { useFormContext } from 'react-hook-form'
import { Drink } from 'services/api/entities/catalog/drink/types/internal/drink-models'
import KArrayInput from 'components/Inputs/KInputs/KArrayInput'

const VariantsFormPartial: React.FC = (props) => {
  const { setValue, getValues, watch } = useFormContext<Drink>()
  const variants = getValues('variants')

  const hasMultipleVariants = watch('hasMultipleVariants')
  const name = watch('name')

  if (!hasMultipleVariants) {
    if (variants?.length !== 1) {
      setValue('variants.0.name', name)
      setValue('variants.0.pricing', getStandardPricingStartup())
    }
    setValue('variants.0.name', name)
  }

  return (
    <KAccordion accordionTitle="Variants" errorsDependencies={['variants']}>
      <KArrayInput
        {...props}
        source="variants"
        defaultValue={getDefaultVariants()}
      >
        <SimpleFormIterator
          disableAdd={!hasMultipleVariants}
          disableRemove={!hasMultipleVariants}
        >
          <LocalizedTextInput
            source="name"
            forceDisabled={!hasMultipleVariants}
          />
          <StandardPricingInput
            source="pricing"
            hideFixedPrice
            label="form.fields.pricing"
          />
        </SimpleFormIterator>
      </KArrayInput>
    </KAccordion>
  )
}

export default VariantsFormPartial

const getDefaultVariants = (name = '') => [
  {
    name,
    pricing: getStandardPricingStartup()
  }
]
