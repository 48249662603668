import {
  DrinkVariantSelection,
  DrinkSelection
} from 'services/api/entities/events/types/internal/selections'
import {
  KTestConfig,
  KTestFunction,
  yupArray,
  yupIgnore,
  YupSchema
} from 'services/yup'
import { productSelectionBaseVS } from 'services/validations/operations/events/selections/common/base'
import { mainProductSelectionVS } from 'services/validations/operations/events/selections/common/mainProduct'

const drinkVariantArrayTestFn: KTestFunction<DrinkVariantSelection[]> = (
  v = []
) => {
  const idsOnly = new Set(v.map((v) => v.id!))
  return idsOnly.size === v.length
}

const drinkVariantArrayTestConfig: KTestConfig<DrinkVariantSelection[]> = {
  message: 'Please do not insert 2 times the same variant',
  test: drinkVariantArrayTestFn
}

const drinkVariantVS: YupSchema<DrinkVariantSelection> = productSelectionBaseVS

export const drinkSelectionVS: YupSchema<DrinkSelection> =
  mainProductSelectionVS.shape({
    variants: yupArray(drinkVariantVS).min(1).test(drinkVariantArrayTestConfig),
    quantity: yupIgnore() // we do not specify quantity for the selections of drinks since it's implicitly the sum of the quantities of the variants
  })
