import { createMockBuilder } from '../../../../utils'
import type { GeoCodedAddress } from '../types'

const buildBaseMock = (): GeoCodedAddress => ({
  geoPoint: {
    addressTypes: []
  }
})

export const buildGeoCodedAddressMock = createMockBuilder(buildBaseMock)
