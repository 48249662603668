import React from 'react'
import { RadioButtonGroupInput } from 'react-admin'
import { EventBriefQuestion } from 'services/api/entities/events/types/internal/services'
import { activityStatusChoices } from 'services/cfg/form'
import { parseMultilangOrFallbackToAnyAvailable } from '@kampaay/common'
import LocalizedSelectInput from 'components/Inputs/Localized/LocalizedSelectInput'
import KSelectInput from 'components/Inputs/KInputs/KSelectInput'
import TimePicker from 'components/Inputs/TimePicker'
import KTextInput from 'components/Inputs/KInputs/KTextInput/KTextInput'
import { useForm } from 'hooks/useForm'
import { RAFormFC } from 'types/common'
import KDateInput from 'components/Inputs/KInputs/KDateInput'

const EventBriefQuestionInput: RAFormFC = ({ source }) => {
  const { useGetValue, getSource } = useForm<EventBriefQuestion>(source)

  const status = useGetValue('status')
  const text = useGetValue('text')
  const questionType = useGetValue('type')
  const options = useGetValue('options')

  const disabled = status === 'inactive'

  return (
    <>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between'
        }}
      >
        <h4>{parseMultilangOrFallbackToAnyAvailable(text)}</h4>
        <KSelectInput
          source={getSource('status')}
          choices={activityStatusChoices}
          defaultValue={activityStatusChoices[1].id} // 'active'
        />
      </div>

      {questionType === 'freeText' && (
        <KTextInput
          source={getSource('response')}
          label="form.fields.response"
          fullWidth
          disabled={disabled}
        />
      )}

      {questionType === 'boolean' && (
        <RadioButtonGroupInput
          source={getSource('response')}
          choices={[
            { id: 'no', name: 'No' },
            { id: 'yes', name: 'Yes' }
          ]}
          label="form.fields.response"
          row
          disabled={disabled}
        />
      )}

      {questionType === 'multiple' && (
        <LocalizedSelectInput
          source={getSource('response')}
          choices={options?.map((o) => ({
            id: o,
            name: o
          }))}
          label="form.fields.response"
          forceDisabled={disabled}
        />
      )}

      {questionType === 'time' && (
        <TimePicker
          source={getSource('response')}
          label="form.fields.response"
          disabled={disabled}
        />
      )}

      {questionType === 'date' && (
        <KDateInput
          source={getSource('response')}
          label="form.fields.response"
          disabled={disabled}
        />
      )}
    </>
  )
}

export default EventBriefQuestionInput
