import { styled } from '@mui/material/styles'
import React from 'react'
import { useTranslate } from 'react-admin'
import HalfStepNumberInput from 'components/Inputs/HalfStepNumberInput/HalfStepNumberInput'

const PREFIX = 'DefaultTimeBeforeAfterHint'

const classes = {
  fieldsBox: `${PREFIX}-fieldsBox`,
  fieldWidth: `${PREFIX}-fieldWidth`,
  ml55: `${PREFIX}-ml55`
}

const Root = styled('div')(() => ({
  [`& .${classes.fieldsBox}`]: {
    display: 'flex',
    flexDirection: 'row',
    gap: '100px',
    width: '100%'
  },

  [`& .${classes.fieldWidth}`]: {
    width: '300px'
  },

  [`& .${classes.ml55}`]: {
    marginLeft: '50px'
  }
}))

const DefaultTimeBeforeAfterHint: React.FC = (props) => {
  const translate = useTranslate()
  return (
    <Root className={[classes.fieldsBox, classes.ml55].join(' ')}>
      <div className={classes.fieldWidth}>
        {translate('form.fields.default')}
      </div>
      <HalfStepNumberInput
        {...props}
        source="defaultTimeBeforeAfterHint.hoursBefore"
        label="form.fields.hoursBefore"
        className={classes.fieldWidth}
      />
      <HalfStepNumberInput
        {...props}
        source="defaultTimeBeforeAfterHint.hoursAfter"
        className={classes.fieldWidth}
        label="form.fields.hoursAfter"
      />
    </Root>
  )
}

export default DefaultTimeBeforeAfterHint
