import { dateToDateOnly } from '../../../../utils'
import type {
  FoodSelection,
  FoodSelectionToAPI,
  OptionedSelection,
  OptionedSelectionToAPI,
  ProfessionalSelection,
  ProfessionalSelectionToAPI,
  SelectionBase,
  SelectionBaseToAPI,
  SelectionWithQuantity,
  SelectionWithQuantityToAPI
} from './types'

const writeSelectionBase = (selection: SelectionBase): SelectionBaseToAPI => ({
  id: selection.id! // mandatory in the form, should be safe to assume it's always there
})

const writeSelectionWithQuantity = (
  selection: SelectionWithQuantity
): SelectionWithQuantityToAPI => ({
  ...writeSelectionBase(selection),
  quantity: selection.quantity! // mandatory in the form, should be safe to assume it's always there
})

export const writeProfessionalSelection = (
  selection: ProfessionalSelection,
  conciergeStartDate?: Date
): ProfessionalSelectionToAPI => ({
  ...writeSelectionBase(selection),
  bookedFrom: selection.bookedFrom,
  bookedTo: selection.bookedTo,
  eventStartDate: conciergeStartDate
    ? dateToDateOnly(conciergeStartDate)
    : undefined
})

export const writeOptionedSelection = (
  selection: OptionedSelection
): OptionedSelectionToAPI => ({
  ...writeSelectionBase(selection),
  options: selection.options?.map((optionGroup) => ({
    ...writeSelectionBase(optionGroup),
    items: optionGroup.items.map(writeSelectionWithQuantity)
  })),
  upselling: selection.upselling?.map(writeSelectionWithQuantity)
})

export const writeFoodSelection = (
  selection: FoodSelection
): FoodSelectionToAPI => ({
  ...writeOptionedSelection(selection),
  deliveryTime: selection.deliveryTime,
  logistic: writeSelectionBase(selection.logistic).id,
  intolerances: selection.intolerances
})
