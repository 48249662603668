import React from 'react'
import { activityStatusChoices } from 'services/cfg/form'
import FileInputAccordion from 'components/Inputs/FileInputAccordion'
import LocalizedTextInput from 'components/Inputs/Localized/LocalizedTextInput'
import MainProductFormContent from 'components/Forms/MainProductFormContent'
import HighlightsInput from 'components/Inputs/HighlightsInput'
import LocationFeatureInput from 'components/Inputs/LocationFeatureInput'
import LocationFacetSection from 'components/Inputs/Facets/FacetFormSections/LocationFacetSection'
import LocationArrangementsInput from 'resources/catalog/location/product/LocationArrangementsInput'
import BookingTimeLimitInput from 'components/Inputs/BookingTimeLimitInput'
import UpsellingSelector from 'components/FormComponents/UpsellingSelector'
import TimePicker from 'components/Inputs/TimePicker'
import KAccordion from 'components/UI/Accordions/KAccordion/KAccordion'
import LocationTimeSuggestionsInput from 'resources/catalog/location/product/LocationTimeSuggestionsInput'
import LocationByRulePricingInput from 'components/FormComponents/pricing/byRules/LocationByRulePricingInput'
import KNumberInput from 'components/Inputs/KInputs/KNumberInput/KNumberInput'
import KSelectInput from 'components/Inputs/KInputs/KSelectInput'
import { useForm } from 'hooks/useForm'
import { Location } from 'services/api/entities/catalog/location/types/internal/location-models'
import { RAFormFC } from 'types/common'
import SuppliersAutocompleteArrayInput from 'components/Inputs/SuppliersAutocompleteArrayInput'
import AddressAutocompleteInput from 'components/Inputs/AddressAutocompleteInput'

const LocationFormContent: RAFormFC = ({ source }) => {
  const { getSource } = useForm<Location>(source)

  return (
    <MainProductFormContent facetFields={<LocationFacetSection />}>
      <LocalizedTextInput
        source={getSource('nickname')}
        label="form.fields.locationNickname"
        fullWidth
      />
      <UpsellingSelector upsellingEntity="locationupselling" />
      <KSelectInput
        source={getSource('status')}
        choices={activityStatusChoices}
      />

      <AddressAutocompleteInput
        label={'form.fields.address'}
        source={getSource('geoCodedAddress')}
        fullWidth
        checkAddressType
      />

      <KNumberInput
        source={getSource('peopleCapacity')}
        label="form.fields.peopleCapacity"
      />
      <KNumberInput
        source={getSource('roomCapacity')}
        label="form.fields.roomCapacity"
      />
      <BookingTimeLimitInput />
      <SuppliersAutocompleteArrayInput />
      <FileInputAccordion source={getSource('planimetry')} />

      {/* Arrangements section */}
      <KAccordion accordionTitle="Arrangements & Features">
        <LocationArrangementsInput source={getSource('arrangements')} />
        <LocationFeatureInput
          source={getSource('characteristics')}
          type="characteristic"
        />
        <LocationFeatureInput
          source={getSource('equipments')}
          type="equipment"
        />
      </KAccordion>
      <HighlightsInput />
      {/* Time slots section */}
      <LocationTimeSuggestionsInput />
      {/* Time slots & Pricing */}
      <KAccordion
        accordionTitle="Time slots & Pricing"
        errorsDependencies={['availableFrom', 'availableTo']}
        defaultExpanded={false}
      >
        <TimePicker
          source={getSource('availableFrom')}
          label="Available From"
        />
        <TimePicker source={getSource('availableTo')} label="Available to" />
        <LocationByRulePricingInput />
      </KAccordion>
    </MainProductFormContent>
  )
}

export default LocationFormContent
