import type { CustomerBase } from '../types'
import { createMockBuilder } from '../../../../utils'

const buildBaseMock = (): CustomerBase => ({
  id: 1,
  responsibleEmail: 'test@customer.com',
  name: 'test name',
  country: 'AD',
  headQuarters: 'test headQuarters',
  responsibleFullName: 'test responsibleFullName',
  vat: undefined
})

export const buildCustomerBaseMock = createMockBuilder(buildBaseMock)
