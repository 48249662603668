import groupBy from 'lodash/groupBy'
import type {
  FoodSelection,
  LogisticSelection,
  ProposalItem,
  ProposalItemGroup,
  WithCostAndPrice
} from '../../../api'
import { getWithMaxValue } from '../../arrays'
import { isTruthy } from '../../misc'
import { aggregateCostAndPrices } from '../../misc/aggregateCostAndPrice'

type LogisticSelectionWithDelivery = LogisticSelection & {
  deliveryTime: string
}

type ItemWithFoodSelection = ProposalItem & { selection: FoodSelection }

/**
 * Custom items are ignored because their price will not depend on the logistic that is selected
 */
const hasLogisticSelectionToConsider = (
  item: ProposalItem
): item is ItemWithFoodSelection => {
  return (
    !!item.selection &&
    !!item.isStandardItem &&
    item.isPriceIncluded &&
    !!('logistic' in item.selection)
  )
}

/**
 * Extracts logistics selections with delivery times from proposal item groups.
 * Only processes items that have a logistics section and delivery time.
 * Combines the logistics data with the corresponding delivery time into a single object.
 *
 * @param itemGroups - Array of proposal item groups to extract logistics from
 * @returns Array of logistics selections, each including their delivery time
 */
const getLogisticsWithDelivery = (
  itemGroups: ProposalItemGroup[]
): LogisticSelectionWithDelivery[] =>
  itemGroups
    .flatMap((group) =>
      group.items.filter(hasLogisticSelectionToConsider).map((item) => ({
        deliveryTime: item.selection.deliveryTime,
        ...item.selection.logistic
      }))
    )
    .filter(isTruthy)

/**
 * Deduplicates logistics entries by delivery time, keeping the one with highest price for each time slot.
 * This is useful when multiple items share the same delivery time but have different logistics costs,
 * as we want to charge the highest logistics cost only once per delivery time.
 *
 * @param tuples - Array of logistics selections with delivery time information
 * @returns Array of deduplicated logistics selections (without delivery time)
 *
 * @example
 * ```ts
 * const logistics = [
 *   { deliveryTime: '14:00', price: 100, cost: 80 },
 *   { deliveryTime: '14:00', price: 150, cost: 120 }, // Higher price for same time
 *   { deliveryTime: '18:00', price: 200, cost: 160 }
 * ]
 *
 * dedupeLogistics(logistics)
 * // Returns:
 * // [
 * //   { price: 150, cost: 120 },  // Kept highest price for 14:00
 * //   { price: 200, cost: 160 }   // Single entry for 18:00
 * // ]
 * ```
 */
const dedupeLogistics = (
  tuples: LogisticSelectionWithDelivery[]
): LogisticSelection[] =>
  Object.values(groupBy(tuples, ({ deliveryTime }) => deliveryTime))
    .map((logistics = []) => getWithMaxValue(logistics, (l) => l.price ?? 0))
    .filter(isTruthy)

/**
 * Extracts the logistics data from a proposal item group and deduplicates it by delivery time,
 * resulting in only the logistics relevant for the proposal.
 *
 * @param itemGroups
 * @returns
 */
export const getProposalLogistics = (itemGroups: ProposalItemGroup[]) =>
  dedupeLogistics(getLogisticsWithDelivery(itemGroups))

/**
 * Calculates the total logistics cost and price for all items in a proposal.
 * The calculation process:
 * 1. Extracts logistics selections with their delivery times
 * 2. Deduplicates logistics by delivery time, keeping the highest price for each time slot
 * 3. Aggregates the final cost and price from the deduplicated logistics
 *
 * @param itemGroups - Array of proposal item groups containing selections with logistics
 * @returns Object containing the total logistics cost and price for the proposal
 */
export const getProposalLogisticsPricing = (
  itemGroups: ProposalItemGroup[]
): WithCostAndPrice => aggregateCostAndPrices(getProposalLogistics(itemGroups))
