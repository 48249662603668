import React from 'react'
import { useKRecordContext } from 'hooks/useKRecordContext'
import { keys } from 'services/utils'
import BaseCfgTab from 'resources/services-config/FormTabs/BaseCfgTab'
import FoodServiceCfgTab from 'resources/services-config/FormTabs/FoodServiceCfgTab'
import GeneralCfgTab from 'resources/services-config/FormTabs/GeneralCfgTab'
import ProfessionalsCfgTab from 'resources/services-config/FormTabs/ProfessionalsCfgTab'
import KTabbedForm from 'components/Forms/KForms/KTabbedForm'
import { TabbedFormProps } from 'react-admin'
import { ServicesConfig } from 'services/api/entities/services-config/types/internal/services-config-models'

const ServicesConfigForm: React.FC<Pick<TabbedFormProps, 'toolbar'>> = ({
  toolbar
}) => {
  const record = useKRecordContext<ServicesConfig>()

  if (!record) return null
  const {
    id: _id,
    generalService: _generalService,
    professionalService: _professionalService,
    foodService: _foodService,
    ...rest
  } = record
  return (
    <KTabbedForm toolbar={toolbar}>
      <GeneralCfgTab />

      <FoodServiceCfgTab />
      {keys(rest).map((servName, idx) => (
        <BaseCfgTab servName={servName} key={idx} />
      ))}
      <ProfessionalsCfgTab />
    </KTabbedForm>
  )
}

export default ServicesConfigForm
