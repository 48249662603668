import { Accessibility } from '@mui/icons-material'
import OrganizationCreate from 'resources/crm/clients/organization/OrganizationCreate'
import OrganizationEdit from 'resources/crm/clients/organization/OrganizationEdit'
import OrganizationList from 'resources/crm/clients/organization/OrganizationList'

const organizationIndex = {
  icon: Accessibility,
  edit: OrganizationEdit,
  create: OrganizationCreate,
  list: OrganizationList
}

export default organizationIndex
