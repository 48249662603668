import { CountryCode, Proposal } from '@kampaay/common'
import { useCallback, useMemo, useState } from 'react'
import {
  TransactionServiceBillingInfo,
  TransactionServiceBillingInfoForAggregates,
  getTransactionRecapBillingInfo
} from 'resources/accounting/transaction/computedGetters'
import { Customization } from 'services/api/entities/events/types/internal/event-models'

/**
 * This hook is used to compute the information of a transaction displayed at the top of the transaction form
 * This will display the totals of all the aggregated services with all the meaningful information
 * Returns the recap transaction billing infos and the function to update the services record
 * @param discountPerc
 * @param customizations
 * @param customerBusinessCountry
 */
export const useTransactionRecapInfo = (
  professionalsCustomizations: Customization[] = [],
  customizations: Customization[] = [],
  customerBusinessCountry?: CountryCode,
  proposal?: Proposal,
  eventDiscount = 0
) => {
  const [servicesBillingInfoMap, setServicesBillingInfoMap] = useState<
    Record<string, TransactionServiceBillingInfoForAggregates>
  >({})

  const servicesBillingInfos = Object.values(servicesBillingInfoMap)

  const transactionRecapInfo = useMemo(
    () =>
      getTransactionRecapBillingInfo(
        servicesBillingInfos,
        professionalsCustomizations,
        customizations,
        eventDiscount,
        proposal
      ),
    [
      servicesBillingInfos,
      eventDiscount,
      customizations,
      customerBusinessCountry
    ]
  )

  const updateServiceBillingInfo = useCallback(
    (serviceBillingInfo: TransactionServiceBillingInfo) => {
      setServicesBillingInfoMap((prev) => ({
        ...prev,
        [serviceBillingInfo.serviceName]: { ...serviceBillingInfo }
      }))
    },
    [servicesBillingInfoMap, setServicesBillingInfoMap]
  )

  return { updateServiceBillingInfo, transactionRecapInfo }
}
