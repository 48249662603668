import React from 'react'
import { useForm } from 'hooks/useForm'
import { RAFormFC, ServiceCollectionName } from 'types/common'
import SupplierSelectInput from 'components/Inputs/SupplierSelectInput'
import KRichTextInput from 'components/Inputs/KInputs/KRichTextInput'
import { KBooleanInput } from 'components/Inputs/KInputs/KBooleanInput'
import { useEventService } from 'hooks/events/useEventService'
import { EnrichedProductService } from 'services/api/entities/events/types/internal/services'
import { translate } from 'services/i18n'
import SupplierAssignmentErrorSpan from 'resources/operations/events/common/SupplierAssignmentErrorSpan'
import filterSupplierBuilder from 'resources/operations/events/common/filterSupplierWithServiceBuilder'
import useCheckIfSelectionsHasCommonSupplier from 'resources/operations/events/common/useCheckIfSelectionsHasCommonSupplier'
import { useCheckIfSelectionsNotInCatalog } from 'resources/operations/events/common/useCheckIfSelectionsNotInCatalog'
import { useCheckIfSupplierProvideProduct } from 'resources/operations/events/common/useCheckIfSupplierProvideProduct'
import KBanner from 'components/UI/KBanner'
import { Warning } from '@mui/icons-material'
import { kampaayTheme } from 'layout/config/themes'

type Props = {
  serviceName: Exclude<ServiceCollectionName, 'professionals'>
}
const EventServiceSection: RAFormFC<Props> = ({ source, serviceName }) => {
  const { useGetValue, getSource } = useForm<EnrichedProductService>(source)
  const summaries = useEventService(serviceName)
  const productSelections = summaries.selections
  const selectionIds = productSelections.map((selection) => selection.id)

  const isSelectionsNotInCatalog = useCheckIfSelectionsNotInCatalog({
    SCN: serviceName,
    selectionIds
  })

  const notifySupplier = useGetValue('notifySupplier')
  const supplier = useGetValue('supplier')
  const supplyConfirmed = useGetValue('supplyConfirmed')
  const lastMessageConfirmed = useGetValue('lastMessageConfirmed')
  const assignmentEmailSent = useGetValue('assignmentEmailSent')
  const filterSupplierWithService = filterSupplierBuilder({
    serviceName,
    selectionIds
  })
  const isSupplierProvideProduct = useCheckIfSupplierProvideProduct({
    selectedSupplierId: supplier,
    serviceName: serviceName,
    selectionIds
  })

  const ifSelectionsHasCommonSupplier = useCheckIfSelectionsHasCommonSupplier({
    selectionIds: selectionIds,
    serviceName: serviceName,
    ifSupplierNotAssigned: !supplier
  })

  return (
    <>
      <SupplierSelectInput
        disabled={notifySupplier}
        source={getSource('supplier')}
        filterFns={isSelectionsNotInCatalog ? [] : [filterSupplierWithService]}
      />
      <SupplierAssignmentErrorSpan
        isSelectionsNotInCatalog={isSelectionsNotInCatalog}
        isSupplierProvideProduct={isSupplierProvideProduct}
        ifSupplierExist={!!supplier}
        ifProductSelected={!!(selectionIds.length > 0)}
      />
      {ifSelectionsHasCommonSupplier ? (
        <div
          style={{
            marginBottom: '1.5rem',
            backgroundColor: 'rgba(255, 0, 0, 0.15)',
            border: '3px solid black',
            padding: '0.5rem'
          }}
        >
          {translate('common.label.notHaveCommonSupplier')}
        </div>
      ) : (
        <></>
      )}
      <div className="align-center mb-4 flex gap-4">
        <KBooleanInput
          className="mt-3"
          label="form.fields.triggerAssignmentEmail"
          source={getSource(`triggerAssignmentEmail`)}
        />
        {assignmentEmailSent && (
          <KBanner
            backgroundColor={kampaayTheme.palette.primary.warning}
            text="form.fields.assignmentEmailAlreadySent"
            icon={<Warning />}
          />
        )}
      </div>
      <KBooleanInput
        source={getSource('supplyConfirmed')}
        label={
          !!supplyConfirmed ? 'Supply confirmed' : 'Supply not yet confirmed'
        }
        disabled
      />
      {!!supplier && (
        <>
          <KRichTextInput
            source={getSource('lastMessage')}
            label="form.fields.lastSupplierMessage"
          />

          <KBooleanInput
            source={getSource('lastMessageConfirmed')}
            label={
              !!lastMessageConfirmed
                ? 'Last message confirmed'
                : 'Last message not yet confirmed'
            }
            disabled
          />
        </>
      )}
    </>
  )
}

export default EventServiceSection
