import { ProductServiceDiscriminator } from '@kampaay/common'
import { encodeProfessionalDiscriminator } from 'components/FormComponents/Brief/BriefServiceInput'
import { EventBrief, ServiceBrief } from 'services/api/entities/eventBrief'
import {
  MappedEnrichedEvent,
  UnMappedEnrichedEvent
} from 'services/api/entities/events/types/internal/event-models'
import {
  EnrichedProfessionalServiceGroup,
  EventBriefQuestion,
  EventProfessionalServiceGroup
} from 'services/api/entities/events/types/internal/services'
import {
  BriefQuestion,
  BriefQuestionBase,
  GeneralBriefQuestion
} from 'services/api/entities/services-config/types/internal/question-models'
import { EncodedProfessionalDiscriminator } from 'services/api/entities/services-config/types/internal/services-config-models'
import {
  DISCRIMINATOR_COLLECTION_NAMES_MAP,
  getCollectionNameFromDiscriminator,
  getDiscriminatorFromProductName,
  getServiceNameFromCollectionName
} from 'services/cfg/name-maps'
import { mapValues } from 'services/utils'
import { ServiceCollectionName } from 'types/common'
import { MainProductAPIEntity } from '..'

/**
 * Used to filter the general questions according to the services that are selected
 *
 * ES. If a food service is selected and the general question is related to the food service this fn returns true
 * @param selectedServicesDiscriminators - array of selected services discriminators
 * @param proServices - array of professional services made of professional sections
 * @param generalQuestion - a single general brief question
 * @returns true if the question is correlated with the selected services false else
 */
export const isGeneralQuestionBoundToSelectedServices =
  (
    selectedServicesDiscriminators: ProductServiceDiscriminator[],
    proServices: EncodedProfessionalDiscriminator[]
  ) =>
  (generalQuestion: GeneralBriefQuestion) => {
    const hasSelectedServices = generalQuestion.activeWhenServices.some(
      (service) =>
        (selectedServicesDiscriminators as string[]).includes(service)
    )

    const hasSelectedProfessionalServices =
      generalQuestion.activeWhenServices.some((s) =>
        (proServices as string[]).includes(s)
      )

    return hasSelectedServices || hasSelectedProfessionalServices
  }

export const isQuestionCompatibleWithSelectedProducts =
  (
    serviceDiscriminator: ProductServiceDiscriminator,
    event: UnMappedEnrichedEvent
  ) =>
  (question: BriefQuestion) => {
    const productsNameService =
      DISCRIMINATOR_COLLECTION_NAMES_MAP[serviceDiscriminator]
    const productsOfService =
      serviceDiscriminator === 'ProfessionalConfigurator'
        ? event['professionalService'].services.flatMap(
            (professional) => professional.selections
          )
        : event[
            productsNameService as Exclude<
              MainProductAPIEntity,
              'professionals'
            >
          ]

    return (
      !question.products.length ||
      question.products.some((product) =>
        productsOfService.some((p) => p.id === product)
      )
    )
  }

const mergeQuestionsAndAnswers = <T extends BriefQuestionBase>(
  responses: EventBrief,
  serviceBrief: T[],
  discriminator: string
) =>
  serviceBrief.map((q) => {
    const serviceSection = responses.find(
      (r) => r.supplieredService === discriminator
    )

    const questionResponse = serviceSection?.questionResponses.find(
      (r) => r.id === q.id
    )

    return {
      ...q,
      response: questionResponse?.response ?? '',
      status: questionResponse?.status ?? 'active'
    }
  })

const findBySupplieredServiceService = (name: string) => (r: ServiceBrief) =>
  r.supplieredService === name

const getGeneralServiceWithBrief = (
  surveyResponses: EventBrief,
  generalQuestions: GeneralBriefQuestion[]
) => {
  const mergedBriefQuestions = mergeQuestionsAndAnswers(
    surveyResponses,
    generalQuestions,
    'GeneralConfigurator'
  )
  return {
    briefQuestions: mergedBriefQuestions,
    discriminator: 'GeneralConfigurator' as const
  }
}

export const getEncodedDiscriminatorsFromProfessionalServiceGroup = ({
  services
}: EventProfessionalServiceGroup) =>
  services.map((s) => encodeProfessionalDiscriminator(s.id))

export const parseEventMultiplex = (
  event: UnMappedEnrichedEvent
): MappedEnrichedEvent => {
  const { serviceConfig, surveyResponses, ...parsedEvent } = event
  const { purchasedServicesArray, productServices, professionalService } =
    parsedEvent
  const {
    id: _id,
    professionalService: { services: professionalServicesConfig },
    generalService: generalServiceConfig,
    ...namedServicesConfig
  } = serviceConfig

  const selectedServicesDiscriminators = purchasedServicesArray.map(
    getDiscriminatorFromProductName
  )
  const selectedProfessionalsDiscriminators =
    getEncodedDiscriminatorsFromProfessionalServiceGroup(professionalService)

  const generalQuestions = generalServiceConfig.brief.filter(
    isGeneralQuestionBoundToSelectedServices(
      selectedServicesDiscriminators,
      selectedProfessionalsDiscriminators
    )
  )

  const generalServiceWithBrief = getGeneralServiceWithBrief(
    surveyResponses,
    generalQuestions
  )

  const eventNamedServicesWithBrief = mapValues((service, servName) => {
    const { serviceDiscriminator, brief } = namedServicesConfig[servName]
    const isAmongSelectedServices =
      selectedServicesDiscriminators.includes(serviceDiscriminator)

    const compatibleBriefQuestions = brief.filter(
      isQuestionCompatibleWithSelectedProducts(
        serviceDiscriminator as ProductServiceDiscriminator,
        event
      )
    )

    const mergedBriefQuestions = isAmongSelectedServices
      ? mergeQuestionsAndAnswers(
          surveyResponses,
          compatibleBriefQuestions,
          serviceDiscriminator
        )
      : []

    return {
      ...service,
      briefConfirmed: !!surveyResponses.find(
        findBySupplieredServiceService(serviceDiscriminator)
      )?.briefConfirmed,
      briefQuestions: mergedBriefQuestions,
      // we know that we are not considering the professional service here
      discriminator: serviceDiscriminator as Exclude<
        ProductServiceDiscriminator,
        'ProfessionalConfigurator'
      >
    }
  }, productServices)

  const professionalsWithBrief = professionalService.services.map((p) => {
    const currentProfessionalServiceConfig = professionalServicesConfig.find(
      (proService) => proService.section.name === p.name
    )

    const briefQuestions =
      currentProfessionalServiceConfig?.brief.filter(
        isQuestionCompatibleWithSelectedProducts(
          'ProfessionalConfigurator',
          event
        )
      ) ?? []

    const mergedBriefQuestions = !!currentProfessionalServiceConfig
      ? mergeQuestionsAndAnswers(
          surveyResponses,
          briefQuestions,
          currentProfessionalServiceConfig?.serviceDiscriminator
        )
      : []

    return {
      ...p,
      briefConfirmed: !!surveyResponses.find(
        findBySupplieredServiceService(p.name)
      )?.briefConfirmed,
      briefQuestions: mergedBriefQuestions,
      discriminator: currentProfessionalServiceConfig?.serviceDiscriminator
    }
  })

  return {
    ...parsedEvent,
    surveyResponses,
    professionalService: {
      ...professionalService,
      services: professionalsWithBrief
    },
    generalService: generalServiceWithBrief,
    productServices: eventNamedServicesWithBrief
  }
}

const getBriefResponses = <
  T extends { discriminator: string; briefQuestions?: EventBriefQuestion[] }
>(
  service: T
) => ({
  supplieredService: service.discriminator,
  questionResponses:
    service.briefQuestions?.map(({ text, response = '', id, status }) => ({
      id,
      question: text,
      response,
      status
    })) ?? []
})

const hasBriefQuestions = <T extends { briefQuestions?: unknown[] }>(
  service: T
): service is T => !!service.briefQuestions?.length

export const productServicesHasSelection =
  (
    event: Pick<
      MappedEnrichedEvent,
      Exclude<ServiceCollectionName, 'professionals'> | 'productServices'
    >
  ) =>
  <
    T extends {
      discriminator: Exclude<
        ProductServiceDiscriminator,
        'ProfessionalConfigurator'
      >
    }
  >({
    discriminator
  }: T) => {
    const collectionName = getCollectionNameFromDiscriminator(discriminator)
    const serviceName = getServiceNameFromCollectionName(collectionName)

    return (
      !!event[collectionName].length ||
      !!event.productServices[serviceName].customizations.length
    )
  }

const hasProfessionalSelections =
  ({ services }: EnrichedProfessionalServiceGroup) =>
  ({ supplieredService }: ServiceBrief) =>
    !!services.find((p) => p.discriminator === supplieredService)

const hasDiscriminator = <
  T extends ProductServiceDiscriminator | EncodedProfessionalDiscriminator,
  Y extends { discriminator?: T },
  R extends Exclude<Y, 'discriminator'> & { discriminator: string }
>(
  service: Y
): service is R => !!service.discriminator

export const mapEventToAPI = (req: MappedEnrichedEvent) => {
  const selectedProductServicesArray = Object.values(req.productServices)
    .filter(hasDiscriminator)
    .filter(productServicesHasSelection(req))

  const selectedServiceDiscriminators = [
    ...selectedProductServicesArray.map((s) => s.discriminator),
    ...req.professionalService.services.map((p) => p.discriminator)
  ]

  const serviceBriefBundle: EventBrief = selectedProductServicesArray
    .filter(hasBriefQuestions)
    .map(getBriefResponses)

  const professionalServiceBriefBundle: EventBrief =
    req.professionalService.services
      .filter(hasBriefQuestions)
      .filter(hasDiscriminator)
      .map(getBriefResponses)
      .filter(hasProfessionalSelections(req.professionalService))

  const nonGeneralBriefBundles = [
    ...serviceBriefBundle,
    ...professionalServiceBriefBundle
  ]

  const generalServiceFilteredQuestions = (
    req.generalService?.briefQuestions ?? []
  ).filter((q) =>
    q.activeWhenServices.some((service) =>
      (selectedServiceDiscriminators as string[]).includes(service)
    )
  )

  const generalServiceBriefBundle = !!generalServiceFilteredQuestions.length
    ? getBriefResponses({
        ...req.generalService!,
        briefQuestions: generalServiceFilteredQuestions
      })
    : undefined

  return {
    ...req,
    surveyResponses: [
      // if there is no general service there is no object with question-answers to send
      ...(!!generalServiceBriefBundle ? [generalServiceBriefBundle] : []),
      ...nonGeneralBriefBundles
    ]
  }
}
