import { MailingList } from 'services/api/entities/mailing-list/types'
import {
  UseValidationSchema,
  yupArray,
  yupIgnore,
  yupObject,
  yupString
} from 'services/yup'

const useMailingListVS: UseValidationSchema<MailingList> = () =>
  yupObject().shape({
    id: yupIgnore(),
    name: yupIgnore(),
    description: yupString(),
    addresses: yupArray(yupString())
  })

export default useMailingListVS
