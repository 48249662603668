import {
  KTestConfig,
  KTestFunction,
  UseValidationSchema,
  yupArray,
  yupBoolean,
  yupIgnore,
  yupObject,
  YupSchema
} from 'services/yup'
import { standardPricingArrayVS } from 'services/validations/catalog/standard-pricing/standardPricingArray'
import { DrinkTailoring, Tailorings } from '@kampaay/common'

// ============================================================
// tests
// ============================================================
export const atLeastOneCustomizationSet: KTestFunction<
  boolean,
  DrinkTailoring
> = (isHidden, { parent: { variants } }) => {
  return !!isHidden || !!variants?.length
}

export const hasAtLeastOneCustomizationSet: KTestConfig<
  boolean,
  DrinkTailoring
> = {
  name: 'hasAtLeastOneCustomizationSet',
  test: atLeastOneCustomizationSet,
  message: 'form.validations.atLeastOneCustomizationTailoringSet'
}

// ============================================================
// VS
// ============================================================
const variantTailoringVS = yupObject().shape({
  id: yupIgnore(),
  isHidden: yupBoolean().required(),
  hasCustomPrice: yupBoolean().required(),
  pricing: standardPricingArrayVS().min(1)
})

export const organizationTailoringsDrinkArrayVS: YupSchema<DrinkTailoring> =
  yupObject().shape({
    id: yupIgnore(),
    isHidden: yupBoolean<DrinkTailoring>()
      .required()
      .test(hasAtLeastOneCustomizationSet),
    hasCustomPrice: yupBoolean().required(),
    variants: yupArray(variantTailoringVS)
  })

export const useOrganizationTailoringsDrinkVS: UseValidationSchema<
  Tailorings<DrinkTailoring>
> = () =>
  yupObject().shape({
    id: yupIgnore(),
    tailorings: yupArray(organizationTailoringsDrinkArrayVS)
  })
