import { foodParse, foodWrite } from '@kampaay/common'
import {
  uploadOrKeepImg,
  uploadOrKeepMultipleImgs
} from 'services/utils/images'

const apiCfg = {
  endpoint: 'food',
  parse: foodParse,
  write: foodWrite(uploadOrKeepImg, uploadOrKeepMultipleImgs)
}

export default apiCfg
