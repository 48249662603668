import React from 'react'
import { useTranslate } from 'react-admin'
import { MappedEnrichedEvent } from 'services/api/entities/events/types/internal/event-models'
import { serviceDiscriminatorArray } from 'services/cfg/form'
import { pdfCss } from 'services/pdf-print/pdf-css'
import { getDateAsString, getTimeLabelFromDate } from '@kampaay/common'
import { RAFormFC } from 'types/common'
import { useForm } from 'hooks/useForm'
import LocalizedHeader from 'components/Fields/Localized/LocalizedHeader'
import Footer from 'resources/operations/events/EventsComponents/PdfRenderers/commonRenderers/Footer'

const BriefPdfRenderer: RAFormFC = ({ source }) => {
  const { useGetValue } = useForm<MappedEnrichedEvent>(source)

  const name = useGetValue('name')
  const customerInfo = useGetValue('customerInfo')
  const startDate = useGetValue('startDate')
  const guestsNumber = useGetValue('guestsNumber')
  const fullAddress = useGetValue('fullAddress')
  const surveyResponses = useGetValue('surveyResponses')

  const translate = useTranslate()
  return (
    <html>
      <head>
        <title>Event Brief {name}</title>
        <style>{pdfCss}</style>
      </head>
      <body>
        <section>
          <div className="page-header"></div>

          <div className="page-footer">
            <Footer />
          </div>

          <table className="quotation-body">
            <thead>
              <tr>
                <td>
                  <div className="header-space " />
                </td>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>
                  <div className="page-content">
                    <section>
                      <p className="text-right">
                        {getDateAsString(new Date().toISOString())}
                      </p>
                      <h4>Azienda</h4>
                      <p>{customerInfo.name}</p>
                      <h4>Numero di telefono referente</h4>
                      <p>{customerInfo.phone}</p>
                      <h4>Evento</h4>
                      <p>
                        {name} - {getDateAsString(startDate)}
                      </p>
                      <h4>Data e ora</h4>
                      <p>
                        {getDateAsString(startDate)} -{' '}
                        {getTimeLabelFromDate(startDate)}
                      </p>
                      <h4>Numero Ospiti</h4>
                      <p>{guestsNumber}</p>
                      <h4>Indirizzo</h4>
                      <p>{fullAddress}</p>
                    </section>
                    <section>
                      <h2 className="mt-15 mb-15 text-center">Brief</h2>
                      <hr />
                      <div>
                        {surveyResponses.map(
                          ({ questionResponses, supplieredService }, idx) => (
                            <section key={`question_service_${idx}`}>
                              <h2 className="underlined text-capitalize">
                                {(
                                  serviceDiscriminatorArray as string[]
                                ).includes(supplieredService)
                                  ? translate(
                                      `form.headers.${supplieredService}`
                                    )
                                  : supplieredService}
                              </h2>

                              <div>
                                {questionResponses?.map((qr, idx) => (
                                  <div key={idx} className="mt-15">
                                    <LocalizedHeader
                                      title={qr.question}
                                      headingLevel="h3"
                                    />
                                    <p className="mb-15">{qr.response}</p>
                                  </div>
                                ))}
                              </div>
                              <hr className="mt-15" />
                            </section>
                          )
                        )}
                      </div>
                    </section>
                  </div>
                </td>
              </tr>
            </tbody>

            <tfoot>
              <tr>
                <td>
                  <div className="footer-space">&nbsp;</div>
                </td>
              </tr>
            </tfoot>
          </table>
        </section>
      </body>
    </html>
  )
}

export default BriefPdfRenderer
