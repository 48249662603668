import { KTestConfig, KTestFunction, YupSchema } from 'services/yup/types'
import { yupArray } from 'services/yup/custom-schemas/array'
import { productCommonVS } from 'services/validations/catalog/productCommon'
import { yupString } from 'services/yup/custom-schemas/string'
import { yupIgnore, yupObject } from 'services/yup'
import { tailoredProductVS } from 'services/validations/catalog/tailoredProduct'
import {
  APILinkedIfClauseRule,
  APILinkedThenClauseRule,
  Highlight,
  MainProduct,
  RuleAction
} from '@kampaay/common'

const timeLimitValidation: KTestFunction<number> = async () => {
  return true
}

const isTimeLimitGreaterThenTheOneInCFG: KTestConfig<number> = {
  test: timeLimitValidation,
  message: () => 'error'
}

const ifClauseLinkedRulesVS: YupSchema<APILinkedIfClauseRule> =
  yupObject().shape({
    ruleName: yupString()
  })

const thenClauseLinkedRulesVS: YupSchema<APILinkedThenClauseRule> =
  yupObject().shape({
    ruleName: yupString(),
    action: yupString<RuleAction>(),
    ruleServicesWhere: yupArray()
  })

const highLightVS: YupSchema<Highlight> = yupObject().shape({
  preview: yupString(),
  content: yupString()
})

export const mainProductVS: YupSchema<MainProduct> = productCommonVS
  .concat(tailoredProductVS)
  .shape({
    ifClauseLinkedRules: yupArray(ifClauseLinkedRulesVS),
    thenClauseLinkedRules: yupArray(thenClauseLinkedRulesVS),
    sections: yupArray(),
    highlights: yupArray(highLightVS),
    advanceReservationMin: yupIgnore<number>().test(
      isTimeLimitGreaterThenTheOneInCFG
    ),
    suppliers: yupArray()
  })
