import { yupIgnore, yupObject, YupSchema, yupString } from 'services/yup'
import * as yup from 'yup'
import { TestConfig } from 'yup'
import Lazy from 'yup/lib/Lazy'
import {
  ALLOWED_FILE_TYPE,
  MAX_FILE_SIZE_BYTES,
  MAX_FILE_SIZE_MB
} from 'services/cfg/files'
import { DocToUpload, UploadedDoc, Doc } from '@kampaay/common'

/**
 * Checks if the document does not exceed the maximum file size
 */
const maxFileSizeCFG: TestConfig<File> = {
  name: 'maxFileSize',
  test: (e) => e.size < MAX_FILE_SIZE_BYTES,
  message: `The size of the document is too big, please stay below the ${MAX_FILE_SIZE_MB}MB`
}

/**
 * Checks if the document is of the allowed type
 */
const allowedFileTypesCFG: TestConfig<File> = {
  name: 'allowedFileTypes',
  test: (e) => e.type === ALLOWED_FILE_TYPE,
  message:
    'The format of the document you are trying to upload is not supported. Please upload a PDF file'
}

/**
 * This is a really tricky issue in yup vs.
 * Keep in mind that the yupObject transform the File type into null always,
 * so we need to use the mixed type to test the file size and type
 * This functionality is tested really well in the doc.test.ts file so we can keep any typing here
 */
export const fileVS: YupSchema<File> = yup
  .mixed()
  .test(maxFileSizeCFG)
  .test(allowedFileTypesCFG) as any

const browserUploadFileVS: YupSchema<DocToUpload> = yupObject().shape({
  rawFile: fileVS,
  src: yupString().required()
})

const uploadedDocVS: YupSchema<UploadedDoc> = yupObject().shape({
  id: yupIgnore(),
  title: yupString().required()
})

export const docVS: Lazy<YupSchema<Doc, Doc>> = yup.lazy((file) =>
  file.rawFile ? browserUploadFileVS : uploadedDocVS
)
