import { RaRecord } from 'ra-core'
import { EntityWithCSV, CSVToAPIEntityMap } from 'services/csv/types'
import { ValueOf } from 'types/common'

/**
 * Filters the related records by the parent record id and remaps them to an object
 * @param relatedResponse array of tuples [key, relatedRecords]
 * @param parentRecord the record to use for filtering the related records
 * @returns an object with the related records filtered by the parent record
 */
export const filterRelatedResponseById = <T extends EntityWithCSV>(
  relatedResponse: [keyof CSVToAPIEntityMap[T], RaRecord[]][],
  parentRecord: CSVToAPIEntityMap[T]
): Record<keyof CSVToAPIEntityMap[T], ValueOf<CSVToAPIEntityMap[T]>[]> => {
  return relatedResponse.reduce(
    (acc, [key, relatedRecords]) => ({
      ...acc,
      [key]: relatedRecords.filter((relatedRecord) => {
        const value = parentRecord[key]
        // The parent record may contain either an array of realted ids or a single related id
        if (Array.isArray(value)) {
          return value.includes(relatedRecord.id)
        }
        return value === relatedRecord.id
      })
    }),
    {} as Record<keyof CSVToAPIEntityMap[T], ValueOf<CSVToAPIEntityMap[T]>[]>
  )
}
