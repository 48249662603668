import { APICfg } from 'services/api/api'
import { parseDrinkTailorings } from 'services/api/entities/crm/organizations/emsTailoring/common/parsers'
import { writeDrinkTailorings } from 'services/api/entities/crm/organizations/emsTailoring/common/writers'

const apiCfg: APICfg = {
  endpoint: '',
  getOneEndpoint: 'organization/:id/tailoring/drinks',
  parse: parseDrinkTailorings,
  write: writeDrinkTailorings
}

export default apiCfg
