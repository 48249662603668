import { keys } from 'services/utils'
import { APICfg } from 'services/api/api'
import { uploadOrKeepMultipleFiles } from 'services/utils/files'
import { APIServicesConfig } from 'services/api/entities/services-config/types/api/response-models'
import { ServicesConfigToAPI } from 'services/api/entities/services-config/types/api/request-models'
import { ServicesConfig } from 'services/api/entities/services-config/types/internal/services-config-models'
import { parseRaMultipleDocs } from 'services/api/raParsers'
import { parseTimeBeforeHint, writeTimeBeforeHint } from '@kampaay/common'

const apiCfg: APICfg = {
  endpoint: 'serviceconfig',
  isSingleEntity: true,
  UPDATE_METHOD: 'POST',
  parse: ({
    foodService,
    rentService,
    musicService,
    drinkService,
    virtualService,
    physicalService,
    locationService,
    ...res
  }: APIServicesConfig): ServicesConfig => {
    return {
      ...res,
      foodService: {
        ...foodService,
        ...parseTimeBeforeHint(foodService),
        eventKampaayerDocs: parseRaMultipleDocs(foodService.eventKampaayerDocs)
      },
      rentService: {
        ...rentService,
        eventKampaayerDocs: parseRaMultipleDocs(rentService.eventKampaayerDocs)
      },
      musicService: {
        ...musicService,
        eventKampaayerDocs: parseRaMultipleDocs(musicService.eventKampaayerDocs)
      },
      drinkService: {
        ...drinkService,
        eventKampaayerDocs: parseRaMultipleDocs(drinkService.eventKampaayerDocs)
      },
      virtualService: {
        ...virtualService,
        eventKampaayerDocs: parseRaMultipleDocs(
          virtualService.eventKampaayerDocs
        )
      },
      physicalService: {
        ...physicalService,
        eventKampaayerDocs: parseRaMultipleDocs(
          physicalService.eventKampaayerDocs
        )
      },
      locationService: {
        ...locationService,
        eventKampaayerDocs: parseRaMultipleDocs(
          locationService.eventKampaayerDocs
        )
      },
      professionalService: res.professionalService.services.length
        ? res.professionalService
        : {
            advanceReservationMin:
              res.professionalService.advanceReservationMin ?? 0,
            services: res.professionalService.services,
            serviceDiscriminator: res.professionalService.serviceDiscriminator
          },
      id: 'servicesconfig'
    }
  },
  write: async (req: ServicesConfig): Promise<ServicesConfigToAPI> => {
    const { id: _id, professionalService, generalService, ...rest } = req
    const mappedProfessionalServices = professionalService.services.map(
      (s) => ({
        ...s,
        section: s.section.id
      })
    )
    const servicesDocuments = await Promise.all(
      keys(rest).map((servName) =>
        uploadOrKeepMultipleFiles(req[servName].eventKampaayerDocs).then(
          (res) => ({
            servName,
            res
          })
        )
      )
    )

    // TODO extract our internal models from food hint to not send them to the api
    const mapped = servicesDocuments.reduce(
      (acc, { servName, res }) => ({
        ...acc,
        [servName]: {
          ...req[servName],
          ...(servName === 'foodService'
            ? writeTimeBeforeHint(req.foodService)
            : {}),
          eventKampaayerDocs: res
        }
      }),
      Object.create(null)
    )

    return {
      ...mapped,
      generalService,
      professionalService: {
        ...req.professionalService,
        services: mappedProfessionalServices
      }
    }
  }
}

export default apiCfg
