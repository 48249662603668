import React from 'react'
import { TextField } from 'react-admin'
import { exportFormatter } from 'services/csv/exporter'
import CatalogItemBaseList, {
  CatalogItemBaseListProps
} from 'components/Lists/CatalogItemBaseList'
import KCloneButton from 'components/Buttons/KCloneButton'

const LocationFeatureList: React.FC<CatalogItemBaseListProps> = (props) => (
  <CatalogItemBaseList {...props} exporter={exportFormatter()}>
    <TextField source="category" label="Item Type" />
    <KCloneButton />
  </CatalogItemBaseList>
)

export default LocationFeatureList
