import React from 'react'
import { BulkDeleteWithConfirmButton } from 'react-admin'
import KBulkExportButton from 'components/Buttons/KBulkExportButton'

/**
 * This component is used in the lists to add like bulk actions:
 * - Export
 * - Delete
 *
 * @see https://marmelab.com/react-admin/Datagrid.html#bulkactionbuttons
 */
const DeleteAndExportBulk: React.FC = () => {
  return (
    <>
      <KBulkExportButton />
      <BulkDeleteWithConfirmButton mutationMode="optimistic" />
    </>
  )
}
export default DeleteAndExportBulk
