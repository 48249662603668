import {
  KTestConfig,
  KTestFunction,
  UseValidationSchema,
  yupArray,
  yupBoolean,
  yupIgnore,
  yupObject,
  YupSchema
} from 'services/yup'
import {
  hasAtLeastOneToggleEnabledCustomPrice,
  hasAtLeastOneToggleEnabledIsHidden
} from 'services/validations/crm/oraganizationTailorings/sharedTestConfig'
import { areRulesNotOverlappingCFG } from 'services/validations/catalog/by-rule-pricing/byRulePricing'
import { professionalByRulePricingVS } from 'services/validations/catalog/professional'
import {
  ProductTailoring,
  ProductTailoringBase,
  ProfessionalPricingRuleSet,
  Tailorings
} from '@kampaay/common'

// ================================================
// tests
// ================================================
const atLeastOnePricingWhenCustomPriceEnabled: KTestFunction<
  boolean,
  ProductTailoring<ProfessionalPricingRuleSet>
> = (hasCustomPrice, { parent: { pricing } }) => {
  return !!hasCustomPrice ? !!pricing?.length : true
}

export const hasAtLeastOnePricingWhenCustomPriceEnabled: KTestConfig<
  boolean,
  ProductTailoring<ProfessionalPricingRuleSet>
> = {
  name: 'hasAtLeastOnePricingWhenCustomPriceEnabled',
  test: atLeastOnePricingWhenCustomPriceEnabled,
  message: 'form.validations.atLeastOnePricing'
}

// ============================================================
// VS
// ============================================================
export const organizationTailoringsProfessionalArrayVS: YupSchema<
  ProductTailoring<ProfessionalPricingRuleSet>
> = yupObject().shape({
  id: yupIgnore(),
  isHidden: yupBoolean<ProductTailoringBase>()
    .required()
    .test(hasAtLeastOneToggleEnabledIsHidden),
  hasCustomPrice: yupBoolean<ProductTailoring<ProfessionalPricingRuleSet>>()
    .required()
    .test(hasAtLeastOneToggleEnabledCustomPrice)
    .test(hasAtLeastOnePricingWhenCustomPriceEnabled),
  pricing: yupArray(professionalByRulePricingVS).test(areRulesNotOverlappingCFG)
})

export const useOrganizationTailoringsProfessionalVS: UseValidationSchema<
  Tailorings<ProductTailoring<ProfessionalPricingRuleSet>>
> = () =>
  yupObject().shape({
    id: yupIgnore(),
    tailorings: yupArray(organizationTailoringsProfessionalArrayVS)
  })
