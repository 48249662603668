import { SortPayload } from 'react-admin'

/**
 * @param sort
 * @returns the query for sorting if field is not id
 */
export const encodeSort = (sort?: SortPayload) => {
  if (
    sort &&
    (sort.order.toLowerCase() === 'asc' || sort.order.toLowerCase() === 'desc')
  )
    return `${sort.field}-${sort.order.toLowerCase()}`
  else return ''
}
