import React from 'react'
import StandardPricingInput from 'components/FormComponents/pricing/standard/StandardPricingInput'
import KImageInput from 'components/Inputs/KImageInput'
import ProductCommonFormContent from 'components/Forms/ProductCommonFormContent'

const LogisticsFormContent: React.FC = () => {
  return (
    <ProductCommonFormContent>
      <KImageInput />
      <StandardPricingInput hideVariablePrice isSinglePriceRange />
    </ProductCommonFormContent>
  )
}

export default LogisticsFormContent
