import { UseValidationSchema } from 'services/yup/types'
import { useOptionedProductVS } from 'services/validations/catalog/optionedProduct'
import { yupIgnore, yupNumber } from 'services/yup'
import { APIPriceRange, Experience } from '@kampaay/common'

export const useExperienceVS: UseValidationSchema<
  Experience,
  APIPriceRange[] | undefined
> = () =>
  useOptionedProductVS().shape({
    maxQuantity: yupNumber(),
    skillFacet: yupIgnore(),
    tagFacets: yupIgnore()
  })
