import { createMockBuilder } from '../../../../utils'
import type { DocumentExtractedService } from '../types'

const buildBaseMock = (): DocumentExtractedService => ({
  name: 'name',
  documents: []
})

export const buildDocumentExtractedServiceMock =
  createMockBuilder(buildBaseMock)
