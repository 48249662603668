import { CreateParams } from 'react-admin'
import { APIEntity } from 'services/api/entities'
import { callPost, getApiCfg } from 'services/api/dataProvider/common'

const dataProviderExtension = {
  reorder: (resource: APIEntity, params: CreateParams) => {
    const apiCfg = getApiCfg(resource)
    return callPost(
      apiCfg,
      `${apiCfg.endpoint}/reorder`,
      'POST',
      params.data,
      true
    )
  }
}

export type CustomDpExtension = typeof dataProviderExtension

export default dataProviderExtension
