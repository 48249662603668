import React from 'react'
import { getSelectionChoices } from 'resources/operations/events/utils'
import { RAFormFC } from 'types/common'
import LocalizedAutocompleteInput from 'components/Inputs/Localized/LocalizedAutocompleteInput'
import EventProductQuantityInput from 'components/FormComponents/EventProductQuantityInput'
import { useForm } from 'hooks/useForm'
import { ProductSelectionBase } from 'services/api/entities/events/types/internal/selections'
import { WithIdAndName } from '@kampaay/common'

export type EventSubSelectionInputContentProps = {
  label: string
  choices?: WithIdAndName[]
  hideQuantity?: boolean
  disableInputs?: boolean
}

const EventSubSelectionInputContent: RAFormFC<
  EventSubSelectionInputContentProps
> = ({ source, label, choices = [], hideQuantity, disableInputs = false }) => {
  const { getSource, useGetValue, useSetValue } =
    useForm<ProductSelectionBase>(source)
  const id = useGetValue('id')
  const name = useGetValue('name')
  const setName = useSetValue('name')

  return (
    <div style={{ display: 'flex', width: '100%' }}>
      {!hideQuantity && (
        <EventProductQuantityInput disabled={disableInputs} source={source} />
      )}
      <LocalizedAutocompleteInput
        source={getSource('id')}
        fullWidth
        label={label}
        choices={getSelectionChoices({ id, name }, choices)}
        disabled={disableInputs}
        onSelect={() => setName('')}
      />
    </div>
  )
}

export default EventSubSelectionInputContent
