import React from 'react'
import { FormDataConsumer, SimpleFormIterator } from 'react-admin'
import { useKGetList } from 'hooks/useKGetList'
import { FormDataConsumerProps } from 'types/common'
import BriefQuestionArrayInput from 'components/FormComponents/Brief/BriefQuestionArrayInput'
import KDivider from 'components/UI/KDivider'
import KFormTab from 'components/Forms/KForms/KFormTab'
import KNumberInput from 'components/Inputs/KInputs/KNumberInput/KNumberInput'
import {
  ProfessionalServiceConfig,
  ServicesConfig
} from 'services/api/entities/services-config/types/internal/services-config-models'
import KArrayInput from 'components/Inputs/KInputs/KArrayInput'
import { ProfessionalFigure } from '@kampaay/common'

// FIXME: find out if we can change this any typing
const ProfessionalsCfgTab: React.FC<any> = (props) => {
  // we need to remove these entries from the props to avoid triggering errors in the nested components
  const { undoable: _undoable, saving: _saving, save: _save, ...rest } = props
  const { list: productChoices } =
    useKGetList<ProfessionalFigure>('professionals')
  return (
    <KFormTab {...rest} label="form.tabs.professionals">
      <KDivider title="Service Time Limit" />
      <KNumberInput
        source="professionalService.advanceReservationMin"
        label="form.fields.advanceReservationMin"
      />
      <KDivider title="Brief Questions" />
      <KArrayInput
        source="professionalService.services"
        label="Professional Services"
      >
        <SimpleFormIterator disableAdd disableRemove>
          <FormDataConsumer>
            {({
              scopedFormData,
              getSource
            }: FormDataConsumerProps<
              ServicesConfig,
              ProfessionalServiceConfig
            >) => {
              if (!scopedFormData) return null
              return (
                <>
                  <h3>{scopedFormData.section.name}</h3>
                  <BriefQuestionArrayInput
                    source={getSource!('brief')}
                    productChoices={productChoices.filter(
                      (product) =>
                        product.sectionName === scopedFormData.section.name
                    )}
                  />
                </>
              )
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </KArrayInput>
    </KFormTab>
  )
}

export default ProfessionalsCfgTab
