import { add, addDays, format, isAfter } from 'date-fns'
import type {
  DateOnly,
  DayMonthLabel,
  HoursDigits,
  ISODate,
  MinutesDigits,
  NullableWithDayAndMonth,
  TimeLabel
} from '../types'
import { countrySwitchStrict } from '../geo-management'

export const removeMillisecondsFromIso = (date: string) =>
  date.replace(/[.]\d+/, '')

/**
 * @deprecated use formatKDate instead
 * from a given date, return a string like this: MM-YYYY, 02-2021
 */
export const getMonthAndYearFromDate = (date?: string): string =>
  date ? format(new Date(date), 'MM-yyyy') : '---'

/**
 * @deprecated use formatKDate instead
 * from a given date, return a string like this: HH:MM, 15:55
 */
export const getTimeLabelFromDate = (date?: Date | string): TimeLabel | '---' =>
  date ? (format(new Date(date), 'HH:mm') as TimeLabel) : '---'

/**
 * @deprecated use formatKDate instead
 * from a given date, return a string like this: DD-MM-YYYY, 28-02-2021
 */
export const getDateAsString = (date?: string | Date) =>
  date ? format(new Date(date), 'dd-MM-yyyy') : '---'

/**
 * ### Date config for standard cases ONLY
 * An object containing various date and time format strings.
 * These formats are intended to be used with the date-fns library's formatting functions.
 *
 *
 * Feel free to add a format if the one you look for is missing
 * but don't change the delimiter.
 *
 */

export type AvailableDateFormats = {
  dateOnly: string
  monthAndYear: string
  time: string
  dateTime: string
}

const K_DATE_FORMATS_IT = {
  dateOnly: 'dd/MM/yyyy',
  monthAndYear: 'MM/yyyy',
  time: 'HH:mm',
  dateTime: 'dd/MM/yyyy HH:mm'
} as const satisfies AvailableDateFormats
const K_DATE_FORMATS_SE = {
  dateOnly: 'yyyy/MM/dd',
  monthAndYear: 'yyyy/MM',
  time: 'HH:mm',
  dateTime: 'yyyy/MM/dd HH:mm'
} as const satisfies AvailableDateFormats

const K_DATE_FORMATS = countrySwitchStrict<AvailableDateFormats>({
  IT: K_DATE_FORMATS_IT,
  SE: K_DATE_FORMATS_SE
})

type KDateFormats = typeof K_DATE_FORMATS

/**
 * Type guard function to check if a string is a key of KDateFormats.
 *
 * @param {string} key - The string to be checked.
 * @returns {key is keyof KDateFormats} Returns true if the string is a key of KDateFormats, false otherwise.
 *
 * @example
 * // Returns true and keeps the information for the typescript compiler
 * isKeyOfKDateFormats('YYYY-MM-DD')
 *
 * // Returns false and keeps the information for the typescript compiler
 * isKeyOfKDateFormats('invalid-key')
 */
export const isKeyOfKDateFormats = (key: string): key is keyof KDateFormats =>
  key in K_DATE_FORMATS

type FormatOptions = NonNullable<Parameters<typeof format>[2]>

/**
 * Formats a date according to the specified format string.
 *
 * @template TStrictMode - A type parameter that can be either 'strict' or 'relaxed'. Default is 'strict'.
 * @param {Date | number} date - The date to format. Can be a Date object or a number representing a timestamp.
 * @param {TStrictMode extends 'strict' ? keyof KDateFormats : string} formatString - The format string to use. If TStrictMode is 'strict', this must be a key of KDateFormats. If TStrictMode is 'relaxed', this can be any string.
 * @param {FormatOptions} [options] - Optional formatting options.
 * @returns {string} The formatted date.
 */
export const formatKDate = <
  TStrictMode extends 'strict' | 'relaxed' = 'strict'
>(
  date: Date | number,
  formatString: TStrictMode extends 'strict' ? keyof KDateFormats : string,
  options?: FormatOptions
): string => {
  const formatStr = isKeyOfKDateFormats(formatString)
    ? K_DATE_FORMATS[formatString]
    : formatString
  return format(date, formatStr, options)
}

export const getLabelFromTime = (hours: number, minutes: number): TimeLabel => {
  const hoursString = String(hours).padStart(2, '0') as HoursDigits
  const minutesString = String(minutes).padStart(2, '0') as MinutesDigits
  return `${hoursString}:${minutesString}`
}

export const getTimeLabels = () => {
  const items = []
  for (let i = 0; i < 24; i++) {
    const hour = getLabelFromTime(i, 0)
    const andHalf = getLabelFromTime(i, 30)
    items.push({ id: hour, name: hour }, { id: andHalf, name: andHalf })
  }
  return items
}

export const getDayAndMonthFromLabel = (
  l?: DayMonthLabel
): NullableWithDayAndMonth => {
  const decoded = l?.split('-')

  return {
    month: decoded?.[2] ?? '',
    day: decoded?.[3] ?? ''
  } as NullableWithDayAndMonth
}

export const getDayMonthLabel = (dayAndMonth: NullableWithDayAndMonth) => {
  const { day, month } = dayAndMonth

  if (!day || !month) {
    throw new Error(
      'ERROR: you are trying to build a dayMonth label with at least one empty string value, these should always be defined instead'
    )
  }

  return `--${month}-${day}`
}

/**
 * Returns true if the given date is older than one hour from the current date
 * @param date - the date to check
 */
export const isOlderThanOneHour = (date: Date | ISODate): boolean =>
  isAfter(new Date(), add(new Date(date), { hours: 1 }))

export const addDaysToDayMonthLabel = (l: DayMonthLabel, days: number) =>
  format(addDays(new Date(l), days), '--MM-dd') as DayMonthLabel

/**
 * Checks if the passed parameter is a Date
 * @param val
 */
export const isDate = (val: any): val is Date =>
  val && Object.prototype.toString.call(val) === '[object Date]' && !isNaN(val)

/**
 * Tests if a date string is a valid ISO date string with any precision and any or no timezone offset
 *
 * @param date the date string to test
 * @returns  true if the date string is a valid ISO date string, false otherwise
 * @link https://stackoverflow.com/a/3143231/11571888
 */
export const isISODate = (date: string): date is ISODate => {
  const regex =
    /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/

  // The regex is a combination of the three previous regexes:
  // - \d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z): Matches a date string with a precision of at least milliseconds and any or no timezone offset.
  // - \d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z): Matches a date string with a precision of at least seconds and any or no timezone offset.
  // - \d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z): Matches a date string with a precision of at least minutes and any or no timezone offset.

  // - \d{4}: Matches a 4-digit year (e.g., 2023).
  // -[01]\d: Matches a hyphen followed by a month in the range of 01 to 12 (e.g., -09 for September).
  // -[0-3]\d: Matches a hyphen followed by a day in the range of 01 to 31 (e.g., -15 for the 15th day of the month).
  // - T: Matches the letter 'T' to separate the date and time components.
  // - [0-2]\d:[0-5]\d: Matches the time component in the format HH:MM, where HH is the hour (00-23) and MM is the minute (00-59).

  return regex.test(date)
}

/**
 * Converts a Date object to an ISO date string.
 *
 * @param {Date} d - The Date object to be converted.
 * @returns {ISODate} The ISO date string representation of the input Date object.
 *
 * @example
 * // Returns "2022-03-14T00:00:00.000Z"
 * dateToISOstring(new Date('2022-03-14'))
 */
export const dateToISODate = (d: Date): ISODate => d.toISOString() as ISODate

export const dateToDateOnly = (d: Date | ISODate): DateOnly =>
  format(new Date(d), 'yyyy-MM-dd')

export const getTimeFromLabel = (label: TimeLabel) => {
  const [hours, minutes] = label.split(':').map((e) => parseInt(e))

  return {
    hours: hours!,
    minutes: minutes!
  }
}

// it merges date and time in a date
export const getDateFromDateAndTime = (
  date: Date | string,
  time: TimeLabel
) => {
  const { hours, minutes } = getTimeFromLabel(time)
  const d = new Date(date)

  d.setHours(hours)
  d.setMinutes(minutes)

  return d
}

export const getHoursFromLabel = (label: TimeLabel) => {
  const { hours, minutes } = getTimeFromLabel(label)
  return hours + minutes / 60
}

/**
 * returns the time difference (in hours) of 2 time label (+24h if end precedes start)
 * @param start string
 * @param end string
 */
export const getHoursDelta = (start: TimeLabel, end: TimeLabel) => {
  const diff = getHoursFromLabel(end) - getHoursFromLabel(start)
  return diff > 0 ? diff : diff + 24
}
