import React from 'react'
import {
  TextFieldProps,
  Box,
  TableCell,
  TextField,
  IconButton
} from '@mui/material'
import { Clear } from '@mui/icons-material'

type Props = TextFieldProps & {
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleClear: (e: React.MouseEvent<HTMLElement>) => void
}
export const ResettableFilterInput: React.FC<Props> = ({
  handleChange,
  label,
  value,
  variant = 'outlined',
  handleClear,
  onKeyDown
}) => {
  return (
    <TableCell>
      <Box display="flex">
        <TextField
          variant={variant}
          value={value}
          onChange={handleChange}
          size="small"
          label={label}
          style={{ flex: 1 }}
          onKeyDown={onKeyDown}
        />
        {!!value ? (
          <IconButton aria-label="delete" onClick={handleClear}>
            <Clear color="error" />
          </IconButton>
        ) : null}
      </Box>
    </TableCell>
  )
}
