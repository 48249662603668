import { Doc } from '@kampaay/common'
import { FileInfo, FileInfoToAPI } from '@kampaay/common'
import { uploadDoc } from 'services/api/dataProvider/common'

export const uploadOrKeepFile = async (
  doc?: FileInfo
): Promise<FileInfoToAPI | undefined> => {
  if (!doc) return
  if (!!doc.rawFile) return await uploadDoc(doc.rawFile)
  return doc.id
}

export const uploadOrKeepMultipleFiles = (fileOrObjArray: Doc[] = []) => {
  return Promise.all(
    fileOrObjArray.map((file) =>
      'rawFile' in file ? uploadDoc(file.rawFile) : file.id
    )
  )
}

/**
 * Given a file name, the function returns the file extension
 * without the dot.
 *
 * @param fileName the name of the file
 * @returns the file extension or an empty string if the file has no extension specified in the filename
 */
export const getFileExtension = (fileName: string): string => {
  if (fileName.indexOf('.') === -1) return ''
  return fileName.split('.').pop() || ''
}

/**
 * Given a file name, the function returns the file name
 * shortened to maxLength characters followed by an ellipsis and
 * the extension.
 * @param fileName
 * @param maxLength
 * @returns the shortened file name with the extension. If the file has no extension specified in the filename, the function returns the file name with an ellipsis
 */
export const getFileNameWithExtensionEllipsis = (
  fileName: string,
  maxLength = 5
): string => {
  const extension = getFileExtension(fileName)
  const name =
    extension.length === 0
      ? fileName
      : fileName.replace(new RegExp(`.${extension}` + '$'), '')
  if (name.length <= maxLength) return fileName
  return `${name.slice(0, maxLength)}...${extension}`
}

/**
 * Given a file URL, the function downloads the file.
 * @param url the URL of the file to download
 * @param filename the name of the file to download
 */
export const downloadFile = (url: string, filename: string) => {
  // Create an anchor element
  const a = document.createElement('a')
  a.href = url
  a.target = '_blank'
  a.download = filename
  document.body.appendChild(a)

  // Trigger a click event on the anchor to initiate the download
  a.click()

  // Release the object URL when done
  URL.revokeObjectURL(url)

  // Remove the anchor from the DOM
  a.remove()
}
