import { Translate } from 'react-admin'
import { Drink } from 'services/api/entities/catalog/drink/types/internal/drink-models'
import { Location } from 'services/api/entities/catalog/location/types/internal/location-models'
import { ProfessionalSection } from 'services/api/entities/catalog/sections/professionalSections'
import { ProductSection } from 'services/api/entities/catalog/types'
import {
  DrinkSelection,
  FoodSelection,
  LocationSelection,
  MainProductSelection,
  OptionedProductSelection,
  OptionGroupSelection,
  ProfessionalSelection
} from 'services/api/entities/events/types/internal/selections'
import { EventProfessionalService } from 'services/api/entities/events/types/internal/services'
import { entries, getIndexById } from 'services/utils'
import {
  APIProfessionalFigure,
  Food,
  getById,
  getId,
  MainProduct,
  OptionedProduct,
  OptionGroup,
  WithId,
  WithIdAndName
} from '@kampaay/common'
import { getReactAppEnv } from 'services/env'
import { CheckoutQuestionReply, QuestionReply } from '@kampaay/common'
import { intersectNonEmptyArrays, isNotEmpty } from 'services/utils/array'
import { someStringsAreNonEmpty } from 'services/utils/string'
import { KConsole } from 'services/console'

const getOptionalId = <T extends { id?: number }>(obj: T) => obj?.id

const isCreating = (formItem?: Partial<WithIdAndName>) =>
  !formItem || !formItem?.id || !formItem?.name

const isCreatingBaseProduct = (formItem?: Partial<MainProductSelection>) => {
  return isCreating(formItem) || !formItem!.quantity
}

const isCreatingOptioned = (formItem?: Partial<OptionedProductSelection>) => {
  if (isCreatingBaseProduct(formItem)) return true
  const { optionSelections, upsellingSelection } = formItem!
  return !optionSelections || !upsellingSelection
}

export const getSelectionChoices = <T extends WithIdAndName>(
  sel?: Partial<WithIdAndName>,
  catalogChoices: T[] = []
) => {
  // this first case handles the case in which we are adding a new item -> the selection is undefined
  if (isCreating(sel)) return catalogChoices!

  // here we check if the selection is available in the catalog and replace the catalog one with the snapshotted one
  // here the selection will always have id and name and also be defined because the check is done in "isCreating"
  if (!!getById(catalogChoices, sel!.id)) {
    const catalogClone = [...catalogChoices]
    catalogClone.splice(getIndexById(catalogClone, sel!.id)!, 1, sel as T)
    return catalogClone
  } else {
    // else we return the selection as the only available choice
    return [sel as T]
  }
}

export const isLocationObsolete = (
  catalog: Location[],
  formLocation?: Partial<LocationSelection>
) => {
  if (isCreating(formLocation) || !formLocation) return false
  const catalogItem = getById(catalog, formLocation.id!)
  // Snapshotted location is not available anymore
  if (!catalogItem) return true
  if (isLocationArrangementObsolete(catalogItem, formLocation)) return true

  return areUpsellingObsolete(
    catalogItem.upsellingItems,
    (formLocation.upsellingSelection ?? []).map(getOptionalId)
  )
}

const isLocationArrangementObsolete = (
  catalogItem: Location,
  formItem: Partial<LocationSelection>
) => {
  if (!formItem?.arrangementName) return false

  return catalogItem.arrangements[formItem.arrangementName]?.status !== 'active'
}

export const isBaseProductObsolete = (
  catalog: WithIdAndName[],
  baseProductSnap?: Partial<MainProductSelection>
) => {
  if (isCreatingBaseProduct(baseProductSnap)) return false
  return !getById(catalog, baseProductSnap!.id!)
}

export const isDrinkObsolete = (
  drinksFromCatalog: Drink[],
  snapshotDrink?: DrinkSelection
) => {
  if (isCreatingBaseProduct(snapshotDrink)) return false
  const itemFromCatalog = getById(drinksFromCatalog, snapshotDrink?.id)
  if (!itemFromCatalog) return true
  return !!(
    snapshotDrink!.variants?.length &&
    !snapshotDrink!.variants?.some(
      (variant) =>
        !!variant &&
        !!variant.id &&
        itemFromCatalog.variants.map(getId).includes(variant.id)
    )
  )
}

const areUpsellingObsolete = (
  catalogItemUpselling: number[],
  snapshotUpselling: (number | undefined)[]
  // here we have to assume that ids from the form are truthy to evaluate them
  // because they old be undefined while adding a new element
) => snapshotUpselling.some((id) => !!id && !catalogItemUpselling.includes(id))

const areOptionsObsolete = (
  catalogOptions: OptionGroup[],
  snapshotOptions: OptionGroupSelection[]
) => {
  const catalogGroupsIds = catalogOptions.map(getId)
  const snapshotGroupIds = snapshotOptions.map(getOptionalId)
  // here we have to assume that ids from the form are truthy to evaluate them
  // because they old be undefined while adding a new element
  if (snapshotGroupIds.some((id) => !!id && !catalogGroupsIds.includes(id)))
    return true

  const catalogItemsIds = catalogOptions.flatMap((grp) => grp.items.map(getId))
  const snapshotItemsIds = snapshotOptions.flatMap((grp) =>
    grp.items.map(getOptionalId)
  )
  return snapshotItemsIds.some((id) => !!id && !catalogItemsIds.includes(id))
}

export const isOptionedObsolete = (
  itemsCatalog: OptionedProduct[],
  formItem: Partial<OptionedProductSelection>
) => {
  // this check below doesn't break the function if the form is still being filled and incomplete
  if (isCreatingOptioned(formItem)) return false
  // the checks below take care of the snapshotted product
  const itemFromCatalog = getById(itemsCatalog, formItem.id!)
  if (!itemFromCatalog) return true
  if (
    areUpsellingObsolete(
      itemFromCatalog.upsellingItems,
      (formItem.upsellingSelection ?? []).map(getOptionalId)
    )
  ) {
    return true
  }
  return areOptionsObsolete(itemFromCatalog.options, formItem.optionSelections!)
}

export const isFoodObsolete = (
  itemsCatalog: Food[],
  formItem?: Partial<FoodSelection>
) => {
  if (isCreatingOptioned(formItem)) return false
  const { logisticSelection, ...optioned } = formItem!
  if (isOptionedObsolete(itemsCatalog, optioned)) return true
  const itemFromCatalog = getById(itemsCatalog, formItem?.id)
  // this check below doesn't break the function if the form is still being filled and incomplete
  if (!logisticSelection?.id) return false
  return !itemFromCatalog!.logistics.includes(logisticSelection.id)
}

const areFiguresObsolete = (
  snapshotFigures: ProfessionalSelection[],
  sectionFigures: APIProfessionalFigure[]
) =>
  snapshotFigures.some(
    (f) => !!f && !!f.id && !sectionFigures.map(getId).includes(f.id)
  )

export const isProfessionalServiceObsolete = (
  catalogSections: ProfessionalSection[],
  formProService?: EventProfessionalService
) => {
  // this check below doesn't break the function if the form is still being filled and incomplete
  if (!formProService?.selections || !formProService?.id) return false
  const catalogSection = getById(catalogSections, formProService.id)
  if (!catalogSection) return true
  return areFiguresObsolete(
    formProService.selections,
    catalogSection.productsFull
  )
}

export const resetFormItemName =
  <T extends Partial<WithIdAndName>>(formItem?: T) =>
  () => {
    if (formItem) {
      formItem.name = ''
    }
  }

export const groupBy = <T, K extends keyof any>(
  items: T[],
  getKey: (item: T) => K
) =>
  items.reduce<Record<K, T[]>>((acc, item) => {
    const key = getKey(item)
    return {
      ...acc,
      [key]: [...(acc[key] ?? []), item]
    }
  }, {} as Record<K, T[]>)

export const getLocationArrangementChoices = (
  translate: Translate,
  formItem?: Partial<LocationSelection>,
  catalogItem?: Location
) => {
  // from the arrangements bound to the catalog item we only pick the 'active' ones, and map them to a collection of their names
  const catalogArrangementNames = catalogItem?.arrangements
    ? entries(catalogItem.arrangements)
        .filter(([, { status }]) => status === 'active')
        .map(([name]) => name)
    : []

  // if there is a selection already done we check if is still available in the catalog item,
  // if not we add it to the possible choices to select. if yes we directly pick the choices from the catalog
  const availableArrangementNames =
    formItem?.arrangementName &&
    !catalogArrangementNames.includes(formItem?.arrangementName)
      ? [formItem?.arrangementName, ...catalogArrangementNames]
      : catalogArrangementNames

  // we then map the names to an array of choices for the select input
  return availableArrangementNames.map((a) => ({
    id: a,
    name: translate(`form.fields.${a}Arrangement`)
  }))
}

export const include = <T extends WithId>(ids: number[], collection: T[]) =>
  collection.filter((e) => ids.includes(e.id))

/**
 * This function access a nested object property by path
 * path example: obj.someArr[4].name
 * @returns the nested property of an object
 */
export const accessPropertyByPath = <T extends Object>(
  obj: T,
  path: string
) => {
  const parsedPath = path
    .replace(/\[(\w+)]/g, '.$1')
    .replace(/^\./, '')
    .split('.')
  let o: any = obj
  parsedPath.forEach((e) => {
    o = o[e as keyof T]
  })

  return o
}

export const isSectionValid = (section: ProductSection<any>) =>
  isNotEmpty(section.products)

export const hasIntersection =
  (choices: MainProduct[]) => (e?: (WithId | undefined)[]) => {
    const choicesCustomers =
      e?.map((el) => getById(choices, el?.id)?.customers ?? []) ?? []
    const choicesOrganizations =
      e?.map((el) => getById(choices, el?.id)?.organizations ?? []) ?? []
    if (
      choicesCustomers.flat().length === 0 &&
      choicesOrganizations.flat().length === 0
    )
      return undefined
    if (!intersectNonEmptyArrays(choicesCustomers).length) {
      return 'You Trying to add products without no common customers'
    }
    if (!intersectNonEmptyArrays(choicesOrganizations).length) {
      return 'You Trying to add products without no common organizations'
    }
    return undefined
  }

export const DEPLOYED_ENVS = ['staging', 'demo', 'production'] as const

type DeployedEnv = (typeof DEPLOYED_ENVS)[number]

export const storeFrontBaseUrl: Record<DeployedEnv, string> = {
  staging: 'https://app.staging.kampaay.com',
  demo: 'https://app-demo.staging.kampaay.com',
  production: 'https://app.kampaay.com'
}

const isDeployedEnv = (env: string): env is DeployedEnv =>
  DEPLOYED_ENVS.includes(env as DeployedEnv)

export const getStoreFrontBaseUrlByEnvironment = () => {
  const env = getReactAppEnv()!
  if (isDeployedEnv(env)) {
    return storeFrontBaseUrl[env]
  } else {
    KConsole.warn(`env not found: "${env}": fallback to staging`)
    return storeFrontBaseUrl.staging
  }
}

export const someRepliesIsFilled = (
  replies: (QuestionReply | CheckoutQuestionReply)[]
) => {
  return someStringsAreNonEmpty(
    ...replies.map((qr) => {
      if ('responses' in qr) {
        return qr.responses.join('')
      } else {
        return qr.response
      }
    })
  )
}
