import type { ProposalHistory, ProposalHistoryToAPI } from './types'

export const proposalHistoryWrite = async ({
  id,
  concierge
}: ProposalHistory): Promise<ProposalHistoryToAPI> => {
  return {
    id,
    concierge
  }
}
