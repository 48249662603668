import React from 'react'
import { APIEntity } from 'services/api/entities'
import { useKGetList } from 'hooks/useKGetList'
import { ActivityStatus, WithId, WithIdAndName } from '@kampaay/common'
import { CustomReferenceContextProvider } from 'components/Inputs/CustomReferenceContext'
import { CircularProgress } from '@mui/material'
import { SortPayload } from 'react-admin'

export type FilterFn<T> = (p: T) => boolean

export type CustomReferenceInputProps<T extends WithId> = {
  children: JSX.Element
  entity: APIEntity
  additionalChoices?: WithIdAndName[]
  filterFns?: FilterFn<T>[]
  queryFilter?: Record<string, unknown>
  sort?: SortPayload
}

export type WithSectionAndStatus = {
  sections?: unknown[]
  status?: ActivityStatus
}

/**
 * If the product has section there should be at least one section
 * If the product has status the status should not be inactive
 * Else the product is visible in store
 * @param product the product to check
 * @returns true if is visible in store false else
 */
export const isProductVisibleInStore = <
  T extends WithSectionAndStatus & WithId
>(
  product: T
) =>
  (!product.sections || !!product.sections?.length) &&
  product.status !== 'inactive'

const CustomReferenceInput = <T extends WithId>({
  children,
  entity,
  filterFns = [],
  queryFilter = {},
  additionalChoices = [],
  sort,
  ...props
}: CustomReferenceInputProps<T>) => {
  // choices are fetched and filtered
  const { list, isLoading, refetch } = useKGetList<T>(entity, {
    filter: queryFilter,
    sort
  })

  if (isLoading)
    return <CircularProgress className="ml-auto mr-auto" size="30px" />

  const fetchedChoices = list.filterEvery(filterFns)
  const choices = [...fetchedChoices, ...additionalChoices]

  return (
    <CustomReferenceContextProvider value={{ refetch }}>
      {React.cloneElement(children, {
        choices,
        ...props
      })}
    </CustomReferenceContextProvider>
  )
}

export default CustomReferenceInput
