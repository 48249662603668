import { WithTimeBeforeAfterHint } from '@kampaay/common'

export const formatTimeBeforeAfterHint = ({
  timeBeforeAfterHint,
  defaultTimeBeforeAfterHint
}: WithTimeBeforeAfterHint) => ({
  'Time before/after hints': [
    ...timeBeforeAfterHint,
    defaultTimeBeforeAfterHint
  ]
})
