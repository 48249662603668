import {
  UseValidationSchema,
  yupArray,
  yupIgnore,
  yupObject,
  YupSchema,
  yupString
} from 'services/yup'
import {
  FoodServiceConfig,
  GeneralServiceConfig,
  ProfessionalServiceConfig,
  ProfessionalServiceGroup,
  ServiceConfig,
  ServicesConfig
} from 'services/api/entities/services-config/types/internal/services-config-models'
import { withTimeBeforeHintVS } from 'services/validations/catalog/hints'
import {
  generalBriefQuestionVS,
  serviceConfigBaseVS,
  serviceDetailsVS
} from 'services/validations/servicesConfig/common'
import { docVS } from 'services/validations/doc'
import { GeneralServiceDiscriminator } from '@kampaay/common'

export const serviceConfigVS: YupSchema<ServiceConfig> = serviceConfigBaseVS
  .concat(serviceDetailsVS)
  .shape({
    eventKampaayerDocs: yupArray(docVS)
  })

export const generalServiceConfigVS: YupSchema<GeneralServiceConfig> =
  yupObject().shape({
    brief: yupArray(generalBriefQuestionVS),
    serviceDiscriminator: yupString<GeneralServiceDiscriminator>()
  })

export const foodServiceConfigVS: YupSchema<FoodServiceConfig> =
  serviceConfigVS.concat(withTimeBeforeHintVS)

export const professionalServiceConfigVS: YupSchema<ProfessionalServiceConfig> =
  serviceConfigBaseVS.shape({
    section: yupObject(),
    // FIXME: look into ts behavior of interpolating EncodedProfessionalDiscriminator type
    serviceDiscriminator: yupIgnore() as any
  })

export const professionalServiceGroupVS: YupSchema<ProfessionalServiceGroup> =
  serviceDetailsVS.shape({
    services: yupArray(professionalServiceConfigVS)
  })

export const servicesConfigVS: UseValidationSchema<ServicesConfig> = () =>
  yupObject().shape({
    id: yupIgnore(),
    generalService: generalServiceConfigVS,
    foodService: foodServiceConfigVS,
    professionalService: professionalServiceGroupVS,
    drinkService: serviceConfigVS,
    locationService: serviceConfigVS,
    musicService: serviceConfigVS,
    physicalService: serviceConfigVS,
    rentService: serviceConfigVS,
    virtualService: serviceConfigVS
  })
