import React, { useEffect } from 'react'
import { locationArrangementStatusChoices } from 'services/cfg/form'
import { translate } from 'services/i18n'
import KToolTip from 'components/UI/KTooltip'
import KNumberInput from 'components/Inputs/KInputs/KNumberInput/KNumberInput'
import KSelectInput from 'components/Inputs/KInputs/KSelectInput'
import { RAFormFC } from 'types/common'
import { useForm } from 'hooks/useForm'
import { LocationArrangementConfig } from 'services/api/entities/catalog/location/types/internal/location-models'

const SingleArrangementInput: RAFormFC<{
  name: string
  defaultValue: LocationArrangementConfig
}> = ({ source, name, defaultValue }) => {
  const { getSource, useGetValue, useSetValue } =
    useForm<LocationArrangementConfig>(source)

  const status = useGetValue('status')
  const capacity = useGetValue('capacity')
  const setCapacity = useSetValue('capacity')

  const disableParticipantsInput =
    (!status && defaultValue.status !== 'active') || status !== 'active'

  const label = translate(`form.fields.${name}Arrangement`)

  useEffect(() => {
    if (status !== 'active' && capacity !== 0) setCapacity(0)
  }, [status, capacity, setCapacity])

  return (
    <div>
      <div>{label}</div>
      <div style={{ display: 'flex' }}>
        <KToolTip
          title="tooltip.hint.locationArrangementStatus"
          placement="top"
        >
          <KSelectInput
            defaultValue={defaultValue.status}
            source={getSource('status')}
            choices={locationArrangementStatusChoices}
            label="Status"
            style={{ marginRight: '24px' }}
          />
        </KToolTip>
        <KToolTip title="tooltip.hint.locationArrangementCapacity">
          <KNumberInput
            defaultValue={defaultValue.capacity}
            source={getSource('capacity')}
            label="form.fields.maxParticipants"
            disabled={disableParticipantsInput}
          />
        </KToolTip>
      </div>
    </div>
  )
}

export default SingleArrangementInput
