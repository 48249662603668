import React from 'react'
import KList, { KListProps } from 'components/Lists/KLists/KList'
import ReorderableListContent from 'components/Lists/DraggableList/ReorderableListContent'

type ReorderableListProps = KListProps

const ReorderableList: React.FC<ReorderableListProps> = ({
  children,
  ...props
}) => {
  return (
    <KList {...props}>
      <ReorderableListContent>{children}</ReorderableListContent>
    </KList>
  )
}

export default ReorderableList
