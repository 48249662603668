import React from 'react'
import { TabBanelProps } from 'components/Dialogs/TabBanel'
import ConciergeOkrsTabContent from 'okrs/concierge-okrs/ConciergeOkrsTabContent'
import { List } from 'react-admin'
import KPagination from 'components/Lists/KPagination'

type Props = TabBanelProps & {
  rangeStart: string
  rangeEnd: string
}

const ConciergeOkrsTab: React.FC<Props> = ({
  tabIndex,
  tabValue,
  rangeEnd,
  rangeStart,
  ...rest
}) => {
  return (
    <div
      role="tabpanel"
      hidden={tabValue !== tabIndex}
      id={`tabpanel-${tabIndex}`}
      {...rest}
    >
      <List
        resource="concierge"
        disableSyncWithLocation
        actions={false}
        pagination={<KPagination rowsPerPageOptions={[500]} />}
        perPage={500}
        filter={{
          'createdAt~gte~': rangeStart,
          'createdAt~lte~': rangeEnd
        }}
      >
        <ConciergeOkrsTabContent rangeStart={rangeStart} rangeEnd={rangeEnd} />
      </List>
    </div>
  )
}

export default ConciergeOkrsTab
