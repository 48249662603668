import React from 'react'
import { BulkDeleteWithConfirmButton } from 'react-admin'
import { BaseListProps } from 'components/Lists/CatalogItemBaseList'
import ProductCommonList from 'components/Lists/ProductCommonList'
import SectionWrapper from 'components/UI/SectionWrapper'
import KCloneButton from 'components/Buttons/KCloneButton'

const LogisticsList: React.FC<BaseListProps> = () => {
  return (
    <SectionWrapper>
      <ProductCommonList
        bulkActionButtons={
          <BulkDeleteWithConfirmButton mutationMode="optimistic" />
        }
      >
        <KCloneButton />
      </ProductCommonList>
    </SectionWrapper>
  )
}

export default LogisticsList
