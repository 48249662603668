import type { ProposalItem } from '../../../../api/resources/proposal-item/types/internal/proposal-item-models'
import { KStorage } from '../../storage'

export type StorageProposalItem = Omit<ProposalItem, 'id'> & {
  proposalId?: number
}

export const proposalItemCopyStorage = new KStorage<StorageProposalItem>(
  '__proposalItem',
  localStorage
)
