import PocEdit from 'poc/poc1/PocEdit'
import PocCreate from 'poc/poc1/PocCreate'
import PocList from 'poc/poc1/PocList'
import { PocDescriptor } from 'poc/factory'
import { apiMocker } from 'poc/apiMock'

type Model = {
  id: number
  title: string
}

const MOCK: Model[] = [
  {
    id: 1,
    title: 'test 17'
  },
  {
    id: 2,
    title: 'test 12'
  }
]

const cfg: PocDescriptor = {
  dp: apiMocker(MOCK),
  forms: { list: PocList, edit: PocEdit, create: PocCreate }
}

export default cfg
