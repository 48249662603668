import { ArrayField, Datagrid, TextField } from 'ra-ui-materialui'
import React from 'react'
import { professionalSpecialTypeChoices } from 'services/cfg/form'
import CatalogItemBaseFormContent from 'components/Forms/CatalogItemBaseFormContent'
import KSelectInput from 'components/Inputs/KInputs/KSelectInput'

const ProfessionalSectionFormContent: React.FC = () => (
  <CatalogItemBaseFormContent>
    <KSelectInput
      source="specialType"
      choices={professionalSpecialTypeChoices}
    />

    <ArrayField sortable={false} source="productsFull">
      <Datagrid>
        <TextField source="name" />
      </Datagrid>
    </ArrayField>
  </CatalogItemBaseFormContent>
)

export default ProfessionalSectionFormContent
