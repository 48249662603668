import {
  yupArray,
  yupBoolean,
  yupIgnore,
  yupNumber,
  yupObject,
  YupSchema,
  yupString
} from 'services/yup'
import {
  BriefQuestion,
  BriefQuestionBase,
  GeneralBriefQuestion,
  Question
} from 'services/api/entities/services-config/types/internal/question-models'
import { QuestionType } from 'types/form'
import {
  ServiceConfigBase,
  ServiceDetails
} from 'services/api/entities/services-config/types/internal/services-config-models'
import { ProductServiceDiscriminator } from '@kampaay/common'

export const questionVS: YupSchema<Question> = yupObject().shape({
  id: yupIgnore(),
  text: yupString().required(),
  type: yupString<QuestionType>().required(),
  mandatory: yupBoolean()
})

export const briefQuestionBaseVS: YupSchema<BriefQuestionBase> =
  questionVS.shape({
    options: yupArray(yupString()).when('type', {
      is: 'multiple',
      then: yupArray(yupString()).min(1).required()
    }),
    notActiveWhenServices: yupArray()
  })

export const briefQuestionVS: YupSchema<BriefQuestion> =
  briefQuestionBaseVS.shape({
    products: yupArray(yupNumber())
  })

export const generalBriefQuestionVS: YupSchema<GeneralBriefQuestion> =
  briefQuestionBaseVS.shape({
    activeWhenServices: yupArray()
  })

export const serviceConfigBaseVS: YupSchema<ServiceConfigBase> =
  yupObject().shape({
    brief: yupArray(briefQuestionVS)
  })

export const serviceDetailsVS: YupSchema<ServiceDetails> = yupObject().shape({
  advanceReservationMin: yupNumber().required(),
  serviceDiscriminator: yupString<ProductServiceDiscriminator>()
})
