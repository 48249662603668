import { buildFilterConfig } from 'services/utils/filters'
import { FILTER_SOURCES } from '@kampaay/common'
import { CATALOG_FILTERS } from 'services/filters/entities/catalog'

export const PHYSICAL_FILTERS = [
  ...CATALOG_FILTERS,
  buildFilterConfig('text', {
    label: 'form.fields.address',
    source: FILTER_SOURCES.address.contains,
    alwaysOn: true
  })
]
