import React from 'react'
import SectionWrapper from 'components/UI/SectionWrapper'
import VirtualExperiencesFormContent from 'resources/catalog/virtualexperiences/product/VirtualExperiencesFormContent'
import { KSimpleForm } from 'components/Forms/KForms/KSimpleForm'
import KCreate from 'components/Forms/KCreate/KCreate'

const VirtualExperienceCreate: React.FC = () => (
  <SectionWrapper>
    <KCreate>
      <KSimpleForm>
        <VirtualExperiencesFormContent />
      </KSimpleForm>
    </KCreate>
  </SectionWrapper>
)

export default VirtualExperienceCreate
