import { DEFAULT_TIME_BEFORE_HINT } from '@kampaay/common'
import { ServicesConfig } from 'services/api/entities/services-config/types/internal/services-config-models'

export const SERVICE_CFG_MOCK: ServicesConfig = {
  id: 'servicesconfig',
  generalService: {
    serviceDiscriminator: 'GeneralConfigurator',
    brief: []
  },
  rentService: {
    advanceReservationMin: 0,
    eventKampaayerDocs: [],
    brief: [],
    serviceDiscriminator: 'RentConfigurator'
  },
  foodService: {
    advanceReservationMin: 0,
    eventKampaayerDocs: [],
    brief: [],
    serviceDiscriminator: 'FoodConfigurator',
    timeBeforeHint: [],
    showTimeBeforeHint: false,
    defaultTimeBeforeHint: DEFAULT_TIME_BEFORE_HINT
  },
  virtualService: {
    advanceReservationMin: 0,
    eventKampaayerDocs: [],
    brief: [],
    serviceDiscriminator: 'VirtualConfigurator'
  },
  physicalService: {
    advanceReservationMin: 0,
    eventKampaayerDocs: [],
    brief: [],
    serviceDiscriminator: 'PhysicalConfigurator'
  },
  musicService: {
    advanceReservationMin: 0,
    eventKampaayerDocs: [],
    brief: [],
    serviceDiscriminator: 'MusicConfigurator'
  },
  drinkService: {
    advanceReservationMin: 0,
    eventKampaayerDocs: [],
    brief: [],
    serviceDiscriminator: 'DrinkConfigurator'
  },
  locationService: {
    advanceReservationMin: 0,
    eventKampaayerDocs: [],
    brief: [],
    serviceDiscriminator: 'LocationConfigurator'
  },
  professionalService: {
    advanceReservationMin: 0,
    services: [],
    serviceDiscriminator: 'ProfessionalConfigurator'
  }
}
