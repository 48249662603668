import React from 'react'
import { TextField, TextFieldProps } from 'react-admin'
import LocalizedField, {
  LocalizedFieldProps
} from 'components/Fields/Localized/LocalizedField'

type LocalizedTextFieldProps = Pick<TextFieldProps, 'sortable' | 'className'> &
  Pick<LocalizedFieldProps, 'source' | 'record'> & {
    label?: string
  }

/**
 * This is a TextField that translates the text from multilang
 * @param source - the source of the text to translate
 * @param label - the label of the text to translate
 * @param sortable - if the field is sortable
 * @param record - the record to translate - if not passed it will be retrieved from the context
 * @param className - the className of the field
 */
const LocalizedTextField: React.FC<LocalizedTextFieldProps> = ({
  source,
  label,
  sortable,
  record,
  className
}) => (
  <LocalizedField source={source} record={record}>
    <TextField label={label} sortable={sortable} className={className} />
  </LocalizedField>
)

export default LocalizedTextField
