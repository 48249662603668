import { useInput, useTranslate } from 'ra-core'
import React from 'react'
import { useMemo } from 'react'
import { CommonInputProps, InputHelperText } from 'react-admin'
import { WithDataTestId } from 'test/utils'
import { useKFormContext } from 'contexts/kform-context/KFormContext'
import { getSourceWithoutIndexNotation } from 'hooks/useForm'
import { isMultilangString } from '@kampaay/common'

// for some reason importing the correct SxProps from material-ui triggers a memory leak therefore to fix this we have to cast it as any
type SX = any

export type KInputProps = Omit<CommonInputProps, 'source'> &
  WithDataTestId & {
    // adding also sx in order to be able to enhance the default behavior
    sx?: SX
    className?: string
    superDense?: boolean
    width?: string
    // narrowing down the label to be just a string, we could open it up to be a component in the future if needed
    label?: string | false
    source?: string
  }

/**
 * A wrapper to the native useInput hook, it is meant to offer a common layer of abstraction where we can add
 * functionality to be applied to all the inputs.
 * All the added properties that are returned should be in the `k` property of the returned object
 */
export const useKInput = (props: KInputProps) => {
  const {
    label,
    helperText,
    className = '',
    sx = {},
    superDense = false,
    width = undefined,
    source = ''
  } = props

  const translate = useTranslate()
  const { mandatoryFieldsSources } = useKFormContext()
  const {
    fieldState,
    formState,
    isRequired: _,
    ...useInputRest
  } = useInput({ ...props, source })
  const { isTouched, error } = fieldState
  const { isSubmitted } = formState

  const isRequired = useMemo(() => {
    return mandatoryFieldsSources.includes(
      getSourceWithoutIndexNotation(source)
    )
  }, [source])

  const hasError = useMemo(
    () => (isTouched || isSubmitted) && !!error,
    [isTouched, isSubmitted, error]
  )

  // If we declare a width it overrides the default behavior
  const computedWidth = width ?? (superDense ? '112px' : undefined)

  const computedSx = superDense
    ? {
        ...sx,
        width: computedWidth,
        '.MuiInputBase-root': {
          padding: '4px 0',
          input: {
            padding: '4px 8px'
          }
        },
        label: {
          top: '-4px'
        }
      }
    : {}

  const computedVariant: KInputProps['variant'] = superDense
    ? 'outlined'
    : undefined

  // this workaround is done in order to remove the helperText when there is no error and no helper text
  const computedHelperText = useMemo(
    () =>
      hasError || helperText ? (
        <InputHelperText
          touched={isTouched || isSubmitted}
          error={error?.message}
          helperText={helperText}
        />
      ) : (
        <></>
      ),
    [hasError, isTouched, isSubmitted, error, helperText]
  )

  return {
    fieldState,
    formState,
    isRequired,
    ...useInputRest,
    k: {
      hasError,
      sx: computedSx,
      className,
      variant: computedVariant,
      helperText: computedHelperText,
      label: label ? (isMultilangString(label) ? translate(label) : label) : ' '
    }
  }
}
