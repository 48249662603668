import React from 'react'
import { Box, BoxProps } from '@mui/material'
import { WithDataTestId } from 'test/utils'

type Props = WithDataTestId & Omit<BoxProps, 'display'>

const FlexBox: React.FC<Props> = ({ children, ...props }) => (
  <Box display="flex" sx={{ width: '100%' }} {...props}>
    {children}
  </Box>
)

export default FlexBox
