import { APICfg } from 'services/api/api'
import { convertEmptyStringToUndefined } from 'services/utils'
import { ProfessionalSpecialType } from 'types/form'
import {
  ProductSection,
  ProductSectionToAPI
} from 'services/api/entities/catalog/types'
import { productSectionParse } from 'services/api/entities/catalog/utils'
import { APIProfessionalFigure } from '@kampaay/common'

type APIProfessionalSection = ProductSection<APIProfessionalFigure> & {
  specialType?: ProfessionalSpecialType
}

export type ProfessionalSection = APIProfessionalSection

export type ProfessionalSectionToApi = ProductSectionToAPI &
  Pick<APIProfessionalSection, 'specialType'>

const professionalSectionWrite = async ({
  specialType,
  products,
  ...rest
}: ProfessionalSection): Promise<ProfessionalSectionToApi> => ({
  ...rest,
  products: products ?? [],
  specialType: convertEmptyStringToUndefined(specialType)
})

const apiCfg: APICfg = {
  endpoint: 'professionalsections',
  parse: productSectionParse,
  write: professionalSectionWrite
}

export default apiCfg
