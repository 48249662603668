import React, { PropsWithChildren } from 'react'
import LabeledDateField from 'components/Fields/LabeledFields/LabeledDateField'
import CustomerFormContentCommon from 'components/FormComponents/customerAndSupplierForms/CustomerFormContentCommon'
import { KBooleanInput } from 'components/Inputs/KInputs/KBooleanInput'
import KTextInput from 'components/Inputs/KInputs/KTextInput/KTextInput'
import { useForm } from 'hooks/useForm'
import { RAFormFC } from 'types/common'
import KSelectInput from 'components/Inputs/KInputs/KSelectInput'
import { activityStatusChoices } from 'services/cfg/form'
import { Customer } from '@kampaay/common'

const CustomerFormContent: RAFormFC<PropsWithChildren> = ({
  source,
  children
}) => {
  const { useGetValue, getSource } = useForm<Customer>(source)

  const status = useGetValue('status')
  const disableAllInputs = status === 'inactive'

  return (
    <CustomerFormContentCommon
      disableAllInputs={disableAllInputs}
      selectInputStatus={
        <KSelectInput
          source="status"
          choices={activityStatusChoices}
          defaultValue={activityStatusChoices[0].id} // 'active'
        />
      }
    >
      <KTextInput
        source={getSource('email')}
        label="form.fields.email"
        disabled={disableAllInputs}
      />
      {children}
      <KTextInput
        source={getSource('phone')}
        label="form.fields.responsiblePhone"
        disabled={disableAllInputs}
      />
      <KTextInput
        source={getSource('address')}
        label="form.fields.customerAddress"
        disabled={disableAllInputs}
      />
      <KBooleanInput
        source={getSource('promotionalActivitiesConsensus')}
        label="form.fields.promotionalActivitiesConsensus"
        fullWidth
        disabled={disableAllInputs}
      />
      <KBooleanInput
        source={getSource('privacyPolicyAccepted')}
        label="form.fields.privacyPolicyAccepted"
        fullWidth
        disabled={disableAllInputs}
      />
      <LabeledDateField
        source={getSource('createdAt')}
        label="form.fields.registrationDate"
      />
      <LabeledDateField
        source={getSource('lastLogin')}
        label="form.fields.lastLoginDate"
      />
    </CustomerFormContentCommon>
  )
}
export default CustomerFormContent
