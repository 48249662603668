import { buildFilterConfig } from 'services/utils/filters'
import { FILTER_SOURCES } from '@kampaay/common'
import { GEO_AREA_FILTER_CFG } from 'services/filters/entities/geoareaFilter'

export const UPSELLING_FILTERS = [
  buildFilterConfig('text', {
    label: 'form.fields.name',
    source: FILTER_SOURCES.name.contains,
    alwaysOn: true
  }),
  GEO_AREA_FILTER_CFG
]
