import React from 'react'
import { APIEntity } from 'services/api/entities'
import { OptionedProductSelection } from 'services/api/entities/events/types/internal/selections'
import EventUpsellingSelectionInput from 'components/FormComponents/EventUpsellingSelectionInput'
import { SelectionDetailsProps } from 'components/FormComponents/MainProductSelectionInputContent'
import OptionSelectionInput from 'resources/operations/events/EventsComponents/OptionSelectionInput'
import { Experience } from '@kampaay/common'

type Props = SelectionDetailsProps<Experience, OptionedProductSelection> & {
  experience: Extract<APIEntity, 'virtuals' | 'physicals'>
}

const ExperienceSelectionDetailsFormContent: React.FC<Props> = ({
  scopedFormData,
  selectedProduct,
  isObsolete,
  getSource,
  experience
}) => {
  if (!scopedFormData || !getSource) return null

  const { upsellingSelection } = scopedFormData

  return (
    <>
      <EventUpsellingSelectionInput
        source={getSource('upsellingSelection')}
        hideQuantity={experience === 'virtuals'}
        disableInputs={isObsolete}
        selectedProduct={selectedProduct}
        upsellingSelection={upsellingSelection}
      />

      <OptionSelectionInput
        selectedProduct={selectedProduct}
        source={getSource('optionSelections')}
        eventOptioned={scopedFormData}
        isObsolete={isObsolete}
      />
    </>
  )
}

export default ExperienceSelectionDetailsFormContent
